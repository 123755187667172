import React from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {UnregisterCallback} from 'history';
import {useFieldArray} from 'react-hook-form';
import {useTitle} from '../../../../../hooks/useTitle';
import {urlHelper} from '../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {useSmartPush} from '../../../../../hooks/useSmartPush';
import {_create} from '../../../../../store/actions/segments/create';
import {_segmentsParams} from '../../../../../store/selectors/segments';
import {_onBeforeunload} from '../../../../../store/actions/application';
import {_fetchingSegmentsParamsState} from '../../../../../store/selectors/segments';

let unregisterCallback: UnregisterCallback | null = null;

const beforeUnload = function (e: BeforeUnloadEvent) {
  e.returnValue = 'Changes you made may not be saved.';

  return 'Changes you made may not be saved.';
}

export function useCreate() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {appUuid} = useSmartPush();
  useTitle('New Segment | SmartPush');

  const params = useSelector(_segmentsParams);
  const segmentsParamsState = useSelector(_fetchingSegmentsParamsState);
  const form = useForm<Record<string, any>>({
    defaultValues: {
      id   : '',
      name : '',
      query: [
        {
          type   : '',
          table  : '',
          values : '',
          filter : '',
          _values: [
            {
              value: '',
            }
          ],
        }
      ],
    }
  });

  const {
    formState: {
      isDirty,
      dirtyFields,
    },
    handleSubmit,
  } = form;

  const hasDirtyFields = !!Object.keys(dirtyFields || {}).length;

  React.useEffect(() => {
    if (isDirty && hasDirtyFields) {
      unregisterCallback = history.block((location) => {
        dispatch(_onBeforeunload({
          open           : true,
          bodyTitle      : 'Warning',
          agreeAction    : () => {
            dispatch(_onBeforeunload(void 0));
            unregisterCallback && unregisterCallback();
            history.push({
              ...location,
              search: void 0,
            });
          },
          onClose        : () => {
            dispatch(_onBeforeunload(void 0));
          },
          dismissAction  : () => {
            dispatch(_onBeforeunload(void 0));
          },
          dismissLabel   : 'Stay on Page',
          agreeLabel     : 'Discard Changes',
          bodyDescription: 'You have unsaved changes in this segment. If you continue, your changes will be lost.',
        }));

        return false;
      });

      window.addEventListener('beforeunload', beforeUnload);
    }

    return function () {
      unregisterCallback && unregisterCallback();

      window.removeEventListener('beforeunload', beforeUnload);
    }
  }, [
    history,
    isDirty,
    dispatch,
    hasDirtyFields,
  ]);

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    name   : 'query',
    control: form.control,
  });

  const onCancel = React.useCallback(() => {
    history.push(urlHelper(AppRoutes.SEGMENTS, {appUuid}));
  }, [
    history,
    appUuid,
  ]);

  const onAppend = React.useCallback(() => {
    append({
      type   : '',
      table  : '',
      values : '',
      filter : '',
      _values: [
        {
          value: '',
        }
      ],
    })
  }, [
    append,
  ]);

  const redirectToSegments = React.useCallback(() => {
    history.push(urlHelper(AppRoutes.SEGMENTS, {appUuid}));
  }, [
    history,
    appUuid,
  ]);

  const onRemove = React.useCallback((index: number) => () => {
    remove(index);
  }, [
    remove,
  ]);

  const create = React.useCallback((formValues: Record<string, any>) => {
    return dispatch(_create(appUuid, formValues, {
      unregisterCallback: () => {
        unregisterCallback && unregisterCallback();
        window.removeEventListener('beforeunload', beforeUnload);
      },
      setError          : form.setError,
      redirect          : () => history.push(urlHelper(AppRoutes.SEGMENTS, {appUuid})),
    }));
  }, [
    form,
    appUuid,
    history,
    dispatch,
  ]);

  return {
    params,
    fields,
    onRemove,
    onAppend,
    onCancel,
    redirectToSegments,
    segmentsParamsState,
    onSubmit: handleSubmit(create),
    ...form
  }
}