import React from 'react';
import Typography from '@material-ui/core/Typography';

export function ErrorMessage(
  {
    style,
    message,
  }: {
    message?: React.ReactNode;
    style?: React.CSSProperties;
  }
): JSX.Element {
  return (
    <Typography
      style={{
        marginTop    : 3,
        fontWeight   : 400,
        lineHeight   : 1.66,
        textAlign    : 'left',
        color        : '#f44336',
        fontSize     : '12px',
        letterSpacing: '0.03333em',
        ...style
      }}
    >{message || <span style={{color: 'transparent'}}>_</span>}
    </Typography>
  )
}