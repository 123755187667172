import {Form} from 'antd';
import React from 'react';
import {Divider} from 'antd';
import {useLogin} from './useLogin';
import {Link as RLink} from 'react-router-dom';
import {GoogleAuth} from '../../Common/GoogleAuth';
import {dataPrefixes} from '../../../../constants';
import {Redirecting} from '../../Common/Redirecting';
import {FormContent} from './components/FormContent';
import {AppRoutes} from '../../../../constants/appRoutes';
import {Text} from '../../../../components/antd/Typography';
import {Title} from '../../../../components/antd/Typography';
import {RecaptchaActions} from '../../../../constants/recaptchaActions';
import '../../index.less';

const {auth: {login}} = dataPrefixes;

export function Login() {
  const {
    control,
    onSubmit,
    clearErrors,
    searchString,
    formState: {
      isSubmitting,
      isSubmitSuccessful,
    },
  } = useLogin();

  return (
    <>
      {!isSubmitSuccessful && (
        <>
          <div
            className='m-b-5'
          >
            <Title
              level={5}
              align='center'
              fontWeight='regular'
            >Sign In to Your Account
            </Title>
          </div>
          <GoogleAuth
            action='gmail_login'
            buttonLabel='Sign In with Google'
            reCaptchaAction={RecaptchaActions.LOGIN}
            buttonProps={{'data-qa': `${login}-gmail-login-button`}}
          />
          <Divider>or</Divider>
        </>
      )}
      <form
        noValidate
        className='form'
        autoComplete='off'
        onSubmit={onSubmit}
      >
        {isSubmitSuccessful ? (
          <Redirecting
            title='You have Signed In.'
            subTitle='Redirecting to the App ...'
          />
        ) : (
          <FormContent
            control={control}
            clearErrors={clearErrors}
            isSubmitting={isSubmitting}
            searchString={searchString}
          />
        )}
      </form>
      {!isSubmitSuccessful && (
        <>
          <Form
            component='div'
            className='form'
            layout='vertical'
          >
            <div
              className='d-flex justify-content-between'
            >
              <Text
              >Don’t have an Account?
              </Text>
              <RLink
                className='ant-typography regular'
                data-qa={`${login}-register-link`}
                to={!searchString ? AppRoutes.REGISTER : `${AppRoutes.REGISTER}?${searchString}`}
              >Sign Up for Free Now
              </RLink>
            </div>
          </Form>
        </>
      )}
    </>
  )
}
