import produce from 'immer';
import {Prompt} from '../../../types/prompt';
import {ActionTypes} from '../../constants/prompt';
import {FiniteStates} from '../../../constants/finiteStates';
import {ActionTypes as CoreActionTypes} from '../../constants/application';

const InitialState: Prompt.Reducer = {
  dataOptions: {
    isTogglingPrompt    : FiniteStates.IDLE,
    isCreatingOrUpdating: FiniteStates.IDLE,
  }
};

export const prompt = produce((draft, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.PROMPT_RESET: {
      return {...InitialState}
    }
    case ActionTypes.PROMPT_DATA_OPTIONS: {
      if (!draft.dataOptions) {
        draft.dataOptions = {} as any;
      }
      Object.keys(payload || {}).forEach((key: string) => {
        (draft.dataOptions as any)[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.PROMPT_UPSERT_ENTITY: {
      if (!draft.entity) {
        draft.entity = {} as any;
      }
      Object.keys(payload || {}).forEach((key: string) => {
        (draft.entity as any)[key] = payload[key];
      });

      return void 0;
    }
    case CoreActionTypes.LOGOUT: {
      return {...InitialState}
    }
    default:
      return void 0;
  }
}, InitialState);