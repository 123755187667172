import React from 'react';
import Crop from './Crop';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import DeleteDialog from '../../Dialog/Delete';
import {TableActions} from '../../../constants/tableActions';
import {destroyAction} from '../../../store/actions/gallery';
import {getDataOptions} from '../../../store/selectors/gallery';
import {getActionDialog} from '../../../store/selectors/gallery';
import {toggleDialogAction} from '../../../store/actions/gallery';
import {getActionDialogData} from '../../../store/selectors/gallery';

export default function Dialogs(
  {
    onClick,
    aspectRatio,
    onCloseGallery,
  }: {
    onClick: any;
    aspectRatio: number;
    onCloseGallery: any;
  }
) {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {
    isDeleting,
  } = useSelector(getDataOptions);
  const dialog = useSelector(getActionDialog);
  const row = useSelector(getActionDialogData);

  const destroy = React.useCallback(() => {
    dispatch(destroyAction());
  }, [
    dispatch,
  ]);

  switch (dialog) {
    case TableActions.DELETE: {
      return (
        <DeleteDialog
          agreeAction={destroy}
          loading={!!isDeleting}
          agreeLabel={t('buttons.delete')}
          open={TableActions.DELETE === dialog}
          dismissLabel={t('buttons.cancel')}
          bodyDescription={t('messages.delete_body_description')}
          onClose={() => dispatch(toggleDialogAction(void 0))}
          dismissAction={() => dispatch(toggleDialogAction(void 0))}
          bodyTitle={t('messages.delete_body_title', {name: row.caption})}
        />
      )
    }
    case TableActions.CROP: {
      return (
        <Crop
          row={row}
          onClick={onClick}
          aspectRatio={aspectRatio}
          onCloseGallery={onCloseGallery}
          open={TableActions.CROP === dialog}
          onClose={() => dispatch(toggleDialogAction(void 0))}
        />
      )
    }
    default: {
      return <></>
    }
  }
}

