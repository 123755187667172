import React from 'react';
import {TextField} from '../../Input';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {IconButton} from '../../IconButton';
import {Theme} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import {optionsAction} from '../../../store/actions/gallery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme: Theme) => ({
  searchTextField: {
    width                         : 300,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
}));

export default function Search() {
  const classes = useStyles()
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const inputRef = React.createRef<any>();

  const [localSearchText, setSearchText] = React.useState<any>('');

  const textFieldOnChange = React.useCallback((e) => {
    setSearchText(e.target.value);
    if (!e.target.value) {
      dispatch(optionsAction({
        pageNumber: 1,
        searchText: void 0,
      }));
    }
  }, [
    dispatch,
  ]);

  const endAdornmentOnClick = React.useCallback(() => {
    if (localSearchText) {
      dispatch(optionsAction({
        pageNumber: 1,
        searchText: localSearchText,
      }));
    }
  }, [
    dispatch,
    localSearchText,
  ]);

  const textFieldOnKeyPress = React.useCallback((e) => {
    if (e.key === 'Enter' && localSearchText) {
      dispatch(optionsAction({
        searchText: localSearchText,
        pageNumber: 1,
      }));
    }
  }, [
    dispatch,
    localSearchText,
  ]);

  return (
    <TextField
      fullWidth
      disableMargin
      color='primary'
      InputProps={{
        endAdornment: (
          <EndAdornment
            onClick={endAdornmentOnClick}
          />
        ),
      }}
      inputRef={inputRef}
      onChange={textFieldOnChange}
      value={localSearchText || ''}
      placeholder={t('search')}
      onKeyPress={textFieldOnKeyPress}
      className={classes.searchTextField}
    />
  )
}

function EndAdornment(
  {
    onClick,
  }: {
    onClick: any;
  }
) {
  return (
    <InputAdornment
      position='end'
    >
      <IconButton
        size='small'
        onClick={onClick}
        color='transparent'
      >
        <SearchIcon/>
      </IconButton>
    </InputAdornment>
  )
}