export enum ActionTypes {
  WEB_NOTIFY_CAMPAIGNS_SET_DATA = 'WEB_NOTIFY_CAMPAIGNS_SET_DATA',
  WEB_NOTIFY_CAMPAIGNS_REMOVE_LANG = 'WEB_NOTIFY_CAMPAIGNS_REMOVE_LANG',
  WEB_NOTIFY_CAMPAIGNS_SET_LOADING = 'WEB_NOTIFY_CAMPAIGNS_SET_LOADING',
  WEB_NOTIFY_CAMPAIGNS_REMOVE_ERROR = 'WEB_NOTIFY_CAMPAIGNS_REMOVE_ERROR',
  WEB_NOTIFY_CAMPAIGNS_DATA_OPTIONS = 'WEB_NOTIFY_CAMPAIGNS_DATA_OPTIONS',
  WEB_NOTIFY_CAMPAIGNS_TOGGLE_ERRORS = 'WEB_NOTIFY_CAMPAIGNS_TOGGLE_ERRORS',
  WEB_NOTIFY_CAMPAIGNS_TABLE_OPTIONS = 'WEB_NOTIFY_CAMPAIGNS_TABLE_OPTIONS',
  WEB_NOTIFY_CAMPAIGNS_TOGGLE_DIALOG = 'WEB_NOTIFY_CAMPAIGNS_TOGGLE_DIALOG',
  WEB_NOTIFY_CAMPAIGNS_UPDATE_ENTITY = 'WEB_NOTIFY_CAMPAIGNS_UPDATE_ENTITY',
  WEB_NOTIFY_CAMPAIGNS_DELETE_ACTION = 'WEB_NOTIFY_CAMPAIGNS_DELETE_ACTION',
  WEB_NOTIFY_CAMPAIGNS_CANCEL_ACTION = 'WEB_NOTIFY_CAMPAIGNS_CANCEL_ACTION',
  WEB_NOTIFY_CAMPAIGNS_TOGGLE_ENTITY = 'WEB_NOTIFY_CAMPAIGNS_TOGGLE_ENTITY',
  WEB_NOTIFY_CAMPAIGNS_EMPTY_LANGUAGES = 'WEB_NOTIFY_CAMPAIGNS_EMPTY_LANGUAGES',
  WEB_NOTIFY_CAMPAIGNS_EMPTY_TABLE_DATA = 'WEB_NOTIFY_CAMPAIGNS_EMPTY_TABLE_DATA',
  WEB_NOTIFY_CAMPAIGNS_ADD_ENTITY_ACTION = 'WEB_NOTIFY_CAMPAIGNS_ADD_ENTITY_ACTION',
  WEB_NOTIFY_CAMPAIGNS_SET_CAMPAIGN_STAT = 'WEB_NOTIFY_CAMPAIGNS_SET_CAMPAIGN_STAT',
  WEB_NOTIFY_CAMPAIGNS_UPDATE_ENTITY_LANG = 'WEB_NOTIFY_CAMPAIGNS_UPDATE_ENTITY_LANG',
  WEB_NOTIFY_CAMPAIGNS_EMPTY_CAMPAIGN_STAT = 'WEB_NOTIFY_CAMPAIGNS_EMPTY_CAMPAIGN_STAT',
  WEB_NOTIFY_CAMPAIGNS_DELETE_ENTITY_ACTION = 'WEB_NOTIFY_CAMPAIGNS_DELETE_ENTITY_ACTION',
  WEB_NOTIFY_CAMPAIGNS_UPDATE_ENTITY_ACTIONS = 'WEB_NOTIFY_CAMPAIGNS_UPDATE_ENTITY_ACTIONS',
}
