import {notification} from 'antd';
import {stringify} from 'querystring';
import {filterObject} from '../../../../utils';
import {Globals} from '../../../../types/globals';
import {urlHelper} from '../../../../utils/urlHelper';
import {ApiRoutes} from '../../../../constants/apiRoutes';
import {_setData, _setLoading} from './actions';
import {getFilters} from '../../../selectors/builder';
import {ActionTypes} from '../../../constants/builders';

export const getIconsData = (appUuid: string): Globals.ThunkAction => async (dispatch, getState, {api}) => {
  dispatch(_setLoading('icons'));
  const filters = getFilters(getState());
  const {pageSize, parentTags, iconPageNumber, iconTags } = filters;

  const params = stringify(filterObject({
    pageSize,
    pageNumber: iconPageNumber,
    tags: parentTags.concat(iconTags).join(','),
  }));

  const endpoint = urlHelper(ApiRoutes.LIST_ICONS, { appUuid });
  const url = `${endpoint}?${params}`;
  const response = await api.get(url);

  if (!response.hasError) {
    dispatch(_setData(response.body.data, ActionTypes.BUILDER_BUILDERS_SET_ICON_DATA));
  }
};

export const getImagesData = (appUuid: string): Globals.ThunkAction => async (dispatch, getState, {api}) => {
  dispatch(_setLoading('images'));
  const filters = getFilters(getState());
  const {pageSize, parentTags, imagePageNumber, imageTags } = filters;

  const params = stringify(filterObject({
    pageSize,
    pageNumber: imagePageNumber,
    tags: parentTags.concat(imageTags).join(','),
  }));

  const endpoint = urlHelper(ApiRoutes.LIST_IMAGES, { appUuid });
  const url = `${endpoint}?${params}`;
  const response = await api.get(url);

  if (!response.hasError) {
    dispatch(_setData(response.body.data, ActionTypes.BUILDER_BUILDERS_SET_IMAGE_DATA));
  }
};

export const getTitlesData = (appUuid: string): Globals.ThunkAction => async (dispatch, getState, {api}) => {
  dispatch(_setLoading('titles'));
  const filters = getFilters(getState());
  const {pageSize, parentTags, titlePageNumber, titleSearchText } = filters;
  const params = stringify(filterObject({
    pageSize,
    pageNumber: titlePageNumber,
    searchText: titleSearchText,
    tags: parentTags,
  }));
  const endpoint = urlHelper(ApiRoutes.LIST_TITLES, { appUuid });
  const url = `${endpoint}?${params}`;
  const response = await api.get(url);

  if (!response.hasError) {
    dispatch(_setData(response.body.data, ActionTypes.BUILDER_BUILDERS_SET_TITLE_DATA));
  }
};

export const getMessagesData = (appUuid: string): Globals.ThunkAction => async (dispatch, getState, {api}) => {
  dispatch(_setLoading('messages'));
  const filters = getFilters(getState());
  const {pageSize, parentTags, messagePageNumber, messageSearchText } = filters;
  const params = stringify(filterObject({
    pageSize,
    pageNumber: messagePageNumber,
    searchText: messageSearchText,
    tags: parentTags,
  }));
  const endpoint = urlHelper(ApiRoutes.LIST_MESSAGES, { appUuid });
  const url = `${endpoint}?${params}`;
  const response = await api.get(url);

  if (!response.hasError) {
    dispatch(_setData(response.body.data, ActionTypes.BUILDER_BUILDERS_SET_MESSAGE_DATA));
  }
};

export const createTemplate = (appUuid: string, tmpData: any): Globals.ThunkAction => async (dispatch, getState, {api}) => {
  const url: string = urlHelper(ApiRoutes.BUILD_TEMPLATE, {appUuid});

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.post(url, filterObject(tmpData));

  if (hasError) {
    notification.error({
      message: data?.limits_notice || data?.messages || message,
    });
  } else {
    notification.success({message: 'Template has been successfully created'});
  }
};
