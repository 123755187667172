import {IObject} from '../types';

export const options: IObject[] = [
  {
    value: 'second',
    label: 'second',
  },
  {
    value: 'minute',
    label: 'minute',
  },
  {
    value: 'hour',
    label: 'hour',

  },
  {
    value: 'day',
    label: 'day',
  },
];
