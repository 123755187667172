import React from 'react';
import {Text} from '../Typography';
import {Route} from 'react-router';
import {Title} from '../Typography';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import logo from '../../../images/antd/logo.svg';
import {getPrefixCls} from '../../../utils/getPrefixCls';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import {_isAuthenticated} from '../../../store/selectors/application';
import './index.less';

export function Guest(props: Record<string, any>) {
  const authenticated = useSelector(_isAuthenticated);
  const isAppSumo = props.location.pathname.includes('app-sumo');

  return authenticated ? (
    <Redirect
      to='/'
    />
  ) : (
    <div
      className='container'
    >
      <div
        className='form-container'
      >
        <Back/>
        <div
          className='m-b-5'
        >
          {isAppSumo ? (
            <Title
              align='center'
              fontWeight='medium'
            >Welcome Sumo-ling!
            </Title>
          ) : (
            <a
              href='https://www.smartpush.ai/'
              className='d-flex justify-content-center'
            >
              <img
                src={logo}
                height={64}
                alt='Smart Push Logo'
              />
            </a>
          )}
        </div>
        <Route
          {...props}
        />
      </div>
    </div>
  )
}

function Back() {
  return (
    <a
      href='https://www.smartpush.ai/'
      className={getPrefixCls('guest-back-link')}
    >
      <Text
        fontWeight='regular'
      ><ArrowLeftOutlined className={getPrefixCls('guest-back-link-icon')}/>Back
      </Text>
    </a>
  )
}
