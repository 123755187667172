import {ActionTypes} from '../../../../constants/templates';
import {ActionTypes as CoreActionTypes} from '../../../../constants/application';

const InitialState: any = {
  byId        : {},
  allIds      : [],
  loading     : false,
  errors      : void 0,
  entity      : void 0,
  message     : void 0,
  actionDialog: void 0,
};

export default (state: any = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_TEMPLATES_SET_DATA: {
      const byId: any = {};
      const allIds: any[] = [];
      (payload as Array<any>).forEach((
        {
          id,
          data,
          ...rest
        }
      ) => {
        allIds.push(id);
        byId[id] = {
          id,
          ...data,
          ...rest,
          ...data?.messages?.default,
        };
      });
      return {
        ...state,
        byId,
        allIds,
      };
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATES_EMPTY_DATA: {
      return {
        ...InitialState,
      };
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATES_DATA_OPTIONS: {
      return {
        ...state,
        dataOptions: {
          ...state.dataOptions,
          ...payload
        }
      }
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATES_TOGGLE_DIALOG: {
      return {
        ...state,
        actionDialog: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATES_TOGGLE_ERRORS: {
      return {
        ...state,
        errors: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATES_REMOVE_ERROR: {
      if (state?.errors?.[payload]) {
        return {
          ...state,
          errors: {
            ...state.errors,
            [payload]: void 0
          },
        }
      }
      return state;
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATES_TOGGLE_ENTITY: {
      return {
        ...state,
        entity: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATES_UPDATE_ENTITY: {
      return {
        ...state,
        entity: {
          ...state.entity,
          ...payload
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATES_UPDATE_ENTITY_LANG: {
      const {
        id,
        ...restPayload
      } = payload;

      return {
        ...state,
        entity: {
          ...state.entity,
          messages: {
            ...state.entity?.messages,
            [id]: {
              ...(state.entity?.messages && state.entity?.messages[id]),
              ...restPayload
            }
          },
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATES_REMOVE_LANG: {
      state.entity?.messages && delete state.entity?.messages[payload];
      const lng = state.entity?.values?.find((i: any) => Number(i.value) === Number(payload))
      return {
        ...state,
        entity: {
          ...state.entity,
          messages: {
            ...state.entity?.messages,
          },
          tags    : [
            ...(state.entity?.tags?.filter((i: any) => String(i.value).toLowerCase() !== String(lng?.label).replace(/,/g, '').toLowerCase()) || [])
          ]
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATES_EMPTY_LANGUAGES: {
      const values = state.entity?.values?.map((i: any) => String(i.label).replace(/,/g, '').toLowerCase())
      return {
        ...state,
        entity: {
          ...state.entity,
          messages: {
            default: {...state.entity?.messages?.default},
          },
          tags    : state.entity?.tags?.filter((i: any) => !values.includes(String(i.value).replace(/,/g, '').toLowerCase()))
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATES_TOGGLE_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case CoreActionTypes.LOGOUT: {
      return InitialState
    }
    default:
      return state;
  }
};
