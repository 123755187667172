import {Card} from 'antd';
import React from 'react';
import {Menu} from 'antd';
import {Radio} from 'antd';
import {Dropdown} from 'antd';
import wp from './icons/wp.svg';
import gtm from './icons/gtm.svg';
import * as Common from '../index';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {Apps} from '../../../types/apps';
import simpleCode from './icons/simpleCode.svg';
import DownOutlined from '@ant-design/icons/DownOutlined';
import {RadioChangeEvent} from 'antd/lib/radio/interface';
import {FiniteStates} from '../../../constants/finiteStates';
import {_downloadingDoc} from '../../../store/selectors/apps';
import {EIntegrationTypes} from '../../../constants/integrationTypes';
import {_downloadDocs} from '../../../store/actions/apps/downloadDocs';
import './index.less';

const radioGroupOptions = [
  {
    value: EIntegrationTypes.simpleCode,
    label: (
      <div
        className='d-flex flex-column justify-content-center align-items-center radio-group-option'
      >
        <div>
          <img
            width={80}
            height={80}
            src={simpleCode}
            alt='Simple Code'
          />
        </div>
        <span>Simple Code</span>
      </div>
    )
  },
  {
    value: EIntegrationTypes.wordpressPlugin,
    label: (
      <div
        className='d-flex flex-column justify-content-center align-items-center radio-group-option'
      >
        <div>
          <img
            src={wp}
            width={80}
            height={80}
            alt='Wordpress Plugin'
          />
        </div>
        <span>Wordpress Plugin</span>
      </div>
    )
  },
  {
    value: EIntegrationTypes.googleTagManager,
    label: (
      <div
        className='d-flex flex-column justify-content-center align-items-center radio-group-option'
      >
        <div>
          <img
            src={gtm}
            width={80}
            height={80}
            alt='Google Tag Manager'
          />
        </div>
        <span>Google Tag Manager</span>
      </div>
    )
  },
];

export function IntegrationTypes(
  {
    value,
    onCopy,
    onChange,
    downloading,
    downloadWorkerJS,
  }: {
    onCopy?: () => void;
    downloading?: boolean;
    value: EIntegrationTypes;
    downloadWorkerJS: () => void;
    onChange: (e: RadioChangeEvent) => void;
  }
) {
  return (
    <>
      <Card
        bordered={false}
        extra={<Extra value={value}/>}
        className='create-app-card m-b-2 integration-options-card'
        title='1. Select Integration Type'
      >
        <Radio.Group
          value={value}
          onChange={onChange}
          optionType='button'
          buttonStyle='outline'
          className='radio-group'
          options={radioGroupOptions}
        />
      </Card>
      <Switcher
        value={value}
        onCopy={onCopy}
        downloading={downloading}
        downloadWorkerJS={downloadWorkerJS}
      />
    </>
  )
}

function Switcher(
  {
    value,
    onCopy,
    downloading,
    downloadWorkerJS,
  }: {
    value: string;
    onCopy?: () => void;
    downloading?: boolean;
    downloadWorkerJS: () => void;
  }
) {
  switch (value) {
    case'simple-code': {
      return (
        <Common.SimpleCode
          onCopy={onCopy}
          downloading={downloading}
          downloadWorkerJS={downloadWorkerJS}
        />
      )
    }
    case'wordpress-plugin': {
      return (
        <Common.WordPressPlugin/>
      )
    }
    case'google-tag-manager': {
      return (
        <Common.GoogleTagManager/>
      )
    }
  }
}

function Extra(
  {
    value
  }: {
    value: string
  }
) {
  const dispatch = useDispatch();
  const downloadingDoc = useSelector(_downloadingDoc);
  const {appUuid} = useParams<{ appUuid?: string }>();

  const download = function (docType?: Apps.DocType) {
    dispatch(_downloadDocs({
      docType,
      appUuid,
    }));
  }

  switch (value) {
    case'simple-code': {
      return (
        <SimpleCodeOverlay
          download={download}
          downloadingDoc={downloadingDoc}
        />
      )
    }
    case'wordpress-plugin': {
      return (
        <WordpressPlugin
          download={download}
          downloadingDoc={downloadingDoc}
        />
      )
    }
    case'google-tag-manager': {
      return (
        <GoogleTagManager
          download={download}
          downloadingDoc={downloadingDoc}
        />
      )
    }
  }
}

function SimpleCodeOverlay(
  {
    download,
    downloadingDoc,
  }: {
    downloadingDoc: FiniteStates;
    download: (type?: Apps.DocType) => void;
  }
) {
  return (
    <Dropdown.Button
      trigger={['click']}
      icon={<DownOutlined/>}
      onClick={() => download()}
      loading={downloadingDoc === FiniteStates.LOADING}
      overlay={<Menu>
        <Menu.Item
          key='downloadWordPressGuide'
          onClick={() => download('wp')}
        >Download WordPress Guide
        </Menu.Item>
        <Menu.Item
          key='downloadGTMGuide'
          onClick={() => download('gtm')}
        >Download GTM Guide
        </Menu.Item>
      </Menu>}
    >Download Integration Guides
    </Dropdown.Button>
  )
}

function WordpressPlugin(
  {
    download,
    downloadingDoc,
  }: {
    downloadingDoc: FiniteStates;
    download: (type?: Apps.DocType) => void;
  }
) {
  return (
    <Dropdown.Button
      trigger={['click']}
      icon={<DownOutlined/>}
      onClick={() => download('wp')}
      loading={downloadingDoc === FiniteStates.LOADING}
      overlay={<Menu>
        <Menu.Item
          onClick={() => download()}
          key='downloadIntegrationGuides'
        >Download Integration Guides
        </Menu.Item>
        <Menu.Item
          key='downloadGTMGuide'
          onClick={() => download('gtm')}
        >Download GTM Guide
        </Menu.Item>
      </Menu>}
    >Download WordPress Guide
    </Dropdown.Button>
  )
}

function GoogleTagManager(
  {
    download,
    downloadingDoc,
  }: {
    downloadingDoc: FiniteStates;
    download: (type?: Apps.DocType) => void;
  }
) {
  return (
    <Dropdown.Button
      trigger={['click']}
      icon={<DownOutlined/>}
      onClick={() => download('gtm')}
      loading={downloadingDoc === FiniteStates.LOADING}
      overlay={<Menu>
        <Menu.Item
          onClick={() => download()}
          key='downloadIntegrationGuides'
        >Download Integration Guides
        </Menu.Item>
        <Menu.Item
          key='downloadWordPressGuide'
          onClick={() => download('wp')}
        >Download WordPress Guide
        </Menu.Item>
      </Menu>}
    >Download GTM Guide
    </Dropdown.Button>
  )
}