import React from 'react';
import Radio from 'antd/lib/radio';
import Box from '@material-ui/core/Box';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {formatDate} from '../../../../../utils';
import {getOptions} from '../../../../../store/selectors/campaigns';
import {optionsAction} from '../../../../../store/actions/campaigns';

export default function ToolbarLeftActions() {
  const dispatch = useDispatch();

  const tableOptions = useSelector(getOptions);

  const radioGroupOnChange = React.useCallback((e) => {
    if (e.target.value === 'history') {
      dispatch(optionsAction({
        tab : e.target.value,
        to  : formatDate(new Date()),
        from: formatDate(new Date((new Date()).setDate(new Date().getDate() - 7)))
      }));
    } else {
      dispatch(optionsAction({
        to  : void 0,
        from: void 0,
        tab : e.target.value,
      }));
    }
  }, [
    dispatch,
  ]);

  React.useEffect(() => {
    localStorage.setItem('push_notifications_active_tab', tableOptions?.tab || 'active');
  }, [
    tableOptions?.tab
  ])

  return (
    <Box
      marginLeft={1}
    >
      <Radio.Group
        value={tableOptions.tab}
        onChange={radioGroupOnChange}
      >
        <Radio.Button
          value='active'
        >Active
        </Radio.Button>
        <Radio.Button
          value='draft'
        >Draft
        </Radio.Button>
        <Radio.Button
          value='history'
        >Completed
        </Radio.Button>
      </Radio.Group>
    </Box>
  )
}
