import clsx from 'clsx';
import React from 'react';
import {notification} from 'antd';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import {useTranslation} from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import {Theme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import {IObject} from '../../../../../../../types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import {copyToClipboard} from '../../../../../../../utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Button} from '../../../../../../../components/Button';
import TableContainer from '@material-ui/core/TableContainer';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import WallpaperRoundedIcon from '@material-ui/icons/WallpaperRounded';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';

const useStyles = makeStyles((theme: Theme) => ({
  root             : {
    marginBottom: theme.spacing(1),
  },
  section          : {
    margin                        : theme.spacing(0, 2),
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      width : '100%',
    },
  },
  titleContainer   : {
    padding: theme.spacing(0, 0, 1, 1,)
  },
  messageContainer : {
    padding: theme.spacing(4, 1, 1, 1),
  },
  avatar           : {
    height: 150,
    width : '100%',
  },
  imageContainer   : {
    height  : 150,
    width   : '100%',
    overflow: 'hidden',
  },
  cellFlex         : {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'space-between'
  },
  mainSection      : {
    display                       : 'flex',
    boxSizing                     : 'border-box',
    justifyContent                : 'space-between',
    padding                       : theme.spacing(7),
    '@media (max-width: 1365px)'  : {
      flexDirection        : 'column',
      alignItems           : 'center',
      '& > div:first-child': {
        marginBottom: theme.spacing(1),
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding      : theme.spacing(3),
    },
  },
  mainInfoContainer: {
    display       : 'flex',
    alignItems    : 'flex-start',
    justifyContent: 'flex-start',
  },
  tableCell        : {
    fontSize      : 12,
    '& *:not(svg)': {
      fontSize: 12,
    },
    borderTop     : 'none',
    borderLeft    : 'none',
    borderRight   : 'none',
    color         : '#707070',
    padding       : theme.spacing(1),
  },
  cardHeader       : {
    fontSize       : 16,
    fontWeight     : 500,
    textAlign      : 'start',
    padding        : theme.spacing(1, 2),
    color          : theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    border         : '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom   : 'none',
  },
  boldTypography   : {
    fontWeight: 'bold',
  },
  titleTypography  : {
    fontSize     : 12,
    fontWeight   : 'bold',
    textTransform: 'uppercase',
  },
  messageTypography: {
    fontSize  : 12,
    fontWeight: 'bold',
    color     : '#212121',
  },
  itemRoot         : {
    width                         : 250,
    flexDirection                 : 'column',
    display                       : 'inline-flex',
    [theme.breakpoints.down('sm')]: {
      alignSelf   : 'center',
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
}));

export default function PreviewContainer(
  {
    campaign,
    isWelcomeMessage,
  }: {
    campaign: IObject;
    isWelcomeMessage?: boolean;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();

  const copyKey = React.useCallback((text: string, copyItemKey: string) => {
    if (copyToClipboard(text)) {
      notification.success({
        message: t('campaigns.snackbar_success_message', {name: copyItemKey}),
      });
    } else {
      notification.success({
        message: t('campaigns.snackbar_error_message'),
      });
    }
  }, [
    t,
  ]);

  const templateData: {
    value: any;
    name: string;
  }[] = [
    {
      value: campaign.title,
      name : t('campaigns.title'),
    },
    {
      value: campaign.message,
      name : t('campaigns.message'),
    },
    {
      value: campaign.url,
      name : t('campaigns.url'),
    },
    {
      value: campaign.icon_url,
      name : t('campaigns.icon_url'),
    },
    {
      value: campaign.image_url,
      name : t('campaigns.image_url'),
    },
    {
      value: campaign.uuid,
      name : t('campaigns.uuid'),
    },
    ...(campaign?.action || []).map((item: {
      url: string;
      type: string;
      title: string;
      icon_url?: string;
    }, index: number) => ({
      value: item.title,
      name : t(`campaigns.action_${index + 1}`),
    }))
  ];

  return (
    <div
      className={classes.root}
    >
      <Typography
        component='div'
        className={classes.cardHeader}
      >{!isWelcomeMessage ? t('campaigns.message_title') : 'Welcome Message Content'}
      </Typography>
      <Paper
        square
        elevation={1}
        variant='outlined'
        className={classes.mainSection}
      >
        <Paper
          square
          elevation={1}
          className={clsx(classes.itemRoot, classes.section)}
        >
          <Typography
            component='div'
            className={classes.mainInfoContainer}
          >
            <Avatar
              variant='square'
              src={campaign.icon_url}
              imgProps={{
                loading: 'lazy',
                style  : {
                  objectFit: 'cover',
                },
              }}
            >
              <SentimentSatisfiedIcon/>
            </Avatar>
            <Typography
              noWrap
              component='div'
              className={classes.titleContainer}
            >
              <Typography
                noWrap
                color='primary'
                className={classes.titleTypography}
              >{t('campaigns.title')}
              </Typography>
              <Tooltip
                title={campaign.title || <em>{t('campaigns.no_title')}</em>}
              >
                <Typography
                  noWrap
                  variant='caption'
                >{campaign.title || <em>{t('campaigns.no_title')}</em>}
                </Typography>
              </Tooltip>
            </Typography>
          </Typography>
          <Typography
            component='div'
            className={classes.imageContainer}
          >
            <Avatar
              variant='square'
              src={campaign.image_url}
              className={classes.avatar}
              imgProps={{
                loading: 'lazy',
                style  : {
                  objectFit: 'cover',
                },
              }}
            >
              <WallpaperRoundedIcon/>
            </Avatar>
          </Typography>
          <Typography
            noWrap
            component='div'
            className={classes.messageContainer}
          >
            <Typography
              color='primary'
              className={classes.messageTypography}
            >{t('campaigns.message')}
            </Typography>
            <Tooltip
              title={campaign.message || <em>{t('campaigns.no_message')}</em>}
            >
              <Typography
                noWrap
                variant='caption'
              >{campaign.message || <em style={{color: 'rgba(0,0,0,.5)'}}>{t('campaigns.no_message')}</em>}
              </Typography>
            </Tooltip>
          </Typography>
        </Paper>
        <Typography
          component='div'
          className={classes.section}
        >
          <TableContainer>
            <Table
              style={{borderCollapse: 'separate'}}
            >
              <TableBody>
                {templateData.map((item, index) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell
                      scope='row'
                      component='td'
                      className={clsx(classes.tableCell, classes.boldTypography)}
                    >{item.name}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                    >
                      <Typography
                        component='div'
                        className={classes.cellFlex}
                      >
                        <Typography
                          component='div'
                        >{item.value || '-'}
                        </Typography>
                        {!!((item.name.includes('URL') || item.name === 'UUID') && item.value) && (
                          <Typography
                            component='div'
                          >
                            <Tooltip
                              title={t('campaigns.copy')}
                            >
                              <div>
                                <Button
                                  size='small'
                                  color='transparent'
                                  onClick={() => {
                                    copyKey(item.value, item.name)
                                  }}
                                >
                                  <FileCopyOutlinedIcon
                                    fontSize='small'
                                    style={{color: '#096dd9'}}
                                  />
                                </Button>
                              </div>
                            </Tooltip>
                          </Typography>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
      </Paper>
    </div>
  )
}
