export enum ActionTypes {
  WEB_NOTIFY_TEMPLATE_CAMPAIGNS_SET_DATA = 'WEB_NOTIFY_TEMPLATE_CAMPAIGNS_SET_DATA',
  WEB_NOTIFY_TEMPLATE_CAMPAIGNS_EMPTY_DATA = 'WEB_NOTIFY_TEMPLATE_CAMPAIGNS_EMPTY_DATA',
  WEB_NOTIFY_TEMPLATE_CAMPAIGNS_DATA_OPTIONS = 'WEB_NOTIFY_TEMPLATE_CAMPAIGNS_DATA_OPTIONS',
  WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TABLE_OPTIONS = 'WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TABLE_OPTIONS',
  WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TOGGLE_ERRORS = 'WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TOGGLE_ERRORS',
  WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TOGGLE_DIALOG = 'WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TOGGLE_DIALOG',
  WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TOGGLE_ENTITY = 'WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TOGGLE_ENTITY',
  WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TOGGLE_LOADING = 'WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TOGGLE_LOADING',
}
