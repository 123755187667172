import React from 'react';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {ColumnsType} from 'antd/es/table';
import {useTranslation} from 'react-i18next';
import {nFormatter} from '../../../../../utils';
import {useTitle} from '../../../../../hooks/useTitle';
import * as TableCell from '../../components/TableCell';
import {Templates} from '../../../../../types/templates';
import {urlHelper} from '../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {useSmartPush} from '../../../../../hooks/useSmartPush';
import {_dataOptions} from '../../../../../store/actions/templates';
import {_fetch} from '../../../../../store/actions/templates/fetch';
import * as selectors from '../../../../../store/selectors/templates';
import {_export} from '../../../../../store/actions/templates/export';

export function useIndex() {
  const history = useHistory();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const tags = useSelector(selectors._tags);
  const total = useSelector(selectors._total);
  const pageSize = useSelector(selectors._pageSize);
  const current = useSelector(selectors._pageNumber);
  const templates = useSelector(selectors._templates);
  const searchText = useSelector(selectors._searchText);
  const sorterField = useSelector(selectors._sorterField);
  const sorterOrder = useSelector(selectors._sorterOrder);
  const exportState = useSelector(selectors._exportTemplatesState);
  const templatesState = useSelector(selectors._fetchingTemplatesState);

  const {
    appUuid,
    user: {
      canCreate,
    },
  } = useSmartPush();

  useTitle(t('templates.document_title'));

  React.useEffect(() => {
    dispatch(_fetch(appUuid))
  }, [
    tags,
    appUuid,
    current,
    pageSize,
    dispatch,
    searchText,
    sorterField,
    sorterOrder,
  ]);

  const onReload = React.useCallback(() => {
    dispatch(_fetch(appUuid));
  }, [
    appUuid,
    dispatch,
  ]);

  const onExport = React.useCallback(() => {
    dispatch(_export(appUuid, {
      exportType: 'csv',
    }));
  }, [
    appUuid,
    dispatch,
  ]);

  const create = React.useCallback(() => {
    history.push(urlHelper(AppRoutes.TEMPLATES_CREATE, {appUuid}));
  }, [
    appUuid,
    history,
  ]);

  const tableOnChange = React.useCallback((pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      dispatch(_dataOptions({
        total     : pagination.total,
        pageNumber: pagination.current,
        pageSize  : pagination.pageSize,
      }));
    } else if (extra.action === 'sort') {
      dispatch(_dataOptions({
        sorterOrder: sorter.order,
        sorterField: sorter.field,
      }));
    }
  }, [
    dispatch,
  ]);

  const columns: ColumnsType<Templates.Entity> = React.useMemo(() => {
    return [
      {
        dataIndex: [
          'data',
          'messages',
          'default',
          'title',
        ],
        title    : 'Title',
        key      : 'data.messages.default.title',
        render   : (value, record) => <TableCell.Title value={value} {...record} />,
      },
      {
        dataIndex: [
          'data',
          'messages',
          'default',
          'message',
        ],
        title    : 'Message',
        key      : 'data.messages.default.message',
        render   : (value) => <TableCell.Message value={value}/>,
      },
      {
        title    : 'Tags',
        key      : 'tags',
        dataIndex: 'tags',
        render   : (value) => <TableCell.Tags value={value}/>,
      },
      {
        title    : 'Sent',
        key      : 'sent_sum',
        dataIndex: 'sent_sum',
        render   : value => nFormatter(value, 3),
      },
      {
        title    : 'Opened',
        key      : 'open_sum',
        dataIndex: 'open_sum',
        render   : value => nFormatter(value, 3),
      },
      {
        title    : 'CTR',
        key      : 'ctr',
        dataIndex: 'ctr',
        render   : value => `${Number(value).toFixed(3)}%`,
      },
      {
        sorter          : true,
        defaultSortOrder: 'descend',
        title           : 'Created at',
        key             : 'created_at',
        dataIndex       : 'created_at',
        render          : (value) => <TableCell.DateTime value={value}/>,
      },
      {
        sorter   : true,
        title    : 'Updated at',
        key      : 'updated_at',
        dataIndex: 'updated_at',
        render   : (value) => <TableCell.DateTime value={value}/>,
      },
      {
        width : '15rem',
        fixed : 'right',
        title : 'Actions',
        key   : 'operation',
        render: (record) => <TableCell.Actions record={record}/>,
      },
    ]
  }, []);

  return {
    total,
    create,
    columns,
    current,
    onReload,
    pageSize,
    onExport,
    templates,
    canCreate,
    exportState,
    tableOnChange,
    templatesState,
  }
}