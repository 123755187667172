import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {Modal, Button, Row, Form, Input} from 'antd';
import {Builder} from '../../../../types/builder';
import {_fetchTags} from '../../../../store/actions/tags';
import DynamicSelect from '../../../../components/antd/DynamicSelect';
import {createOrUpdateTitle} from '../../../../store/actions/builder/title/services';

const maxTegCounts = 5;

interface IProps {
  visible: boolean;
  onCancel: () => void;
  rowData?: Builder.TitleEntity;
};

const CreateOrEditDialog: React.FC<IProps> = ({visible, onCancel, rowData}) => {
  const dispatch = useDispatch();
  const {appUuid} = useParams<{appUuid: string}>();
  const [titleValue, setTitle] = useState('');
  const [isTitleEmpty, setIsTitleEmpty] = useState(false);
  const [tagsValue, setSags] = useState([]);
  const [hasTagError, setHasTagError] = useState('');

  useEffect(() => {
    if (rowData) {
      setTitle(rowData.value);
      setSags(rowData.tags.split(','));
    }
  },[rowData]);

  const onTitleChange = (value: string) => {
    setTitle(value);
    setIsTitleEmpty(!value);
  };

  const onTagsChange = (data: string[]) => {
    setSags(data);
    if (!data.length) {
      setHasTagError('The tags field is required.');
    } else if (data.length > maxTegCounts) {
      setHasTagError('You have reached your limit of 5 builder item tags.');
    } else {
      setHasTagError('');
    }
  };

  const onSave = () => {
    const data = {
      value: titleValue,
      tags: tagsValue.join(),
    };
    dispatch(createOrUpdateTitle(appUuid, data, rowData?.id));
    onCancel();
  };

  return (
    <Modal
      title={rowData ? 'Edit Title' : 'Create Title'}
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={(
        <Row justify='center'>
          <Button
            children='Close'
            onClick={onCancel}
            type='primary'
            ghost
          />
          <Button
            type='primary'
            children={rowData ? 'Save' : 'Create'}
            onClick={onSave}
            style={{marginLeft: 70}}
            disabled={!titleValue || !tagsValue.length || !!hasTagError}
          />
        </Row>
      )}
    >
      <Form layout='vertical'>
        <Form.Item
          label='Title *'
          validateStatus={isTitleEmpty && 'error'}
          help={isTitleEmpty && 'The value field is required.'}
        >
          <Input
            value={titleValue}
            onChange={event => onTitleChange(event.target.value)}
          />
        </Form.Item>
        <Form.Item
          label='Tags *'
          validateStatus={hasTagError && 'error'}
          help={hasTagError}
        >
          <DynamicSelect
            value={tagsValue}
            mode='tags'
            onChange={onTagsChange}
            fetchData={_fetchTags}
            placeholder='Select...'
            dataKey='rows'
            titleKey='id'
            valueKey='id'
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateOrEditDialog;
