import clsx from 'clsx';
import React from 'react';
import {Button} from '../Button';
import Box from '@material-ui/core/Box';
import {replaceBetween} from '../../utils';
import DoneIcon from '@material-ui/icons/Done';
import {Theme} from '@material-ui/core/styles';
import {BoxProps} from '@material-ui/core/Box';
import {useTheme} from '@material-ui/core/styles';
import {getUrlVars} from '../../utils/getUrlVars';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import MuiTextField from '@material-ui/core/TextField';
import {TextFieldProps} from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {lighten} from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles(() => ({
  muiTextField     : {
    backgroundColor: '#ffffff',
  },
  muiTextFieldRoot : {
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
  formLabelAsterisk: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red',
    },
  },
}));

const useInputLabel = makeStyles(({palette}: Theme) => ({
  root   : {
    fontSize               : 14,
    color                  : palette.text.primary,
    '&$focused:not($error)': {
      color: palette.text.primary,
    },
  },
  error  : {},
  focused: {},
  shrink : {
    fontWeight: 400,
    transform : 'translate(0, -1px)',
  },
}));

const useInputBase = makeStyles(({palette, spacing}: Theme) => ({
  root       : {
    fontSize                : 14,
    borderRadius            : 2,
    border                  : '1px solid',
    borderColor             : 'rgba(0, 0, 0, 0.3)',
    transition              : 'all 1s cubic-bezier(.075,.82,.165,1)',
    '&:hover:not($disabled)': {
      borderColor: palette.primary.main,
    },
    '& > svg'               : {
      color: lighten(palette.text.primary, .3),
    },
  },
  focused    : {
    borderWidth             : 1,
    borderColor             : palette.primary.main,
    boxShadow               : 'rgba(24, 144, 255, 0.2) 0px 0px 0px 2px',
    '&:hover:not($disabled)': {
      borderColor: palette.primary.main,
    },
  },
  error      : {
    borderColor             : palette.error.main,
    '&:hover:not($disabled)': {
      borderColor: palette.error.main,
    },
  },
  input      : {
    padding: '6.512px 8px',
  },
  disabled   : {},
  formControl: {
    'label + &': {
      marginTop: spacing(2.75),
    },
  },
}));

const formHelperTextStyles: React.CSSProperties = {
  right   : 0,
  bottom  : 8,
  position: 'absolute',
}

export function TextField(
  {
    tags,
    setValue,
    inputRef,
    tagsProps,
    className,
    InputProps,
    helperText,
    disableHelper,
    characterLimit,
    InputLabelProps,
    ...rest
  }: TextFieldProps & {
    tagsProps?: BoxProps;
    disableHelper?: boolean;
    characterLimit?: number;
    tags?: Record<string, string>;
    setValue?: UseFormSetValue<any>;
  }
): JSX.Element {
  const classes = useStyles();
  const inputBaseStyles = useInputBase();
  const inputLabelStyles = useInputLabel();
  const ref = React.useRef<HTMLInputElement | null>(null);

  let localInputProps: any = {
    disableUnderline: true,
  };

  let inputPropsClasses: any = {
    ...inputBaseStyles,
    ...InputProps?.classes,
  }
  if (rest.variant === 'outlined') {
    inputPropsClasses = {
      ...InputProps?.classes,
    }

    localInputProps = {};
  }

  let inputLabelPropsClasses: any = {
    ...inputLabelStyles,
    ...InputLabelProps?.classes,
  };
  if (rest.variant === 'outlined') {
    inputLabelPropsClasses = {
      ...InputLabelProps?.classes,
    }
  }

  return (
    <Box
      position='relative'
      width={rest.fullWidth ? '100%' : void 0}
    >
      <MuiTextField
        InputProps={{
          ...InputProps,
          ...localInputProps,
          classes  : inputPropsClasses,
          className: clsx(
            InputProps?.className,
            (rest.variant === 'outlined') && classes.muiTextField,
          ),
        }}
        className={clsx(className, classes.muiTextFieldRoot)}
        InputLabelProps={{
          ...InputLabelProps,
          classes  : inputLabelPropsClasses,
          shrink   : (rest.variant === 'outlined') ? true : true,
          className: clsx(
            classes.formLabelAsterisk,
            InputLabelProps?.className,
          ),
        }}
        inputRef={instance => {
          ref.current = instance;
          if (typeof inputRef === 'function') {
            (inputRef as any)(instance);
          }
        }}
        helperText={helperText || (disableHelper ? void 0 : <span style={{color: 'transparent'}}>_</span>)}
        {...rest as any}
      />
      {!!characterLimit && (
        <CharacterLimit
          value={rest.value}
          error={!!rest.error}
          characterLimit={characterLimit}
        />
      )}
      {!!tags && (typeof setValue === 'function') && (
        <Tags
          tags={tags}
          inputRef={ref}
          name={rest.name}
          value={rest.value}
          setValue={setValue}
          disabled={rest.disabled}
          {...tagsProps}
        />
      )}
    </Box>
  );
}

export function PasswordField(
  {
    showValidationRules,
    ...props
  }: TextFieldProps & {
    tagsProps?: BoxProps;
    disableHelper?: boolean;
    characterLimit?: number;
    tags?: Record<string, string>;
    showValidationRules?: boolean;
    setValue?: UseFormSetValue<any>;
  }
) {
  const [showPassword, togglePassword] = React.useState<boolean>(false);

  const handleClickShowPassword = React.useCallback(() => {
    togglePassword(prevState => !prevState);
  }, []);

  const handleMouseDownPassword = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  return (
    <Box>
      <TextField
        {...props}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          ...props?.InputProps,
          endAdornment: (
            <InputAdornment
              position={(props.variant === 'outlined') ? 'end' : 'start'}
            >
              <IconButton
                edge='end'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                size={(props.variant === 'outlined') ? void 0 : 'small'}
              >{showPassword ? (
                <Visibility
                  color={!!props.error ? 'error' : 'primary'}
                />
              ) : (
                <VisibilityOff
                  color={!!props.error ? 'error' : 'primary'}
                />
              )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {!!showValidationRules && (
        <ValidationRules
          password={props.value || ''}
        />
      )}
    </Box>
  )
}

function ValidationRules(
  {
    password,
  }: {
    password?: any;
  }
) {
  const [{
    one_lowercase_letter,
    one_uppercase_letter,
    at_least_8_characters,
    contains_a_number_or_a_symbol,
  }, setRules] = React.useState<{
    one_lowercase_letter: boolean,
    one_uppercase_letter: boolean,
    at_least_8_characters: boolean,
    contains_a_number_or_a_symbol: boolean,
  }>({
    one_lowercase_letter         : false,
    one_uppercase_letter         : false,
    at_least_8_characters        : false,
    contains_a_number_or_a_symbol: false,
  });
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    setRules({
      at_least_8_characters        : /^(?=.{8,})/.test(password),
      one_lowercase_letter         : /^(?=.*?[a-z])/.test(password),
      one_uppercase_letter         : /^(?=.*?[A-Z])/.test(password),
      contains_a_number_or_a_symbol: /^(?=.*?[0-9]|.*?[!'#$%&"()*+,-./:;<=>?@[\]^_`{|}~])/.test(password),
    });
  }, [
    password,
  ]);

  return (
    <Box
      display='flex'
      marginBottom={2}
      justifyContent='space-between'
      flexDirection={isSm ? 'column' : 'row'}
      alignItems={isSm ? 'flex-start' : 'center'}
    >
      <Box>
        <Typography
          style={{color: at_least_8_characters ? '#248000' : '#f44336'}}
        >
          <Box
            display='flex'
            component='span'
            alignItems='center'
          >
            <Box
              display='flex'
              component='span'
              marginRight={.5}
              alignItems='center'
            >
              {at_least_8_characters ? (
                <DoneIcon
                  color='inherit'
                  fontSize='inherit'
                />
              ) : (
                <HighlightOffIcon
                  color='inherit'
                  fontSize='inherit'
                />
              )}
            </Box>
            <Typography
              color='inherit'
              variant='inherit'
            >at least 8 characters
            </Typography>
          </Box>
        </Typography>
        <Typography
          style={{color: one_uppercase_letter ? '#248000' : '#f44336'}}
        >
          <Box
            display='flex'
            component='span'
            alignItems='center'
          >
            <Box
              display='flex'
              component='span'
              marginRight={.5}
              alignItems='center'
            >
              {one_uppercase_letter ? (
                <DoneIcon
                  color='inherit'
                  fontSize='inherit'
                />
              ) : (
                <HighlightOffIcon
                  color='inherit'
                  fontSize='inherit'
                />
              )}
            </Box>
            <Typography
              color='inherit'
              variant='inherit'
            >one uppercase letter
            </Typography>
          </Box>
        </Typography>
      </Box>
      <Box
        width={8}
      />
      <Box>
        <Typography
          style={{color: one_lowercase_letter ? '#248000' : '#f44336'}}
        >
          <Box
            display='flex'
            component='span'
            alignItems='center'
          >
            <Box
              display='flex'
              component='span'
              marginRight={.5}
              alignItems='center'
            >
              {one_lowercase_letter ? (
                <DoneIcon
                  color='inherit'
                  fontSize='inherit'
                />
              ) : (
                <HighlightOffIcon
                  color='inherit'
                  fontSize='inherit'
                />
              )}
            </Box>
            <Typography
              color='inherit'
              variant='inherit'
            >one lowercase letter
            </Typography>
          </Box>
        </Typography>
        <Typography
          style={{color: contains_a_number_or_a_symbol ? '#248000' : '#f44336'}}
        >
          <Box
            display='flex'
            component='span'
            alignItems='center'
          >
            <Box
              display='flex'
              component='span'
              marginRight={.5}
              alignItems='center'
            >
              {contains_a_number_or_a_symbol ? (
                <DoneIcon
                  color='inherit'
                  fontSize='inherit'
                />
              ) : (
                <HighlightOffIcon
                  color='inherit'
                  fontSize='inherit'
                />
              )}
            </Box>
            <Typography
              color='inherit'
              variant='inherit'
            >contains a number or a symbol
            </Typography>
          </Box>
        </Typography>
      </Box>

    </Box>
  )
}

function CharacterLimit(
  {
    error,
    value,
    characterLimit,
  }: {
    value: any;
    error: boolean;
    characterLimit: number;
  }
) {
  const counter = React.useCallback(() => {
    const newValue = value ? value.toString() : '';
    return [...newValue].length;
  }, [
    value
  ]);

  return (
    <FormHelperText
      error={!!error}
      style={formHelperTextStyles}
    ><span style={(counter() > characterLimit) ? {color: '#f44336'} : void 0}>{counter()}</span>/{characterLimit}
    </FormHelperText>
  )
}

const useTagsStyles = makeStyles((theme: Theme) => ({
  tags: {
    display       : 'flex',
    flexWrap      : 'wrap',
    alignItems    : 'center',
    justifyContent: 'center',
    '& button'    : {
      textTransform: 'unset',
      marginTop    : theme.spacing(1),
      marginRight  : theme.spacing(1),
    }
  },
}));

function Tags(
  {
    tags,
    name,
    value,
    disabled,
    setValue,
    inputRef,
    ...rest
  }: BoxProps & {
    value: any;
    name: string;
    disabled?: boolean;
    tags: Record<string, string>;
    setValue: UseFormSetValue<any>;
    inputRef: React.RefObject<HTMLInputElement>;
  }
) {
  const classes = useTagsStyles();

  const onClick = (tag: string) => () => {
    const currentTag = tags[tag];
    const selectionEnd = inputRef.current.selectionEnd;
    const selectionStart = inputRef.current.selectionStart;

    if (!(value || '').includes(tags[tag])) {
      setValue(name, replaceBetween(
        inputRef.current.value,
        currentTag,
        selectionStart,
        selectionEnd,
      ));
      inputRef.current.focus();
      inputRef.current.setSelectionRange(
        selectionStart + currentTag.length,
        selectionStart + currentTag.length
      );
    } else {
      const urlVars = getUrlVars(value || '');
      const searchValue = urlVars[tags[tag]];

      if (!searchValue) {
        setValue(name, (value || '').replace(tags[tag], ''));

        inputRef.current.focus();
        inputRef.current.setSelectionRange(
          selectionStart - currentTag.length,
          selectionStart - currentTag.length
        );
      } else {
        setValue(name, (value || '').replace(`${currentTag}=${searchValue}`, ''));

        inputRef.current.focus();
        inputRef.current.setSelectionRange(
          selectionStart - `${currentTag}=${searchValue}`.length,
          selectionStart - `${currentTag}=${searchValue}`.length
        );
      }
    }
  }

  return (
    <Box
      marginTop={-2}
      marginBottom={3}
      className={classes.tags}
      {...rest}
    >
      {Object.keys(tags).map((tag: string) => {
        return (
          <Button
            size='small'
            data-value={tag}
            variant='outlined'
            disabled={disabled}
            onClick={onClick(tag)}
            key={`TaggedItem_${tag}`}
            color={(value || '').includes(tags[tag]) ? 'primary' : void 0}
          >{tag}
          </Button>
        )
      })}
    </Box>
  )
}
