import React from 'react'
import CrProvider from './Cr/Provider';
import EpcProvider from './Epc/Provider';
import TitleProvider from './Title/Provider';
import ActionProvider from './Action/Provider';
import StatusProvider from './Status/Provider';
import CtrGetProvider from './CtrGet/Provider';
import RevenueProvider from './Revenue/Provider';
import {Plugin} from '@devexpress/dx-react-core';
import DeliverAtProvider from './DeliverAt/Provider';
import WaitUntilProvider from './WaitUntil/Provider';
import FrontDataProvider from './FrontData/Provider';

export default function Providers(
  {
    actions,
  }: any
) {
  return (
    <Plugin>
      <ActionProvider
        for={[
          'actions',
        ]}
        actions={actions}
      />
      <StatusProvider
        for={[
          'status',
        ]}
        actions={actions}
      />
      <CtrGetProvider
        for={[
          'ctr_get',
        ]}
        actions={actions}
      />
      <CrProvider
        for={[
          'cr',
        ]}
        actions={actions}
      />
      <EpcProvider
        for={[
          'epc',
        ]}
        actions={actions}
      />
      <RevenueProvider
        for={[
          'revenue',
        ]}
        actions={actions}
      />
      <TitleProvider
        for={[
          'title',
        ]}
        actions={actions}
      />
      <DeliverAtProvider
        for={[
          'deliver_at',
        ]}
        actions={actions}
      />
      <WaitUntilProvider
        for={[
          'wait_until',
        ]}
        actions={actions}
      />
      <FrontDataProvider
        for={[
          'front_data',
        ]}
        actions={actions}
      />
    </Plugin>
  )
}
