import clsx from 'clsx';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {TableFixedColumns} from '@devexpress/dx-react-grid-material-ui';

const useStyles = makeStyles(() => ({
  root: {
    overflow : 'unset',
    boxShadow: '-3px 0px 5px rgba(0,0,0,0.08)',
  },
}));

export default function TableFixedColumnsCellComponent(props: TableFixedColumns.CellProps) {
  const classes = useStyles();
  return (
    <TableFixedColumns.Cell
      {...props}
      className={clsx(classes.root, (props as any).className)}
    />
  )
}