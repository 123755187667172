import {_upsertSidebarData} from './index';
import {Globals} from '../../../types/globals';
import {ApiRoutes} from '../../../constants/apiRoutes';

export const _deliveredMessages = (): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  const {
    body: {
      data,
    },
    hasError,
  } = await api.get(ApiRoutes.DELIVERED_MESSAGES);

  if (hasError) {
    dispatch(_upsertSidebarData({
      deliveredMessages: 0,
    }))
  } else {
    dispatch(_upsertSidebarData({
      deliveredMessages: data?.count || 0,
    }))
  }
};
