import data from './data';
import options from './options';
import pagination from './pagination';
import {combineReducers} from 'redux';

export default combineReducers({
  data,
  options,
  pagination,
});
