import {Avatar} from 'antd';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {urlHelper} from '../../../../../../utils/urlHelper';
import {Templates} from '../../../../../../types/templates';
import {AppRoutes} from '../../../../../../constants/appRoutes';
import {Text} from '../../../../../../components/antd/Typography';

export function Title(
  {
    id,
    data,
    value,
  }: {
    value: string,
  } & Templates.Entity
) {
  const {appUuid} = useParams<{ appUuid: string }>();

  return (
    <div
      className='d-flex align-items-flex-end'
    >
      {!!data?.messages?.default?.icon_url && (
        <Avatar
          className='m-r-1'
          src={data.messages.default.icon_url}
        />
      )}
      <Text
        ellipsis
        underline
        style={{maxWidth: '40rem'}}
      >
        <Link
          to={urlHelper(AppRoutes.TEMPLATE_DETAILS, {appUuid, id})}
        >{value}
        </Link>
      </Text>
    </div>
  )
}
