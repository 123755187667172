import React from 'react';
import {Input} from 'antd';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {getPrefixCls} from '../../../../../utils/getPrefixCls';
import {_dataOptions} from '../../../../../store/actions/segments';
import {FiniteStates} from '../../../../../constants/finiteStates';
import {_searchText} from '../../../../../store/selectors/segments';
import {_fetchingSegmentsState} from '../../../../../store/selectors/segments';
import './index.less';

const {Search} = Input;

export function TableHeader() {
  const dispatch = useDispatch();

  const searchText = useSelector(_searchText);
  const segmentsState = useSelector(_fetchingSegmentsState);

  const onSearch = React.useCallback((value) => {
    dispatch(_dataOptions({
      searchText: value,
    }));
  }, [
    dispatch,
  ]);

  const onChange = React.useCallback((event) => {
    if (!event.target.value) {
      dispatch(_dataOptions({
        searchText: void 0,
      }));
    }
  }, [
    dispatch,
  ]);

  return (
    <div
      className={`d-flex align-items-center justify-content-between ${getPrefixCls('table-header')}`}
    >
      <Search
        onChange={onChange}
        onSearch={onSearch}
        placeholder='Search...'
        defaultValue={searchText}
        className={getPrefixCls('table-header-search')}
        disabled={segmentsState === FiniteStates.LOADING}
      />
    </div>
  )
}