import React from 'react';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {Modal, Radio, Space, Button } from 'antd';
import {_destroyAction} from '../../../../../store/actions/automation/destroy';
import {_toggleAction} from '../../../../../store/actions/automation/toggleStatus';
import {TableActions} from '../../../../../constants/tableActions';
import {Automations} from '../../../../../types/automations';
import './dialog.less';

interface IProps {
  dialogType: string;
  visible: boolean;
  onCancel: () => void;
  rowData: Automations.Entity;
}

const Dialogs: React.FC<IProps> = ({dialogType, visible, onCancel, rowData}) => {
  const [value, setValue] = React.useState<string>('false');
  const dispatch = useDispatch();
  const {appUuid} = useParams<{ appUuid: string }>();

  const handleDelete = () => {
    dispatch(_destroyAction(appUuid, rowData.id, value));
    onCancel();
  };

  const handleStatusChange = () => {
    dispatch(_toggleAction(appUuid, rowData.id, value));
    onCancel();
  };

  const getWrapperCn = React.useMemo(() => {
    let cn = 'dialogContent ';
    if (dialogType === TableActions.DELETE) {
      cn += 'delete';
    } else if (rowData.active) {
      cn += 'deactivate';
    } else cn += 'activate';
    return cn;
  }, [dialogType, rowData.active]);

  const deleteDialogContent = () => {
    return (
      <div className='dialogBody'>
        <h2>{`Are you sure you want to delete ${rowData.name}?`}</h2>
        <div className='infoText'>{'Once you are done deleting, you cannot undo this action.'}</div>
        <Radio.Group onChange={(e) => setValue(e.target.value)} value={value} className={'radioGroups'}>
          <Space direction='vertical'>
            <Radio value={'false'}>Keep active and scheduled campaigns</Radio>
            <Radio value={'true'}>Delete active and scheduled campaigns</Radio>
          </Space>
        </Radio.Group>
      </div>
    );
  };

  const activateDialogContent = () => {
    const isActive = rowData.active;
    return (
      <div className={'dialogBody'}>
        <h2>{`Are you sure you want to ${isActive ? 'deactivate' : 'activate'} ${rowData.name}?`}</h2>
        <div className='infoText'>
          {isActive ?
            <div>Your automation for today (UTC+0 timezone) will continue <br/>and will pause running tomorrow.</div>
            : <div>Your automation for today (UTC+0 timezone) will stay paused <br/>and will restart running tomorrow.</div>
          }
        </div>
        {isActive &&
          <Radio.Group onChange={(e) => setValue(e.target.value)} value={value} className={'radioGroups'}>
            <Space direction='vertical'>
              <Radio value={'false'}>Keep active and scheduled campaigns</Radio>
              <Radio value={'true'}>Delete active and scheduled campaigns</Radio>
            </Space>
          </Radio.Group>
        }

      </div>
    );
  };

  return (
    <Modal
      wrapClassName={getWrapperCn}
      title={dialogType === TableActions.DELETE ? 'Delete Automation' : 'Status Change'}
      visible={visible}
      onCancel={onCancel}
      footer={dialogType === TableActions.DELETE ? (
        <Space direction='horizontal'>
          <Button
            children='Keep Automation'
            onClick={onCancel}
          />
          <Button
            danger
            type='primary'
            children='Delete Automation'
            onClick={handleDelete}
          />
        </Space>
      ) : (
        <Space direction='horizontal'>
          <Button
            onClick={onCancel}
            children={rowData.active ? 'Keep Activated' : 'Keep Deactivated'}
          />
          <Button
            type='primary'
            onClick={handleStatusChange}
            children={rowData.active ? 'Deactivate Automation' : 'Activate Automation'}
          />
        </Space>
      )}
    >
      {dialogType === TableActions.DELETE ? deleteDialogContent() : activateDialogContent()}
    </Modal>
  );
};

export default Dialogs;
