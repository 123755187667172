import {ActionTypes} from '../../../constants/builders';
import {Builder} from '../../../../types/builder';

export const _updateFilters = (data: {[key: string]: any}) => ({
  payload: data,
  type   : ActionTypes.BUILDER_BUILDERS_UPDATE_FILTERS,
});

export const _setData = (
  data: Builder.IconEntity[]
  | Builder.ImageEntity[]
  | Builder.MessageEntity[]
  | Builder.TitleEntity[], type: ActionTypes
  ) => ({
  payload: data,
  type,
});

export const _cleanUp = () => ({
  type   : ActionTypes.BUILDER_BUILDERS_CLEAUP,
});

export const _cleanUpIconsData = () => ({
  type: ActionTypes.BUILDER_BUILDERS_CLEAUP_ICON_DATA,
});

export const _cleanUpImagesData = () => ({
  type: ActionTypes.BUILDER_BUILDERS_CLEAUP_IMAGE_DATA,
});

export const _cleanUpTitlesData = () => ({
  type: ActionTypes.BUILDER_BUILDERS_CLEAUP_TITLE_DATA,
});

export const _cleanUpMessagesData = () => ({
  type: ActionTypes.BUILDER_BUILDERS_CLEAUP_MESSAGE_DATA,
});



export const _setLoading = (data?: string) => ({
  payload: data,
  type   : ActionTypes.BUILDER_BUILDERS_SET_LOADING,
});
