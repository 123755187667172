import React from 'react';
import {useParams} from 'react-router';
import {_fetch} from '../../../../store/actions/automation/fetch';
import {TablePaginationConfig} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {FilterValue} from 'antd/lib/table/interface';
import {SortTypes} from '../../../../constants/sortTypes';
import {useSelector, useDispatch} from 'react-redux';
import * as selectors from '../../../../store/selectors/automation';
import ActionCell from './Table/ActionCell';
import {_clearData, _dataOptions} from '../../../../store/actions/automation/actions';

// TODO:: check automation permissions
export function useAutomation() {
    const dispatch = useDispatch();
    const {appUuid} = useParams<{ appUuid: string }>();
    const pageNumber = useSelector(selectors._pageNumber);
    const pageSize = useSelector(selectors._pageSize);
    const automationsData = useSelector(selectors._automationsData);
    const searchText = useSelector(selectors._searchText);
    const sortOrder = useSelector(selectors._sortOrder);
    const sortName = useSelector(selectors._sortName);
    const fetchingState = useSelector(selectors._fetchingAutomations);
    const total = useSelector(selectors._total);

    const handleTableChange = (p: TablePaginationConfig, f: Record<string, FilterValue>, sorter: any) => {
      const order: 'descend' | 'ascend' = sorter.order;
      dispatch(_dataOptions({ sortName: sorter.field, sortOrder: SortTypes[order] }));
    };
  
    const onPageChanges = (pageNumber: number, pageSize: number) => {
      dispatch(_dataOptions({pageNumber, pageSize}));
    };
  
    React.useEffect(() => {
      dispatch(_fetch(appUuid));
    }, [dispatch, appUuid, searchText, pageNumber, pageSize, sortOrder, sortName]);

    React.useEffect(() => {
      return function () {
        dispatch(_clearData());
      }
    }, [dispatch]);

    const columns: ColumnsType<any> = React.useMemo(() => {
        return [
          {
            key      : 'name',
            title    : 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'descend',
            sorter   : true,
          },
          {
            key      : 'from_date',
            title    : 'Run Start Date',
            dataIndex: 'from_date',
            minWidth : 150,
            sorter   : true,
            ellipsis : true,
          },
          {
            key      : 'to_date',
            title    : 'Run Finish Date',
            dataIndex: 'to_date',
            width    : 150,
            sorter   : true,
            ellipsis : true,
          },
          {
            key      : 'start_template',
            title    : 'Starting Template',
            dataIndex: 'start_template',
            width    : 180,
            sorter   : true,
            ellipsis : true,
          },
          {
            key      : 'last_template',
            title    : 'Latest Template Run',
            dataIndex: 'last_template',
            width    : 180,
            sorter   : true,
            ellipsis : true,
          },
          {
            key      : 'end_template',
            title    : 'Estimated Ending Template',
            dataIndex: 'end_template',
            width    : 220,
            sorter   : true,
            ellipsis : true,
          },
          {
            key      : 'is_direct',
            title    : 'Per User Optimization',
            dataIndex: 'is_direct',
            width    : 200,
            render   : (is_direct) => is_direct ? 'At the same time' : 'By subscriber time zone',
            sorter   : true,
            ellipsis : true,
          },
          {
            key      : 'active',
            title    : 'Status',
            dataIndex: 'active',
            render   : (active) => active ? 'Active' : 'Suspended',
            sorter: true,
          },
          {
            key      : 'created_at',
            title    : 'Created at',
            dataIndex: 'created_at',
            width    : 180,
            sorter   : true,
            ellipsis : true,
          },
          {
            key      : 'updated_at',
            title    : 'Updated at',
            dataIndex: 'updated_at',
            width    : 180,
            sorter   : true,
            ellipsis : true,
          },
          {
            key      : 'actions',
            title    : 'Actions',
            align    : 'center',
            fixed    : 'right',
            width    : 60,
            render   : (row) => <ActionCell row={row}/>,
          },
        ]
      }, []);

    return {
      handleTableChange,
      onPageChanges,
      columns,
      fetchingState,
      automationsData,
      pageNumber,
      pageSize,
      total,
    }
  }
