import React from 'react';
import {YMInitializer} from 'react-yandex-metrika';

const ymAccount = process.env.REACT_APP_YM_ACCOUNT;
if (!ymAccount) {
  console.error('Missing yandex metrika account');
}

export function YMProvider(
  {
    children
  }: {
    children: React.ReactNode;
  }
) {
  return (
    <>
      {children}
      <YMInitializer
        version='2'
        options={{
          webvisor           : true,
          clickmap           : true,
          trackLinks         : true,
          accurateTrackBounce: true,
        }}
        accounts={[Number(ymAccount)]}
      />
    </>
  )
}