import React from 'react';
import {DisplayLimit} from './DisplayLimit';
import {LimitsNotice} from './LimitsNotice';
import {SubscribersLimits} from './SubscribersLimits';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  alertWrapper: {
    top     : 0,
    left    : 0,
    zIndex  : 1500,
    width   : '100%',
    position: 'fixed',
  }
}));

export function LimitsAlerts(
  {
    matchPath,
  }: {
    matchPath: string | string[];
  }
) {
  const classes = useStyles();

  return (
    <div
      className={classes.alertWrapper}
    >
      <DisplayLimit
        matchPath={matchPath}
      />
      <LimitsNotice
        matchPath={matchPath}
      />
      <SubscribersLimits
        matchPath={matchPath}
      />
    </div>
  )
}
