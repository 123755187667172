import React from 'react';
import {useTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import {formatBytes} from '../../../../../../utils/formatBytes';

export default function Limits(
  {
    limits
  }: {
    limits?: any
  }
) {
  const {t} = useTranslation();

  if (!limits) {
    return null;
  }

  const t1: any = Object.keys(limits || {}).reduce((acc: any, curr: any) => {
    Object.keys(limits[curr] || {}).forEach((k: any) => {
      acc[`${curr}-${k}`] = limits[curr][k];
    });

    return acc;
  }, {});

  return (
    <table
      cellPadding={12}
      style={{borderCollapse: 'collapse'}}
    >
      <tbody>
      {Object.keys(t1).map((l: any, index: number) => {
        return (
          <tr
            key={index}
          >
            <td>
              <Typography
                align='center'
                style={{
                  lineHeight     : 1,
                  color          : '#717171',
                  backgroundColor: '#eaeaea',
                  padding        : '5px 8px 3px',
                  border         : '1px solid #c8c8c8',
                }}
              >
                {
                  (() => {
                    if ([
                      'users-per_account_max_image_size',
                      'users-per_account_max_storage_size',
                    ].includes(l)) {
                      return formatBytes(t1[l]);
                    } else {
                      return t1[l]
                    }
                  })()
                }
              </Typography>
            </td>
            <td>{t(`acl.${l}`)}</td>
          </tr>
        )
      })}
      </tbody>
    </table>
  )
}