import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import ScheduleCard from '../../ScheduleCard';
import {IObject} from '../../../types';
import {updateEntityAction} from '../../../store/actions/campaigns';

export default React.memo((
  {
    errors,
    entity,
    disabled,
    removeError,
  }: {
    entity: IObject;
    errors?: IObject;
    removeError?: any;
    disabled?: boolean;
  }
) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const scheduleCardLabels = React.useMemo(() => ({
    ttl             : t('campaigns.ttl'),
    schedule        : t('campaigns.send_schedule'),
    ttl_helper_text : t('campaigns.ttl_helper_text'),
    wait_before_send: t('campaigns.wait_before_send'),
  }), [
    t,
  ]);

  const updateEntity = React.useCallback((inputName: string, value: any) => {
    dispatch(updateEntityAction({
      [inputName]: value,
    }))
    if (typeof removeError === 'function') {
      removeError(inputName);
    }
  }, [
    dispatch,
    removeError,
  ]);

  return (
    <ScheduleCard
      errors={errors}
      entity={entity}
      disabled={disabled}
      onChange={updateEntity}
      labels={scheduleCardLabels}
    />
  )
})

