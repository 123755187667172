import {Card} from 'antd';
import React from 'react';
import {Alert} from 'antd';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {urlHelper} from '../../../../utils/urlHelper';
import {AppRoutes} from '../../../../constants/appRoutes';
import {Link} from '../../../../components/antd/Typography';
import {Text} from '../../../../components/antd/Typography';
import * as cp from '../SimpleCode/components/Codespinnets';
import {_cdnUrl} from '../../../../store/selectors/application/acl';

export function GoogleTagManager() {
  const cdnUrl = useSelector(_cdnUrl);
  const {appUuid} = useParams<{ appUuid: string }>();

  return (
    <>
      <Card
        bordered={false}
        title='2. Access Google Tag Manager'
        className='create-app-card m-b-2'
      >
        <Text
          className='m-b-2 d-block'
        >Use the Google Tag Manager (GTM) to insert the SmartPush javascript code into your website. If you are a HTTPS user, there is an additional step to take to integrate SmartPush into your website.
        </Text>
        <ol
          className='document-list'
        >
          <li
          ><Text>Go to <Link target='_blank' href='https://tagmanager.google.com.'>https://tagmanager.google.com.</Link></Text>
          </li>
          <li
          ><Text>Create an account.</Text>
          </li>
          <li
          >Enter your website details and sign in.
          </li>
          <li>
            <Text
              className='m-b-2'
            >In the Workspace section, click <Text fontWeight='bold'>Add a new tag.</Text>
            </Text>
            <div>
              <img
                width='100%'
                alt='add new tag'
                className='ant-card-bordered'
                src='https://cdnjam.com/cdn/doc/gtm-doc-8.png'
              />
            </div>
          </li>
          <li>
            <Text
              className='m-b-2'
            >Provide your tag name.
            </Text>
            <div>
              <img
                width='100%'
                alt='provide your tag name'
                className='ant-card-bordered'
                src='https://cdnjam.com/cdn/doc/gtm-doc-12.png'
              />
            </div>
          </li>
          <li>
            <Text
              className='m-b-2'
            >Click <Text fontWeight='bold'>Tag Configuration.</Text>
            </Text>
            <div>
              <img
                width='100%'
                alt='click tag configuration'
                className='ant-card-bordered'
                src='https://cdnjam.com/cdn/doc/gtm-doc-6.png'
              />
            </div>
          </li>
          <li>
            <Text
              className='m-b-2'
            >Select <Text fontWeight='bold'>Custom HTML.</Text>
            </Text>
            <div>
              <img
                width='100%'
                alt='select custom html'
                className='ant-card-bordered'
                src='https://cdnjam.com/cdn/doc/gtm-doc-2.png'
              />
            </div>
          </li>
          <li>
            <Text
              className='m-b-2'
            >Copy the following script:
            </Text>
            <Text
              code
              className='d-flex align-items-flex-start custom-copyable'
              copyable={{
                text: urlHelper(cp.codespinnet, {CDN_URL: cdnUrl, APP_UUID: appUuid}),
              }}
            >{urlHelper(cp.codespinnet, {CDN_URL: cdnUrl, APP_UUID: appUuid})}
            </Text>
          </li>
          <li>
            <Text
              className='m-b-2'
            >Go back to the <Text italic>Custom HTML</Text> section of your tag manager and paste the script
            </Text>
            <div>
              <img
                width='100%'
                alt='go back to custom html'
                className='ant-card-bordered'
                src='https://cdnjam.com/cdn/doc/gtm-doc-1.png'
              />
            </div>
          </li>
          <li>
            <Text
              className='m-b-2'
            >Scroll down and click <Text fontWeight='bold'>Advanced Settings.</Text> Make sure Tag firing options is set to <Text fontWeight='bold'>Once per page</Text>.
            </Text>
            <div>
              <img
                width='100%'
                alt='advanced settings'
                className='ant-card-bordered'
                src='https://cdnjam.com/cdn/doc/gtm-doc-7.png'
              />
            </div>
          </li>
          <li>
            <Text
              className='m-b-2'
            >Scroll down the page and click <Text fontWeight='bold'>Triggering.</Text>
            </Text>
            <div>
              <img
                width='100%'
                className='ant-card-bordered'
                src='https://cdnjam.com/cdn/doc/gtm-doc-3.png'
                alt='scroll down the page and click triggering'
              />
            </div>
          </li>
          <li>
            <Text
              className='m-b-2'
            >Select the <Text fontWeight='bold'>All pages</Text> checkbox and click <Text fontWeight='bold'>Add.</Text>
            </Text>
            <div>
              <img
                width='100%'
                alt='select all'
                className='ant-card-bordered'
                src='https://cdnjam.com/cdn/doc/gtm-doc-9.png'
              />
            </div>
          </li>
          <li>
            <Text
              className='m-b-2'
            >Check whether or not your website ID is correctly inserted into the code.
            </Text>
            <div>
              <img
                width='100%'
                className='ant-card-bordered'
                src='https://cdnjam.com/cdn/doc/gtm-doc-5.png'
                alt='check whether or not your website id is correctly inserted'
              />
            </div>
          </li>
          <li>
            <Text
              className='m-b-2'
            >Click <Text fontWeight='bold'>Save</Text> to save the changes.
            </Text>
          </li>
          <li>
            <Text
              className='m-b-2'
            >To publish the changes on your live website, click <Text fontWeight='bold'>Submit</Text> and then <Text fontWeight='bold'>Publish.</Text>
            </Text>
            <div>
              <img
                width='100%'
                alt='publish'
                className='ant-card-bordered'
                src='https://cdnjam.com/cdn/doc/gtm-doc-4.png'
              />
            </div>
          </li>
        </ol>
        <Alert
          showIcon
          type='success'
          message={<Text><Text fontWeight='bold'>Congrats!</Text> You successfully installed the javascript code on your site.</Text>}
        />
      </Card>
      <Card
        bordered={false}
        className='create-app-card m-b-2'
        title='3. Workflow for HTTPS users'
      >
        <div
          className='m-b-2'
        >
          <Text
          >After installing the javascript code, you’ll need to install a service worker in the root directory of your website for HTTPS users. Follow the <Link target='_blank' href={urlHelper(AppRoutes.DOCUMENTATION, {appUuid})}>Upload SmartPush Worker</Link> section to finalize the installation.
          </Text>
        </div>
        <Alert
          showIcon
          type='success'
          message={<Text><Text fontWeight='bold'>Congratulations!</Text> You have successfully integrated SmartPush into your Website!</Text>}
        />
      </Card>
    </>
  )
}