export function formatBytes(bytes: number, decimals: number = 0) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
}

export function formatBytesToObject(bytes: number, decimals: number = 0): {
  size: string;
  number: number;
} {
  if (bytes === 0) return {
    number: 0,
    size  : 'Bytes',
  };

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    size  : sizes[i],
    number: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
  }
}