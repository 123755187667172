import {Globals} from '../../../types/globals';
import {blankFeatures} from '../../../constants';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {_upsertSubscriptionsAndBilling} from './index';
import {FiniteStates} from '../../../constants/finiteStates';

export const _downgrade = (
  {
    redirect,
    formValues,
  }: {
    redirect?: () => void;
    formValues: Record<string, any>;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_upsertSubscriptionsAndBilling({
    downgradeState: FiniteStates.LOADING,
  }));

  const {
    hasError,
    body: {
      data,
      message
    }
  } = await api.post(ApiRoutes.UPDATE_SUBSCRIPTION, {
    interval      : formValues.interval,
    plan_slug     : formValues.plan_slug,
    payment_method: formValues.payment_method,
  });

  if (!hasError) {
    dispatch(_upsertSubscriptionsAndBilling({
      showDowngradeModal: void 0,
      downgradeState    : FiniteStates.SUCCESS,
    }))

    if (data.need_interaction && data.hosted_invoice_url) {
      window.open(data.hosted_invoice_url, '_blank', blankFeatures);
      redirect && redirect();
    } else {
      redirect && redirect();
    }
  } else {
    dispatch(_upsertSubscriptionsAndBilling({
      showDowngradeModal: void 0,
      downgradeState    : FiniteStates.FAILURE,
      downgradeErrors   : {
        message,
        errors: Object.values(data || {}),
      }
    }))
  }
};
