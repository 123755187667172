import React from 'react';
import {Text} from '../../../../../../components/antd/Typography';

export function DateTime({value}: { value: string }) {
  return (
    <Text
      ellipsis
    >{value}
    </Text>
  )
}