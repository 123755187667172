import {_dataOptions} from './index';
import {_upsertEntity} from './index';
import ym from 'react-yandex-metrika';
import {Globals} from '../../../types/globals';
import {dataLayer} from '../../../utils/dataLayer';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';

export const _skipForNow = (
  {
    appUuid,
    redirect,
    disableIntegrationSkip,
  }: {
    appUuid: string;
    redirect?: () => void;
    disableIntegrationSkip?: boolean;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    savingStep: true,
  }));

  const {
    body: {
      data
    },
    hasError,
  } = await api.post(urlHelper(ApiRoutes.SAVE_STEP, {
    appUuid,
  }), {
    creation_step    : 2,
    integration_state: 2,
  });

  if (!hasError) {
    if (!disableIntegrationSkip) {
      dataLayer({
        app_uuid: appUuid,
        event   : 'integration-skipped',
      });
      try {
        await ym('reachGoal', 'integration-skipped', {
          app_uuid: appUuid,
        });
      } catch (e) {
        console.error(e)
      }
    }

    dispatch(_upsertEntity(data))
  }

  if (typeof redirect === 'function') {
    redirect();
  }

  dispatch(_dataOptions({
    savingStep: void 0,
  }));
};
