import React from 'react';
import {getParams} from '../utils';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {privatePrefix} from '../constants';
import {useTranslation} from 'react-i18next';
import {AppRoutes} from '../constants/appRoutes';
import {_init} from '../store/actions/application/init';
import {_user} from '../store/selectors/application/acl';
import {_isAuthenticated} from '../store/selectors/application';

export function useSmartPushProvider() {
  const history = useHistory();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(_user);
  const isAuthenticated = useSelector(_isAuthenticated);

  const {
    appUuid
  } = getParams<{ appUuid: string }>(history.location.pathname, {
    path: `/${privatePrefix}/:appUuid`,
  });

  React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(_init({
        appUuid,
        history,
      }));
    } else {
      history.replace(AppRoutes.LOGIN)
    }
  }, [
    t,
    history,
    appUuid,
    dispatch,
    isAuthenticated,
  ]);

  return {
    user,
    appUuid,
    isAuthenticated,
  }
}
