import clsx from 'clsx';
import React from 'react';
import Limits from './Limits';
import Permissions from './Permissions';
import {MouseEventHandler} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Card} from '../../../../../components/Card';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {CardHeader} from '../../../../../components/CardHeader';

const useStyles = makeStyles((theme: Theme) => ({
  selectable           : {
    height: '100%',
  },
  cardHeaderContent    : {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'space-between',
  },
  nestedCard           : {
    width       : 400,
    minWidth    : 400,
    overflow    : 'unset',
    marginBottom: theme.spacing(4),
    marginLeft  : theme.spacing(1.5),
    marginRight : theme.spacing(1.5),
  },
  nestedTitleTypography: {
    fontSize  : 16,
    fontWeight: 'bold',
    color     : '#ffffff',
  },
  nestedCardHeaderRoot : {
    padding                       : theme.spacing(2, 3),
    backgroundColor               : theme.palette.secondary.dark,
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  nestedCardContentRoot: {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  selectedCard         : {
    backgroundColor: theme.palette.primary.main,
  },
  nonSelected          : {
    outline  : '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0), 0px 3px 4px 0px rgba(0,0,0,0), 0px 1px 8px 0px rgba(0,0,0,0)'
  },
}));

export default function SubscriptionPlan(
  {
    plan,
    selected,
    onChange,
  }: {
    plan: any;
    selected: boolean;
    onChange?: MouseEventHandler<HTMLDivElement>;
  }
) {
  const classes = useStyles();
  const limits: any = plan.limits;
  const details: any = plan.details;

  return (
    <Card
      elevation={3}
      variant='elevation'
      className={clsx(classes.nestedCard, !selected && classes.nonSelected)}
    >
      <div
        data-id={plan.id}
        onClick={onChange}
        data-plan={plan.slug}
        data-name='pending_plan'
        className={classes.selectable}
      >
        <CardHeader
          title={plan.name}
          color={selected ? 'primary' : 'disabled'}
        />
        <CardContent
          classes={{root: classes.nestedCardContentRoot}}
        >
          <Permissions
            permissions={details}
          />
          <Limits
            limits={limits}
          />
        </CardContent>
      </div>
    </Card>
  )
}