import React from 'react';
import {useParams, useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {Input, Button, Row, Tooltip} from 'antd';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {getPrefixCls} from '../../../../../../utils/getPrefixCls';
import {_fetch} from '../../../../../../store/actions/automation/fetch';
import {_dataOptions} from '../../../../../../store/actions/automation/actions';
import {FiniteStates} from '../../../../../../constants/finiteStates';
import {_fetchingAutomations, _searchText} from '../../../../../../store/selectors/automation';
import {urlHelper} from '../../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../../constants/appRoutes';
import {useSmartPush} from '../../../../../../hooks/useSmartPush';
import {aclKeys} from '../../../../../../constants/aclKeys';
import './index.less';

const {Search} = Input;
const initialPageNumber = 0;

const TableHeader: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {appUuid} = useParams<{ appUuid: string }>();
  const searchText = useSelector(_searchText);
  const automationState = useSelector(_fetchingAutomations);
  const {
    user: {
      canCreate,
    }
  } = useSmartPush();

  const onReload = () => {
    dispatch(_fetch(appUuid));
  };

  const onSearch = (value: string) => {
    dispatch(_dataOptions({
      searchText: value,
      pageNumber: initialPageNumber,
    }));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      dispatch(_dataOptions({
        searchText: void 0,
        pageNumber: initialPageNumber,
      }));
    }
  };

  return (
    <div className={`d-flex align-items-center justify-content-between ${getPrefixCls('table-header')}`}>
      <Row wrap={false}>
        <Search
          onChange={onChange}
          onSearch={onSearch}
          placeholder='Search by Name'
          defaultValue={searchText}
          className={getPrefixCls('table-header-search')}
          disabled={automationState === FiniteStates.LOADING}
        />
        {canCreate(aclKeys.AUTOMATIONS) &&
          <Button
            type='primary'
            className='m-l-1'
            onClick={() => history.push(urlHelper(AppRoutes.AUTOMATION_CREATE, {appUuid}))}
            icon={<PlusOutlined/>}
            children={'Create Automation'}
          />
        }
      </Row>
      <Tooltip title='Refresh' placement='left'>
        <Button
          shape='circle'
          onClick={onReload}
          icon={<ReloadOutlined style={{color: '#0000008a'}}/>}
          disabled={automationState === FiniteStates.LOADING}
        />
      </Tooltip>
    </div>
  )
};

export default TableHeader;
