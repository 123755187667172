const SmartPushApiPrefix = 'webnotify';

export const ApiRoutes = {
  EMAIL_CHANGE              : '/email',
  OAUTH                     : '/oauth',
  ME                        : '/auth/me',
  UPDATE_USER_PROFILE       : '/profile',
  PASSWORD_RESET            : '/password',
  REGISTER                  : '/register',
  APP_SUMO_REGISTER         : '/appsumo/claim',
  APP_SUMO_CHECK_TOKEN      : '/appsumo/check',
  LOGIN                     : '/auth/login',
  LOGOUT                    : '/auth/logout',
  OAUTH_COMPLETE            : '/oauth/complete',
  PASSWORD_RESET_TOKEN      : '/password/reset',
  UPDATE_PASSWORD           : '/password/change',
  RESEND_REGISTRATION_EMAIL : '/register/resend',
  CONFIRM_REGISTRATION      : '/register/confirm',
  PROFILE_TIMEZONES         : '/profile/timezones',
  RESEND_EMAIL_CHANGE       : '/profile/resend-email-change',
  CANCEL_EMAIL_CHANGE       : '/profile/cancel-email-change',
  SURVEY                    : `/${SmartPushApiPrefix}/survey`,
  DELIVERED_MESSAGES        : `/${SmartPushApiPrefix}/sidebar`,
  CHECK_PASSWORD_RESET_TOKEN: '/password/check/:token/:email',

  LANGUAGES: `/${SmartPushApiPrefix}/global/languages`,
  COUNTRIES: `/${SmartPushApiPrefix}/global/countries`,

  // Campaigns
  LIST_CAMPAIGNS      : `/${SmartPushApiPrefix}/:appUuid/campaigns`,
  CREATE_CAMPAIGN     : `/${SmartPushApiPrefix}/:appUuid/campaigns`,
  CREATE_SEND_CAMPAIGN: `/${SmartPushApiPrefix}/:appUuid/campaigns/send`,
  GET_CAMPAIGN        : `/${SmartPushApiPrefix}/:appUuid/campaigns/:uuid`,
  UPDATE_CAMPAIGN     : `/${SmartPushApiPrefix}/:appUuid/campaigns/:uuid`,
  DELETE_CAMPAIGN     : `/${SmartPushApiPrefix}/:appUuid/campaigns/:uuid`,
  SEND_CAMPAIGN       : `/${SmartPushApiPrefix}/:appUuid/campaigns/:uuid/send`,
  GET_CAMPAIGN_STATS  : `/${SmartPushApiPrefix}/:appUuid/campaigns/:uuid/stats`,
  CANCEL_CAMPAIGN     : `/${SmartPushApiPrefix}/:appUuid/campaigns/:uuid/cancel`,

  // Apps
  LIST_APPS          : `/${SmartPushApiPrefix}/apps`,
  CREATE_APP         : `/${SmartPushApiPrefix}/apps`,
  GET_APP            : `/${SmartPushApiPrefix}/apps/:appUuid`,
  UPDATE_APP         : `/${SmartPushApiPrefix}/apps/:appUuid`,
  DELETE_APP         : `/${SmartPushApiPrefix}/apps/:appUuid`,
  SAVE_STEP          : `/${SmartPushApiPrefix}/apps/:appUuid/step`,
  TOGGLE_SUBSCRIPTION: `/${SmartPushApiPrefix}/apps/:appUuid/enable`,
  DOCS_DOWNLOAD      : `/${SmartPushApiPrefix}/:appUuid/docs/download/:guide`,
  SEND_TO_DEVELOPER  : `/${SmartPushApiPrefix}/:appUuid/docs/send-to-developer`,

  // Welcome Message
  CREATE_WELCOME_MESSAGE: `/${SmartPushApiPrefix}/:appUuid/campaigns/welcome`,
  UPDATE_WELCOME_MESSAGE: `/${SmartPushApiPrefix}/:appUuid/campaigns/:uuid/welcome`,
  TOGGLE_WELCOME_MESSAGE: `/${SmartPushApiPrefix}/:appUuid/campaigns/:uuid/toggle-welcome`,
  TIMEZONES             : `/${SmartPushApiPrefix}/:appUuid/profile/timezones`,

  // Dashboard stats
  EPC_STATS                : `/${SmartPushApiPrefix}/:appUuid/stats/epc`,
  CTR_STATS                : `/${SmartPushApiPrefix}/:appUuid/stats/ctr`,
  TOTAL_STATS              : `/${SmartPushApiPrefix}/:appUuid/stats/total`,
  EPPDM_STATS              : `/${SmartPushApiPrefix}/:appUuid/stats/eppdm`,
  CLICK_STATS              : `/${SmartPushApiPrefix}/:appUuid/stats/clicks`,
  CLOSE_STATS              : `/${SmartPushApiPrefix}/:appUuid/stats/closes`,
  REVENUE_STATS            : `/${SmartPushApiPrefix}/:appUuid/stats/revenue`,
  DISPLAY_STATS            : `/${SmartPushApiPrefix}/:appUuid/stats/displays`,
  DAILY_INACTIVE           : `/${SmartPushApiPrefix}/:appUuid/stats/inactive`,
  SUBSCRIBED_STATS         : `/${SmartPushApiPrefix}/:appUuid/stats/subscribed`,
  UNSUBSCRIBED_STATS       : `/${SmartPushApiPrefix}/:appUuid/stats/unsubscribed`,
  PERIOD_UNSUBSCRIBED_STATS: `/${SmartPushApiPrefix}/:appUuid/stats/period-unsubscribed`,

  // Templates
  LIST_TEMPLATES      : `/${SmartPushApiPrefix}/:appUuid/templates`,
  CREATE_TEMPLATE     : `/${SmartPushApiPrefix}/:appUuid/templates`,
  TEMPLATES_TAGS      : `/${SmartPushApiPrefix}/:appUuid/templates/tags`,
  GET_TEMPLATE        : `/${SmartPushApiPrefix}/:appUuid/templates/:uuid`,
  UPDATE_TEMPLATE     : `/${SmartPushApiPrefix}/:appUuid/templates/:uuid`,
  DELETE_TEMPLATE     : `/${SmartPushApiPrefix}/:appUuid/templates/:uuid`,
  BUILD_TEMPLATE      : `/${SmartPushApiPrefix}/:appUuid/builders/save-template`,
  DELETE_TEMPLATE_STAT: `/${SmartPushApiPrefix}/:appUuid/templates/:uuid/:cUuid`,
  GET_TEMPLATE_DETAILS: `/${SmartPushApiPrefix}/:appUuid/templates/:id/campaigns`,

  // Segments
  LIST_SEGMENTS        : `/${SmartPushApiPrefix}/:appUuid/segments`,
  CREATE_SEGMENT       : `/${SmartPushApiPrefix}/:appUuid/segments`,
  UPDATE_SEGMENT       : `/${SmartPushApiPrefix}/:appUuid/segments/:id`,
  GET_SEGMENT          : `/${SmartPushApiPrefix}/:appUuid/segments/:id`,
  DELETE_SEGMENT       : `/${SmartPushApiPrefix}/:appUuid/segments/:id`,
  GET_SEGMENT_PARAMS   : `/${SmartPushApiPrefix}/:appUuid/segments/params`,
  GET_SEGMENT_COUNTRIES: `/${SmartPushApiPrefix}/:appUuid/segments/countries`,
  GET_SEGMENT_CONSTANTS: `/${SmartPushApiPrefix}/:appUuid/segments/constants`,
  GET_SEGMENT_LANGUAGES: `/${SmartPushApiPrefix}/:appUuid/segments/languages`,
  CHECK_SEGMENTS       : `/${SmartPushApiPrefix}/:appUuid/segments/check:list`,

  // Subscribers
  LIST_SUBSCRIBERS            : `/${SmartPushApiPrefix}/:appUuid/subscribers`,
  GET_SUBSCRIBER              : `/${SmartPushApiPrefix}/:appUuid/subscribers/:uuid`,
  SET_AS_TEST_SUBSCRIBERS     : `/${SmartPushApiPrefix}/:appUuid/subscribers/:uuid/test`,
  REMOVE_FROM_TEST_SUBSCRIBERS: `/${SmartPushApiPrefix}/:appUuid/subscribers/:uuid/test`,
  DELETE_SUBSCRIBERS          : `/${SmartPushApiPrefix}/:appUuid/subscribers/:uuid/archive`,

  // Automation
  LIST_AUTOMATIONS : `/${SmartPushApiPrefix}/:appUuid/automations`,
  CREATE_AUTOMATION: `/${SmartPushApiPrefix}/:appUuid/automations`,
  GET_AUTOMATION   : `/${SmartPushApiPrefix}/:appUuid/automations/:id`,
  UPDATE_AUTOMATION: `/${SmartPushApiPrefix}/:appUuid/automations/:id`,
  DELETE_AUTOMATION: `/${SmartPushApiPrefix}/:appUuid/automations/:id`,
  TOGGLE_AUTOMATION: `/${SmartPushApiPrefix}/:appUuid/automations/:id/activate`,

  // Builder
  LIST_ICONS : `/${SmartPushApiPrefix}/:appUuid/builders/icons`,
  DELETE_ICON: `/${SmartPushApiPrefix}/:appUuid/builders/icons/:id`,
  CREATE_ICON: `/${SmartPushApiPrefix}/:appUuid/builders/icons`,
  UPDATE_ICON: `/${SmartPushApiPrefix}/:appUuid/builders/icons/:id`,

  LIST_IMAGES : `/${SmartPushApiPrefix}/:appUuid/builders/images`,
  DELETE_IMAGE: `/${SmartPushApiPrefix}/:appUuid/builders/images/:id`,
  CREATE_IMAGE: `/${SmartPushApiPrefix}/:appUuid/builders/images`,
  UPDATE_IMAGE: `/${SmartPushApiPrefix}/:appUuid/builders/images/:id`,

  LIST_TITLES : `/${SmartPushApiPrefix}/:appUuid/builders/titles`,
  DELETE_TITLE: `/${SmartPushApiPrefix}/:appUuid/builders/titles/:id`,
  CREATE_TITLE: `/${SmartPushApiPrefix}/:appUuid/builders/titles`,
  UPDATE_TITLE: `/${SmartPushApiPrefix}/:appUuid/builders/titles/:id`,

  LIST_MESSAGES : `/${SmartPushApiPrefix}/:appUuid/builders/messages`,
  DELETE_MESSAGE: `/${SmartPushApiPrefix}/:appUuid/builders/messages/:id`,
  CREATE_MESSAGE: `/${SmartPushApiPrefix}/:appUuid/builders/messages`,
  UPDATE_MESSAGE: `/${SmartPushApiPrefix}/:appUuid/builders/messages/:id`,

  BUILDER_TAGS: `/${SmartPushApiPrefix}/:appUuid/builders/tags`,

  // Users
  LIST_USERS        : `/users`,
  CREATE_USER       : `/users`,
  GET_USER          : `/users/:uuid`,
  UPDATE_USER       : `/users/:uuid`,
  ARCHIVE_USER      : `/users/:uuid/archive`,
  RESTORE_USER      : `/users/:uuid/restore`,
  FEATURE_GROUPS    : `/admin/features/groups`,
  SUBSCRIPTION_PLANS: `/admin/subscription-plans`,
  GET_PERMISSIONS   : `/users/permissions/webnotify`,

  //API Tokens
  LIST_API_TOKENS     : `/api-tokens`,
  CREATE_API_TOKEN    : `/api-tokens`,
  GET_API_TOKEN       : `/api-tokens/:id`,
  UPDATE_API_TOKEN    : `/api-tokens/:id`,
  DELETE_API_TOKEN    : `/api-tokens/:id`,
  REGENERATE_API_TOKEN: `/api-tokens/:id/regenerate`,

  // Prompts
  GET_PROMPT   : `/${SmartPushApiPrefix}/:appUuid/prompt`,
  CREATE_PROMPT: `/${SmartPushApiPrefix}/:appUuid/prompt`,
  TOGGLE_PROMPT: `/${SmartPushApiPrefix}/:appUuid/prompt/enable`,

  PORTAL                             : '/profile/portal',
  PUBLIC_SUBSCRIPTION_PLANS          : '/subscription-plans',
  SETUP_INTENT                       : '/stripe/setup-intent',
  SKIP_CHECKOUT                      : '/stripe/checkout-skip',
  CREATE_PAYMENT_METHOD              : '/stripe/payment-method',
  CANCEL_SCHEDULE                    : '/stripe/cancel-schedule',
  UPDATE_SUBSCRIPTION                : '/stripe/update-subscription',
  SUBSCRIPTION_UPDATE_PRORATION_TOTAL: '/stripe/subscription-update-proration-total',
  UPDATE_PAYMENT_METHOD              : '/stripe/payment-method/:stripePaymentMethodId',
}
