import {notification} from 'antd';
import {_setPortal} from './index';
import {Globals} from '../../../types/globals';
import {_countries} from '../../selectors/locales';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _fetchPortal = (additionalData?: Record<string, any>): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_setPortal({
    loading          : true,
    portalFiniteState: FiniteStates.LOADING
  }));

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.get(ApiRoutes.PORTAL);

  if (hasError) {
    notification.error({message});

    dispatch(_setPortal({
      loading          : void 0,
      portalFiniteState: FiniteStates.FAILURE
    }));
  } else {
    if (data.payment_method) {
      const countries = _countries(getState());
      const country = countries.find((country: Record<string, any>) => {
        return country.iso === data.payment_method.billing_address.address.country.toLowerCase()
      });
      let state = data.payment_method.billing_address.address.state;
      if (country?.iso.toLowerCase() === 'us') {
        state = {
          label: state,
          value: state,
        }
      }

      dispatch(_setPortal({
        ...data,
        payment_method   : {
          ...data.payment_method,
          billing_address: {
            ...data.payment_method.billing_address,
            address: {
              ...data.payment_method.billing_address.address,
              state,
              country: {
                value: country?.iso,
                label: country?.text,
              },
            }
          }
        },
        loading          : void 0,
        portalFiniteState: FiniteStates.SUCCESS,
        ...additionalData
      }));
    } else {
      dispatch(_setPortal({
        ...data,
        loading          : void 0,
        portalFiniteState: FiniteStates.SUCCESS,
        ...additionalData
      }));
    }
  }
};
