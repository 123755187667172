import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';

const useStyles = makeStyles(() => ({
  toolTipUl      : {
    paddingLeft: 8,
    margin     : 0,
    fontSize   : 12,
  },
  toolTipLi      : {
    fontSize  : 14,
    fontWeight: 500,
  },
  spanContainers : {
    display   : 'flex',
    alignItems: 'baseline',
  },
  includeIcon    : {
    fontSize: 15,
    color   : '#3AAB60'
  },
  excludeIcon    : {
    fontSize: 15,
    color   : '#DE0000'
  },
  typographyEmpty: {
    display: 'table',
    margin : '0 auto',
  },
  tableUl        : {
    listStyle  : 'none',
    paddingLeft: 8,
    margin     : 0
  },
  tableLi        : {
    fontWeight: 'bold',
    fontSize  : 12
  },
  typographyAll  : {
    fontSize  : 12,
    fontWeight: 'bold',
  },
}));

export default function FrontDataFormatter(
  {
    row,
    value,
  }: DataTypeProvider.ValueFormatterProps
) {
  const classes = useStyles();

  let exclude_segments: any;
  let include_segments: any;

  if (value) {
    if (value.exclude_segments) {
      exclude_segments = value.exclude_segments;
    }
    if (value.include_segments) {
      include_segments = value.include_segments;
    }
  }


  return (
    <>
      {(include_segments && (include_segments.length !== 0) && Array.isArray(include_segments)) && (
        <Typography
          component='span'
          className={classes.spanContainers}
        >
          <Tooltip
            title='Include segments'
          >
            <CheckCircleOutlineRoundedIcon
              className={classes.includeIcon}
            />
          </Tooltip>
          <Tooltip
            title={include_segments.map((item: any) => (
              <li
                className={classes.toolTipLi}
                key={`CampaignsListIncludeSegments_${item.label}${Math.random()}`}
                style={{listStyle: include_segments.length > 1 ? 'circle' : 'none'}}
              >{item.label}
              </li>
            ))}
          >
            <Typography
              noWrap
              component='ul'
              className={classes.tableUl}
            >
              {include_segments.slice(0, 3).map((item: any) => (
                <Typography
                  noWrap
                  component='li'
                  className={classes.tableLi}
                  key={`CampaignsListIncludeSegments_${item.label}${Math.random()}`}
                >{item.label}
                </Typography>
              ))}
              {((include_segments.length - 3) > 0) && (
                <Typography
                  noWrap
                  component='li'
                  className={classes.tableLi}
                  style={{color: 'rgb(239, 69, 38)'}}
                >+{include_segments.length - 3}
                </Typography>
              )}
            </Typography>
          </Tooltip>
        </Typography>
      )}
      {(exclude_segments && (exclude_segments.length !== 0) && Array.isArray(exclude_segments)) && (
        <Typography
          component='span'
          className={classes.spanContainers}
        >
          <Tooltip
            title='Exclude segments'
          >
            <RemoveCircleOutlineRoundedIcon
              className={classes.excludeIcon}
            />
          </Tooltip>
          <Tooltip
            title={
              exclude_segments.map((item: any) => (
                <li
                  className={classes.toolTipLi}
                  key={`CampaignsListExcludeSegments_${item.label}${Math.random()}`}
                  style={{listStyle: exclude_segments.length > 1 ? 'circle' : 'none'}}
                >{item.label}
                </li>
              ))
            }
          >
            <Typography
              noWrap
              component='ul'
              className={classes.tableUl}
            >
              {exclude_segments.slice(0, 3).map((item: any) => (
                <Typography
                  noWrap
                  component='li'
                  className={classes.tableLi}
                  key={`CampaignsListExcludeSegments_${item.label}${Math.random()}`}
                >{item.label}
                </Typography>
              ))}
              {((exclude_segments.length - 3) > 0) && (
                <Typography
                  noWrap
                  component='li'
                  className={classes.tableLi}
                  style={{color: 'rgb(239, 69, 38)'}}
                >+{exclude_segments.length - 3}
                </Typography>
              )}
            </Typography>
          </Tooltip>
        </Typography>
      )}
      {(() => {
        if ((String(row && row.send_to).toLocaleLowerCase() === 'all')) {
          return (
            <Typography
              align='left'
              component='span'
              className={classes.typographyAll}
            >All
            </Typography>
          )
        } else if (!(include_segments && exclude_segments)) {
          return (
            <Typography
              align='center'
              component='span'
              className={classes.typographyEmpty}
            />
          )
        }
      })()}
    </>
  )
}
