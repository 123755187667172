import React from 'react';
import {IObject} from '../../../types';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import AudienceCard from '../../AudienceCard';
import {updateEntityAction} from '../../../store/actions/campaigns';

export default React.memo((
  {
    entity,
    errors,
    removeError,
  }: {
    entity: IObject;
    errors?: IObject;
    removeError: any;
  }
) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {
    appUuid,
  } = useParams<{ appUuid: string }>();

  const audienceCardLabels = React.useMemo(() => ({
    audience                  : t('campaigns.audience'),
    send_to_subscribed_users  : t('campaigns.send_to_subscribed_users'),
    send_to_users_in_segments : t('campaigns.send_to_users_in_segments'),
    exclude_users_in_segments : t('campaigns.exclude_users_in_segments'),
    send_to_particular_segment: t('campaigns.send_to_particular_segment'),
  }), [
    t,
  ]);

  const updateEntity = React.useCallback((inputName: string, value: any, action?: any) => {
    if (action?.action === 'remove-value') {
      removeError(`${inputName}.${action?.removedValue?.value}`);
    }
    removeError(`include_segments`);
    removeError(`exclude_segments`);
    dispatch(updateEntityAction({
      [inputName]: value,
    }));
  }, [
    dispatch,
    removeError,
  ]);

  return (
    <AudienceCard
      errors={errors}
      entity={entity}
      appUuid={appUuid}
      onChange={updateEntity}
      labels={audienceCardLabels}
    />
  )
})
