import {notification} from 'antd';
import {_updateData} from './actions';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {Globals} from '../../../types/globals';

export const _toggleAction = (appUuid: string, id: number, deleteActiveCampaigns?: string): Globals.ThunkAction => {
  return async (dispatch, getState, { api }) => {

    const {
      hasError,
      body: {
        message,
        data,
      },
    } = await api.get(`${urlHelper(ApiRoutes.TOGGLE_AUTOMATION, {
      id,
      appUuid,
    })}?remove_campaigns=${deleteActiveCampaigns}`);

    notification.open({
      message,
      type: hasError ? 'error' : 'success',
    });

    if (!hasError) {
      dispatch(_updateData(data));
    }
  }
};
