import {_setPortal} from './index';
import {_me} from '../application/me';
import {_saveStep} from '../apps/saveStep';
import {Globals} from '../../../types/globals';
import {ApiRoutes} from '../../../constants/apiRoutes';

export const _skipCheckout = (
  {
    appUuid,
    redirect,
  }: {
    appUuid?: string;
    redirect: () => void;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_setPortal({
    skippingCheckout: true,
  }));

  const {
    hasError,
  } = await api.get(ApiRoutes.SKIP_CHECKOUT);

  if (!hasError) {
    if (appUuid) {
      await Promise.all([
        dispatch(_me()),
        dispatch(_saveStep({
          appUuid,
          creationStep: 2,
        })),
      ]);
    } else {
      await dispatch(_me());
    }
    await redirect();
  }

  dispatch(_setPortal({
    skippingCheckout: false,
  }));
};
