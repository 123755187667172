import clsx from 'clsx';
import {Col} from 'antd';
import {Row} from 'antd';
import React from 'react';
import {Card} from 'antd';
import {Badge} from 'antd';
import {Modal} from 'antd';
import {Alert} from 'antd';
import {Slider} from 'antd';
import {Button} from 'antd';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {nFormatter} from '../../../../../../../utils';
import {formatWithCommas} from '../../../../../../../utils';
import {urlHelper} from '../../../../../../../utils/urlHelper';
import {dataLayer} from '../../../../../../../utils/dataLayer';
import {tidioChat} from '../../../../../../../utils/tidioChatApi';
import {AppRoutes} from '../../../../../../../constants/appRoutes';
import {Text} from '../../../../../../../components/antd/Typography';
import {getPrefixCls} from '../../../../../../../utils/getPrefixCls';
import {Title} from '../../../../../../../components/antd/Typography';
import {_freePlan} from '../../../../../../../store/selectors/portal';
import {_currentApp} from '../../../../../../../store/selectors/apps';
import {_paidPlans} from '../../../../../../../store/selectors/portal';
import {_currentPlan} from '../../../../../../../store/selectors/portal';
import {FiniteStates} from '../../../../../../../constants/finiteStates';
import {_isCustomPlan} from '../../../../../../../store/selectors/portal';
import {_paymentMethod} from '../../../../../../../store/selectors/portal';
import {SPProgress} from '../../../../../../../components/antd/SPProgress';
import {_isAppSumoPlan} from '../../../../../../../store/selectors/portal';
import {_downgradeState} from '../../../../../../../store/selectors/portal';
import {_downgradeErrors} from '../../../../../../../store/selectors/portal';
import {_sidebarData} from '../../../../../../../store/selectors/application';
import {_skippingCheckout} from '../../../../../../../store/selectors/portal';
import {_existingCardErrors} from '../../../../../../../store/selectors/portal';
import {_showDowngradeModal} from '../../../../../../../store/selectors/portal';
import {_userIsFree} from '../../../../../../../store/selectors/application/acl';
import {_maxDisplaysCount} from '../../../../../../../store/selectors/application/acl';
import {_subscribersLimits} from '../../../../../../../store/selectors/application/acl';
import {SPProgressDeliveredMessages} from '../../../../../../../components/antd/SPProgress';
import {_downgrade} from '../../../../../../../store/actions/subscriptionsAndBilling/downgrade';
import {_upsertSubscriptionsAndBilling} from '../../../../../../../store/actions/subscriptionsAndBilling';
import {_downgradeToFree} from '../../../../../../../store/actions/subscriptionsAndBilling/downgradeToFree';
import './index.less';

const mainContainerStyle: React.CSSProperties = {
  maxWidth: 1224,
  margin  : '0 auto',
}

export function ChangePlan() {
  return (
    <div style={mainContainerStyle}>
      <CurrentPlanAndStatistics/>
      <Row
        gutter={8}
        align='stretch'
        className='m-t-2'
      >
        <Col
          lg={8}
          xs={24}
        ><FreePlanCol/>
        </Col>
        <Col
          lg={8}
          xs={24}
        ><PlanSliderCol/>
        </Col>
        <Col
          lg={8}
          xs={24}
        ><CustomPlanCol/>
        </Col>
      </Row>
    </div>
  )
}

function FreePlanCol() {
  const history = useHistory();
  const dispatch = useDispatch();
  const freePlan = useSelector(_freePlan);
  const userIsFree = useSelector(_userIsFree);
  const {appUuid} = useParams<{ appUuid: string }>();
  const downgradeState = useSelector(_downgradeState);
  const skippingCheckout = useSelector(_skippingCheckout);
  const existingCardErrors = useSelector(_existingCardErrors);

  const skipCheckout = React.useCallback(() => {
    return dispatch(_downgradeToFree({
      redirect: () => {
        if (appUuid) {
          history.replace(urlHelper(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP, {appUuid}))
        } else {
          history.replace(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS)
        }
      },
    }));
  }, [
    appUuid,
    history,
    dispatch,
  ]);

  const website = freePlan?.plan_limits?.apps?.per_account_max_count || 0;
  const maxDisplays = freePlan?.plan_limits?.apps?.max_displays_count || 0;
  const maxSubscribers = freePlan?.plan_limits?.apps?.max_subscribers_count || 0;
  const activeCampaigns = freePlan?.plan_limits?.campaigns?.per_app_max_active_count || 0;

  return (
    <div
      className={`${getPrefixCls('change-plan-plan-column')}`}
    >
      <div>
        <div
          className='m-b-5'
        >
          <Title
            level={4}
            fontWeight='bold'
          >Beginner
          </Title>
        </div>
        <Title
          fontWeight='bold'
          className={`${getPrefixCls('change-plan-plan-name')}`}
        >Free
        </Title>
        <div>
          <div
            className={`${getPrefixCls('change-plan-pricing-wrapper')}`}
          >
            <Text
            >$0/mo
            </Text>
          </div>
          <div>
            <Text
            >{nFormatter(maxSubscribers, 0)} Subscribers, {website} Website{website > 1 ? 's' : ''},
            </Text>
            <br/>
            <Text
            >{activeCampaigns} Active Campaign{activeCampaigns > 1 ? 's' : ''}
            </Text>
          </div>
        </div>
      </div>
      <div>
        <div
          className='m-t-5'
        >
          <Text
            fontWeight='bold'
          >{formatWithCommas(maxDisplays)}/mo
          </Text>
          <br/>
          <Text
            fontWeight='bold'
          >Delivered Messages
          </Text>
          {!!existingCardErrors && (
            <Alert
              closable
              type='error'
              className='m-b-2'
              message={existingCardErrors?.message}
              description={existingCardErrors?.errors?.map((error: string) => <Text>{error}</Text>)}
              afterClose={() => dispatch(_upsertSubscriptionsAndBilling({existingCardErrors: void 0}))}
            />
          )}
          <Button
            block
            size='large'
            onClick={skipCheckout}
            loading={skippingCheckout}
            disabled={userIsFree || (downgradeState === FiniteStates.LOADING)}
            className={`${getPrefixCls('change-plan-downgrade-plan-button')}`}
          >{userIsFree ? 'Current plan' : 'Downgrade plan'}
          </Button>
        </div>
      </div>
    </div>
  );
}

function PlanSliderCol() {
  const {push} = useHistory();
  const dispatch = useDispatch();
  const paidPlans = useSelector(_paidPlans);
  const userIsFree = useSelector(_userIsFree);
  const currentPlan = useSelector(_currentPlan);
  const isCustomPlan = useSelector(_isCustomPlan);
  const isAppSumoPlan = useSelector(_isAppSumoPlan);
  const paymentMethod = useSelector(_paymentMethod);
  const {appUuid} = useParams<{ appUuid: string }>();
  const downgradeState = useSelector(_downgradeState);
  const downgradeErrors = useSelector(_downgradeErrors);
  const showDowngradeModal = useSelector(_showDowngradeModal);
  const [sliderValue, setSliderValue] = React.useState<number | undefined>(undefined);

  const downgrade = () => {
    dispatch(_downgrade({
      formValues: {
        interval      : 'month',
        payment_method: paymentMethod?.id,
        plan_slug     : selectedPlan?.plan_slug,
      },
      redirect  : () => {
        dataLayer({
          event               : 'downgrade_click',
          downgrade_click_plan: selectedPlan?.plan_slug,
        });
        window.location.replace(appUuid ? urlHelper(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP, {appUuid}) : AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS)
      }
    }));
  };

  const onCancel = () => {
    if (downgradeState === FiniteStates.LOADING) {
      return;
    }
    dispatch(_upsertSubscriptionsAndBilling({
      showDowngradeModal: void 0,
    }));
  };

  const showModal = () => {
    dispatch(_upsertSubscriptionsAndBilling({
      showDowngradeModal: true,
    }));
  };

  React.useEffect(() => {
    return function () {
      dispatch(_upsertSubscriptionsAndBilling({
        downgradeErrors: void 0,
        downgradeState : FiniteStates.IDLE,
      }));
    }
  }, [
    dispatch,
  ]);

  React.useEffect(() => {
    if (paidPlans.length && currentPlan) {
      const current = paidPlans.find((paidPlan: any) => paidPlan.plan_slug === currentPlan.plan_slug)
      const currentIndex = paidPlans.findIndex((paidPlan: any) => paidPlan.plan_slug === currentPlan.plan_slug)

      if (currentIndex < paidPlans.length - 1) {
        setSliderValue(paidPlans[currentIndex + 1].markPosition)
      } else {
        setSliderValue(current.markPosition);
      }
    }
  }, [
    paidPlans,
    currentPlan,
  ]);

  const marks = paidPlans.reduce((acc: Record<string, any>, curr: Record<string, any>) => {
    acc[curr.markPosition] = formatWithCommas(curr.free_displays_count);
    return acc;
  }, {});

  const selectedPlan = paidPlans.find((paidPlan: any) => {
    return paidPlan.markPosition === sliderValue
  });

  const currentPlanIndex = paidPlans.findIndex((paidPlan: any) => {
    return paidPlan.plan_slug === currentPlan.plan_slug
  });

  const selectedPlanIndex = paidPlans.findIndex((paidPlan: any) => {
    return paidPlan.markPosition === sliderValue
  });

  function onClick() {
    if ((selectedPlanIndex !== -1) && (currentPlanIndex !== -1)) {
      if (selectedPlanIndex < currentPlanIndex) {
        return showModal();
      }
    }

    if (selectedPlan.plan_slug) {
      push(appUuid ? urlHelper(AppRoutes.PAY_AND_ACTIVATE_APP, {
        appUuid,
        plan: selectedPlan.plan_slug,
      }) : urlHelper(AppRoutes.PAY_AND_ACTIVATE, {
        plan: selectedPlan.plan_slug,
      }))
    }
  }

  function getButtonText() {
    if (isCustomPlan || isAppSumoPlan) {
      return 'Select plan';
    }

    if (userIsFree) {
      return 'Upgrade plan';
    }

    if (selectedPlan?.plan_slug === currentPlan.plan_slug) {
      return 'Current plan';
    }

    if ((selectedPlanIndex !== -1) && (currentPlanIndex !== -1)) {
      if (selectedPlanIndex > currentPlanIndex) {
        return 'Upgrade plan';
      }

      if (selectedPlanIndex < currentPlanIndex) {
        return 'Downgrade plan';
      }
    }

    return 'Select plan';
  }

  function getButtonType() {
    if (isCustomPlan || isAppSumoPlan) {
      return 'default';
    }

    if (userIsFree) {
      return 'primary';
    }

    if (selectedPlan?.plan_slug === currentPlan.plan_slug) {
      return 'default';
    }

    if ((selectedPlanIndex !== -1) && (currentPlanIndex !== -1)) {
      if (selectedPlanIndex > currentPlanIndex) {
        return 'primary';
      }

      if (selectedPlanIndex < currentPlanIndex) {
        return 'default';
      }
    }

    return 'primary';
  }

  return (
    <div
      className={`${getPrefixCls('change-plan-plan-column')}`}
    >
      {!!downgradeErrors && (
        <Alert
          showIcon
          closable
          type='error'
          message={downgradeErrors?.message}
          className={`m-b-3 ${getPrefixCls('change-plan-error-alert')}`}
          description={downgradeErrors?.errors?.map((err: string) => <Text>{err}</Text>)}
          afterClose={() => dispatch(_upsertSubscriptionsAndBilling({downgradeErrors: void 0}))}
        />
      )}
      <div>
        <div
          className='m-b-5'
        >
          <Title
            level={4}
            fontWeight='bold'
          >{selectedPlan?.plan_full_name}
          </Title>
        </div>
        <Title
          fontWeight='bold'
          className={`${getPrefixCls('change-plan-plan-name')}`}
        >${selectedPlan?.unit_amount}/mo
        </Title>
        <div>
          <div>
            <Text
            >${selectedPlan?.price_per_1000} per 1,000
            </Text>
            <br/>
            <Text
            >additional delivered messages
            </Text>
          </div>
          <div
            className='m-t-4'
          >
            <Text
            >Unlimited Subscribers,
            </Text>
            <br/>
            <Text
            >Websites, Active Campaigns
            </Text>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`${getPrefixCls('change-plan-slider-wrapper')}`}
        >
          <Slider
            step={null}
            marks={marks}
            value={sliderValue}
            tooltipVisible={false}
            onChange={setSliderValue}
            handleStyle={{borderColor: '#096DD9'}}
            trackStyle={{backgroundColor: '#096DD9'}}
          />
        </div>
        <div
          className='m-t-5'
        >
          <Text
            fontWeight='bold'
          >{formatWithCommas(selectedPlan?.free_displays_count)}/mo
          </Text>
          <br/>
          <Text
            fontWeight='bold'
          >Delivered Messages
          </Text>
          <Button
            block
            size='large'
            onClick={onClick}
            type={getButtonType()}
            disabled={selectedPlan?.plan_slug === currentPlan.plan_slug}
            className={`${getPrefixCls('change-plan-downgrade-plan-button')}`}
          >{getButtonText()}
          </Button>
        </div>
      </div>
      <Modal
        footer={[
          <Button
            key='yesDowngrade'
            onClick={downgrade}
            loading={downgradeState === FiniteStates.LOADING}
          >Yes, downgrade
          </Button>,
          <Button
            key='cancel'
            type='primary'
            onClick={onCancel}
            disabled={downgradeState === FiniteStates.LOADING}
          >Cancel
          </Button>,
        ]}
        onCancel={onCancel}
        title='Downgrade Plan'
        visible={!!showDowngradeModal}
      ><Text>Are you sure you want to downgrade plan?</Text>
      </Modal>
    </div>
  )
}

function CustomPlanCol() {
  const currentPlan = useSelector(_currentPlan);
  const isCustomPlan = useSelector(_isCustomPlan);
  const isAppSumoPlan = useSelector(_isAppSumoPlan);
  const downgradeState = useSelector(_downgradeState);

  function contactUs() {
    tidioChat().popUpOpen();
  }

  return (
    <div
      className={`${getPrefixCls('change-plan-plan-column')}`}
    >
      <div>
        <div
          className='m-b-5'
        >
          <Title
            level={4}
            fontWeight='bold'
          >{(isCustomPlan || isAppSumoPlan) ? currentPlan.plan_name : 'Enterprise'}
          </Title>
        </div>
        <Title
          fontWeight='bold'
          className={`${getPrefixCls('change-plan-plan-name')}`}
        >{(isCustomPlan || isAppSumoPlan) ? `$${currentPlan.unit_amount}${!isAppSumoPlan ? '/mo' : '/forever'}` : 'Custom'}
        </Title>
        {isAppSumoPlan && (
          <div
            className='m-b-6'
          >
            <Text
              strong
            >{formatWithCommas(currentPlan.free_displays_count)}/mo
            </Text>
            <br/>
            <Text
              strong
            >Delivered Messages
            </Text>
          </div>
        )}
        <div>
          {!isAppSumoPlan ? (
            isCustomPlan ? (
              <div
                className='m-b-6'
              >
                <Text
                >${currentPlan.price_per_1000} per 1000
                </Text>
                <br/>
                <Text
                >additional delivered messages
                </Text>
                <br/>
                <div
                  className='m-t-3'
                >
                  <Text>
                    Unlimited everything, or limits
                    <br/>
                    and features at the price point
                    <br/>
                    of your convenience.
                  </Text>
                </div>
              </div>
            ) : (
              <div>
                <Text
                >Need a Customized
                </Text>
                <br/>
                <Text
                >Enterprise Plan?
                </Text>
              </div>
            )
          ) : (
            <div
              className='d-flex justify-content-center'
            >
              <div
                className='d-flex flex-column align-items-flex-start'
              >
                <Text
                >Unlimited subscribers
                </Text>
                <Text
                >Unlimited websites
                </Text>
                <Text
                >Unlimited active Campaigns
                </Text>
                <Text
                >Unlimited webhooks
                </Text>
                <Text
                >Unlimited API keys
                </Text>
                <Text
                >Unlimited templates
                </Text>
                <Text
                >Opt-in prompt
                </Text>
                <Text
                >Scheduling per time zone
                </Text>
                <Text
                >20 segments
                </Text>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        {!isAppSumoPlan && (
          <div
            className='m-t-5'
          >
            {!isCustomPlan ? (
              <>
                <Text
                  fontWeight='bold'
                >Contact us, and we will
                </Text>
                <br/>
                <Text
                  fontWeight='bold'
                >create a special plan for you
                </Text>
              </>
            ) : (
              <>
                <Text
                  fontWeight='bold'
                >{formatWithCommas(currentPlan.free_displays_count)}/mo
                </Text>
                <br/>
                <Text
                  fontWeight='bold'
                >Delivered Messages
                </Text>
              </>
            )}
            <Button
              block
              size='large'
              onClick={contactUs}
              disabled={(downgradeState === FiniteStates.LOADING) || isCustomPlan}
              className={`${getPrefixCls('change-plan-downgrade-plan-button')}`}
            >{isCustomPlan ? 'Current Plan' : 'Contact us'}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

function CurrentPlanAndStatistics() {
  const currentApp = useSelector(_currentApp);
  const userIsFree = useSelector(_userIsFree);
  const currentPlan = useSelector(_currentPlan);
  const sidebarData = useSelector(_sidebarData);
  const isAppSumoPlan = useSelector(_isAppSumoPlan);
  const maxDisplaysCount = useSelector(_maxDisplaysCount);
  const subscribersLimits = useSelector(_subscribersLimits);

  let extraDeliveredMessages = sidebarData?.deliveredMessages - currentPlan?.free_displays_count;

  if (extraDeliveredMessages < 0) {
    extraDeliveredMessages = 0;
  }

  return (
    <Card
      title='Current Plan & Statistics'
    >
      <div
        className={`${getPrefixCls('change-plan-current-plan-and-statistics-wrapper')} ${isAppSumoPlan ? 'max-40' : ''}`}
      >
        <div
          className={`${getPrefixCls('change-plan-current-plan-and-statistics')} d-flex align-items-stretch m-b-5`}
        >
          <div
            className='d-flex justify-content-between flex-column'
          >
            <Text
            >{currentPlan.plan_name}
            </Text>
            <Title
              level={3}
              fontWeight='medium'
            >${currentPlan.unit_amount}{isAppSumoPlan ? '/forever' : '/mo'}
            </Title>
          </div>
          <div
            className={`${isAppSumoPlan ? 'm-l-6' : ''} d-flex justify-content-between flex-column`}
          >
            <Text
            >Delivered Messages included<br/>In Plan
            </Text>
            <Title
              level={3}
              fontWeight='medium'
            >{formatWithCommas(currentPlan.free_displays_count)}
            </Title>
          </div>
          {userIsFree ? (
            <div
              className='d-flex justify-content-between flex-column'
            >
              <Text
              >Subscribers
              </Text>
              <Title
                level={3}
                fontWeight='medium'
              >{formatWithCommas(subscribersLimits)}
              </Title>
            </div>
          ) : (
            !isAppSumoPlan ? (
              <div
                className='d-flex justify-content-between flex-column'
              >
                <Text
                >Price for Extra Delivered<br/>Messages
                </Text>
                <Title
                  level={3}
                  fontWeight='medium'
                >${currentPlan.price_per_1000} per 1000
                </Title>
              </div>
            ) : null
          )}
        </div>
      </div>
      <div
        className={clsx(
          'd-flex m-t-6',
          userIsFree ? 'justify-content-between' : 'justify-content-center',
          userIsFree ? getPrefixCls('change-plan-statistics-wrapper-free') : getPrefixCls('change-plan-statistics-wrapper')
        )}
      >
        <div>
          <SPProgressDeliveredMessages
            total={maxDisplaysCount}
            extra={[sidebarData?.deliveredMessages]}
            header={<Title level={5} fontWeight='medium'>Delivered Messages</Title>}
          />
          <div
            className='m-b-2 d-flex justify-content-between'
          >
            <Badge
              status='processing'
              text='Delivered messages included in plan.'
              className={`${getPrefixCls('change-plan-current-plan-and-statistics-badge')}`}
            />
            <Text className='m-l-3'>{formatWithCommas(sidebarData?.deliveredMessages)}</Text>
          </div>
          {!userIsFree && !isAppSumoPlan && (
            <div
              className='m-b-2 d-flex justify-content-between'
            >
              <Badge
                status='warning'
                text='Extra delivered messages (with additional charge).'
                className={`${getPrefixCls('change-plan-current-plan-and-statistics-badge')}`}
              />
              <Text className='m-l-3'>{formatWithCommas(extraDeliveredMessages)}</Text>
            </div>
          )}
        </div>
        {userIsFree && (
          <div>
            <SPProgress
              total={subscribersLimits}
              extra={[currentApp?.active_users || 0]}
              header={<Title level={5} fontWeight='medium'>Subscribers</Title>}
            />
            <div
              className='m-b-2 d-flex justify-content-between'
            >
              <Badge
                status='processing'
                text='Subscribers available in plan'
                className={`${getPrefixCls('change-plan-current-plan-and-statistics-badge')}`}
              />
              <Text className='m-l-3'>{formatWithCommas(currentApp?.active_users || 0)}</Text>
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}
