import clsx from 'clsx';
import React from 'react';
import {Button} from '../../Button';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import Link from '@material-ui/core/Link';
import {IconButton} from '../../IconButton';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {FormControl} from '../../FormControl';
import {Theme} from '@material-ui/core/styles';
import {AutoComplete} from '../../AutoComplete';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import phoneNumbers from '../../../constants/phone.json';
import {getTimer} from '../../../store/selectors/settings';
import {Accounts} from '../../../constants/socialAccounts';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cancelAction} from '../../../store/actions/settings';
import {_user} from '../../../store/selectors/application/acl';
import CircularProgress from '@material-ui/core/CircularProgress';
import {updateTimerAction} from '../../../store/actions/settings';
import {handleSettingsAction} from '../../../store/actions/settings';
import {saveGeneralFormAction} from '../../../store/actions/settings';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {handleGeneralFormAction} from '../../../store/actions/settings';
import {FilterOptionsState} from '@material-ui/lab/useAutocomplete/useAutocomplete';

const useStyles = makeStyles((theme: Theme) => ({
  endAdornment            : {
    right: 8,
  },
  timeZoneInput           : {
    paddingRight: 20,
  },
  formControlClass        : {
    flexGrow: 1,
  },
  phoneFormControl        : {
    width                     : 200,
    marginRight               : theme.spacing(1),
    '& > .MuiFormControl-root': {
      minWidth: 0,
    }
  },
  autocompleteFormControl : {
    width                     : 150,
    marginRight               : theme.spacing(1),
    '& > .MuiFormControl-root': {
      minWidth: 0,
    }
  },
  noMargin                : {
    '& > .MuiFormControl-root': {
      marginBottom: 0,
    }
  },
  container               : {
    width         : '100%',
    display       : 'flex',
    justifyContent: 'center',
  },
  dFlex                   : {
    display: 'flex',
  },
  formControl             : {
    maxWidth     : 600,
    display      : 'flex',
    width        : '100%',
    flexDirection: 'column',
  },
  usernameFormControl     : {
    width                     : '100%',
    marginRight               : theme.spacing(1),
    '& > .MuiFormControl-root': {
      minWidth: 0,
    }
  },
  socialAccountsComponents: {
    display   : 'flex',
    alignItems: 'center',
  },
  loader                  : {
    color: '#ffffff',
  },
  helperText              : {
    marginTop  : 3,
    marginLeft : 14,
    marginRight: 14,
    fontWeight : 400,
    lineHeight : 1.66,
    textAlign  : 'left',
    fontSize   : '12px',
  },
  btn                     : {
    marginLeft : 8,
    marginRight: 8,
    width      : 150,
    fontWeight : 'bold',
  },
  inputCLabel             : {
    fontSize  : 16,
    fontWeight: 'bold',
    margin    : '8px 14px 0',
    color     : theme.palette.primary.main,
  },
  socialAccountsHeader    : {
    fontSize  : 18,
    fontWeight: 'bold',
  },
  feedbackHeader          : {
    fontSize  : 18,
    fontWeight: 'bold',
  },
  feedback                : {
    margin: theme.spacing(7.5, 1, 3, 1),
  },
  socialAccountsBody      : {
    fontSize: '12px'
  },
  socialAccounts          : {
    margin      : '8px 14px 0',
    marginBottom: theme.spacing(3),
    marginLeft  : theme.spacing(1),
    marginRight : theme.spacing(1),
  },
  buttonsWrapper          : {
    marginTop                     : 32,
    flexDirection                 : 'row',
    display                       : 'flex',
    [theme.breakpoints.down('xs')]: {
      marginTop             : 8,
      flexDirection         : 'column',
      alignItems            : 'center',
      '& button:first-child': {
        marginBottom: 12,
      }
    }
  },
  addButtonContainer      : {
    margin     : '8px 14px 0',
    marginLeft : theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  optionPart              : {
    fontWeight     : 400,
    backgroundColor: 'white',
  },
  autocomplete            : {
    width: `calc(100% - ${theme.spacing(2)}px)`
  },
}));

const filterOptions = function (options: any[], state: FilterOptionsState<any>) {
  if (!state.inputValue) {
    return options;
  }
  return options.filter((op: any) => {
    return (
      (String(op.code).toLowerCase() === String(state.inputValue).trim().toLowerCase()) ||
      String(op.label).toLowerCase().includes(String(state.inputValue).trim().toLowerCase())
    );
  });
};

export const GeneralForm: React.FC<IProps> = (
  {
    error,
    resend,
    cancel,
    resend1,
    loading,
    dataList,
    resetForm,
    handleForm,
    submitForm,
    isResending,
    isCanceling,
    pendingEmail,
    formData: {
      email,
      contacts,
      last_name,
      first_name,
      email_verified_at,
      display_time_zone_text,
    },
    ...rest
  }
) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const user = useSelector(_user);

  const [mainErrors, setMainErrors] = React.useState<Record<string, any>>({});
  const {
    phone,
    social,
  } = contacts || {};
  const socialLength = Object.keys(social || {}).length

  const accountOptions: any = React.useMemo(() => {
    return Accounts.map((i) => ({
      value: i,
      label: t(`social_accounts.${i}`)
    }))
  }, [
    t,
  ])

  React.useEffect(() => {
    const submitOnEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !loading) {
        submitForm();
      }
    };
    window.addEventListener('keypress', submitOnEnter);
    return () => window.removeEventListener('keypress', submitOnEnter);
  }, [
    loading,
    resetForm,
    submitForm,
  ]);

  React.useEffect(() => {
    if (error) {
      setMainErrors(error);
    }
  }, [
    error,
  ]);

  return (
    <Typography
      component='div'
      style={{width: '100%'}}
      {...rest}
    >
      {(error?.message && (Object.keys(error || {}).length === 1)) && (
        <Typography
          component='p'
          color='error'
          align='center'
        >{error.message}
        </Typography>
      )}
      <Typography
        component='div'
        className={classes.container}
      >
        <Typography
          component='div'
          className={classes.formControl}
        >
          <FormControl
            name='first_name'
            disabled={!!loading}
            value={first_name || ''}
            onChange={(e) => {
              handleForm({
                first_name: e.target.value,
              });
              setMainErrors(prevState => ({
                ...prevState,
                first_name: void 0,
              }))
            }}
            cLabel={<>{t('general_settings.first_name')}<span style={{color: 'red', marginLeft: 2}}>*</span></>}
            error={!!(mainErrors && mainErrors.first_name)}
            helperText={mainErrors && mainErrors.first_name}
          />
          <FormControl
            name='last_name'
            disabled={!!loading}
            value={last_name || ''}
            onChange={(e) => {
              handleForm({
                last_name: e.target.value,
              });
              setMainErrors(prevState => ({
                ...prevState,
                last_name: void 0,
              }))
            }}
            error={!!(mainErrors && mainErrors.last_name)}
            helperText={mainErrors && mainErrors.last_name}
            cLabel={<>{t('general_settings.last_name')}<span style={{color: 'red', marginLeft: 2}}>*</span></>}
          />
          <FormControl
            name='email'
            value={email || ''}
            onChange={(e) => {
              handleForm({
                email: e.target.value,
              });
              setMainErrors(prevState => ({
                ...prevState,
                email: void 0,
              }));
            }}
            disabled={!!pendingEmail || !!loading}
            error={!!(mainErrors && mainErrors.email)}
            cLabel={<>{t('general_settings.email')}<span style={{color: 'red', marginLeft: 2}}>*</span></>}
            helperText={
              mainErrors?.email ||
              (pendingEmail && (
                <PendingEmail
                  cancel={cancel}
                  resend={resend}
                  isResending={isResending}
                  isCanceling={isCanceling}
                  pendingEmail={pendingEmail}
                />
              )) ||
              (!email_verified_at && (
                <EmailVerifiedAt
                  resend={resend1}
                  isCanceling={isCanceling}
                  isResending={isResending}
                  email={user?.email || email}
                />
              ))
            }
          />
          <div
            style={{marginBottom: 24}}
          >
            <Typography
              component='div'
              className={classes.dFlex}
            >
              <AutoComplete
                disabled={!!loading}
                options={phoneNumbers}
                PaperComponent={PPaper}
                value={phone?.code || null}
                filterOptions={filterOptions}
                onChange={(event: any, v: any) => {
                  handleForm({
                    contacts: {
                      ...contacts,
                      phone: {
                        ...contacts?.phone,
                        code: v,
                      }
                    },
                  });
                  setMainErrors(prevState => ({
                    ...prevState,
                    'contacts.phone.number': void 0,
                    'contacts.phone.code'  : void 0,
                  }));
                }}
                classes={{endAdornment: classes.endAdornment}}
                getOptionLabel={(option: any) => `${option?.value} (${option?.label})`}
                renderInput={(params: any) => (
                  <FormControl
                    {...params}
                    cLabel={t('general_settings.phone')}
                    error={!!mainErrors['contacts.phone.code']}
                    inputProps={{
                      ...params.inputProps,
                      className: classes.timeZoneInput,
                    }}
                    formControlClass={clsx(classes.phoneFormControl, classes.noMargin)}
                  />
                )}
              />
              <FormControl
                cLabel={' '}
                name='phone'
                disabled={!!loading}
                onChange={(e) => {
                  handleForm({
                    contacts: {
                      ...contacts,
                      phone: {
                        ...contacts?.phone,
                        number: e.target.value,
                      }
                    },
                  });
                  setMainErrors(prevState => ({
                    ...prevState,
                    'contacts.phone.code'  : void 0,
                    'contacts.phone.number': void 0,
                  }));
                }}
                value={contacts?.phone?.number || ''}
                error={!!mainErrors['contacts.phone.number']}
                formControlClass={clsx(classes.formControlClass, classes.noMargin)}
              />
            </Typography>
            {(mainErrors['contacts.phone.number'] || mainErrors['contacts.phone.code']) && (
              <Typography
                color='error'
                className={classes.helperText}
              >{mainErrors['contacts.phone.number'] || mainErrors['contacts.phone.code']}
              </Typography>
            )}
          </div>
          <AutoComplete
            fullWidth
            disableClearable
            options={dataList}
            disabled={!!loading}
            groupBy={(t: any) => t.groupBy}
            value={display_time_zone_text || null}
            className={classes.autocomplete}
            onChange={(event: any, v: any) => {
              handleForm({
                display_time_zone_text: v,
              });
              setMainErrors(prevState => ({
                ...prevState,
                display_time_zone_text: void 0,
              }));
            }}
            getOptionLabel={(t: any) => t && t.label}
            classes={{endAdornment: classes.endAdornment}}
            getOptionSelected={(option: any, value: any) => (value && value.value) === (option && option.value)}
            renderInput={(params: any) => (
              <FormControl
                {...params}
                cLabel={t('general_settings.timezone')}
                inputProps={{
                  ...params.inputProps,
                  className: classes.timeZoneInput,
                }}
              />
            )}
          />
          <Typography
            component='div'
            className={classes.socialAccounts}
          >
            <Typography
              color='primary'
              className={classes.socialAccountsHeader}
            >{t('general_settings.social_accounts')}
            </Typography>
            <Typography
              color='primary'
              className={classes.socialAccountsBody}
            >{t('general_settings.helper_text')}
            </Typography>
          </Typography>
          {Object.keys(social).map((contactIndex: string, index) => {
            const currentContact: any = social[contactIndex];
            const value: any = {
              value: currentContact.key,
              label: t(`social_accounts.${currentContact.key}`),
            };

            return (
              <div
                key={index}
                style={{marginBottom: 24}}
              >
                <Typography
                  component='div'
                  className={classes.socialAccountsComponents}
                >
                  <AutoComplete
                    disableClearable
                    disabled={!!loading}
                    value={value || null}
                    options={accountOptions}
                    onChange={(event: any, v: any) => {
                      handleForm({
                        contacts: {
                          ...contacts,
                          social: {
                            ...social,
                            [contactIndex]: {
                              ...social[contactIndex],
                              key: v.value
                            },
                          },
                        },
                      });
                      setMainErrors(prevState => ({
                        ...prevState,
                        [`contacts.social.${index}.key`]  : void 0,
                        [`contacts.social.${index}.value`]: void 0,
                      }));
                    }}
                    classes={{endAdornment: classes.endAdornment}}
                    getOptionLabel={(option: any) => option?.label}
                    getOptionSelected={(option, value) => (value && value.value) === (option && option.value)}
                    renderInput={(params: any) => (
                      <FormControl
                        {...params}
                        error={
                          !!mainErrors[`contacts.social.${index}.value`] ||
                          !!mainErrors[`contacts.social.${index}.key`]
                        }
                        formControlClass={clsx(classes.autocompleteFormControl, classes.noMargin)}
                        inputProps={{
                          ...params.inputProps,
                          className: classes.timeZoneInput,
                        }}
                      />
                    )}
                  />
                  <FormControl
                    fullWidth
                    disabled={!!loading}
                    onChange={(e) => {
                      handleForm({
                        contacts: {
                          ...contacts,
                          social: {
                            ...social,
                            [contactIndex]: {
                              ...social[contactIndex],
                              value: e.target.value
                            },
                          },
                        },
                      });
                      setMainErrors(prevState => ({
                        ...prevState,
                        [`contacts.social.${index}.key`]  : void 0,
                        [`contacts.social.${index}.value`]: void 0,
                      }));
                    }}
                    value={currentContact?.value || ''}
                    error={
                      !!mainErrors[`contacts.social.${index}.value`] ||
                      !!mainErrors[`contacts.social.${index}.key`]
                    }
                    formControlClass={clsx(classes.usernameFormControl, classes.noMargin)}
                    placeholder={t(`social_accounts.${currentContact.key}_placeholder`)}
                  />
                  <IconButton
                    size='medium'
                    color='transparent'
                    onClick={() => {
                      delete social[contactIndex];
                      handleForm({
                        contacts: {
                          phone,
                          social: {
                            ...(Object.values(social))
                          }
                        },
                      });
                    }}
                  >
                    <DeleteIcon
                      color='error'
                    />
                  </IconButton>
                </Typography>
                {(mainErrors[`contacts.social.${index}.value`] || mainErrors[`contacts.social.${index}.key`]) && (
                  <Typography
                    color='error'
                    className={classes.helperText}
                  >{mainErrors[`contacts.social.${index}.value`] || mainErrors[`contacts.social.${index}.key`]}
                  </Typography>
                )}
              </div>
            )
          })}
          <Typography
            component='div'
            className={classes.addButtonContainer}
          >
            <Button
              size='small'
              color='primary'
              variant='outlined'
              onClick={() => {
                handleForm({
                  contacts: {
                    ...contacts,
                    social: {
                      ...contacts.social,
                      [Number(socialLength) + 1]: {
                        key: 'skype'
                      }
                    },
                  },
                });
              }}
              disabled={socialLength === 5}
              startIcon={<AddCircleOutlineIcon/>}
              style={{paddingLeft: 12, paddingRight: 12}}
            >{t('buttons.add')}
            </Button>
          </Typography>
          <Typography
            component='div'
            className={classes.formControl}
          >
            <Typography
              component='div'
              className={classes.buttonsWrapper}
            >
              <Button
                size='small'
                disableRipple
                color='primary'
                disableFocusRipple
                variant='contained'
                disabled={!!loading}
                onClick={submitForm}
                className={classes.btn}
              >
                {!!loading ? (
                  <CircularProgress
                    size={25}
                    className={classes.loader}
                  />
                ) : (
                  t('general_settings.save')
                )}
              </Button>
              <Button
                size='small'
                disableRipple
                color='default'
                variant='outlined'
                disableFocusRipple
                onClick={() => {
                  resetForm();
                  setMainErrors({});
                }}
                disabled={!!loading}
                className={classes.btn}
              >{t('general_settings.discard')}
              </Button>
            </Typography>
          </Typography>
          <Typography
            component='div'
            className={classes.feedback}
          >
            <Typography
              color='primary'
              className={classes.feedbackHeader}
            >{t('general_settings.feedback')}
            </Typography>
            <Typography
              color='primary'
              style={{fontSize: '12px'}}
            >{t('general_settings.feedback_text')} <a target='_blank' rel='noopener noreferrer' href={'mailto:support@smartpush.ai'}>support@smartpush.ai</a>
            </Typography>
          </Typography>
          <Typography
            component='div'
            style={{marginTop: 0}}
            className={classes.feedback}
          >
            <Typography
              color='primary'
              className={classes.feedbackHeader}
            >{t('general_settings.delete_your_account')}
            </Typography>
            <Typography
              color='primary'
              style={{fontSize: '12px'}}
            >If you would like to delete your account, please email our support team at <a target='_blank' rel='noopener noreferrer' href={'mailto:support@smartpush.ai'}>support@smartpush.ai</a><br/>Please note that when you delete your account, you will lose access to all of your SmartPush data.
            </Typography>
          </Typography>
        </Typography>
      </Typography>
    </Typography>
  )
};

function PPaper(props: any) {
  return <Paper {...props} style={{width: 300}}/>
}

interface IProps {
  resetForm: any;
  dataList?: any;
  loading?: boolean;
  className?: string;
  isResending?: boolean;
  isCanceling?: boolean;
  cancel: typeof cancelAction;
  resend: any;
  resend1: any;
  style?: React.CSSProperties;
  formData: Record<string, any>;
  error?: Record<string, any>;
  submitForm: typeof saveGeneralFormAction;
  handleForm: typeof handleGeneralFormAction;
  pendingEmail?: { created_at: string, email: string };
}

let interval: any;

function Resend(
  {
    resend,
    cancel,
    isResending,
    isCanceling,
  }: {
    resend: any;
    cancel?: any;
    isResending?: boolean;
    isCanceling?: boolean;
  }
) {
  const dispatch = useDispatch();
  const timer = useSelector(getTimer);

  React.useEffect(() => {
    if (timer === 60) {
      if (interval) {
        clearInterval(interval);
      }
      interval = setInterval(() => {
        dispatch(updateTimerAction())
      }, 1000);
    }

    if (timer === 0) {
      if (interval) {
        clearInterval(interval);
      }
      dispatch(handleSettingsAction({
        timer: void 0,
      }));
    }
  }, [
    timer,
    dispatch,
  ]);

  return (
    <>
      <Link
        href='#'
        onClick={(e: any) => {
          e.preventDefault();
          if (!isResending) {
            if (!timer) {
              resend();
            }
          }
        }}
        style={{
          alignItems: 'center',
          display   : 'inline-flex',
          cursor    : (isResending || !!timer) ? 'not-allowed' : 'pointer'
        }}
        underline={(isResending || !!timer) ? 'none' : 'hover'}
        color={(isResending || !!timer) ? 'secondary' : 'primary'}
      >
        <Typography
          component='p'
          variant='inherit'
          style={{lineHeight: '19.92px', whiteSpace: 'nowrap'}}
        >Resend Email
        </Typography>
        {isResending && (
          <Typography
            component='span'
            variant='inherit'
            style={{
              marginLeft: 5,
              alignItems: 'center',
              display   : 'inline-flex',
            }}
          >
            <CircularProgress
              size={10}
            />
          </Typography>
        )}
        {!!timer && (
          <Typography
            component='span'
            variant='inherit'
            style={{
              marginLeft: 5,
              alignItems: 'center',
              lineHeight: '19.92px',
              display   : 'inline-flex',
            }}
          >{timer}
          </Typography>
        )}
      </Link>
      {(typeof cancel === 'function') && (
        <Link
          href='#'
          underline={isCanceling ? 'none' : 'hover'}
          color={isCanceling ? 'secondary' : 'primary'}
          onClick={(e: any) => {
            e.preventDefault();
            if (!isResending && !isCanceling) {
              cancel();
              if (interval) {
                clearInterval(interval);
              }
            }
          }}
          style={{
            marginLeft: 5,
            alignItems: 'center',
            display   : 'inline-flex',
            cursor    : isCanceling ? 'not-allowed' : 'pointer'
          }}
        >
          <Typography
            component='span'
            variant='inherit'
            style={{lineHeight: '19.92px'}}
          >Cancel
          </Typography>
        </Link>
      )}
    </>
  )
}

function EmailVerifiedAt(
  {
    email,
    resend,
    isCanceling,
    isResending,
  }: {
    email: any,
    resend: any,
    isCanceling: any,
    isResending: any,
  }
) {
  return (
    <Typography
      variant='inherit'
      style={{
        display   : 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          marginRight    : 5,
          marginBottom   : 1,
          width          : 5,
          minWidth       : 5,
          height         : 5,
          minHeight      : 5,
          color          : 'red',
          borderRadius   : '50%',
          backgroundColor: 'red',
          display        : 'block',
        }}
      />
      <span
        style={{
          marginRight: 3,
          whiteSpace : 'nowrap',
        }}
      >Please confirm
      </span>
      <Tooltip
        title={email}
      >
        <Typography
          noWrap
          component='p'
          variant='inherit'
          style={{
            marginRight: 3,
          }}
        >{email}
        </Typography>
      </Tooltip>
      <Resend
        resend={resend}
        isCanceling={isCanceling}
        isResending={isResending}
      />
    </Typography>
  )
}

function PendingEmail(
  {
    cancel,
    resend,
    isCanceling,
    isResending,
    pendingEmail,
  }: {
    cancel: any,
    resend: any,
    isCanceling: any,
    isResending: any,
    pendingEmail: any,
  }
) {
  return (
    <Typography
      variant='inherit'
      style={{
        display   : 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          marginRight    : 5,
          marginBottom   : 1,
          width          : 5,
          minWidth       : 5,
          height         : 5,
          minHeight      : 5,
          color          : 'red',
          borderRadius   : '50%',
          backgroundColor: 'red',
          display        : 'block',
        }}
      />
      <span
        style={{
          marginRight: 3,
          whiteSpace : 'nowrap',
        }}
      >Please confirm
      </span>
      <Tooltip
        title={pendingEmail.email}
      >
        <Typography
          noWrap
          component='p'
          variant='inherit'
          style={{
            marginRight: 3,
          }}
        >{pendingEmail.email}
        </Typography>
      </Tooltip>
      <Resend
        resend={resend}
        cancel={cancel}
        isCanceling={isCanceling}
        isResending={isResending}
      />
    </Typography>
  )
}