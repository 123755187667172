import React from 'react';
import {Form} from 'antd';
import {Input} from 'antd';
import {Controller} from 'react-hook-form';
import {Link as RLink} from 'react-router-dom';
import {dataPrefixes} from '../../../../constants';
import {useForgotPassword} from './useForgotPassword';
import {SubmitButton} from './components/SubmitButton';
import {AppRoutes} from '../../../../constants/appRoutes';
import {Link} from '../../../../components/antd/Typography';
import {Text} from '../../../../components/antd/Typography';
import {Title} from '../../../../components/antd/Typography';
import {FormAlert} from '../../../../components/antd/FormAlert';
import '../../index.less';

const {Item} = Form;

const {auth: {forgotPassword}} = dataPrefixes;

export function ForgotPassword() {
  const {
    reset,
    control,
    formInfo,
    onSubmit,
    getValues,
    clearErrors,
    searchString,
    formState: {
      isSubmitting,
      isSubmitSuccessful,
    },
  } = useForgotPassword();

  return (
    <>
      <form
        noValidate
        className='form'
        autoComplete='off'
        onSubmit={onSubmit}
      >
        <div>
          <div
            className='m-b-5'
          >
            <Title
              level={5}
              align='center'
              fontWeight='regular'
            >{isSubmitSuccessful ? 'Check your email for the reset password link' : 'Enter your email to reset password'}
            </Title>
          </div>
          <Controller
            name='message'
            control={control}
            render={(
              {
                fieldState: {
                  error,
                }
              }
            ) => {
              return (
                <FormAlert
                  type='error'
                  message={error?.message}
                  onClose={() => clearErrors('message')}
                />
              )
            }}
          />
          <FormAlert
            type='info'
            onClose={() => reset()}
            message={(isSubmitSuccessful && formInfo.message) ? formInfo.message.replace('{{{email}}}', getValues('email')) : void 0}
          />
          <Form
            component='div'
            className='form'
            layout='vertical'
          >
            <Controller
              name='email'
              control={control}
              render={(
                {
                  field     : {
                    ref,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                    invalid,
                  }
                }
              ) => {
                return (
                  <Item
                    label='Email'
                    validateStatus={invalid ? 'error' : void 0}
                    help={<span data-qa={`${forgotPassword}-form-item-email-explain-error`}>{error?.message}</span>}
                  >
                    <Input
                      ref={ref}
                      autoFocus
                      autoComplete='off'
                      disabled={isSubmitSuccessful}
                      {...fieldRest}
                    />
                  </Item>
                );
              }}
            />
            <div
              className='m-b-5'
            >
              <SubmitButton
                control={control}
                loading={isSubmitting}
                disabled={isSubmitSuccessful}
              />
            </div>
            <div
              className='d-flex justify-content-between align-items-center'
            >
              <Text
              >{isSubmitSuccessful ? 'Didn\'t Receive Email?' : 'Remember Your Password?'}
              </Text>
              {isSubmitSuccessful ? (
                <Link
                  onClick={() => reset()}
                >Reset Again
                </Link>
              ) : (
                <RLink
                  className='ant-typography regular'
                  to={!searchString ? AppRoutes.LOGIN : `${AppRoutes.LOGIN}?${searchString}`}
                >Sign In
                </RLink>
              )}
            </div>
          </Form>
        </div>
      </form>
    </>
  )
}
