import React from 'react';
import {useSurvey} from './useSurvey';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {urlHelper} from '../../../utils/urlHelper';
import {AppRoutes} from '../../../constants/appRoutes';
import {Spinner} from '../../../components/antd/Spinner';
import {SurveyContainer} from '../../../components/antd/Survey';
import {_surveys} from '../../../store/actions/application/surveys';
import {_surveyIsLoading} from '../../../store/selectors/application/acl';

export function Survey() {
  const dispatch = useDispatch();
  const surveyIsLoading = useSelector(_surveyIsLoading);

  React.useEffect(() => {
    dispatch(_surveys());
  }, [dispatch]);

  if (surveyIsLoading) {
    return <Spinner/>
  }

  return <Content/>
}

export function Content() {
  const {
    skip,
    data,
    start,
    letsGo,
    setData,
    redirect,
    firstName,
  } = useSurvey();
  const history = useHistory<any>();
  const {appUuid} = useParams<{ appUuid?: string }>();

  if (redirect) {
    if (history.location.state?.from) {
      if ([
        urlHelper(AppRoutes.APPS_INTEGRATE_APP, {appUuid}),
        urlHelper(AppRoutes.APPS_PAY_AND_LAUNCH_APP, {appUuid}),
      ].includes(history.location.state?.from)) {
        if (appUuid) {
          history.replace(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid}));
        } else {
          history.replace(AppRoutes.APPS_CREATE);
        }
      } else {
        history.replace(history.location.state.from);
      }
    } else {
      window.location.replace('/');
    }
    return null;
  }

  return (
    <SurveyContainer
      data={data}
      skip={skip}
      start={start}
      letsGo={letsGo}
      setData={setData}
      firstName={firstName}
    />
  )
}
