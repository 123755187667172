import React from 'react';
import {Button} from 'antd';
import {Breadcrumb} from 'antd';
import {Route} from 'react-router';
import Providers from './Providers';
import {Switch} from 'react-router';
import Dashboard from './Dashboard';
import {SubscriberInfo} from './Info';
import {useParams} from 'react-router';
import Box from '@material-ui/core/Box';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {IObject} from '../../../../types';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {urlHelper} from '../../../../utils/urlHelper';
import Table from '../../../../components/ReduxTable';
import {_get} from '../../../../store/actions/apps/get';
import {AppRoutes} from '../../../../constants/appRoutes';
import {ApiRoutes} from '../../../../constants/apiRoutes';
import {Content} from '../../../../components/antd/Content';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {Navigation} from '../../../../components/antd/Navigation';
import {fetchAction} from '../../../../store/actions/subscribers';
import {optionsAction} from '../../../../store/actions/subscribers';
import {_apiBaseUrl} from '../../../../store/selectors/application';
import * as selectors from '../../../../store/selectors/subscribers';
import {emptyDataAction} from '../../../../store/actions/subscribers';
import {handleExportAction} from '../../../../store/actions/subscribers';
import {_hasConversionsAccess} from '../../../../store/selectors/application/acl';
import {useTitle} from '../../../../hooks/useTitle';

export function Subscribers() {
  const {appUuid} = useParams<{ appUuid: string }>();

  return (
    <Switch>
      <Route
        exact
        component={List}
        path={AppRoutes.ANALYTICS_SUBSCRIBERS}
      />
      <Route
        exact
        component={SubscriberInfo}
        path={AppRoutes.ANALYTICS_SUBSCRIBERS_INFO}
      />
      <Route
        exact
        path={`${AppRoutes.ANALYTICS_SUBSCRIBERS}/*`}
      >
        <Redirect
          to={urlHelper(AppRoutes.ANALYTICS_SUBSCRIBERS, {appUuid})}
        />
      </Route>
    </Switch>
  )
}

function List() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  useTitle(t('subscribers.document_title'));
  const byId = useSelector(selectors.getById);
  const apiBaseUrl = useSelector(_apiBaseUrl);
  const allIds = useSelector(selectors.getAllIds);
  const {appUuid} = useParams<{ appUuid: string }>();
  const isLoading = useSelector(selectors.getIsLoading);
  const tableOptions = useSelector(selectors.getOptions);
  const dataOptions = useSelector(selectors.getDataOptions);
  const [loading, setLoading] = React.useState<boolean>(true);
  const hasConversionsAcl = useSelector(_hasConversionsAccess);
  const paginationOptions = useSelector(selectors.getPaginationOptions);

  React.useEffect(() => {
    setLoading(true);

    dispatch(optionsAction({
      columns: tableOptions.columnOrder.map((item: string) => ({
        name : item,
        title: t(`subscribers.${item}`),
      })).filter((i: any) => {
        if (hasConversionsAcl) {
          return true;
        }
        return ![
          'conversion_sum',
          'conversion_count',
          'last_converted_at',
        ].includes(i.name);
      }),
    }));

    setLoading(false);
  }, [
    t,
    dispatch,
    hasConversionsAcl,
    tableOptions.columnOrder,
  ]);

  React.useEffect(() => {
    return function () {
      dispatch(emptyDataAction())
    }
  }, [
    dispatch,
  ]);

  const options = React.useMemo(() => {
    return {
      ...tableOptions,
      ...paginationOptions,
      isExporting: dataOptions.isExporting,
    };
  }, [
    tableOptions,
    paginationOptions,
    dataOptions.isExporting,
  ]);

  const loadData = React.useCallback(() => {
    dispatch(fetchAction());
  }, [
    dispatch,
  ]);

  const handleExport = React.useCallback(() => {
    dispatch(handleExportAction('csv'));
  }, [
    dispatch,
  ]);

  const updateOptions = React.useCallback((options: IObject) => {
    dispatch(optionsAction(options));
  }, [
    dispatch,
  ]);

  function getTableNoDataMessage() {
    if (isLoading) {
      return t('loading');
    }
    if (!!options.searchText) {
      return t('empty_search', {
        name: t('subscribers.tab_name')
      });
    } else {
      return t('subscribers.empty_state');
    }
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <Navigation
        title='Subscribers'
        breadcrumbItems={[
          <Breadcrumb.Item
            key='app.subscribers'
          >Subscribers
          </Breadcrumb.Item>
        ]}
        extra={[
          <Button
            type='default'
            onClick={() => {
              loadData();
              dispatch(_get(appUuid));
            }}
            icon={<ReloadOutlined/>}
            key='ReloadPushNotifications'
          />
        ]}
      />
      <Content>
        <Paper
          square
          elevation={0}
          variant='elevation'
        >
          <Box
            padding={3}
          >
            <Dashboard/>
          </Box>
        </Paper>
        <Box height={8}/>
        <Paper
          square
          elevation={0}
          variant='elevation'
        >
          <Box
            padding={3}
            position='relative'
          >
            <Table
              byId={byId}
              disableReload
              allIds={allIds}
              messages={{
                tableNoData        : getTableNoDataMessage(),
                exportAgree        : t('subscribers.exportAgree'),
                exportCancel       : t('subscribers.exportCancel'),
                exportMainText     : t('subscribers.exportMainText'),
                searchPlaceholder  : t('subscribers.search_placeholder'),
                exportSecondaryText: t('subscribers.exportSecondaryText'),
              }}
              simplePagination
              options={options}
              loading={isLoading}
              loadData={loadData}
              handleExport={handleExport}
              customProviders={Providers}
              updateTableOptions={updateOptions}
              hasMorePages={options.hasMorePages}
              getRowId={row => `${row.uuid}${Math.random()}`}
              url={`${apiBaseUrl}${urlHelper(ApiRoutes.LIST_SUBSCRIBERS, {appUuid})}`}
            />
          </Box>
        </Paper>
      </Content>
    </>
  )
}
