import React from 'react';
import {Template} from '../Templates';
import Box from '@material-ui/core/Box';
import {useSelector} from 'react-redux';
import {useWatch} from 'react-hook-form';
import Hidden from '@material-ui/core/Hidden';
import {Control} from 'react-hook-form/dist/types/form';
import {_system} from '../../../../store/selectors/application/acl';

export function Preview(
  {
    index,
    control,
    disabled,
  }: {
    index: number;
    control: Control;
    disabled?: boolean;
  }
) {
  const system = useSelector(_system);
  const message = useWatch({
    control,
    name: `messages.[${index}]`,
  });

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Hidden
        xsDown
      >
        <div>
          <Template
            icon={message.icon_url}
            cdnUrl={system?.cdn_url}
            bgColor={message.bg_color}
            direction={message.direction}
            message={message.message_text}
            templateId={message.template_id}
            deny={{
              button   : message.deny_text,
              textColor: message.deny_text_color,
              bgColor  : message.deny_button_color,
            }}
            style={{
              minWidth      : 400,
              justifyContent: 'center',
              filter        : disabled ? 'blur(1px)' : 'unset'
            }}
            allow={{
              button   : message.allow_text,
              textColor: message.allow_text_color,
              bgColor  : message.allow_button_color,
            }}
            messageColor={message.message_text_color}
            position={message?.position?.value || ''}
          />
        </div>
      </Hidden>
    </Box>
  )
}