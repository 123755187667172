import React from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(() => ({
  iconRoot: {
    marginLeft: 3,
    fontSize  : 16,
    color     : '#2D0940',
  }
}));

export default function Tooltip(
  {
    title,
  }: {
    title: string;
  }
) {
  const classes = useStyles();

  return (
    <MuiTooltip
      title={title}
    >
      <InfoOutlinedIcon
        className={classes.iconRoot}
      />
    </MuiTooltip>
  )
}
