import React from 'react';
import axios from 'axios';
import {Canceler} from 'axios';
import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {UnregisterCallback} from 'history';
import {useFieldArray} from 'react-hook-form';
import {urlHelper} from '../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {_get} from '../../../../../store/actions/segments/get';
import {_segment} from '../../../../../store/selectors/segments';
import {_dataOptions} from '../../../../../store/actions/segments';
import {FiniteStates} from '../../../../../constants/finiteStates';
import {_removeEntity} from '../../../../../store/actions/segments';
import {_update} from '../../../../../store/actions/segments/update';
import {_segmentsParams} from '../../../../../store/selectors/segments';
import {_onBeforeunload} from '../../../../../store/actions/application';
import {_fetchingSegmentState} from '../../../../../store/selectors/segments';
import {_fetchingSegmentsParamsState} from '../../../../../store/selectors/segments';

let cancel: Canceler | undefined;
const CancelToken = axios.CancelToken;

export function useEdit() {
  const dispatch = useDispatch();
  const segmentState = useSelector(_fetchingSegmentState);
  const segmentsParamsState = useSelector(_fetchingSegmentsParamsState);

  const canRender = [
    FiniteStates.FAILURE,
    FiniteStates.SUCCESS,
  ].includes(segmentState) && [
    FiniteStates.FAILURE,
    FiniteStates.SUCCESS,
  ].includes(segmentsParamsState);

  const {
    id,
    appUuid,
  } = useParams<{ appUuid: string; id: string }>();

  React.useEffect(() => {
    dispatch(_get(appUuid, Number(id), {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    }));

    return function () {
      if (typeof cancel === 'function') {
        cancel();
      }
      dispatch(_removeEntity());

      dispatch(_dataOptions({
        fetchingSegmentState: FiniteStates.IDLE,
      }));
    }
  }, [
    id,
    appUuid,
    dispatch,
  ]);

  return {
    canRender,
  }
}

let unregisterCallback: UnregisterCallback | null = null;

const beforeUnload = function (e: BeforeUnloadEvent) {
  e.returnValue = 'Changes you made may not be saved.';

  return 'Changes you made may not be saved.';
}

export function useEditForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const segment = useSelector(_segment);
  const params = useSelector(_segmentsParams);
  const form = useForm<Record<string, any>>({
    defaultValues: segment,
  });

  const {
    formState: {
      isDirty,
      dirtyFields,
    },
    handleSubmit,
  } = form;

  const hasDirtyFields = !!Object.keys(dirtyFields || {}).length;

  React.useEffect(() => {
    if (isDirty && hasDirtyFields) {
      unregisterCallback = history.block((location) => {
        dispatch(_onBeforeunload({
          open           : true,
          bodyTitle      : 'Warning',
          agreeAction    : () => {
            dispatch(_onBeforeunload(void 0));
            unregisterCallback && unregisterCallback();
            history.push({
              ...location,
              search: void 0,
            });
          },
          onClose        : () => {
            dispatch(_onBeforeunload(void 0));
          },
          dismissAction  : () => {
            dispatch(_onBeforeunload(void 0));
          },
          dismissLabel   : 'Stay on Page',
          agreeLabel     : 'Discard Changes',
          bodyDescription: 'You have unsaved changes in this segment. If you continue, your changes will be lost.',
        }));

        return false;
      });

      window.addEventListener('beforeunload', beforeUnload);
    }

    return function () {
      unregisterCallback && unregisterCallback();

      window.removeEventListener('beforeunload', beforeUnload);
    }
  }, [
    history,
    isDirty,
    dispatch,
    hasDirtyFields,
  ]);

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    name   : 'query',
    control: form.control,
  });

  const {
    appUuid,
  } = useParams<{ appUuid: string; id: string }>();

  const onCancel = React.useCallback(() => {
    history.push(urlHelper(AppRoutes.SEGMENTS, {appUuid}));
  }, [
    history,
    appUuid,
  ]);

  const redirectToSegments = React.useCallback(() => {
    history.push(urlHelper(AppRoutes.SEGMENTS, {appUuid}));
  }, [
    history,
    appUuid,
  ]);

  const onAppend = React.useCallback(() => {
    append({
      type   : '',
      table  : '',
      values : '',
      filter : '',
      _values: [
        {
          value: '',
        }
      ],
    })
  }, [
    append,
  ]);

  const onRemove = React.useCallback((index: number) => () => {
    remove(index);
  }, [
    remove,
  ]);

  const update = React.useCallback((formValues: Record<string, any>) => {
    return dispatch(_update(appUuid, formValues, {
      unregisterCallback: () => {
        unregisterCallback && unregisterCallback();
        window.removeEventListener('beforeunload', beforeUnload);
      },
      setError          : form.setError,
      redirect          : () => history.push(urlHelper(AppRoutes.SEGMENTS, {appUuid})),
    }));
  }, [
    form,
    appUuid,
    history,
    dispatch,
  ]);

  return {
    params,
    fields,
    segment,
    onRemove,
    onAppend,
    onCancel,
    redirectToSegments,
    onSubmit: handleSubmit(update),
    ...form
  }
}