import React from 'react';

export const customStyles = (hasError: boolean = false, options?: { [key: string]: React.CSSProperties }) => ({
  menuList: (provided: any) => ({
    ...provided,
    paddingTop   : 0,
    paddingBottom: 0,
  }),
  option  : (provided: any, state: any) => {
    return {
      ...provided,
      color          : state.isSelected ? '#ffffff' : state.isFocused ? '#ffffff' : '#000000',
      backgroundColor: state.isSelected ? '#24083C' : state.isFocused ? '#24083C' : '#ffffff',
      '&:active'     : {
        color          : '#ffffff',
        backgroundColor: '#24083C',
      },
      '&:focused'    : {
        color          : '#ffffff',
        backgroundColor: '#24083C',
      },
      '&:hover'      : {
        color          : '#ffffff',
        backgroundColor: '#24083C',
      },
    }
  },
  control : (provided: any) => ({
    ...provided,
    borderRadius: 0,
    minWidth    : 200,
    boxShadow   : 'none',
    border      : '1px solid #EAEAEA',
    borderColor : hasError ? '#f44336 !important' : '#EAEAEA',
    '&:hover'   : {
      borderColor: hasError ? '#f44336' : '#24083C',
    },
    ...(options?.control),
  }),
  menu    : (provided: any) => ({
    ...provided,
    padding     : 0,
    borderRadius: 0,
    boxShadow   : 'none',
    border      : '1px solid #F5F5F5',
    zIndex      : 500000,
  }),
});

export const reactSelectStyles = (errors?: Record<string, any>) => ({
  menu              : (provided: any) => {
    return {
      ...provided,
      padding     : 0,
      borderRadius: 0,
      boxShadow   : 'none',
      zIndex      : 500000,
      border      : '1px solid #F5F5F5',
    }
  },
  menuList          : (provided: any) => {
    return {
      ...provided,
      paddingTop   : 0,
      paddingBottom: 0,
    }
  },
  container         : (provided: any) => {
    return {
      ...provided,
      width: '100%',
    }
  },
  option            : (provided: any, state: any) => {
    return {
      ...provided,
      backgroundColor: 'transparent',
      color          : state.isFocused ? '#096dd9' : '#000000',
      '&:active'     : {
        color          : '#096dd9',
        backgroundColor: 'transparent',
      },
      '&:focused'    : {
        color          : '#096dd9',
        backgroundColor: 'transparent',
      },
      '&:hover'      : {
        color          : '#096dd9',
        backgroundColor: 'transparent',
      },
    }
  },
  control           : (provided: any, state: any) => {
    return {
      ...provided,
      borderRadius: 0,
      width       : '100%',
      boxShadow   : state.isFocused ? 'rgba(24, 144, 255, 0.2) 0px 0px 0px 2px' : 'none',
      '&:hover'   : {
        border: '1px solid #096dd9',
      }
    }
  },
  multiValue        : (provided: any, state: any) => {
    const er: string = errors?.[`${errors.type}.${state?.data?.value}`];
    const hasError: boolean = !!er;

    return {
      ...provided,
      border         : hasError ? '1px solid rgb(97, 26, 21)' : provided.border,
      backgroundColor: hasError ? 'rgb(253, 236, 234)' : provided.backgroundColor,
    }
  },
  multiValueLabel   : (provided: any, state: any) => {
    const er: string = errors?.[`${errors.type}.${state?.data?.value}`];
    const hasError: boolean = !!er;

    return {
      ...provided,
      color: hasError ? 'rgb(97, 26, 21)' : provided.color,
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      backgroundColor: state.isFocused ? '#096dd9' : provided.backgroundColor,
    }
  },
  clearIndicator    : (provided: any, state: any) => {
    return {
      ...provided,
      color: state.isFocused ? '#096dd9' : provided.color,
    }
  },
  dropdownIndicator : (provided: any, state: any) => {
    return {
      ...provided,
      color: state.isFocused ? '#096dd9' : provided.color,
    }
  },
});