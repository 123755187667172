import React from 'react';
import RSelect from 'react-select';
import Box from '@material-ui/core/Box';
import {InputLabel} from '../InputLabel';
import {Props as RSProps} from 'react-select';
import Creatable from 'react-select/creatable';
import {BoxProps} from '@material-ui/core/Box';
import {StylesConfig} from 'react-select/src/styles';
import {withAsyncPaginate} from 'react-select-async-paginate';
import {Props} from 'react-select-async-paginate/ts/withAsyncPaginate';
import {AsyncPaginate as RSAsyncPaginate} from 'react-select-async-paginate';

const styles = (
  {
    options,
    hasError,
    deletedValues,
  }: {
    hasError?: boolean;
    deletedValues?: number[];
    options?: Record<string, React.CSSProperties>;
  } = {}
): StylesConfig<any, boolean, any> => ({
  menuList           : (provided) => ({
    ...provided,
    paddingTop   : 0,
    paddingBottom: 0,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding  : 0,
    '& > div': {padding: 5}
  }),
  option             : (provided, state) => ({
    ...provided,
    fontSize       : 14,
    color          : state.isFocused ? '#096dd9' : '#000000',
    backgroundColor: state.isSelected ? '#E6F7FF' : 'transparent',
    '&:active'     : {
      color          : '#096dd9',
      backgroundColor: 'transparent',
    },
    '&:focused'    : {
      color          : '#096dd9',
      backgroundColor: 'transparent',
    },
    '&:hover'      : {
      color          : '#096dd9',
      cursor         : 'pointer',
      backgroundColor: 'transparent',
    },
  }),
  multiValue         : (provided, state) => ({
    ...provided,
    fontSize: 14,
    outline : !!deletedValues?.includes(state.data.value) ? '1px solid #f44336' : void 0,
    '& > *' : {
      color: !!deletedValues?.includes(state.data.value) ? '#f44336' : void 0,
    }
  }),
  multiValueRemove   : (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  placeholder        : (provided, state) => ({
    ...provided,
    fontSize: 14,
    color   : state.isDisabled ? '#d6d6d6' : '#a3a3a3',
  }),
  clearIndicator     : (provided, state) => ({
    ...provided,
    color: hasError ? '#f44336' : state.isFocused ? '#096dd9' : provided.color,
  }),
  dropdownIndicator  : (provided, state) => ({
    ...provided,
    color: hasError ? '#f44336' : state.isFocused ? '#096dd9' : provided.color,
  }),
  indicatorSeparator : (provided, state) => ({
    ...provided,
    backgroundColor: hasError ? '#f44336' : state.isFocused ? '#096dd9' : provided.backgroundColor,
  }),
  input              : (provided) => ({
    ...provided,
    margin: '0px',
  }),
  container          : (provided) => ({
    ...provided,
    width: '100%',
  }),
  valueContainer     : (provided) => ({
    ...provided,
    fontSize : 14,
    minHeight: '30px',
    padding  : '0 6px',
  }),
  menu               : (provided) => ({
    ...provided,
    padding     : 0,
    borderRadius: 0,
    zIndex      : 500000,
    boxShadow   : 'none',
    border      : '1px solid #F5F5F5',
  }),
  control            : (provided, state) => ({
    ...provided,
    borderRadius   : 2,
    minHeight      : '30px',
    width          : '100%',
    backgroundColor: 'hsl(0, 0%, 100%)',
    borderColor    : hasError ? '#f44336' : 'rgba(0, 0, 0, 0.3)',
    boxShadow      : !hasError ? 'none' : state.isFocused ? 'rgba(24, 144, 255, 0.2) 0px 0px 0px 2px' : 'none',
    '&:hover'      : {
      borderColor: hasError ? '#f44336' : '#096dd9',
    },
    ...(options?.control),
  }),
})

export function AsyncPaginate(
  {
    label,
    error,
    boxProps,
    deletedItems,
    styleOptions,
    secondaryBoxProps,
    ...props
  }: Props<any, any, boolean> & {
    boxProps?: BoxProps;
    label?: React.ReactNode;
    error?: React.ReactNode;
    secondaryBoxProps?: BoxProps;
    deletedItems?: { label: string; value: number }[];
    styleOptions?: Record<string, React.CSSProperties>;
  }
) {
  return (
    <>
      <Box
        marginTop='16px'
        marginBottom='8px'
        position='relative'
        {...boxProps}
      >
        <InputLabel
          label={label}
          disabled={!!props.isDisabled}
        />
        <Box
          marginTop='6px'
          {...secondaryBoxProps}
        >
          <RSAsyncPaginate
            placeholder=''
            styles={styles({
              hasError     : !!error,
              options      : styleOptions,
              deletedValues: deletedItems?.map(i => i.value),
            })}
            {...props}
          />
        </Box>
      </Box>
    </>
  )
}

const WithAsyncPaginate = withAsyncPaginate(Creatable);

export function CreatableAsyncPaginate(props: any) {
  return (
    <WithAsyncPaginate
      placeholder=''
      styles={styles()}
      {...props}
    />
  )
}

export const Select = React.forwardRef<RSelect, RSProps & {
  required?: boolean;
  boxProps?: BoxProps;
  label?: React.ReactNode;
  secondaryBoxProps?: BoxProps;
  styleOptions?: Record<string, React.CSSProperties>;
}>((
  {
    label,
    required,
    boxProps,
    styleOptions,
    secondaryBoxProps,
    ...props
  }, ref
) => {
  return (
    <Box
      marginTop='16px'
      marginBottom='8px'
      position='relative'
      {...boxProps}
    >
      <InputLabel
        label={label}
        required={required}
        error={props.hasError}
        disabled={!!props.isDisabled}
      />
      <Box
        marginTop='6px'
        {...secondaryBoxProps}
      >
        <RSelect
          ref={ref}
          {...props}
          styles={styles({
            options : styleOptions,
            hasError: (props.hasError && !props.isDisabled),
          })}
        />
      </Box>
    </Box>
  )
});
