import {notification} from 'antd';
import stripeJs from '@stripe/stripe-js';
import {_fetchPortal} from './fetchPortal';
import {Globals} from '../../../types/globals';
import {UseFormSetError} from 'react-hook-form';
import {ApiRoutes} from '../../../constants/apiRoutes';

export const _createPaymentMethod = (
  {
    card,
    stripe,
    onClose,
    setError,
    formValues,
  }: {
    onClose?: () => void;
    stripe: stripeJs.Stripe;
    formValues: Record<string, any>;
    card: stripeJs.StripeCardElement;
    setError: UseFormSetError<Record<string, any>>;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  try {
    const {
      hasError,
      body: {
        message,
        data: intentData,
      }
    } = await api.get(ApiRoutes.SETUP_INTENT);
    if (hasError) {
      setError('name', {message});
    } else {
      const payload = await stripe.confirmCardSetup(intentData?.client_secret, {
        payment_method: {
          card,
          billing_details: {
            name   : formValues.name,
            address: {
              city       : formValues.city,
              line1      : formValues.line1,
              line2      : formValues.line2,
              postal_code: formValues.postal_code,
              state      : formValues.state?.value || formValues.state,
              country    : formValues.country?.value || formValues.country,
            },
          }
        },
      });

      if (payload.error) {
        console.error(payload);
        if (payload.error.message) {
          setError('cardField', {message: payload.error.message});
        }
      } else {
        const {
          hasError,
          body: {
            data,
            message,
          }
        } = await api.post(ApiRoutes.CREATE_PAYMENT_METHOD, {
          payment_method: payload.setupIntent.payment_method
        });

        if (hasError) {
          console.error(data, message);
          if (message) {
            setError('cardField', {message});
          }
        } else {
          if (typeof onClose === 'function') {
            onClose();
          }
          await dispatch(_fetchPortal());

          notification.success({message: data.message});
        }
      }
    }
  } catch (e) {
    if (e.message) {
      setError('cardField', {message: e.message});
    }
  }
};