import React from 'react';
import {Input} from 'antd';
import {Select} from 'antd';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {_tags} from '../../../../../store/selectors/templates';
import {useSmartPush} from '../../../../../hooks/useSmartPush';
import {getPrefixCls} from '../../../../../utils/getPrefixCls';
import {FiniteStates} from '../../../../../constants/finiteStates';
import {_tagsArray} from '../../../../../store/selectors/templates';
import {_dataOptions} from '../../../../../store/actions/templates';
import {_searchText} from '../../../../../store/selectors/templates';
import {_hasMoreTags} from '../../../../../store/selectors/templates';
import {_tagsSearchText} from '../../../../../store/selectors/templates';
import {_fetchTags} from '../../../../../store/actions/templates/fetchTags';
import {_fetchingTemplatesState} from '../../../../../store/selectors/templates';
import {_fetchingTemplateTagsState} from '../../../../../store/selectors/templates';
import './index.less';

const {Search} = Input;

export function TableHeader() {
  return (
    <div
      className={`d-flex align-items-center ${getPrefixCls('templates-table-header')}`}
    >
      <SearchFilter/>
      <TagsFilter/>
    </div>
  )
}

function TagsFilter() {
  const dispatch = useDispatch();
  const tags = useSelector(_tags);
  const {appUuid} = useSmartPush();
  const tagsArray = useSelector(_tagsArray);
  const hasMoreTags = useSelector(_hasMoreTags);
  const searchText = useSelector(_tagsSearchText);
  const fetchingTemplateTagsState = useSelector(_fetchingTemplateTagsState);

  React.useEffect(() => {
    if (hasMoreTags) {
      dispatch(_fetchTags(appUuid));
    }
  }, [
    appUuid,
    dispatch,
    searchText,
    hasMoreTags,
  ]);

  const onChange = React.useCallback((value: string[]) => {
    dispatch(_dataOptions({
      tags: value as string[]
    }));
  }, [
    dispatch,
  ]);

  const onSearch = React.useCallback((value: string) => {
    dispatch(_dataOptions({
      tagsPageNumber: 1,
      hasMoreTags   : true,
      tagsSearchText: value,
    }));
  }, [
    dispatch,
  ]);

  const onPopupScroll = React.useCallback((e) => {
    if (hasMoreTags) {
      const {target} = e as any;

      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        if (hasMoreTags) {
          dispatch(_fetchTags(appUuid));
        }
      }
    }
  }, [
    appUuid,
    dispatch,
    hasMoreTags,
  ]);

  return (
    <Select
      allowClear
      showSearch
      value={tags}
      mode='multiple'
      options={tagsArray}
      onSearch={onSearch}
      onChange={onChange}
      maxTagCount='responsive'
      placeholder='Filter by tags'
      onPopupScroll={onPopupScroll}
      className={getPrefixCls('templates-table-header-select')}
      loading={fetchingTemplateTagsState === FiniteStates.LOADING}
    />
  )
}

function SearchFilter() {
  const dispatch = useDispatch();
  const searchText = useSelector(_searchText);
  const templatesState = useSelector(_fetchingTemplatesState);

  const onSearch = React.useCallback((value) => {
    dispatch(_dataOptions({
      pageNumber: 1,
      searchText: value,
    }));
  }, [
    dispatch,
  ]);

  const onChange = React.useCallback((event) => {
    if (!event.target.value) {
      dispatch(_dataOptions({
        pageNumber: 1,
        searchText: void 0,
      }));
    }
  }, [
    dispatch,
  ]);

  return (
    <Search
      onChange={onChange}
      onSearch={onSearch}
      defaultValue={searchText}
      placeholder='Search by ID, Title, and Message...'
      disabled={templatesState === FiniteStates.LOADING}
      className={getPrefixCls('templates-table-header-search')}
    />
  )
}
