export const FEATURE = 'frs';

export const FEATURE_GROUP = 'fg';

export enum ACCESS_TYPES {
  READ = 'r',
  SEND = 's',
  UPDATE = 'u',
  CREATE = 'c',
  DELETE = 'd',
  VISIBLE = 'v',
  CANCEL = 'cl',
  DISPLAY_UPGRADE = 'd_u',
  VISIBLE_DATA = 'v_d',
}

export const ACCESS_TYPES_BY_STRING: {[key: string]: string} = {
  r: 'Read',
  s: 'Send',
  u: 'Update',
  c: 'Create',
  d: 'Delete',
  v: 'Visible',
  cl: 'Cancel',
  d_u: 'Display upgrade',
  v_d: 'Visible data',
};

export const orderedAccessTypes: any[] = [
  ACCESS_TYPES.VISIBLE,
  ACCESS_TYPES.DISPLAY_UPGRADE,
  ACCESS_TYPES.READ,
  ACCESS_TYPES.VISIBLE_DATA,
  ACCESS_TYPES.CREATE,
  ACCESS_TYPES.UPDATE,
  ACCESS_TYPES.DELETE,
  ACCESS_TYPES.SEND,
  ACCESS_TYPES.CANCEL,
];

export const orderedAclKeys: any = [
  {
    slug           : 'apps',
    [FEATURE]      : [
      {
        slug           : 'locales',
        [FEATURE_GROUP]: orderedAccessTypes,
      },
      {
        slug           : 'webhooks',
        [FEATURE_GROUP]: orderedAccessTypes,
      },
      {
        slug           : 'force-prompt',
        [FEATURE_GROUP]: orderedAccessTypes,
      },
      {
        slug           : 'timezone-send',
        [FEATURE_GROUP]: orderedAccessTypes,
      },
      {
        slug           : 'conversions',
        [FEATURE_GROUP]: orderedAccessTypes,
      },
    ],
    [FEATURE_GROUP]: orderedAccessTypes
  },
  {
    slug           : 'app-settings',
    [FEATURE_GROUP]: orderedAccessTypes,
  },
  {
    slug           : 'users',
    [FEATURE_GROUP]: orderedAccessTypes,
  },
  {
    slug           : 'builder',
    [FEATURE_GROUP]: orderedAccessTypes,
  },
  {
    slug           : 'api-keys',
    [FEATURE_GROUP]: orderedAccessTypes,
  },
  {
    slug           : 'segments',
    [FEATURE_GROUP]: orderedAccessTypes,
  },
  {
    slug           : 'campaigns',
    [FEATURE_GROUP]: orderedAccessTypes,
  },
  {
    slug           : 'dashboard',
    [FEATURE_GROUP]: orderedAccessTypes,
  },
  {
    slug           : 'templates',
    [FEATURE_GROUP]: orderedAccessTypes,
  },
  {
    slug           : 'automations',
    [FEATURE_GROUP]: orderedAccessTypes,
  },
  {
    slug           : 'subscribers',
    [FEATURE_GROUP]: orderedAccessTypes,
  },
  {
    slug           : 'documentation',
    [FEATURE_GROUP]: orderedAccessTypes,
  },
  {
    slug           : 'welcome-messages',
    [FEATURE_GROUP]: orderedAccessTypes,
  }
];
