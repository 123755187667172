import React from 'react';
import {SendBy} from '../../SendBy';
import Box from '@material-ui/core/Box';
import {useTranslation} from 'react-i18next';
import {Select} from '../../../AsyncPaginate';
import {getLocales} from '../../../../constants';
import {UseFormReturn} from 'react-hook-form/dist/types';

export function SendByWrapper(
  {
    form,
    disabled,
    isToggling,
  }: {
    disabled?: boolean;
    isToggling?: boolean;
    form: UseFormReturn<Record<string, any>>;
  }
) {
  const {t} = useTranslation();
  const locales = React.useMemo(() => getLocales(t), [
    t,
  ]);

  if (isToggling) {
    return (
      <Box
        width={300}
        marginTop={-2}
        marginBottom={-2}
      >
        <Select
          label='Send by'
          options={locales}
          isDisabled={disabled}
          defaultValue={form.getValues('locale_type')}
        />
      </Box>
    )
  }

  return (
    <SendBy
      form={form}
      disabled={disabled}
    />
  )
}