import {ActionTypes} from '../../../constants/icons';
import {Builder} from '../../../../types/builder';

export const _updateFilters = (data: {[key: string]: any}) => ({
  payload: data,
  type   : ActionTypes.BUILDER_ICONS_UPDATE_FILTERS,
});

export const _setData = (data: Builder.IconEntity[]) => ({
  payload: data,
  type   : ActionTypes.BUILDER_ICONS_SET_DATA,
});

export const _deleteData = (data: number) => ({
  payload: data,
  type   : ActionTypes.BUILDER_ICONS_DELETE_DATA,
});

export const _setLoading = (data?: boolean) => ({
  payload: data,
  type   : ActionTypes.BUILDER_ICONS_SET_LOADING,
});
