import {ctr} from './ctr';
import produce from 'immer';
import moment from 'moment';
import {total} from './total';
import {Moment} from 'moment';
import {clicks} from './clicks';
import {closes} from './closes';
import {displays} from './displays';
import {combineReducers} from 'redux';
import {momentUTC} from '../../../utils';
import {unsubscribed} from './unsubscribed';
import {dailyInactive} from './dailyInactive';
import {Reports} from '../../../types/reports';
import {ActionTypes} from '../../constants/reports';
import {periodUnsubscribed} from './periodUnsubscribed';
import {FiniteStates} from '../../../constants/finiteStates';
import {ActionTypes as CtrActionTypes} from '../../constants/reports/ctr';
import {ActionTypes as CoreActionTypes} from '../../constants/application';
import {ActionTypes as TotalActionTypes} from '../../constants/reports/total';
import {ActionTypes as ClosesActionTypes} from '../../constants/reports/closes';
import {ActionTypes as ClicksActionTypes} from '../../constants/reports/clicks';
import {ActionTypes as DisplaysActionTypes} from '../../constants/reports/displays';
import {ActionTypes as UnsubscribedActionTypes} from '../../constants/reports/unsubscribed';
import {ActionTypes as DailyInactiveActionTypes} from '../../constants/reports/dailyInactive';
import {ActionTypes as PeriodUnsubscribedActionTypes} from '../../constants/reports/periodUnsubscribed';

const m0 = moment(moment().utc().startOf('day').subtract(4, 'days'), 'ddd, MMM. D, YYYY');
const m1 = moment(moment().utc().startOf('day'), 'ddd, MMM. D, YYYY');

function setTableDates(mm0: Moment, mm1: Moment, prevTable?: any[]) {
  const diff = mm1.diff(mm0, 'days');

  const rows: any[] = [];

  for (let i = 0; i <= diff; i++) {
    let currentM = moment(mm0).utc().add(i, 'day').format('YYYY-MM-DD');
    const row = prevTable?.[i];

    rows.push({
      ctr               : '#',
      total             : '#',
      clicks            : '#',
      closes            : '#',
      displays          : '#',
      subscribed        : '#',
      unsubscribed      : '#',
      dailyInactive     : '#',
      periodUnsubscribed: '#',
      ...row,
      date: currentM,
    });
  }

  return rows;
}

const InitialState: Reports.Index.Reducer = {
  dates         : [
    m0,
    m1,
  ],
  showTable     : false,
  state         : FiniteStates.IDLE,
  reportsTable  : setTableDates(m0, m1),
  selectedCharts: JSON.parse(localStorage.getItem('selected_charts')) || [],
};

export const index = produce((draft, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.REPORTS_SET_DATES: {
      draft.dates = payload;

      const reportsTable = draft.reportsTable
      draft.reportsTable = setTableDates(payload[0], payload[1], [
        ...reportsTable,
      ]);

      return void 0;
    }
    case ActionTypes.REPORTS_SET_STATE: {
      draft.state = payload;

      return void 0;
    }
    case ActionTypes.REPORTS_TOGGLE_TABLE: {
      draft.showTable = payload;

      return void 0;
    }
    case ActionTypes.REPORTS_SET_APP_UUID: {
      draft.appUuid = payload;

      return void 0;
    }
    case ActionTypes.REPORTS_SET_SELECTED_CHARTS: {
      draft.selectedCharts = payload;
      localStorage.setItem('selected_charts', JSON.stringify(payload));

      return void 0;
    }
    case CoreActionTypes.LOGOUT: {
      return {...InitialState}
    }
    case TotalActionTypes.REPORTS_TOTAL_SET_DATA: {
      const copy = draft.reportsTable;
      draft.reportsTable = copy.map((item) => {
        const current: any = payload.find((subItem: any) => {
          return momentUTC(subItem[0]).format('YYYY-MM-DD') === item.date
        });
        return {
          ...item,
          total: current?.[1] ?? '-',
        }
      });
      return void 0;
    }
    case DailyInactiveActionTypes.REPORTS_DAILY_INACTIVE_SET_DATA: {
      const copy = draft.reportsTable;
      draft.reportsTable = copy.map((item) => {
        const current: any = payload.find((subItem: any) => {
          return momentUTC(subItem[0]).format('YYYY-MM-DD') === item.date
        });
        return {
          ...item,
          dailyInactive: current?.[1] ?? '-',
        }
      });
      return void 0;
    }
    case DisplaysActionTypes.REPORTS_DISPLAYS_SET_DATA: {
      const copy = draft.reportsTable;
      draft.reportsTable = copy.map((item) => {
        const current: any = payload.find((subItem: any) => {
          return momentUTC(subItem[0]).format('YYYY-MM-DD') === item.date
        });
        return {
          ...item,
          displays: current?.[1] ?? '-',
        }
      });
      return void 0;
    }
    case ClicksActionTypes.REPORTS_CLICKS_SET_DATA: {
      const copy = draft.reportsTable;
      draft.reportsTable = copy.map((item) => {
        const current: any = payload.find((subItem: any) => {
          return momentUTC(subItem[0]).format('YYYY-MM-DD') === item.date
        });
        return {
          ...item,
          clicks: current?.[1] ?? '-',
        }
      });
      return void 0;
    }
    case ClosesActionTypes.REPORTS_CLOSES_SET_DATA: {
      const copy = draft.reportsTable;
      draft.reportsTable = copy.map((item) => {
        const current: any = payload.find((subItem: any) => {
          return momentUTC(subItem[0]).format('YYYY-MM-DD') === item.date
        });
        return {
          ...item,
          closes: current?.[1] ?? '-',
        }
      });
      return void 0;
    }
    case CtrActionTypes.REPORTS_CTR_SET_DATA: {
      const copy = draft.reportsTable;
      draft.reportsTable = copy.map((item) => {
        const current: any = payload.find((subItem: any) => {
          return momentUTC(subItem[0]).format('YYYY-MM-DD') === item.date
        });

        return {
          ...item,
          ctr: current[1] ?? '-',
        }
      });
      return void 0;
    }
    case UnsubscribedActionTypes.REPORTS_UNSUBSCRIBED_SET_DATA: {
      const copy = draft.reportsTable;
      draft.reportsTable = copy.map((item) => {
        const current: any = payload.find((subItem: any) => {
          return momentUTC(subItem[0]).format('YYYY-MM-DD') === item.date
        });

        return {
          ...item,
          unsubscribed: current[1] ?? '-',
        }
      });
      return void 0;
    }
    case PeriodUnsubscribedActionTypes.REPORTS_PERIOD_UNSUBSCRIBED_SET_DATA: {
      const copy = draft.reportsTable;

      const subscribed = payload[1];
      const periodUnsubscribed = payload[0];

      draft.reportsTable = copy.map((item) => {
        const currentPeriodUnsubscribed: any = periodUnsubscribed.find((subItem: any) => {
          return momentUTC(subItem[0]).format('YYYY-MM-DD') === item.date
        });

        const currentSubscribed: any = subscribed.find((subItem: any) => {
          return momentUTC(subItem[0]).format('YYYY-MM-DD') === item.date
        });

        return {
          ...item,
          subscribed        : currentSubscribed[1] ?? '-',
          periodUnsubscribed: currentPeriodUnsubscribed[1] ?? '-',
        }
      });
      return void 0;
    }
    default:
      return void 0;
  }
}, InitialState);

export const reports = combineReducers({
  ctr,
  index,
  total,
  clicks,
  closes,
  displays,
  unsubscribed,
  dailyInactive,
  periodUnsubscribed,
})