import React from 'react';
import {Input} from 'antd';
import {AutoComplete} from 'antd';
import {getPrefixCls} from '../../../../utils/getPrefixCls';

const lineStyle: React.CSSProperties = {
  left           : 0,
  height         : 3,
  bottom         : 0,
  width          : '100%',
  backgroundColor: '#096DD9',
  position       : 'absolute',
}

export function SurveyAutocomplete(
  {
    open,
    value,
    onBlur,
    onFocus,
    options,
    onClick,
    onSelect,
    onChange,
    onSearch,
    autoFocus,
    placeholder,
    inputStyles,
    dropdownStyle,
  }: {
    open: any;
    value: any;
    onBlur: any;
    options: any;
    onClick: any;
    onFocus: any;
    onSelect: any;
    onChange?: any;
    onSearch?: any;
    autoFocus: any;
    onMouseDown: any;
    onPressEnter: any;
    placeholder?: any;
    inputStyles?: React.CSSProperties;
    dropdownStyle?: React.CSSProperties;
  }
) {
  const ref = React.useRef<HTMLHeadingElement>(null);
  const inputRef = React.useRef<any>(null);

  const [width, setWidth] = React.useState<number>(200)

  React.useEffect(() => {
    if (ref.current?.offsetWidth) {
      setWidth(ref.current?.offsetWidth)
    }
  }, [
    ref.current?.offsetWidth
  ]);

  return (
    <>
      <div
        style={{position: 'relative'}}
      >
        {!value && (
          <div
            style={{
              ...lineStyle,
              width,
              minWidth: inputStyles?.minWidth || 'unset'
            }}
          />
        )}
        <div
          style={{
            width   : !open ? 0 : width,
            minWidth: !open ? 0 : (inputStyles?.minWidth || 'unset')
          }}
        >
          <AutoComplete
            open={open}
            style={{
              opacity: (!open && !placeholder) ? 0 : 1,
            }}
            value={value}
            onBlur={onBlur}
            options={options}
            onFocus={onFocus}
            onSelect={onSelect}
            onChange={onChange}
            onSearch={onSearch}
            autoFocus={autoFocus}
            dropdownStyle={dropdownStyle}
          >
            <Input
              ref={inputRef}
              bordered={false}
              autoFocus={autoFocus}
              placeholder={placeholder}
              style={{width, ...inputStyles}}
              className={getPrefixCls('survey-wizardInput')}
            />
          </AutoComplete>
        </div>
      </div>
      <h3
        ref={ref}
        onClick={()=>{
          onClick();
          inputRef.current?.focus()
        }}
        style={{display: open ? 'none' : 'block'}}
        className='ant-typography sp-survey-colorBlue regular'
      >{value}
      </h3>
    </>
  )
}
