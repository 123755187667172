import React from 'react';
import Highcharts from 'highcharts/highstock';
import Typography from '@material-ui/core/Typography';
import HighchartsReact from 'highcharts-react-official';
import variablePie from 'highcharts/modules/variable-pie.js';
import makeStyles from '@material-ui/core/styles/makeStyles';

variablePie(Highcharts);

const useStyles = makeStyles(() => ({
  notShow   : {
    opacity: 0
  },
  subTitle  : {
    left          : 0,
    right         : 0,
    margin        : 0,
    height        : 48,
    top           : 176,
    zIndex        : 500,
    display       : 'flex',
    textAlign     : 'center',
    alignItems    : 'center',
    flexDirection : 'column',
    justifyContent: 'center',
    position      : 'absolute',
    '& > span'    : {
      fontSize  : 16,
      fontWeight: 'bold',
    }
  },
  chartTitle: {
    marginRight: 48,
    fontSize   : 18,
    color      : '#2D0940',
    fontWeight : 'bold'
  },
}));

export default function Chart(
  {
    title,
    innerItem,
    chartData,
    className,
    innerItemColor,
  }: any
) {
  const classes = useStyles();

  return (
    <Typography
      component='div'
      className={className}
      style={{position: 'relative'}}
    >
      <p
        className={classes.subTitle}
        style={{color: innerItemColor}}
      >{innerItem}
      </p>
      <HighchartsReact
        options={{
          series : [{
            minPointSize: 20,
            size        : 220,
            useHtml     : true,
            colorByPoint: true,
            showInLegend: true,
            dataLabels  : {
              enabled: false,
            },
            innerSize   : '50%',
            data        : chartData,
          }],
          tooltip: {
            useHTML  : true,
            formatter: function (): string {
              return `<span style="color:${(this as any).color}">●</span><b>${(this as any).key}</b>`;
            }
          },
          credits: {
            enabled: false,
          },
          legend : {
            itemMarginBottom: 5,
            floating        : true,
            align           : 'left',
            layout          : 'vertical',
            itemStyle       : {
              color: '#333333',
              font : '12px Roboto, sans-serif',
            },
          },
          chart  : {
            animation: false,
            margin   : [0, 0, 0, 0],
            type     : 'variablepie',
          },
          title  : {
            useHtml: true,
            text   : `<p class='${classes.chartTitle}'>${title}</p>`,
          },
        }}
        highcharts={Highcharts}
      />
    </Typography>
  );
}
