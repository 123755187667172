import {_setData} from './index';
import {_dataOptions} from './index';
import {stringify} from 'querystring';
import {momentTZ} from '../../../utils';
import {filterObject} from '../../../utils';
import {Globals} from '../../../types/globals';
import {Templates} from '../../../types/templates';
import {urlHelper} from '../../../utils/urlHelper';
import * as selectors from '../../selectors/templates';
import {ApiRoutes} from '../../../constants/apiRoutes';
import DATE_COLUMNS from '../../../constants/dateColumns';
import {FiniteStates} from '../../../constants/finiteStates';

export const _fetch = (appUuid: string): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    fetchingTemplatesState: FiniteStates.LOADING,
  }));

  const tags = selectors._tags(getState());
  const pageSize = selectors._pageSize(getState());
  const pageNumber = selectors._pageNumber(getState());
  const searchText = selectors._searchText(getState());
  const sorterOrder = selectors._sorterOrder(getState());
  const sorterField = selectors._sorterField(getState());

  let sortName = 'templates.created_at';
  switch (sorterField) {
    case 'ctr': {
      sortName = 'ctr_get';
      break;
    }
    case 'updated_at': {
      sortName = 'templates.updated_at';
      break;
    }
    case 'created_at': {
      sortName = 'templates.created_at';
      break;
    }
    case 'title': {
      sortName = 'templates.title';
      break;
    }
  }

  const params = stringify(filterObject({
    sortName,
    pageSize,
    pageNumber,
    searchText,
    tags     : tags?.join(','),
    sortOrder: (sorterOrder === 'ascend') ? 'asc' : 'desc',
  }));

  const {
    hasError,
    body: {
      data,
    },
  } = await api.get(`${urlHelper(ApiRoutes.LIST_TEMPLATES, {
    appUuid,
  })}?${params}`, {
    skipDelay: true,
  });

  if (!hasError) {
    dispatch(_dataOptions({
      total     : data.total,
      pageNumber: data.pageNumber,
    }));

    const createdAtFormat = (DATE_COLUMNS.find(dC => dC.column === 'created_at')).format;
    const updatedAtFormat = (DATE_COLUMNS.find(dC => dC.column === 'updated_at')).format;

    dispatch(_setData(data.rows.map((
      {
        updated_at,
        created_at,
        ...rest
      }: Templates.Entity) => {

      return {
        ...rest,
        created_at: momentTZ(created_at).format(createdAtFormat),
        updated_at: momentTZ(updated_at).format(updatedAtFormat),
      }
    })));
  }

  dispatch(_dataOptions({
    fetchingTemplatesState: FiniteStates.SUCCESS,
  }));
};