export function requestTransformer(formValues: Record<string, any>) {
  return {
    name : formValues.name?.trim(),
    query: formValues.query?.map(({_values, ...query}: Record<string, any>) => {
      let values: any = void 0;

      switch (query.type?.search) {
        case true: {
          if (Array.isArray(query.values)) {
            values = query.values?.map((v) => {
              return v.value;
            });
          } else {
            values = query.values?.value ? [
              query.values?.value,
            ] : void 0;
          }
          break;
        }
        case false: {
          switch (query.type?.type) {
            case 'number': {
              if (!!query.values && !Number.isNaN(query.values)) {
                values = [Number(query.values)]
              }
              break;
            }
            case 'string': {
              if ([
                'in',
                'not_in',
              ].includes(query.filter.value)) {
                values = _values?.map((v: any) => v?.value?.trim());
              } else {
                values = query.values ? [
                  query.values.trim(),
                ] : void 0;
              }
            }
          }
          break;
        }
        default: {
          values = void 0;
          break;
        }
      }

      return {
        ...query,
        values,
        type  : query.type?.value,
        filter: query.filter?.value,
      }
    })
  }
}

export function responseTransformer(dataFromServer: Record<string, any>, typeOptions: any[], {
  hasConversionsAcl,
}: {
  hasConversionsAcl?: boolean;
} = {}) {
  return {
    id   : dataFromServer.id,
    name : dataFromServer.name,
    query: (dataFromServer.query || []).map((q: any) => {
      let params: any = {};
      const typeOption = (typeOptions || []).find((opt: any) => opt.value === q.type);
      if (
        (typeOption.search === false) &&
        (typeOption.type === 'string') &&
        ['in', 'not_in'].includes(q.filter)
      ) {
        params = {
          values : '',
          _values: q.labels.options.map((o: string) => ({value: o})),
        }
      }
      const notArray = ((typeOption.search === false) && ['equal', 'not_equal'].includes(q.filter)) || (
        (typeOption.search === false) && (typeOption.type === 'number')
      )

      return {
        table : q.table,
        filter: {
          value: q.filter,
          label: ((typeOption.search === false) && (typeOption.type === 'number') && ![
            'sessions',
            'conversion_sum',
            'conversion_count',
          ].includes(typeOption.field)) ? words.params_1[q.filter] : words.params[q.filter],
        },
        values: notArray ? q.labels.options?.[0] : q.labels.options,
        type  : (typeOptions || []).find((opt: any) => opt.value === q.type),
        ...params
      };
    }).filter((i: any) => {
      if (hasConversionsAcl) {
        return true;
      }
      return ![
        'conversion_sum',
        'conversion_count',
        'last_converted_at',
      ].includes(i.type);
    })
  };
}

const words: Record<string, any> = {
  params  : {
    in          : 'Contains',
    equal       : 'Equals to',
    less_than   : 'Is less than',
    not_in      : 'Does not contain',
    not_equal   : 'Is not equal to',
    greater_than: 'Is greater than'
  },
  params_1: {
    in          : 'Is within',
    equal       : 'Equals to',
    not_in      : 'Is not within',
    less_than   : 'Is less than',
    not_equal   : 'Is not equal to',
    greater_than: 'Is greater than'
  },
}