import React from 'react';
import {Form} from 'antd';
import {Switch} from 'antd';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {_toggleTable} from '../../../../../../store/actions/reports';
import {FiniteStates} from '../../../../../../constants/finiteStates';
import {_reportsDates} from '../../../../../../store/selectors/reports';
import {_reportsState} from '../../../../../../store/selectors/reports';
import {_initReportsTable} from '../../../../../../store/actions/reports';
import {_reportsShowTable} from '../../../../../../store/selectors/reports';

const style: React.CSSProperties = {
  width   : 140,
  minWidth: 140,
}

export function ToggleTable() {
  const dispatch = useDispatch();
  const state = useSelector(_reportsState);
  const checked = useSelector(_reportsShowTable);
  const reportsDates = useSelector(_reportsDates);
  const {appUuid} = useParams<{ appUuid: string }>();

  React.useEffect(() => {
    return function () {
      dispatch(_toggleTable(false));
      localStorage.removeItem('table_mounted');
    }
  }, [
    dispatch,
  ]);

  function onChange(checked: boolean) {
    if (checked) {
      localStorage.setItem('table_mounted', 'mounted');
    } else {
      localStorage.removeItem('table_mounted');
    }

    dispatch(_toggleTable(checked));
    if (checked) {
      dispatch(_initReportsTable(appUuid, reportsDates))
    }
  }

  return (
    <Form.Item
      style={style}
      className='m-b-0'
      label='Show Table'
    >
      <Switch
        checked={checked}
        onChange={onChange}
        disabled={state !== FiniteStates.SUCCESS}
      />
    </Form.Item>
  )
}