import React from 'react';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {EmojiTextField} from '../../../EmojiAdornment';
import {Control} from 'react-hook-form/dist/types/form';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {UseFormGetValues} from 'react-hook-form/dist/types/form';

export function MessageTextField(
  {
    index,
    control,
    disabled,
    setValue,
    getError,
    getValues,
  }: {
    index: number;
    control: Control;
    disabled?: boolean;
    setValue: UseFormSetValue<Record<string, any>>;
    getValues: UseFormGetValues<Record<string, any>>;
    getError: (name: string, index: number) => string;
  }
) {
  const direction = useWatch({
    control,
    name: `messages.[${index}].direction`,
  });

  return (
    <Controller
      control={control}
      name={`messages.[${index}].message_text`}
      render={({field: {ref, ...fieldRest}}) => (
        <EmojiTextField
          inputRef={ref}
          label='Message'
          setValue={setValue}
          disabled={disabled}
          getValues={getValues}
          inputProps={{
            disabled: false,
            readOnly: disabled,
            style   : {direction},
          }}
          error={!!getError('message_text', index)}
          helperText={getError('message_text', index)}
          {...fieldRest}
        />
      )}
    />
  )
}