import {_upsertUser} from './index';
import {_fetch} from '../apps/fetch';
import {_apps} from '../../selectors/apps';
import {Globals} from '../../../types/globals';
import {dataLayer} from '../../../utils/dataLayer';
import {surveyState} from '../../../constants/survey';
import {tidioChat} from '../../../utils/tidioChatApi';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {Permissions} from '../../../constants/permission';

export const _me = (): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  const {
    body: {
      data,
      status,
      message,
    },
    hasError,
  } = await api.get(ApiRoutes.ME);

  let surveys = [];

  if ([
    surveyState.SKIPPED,
    surveyState.FINISHED,
  ].includes(data.user?.notes?.survey_state?.state)) {
    surveys = [{
      key  : 'state',
      value: data.user?.notes?.survey_state?.state,
    }]
  } else {
    const {
      body    : {
        data: _data,
      },
      hasError: _hasError,
    } = await api.get(ApiRoutes.SURVEY);
    if (!_hasError) {
      surveys = _data;
    }
  }

  if (!hasError) {
    const {
      user,
      email,
      ...rest
    } = data;

    if (user?.notes?.tracking === false) {
      (window as any)[`disableYaCounter${process.env.REACT_APP_YM_ACCOUNT}`] = true;
    } else {
      (window as any)[`disableYaCounter${process.env.REACT_APP_YM_ACCOUNT}`] = false;
    }

    localStorage.setItem('display_time_zone', user.display_time_zone_text);

    if (Number(user.role) === 1) {
      if (document.getElementById('tidio-chat')) {
        document.getElementById('tidio-chat').style.display = 'none';
      }
    } else {
      tidioChat().setVisitorData({
        distinct_id: user.uuid,
        email      : user.email,
        name       : user.first_name,
      });
    }

    dispatch(_upsertUser({
      ...user,
      plan              : user.plan ? user.plan.toLowerCase() : void 0,
      plan_template_name: user.plan_template_name ? user.plan_template_name.toLowerCase() : void 0,
      pending_email     : email,
      ...rest,
      response_status: 200,
      surveys,
      isAdmin        : () => Number(user?.role) === 1,
      canRead        : (aclKey: string) => !!user?.acl?.[aclKey]?.fg?.[Permissions.READ],
      canCreate      : (aclKey: string) => !!user?.acl?.[aclKey]?.fg?.[Permissions.CREATE],
      canUpdate      : (aclKey: string) => !!user?.acl?.[aclKey]?.fg?.[Permissions.UPDATE],
      canDelete      : (aclKey: string) => !!user?.acl?.[aclKey]?.fg?.[Permissions.DELETE],
      hasVisibility  : (aclKey: string) => !!user?.acl?.[aclKey]?.fg?.[Permissions.VISIBLE],
    }));

    await dispatch(_fetch())
    const firstApp = _apps(getState())?.[0];
    let params = {
      app_title   : '',
      app_site_url: '',
      app_uuid    : '',
    };
    if (firstApp) {
      params = {
        app_title   : firstApp.title,
        app_site_url: firstApp.domain,
        app_uuid    : firstApp.title,
      };
    }


    dataLayer({
      event                   : 'smartpush_me_api_call_success',
      user_uuid               : user.uuid,
      user_plan               : user.plan,
      user_email              : user.email,
      user_role               : Number(user.role),
      user_billing_cycle_count: user.billing_cycle,
      user_last_name          : user.last_name || '',
      user_first_name         : user.first_name || '',
      user_email_verified     : user.email_verified_at || '',
      user_timezone           : user.display_time_zone_text,
      ...params
    })
  } else {
    dispatch(_upsertUser({
      response_status : status,
      response_message: message,
    }));
  }
};
