import React from 'react';
import {useWatch} from 'react-hook-form';
import {Header} from '../../../../Common/Header';
import {ICreateAppForm} from '../../useUpsertApp';
import {Control} from 'react-hook-form/dist/types/form';

export function HeaderWrapper(
  {
    control,
  }: {
    control: Control<ICreateAppForm>;
  }
) {
  const title = useWatch({
    control,
    name: 'title',
  });
  const domain = useWatch({
    control,
    name: 'domain',
  });
  const sum = function () {
    return (
      Number(!!title?.trim()) +
      Number(!!domain?.trim())
    )
  }();

  return (
    <Header
      current={0}
      percent={(100 / 2) * sum}
    />
  )
}
