import {getState} from '../index';
import {createSelector} from 'reselect';

export const getBuilders = createSelector(getState, (state) => {
  return state.builder.builders;
});

export const getIconsAllData = createSelector(getBuilders, (builders) => {
  return builders.icons;
});

export const getImagesAllData = createSelector(getBuilders, (builders) => {
  return builders.images;
});

export const getTitlesAllData = createSelector(getBuilders, (builders) => {
  return builders.titles;
});

export const getMessagesAllData = createSelector(getBuilders, (builders) => {
  return builders.messages;
});

export const getFilters = createSelector(getBuilders, (builders) => {
  return builders.filters;
});
