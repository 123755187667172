import React from 'react';
import MuiToolbar from '@material-ui/core/Toolbar';
import {Toolbar} from '@devexpress/dx-react-grid-material-ui';

export default function ToolbarRootComponent(
  {
    children,
  }: Toolbar.RootProps
) {
  return (
    <MuiToolbar
      disableGutters
      children={children}
      style={{alignItems: 'center', marginBottom: 8, minHeight: 'unset'}}
    />
  )
}

