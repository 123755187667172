import produce from 'immer';
import {Templates} from '../../../types/templates';
import {ActionTypes} from '../../constants/templates';
import {FiniteStates} from '../../../constants/finiteStates';
import {ActionTypes as CoreActionTypes} from '../../constants/application';

const InitialState: Templates.Reducer = {
  dataOptions: {
    pageNumber                  : 1,
    tagsPageNumber              : 1,
    pageSize                    : 10,
    hasMoreTags                 : true,
    sorterOrder                 : 'descend',
    sorterField                 : 'created_at',
    detailsPageNumber           : 1,
    detailsPageSize             : 10,
    detailsSorterOrder          : 'descend',
    detailsSorterField          : 'data->wait_until',
    exportTemplatesState        : FiniteStates.IDLE,
    fetchingTemplateState       : FiniteStates.IDLE,
    fetchingTemplatesState      : FiniteStates.IDLE,
    fetchingTemplateTagsState   : FiniteStates.IDLE,
    fetchingTemplateDetailsState: FiniteStates.IDLE,
  },
};

export const templates = produce((draft, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.TEMPLATES_SET_DATA: {
      draft.entities = payload;

      return void 0;
    }
    case ActionTypes.TEMPLATES_SET_TAGS: {
      const newArray = [
        ...(draft?.tagsArray || []).map(tag => tag.value),
        ...(payload || []).map((tag: {
          value: string;
          label: string;
        }) => tag.value),
      ];

      draft.tagsArray = [...new Set(newArray)].map(tag => ({
        value: tag,
        label: tag,
      }));

      return void 0;
    }
    case ActionTypes.TEMPLATES_SET_DETAILS: {
      draft.entityDetails = payload;
      return void 0;
    }
    case ActionTypes.TEMPLATES_DATA_OPTIONS: {
      Object.keys(payload).forEach((key) => {
        (draft.dataOptions as any)[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.TEMPLATES_REMOVE_ENTITY: {
      draft.entity = void 0;
      draft.entityDetails = void 0;

      return void 0;
    }
    case ActionTypes.TEMPLATES_UPSERT_ENTITY: {
      if (!draft.entity) {
        draft.entity = {} as any;
      }
      Object.keys(payload).forEach((key) => {
        (draft.entity as any)[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.TEMPLATES_UPSERT_DATA_ITEM: {
      const index = draft.entities.findIndex((template) => template.id === payload.id);
      if (index !== -1) {
        Object.keys(payload).forEach((payloadKey) => {
          (draft.entities[index] as any)[payloadKey] = payload[payloadKey];
        });
      }

      return void 0;
    }
    case ActionTypes.TEMPLATES_UPSERT_DETAILS_DATA_ITEM: {
      const index = draft.entityDetails.findIndex((item) => item.uuid === payload.uuid);
      if (index !== -1) {
        Object.keys(payload).forEach((payloadKey) => {
          (draft.entityDetails[index] as any)[payloadKey] = payload[payloadKey];
        });
      }

      return void 0;
    }
    case CoreActionTypes.LOGOUT: {
      return {...InitialState}
    }
    default:
      return void 0;
  }
}, InitialState);