import {TFunction} from 'i18next';
import {filterObject} from './index';
import {DEFAULT_TEMPLATE_ID} from '../constants';
import {LOCALE_TYPE} from '../constants/localeType';

export function responseTransformer(dataFromServer: Record<string, any>, app: Record<string, any>, t: TFunction): Record<string, any> {
  const localeType: string = dataFromServer?.locale_type || LOCALE_TYPE.LANGUAGE;

  let values = app.languages;
  if (localeType === LOCALE_TYPE.COUNTRY) {
    values = app.countries;
  }

  const dMessage = dataFromServer?.messages?.default;

  const position = dataFromServer.position || dMessage.position || 'top_left';
  const defaultMessage = {
    _id               : 'default',
    iso               : 'default',
    text              : t('default'),
    position          : {
      value: position,
      label: t(`app_settings.${position}`)
    },
    template_html     : dMessage.template_html,
    message_text      : dataFromServer.text || dMessage.text,
    icon_url          : dataFromServer.icon_url || dMessage.icon_url,
    bg_color          : dataFromServer.bg_color || dMessage.bg_color,
    deny_text         : dataFromServer.deny_text || dMessage.deny_text,
    allow_text        : dataFromServer.allow_text || dMessage.allow_text,
    direction         : dataFromServer.direction || dMessage.direction || 'ltr',
    deny_text_color   : dataFromServer.deny_text_color || dMessage.deny_text_color,
    allow_text_color  : dataFromServer.allow_text_color || dMessage.allow_text_color,
    deny_button_color : dataFromServer.deny_button_color || dMessage.deny_button_color,
    allow_button_color: dataFromServer.allow_button_color || dMessage.allow_button_color,
    message_text_color: dataFromServer.message_text_color || dMessage.message_text_color || '#000000',
    template_id       : Number(dataFromServer.template_id) || Number(dMessage.template_id) || DEFAULT_TEMPLATE_ID,
  };

  const messages: Record<string, any> = filterObject({
    ...dataFromServer?.messages,
    default: void 0,
  });

  const newMessages = Object.keys(messages).reduce((acc: any, current) => {
    const value = values.find((v: {
      _id: number;
      iso: string;
      text: string;
    }) => v.iso === current);
    const message = messages?.[current];

    if (value) {
      const position = message.position || 'top_left';
      acc.push({
        iso               : value.iso,
        text              : value.text,
        message_text      : message.text,
        icon_url          : message.icon_url,
        bg_color          : message.bg_color,
        deny_text         : message.deny_text,
        allow_text        : message.allow_text,
        template_id       : message.template_id,
        position          : {
          value: position,
          label: t(`app_settings.${position}`)
        },
        template_html     : message.template_html,
        deny_text_color   : message.deny_text_color,
        allow_text_color  : message.allow_text_color,
        deny_button_color : message.deny_button_color,
        _id               : Number(current) || current,
        direction         : message.direction || 'ltr',
        allow_button_color: message.allow_button_color,
        message_text_color: message.message_text_color || '#000000',
      });
    }

    return acc;
  }, []);
  newMessages.unshift(defaultMessage);

  let options: Record<string, string | number> = {};
  const displayType = dataFromServer.display_type ? dataFromServer.display_type : (dataFromServer.auto_show === true ? 'delay' : 'manual');

  switch (displayType) {
    case 'delay': {
      options = {
        page_viewed: 3,
        percentage : 50,
        delay      : Number(dataFromServer.delay) || 1000,
      }
      break;
    }
    case 'page_viewed': {
      options = {
        percentage : 50,
        delay      : 1000,
        page_viewed: dataFromServer.page_viewed || 3,
      }
      break;
    }
    case 'percentage': {
      options = {
        page_viewed: 3,
        delay      : 1000,
        percentage : dataFromServer.percentage || 50,
      }
      break;
    }
    case 'manual': {
      options = {
        page_viewed: 3,
        percentage : 50,
        delay      : 1000,
      }
      break;
    }
  }

  return {
    ...options,
    tab_value            : 'default',
    display_type         : displayType,
    messages             : newMessages,
    id                   : dataFromServer.id,
    ttl                  : dataFromServer.ttl,
    locale_type          : {
      value: localeType,
      label: t(`locale.${localeType}`),
    },
    app_uuid             : dataFromServer.app_uuid,
    subscription_enabled : app.subscription_enabled,
    custom_prompt_enabled: app.custom_prompt_enabled,
    has_locales          : !!Object.keys(messages).length,
    auto_show            : Number(dataFromServer.auto_show),
    dimmed               : dataFromServer.dimmed ? dataFromServer.dimmed : false,
  };
}

export function requestTransformer(formData: Record<string, any>): Record<string, any> {
  let options: Record<string, string | number> = {};
  const displayType = formData.display_type;

  switch (displayType) {
    case 'delay': {
      options = {
        delay: formData.delay,
      }
      break;
    }
    case 'page_viewed': {
      options = {
        page_viewed: formData.page_viewed,
      }
      break;
    }
    case 'percentage': {
      options = {
        percentage: formData.percentage,
      }
      break;
    }
    case 'manual': {
      options = {}
      break;
    }
  }

  return {
    ...options,
    display_type: displayType,
    ttl         : formData.ttl,
    delay       : formData.delay,
    auto_show   : Number(formData.auto_show),
    locale_type : formData.locale_type.value,
    dimmed      : formData.dimmed ? 'rgba(0,0,0,.5)' : null,
    messages    : (formData.messages || []).reduce((acc: any, message: any) => {
      acc[message.iso] = filterObject({
        ...message,
        _id         : void 0,
        iso         : void 0,
        message_text: void 0,
        template_id : message.template_id,
        text        : message.message_text,
        position    : message.position.value,
      });
      return acc;
    }, {}),
  };
}
