import {_dataOptions} from './index';
import {_upsertEntity} from './index';
import {sleep} from '../../../utils/sleep';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';

export const _saveStep = (
  {
    appUuid,
    redirect,
    creationStep,
  }: {
    appUuid: string;
    creationStep: number;
    redirect?: () => void;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    savingStep: true,
  }));

  const {
    body: {
      data
    },
    hasError,
  } = await api.post(urlHelper(ApiRoutes.SAVE_STEP, {
    appUuid,
  }), {
    creation_step: creationStep,
  });

  if (redirect) {
    await sleep(1000);
    await redirect();
  }

  if (!hasError) {
    dispatch(_upsertEntity(data))
  }
  dispatch(_dataOptions({
    savingStep: void 0,
  }));
};
