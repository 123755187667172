import {getState} from '../index';
import {createSelector} from 'reselect';

export const getTitles = createSelector(getState, (state) => {
  return state.builder.titles;
});

export const getTitlesData = createSelector(getTitles, (titles) => {
  return titles.dataOptions.entities;
});

export const getFetchState = createSelector(getTitles, (titles) => {
  return titles.dataOptions.fetched;
});

export const getTotal= createSelector(getTitles, (titles) => {
  return titles.dataOptions.total;
});

export const getFilters = createSelector(getTitles, (titles) => {
  return titles.filters;
});
