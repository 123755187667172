import React from 'react';
import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {UnregisterCallback} from 'history';
import {useTranslation} from 'react-i18next';
import {useTitle} from '../../../../../hooks/useTitle';
import {_entity} from '../../../../../store/selectors/prompt';
import {_create} from '../../../../../store/actions/prompt/create';
import {_subscriptionState} from '../../../../../store/selectors/apps';
import {_customPromptState} from '../../../../../store/selectors/apps';
import {_userAcl} from '../../../../../store/selectors/application/acl';
import {_onBeforeunload} from '../../../../../store/actions/application';
import {_isTogglingSubscription} from '../../../../../store/selectors/apps';
import {_canUpdateApp} from '../../../../../store/selectors/application/acl';
import {_hasLocalesAccess} from '../../../../../store/selectors/application/acl';

let unregisterCallback: UnregisterCallback | null = null;

const beforeUnload = function (e: BeforeUnloadEvent) {
  e.returnValue = 'Changes you made may not be saved.';

  return 'Changes you made may not be saved.';
}

export function usePrompt() {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userAcl = useSelector(_userAcl);
  useTitle(t('app_settings.prompt_settings'));
  const canUpdateApp = useSelector(_canUpdateApp);
  const {app_uuid, ...entity} = useSelector(_entity);
  const {appUuid} = useParams<{ appUuid: string }>();
  const hasLocalesAccess = useSelector(_hasLocalesAccess);
  const customPromptState = useSelector(_customPromptState);
  const subscriptionState = useSelector(_subscriptionState);
  const isTogglingSubscription = useSelector(_isTogglingSubscription);

  const form = useForm<Record<string, any>>({
    defaultValues: entity
  });
  const {
    control,
    setError,
    register,
    formState,
    handleSubmit,
  } = form;
  const {
    isDirty,
    dirtyFields,
    isSubmitting,
  } = formState;
  const hasDirtyFields = !!Object.keys(dirtyFields || {}).length;

  React.useEffect(() => {
    if (isDirty && hasDirtyFields) {
      unregisterCallback = history.block((location) => {
        dispatch(_onBeforeunload({
          open           : true,
          bodyTitle      : 'Warning',
          agreeAction    : () => {
            dispatch(_onBeforeunload(void 0));
            unregisterCallback && unregisterCallback();
            history.push(location);
          },
          onClose        : () => {
            dispatch(_onBeforeunload(void 0));
          },
          dismissAction  : () => {
            dispatch(_onBeforeunload(void 0));
          },
          dismissLabel   : 'Stay on Page',
          agreeLabel     : 'Discard Changes',
          bodyDescription: 'You have unsaved changes in your settings. If you continue, your changes will be lost.',
        }));

        return false;
      });

      window.addEventListener('beforeunload', beforeUnload);
    }

    return function () {
      unregisterCallback && unregisterCallback();

      window.removeEventListener('beforeunload', beforeUnload);
    }
  }, [
    history,
    isDirty,
    dispatch,
    hasDirtyFields,
  ]);

  const submit = React.useCallback((formValues: Record<string, any>) => {
    if (formValues.has_locales && !hasLocalesAccess) {
      return dispatch(_onBeforeunload({
        open           : true,
        agreeAction    : () => {
          dispatch(_onBeforeunload(void 0));

          return dispatch(_create(appUuid, formValues, {
            t,
            setError,
            unregisterCallback: () => {
              unregisterCallback && unregisterCallback();
              window.removeEventListener('beforeunload', beforeUnload);
            },
          }))
        },
        onClose        : () => {
          dispatch(_onBeforeunload(void 0));
        },
        dismissAction  : () => {
          dispatch(_onBeforeunload(void 0));
        },
        agreeLabel     : t('buttons.save'),
        dismissLabel   : t('buttons.cancel'),
        bodyTitle      : t('app_settings.warning_body_title'),
        bodyDescription: t('app_settings.warning_body_description'),
      }));
    } else {
      return dispatch(_create(appUuid, formValues, {
        t,
        setError,
        unregisterCallback: () => {
          unregisterCallback && unregisterCallback();
          window.removeEventListener('beforeunload', beforeUnload);
        },
      }))
    }
  }, [
    t,
    appUuid,
    setError,
    dispatch,
    hasLocalesAccess,
  ]);

  return {
    form,
    control,
    userAcl,
    register,
    isSubmitting,
    customPromptState,
    onSubmit: handleSubmit(submit),
    disabled: !subscriptionState || isTogglingSubscription || !canUpdateApp,
  }
}