import {Application} from '../../../types/application';
import {ActionTypes} from '../../constants/application';
import {FiniteStates} from '../../../constants/finiteStates';

export const _emptyForm = () => ({
  type: ActionTypes.EMPTY_FORM,
});

export const _upsertUser = (user: Partial<Application.User>) => ({
  payload: user,
  type   : ActionTypes.UPSERT_USER,
});

export const _upsertSidebarData = (payload: Record<string, any>) => ({
  payload,
  type: ActionTypes.UPSERT_SIDEBAR_DATA,
});

export const _upsertSurvey = (payload: Application.Survey) => ({
  payload,
  type: ActionTypes.UPSERT_SURVEY,
});

export const _onBeforeunload = (data: Record<string, any>) => ({
  payload: data,
  type   : ActionTypes.BEFORE_UNLOAD,
});

export const _upsertForm = (payload: Record<string, any>) => ({
  payload,
  type: ActionTypes.UPSERT_FORM,
});

export const _setApplicationStatus = (status: FiniteStates) => ({
  payload: status,
  type   : ActionTypes.SET_APPLICATION_STATUS,
});

export const _unauthorized = (isUnauthorized?: boolean) => ({
  payload: isUnauthorized,
  type   : ActionTypes.UNAUTHORIZED,
});

export const _suspended = (payload?: Record<string, any>) => ({
  payload,
  type: ActionTypes.SUSPENDED,
});

export const _upsertApplication = (payload?: Record<string, any>) => ({
  payload,
  type: ActionTypes.UPSERT_APPLICATION,
});
