import React from 'react';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {IntegrationTypes} from '../../../../CommonComponents';
import {_downloadingSWFile} from '../../../../../store/selectors/apps';
import {EIntegrationTypes} from '../../../../../constants/integrationTypes';
import {_downloadWorkerJS} from '../../../../../store/actions/apps/downloadWorkerJS';

export function Integrations() {
  const dispatch = useDispatch();
  const {appUuid} = useParams<{ appUuid: string }>();
  const downloading = useSelector(_downloadingSWFile);
  const [value, setValue] = React.useState<EIntegrationTypes>(EIntegrationTypes.simpleCode);

  const downloadWorkerJS = React.useCallback(() => {
    dispatch(_downloadWorkerJS(appUuid));
  }, [
    appUuid,
    dispatch,
  ]);

  return (
    <div
      className='d-flex align-items-center flex-column'
    >
      <IntegrationTypes
        value={value}
        downloading={downloading}
        downloadWorkerJS={downloadWorkerJS}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  )
}