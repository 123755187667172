import React from 'react';
import {Dispatch} from 'react';
import {SetStateAction} from 'react';
import {MenuFoldOutlined} from '@ant-design/icons';
import {MenuUnfoldOutlined} from '@ant-design/icons';
import './index.less';

export function SiderTrigger(
  {
    toggle,
    collapsed,
  }: {
    collapsed: boolean;
    toggle: Dispatch<SetStateAction<boolean | undefined>>;
  }
) {
  return (
    <div
      className='sider-trigger'
      onClick={() => {
        toggle((prev) => {
          localStorage.setItem('layout_sider', `${!prev}`)
          return !prev
        })
      }}
    >
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
      })}
    </div>
  )
}