import React from 'react';
import Title from './Title';
import Content from './Content';
import Actions from './Actions';
import {TitleProps} from './Title';
import {ActionProps} from './Actions';
import {ContentProps} from './Content';
import MuiDialog from '@material-ui/core/Dialog';
import {DialogProps} from '@material-ui/core/Dialog';

export type TColor = 'success' | 'danger' | 'warning' | 'primary'

export default function Dialog(
  {
    content,
    actions,
    children,
    formProps,
    PaperProps,
    dialogTitle,
    ...restProps
  }: Omit<IDialogProps, 'children'> & { children?: any, formProps?: any }
) {
  return (
    <MuiDialog
      scroll='body'
      PaperProps={{
        ...PaperProps,
        style: {
          overflowY: 'unset',
          ...PaperProps?.style
        }
      }}
      {...restProps}
    >
      {formProps ? (
        <form
          {...formProps}
        >
          {!!dialogTitle && (
            <Title
              {...dialogTitle}
            />
          )}
          {children ? (
            children
          ) : (
            !!content && (
              <Content
                {...content}
              />
            )
          )}
          {!!actions && (
            <Actions
              {...actions}
            />
          )}
        </form>
      ) : (
        <>
          {!!dialogTitle && (
            <Title
              {...dialogTitle}
            />
          )}
          {children ? (
            children
          ) : (
            !!content && (
              <Content
                {...content}
              />
            )
          )}
          {!!actions && (
            <Actions
              {...actions}
            />
          )}
        </>
      )}
    </MuiDialog>
  )
}

interface IDialogProps extends DialogProps {
  actions?: ActionProps;
  content?: ContentProps;
  dialogTitle?: TitleProps;
}
