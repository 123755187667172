import clsx from 'clsx';
import {Col} from 'antd';
import {Row} from 'antd';
import React from 'react';
import {SiteTitle} from './components/SiteTitle';
import {ICreateAppForm} from '../../useUpsertApp';
import {Control} from 'react-hook-form/dist/types/form';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {Text} from '../../../../../../../components/antd/Typography';
import {FieldNamesMarkedBoolean} from 'react-hook-form/dist/types/form';

export function SiteTitleComponent(
  {
    control,
    setValue,
    dirtyFields,
  }: {
    control: Control<ICreateAppForm>;
    setValue: UseFormSetValue<ICreateAppForm>;
    dirtyFields: FieldNamesMarkedBoolean<ICreateAppForm>
  }
) {
  const [isFocuses, toggleFocusState] = React.useState<boolean>(false);

  return (
    <Row
      gutter={24}
      align='middle'
      justify='space-between'
      className='create-app-site-info-row'
    >
      <Col
        xs={24}
        sm={12}
      >
        <SiteTitle
          control={control}
          setValue={setValue}
          isDirty={dirtyFields.title}
          toggleFocusState={toggleFocusState}
        />
      </Col>
      <Col
        xs={24}
        sm={12}
      >
        <Text
          fontWeight='regular'
          className={clsx('hoverable-item m-b-4 d-block', isFocuses && 'opacity-1')}
        >The name to call your app in Smartpush and your site in push notifications.
        </Text>
      </Col>
    </Row>
  )
}