import {_fetch} from './fetch';
import {notification} from 'antd';
import {_upsertDataItem} from './index';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _destroy = (id: number): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_upsertDataItem({
    id,
    deleting: FiniteStates.LOADING,
  }));

  const {
    hasError,
    body: {
      status,
      message,
    },
  } = await api.delete(urlHelper(ApiRoutes.DELETE_API_TOKEN, {id}));

  if (hasError) {
    if (status === 403) {
      notification.error({message: 'You don\'t have access to manage this action'});
    } else {
      notification.error({message});
    }

    dispatch(_upsertDataItem({
      id,
      deleting: FiniteStates.FAILURE,
    }));
  } else {
    notification.success({message});

    dispatch(_fetch()).catch(console.error);
  }
};
