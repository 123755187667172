export enum ActionTypes {
  WEB_NOTIFY_GALLERY_SET_DATA = 'WEB_NOTIFY_GALLERY_SET_DATA',
  WEB_NOTIFY_GALLERY_EMPTY_DATA = 'WEB_NOTIFY_GALLERY_EMPTY_DATA',
  WEB_NOTIFY_GALLERY_DATA_OPTIONS = 'WEB_NOTIFY_GALLERY_DATA_OPTIONS',
  WEB_NOTIFY_GALLERY_TABLE_OPTIONS = 'WEB_NOTIFY_GALLERY_TABLE_OPTIONS',
  WEB_NOTIFY_GALLERY_TOGGLE_ERRORS = 'WEB_NOTIFY_GALLERY_TOGGLE_ERRORS',
  WEB_NOTIFY_GALLERY_TOGGLE_DIALOG = 'WEB_NOTIFY_GALLERY_TOGGLE_DIALOG',
  WEB_NOTIFY_GALLERY_TOGGLE_ENTITY = 'WEB_NOTIFY_GALLERY_TOGGLE_ENTITY',
  WEB_NOTIFY_GALLERY_TOGGLE_LOADING = 'WEB_NOTIFY_GALLERY_TOGGLE_LOADING',
}
