import {Moment} from 'moment';
import {Dispatch} from 'redux';
import {OutputSelector} from 'reselect';
import {CancelTokenSource} from 'axios';
import {Globals} from '../../../types/globals';
import {Reports} from '../../../types/reports';
import {_fetch as _fetchCtr} from './ctr/fetch';
import {ActionTypes} from '../../constants/reports';
import {_fetch as _fetchTotal} from './total/fetch';
import {_fetch as _closesTotal} from './closes/fetch';
import {_fetch as _fetchClicks} from './clicks/fetch';
import {_selectedCharts} from '../../selectors/reports';
import {_fetch as _fetchDisplays} from './displays/fetch';
import {FiniteStates} from '../../../constants/finiteStates';
import {_fetch as _fetchUnsubscribed} from './unsubscribed/fetch';
import {_fetch as _fetchDailyInactive} from './dailyInactive/fetch';
import {_fetchingState as _ctrState} from '../../selectors/reports/ctr';
import {_fetchingState as _totalState} from '../../selectors/reports/total';
import {_fetchingState as _clicksState} from '../../selectors/reports/clicks';
import {_fetchingState as _closesState} from '../../selectors/reports/closes';
import {_fetch as _fetchPeriodUnsubscribed} from './periodUnsubscribed/fetch';
import {_fetchingState as _displaysState} from '../../selectors/reports/displays';
import {_fetchingState as _unsubscribedState} from '../../selectors/reports/unsubscribed';
import {_fetchingState as _dailyInactiveState} from '../../selectors/reports/dailyInactive';
import {_fetchingState as _periodUnsubscribedState} from '../../selectors/reports/periodUnsubscribed';

export const _initReports = (
  appUuid: string,
  dates: [Moment, Moment],
  {
    cancelTokenSource,
  }: {
    cancelTokenSource?: CancelTokenSource
  } = {}
): Globals.ThunkAction => async (dispatch, getState) => {
  dispatch(_setState(FiniteStates.LOADING));

  dispatch(_allDataOptions({
    appUuid,
    fetchingState: FiniteStates.IDLE,
  }));

  const available = _selectedCharts(getState());
  const fetches = fetchMapping({appUuid, dates, dispatch});

  for (const f of fetches) {
    const index = available.findIndex((a) => a.value === f.value);

    if ((index !== -1) || localStorage.getItem('table_mounted')) {
      if (!localStorage.getItem('mounted')) {
        break;
      }
      await f.fetch(cancelTokenSource)
    }
  }

  dispatch(_setAppUuid(appUuid));

  dispatch(_setState(FiniteStates.SUCCESS));
};

export const _initReportsTable = (
  appUuid: string,
  dates: [Moment, Moment],
): Globals.ThunkAction => async (dispatch, getState) => {
  dispatch(_setState(FiniteStates.LOADING));

  const fetches = fetchMapping({appUuid, dates, dispatch});

  for (const f of fetches) {
    if (!localStorage.getItem('mounted')) {
      break;
    }

    if (!localStorage.getItem('table_mounted')) {
      break;
    }

    if (f.finiteState()(getState()) === FiniteStates.IDLE) {
      await f?.fetch();
    }
  }

  dispatch(_setAppUuid(appUuid));

  dispatch(_setState(FiniteStates.SUCCESS));
};

export const _setDates = (data: Reports.Index.Dates) => ({
  payload: data,
  type   : ActionTypes.REPORTS_SET_DATES,
});

export const _toggleTable = (payload: boolean) => ({
  payload,
  type: ActionTypes.REPORTS_TOGGLE_TABLE,
});

export const _setState = (payload: FiniteStates) => ({
  payload,
  type: ActionTypes.REPORTS_SET_STATE,
});

export const _setAppUuid = (uuid?: string) => ({
  payload: uuid,
  type   : ActionTypes.REPORTS_SET_APP_UUID,
});

export const _setSelectedCharts = (data: Reports.Index.SelectedCharts) => ({
  payload: data,
  type   : ActionTypes.REPORTS_SET_SELECTED_CHARTS,
});

export const _resetReports = () => ({
  type: ActionTypes.REPORTS_RESET,
});

export const _allDataOptions = (payload: Partial<Reports.Chart.DataOptions>) => ({
  payload,
  type: ActionTypes.REPORTS_ALL_DATA_OPTIONS,
});

export function fetchMapping(
  {
    dates,
    appUuid,
    dispatch,
  }: {
    appUuid: string;
    dates: [Moment, Moment];
    dispatch: Dispatch<any>;
  }
): {
  value: string;
  fetch: (cancelTokenSource?: CancelTokenSource) => void;
  finiteState?: () => OutputSelector<any, any, any>;
}[] {
  return [
    {
      fetch      : async (cancelTokenSource) => {
        try {
          await dispatch(_fetchPeriodUnsubscribed(appUuid, dates, {
            cancelTokenSource,
          }));
        } catch (e) {
          console.error(e)
        }
      },
      finiteState: () => _periodUnsubscribedState,
      value      : 'period_unsubscribed_and_subscribed',
    },
    {
      value      : 'dailyInactive',
      fetch      : async (cancelTokenSource) => {
        try {
          await dispatch(_fetchDailyInactive(appUuid, dates, {
            cancelTokenSource,
          }));
        } catch (e) {
          console.error(e)
        }
      },
      finiteState: () => _dailyInactiveState,
    },
    {
      value      : 'total',
      fetch      : async (cancelTokenSource) => {
        try {
          await dispatch(_fetchTotal(appUuid, dates, {
            cancelTokenSource
          }));
        } catch (e) {
          console.error(e)
        }
      },
      finiteState: () => _totalState,
    },
    {
      value      : 'unsubscribed',
      fetch      : async (cancelTokenSource) => {
        try {
          await dispatch(_fetchUnsubscribed(appUuid, dates, {
            cancelTokenSource,
          }));
        } catch (e) {
          console.error(e)
        }
      },
      finiteState: () => _unsubscribedState,
    },
    {
      value      : 'clicks',
      fetch      : async (cancelTokenSource) => {
        try {
          await dispatch(_fetchClicks(appUuid, dates, {
            cancelTokenSource,
          }));
        } catch (e) {
          console.error(e)
        }
      },
      finiteState: () => _clicksState,
    },
    {
      value      : 'closes',
      fetch      : async (cancelTokenSource) => {
        try {
          await dispatch(_closesTotal(appUuid, dates, {
            cancelTokenSource,
          }));
        } catch (e) {
          console.error(e)
        }
      },
      finiteState: () => _closesState,
    },
    {
      value      : 'displays',
      fetch      : async (cancelTokenSource) => {
        try {
          await dispatch(_fetchDisplays(appUuid, dates, {
            cancelTokenSource,
          }));
        } catch (e) {
          console.error(e)
        }
      },
      finiteState: () => _displaysState,
    },
    {
      value      : 'ctr',
      finiteState: () => _ctrState,
      fetch      : async (cancelTokenSource) => {
        try {
          await dispatch(_fetchCtr(appUuid, dates, {
            cancelTokenSource,
          }));
        } catch (e) {
          console.error(e)
        }
      },
    },
  ]
}
