import {Globals} from '../../../types/globals';
import {ActionTypes} from '../../constants/locales';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _fetch = (): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch({
    payload: FiniteStates.LOADING,
    type   : ActionTypes.LOCALES_SET_STATUS,
  });

  const [
    rCountries,
    rLanguages,
  ]: Record<string, any>[] = await Promise.all([
    api.get(`${ApiRoutes.COUNTRIES}?pageSize=500`, {skipDelay: true}),
    api.get(`${ApiRoutes.LANGUAGES}?pageSize=500`, {skipDelay: true}),
  ]);

  if (!rCountries.hasError) {
    dispatch({
      payload: rCountries.body.data.items,
      type   : ActionTypes.LOCALES_SET_COUNTRIES,
    });
  } else {
    dispatch({
      payload: [],
      type   : ActionTypes.LOCALES_SET_COUNTRIES,
    });
  }

  if (!rLanguages.hasError) {
    dispatch({
      payload: rLanguages.body.data.items,
      type   : ActionTypes.LOCALES_SET_LANGUAGES,
    });
  } else {
    dispatch({
      payload: [],
      type   : ActionTypes.LOCALES_SET_LANGUAGES,
    });
  }

  dispatch({
    payload: FiniteStates.SUCCESS,
    type   : ActionTypes.LOCALES_SET_STATUS,
  });
};