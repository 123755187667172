import React from 'react';
import {Form} from 'antd';
import {Input} from 'antd';
import {Space} from 'antd';
import {Terms} from '../Terms';
import {Controller} from 'react-hook-form';
import {SubmitButton} from '../SubmitButton';
import {PrivacyPolicy} from '../PrivacyPolicy';
import {IRegisterForm} from '../../useRegister';
import {UseFormClearErrors} from 'react-hook-form';
import {ValidateStatus} from 'antd/lib/form/FormItem';
import {Control} from 'react-hook-form/dist/types/form';
import {dataPrefixes} from '../../../../../../../constants';
import {Text} from '../../../../../../../components/antd/Typography';
import {FormAlert} from '../../../../../../../components/antd/FormAlert';

const {Item} = Form;
const {Password} = Input;

const {
  auth: {
    register: registerPrefix
  }
} = dataPrefixes;

export function FormContent(
  {
    control,
    clearErrors,
    isSubmitting,
  }: {
    isSubmitting: boolean;
    control: Control<IRegisterForm>;
    clearErrors: UseFormClearErrors<IRegisterForm>
  }
) {
  return (
    <div>
      <Controller
        name='message'
        control={control}
        render={(
          {
            fieldState: {
              error,
            }
          }
        ) => {
          return (
            <FormAlert
              type='error'
              message={error?.message}
              onClose={() => clearErrors('message')}
            />
          )
        }}
      />
      <Form
        component='div'
        className='form'
        layout='vertical'
      >
        <Space
          size={16}
          align='start'
          className='d-flex justify-content-between'
        >
          <Controller
            name='first_name'
            control={control}
            render={(
              {
                field     : {
                  ref,
                  ...fieldRest
                },
                fieldState: {
                  error,
                  invalid,
                }
              }
            ) => {
              return (
                <Item
                  label='First Name'
                  validateStatus={invalid ? 'error' : void 0}
                  help={<span data-qa={`${registerPrefix}-form-item-first-name-explain-error`}>{error?.message}</span>}
                >
                  <Input
                    ref={ref}
                    autoFocus
                    autoComplete='off'
                    {...fieldRest}
                  />
                </Item>
              );
            }}
          />
          <Controller
            name='last_name'
            control={control}
            render={(
              {
                field     : {
                  ref,
                  ...fieldRest
                },
                fieldState: {
                  error,
                  invalid,
                }
              }
            ) => {
              return (
                <Item
                  label='Last Name'
                  validateStatus={invalid ? 'error' : void 0}
                  help={<span data-qa={`${registerPrefix}-form-item-last-name-explain-error`}>{error?.message}</span>}
                >
                  <Input
                    ref={ref}
                    autoComplete='off'
                    {...fieldRest}
                  />
                </Item>
              );
            }}
          />
        </Space>
        <Controller
          name='email'
          control={control}
          render={(
            {
              field     : {
                ref,
                ...fieldRest
              },
              fieldState: {
                error,
                invalid,
              }
            }
          ) => {
            return (
              <Item
                label='Email'
                validateStatus={invalid ? 'error' : void 0}
                help={<span data-qa={`${registerPrefix}-form-item-email-explain-error`}>{error?.message}</span>}
              >
                <Input
                  disabled
                  ref={ref}
                  autoComplete='off'
                  {...fieldRest}
                />
              </Item>
            );
          }}
        />
        <Controller
          name='password'
          control={control}
          render={(
            {
              field     : {
                ref,
                ...fieldRest
              },
              fieldState: {
                error,
                invalid,
              }
            }
          ) => {
            return (
              <Item
                label='Password'
                className='m-b-4'
                validateStatus={invalid ? 'error' : '' as ValidateStatus}
                help={!invalid ? 'At least 8 characters' : <span data-qa={`${registerPrefix}-form-item-password-explain-error`}>{error?.message}</span>}
              >
                <Password
                  ref={ref}
                  autoComplete='current-password'
                  {...fieldRest}
                />
              </Item>
            );
          }}
        />
        <div
          className='m-t-2 m-b-5'
        >
          <Text
            fontSize='small'
          >By signing up, you agree to Smartpush <Terms/> and <PrivacyPolicy/>
          </Text>
        </div>
        <Item>
          <SubmitButton
            control={control}
            loading={isSubmitting}
          />
        </Item>
      </Form>
    </div>
  )
}
