import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {Theme} from '@material-ui/core/styles';
import AutosizeInput from 'react-input-autosize';
import IconButton from '@material-ui/core/IconButton';
import {lastRowOnPage} from '@devexpress/dx-grid-core';
import {firstRowOnPage} from '@devexpress/dx-grid-core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {calculateStartPage} from '@devexpress/dx-grid-core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  pagination                : {
    margin: 0
  },
  simplePagination          : {
    display   : 'flex',
    alignItems: 'center',
  },
  rowsLabel                 : {
    ...theme.typography.caption,
    paddingRight: theme.spacing(5),
    fontSize    : 12,
  },
  button                    : {
    minWidth: theme.spacing(2)
  },
  activeButton              : {
    fontWeight: 'bold',
    color     : '#096dd9',
    cursor    : 'default',
  },
  arrowButton               : {
    display    : 'inline-block',
    transform  : theme.direction === 'rtl' ? 'rotate(180deg)' : void 0,
    msTransform: theme.direction === 'rtl' ? 'rotate(180deg)' : void 0,
  },
  prev                      : {
    marginRight: 0,
  },
  next                      : {
    marginLeft: 0,
  },
  pageInput                 : {
    '& > input': {
      marginLeft  : 8,
      paddingLeft : 5,
      paddingRight: 5,
      borderRadius: 0,
      fontSize    : 14,
      minWidth    : 64,
      width       : 120,
      lineHeight  : 1.75,
      boxShadow   : 'none',
      fontWeight  : 'bold',
      textAlign   : 'center',
      padding     : '6.5px 16px',
      boxSizing   : 'border-box',
      outline     : 'none !important',
      color       : theme.palette.text.primary,
      fontFamily  : 'Roboto, Helvetica, Arial sans-serif',
      border      : `1px solid ${theme.palette.text.primary}`,
      transition  : 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    }
  },
  pageInputSimple           : {
    '& > input': {
      marginLeft     : 8,
      marginRight    : 8,
      paddingLeft    : 5,
      paddingRight   : 5,
      borderRadius   : 0,
      width          : 50,
      lineHeight     : 1.75,
      boxShadow      : 'none',
      fontWeight     : 'bold',
      textAlign      : 'center',
      padding        : '6px 16px',
      fontSize       : '14px',
      boxSizing      : 'border-box',
      backgroundColor: 'transparent',
      outline        : 'none !important',
      border         : 'none !important',
      color          : theme.palette.text.primary,
      fontFamily     : 'Roboto, Helvetica, Arial sans-serif',
    }
  },
  '@media(max-width: 768px)': {
    button   : {
      display: 'none'
    },
    prev     : {
      marginRight: theme.spacing(1)
    },
    next     : {
      marginLeft: theme.spacing(1)
    },
    rowsLabel: {
      paddingRight: theme.spacing(2)
    }
  },
}));

const PageButton = (
  {
    text,
    classes,
    onClick,
    isActive,
    isDisabled,
  }: any
) => {
  const buttonClasses = clsx({
    [classes.button]      : true,
    [classes.activeButton]: isActive
  });

  return (
    <Button
      color='default'
      onClick={onClick}
      disabled={isDisabled}
      className={buttonClasses}
      {...(isActive ? {tabIndex: -1} : null)}
    >{text}
    </Button>
  );
};

PageButton.propTypes = {
  text      : PropTypes.string.isRequired,
  isActive  : PropTypes.bool,
  isDisabled: PropTypes.bool,
  classes   : PropTypes.object.isRequired,
  onClick   : PropTypes.func
};

PageButton.defaultProps = {
  onClick   : () => {
  },
  isDisabled: false,
  isActive  : false
};

const ellipsisSymbol = '\u2026';

const renderPageButtons: any = (
  currentPage: any,
  totalPageCount: any,
  classes: any,
  onCurrentPageChange: any
) => {
  const pageButtons = [];
  const maxButtonCount = 3;
  let startPage = 1;
  let endPage = totalPageCount || 1;

  if (maxButtonCount < totalPageCount) {
    startPage = calculateStartPage(
      currentPage + 1,
      maxButtonCount,
      totalPageCount
    );
    endPage = startPage + maxButtonCount - 1;
  }
  if (startPage > 1) {
    pageButtons.push(
      <PageButton
        key={1}
        classes={classes}
        text={String(1)}
        onClick={() => onCurrentPageChange(0)}
      />
    );

    if (startPage > 2) {
      pageButtons.push(
        <PageButton
          isDisabled
          classes={classes}
          key='ellipsisStart'
          text={ellipsisSymbol}
        />
      );
    }
  }

  for (let page = startPage; page <= endPage; page += 1) {
    pageButtons.push(
      <PageButton
        key={page}
        classes={classes}
        text={String(page)}
        isDisabled={startPage === endPage}
        isActive={page === currentPage + 1}
        onClick={() => onCurrentPageChange(page - 1)}
      />
    );
  }

  if (endPage < totalPageCount) {
    if (endPage < totalPageCount - 1) {
      pageButtons.push(
        <PageButton
          isDisabled
          key='ellipsisEnd'
          classes={classes}
          text={ellipsisSymbol}
        />
      );
    }

    pageButtons.push(
      <PageButton
        classes={classes}
        key={totalPageCount}
        text={String(totalPageCount)}
        onClick={() => onCurrentPageChange(totalPageCount - 1)}
      />
    );
  }

  return pageButtons;
};

export const Pagination = (
  {
    pageSize,
    getMessage,
    totalPages,
    totalCount,
    currentPage,
    disableInput,
    hasMorePages,
    simplePagination,
    onCurrentPageChange,
  }: any
) => {
  const classes = useStyles();
  const to = lastRowOnPage(currentPage, pageSize, totalCount);
  const from = firstRowOnPage(currentPage, pageSize, totalCount);

  const [localCurrentPage, setLocalCurrentPage] = React.useState<number | string>('');

  const inputOnChange = React.useCallback((e) => {
    if (/^[0-9]*$/.test(e.target.value)) {
      if (simplePagination) {
        setLocalCurrentPage(e.target.value);
      } else {
        if ((Number(e.target.value) <= totalPages)) {
          setLocalCurrentPage(e.target.value);
        }
      }
    }
  }, [
    totalPages,
    simplePagination,
  ]);

  const nextButtonOnClick = React.useCallback(() => {
    if (simplePagination) {
      onCurrentPageChange(currentPage + 1);
    } else {
      currentPage < totalPages - 1 && onCurrentPageChange(currentPage + 1);
    }
  }, [
    totalPages,
    currentPage,
    simplePagination,
    onCurrentPageChange,
  ]);

  const inputOnKeyPress = React.useCallback((e) => {
    if (e.key === 'Enter') {
      if (e.target.value) {
        onCurrentPageChange(Number(e.target.value) - 1)
      }
    }
  }, [
    onCurrentPageChange,
  ]);

  const previousButtonOnClick = React.useCallback(() => {
    currentPage > 0 && onCurrentPageChange(currentPage - 1)
  }, [
    currentPage,
    onCurrentPageChange,
  ]);

  React.useEffect(() => {
    setLocalCurrentPage(Number(currentPage) + 1);
  }, [
    currentPage,
  ]);

  return (
    <div
      data-id='pagination-root'
      className={classes.pagination}
    >
      {!simplePagination && (
        <span
          className={classes.rowsLabel}
        >{getMessage('info', {from, to, count: totalCount})}
        </span>
      )}
      <IconButton
        aria-label='Previous'
        disabled={currentPage === 0}
        data-id='pagination-previous'
        onClick={previousButtonOnClick}
        className={clsx(classes.arrowButton, classes.prev)}
      >
        <ChevronLeft/>
      </IconButton>
      {simplePagination ? (
        <AutosizeInput
          min={1}
          data-id='autosize-input'
          value={localCurrentPage}
          onChange={inputOnChange}
          onKeyPress={inputOnKeyPress}
          className={classes.pageInputSimple}
          max={!simplePagination ? totalPages : void 0}
        />
      ) : (
        renderPageButtons(currentPage, totalPages, classes, onCurrentPageChange)
      )}
      <IconButton
        aria-label='Next'
        data-id='pagination-next'
        onClick={nextButtonOnClick}
        className={clsx(classes.arrowButton, classes.next)}
        disabled={!simplePagination ? (currentPage === totalPages - 1 || totalCount === 0) : !hasMorePages}
      >
        <ChevronRight/>
      </IconButton>
      {!disableInput && !simplePagination && (
        <AutosizeInput
          data-id='autosize-input'
          value={localCurrentPage}
          onChange={inputOnChange}
          onKeyPress={inputOnKeyPress}
          className={classes.pageInput}
          max={!simplePagination ? totalPages : void 0}
        />
      )}
    </div>
  );
};
