import clsx from 'clsx';
import React from 'react';
import Box from '@material-ui/core/Box';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {Theme} from '@material-ui/core/styles';
import {useTheme} from '@material-ui/core/styles';
import {UseFormClearErrors} from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import states from '../../../../constants/states.json';
import {Control} from 'react-hook-form/dist/types/form';
import {Select} from '../../../../components/AsyncPaginate';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {UseFormSetError} from 'react-hook-form/dist/types/form';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {ErrorMessage} from '../../../../components/ErrorMessage';
import {TextField} from '../../../../components/BorderedTextField';
import {CardField} from '../../../Application/ManageAccount/Components/PaymentsAndSubscriptions/Components/PaymentMethodAndBillingAddress/Components/CardField';

const inputProps = {
  required: false,
  style   : {
    backgroundColor: '#ffffff',
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  root              : {
    '& fieldset': {
      borderWidth: '1px !important',
      borderColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  typography        : {
    fontSize  : 16,
    lineHeight: 1.625,
    color     : '#000000',
  },
  helperTypography  : {
    fontSize  : 12,
    lineHeight: 1.625,
    color     : 'rgba(0, 0, 0, .7)',
  },
  disabledTypography: {
    color: theme.palette.text.disabled,
  },
}));

export function PaymentDetails(
  {
    control,
    setValue,
    setError,
    countries,
    clearErrors,
    paymentMethod,
  }: {
    countries: Record<string, any>[];
    paymentMethod: Record<string, any>;
    control: Control<Record<string, any>>;
    setValue: UseFormSetValue<Record<string, any>>;
    setError: UseFormSetError<Record<string, any>>;
    clearErrors: UseFormClearErrors<Record<string, any>>;
  }
) {
  const theme = useTheme();
  const classes = useStyles();
  const card = useWatch({
    control,
    name: 'card',
  });
  const disabled = (card === 'existing_card') && !!paymentMethod;
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      paddingLeft={smDown ? 0 : 4}
    >
      <Box>
        <Box>
          <Typography
            className={clsx(
              classes.typography,
              disabled && classes.disabledTypography,
            )}
          >Payment Details
          </Typography>
          <Controller
            name='name'
            rules={{
              validate: (value) => {
                if (disabled) {
                  return true
                }
                if (!value?.trim()) {
                  return 'Name field is required';
                }
                return true;
              }
            }}
            control={control}
            render={(
              {
                field     : {
                  ref,
                  value,
                  ...fieldRest
                },
                fieldState: {
                  error,
                }
              }
            ) => {
              return (
                <TextField
                  required
                  fullWidth
                  size='small'
                  inputRef={ref}
                  margin='normal'
                  variant='outlined'
                  autoComplete='off'
                  disabled={disabled}
                  value={value || ''}
                  inputProps={inputProps}
                  error={!!error?.message}
                  helperText={error?.message}
                  placeholder='Cardholder Name*'
                  classes={{root: classes.root}}
                  {...fieldRest}
                />
              )
            }}
          />
          <Controller
            rules={{
              validate: (value) => {
                if (disabled) {
                  return true
                }
                if (!value?.complete) {
                  return 'Card field is required';
                }
                return true;
              }
            }}
            name='cardField'
            control={control}
            render={(
              {
                field     : {
                  onChange,
                },
                fieldState: {
                  error,
                }
              }
            ) => {
              return (
                <>
                  <CardField
                    color='white'
                    disabled={disabled}
                    onChange={(e) => {
                      onChange(e);
                      if (e.error?.message) {
                        setError('cardField', {
                          message: e.error?.message
                        })
                      } else {
                        clearErrors('cardField');
                      }
                    }}
                    hasError={!!error?.message}
                  />
                  <ErrorMessage
                    message={error?.message}
                  />
                </>
              )
            }}
          />
          <Box
            marginBottom={2}
          >
            <Typography
              className={clsx(
                classes.helperTypography,
                disabled && classes.disabledTypography,
              )}
            >* When changing your plan, we will charge the prorated difference between the new plan and your current
              plan.
            </Typography>
            <Typography
              className={clsx(
                classes.helperTypography,
                disabled && classes.disabledTypography,
              )}
            >Your credit card issuer may charge foreign transaction or cross-border fees in addition to the total price.
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            className={clsx(
              classes.typography,
              disabled && classes.disabledTypography,
            )}
          >Billing Details
          </Typography>
          <Box
            display='flex'
            flexDirection='column'
          >
            <Controller
              rules={{
                validate: (value) => {
                  if (disabled) {
                    return true
                  }
                  if (!value) {
                    return 'Country field is required';
                  }
                  return true;
                }
              }}
              name='country'
              control={control}
              render={(
                {
                  field     : {
                    ref,
                    value,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                  }
                }
              ) => {
                return (
                  <>
                    <Select
                      isClearable
                      value={value}
                      {...fieldRest}
                      menuPlacement='top'
                      isDisabled={disabled}
                      placeholder='Country*'
                      hasError={error?.message}
                      boxProps={{marginBottom: 0}}
                      options={countries.map((country: Record<string, any>) => ({
                        value: country.iso,
                        label: country.text,
                      }))}
                    />
                    <ErrorMessage
                      message={error?.message}
                      style={{marginTop: 4, lineHeight: '19.92px'}}
                    />
                  </>
                )
              }}
            />
            <Controller
              name='line1'
              rules={{
                validate: (value) => {
                  if (disabled) {
                    return true
                  }
                  if (!value?.trim()) {
                    return 'Address field is required';
                  }
                  return true;
                }
              }}
              control={control}
              render={(
                {
                  field     : {
                    ref,
                    value,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                  }
                }
              ) => {
                return (
                  <TextField
                    required
                    fullWidth
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    autoComplete='off'
                    value={value || ''}
                    disabled={disabled}
                    inputProps={inputProps}
                    error={!!error?.message}
                    helperText={error?.message}
                    placeholder='Street address*'
                    classes={{root: classes.root}}
                    {...fieldRest}
                  />
                )
              }}
            />
            <Controller
              name='line2'
              control={control}
              render={(
                {
                  field     : {
                    ref,
                    value,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                  }
                }
              ) => {
                return (
                  <TextField
                    required
                    fullWidth
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    autoComplete='off'
                    value={value || ''}
                    disabled={disabled}
                    inputProps={inputProps}
                    error={!!error?.message}
                    helperText={error?.message}
                    classes={{root: classes.root}}
                    placeholder='Apt, Unit, Suite, etc. (optional)'
                    {...fieldRest}
                  />
                )
              }}
            />
            <Box
              width='100%'
              display='flex'
              justifyContent='space-between'
              flexDirection={smDown ? 'column' : 'row'}
            >
              <Box
                width={smDown ? '100%' : '33.33333%'}
              >
                <Controller
                  name='city'
                  rules={{
                    validate: (value) => {
                      if (disabled) {
                        return true
                      }
                      if (!value?.trim()) {
                        return 'City code field is required';
                      }
                      return true;
                    }
                  }}
                  control={control}
                  render={(
                    {
                      field     : {
                        ref,
                        value,
                        ...fieldRest
                      },
                      fieldState: {
                        error,
                      }
                    }
                  ) => {
                    return (
                      <TextField
                        required
                        fullWidth
                        size='small'
                        inputRef={ref}
                        autoComplete='off'
                        variant='outlined'
                        placeholder='City*'
                        disabled={disabled}
                        value={value || ''}
                        inputProps={inputProps}
                        error={!!error?.message}
                        helperText={error?.message}
                        classes={{root: classes.root}}
                        {...fieldRest}
                      />
                    )
                  }}
                />
              </Box>
              <Box
                marginLeft={smDown ? 0 : 2}
                marginRight={smDown ? 0 : 2}
                width={smDown ? '100%' : '33.33333%'}
              >
                <StateRegion
                  control={control}
                  setValue={setValue}
                  disabled={disabled}
                />
              </Box>
              <Box
                width={smDown ? '100%' : '33.33333%'}
              >
                <Controller
                  rules={{
                    validate: (value) => {
                      if (disabled) {
                        return true
                      }
                      if (!value?.trim()) {
                        return 'Postal code field is required';
                      }
                      return true;
                    }
                  }}
                  name='postal_code'
                  control={control}
                  render={(
                    {
                      field     : {
                        ref,
                        value,
                        ...fieldRest
                      },
                      fieldState: {
                        error,
                      }
                    }
                  ) => {
                    return (
                      <TextField
                        required
                        fullWidth
                        size='small'
                        inputRef={ref}
                        variant='outlined'
                        autoComplete='off'
                        value={value || ''}
                        disabled={disabled}
                        placeholder='ZIP Code*'
                        inputProps={inputProps}
                        error={!!error?.message}
                        helperText={error?.message}
                        classes={{root: classes.root}}
                        {...fieldRest}
                      />
                    )
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

function StateRegion(
  {
    control,
    disabled,
    setValue,
  }: {
    disabled?: boolean;
    setValue?: UseFormSetValue<any>;
    control: Control<Record<string, any>>;
  }
) {
  const classes = useStyles();
  const country = useWatch({
    control,
    name: 'country',
  });

  React.useEffect(() => {
    setValue('state', '')
  }, [
    country,
    setValue,
  ])

  return (
    <Controller
      name='state'
      rules={{
        validate: (value) => {
          if (disabled) {
            return true
          }
          if (country?.value !== 'us') {
            if (!value?.trim()) {
              return 'State field is required';
            }
          } else {
            if (!value) {
              return 'State field is required';
            }
          }
          return true;
        }
      }}
      control={control}
      render={(
        {
          field     : {
            ref,
            value,
            ...fieldRest
          },
          fieldState: {
            error,
          }
        }
      ) => {
        return (country?.value === 'us') ? (
          <>
            <Select
              isClearable
              value={value}
              {...fieldRest}
              options={states}
              menuPlacement='top'
              placeholder='State*'
              isDisabled={disabled}
              hasError={error?.message}
              secondaryBoxProps={{marginTop: 0}}
              boxProps={{marginBottom: 0, marginTop: 0}}
            />
            <ErrorMessage
              message={error?.message}
              style={{marginTop: 4, lineHeight: '19.92px'}}
            />
          </>
        ) : (
          <TextField
            required
            fullWidth
            size='small'
            inputRef={ref}
            autoComplete='off'
            variant='outlined'
            disabled={disabled}
            value={value || ''}
            inputProps={inputProps}
            error={!!error?.message}
            placeholder='State/Region*'
            helperText={error?.message}
            classes={{root: classes.root}}
            {...fieldRest}
          />
        )
      }}
    />
  )
}
