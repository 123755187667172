import {_setData} from './index';
import {_dataOptions} from './index';
import {stringify} from 'querystring';
import {momentTZ} from '../../../utils';
import {filterObject} from '../../../utils';
import {Globals} from '../../../types/globals';
import {_pageSize} from '../../selectors/apiKeys';
import {_searchText} from '../../selectors/apiKeys';
import {_pageNumber} from '../../selectors/apiKeys';
import {ApiRoutes} from '../../../constants/apiRoutes';
import DATE_COLUMNS from '../../../constants/dateColumns';
import {FiniteStates} from '../../../constants/finiteStates';

export const _fetch = (): Globals.ThunkAction => async (dispatch, getState, {
  api
}) => {
  dispatch(_dataOptions({
    fetchingApiKeysState: FiniteStates.LOADING,
  }));
  const pageSize = _pageSize(getState());
  const searchText = _searchText(getState());
  const pageNumber = _pageNumber(getState());

  const params = stringify(filterObject({
    pageSize,
    pageNumber,
    searchText,
    sortOrder: 'desc',
    sortName : 'created_at',
  }));

  const {
    hasError,
    body: {
      data,
    },
  } = await api.get(`${ApiRoutes.LIST_API_TOKENS}?${params}`, {
    skipDelay: true,
  });

  if (!hasError) {
    const {
      rows,
      ...rest
    } = data;

    const createdAtFormat = (DATE_COLUMNS.find(dC => dC.column === 'created_at')).format;
    const expiresAtFormat = (DATE_COLUMNS.find(dC => dC.column === 'expires_at')).format;

    dispatch(_setData(rows.map((
      {
        key,
        expires_at,
        created_at,
        ...rest
      }: {
        id: number;
        key: string;
        name: string;
        enabled: number;
        created_at: string;
        expires_at: string;
      }) => {

      return {
        ...rest,
        api_key   : key,
        created_at: momentTZ(created_at).format(createdAtFormat),
        expires_at: momentTZ(expires_at).format(expiresAtFormat),
      }
    })));

    dispatch(_dataOptions({
      ...rest,
    }));
  }

  dispatch(_dataOptions({
    fetchingApiKeysState: FiniteStates.SUCCESS,
  }));
};
