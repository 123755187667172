import clsx from 'clsx';
import React from 'react';
import Skeleton from '../Skeleton';
import red from '@material-ui/core/colors/red';
import {Theme} from '@material-ui/core/styles';
import GraphemeSplitter from 'grapheme-splitter';
import Typography from '@material-ui/core/Typography';
import MuiTextField from '@material-ui/core/TextField';
import {TextFieldProps} from '@material-ui/core/TextField';
import {TypographyProps} from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const splitter = new GraphemeSplitter();

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth      : {
    width: '100%',
  },
  input          : {},
  formControl    : {
    flexDirection: 'column',
    display      : 'inline-flex',
  },
  mb             : {
    marginBottom: theme.spacing(3),
  },
  label          : {
    lineHeight: 1,
    margin    : '0px 8px 6px 0px',
  },
  helperTextRoot : {
    margin: theme.spacing(1, 1, 0),
  },
  error          : {
    color: red[500],
  },
  disabled       : {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  characterLimit : {
    flex     : 1,
    textAlign: 'right',
    margin   : '5px 15px 0 0'
  },
  skeleton       : {
    borderRadius: 0,
    margin      : 0,
    height      : 35,
    width       : '100%',
    transform   : 'unset',
  },
  helperContainer: {
    display: 'flex',
  }
}));

function counter(str: string) {
  str = str ? str.toString() : '';
  return splitter.countGraphemes(str);
}

export const TextField = React.memo((
  {
    label,
    value,
    color,
    error,
    style,
    variant,
    loading,
    onChange,
    disabled,
    fullWidth,
    className,
    LabelProps,
    InputProps,
    helperText,
    disableMargin,
    characterLimit,
    ...rest
  }: TextFieldProps & {
    loading?: boolean;
    disableMargin?: boolean;
    characterLimit?: number;
    LabelProps?: TypographyProps;
    color?: 'primary' | 'default' | 'success' | 'error';
  }
) => {
  const classes: any = useStyles();

  const handleOnChange = React.useCallback((e) => {
    if (typeof onChange === 'function') {
      onChange(e);
    }
  }, [
    onChange,
  ]);

  return (
    <Typography
      style={style}
      component='div'
      className={clsx(
        classes.formControl,
        !disableMargin && classes.mb,
        fullWidth && classes.fullWidth,
        className
      )}
    >
      {label && (
        <Typography
          component='span'
          className={clsx(classes.label, error && classes.error, disabled && classes.disabled)}
          {...LabelProps}
        >{label}
        </Typography>
      )}
      {loading ? (
        <Skeleton
          className={classes.skeleton}
        />
      ) : (
        <MuiTextField
          size='small'
          value={value}
          error={error}
          classes={{
            root: clsx(classes.root, color && classes[`${color}Class`])
          }}
          variant='outlined'
          InputProps={{
            classes: {input: classes.input},
            ...InputProps
          }}
          disabled={disabled}
          fullWidth={fullWidth}
          onChange={handleOnChange}
          FormHelperTextProps={{
            classes: {root: classes.helperTextRoot}
          }}
          helperText={(helperText || characterLimit) && (
            <Helper
              value={value}
              classes={classes}
              counter={counter}
              helperText={helperText}
              characterLimit={characterLimit}
            />
          )}
          {...rest}
        />
      )}
    </Typography>
  )
});

function Helper(
  {
    value,
    classes,
    counter,
    helperText,
    characterLimit,
  }: any
) {
  return React.useMemo(() => {
    return (
      <Typography
        color='inherit'
        component='span'
        variant='inherit'
        className={classes.helperContainer}
      >
        {helperText && (
          <Typography
            color='inherit'
            component='span'
            variant='inherit'
          >{helperText}
          </Typography>
        )}
        {characterLimit && (
          <Typography
            color='inherit'
            component='span'
            variant='inherit'
            className={classes.characterLimit}
          >
            <span
              style={{
                fontWeight: counter(value) > characterLimit ? 500 : 'inherit',
                color     : counter(value) > characterLimit ? 'red' : 'inherit',
              }}
            >{counter(value)}</span>/{characterLimit}
          </Typography>
        )}
      </Typography>
    )
  }, [
    value,
    classes,
    counter,
    helperText,
    characterLimit,
  ])
}
