import clsx from 'clsx';
import React from 'react';
import MuiIconButton from '@material-ui/core/IconButton';
import {IconButtonProps} from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  noPadding     : {
    padding   : 0,
    marginLeft: 10,
  },
  btnTransparent: {
    backgroundColor: 'transparent',
    '&:hover'      : {
      backgroundColor: 'transparent',
      borderColor    : 'transparent',
    },
    '&:active'     : {
      backgroundColor: 'transparent',
      borderColor    : 'transparent',
    },
    '&:focus'      : {
      backgroundColor: 'transparent',
      borderColor    : 'transparent',
    },
  },
}));

export const IconButton: React.FC<IProps & { component?: any }> = (
  {
    color,
    className,
    noPadding,
    ...rest
  }
) => {
  const classes = useStyles();
  switch (color) {
    case 'transparent': {
      return (
        <MuiIconButton
          {...rest}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          className={clsx(classes.btnTransparent, className, noPadding && classes.noPadding)}
        />
      );
    }
    default: {
      return (
        <MuiIconButton
          {...rest}
          color={color}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          className={clsx(className, noPadding && classes.noPadding)}
        />
      );
    }
  }
};
type ButtonColor = 'transparent' | 'inherit' | 'primary' | 'secondary' | 'default';

interface IProps extends Omit<IconButtonProps, 'color'> {
  color?: ButtonColor;
  noPadding?: boolean,
}
