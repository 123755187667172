import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {TableSelection} from '@devexpress/dx-react-grid-material-ui';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft         : '0 !important',
    paddingRight        : '0 !important',
    textAlign           : 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem !important',
    }
  },
}));

export default function TableSelectionHeaderCellComponent(props: TableSelection.HeaderCellProps) {
  const classes = useStyles();
  return (
    <TableSelection.HeaderCell
      {...props}
      className={classes.root}
    />
  )
}