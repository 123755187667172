import {Button} from 'antd';
import * as React from 'react';
import {Surveys} from '../../../../../../types/surveys';
import {tryingWebPush} from '../../../../../../constants/survey';
import {getPrefixCls} from '../../../../../../utils/getPrefixCls';
import {amNotUsingAnyService} from '../../../../../../constants/survey';

export function LetsGo(
  {
    data,
    letsGo,
  }: {
    letsGo: () => void;
    data: Surveys.Data;
  }
) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const trying = tryingWebPush === data.reason?.toLowerCase()?.trim();
  const notUsing = amNotUsingAnyService === data.competitor?.toLowerCase()?.trim();

  return (trying || notUsing || data.competitorSelected) && data.howDidYouFindUsSelected ? (
    <div
      className='m-t-5'
    >
      <Button
        loading={loading}
        onClick={async function () {
          setLoading(true);
          await letsGo();
          setLoading(false);
        }}
        className={`${getPrefixCls('survey-startButton')} ${getPrefixCls('survey-startButtonAnimation2')}`}
      >Let’s go!
      </Button>
    </div>
  ) : null;
}
