import React from 'react';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {aclKeys} from '../../../constants/aclKeys';
import {urlHelper} from '../../../utils/urlHelper';
import {AppRoutes} from '../../../constants/appRoutes';
import {useSmartPush} from '../../../hooks/useSmartPush';
import {_suspended} from '../../../store/selectors/application';
import {_showPlanChange} from '../../../store/selectors/application/acl';
import {_showPassiveGracePeriodWarning} from '../../../store/selectors/application/acl';

export function useManageAccount() {
  const {
    user: {
      canRead,
      hasVisibility,
    }
  } = useSmartPush();

  const {push} = useHistory();
  const {pathname} = useLocation();
  const suspended = useSelector(_suspended);
  const {appUuid} = useParams<{ appUuid?: string }>();
  const showPlanChange = useSelector(_showPlanChange);
  const showPGPeriodWarning = useSelector(_showPassiveGracePeriodWarning);
  const tabsOnChange = React.useCallback((activeKey: string) => {
    push(activeKey);
  }, [
    push,
  ]);

  let hasApiKeysAccess = (canRead(aclKeys.API_KEYS) && hasVisibility(aclKeys.API_KEYS));
  if (!!suspended || showPGPeriodWarning) {
    hasApiKeysAccess = false;
  }

  const apiKeysPath = appUuid ? urlHelper(AppRoutes.API_KEYS_APP, {appUuid}) : AppRoutes.API_KEYS;
  const securityPath = appUuid ? urlHelper(AppRoutes.SECURITY_APP, {appUuid}) : AppRoutes.SECURITY;
  const manageAccountPath = appUuid ? urlHelper(AppRoutes.MANAGE_ACCOUNT_APP, {appUuid}) : AppRoutes.MANAGE_ACCOUNT;
  const paymentsAndSubscriptionsPath = appUuid ? urlHelper(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP, {appUuid}) : AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS;

  const hideTabs = pathname.startsWith(AppRoutes.CHANGE_PLAN) ||
    pathname.startsWith(urlHelper(AppRoutes.CHANGE_PLAN_APP, {appUuid}));

  const currentStep = [
    AppRoutes.CHANGE_PLAN_APP,
    urlHelper(AppRoutes.CHANGE_PLAN_APP, {appUuid}),
  ].includes(pathname) ? 0 : 1;

  return {
    canRead,
    pathname,
    hideTabs,
    currentStep,
    apiKeysPath,
    securityPath,
    tabsOnChange,
    hasVisibility,
    showPlanChange,
    hasApiKeysAccess,
    manageAccountPath,
    paymentsAndSubscriptionsPath,
  }
}
