import React from 'react';
import {Button} from 'antd';
import {useWatch} from 'react-hook-form';
import stripeJs from '@stripe/stripe-js';
import {CheckOutlined} from '@ant-design/icons';
import {Control} from 'react-hook-form/dist/types/form';
import {IPayAndLaunchForm} from '../../usePayAndLaunch';

export function SubmitButton(
  {
    control,
    isSubmitting,
    skippingCheckout,
  }: {
    isSubmitting?: boolean;
    skippingCheckout?: boolean;
    control: Control<IPayAndLaunchForm>;
  }
) {
  const [
    city,
    name,
    state,
    line1,
    cardField,
    postal_code,
  ] = useWatch({
    control,
    name: [
      'city',
      'name',
      'state',
      'line1',
      'cardField',
      'postal_code',
    ]
  });

  const disabled = !(
    (name as string)?.trim() &&
    (city as string)?.trim() &&
    (state as string)?.trim() &&
    (line1 as string)?.trim() &&
    (postal_code as string)?.trim() &&
    (cardField as stripeJs.StripeCardElementChangeEvent)?.complete
  );

  return (
    <Button
      type='primary'
      htmlType='submit'
      loading={isSubmitting}
      disabled={disabled || skippingCheckout}
      icon={<CheckOutlined/>}
    >Finish
    </Button>
  )
}
