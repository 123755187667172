import {IObject} from '../../../../types';
import {ActionTypes} from '../../../constants/gallery';

const InitialState: IObject = {
  pageNumber: 1,
};

export default (state: any = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_GALLERY_TABLE_OPTIONS: {
      if (payload.pageNumber) {
        return {
          ...state,
          pageNumber: payload.pageNumber,
        }
      }
      return state;
    }
    default:
      return state;
  }
};
