import {darken} from '@material-ui/core/styles/colorManipulator';
import {lighten} from '@material-ui/core/styles/colorManipulator';
import {ThemeOptions} from '@material-ui/core/styles/createMuiTheme';

export const themeOptions: ThemeOptions = {
  palette  : {
    type   : 'light',
    primary: {
      main : '#096dd9',
      dark : darken('#096dd9', .2),
      light: lighten('#096dd9', .2),
    },
    text   : {
      primary: 'rgba(0,0,0,.85)'
    }
  },
  shape    : {
    borderRadius: 2,
  },
  overrides: {
    MuiButton         : {
      sizeSmall: {
        fontSize: 14,
      },
      contained: {
        borderRadius: 0,
        boxShadow   : 'none',
        '&:hover'   : {
          boxShadow: 'none',
        },
      },
      outlined : {
        borderRadius: 0,
        boxShadow   : 'none',
        '&:hover'   : {
          boxShadow: 'none',
        },
      },
      root     : {
        borderRadius : 0,
        fontSize     : 14,
        fontWeight   : 'bold',
        boxShadow    : 'none',
        textTransform: 'initial',
        '&:hover'    : {
          boxShadow: 'none',
        },
      },
    },
    MuiDialog         : {
      paper: {
        borderRadius: 0,
        boxShadow   : 'none',
      },
    },
    MuiTableHead      : {
      root: {
        '& tr:first-child th'                                 : {
          fontWeight     : 500,
          textAlign      : 'left',
          backgroundColor: '#fafafa',
          color          : 'rgba(0,0,0,.85)',
          borderLeft     : '1px solid #f0f0f0',
          borderRight    : '1px solid #f0f0f0',
        },
        '& tr:first-child th:first-child'                     : {
          borderLeft : 'none',
          borderRight: '1px solid #f0f0f0',
        },
        '& tr:first-child th:last-child'                      : {
          borderRight: 'none',
          borderLeft : '1px solid #f0f0f0',
        },
        '& tr:first-child th span'                            : {
          fontSize  : 14,
          fontWeight: 500,
          color     : 'rgba(0,0,0,.85)',
        },
        '& tr:first-child th svg:not(.tmp)'                   : {
          fontSize  : 14,
          fontWeight: 500,
          color     : 'rgba(0,0,0,.85) !important',
        },
        '& tr:first-child th svg:not(.tmp):hover'             : {
          color: 'rgba(0,0,0,.85) !important',
        },
        '& .Mui-disabled svg.MuiTableSortLabel-icon:not(.tmp)': {
          display: 'none',
        },
      },
    },
    MuiTableBody      : {
      root: {
        backgroundColor      : '#ffffff',
        '& tr td'            : {
          fontSize: 12,
        },
        '& tr td:first-child': {
          borderLeft: 'none',
        },
        '& tr td:last-child' : {
          borderRight: 'none',
        },
      },
    },
    MuiTableCell      : {
      footer: {
        border: 'none',
      },
      body  : {
        borderColor : '#f0f0f0f0',
        color       : 'rgba(0,0,0,.85)',
        borderBottom: '1px solid #f0f0f0f0',
      },
      root  : {
        fontSize: 14
      }
    },
    MuiPaper          : {
      elevation2: {
        boxShadow: 'none',
      }
    },
    MuiPopover        : {
      paper: {
        maxHeight: '50%',
      }
    },
    MuiTooltip        : {
      tooltip: {
        fontSize       : 14,
        backgroundColor: 'rgba(97, 97, 97, 1)',
      },
    },
    MuiTypography     : {
      colorPrimary: {
        color: 'rgba(0,0,0,.85)',
      },
      body1       : {
        fontSize  : '16px',
        lineHeight: '24px',
      },
      body2       : {
        fontSize  : '14px',
        lineHeight: '20.02px',
      },
      caption     : {
        fontSize: '12px',
      },
    },
    MuiIcon           : {
      colorPrimary: {
        color: 'rgba(0,0,0,.85)',
      },
    },
    MuiSvgIcon        : {
      colorPrimary : {
        color: 'rgba(0,0,0,.85)',
      },
      root         : {
        fontSize: 24
      },
      fontSizeSmall: {
        fontSize: 20
      }
    },
    MuiTab            : {
      root: {
        fontSize: 14,
      }
    },
    MuiOutlinedInput  : {
      root            : {
        fontSize                   : 14,
        height                     : 'unset',
        '&:hover $notchedOutline'  : {
          borderColor: '#096dd9',
        },
        '@media (hover: none)'     : {
          '&:hover $notchedOutline': {
            borderColor: 'rgba(0,0,0,.85)'
          }
        },
        '&$focused $notchedOutline': {
          borderWidth: 1,
          boxShadow  : 'rgba(24, 144, 255, 0.2) 0px 0px 0px 2px',
        },
      },
      inputMarginDense: {
        paddingTop   : 7.7,
        paddingBottom: 7.7,
      },
      notchedOutline  : {
        borderColor: '#d9d9d9',
        transition : 'all 1s cubic-bezier(.075,.82,.165,1)',
      },
    },
    MuiMenu           : {
      list : {
        paddingTop   : 0,
        paddingBottom: 0,
      },
      paper: {
        boxShadow: 'none',
        border   : '1px solid rgba(0, 0, 0, 0.12)',
      }
    },
    MuiMenuItem       : {
      root: {
        fontSize          : 14,
        '&$selected'      : {
          color          : '#096dd9',
          backgroundColor: '#E6F7FF',
        },
        '&$selected:hover': {
          color          : '#096dd9',
          backgroundColor: '#E6F7FF',
        },
        '&:hover'         : {
          color          : '#096dd9',
          backgroundColor: 'transparent',
        },
      }
    },
    MuiSelect         : {
      select: {
        fontSize : 14,
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiFormHelperText : {
      root: {
        fontSize  : 12,
        lineHeight: '19.92px',
      }
    },
    MuiIconButton     : {
      sizeSmall: {
        fontSize: 18
      }
    },
    MuiSnackbarContent: {
      root: {
        fontSize: 14,
      }
    },
  },
};
