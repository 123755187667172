import React from 'react';
import {Card} from 'antd';
import {Modal} from 'antd';
import {Button} from 'antd';
import {Tooltip} from 'antd';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import * as cp from './components/Codespinnets';
import {Pre} from '../../../../components/antd/Pre';
import {urlHelper} from '../../../../utils/urlHelper';
import {Link} from '../../../../components/antd/Typography';
import {Text} from '../../../../components/antd/Typography';
import {Title} from '../../../../components/antd/Typography';
import {_currentApp} from '../../../../store/selectors/apps';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import {_cdnUrl} from '../../../../store/selectors/application/acl';

export function SimpleCode(
  {
    onCopy,
    downloading,
    downloadWorkerJS,
  }: {
    onCopy?: () => void;
    downloading?: boolean;
    downloadWorkerJS: () => void;
  }
) {
  const cdnUrl = useSelector(_cdnUrl);
  const {appUuid} = useParams<{ appUuid: string }>();

  return (
    <>
      <Card
        bordered={false}
        title='2. Add Code'
        extra={<ExtraActions/>}
        className='create-app-card m-b-2'
      >
        <Text
          className='m-b-2 d-block'
        >Add this code to the <Hed/> section on all pages of your site that users can subscribe to
        </Text>
        <Text
          code
          className='d-flex align-items-flex-start custom-copyable'
          copyable={{
            onCopy,
            text: urlHelper(cp.codespinnet, {CDN_URL: cdnUrl, APP_UUID: appUuid}),
          }}
        >{urlHelper(cp.codespinnet, {CDN_URL: cdnUrl, APP_UUID: appUuid})}
        </Text>
      </Card>
      <Card
        bordered={false}
        title='3. Upload File'
        className='create-app-card m-b-2'
      >
        <Tooltip
          placement='right'
          visible={(downloading === false) ? true : void 0}
          title={downloading ? 'Downloading...' : (downloading === undefined) ? 'Download' : 'Downloaded'}
        >
          <Button
            className='m-b-2'
            loading={downloading}
            onClick={downloadWorkerJS}
            icon={<DownloadOutlined/>}
          >smart-worker.js
          </Button>
        </Tooltip>
        <div>
          <div>
            <Text
            >Download the <Sw/> file and <Up/> it to the top-level root of your site directory.
            </Text>
          </div>
          <div>
            <Text
            >It should be publicly available by this URL: <MySite/>
            </Text>
          </div>
        </div>
      </Card>
    </>
  )
}

function Sw() {
  return (
    <Text
      fontWeight='bold'
    >smart-worker
    </Text>
  )
}

function Up() {
  return (
    <Text
      fontWeight='bold'
    >upload
    </Text>
  )
}

function Hed() {
  return (
    <Link
      fontWeight='bold'
      className='cursor-default'
    >{`<`}head{`>`}
    </Link>
  )
}

function MySite() {
  const app = useSelector(_currentApp);
  let domain = 'https://mysite.com';
  try {
    domain = (new URL(app.domain)).origin
  } catch (e) {
  }

  return (
    <Link
      target='_blank'
      href={`${domain}/smart-worker.js`}
    >{domain}/smart-worker.js
    </Link>
  )
}

function ExtraActions() {
  const {appUuid} = useParams<{ appUuid: string }>();
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip
        title='Advanced Configs'
      >
        <SettingOutlined
          onClick={showModal}
          className='pointer advanced-configs-icon'
        />
      </Tooltip>
      <Modal
        centered
        width={550}
        okText='Done'
        destroyOnClose
        onOk={handleOk}
        onCancel={handleCancel}
        title='Advanced Configs'
        visible={isModalVisible}
        cancelButtonProps={{style: {display: 'none'}}}
      >
        <Title
          level={5}
          fontWeight='bold'
          className='m-b-2'
        >Setting Options
        </Title>
        <div
          className='m-b-2'
        >
          <Text
          >You can set some of the push notification options directly in your code with the <Text
            code>w_push_options</Text> global variable.
          </Text>
        </div>
        <Text
          code
          className='d-flex align-items-flex-start custom-copyable m-b-2'
          copyable={{text: urlHelper(cp.codespinnet_2, {APP_UUID: appUuid})}}
        >
          <Pre>{cp.codespinnet_2_1}</Pre>
          <Pre>{cp.codespinnet_2_2}</Pre>
          <Pre>{urlHelper(cp.codespinnet_2_3, {APP_UUID: appUuid})}</Pre>
          <Pre>{cp.codespinnet_2_4}</Pre>
          <Pre>{cp.codespinnet_2_5}</Pre>
          <Pre>{cp.codespinnet_2_6}</Pre>
          <Pre>{cp.codespinnet_2_7}</Pre>
          <Pre>{cp.codespinnet_2_8}</Pre>
          <Pre>{cp.codespinnet_2_9}</Pre>
          <Pre>{cp.codespinnet_2_10}</Pre>
          <Pre>{cp.codespinnet_2_11}</Pre>
          <Pre>{cp.codespinnet_2_12}</Pre>
        </Text>
        <Title
          level={5}
          fontWeight='bold'
          className='m-b-2'
        >Function parameters
        </Title>
        <ul
          className='document-list'
        >
          <li>
            <Text
            ><Text fontWeight='bold'>appId</Text> <Text italic>(Optional)</Text>Your App UUID. If present, the service
              will init automatically.
            </Text>
          </li>
          <li>
            <Text
            ><Text fontWeight='bold'>autoShow</Text> <Text italic>(Optional)</Text>Set true for prompts to be triggered
              automatically upon loading on your site or programmatically at a time of your choosing with the <Text
                code>WPush.showDialog()</Text> command.
            </Text>
          </li>
          <li>
            <Text
            ><Text fontWeight='bold'>disableDialog</Text> <Text italic>(Optional)</Text>Set true to hide the custom
              prompt dialog.
            </Text>
          </li>
          <li>
            <Text
            ><Text fontWeight='bold'>ttl</Text> <Text italic>(Optional)</Text>Minutes to wait until showing prompt
              dialog after rejection.
            </Text>
          </li>
          <li>
            <Text
            ><Text fontWeight='bold'>delay</Text> <Text italic><Text italic>(Optional)</Text></Text>Milliseconds to wait
              until showing prompt dialog if autoShow is enabled.
            </Text>
          </li>
          <li>
            <Text
            ><Text fontWeight='bold'>tags</Text> <Text italic><Text italic>(Optional)</Text></Text>Simple key values to
              associate with user.
            </Text>
          </li>
        </ul>
      </Modal>
    </>
  )
}
