import {History} from 'history';
import {notification} from 'antd';
import {IObject} from '../../../types';
import {filterObject} from '../../../utils';
import {Globals} from '../../../types/globals';
import {FEATURE} from '../../../constants/users';
import {ActionTypes} from '../../constants/users';
import {urlHelper} from '../../../utils/urlHelper';
import {getPermissions} from '../../selectors/users';
import {ACCESS_TYPES} from '../../../constants/users';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {AppRoutes} from '../../../constants/appRoutes';
import {FEATURE_GROUP} from '../../../constants/users';
import {_isAdmin} from '../../selectors/application/acl';
import {_apiBaseUrl} from '../../selectors/application';
import {orderedAccessTypes} from '../../../constants/users';
import {PaymentCycleOptions} from '../../../constants/paymentCycle';
import {NumberOfPeriodsOptions} from '../../../constants/numberOfPeriods';

import {getUsersData} from './services';

export const dataOptionsAction = (options: any) => ({
  payload: options,
  type   : ActionTypes.WEB_NOTIFY_USERS_DATA_OPTIONS,
});

export const loadingAction = (loading: boolean) => ({
  payload: loading,
  type   : ActionTypes.WEB_NOTIFY_USERS_TOGGLE_LOADING,
});

export const toggleErrorsAction = (errors?: any) => ({
  payload: errors,
  type   : ActionTypes.WEB_NOTIFY_USERS_TOGGLE_ERRORS,
});

export const updateErrorsAction = (errors?: any) => ({
  payload: errors,
  type   : ActionTypes.WEB_NOTIFY_USERS_UPDATE_ERRORS,
});

export const toggleEntityAction = (payload?: any) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_USERS_TOGGLE_ENTITY,
});

export const updateEntityAction = (payload?: any) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_USERS_UPDATE_ENTITY,
});

export const toggleDialogAction = (payload?: {
  data?: any;
  dialog: string;
}) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_USERS_TOGGLE_DIALOG,
});

export const togglePermissionsAction = (permissions: any[]) => ({
  payload: permissions,
  type   : ActionTypes.WEB_NOTIFY_USERS_TOGGLE_PERMISSIONS,
});


function filterAccesses(accesses: any) {
  return filterObject({
    [ACCESS_TYPES.VISIBLE]        : accesses?.[ACCESS_TYPES.VISIBLE],
    [ACCESS_TYPES.DISPLAY_UPGRADE]: accesses?.[ACCESS_TYPES.DISPLAY_UPGRADE],
    [ACCESS_TYPES.READ]           : accesses?.[ACCESS_TYPES.READ],
    [ACCESS_TYPES.VISIBLE_DATA]   : accesses?.[ACCESS_TYPES.VISIBLE_DATA],
    [ACCESS_TYPES.CREATE]         : accesses?.[ACCESS_TYPES.CREATE],
    [ACCESS_TYPES.UPDATE]         : accesses?.[ACCESS_TYPES.UPDATE],
    [ACCESS_TYPES.DELETE]         : accesses?.[ACCESS_TYPES.DELETE],
    [ACCESS_TYPES.SEND]           : accesses?.[ACCESS_TYPES.SEND],
    [ACCESS_TYPES.CANCEL]         : accesses?.[ACCESS_TYPES.CANCEL],
  })
}

function getDisabledAccess(accesses: any) {
  return orderedAccessTypes.reduce((a: any, c: string) => {
    if (accesses[`disabled_${c}`]) {
      a[c] = 0;
    }

    return a;
  }, {})
}

export const createOrUpdateAction = (
  {
    entity,
    history,
    appUuid,
  }: {
    entity?: any;
    appUuid: string;
    history: History;
  }
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isCreatingOrUpdating: true,
  }));
  dispatch(toggleErrorsAction(void 0));

  const apiBaseUrl = _apiBaseUrl(getState());

  let newUrl: string = `${apiBaseUrl}${ApiRoutes.CREATE_USER}`;
  if (entity?.uuid) {
    newUrl = urlHelper(`${apiBaseUrl}${ApiRoutes.UPDATE_USER}`, {
      uuid: entity.uuid
    });
  }

  const {
    role,
    price,
    groups,
    acl = {},
    cycle_count,
    pendingPlan,
    freezeLimits,
    selectedPlan,
    billing_cycle,
    subscriptionPlans,
    ...restEntity
  } = entity;

  const newEntity = {
    ...restEntity,
    services     : ['webnotify'],
    price        : Number(price ?? 0),
    cycle_count  : cycle_count?.value,
    billing_cycle: billing_cycle?.value,
    enabled      : Number(entity?.enabled ?? 0),
    permissions  : (entity?.permissions || []).map((item: any) => {
      return {
        type      : item.type,
        service   : 'webnotify',
        permission: item.value,
      }
    }),
    acl          : Object.keys(acl).reduce((acc: IObject, curr: string) => {
      if (acl?.[curr]?.[FEATURE]) {
        const f = acl?.[curr]?.[FEATURE];
        acc[curr] = {
          ...acc[curr],
          [FEATURE]: Object.keys(f).reduce((subAcc: any, subCurr: string) => {
            if (f[subCurr]) {
              subAcc[subCurr] = {
                ...filterAccesses(f[subCurr]),
                ...getDisabledAccess(f[subCurr])
              }
            }

            return subAcc;
          }, {})
        }
      }

      if (acl?.[curr]?.[FEATURE_GROUP]) {
        const fg = acl?.[curr]?.[FEATURE_GROUP];
        acc[curr] = {
          ...acc[curr],
          [FEATURE_GROUP]: {
            ...filterAccesses(fg),
            ...getDisabledAccess(fg)
          },
        }
      }

      return acc;
    }, {}),
  };

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.post(newUrl, newEntity);

  dispatch(dataOptionsAction({
    isCreatingOrUpdating: void 0,
  }));

  if (hasError) {
    dispatch(toggleErrorsAction(data.errors));

    notification.error({message});
  } else {
    notification.success({message});

    dispatch(toggleErrorsAction(void 0));
    dispatch(toggleEntityAction(void 0));

    history.push(urlHelper(AppRoutes.USERS, {appUuid}))

    dispatch(getUsersData());
  }
};

export const getPermissionsAction = (
  {
    row,
    signal,
  }: {
    row?: IObject;
    signal?: AbortSignal;
  } = {}
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isGettingPermissions: true,
  }));

  const isAdmin = _isAdmin(getState());
  const apiBaseUrl = _apiBaseUrl(getState());

  const {
    hasError,
    body: {
      data,
      name,
      message,
    },
  } = await api.get(`${apiBaseUrl}${ApiRoutes.GET_PERMISSIONS}`, {signal});

  if (isAdmin) {
    const sPlans = await api.get(`${apiBaseUrl}${ApiRoutes.SUBSCRIPTION_PLANS}`, {signal});

    if (!sPlans.hasError) {
      const newSPlans: IObject[] = (sPlans.body.data || []).map((sPlan: IObject) => {
        const paymentOption = PaymentCycleOptions.find((option) => option.value === sPlan.free_billing_cycle);
        const periodOption = NumberOfPeriodsOptions.find((option) => option.value === sPlan.free_cycle_count);

        return {
          ...sPlan,
          free_cycle_count  : periodOption,
          free_billing_cycle: paymentOption,
        }
      });

      dispatch({
        payload: newSPlans,
        type   : ActionTypes.WEB_NOTIFY_USERS_SUBSCRIPTION_PLANS,
      });

      if (!row?.uuid) {
        const initialPlan: number | undefined = newSPlans?.[0]?.id;

        if (initialPlan) {
          dispatch(updateEntityAction({
            pendingPlan : initialPlan,
            freezeLimits: newSPlans?.[0]?.limits,
          }))
        }
      }
    }

    const fGroups = await api.get(`${apiBaseUrl}${ApiRoutes.FEATURE_GROUPS}`, {signal});
    if (!fGroups.hasError) {
      dispatch({
        payload: fGroups.body.data,
        type   : ActionTypes.WEB_NOTIFY_USERS_FEATURE_GROUPS,
      })
    }
  }

  if (hasError) {
    if (name !== 'AbortError') {
      dispatch(toggleErrorsAction({permissions: message, message}));
    }
  } else {
    dispatch(togglePermissionsAction(data))
  }

  dispatch(dataOptionsAction({
    isGettingPermissions: void 0,
  }));
};

export const getAction = (
  uuid: string,
  {
    signal,
  }: {
    signal?: AbortSignal;
  } = {}
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isGetting: true,
  }));

  await dispatch(getPermissionsAction({signal}));
  let permissions = getPermissions(getState());

  try {
    permissions = permissions[0].inputs
  } catch (e) {
    permissions = [];
  }

  const endpoint = `${_apiBaseUrl(getState())}${urlHelper(ApiRoutes.GET_USER, {
    uuid,
  })}`;

  const {
    hasError,
    body: {
      name,
      data,
      message,
    },
  } = await api.get(endpoint, {signal});

  if (hasError) {
    dispatch(toggleDialogAction(void 0));

    dispatch(loadingAction(false));

    if (name !== 'AbortError') {
      notification.error({message})
    }
  } else {
    const acl = Object.keys(data.acl || {}).reduce((acc: any, curr: any) => {
      if (data.acl?.[curr]?.[FEATURE_GROUP]) {
        const fg = data.acl?.[curr]?.[FEATURE_GROUP];

        acc[curr] = {
          ...acc[curr],
          [FEATURE_GROUP]: {
            ...Object.keys(fg).reduce((a: any, c: any) => {
              a[c] = fg[c];
              // if (fg[c] === 0 && c !== ACCESS_TYPES.VISIBLE) {
              //   a[c] = 1;
              //   a[`disabled_${c}`] = true;
              // } else {
              //   a[c] = fg[c];
              // }
              return a;
            }, {})
          },
        }
      }

      if (data.acl?.[curr]?.[FEATURE]) {
        const f = data.acl?.[curr]?.[FEATURE];

        acc[curr] = {
          ...acc[curr],
          [FEATURE]: Object.keys(f).reduce((subAcc: any, subCurr: string) => {
            const subAccess = f[subCurr];

            if (f[subCurr]) {
              subAcc[subCurr] = {
                ...Object.keys(subAccess).reduce((a: any, c: any) => {
                  a[c] = subAccess[c];
                  // if (subAccess[c] === 0 && c !== ACCESS_TYPES.VISIBLE) {
                  //   a[c] = 1;
                  //
                  //   a[`disabled_${c}`] = true;
                  // } else {
                  //   a[c] = subAccess[c];
                  // }
                  return a;
                }, {})
              };
            }

            return subAcc;
          }, {})
        }
      }

      return acc;
    }, {});

    dispatch(toggleEntityAction({
      ...data,
      acl,
      freezeLimits : data.limits,
      services     : data.active_services,
      billing_cycle: PaymentCycleOptions.find((pCc) => pCc.value === data?.billing_cycle),
      cycle_count  : NumberOfPeriodsOptions.find((pCc) => pCc.value === data?.cycle_count),
      permissions  : (data.active_permissions || []).map(({permission, service_slug, ...rest}: IObject) => ({
        value  : permission,
        service: service_slug,
        label  : ((permissions || []).find((i: any) => i.value === permission) || {}).label,
        ...rest
      })),
    }));

    dispatch(loadingAction(false));
  }

  dispatch(dataOptionsAction({
    isGetting: void 0,
  }));
};
