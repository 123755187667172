import clsx from 'clsx';
import React from 'react';
import {useImmer} from 'use-immer';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {momentTZ} from '../../../../utils';
import {useTranslation} from 'react-i18next';
import InfoIcon from '@material-ui/icons/Info';
import {Theme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {Back} from '../../../../components/Back';
import {Card} from '../../../../components/Card';
import ErrorIcon from '@material-ui/icons/Error';
import {emptyArray} from '../../../../constants';
import SubscriptionPlan from './SubscriptionPlan';
import {emptyObject} from '../../../../constants';
import Checkbox from '@material-ui/core/Checkbox';
import {FEATURE} from '../../../../constants/users';
import ModulePermissions from './ModulePermissions';
import {Button} from '../../../../components/Button';
import {urlHelper} from '../../../../utils/urlHelper';
import Typography from '@material-ui/core/Typography';
import {TextField} from '../../../../components/Input';
import CardContent from '@material-ui/core/CardContent';
import {ACCESS_TYPES} from '../../../../constants/users';
import {AppRoutes} from '../../../../constants/appRoutes';
import {getAction} from '../../../../store/actions/users';
import {formatBytes} from '../../../../utils/formatBytes';
import {FEATURE_GROUP} from '../../../../constants/users';
import {IOSSwitch} from '../../../../components/IOSSwitch';
import {orderedAclKeys} from '../../../../constants/users';
import {getEntity} from '../../../../store/selectors/users';
import {getErrors} from '../../../../store/selectors/users';
import {getGroups} from '../../../../store/selectors/users';
import {Content} from '../../../../components/antd/Content';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {CardHeader} from '../../../../components/CardHeader';
import DATE_COLUMNS from '../../../../constants/dateColumns';
import {AutoComplete} from '../../../../components/AutoComplete';
import {orderedAccessTypes} from '../../../../constants/users';
import {getDataOptions} from '../../../../store/selectors/users';
import {formatBytesToObject} from '../../../../utils/formatBytes';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {toggleErrorsAction} from '../../../../store/actions/users';
import {_isAdmin} from '../../../../store/selectors/application/acl';
import {getPermissionsAction} from '../../../../store/actions/users';
import {createOrUpdateAction} from '../../../../store/actions/users';
import CircularProgress from '../../../../components/CircularProgress';
import {PaymentCycleOptions} from '../../../../constants/paymentCycle';
import {getSubscriptionPlans} from '../../../../store/selectors/users';
import {NumberOfPeriodsOptions} from '../../../../constants/numberOfPeriods';

const useStyles = makeStyles((theme: Theme) => ({
  title                          : {
    fontSize    : 24,
    marginBottom: theme.spacing(2),
  },
  textField                      : {
    width: 80,
  },
  helperText                     : {
    marginLeft: 5,
    fontSize  : 12,
  },
  checkboxLabel                  : {
    marginRight : 5,
    marginBottom: 0,
  },
  cardHeaderRoot                 : {
    padding                       : theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  cardContentRoot                : {
    padding                       : theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 1, 1),
    },
  },
  formControlLabel               : {
    margin               : 0,
    '& > .MuiSwitch-root': {
      marginLeft : 0,
      marginRight: theme.spacing(1),
    },
  },
  toggleUserWrapper              : {
    marginBottom: theme.spacing(4),
  },
  nestedCardContentRoot          : {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  toggleUserReadonlyAccessWrapper: {
    marginBottom: theme.spacing(4),
  },
  checkboxLabelRoot              : {
    display   : 'flex',
    alignItems: 'center',
  },
  buttonsContainer               : {
    display     : 'flex',
    alignItems  : 'center',
    marginTop   : theme.spacing(-3),
    '& > button': {
      width: 200,
    },
  },
  cardHeaderContent              : {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'space-between',
  },
  planPriceContainer             : {
    display     : 'flex',
    marginBottom: theme.spacing(3),
  },
  paymentOptionsRoot             : {
    marginTop    : 24,
    marginBottom : 24,
    display      : 'flex',
    flexDirection: 'column',
  },
  textFieldContainer             : {
    display   : 'flex',
    alignItems: 'center',
  },
  autoCompleteContainer          : {
    display       : 'flex',
    justifyContent: 'space-between',
  },
  updateSubscriptionContainer    : {
    display     : 'flex',
    alignItems  : 'center',
    marginBottom: theme.spacing(3),
  },
  nestedCard                     : {
    overflow    : 'unset',
    marginBottom: theme.spacing(4),
  },
  nestedTitleTypography          : {
    fontSize  : 16,
    fontWeight: 'bold',
    color     : '#ffffff',
  },
  titleTypography                : {
    fontSize  : 22,
    fontWeight: 'bold',
    color     : theme.palette.primary.main,
  },
  cardRoot                       : {
    borderRadius: 0,
    position    : 'relative',
    transition  : theme.transitions.create(['border-color'], {
      delay: theme.transitions.duration.leavingScreen
    }),
  },
  simpleTable                    : {
    borderCollapse: 'collapse',
    marginBottom  : theme.spacing(3),
    '& td'        : {
      padding: theme.spacing(2),
      border : '1px solid #ddd',
    },
  },
  nestedCardHeaderRoot           : {
    padding                       : theme.spacing(2, 3),
    backgroundColor               : theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

export default function CreateOrUpdate() {
  const classes = useStyles();
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const errors = useSelector(getErrors);
  const fGroups = useSelector(getGroups);
  const isAdmin = useSelector(_isAdmin);
  const reduxEntity = useSelector(getEntity);
  const [entity, updateEntity] = useImmer<any>({});
  const subscriptionPlans = useSelector(getSubscriptionPlans);
  const {
    isGettingPermissions,
    isCreatingOrUpdating,
  } = useSelector(getDataOptions);

  const {
    uuid,
    appUuid,
  } = useParams<{
    uuid?: string;
    appUuid: string;
  }>();

  React.useEffect(() => {
    const controller = new AbortController();
    if (uuid) {
      dispatch(getAction(uuid, {
        signal: controller.signal,
      }))
    } else {
      dispatch(getPermissionsAction({
        signal: controller.signal,
      }))
    }
  }, [
    uuid,
    dispatch,
  ]);

  React.useEffect(() => {
    updateEntity(() => {
      return {
        ...reduxEntity,
        subscriptionPlans,
        update_subscription: true,
        groups             : fGroups,
      };
    });
  }, [
    uuid,
    fGroups,
    reduxEntity,
    updateEntity,
    subscriptionPlans,
  ]);

  const {
    users,
    ...restModules
  } = entity?.limits || emptyObject;

  const createOrUpdate = React.useCallback(() => {
    dispatch(createOrUpdateAction({
      entity,
      history,
      appUuid,
    }))
  }, [
    entity,
    history,
    appUuid,
    dispatch,
  ]);
  const handleOnChange = React.useCallback((e: any) => {
    const value = Number(e.target.checked);

    const {
      aclKey,
      accessType,
      featureSlug
    } = e.currentTarget.dataset;

    updateEntity(draft => {
      if (featureSlug) {
        draft.acl[aclKey][FEATURE][featureSlug][accessType] = value;
      } else {
        draft.acl[aclKey][FEATURE_GROUP][accessType] = value;
      }
    })
  }, [
    updateEntity,
  ]);
  const handlePermissionsChange = React.useCallback((event, newValue) => {
    updateEntity(draft => {
      draft.permissions = newValue;
    })
  }, [
    updateEntity,
  ]);

  if (((!!isGettingPermissions) && isAdmin)) {
    return (
      <CircularProgress/>
    )
  }

  const expiresAtFormat = (DATE_COLUMNS.find(dC => dC.column === 'expires_at') || {format: 'YYYY-MM-DD hh:mm:ss A'}).format;
  const planStartedAtFormat = (DATE_COLUMNS.find(dC => dC.column === 'plan_started_at') || {format: 'YYYY-MM-DD hh:mm:ss A'}).format;

  return (
    <Content>
      <Typography
        component='div'
      >
        <Back
          text={t('users.back_to_users')}
          url={urlHelper(AppRoutes.USERS, {appUuid})}
        />
        <Card
          classes={{root: classes.cardRoot}}
        >
          <CardHeader
            color='primary'
            title={t('users.register_user')}
          />
          <CardContent
            classes={{
              root: classes.cardContentRoot,
            }}
          >
            <Typography
              component='div'
              style={{
                marginBottom: 32
              }}
            >
              <FormControlLabel
                labelPlacement='end'
                checked={!!entity.enabled}
                onChange={(event, checked) => {
                  updateEntity(draft => {
                    draft.enabled = checked;
                  })
                }}
                classes={{
                  root: classes.formControlLabel,
                }}
                label={
                  <Typography
                    color='primary'
                    variant='body1'
                    component='span'
                  >{t('users.user_is_enabled')}
                  </Typography>
                }
                control={
                  <IOSSwitch
                    size='small'
                    name='enabled'
                  />
                }
              />
            </Typography>
            <Card
              elevation={3}
              className={classes.nestedCard}
            >
              <CardHeader
                color='primary'
                title={t('users.general_information')}
              />
              <CardContent
                classes={{root: classes.nestedCardContentRoot}}
              >
                <div>
                  <TextField
                    fullWidth
                    type='text'
                    name='first_name'
                    onChange={(e) => {
                      const name = e.target.name;
                      const value = e.target.value;

                      updateEntity(draft => {
                        draft[name] = value
                      })
                    }}
                    error={!!errors?.first_name}
                    label={t('users.first_name')}
                    helperText={errors?.first_name}
                    value={entity?.first_name || ''}
                  />
                  <TextField
                    fullWidth
                    type='text'
                    name='last_name'
                    onChange={(e) => {
                      const name = e.target.name;
                      const value = e.target.value;

                      updateEntity(draft => {
                        draft[name] = value
                      })
                    }}
                    error={!!errors?.last_name}
                    label={t('users.last_name')}
                    helperText={errors?.last_name}
                    value={entity?.last_name || ''}
                  />
                  <TextField
                    fullWidth
                    type='text'
                    name='email'
                    autoComplete='off'
                    onChange={(e) => {
                      const name = e.target.name;
                      const value = e.target.value;

                      updateEntity(draft => {
                        draft[name] = value
                      })
                    }}
                    error={!!errors?.email}
                    label={t('users.email')}
                    helperText={errors?.email}
                    value={entity?.email || ''}
                  />
                </div>
              </CardContent>
              <div/>
            </Card>
            <Card
              elevation={3}
              className={classes.nestedCard}
            >
              <CardHeader
                color='primary'
                title={t('users.accesses')}
              />
              <CardContent
                classes={{
                  root: classes.nestedCardContentRoot,
                }}
              >
                <ModulePermissions
                  onChange={handlePermissionsChange}
                  data={entity?.permissions || emptyArray}
                />
              </CardContent>
              <div/>
            </Card>
            {isAdmin && (
              <Card
                elevation={3}
                className={classes.nestedCard}
              >
                <CardHeader
                  color='primary'
                  title={t('users.plans_and_permissions')}
                />
                <CardContent
                  classes={{
                    root: classes.nestedCardContentRoot,
                  }}
                >
                  {!!entity.pendingPlan && (
                    <Typography
                      component='div'
                      style={{
                        display     : 'flex',
                        overflowX   : 'auto',
                        paddingLeft : 8,
                        paddingRight: 8,
                      }}
                    >
                      {(entity?.subscriptionPlans || emptyArray).map((plan: any) => {
                        return (
                          <SubscriptionPlan
                            plan={plan}
                            key={plan.id}
                            onChange={(e: any) => {
                              const planId = e.currentTarget.dataset.id;
                              updateEntity(draft => {
                                draft.pendingPlan = Number(planId)
                              })
                            }}
                            selected={entity?.pendingPlan === plan.id}
                          />
                        )
                      })}
                    </Typography>
                  )}
                  {!!entity?.acl && (
                    <>
                      <Typography
                        color='primary'
                        className={classes.title}
                      >{t('users.modify_permissions_and_limits')}
                      </Typography>
                      <table
                        className={classes.simpleTable}
                      >
                        <tbody>
                        <tr>
                          <td
                            colSpan={2}
                          >
                            <FormControlLabel
                              disabled
                              labelPlacement='end'
                              checked={!!entity.enabled}
                              classes={{root: classes.formControlLabel}}
                              label={
                                <Typography
                                  variant='body1'
                                  component='span'
                                  color='textSecondary'
                                >{t('users.user_is_enabled')}
                                </Typography>
                              }
                              control={
                                <IOSSwitch
                                  size='small'
                                  name='enabled'
                                />
                              }
                            />
                          </td>
                          {!!entity?.limits && (
                            <td>
                              <table>
                                <tbody>
                                {Object.keys(users || {}).map((limitKey: string) => {
                                  switch (limitKey) {
                                    case 'per_account_max_image_size':
                                    case 'per_account_max_storage_size': {
                                      return (
                                        <tr
                                          key={limitKey}
                                        >
                                          <td>
                                            <div
                                              style={{
                                                display   : 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <TextField
                                                disableMargin
                                                className={classes.textField}
                                                error={!!errors[`limits.users.${limitKey}`]}
                                                value={formatBytesToObject(users?.[limitKey] || 0).number || ''}
                                                inputProps={{
                                                  type: 'number',
                                                  max : formatBytesToObject(entity?.freezeLimits?.users?.[limitKey] || 0).number
                                                }}
                                                onChange={(e) => {
                                                  const sizes: any = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
                                                  const size: any = formatBytesToObject(entity?.freezeLimits?.users?.[limitKey] || 0).size;
                                                  const val: any = formatBytesToObject(entity?.freezeLimits?.users?.[limitKey] || 0).number;
                                                  const s: any = sizes.findIndex((i: any) => size === i);

                                                  let value: any = e.target.value;
                                                  if (value && Number(value)) {
                                                    const newValue = Number(value);
                                                    if (newValue > val) {
                                                      return void 0;
                                                    }

                                                    updateEntity(draft => {
                                                      if (draft?.limits?.users) {
                                                        if (s) {
                                                          draft.limits.users[limitKey] = newValue * Math.pow(1024, s);
                                                        } else {
                                                          draft.limits.users[limitKey] = newValue;
                                                        }
                                                      }
                                                    })
                                                    return void 0;
                                                  }

                                                  updateEntity(draft => {
                                                    if (draft?.limits?.users) {
                                                      draft.limits.users[limitKey] = value;
                                                    }
                                                  })
                                                }}
                                              />
                                              {!!errors[`limits.users.${limitKey}`] && (
                                                <Tooltip
                                                  title={errors[`limits.users.${limitKey}`]}
                                                >
                                                  <ErrorIcon
                                                    color='error'
                                                    fontSize='small'
                                                    style={{marginLeft: 5}}
                                                  />
                                                </Tooltip>
                                              )}
                                            </div>
                                          </td>
                                          <td>
                                            <Typography
                                              style={{width: 200, overflowWrap: 'anywhere', flexWrap: 'wrap'}}
                                            >{t(`acl.users-${limitKey}_max`, {max: formatBytes(entity?.freezeLimits?.users?.[limitKey] || 0)})}
                                            </Typography>
                                          </td>
                                        </tr>
                                      )
                                    }
                                    default: {
                                      return (
                                        <tr
                                          key={limitKey}
                                        >
                                          <td>
                                            <div
                                              style={{
                                                display   : 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <TextField
                                                disableMargin
                                                value={users?.[limitKey]}
                                                className={classes.textField}
                                                error={!!errors[`limits.users.${limitKey}`]}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  updateEntity(draft => {
                                                    if (draft?.limits?.users) {
                                                      draft.limits.users[limitKey] = value;
                                                    }
                                                  })
                                                }}
                                              />
                                              {!!errors[`limits.users.${limitKey}`] && (
                                                <Tooltip
                                                  title={errors[`limits.users.${limitKey}`]}
                                                >
                                                  <ErrorIcon
                                                    color='error'
                                                    fontSize='small'
                                                    style={{marginLeft: 5}}
                                                  />
                                                </Tooltip>
                                              )}
                                            </div>
                                          </td>
                                          <td>
                                            <Typography
                                              style={{width: 200, overflowWrap: 'anywhere', flexWrap: 'wrap'}}
                                            >{t(`acl.users-${limitKey}_max`, {max: entity?.freezeLimits?.users?.[limitKey] || 0})}
                                            </Typography>
                                          </td>
                                        </tr>
                                      )
                                    }
                                  }
                                })}
                                </tbody>
                              </table>
                            </td>
                          )}
                        </tr>
                        {orderedAclKeys.map((acl: any, aclIndex: number) => {
                          const aclKey: string = acl.slug;
                          const data = entity?.acl || emptyObject;

                          if (data[aclKey]) {
                            const features: any = data[aclKey][FEATURE] || {};
                            const orderedFeatures: any[] = acl[FEATURE] || [];
                            const featureGroup: any = data[aclKey][FEATURE_GROUP];
                            const checked = Boolean(featureGroup[ACCESS_TYPES.VISIBLE]);
                            const disabled = Boolean(featureGroup[`disabled_${ACCESS_TYPES.VISIBLE}`]);
                            const currentModule = restModules?.[aclKey] || {};
                            const currentFreezeModule = entity?.freezeLimits?.[aclKey] || {};

                            return (
                              <React.Fragment
                                key={aclIndex}
                              >
                                <tr>
                                  <td>
                                    <FormControlLabel
                                      checked={checked}
                                      disabled={disabled}
                                      labelPlacement='end'
                                      onChange={handleOnChange}
                                      classes={{root: classes.formControlLabel}}
                                      label={
                                        <Typography
                                          noWrap
                                          variant='body1'
                                          component='span'
                                          color='textSecondary'
                                        >{t(`acl.${aclKey}`)}
                                        </Typography>
                                      }
                                      control={
                                        <IOSSwitch
                                          size='small'
                                          name='enabled'
                                          inputProps={{
                                            'data-acl-key'    : aclKey,
                                            'data-access-type': ACCESS_TYPES.VISIBLE,
                                          } as any}
                                        />
                                      }
                                    />
                                  </td>
                                  <td>
                                    {orderedAccessTypes.map((access: string, accessIndex: number) => {
                                      const availableAccess: string = featureGroup[access];
                                      const disabled: boolean = featureGroup[`disabled_${access}`];

                                      if (availableAccess !== undefined) {
                                        return (
                                          <div
                                            key={accessIndex}
                                            style={{
                                              display: 'inline-flex',
                                            }}
                                          >
                                            <FormControlLabel
                                              disabled={disabled}
                                              label={
                                                <Typography
                                                  noWrap
                                                  color='inherit'
                                                  variant='inherit'
                                                >{t(`acl.${access}`)}
                                                </Typography>
                                              }
                                              onChange={handleOnChange}
                                              checked={Boolean(availableAccess)}
                                              control={
                                                <Checkbox
                                                  size='small'
                                                  color='primary'
                                                  inputProps={{
                                                    'data-acl-key'    : aclKey,
                                                    'data-access-type': access,
                                                  } as any}
                                                />
                                              }
                                            />
                                            {errors[`acl.${aclKey}.fg.${access}`] && (
                                              <Tooltip
                                                title={errors[`acl.${aclKey}.fg.${access}`]}
                                              >
                                                <ErrorIcon
                                                  color='error'
                                                  fontSize='small'
                                                  style={{
                                                    right   : 10,
                                                    position: 'relative',
                                                  }}
                                                />
                                              </Tooltip>
                                            )}
                                          </div>
                                        )
                                      }

                                      return void 0;
                                    })}
                                  </td>
                                  {!!entity?.limits && (
                                    <td>
                                      <table>
                                        <tbody>
                                        {Object.keys(currentModule).map((limitKey: string) => {
                                          const currentLimit = currentModule?.[limitKey];
                                          const currentFreezeLimit = currentFreezeModule?.[limitKey];

                                          switch (limitKey) {
                                            default: {
                                              return (
                                                <tr
                                                  key={limitKey}
                                                >
                                                  <td>
                                                    <div
                                                      style={{
                                                        display   : 'flex',
                                                        alignItems: 'center',
                                                      }}
                                                    >
                                                      <TextField
                                                        disableMargin
                                                        value={currentLimit || ''}
                                                        className={classes.textField}
                                                        error={!!errors[`limits.${aclKey}.${limitKey}`]}
                                                        onChange={(e) => {
                                                          const value = e.target.value;
                                                          updateEntity(draft => {
                                                            if (draft?.limits?.[aclKey]) {
                                                              draft.limits[aclKey][limitKey] = value;
                                                            }
                                                          })
                                                        }}
                                                      />
                                                      {!!errors[`limits.${aclKey}.${limitKey}`] && (
                                                        <Tooltip
                                                          title={errors[`limits.${aclKey}.${limitKey}`]}
                                                        >
                                                          <ErrorIcon
                                                            color='error'
                                                            fontSize='small'
                                                            style={{marginLeft: 5}}
                                                          />
                                                        </Tooltip>
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <Typography
                                                      style={{width: 200, overflowWrap: 'anywhere', flexWrap: 'wrap'}}
                                                    >{t(`acl.${aclKey}-${limitKey}_max`, {max: currentFreezeLimit})}
                                                    </Typography>
                                                  </td>
                                                </tr>
                                              )
                                            }
                                          }
                                        })}
                                        </tbody>
                                      </table>
                                    </td>
                                  )}
                                </tr>
                                {orderedFeatures.map((feature: any, featureIndex: number) => {
                                  if (features[feature.slug]) {
                                    const currentFeature: any = features[feature.slug];
                                    const currentFeatureIsDisabled = currentFeature[`disabled_${ACCESS_TYPES.VISIBLE}`]

                                    return (
                                      <tr
                                        key={featureIndex}
                                      >
                                        <td>
                                          <FormControlLabel
                                            key={featureIndex}
                                            labelPlacement='end'
                                            onChange={handleOnChange}
                                            disabled={currentFeatureIsDisabled}
                                            classes={{root: classes.formControlLabel}}
                                            checked={Boolean(currentFeature[ACCESS_TYPES.VISIBLE])}
                                            label={
                                              <Typography
                                                variant='body1'
                                                component='span'
                                                color='textSecondary'
                                              >{t(`acl.${feature.slug}`)}
                                              </Typography>
                                            }
                                            control={
                                              <IOSSwitch
                                                size='small'
                                                name='enabled'
                                                inputProps={{
                                                  'data-acl-key'     : aclKey,
                                                  'data-feature-slug': feature.slug,
                                                  'data-access-type' : ACCESS_TYPES.VISIBLE,
                                                } as any}
                                              />
                                            }
                                          />
                                        </td>
                                        <td>
                                          {(feature[FEATURE_GROUP] || []).map((featureAccess: string, featureAccessIndex: number) => {
                                            if (currentFeature[featureAccess] !== undefined) {
                                              const currentFeatureIsDisabled = currentFeature[`disabled_${featureAccess}`]

                                              return (
                                                <div
                                                  key={featureAccessIndex}
                                                  style={{
                                                    display: 'inline-flex',
                                                  }}
                                                >
                                                  <FormControlLabel
                                                    disabled={currentFeatureIsDisabled}
                                                    label={
                                                      <Typography
                                                        noWrap
                                                        color='inherit'
                                                        variant='inherit'
                                                      >{t(`acl.${featureAccess}`)}
                                                      </Typography>
                                                    }
                                                    onChange={handleOnChange}
                                                    checked={Boolean(currentFeature[featureAccess])}
                                                    control={
                                                      <Checkbox
                                                        size='small'
                                                        color='primary'
                                                        inputProps={{
                                                          'data-acl-key'     : aclKey,
                                                          'data-access-type' : featureAccess,
                                                          'data-feature-slug': feature.slug,
                                                        } as any}
                                                      />
                                                    }
                                                  />
                                                </div>
                                              )
                                            }

                                            return void 0;
                                          })}
                                        </td>
                                      </tr>
                                    )
                                  }

                                  return void 0;
                                })}
                              </React.Fragment>
                            )
                          }

                          return void 0;
                        })}
                        </tbody>
                      </table>
                      <Typography
                        component='div'
                        className={classes.paymentOptionsRoot}
                      >
                        <Typography
                          component='div'
                          className={classes.updateSubscriptionContainer}
                        >
                          <Checkbox
                            color='primary'
                            disabled={!uuid}
                            name='update_subscription'
                            onChange={(e, checked) => {
                              updateEntity(draft => {
                                draft.update_subscription = checked
                              })
                            }}
                            checked={!!entity?.update_subscription}
                          />
                          <div
                            className={classes.checkboxLabelRoot}
                          >
                            <Typography
                              color='primary'
                              className={clsx(classes.title, classes.checkboxLabel)}
                            >{t('users.modify_plan_name_and_payment_options')}
                            </Typography>
                            {!!uuid && (
                              <Tooltip
                                title={t('users.modify_plan_name_and_payment_options_helper')}
                              >
                                <InfoIcon
                                  fontSize='small'
                                />
                              </Tooltip>
                            )}
                          </div>
                        </Typography>
                        <Typography
                          component='div'
                        >
                          {!!entity?.update_subscription ? (
                            <>
                              <Typography
                                component='div'
                                className={classes.planPriceContainer}
                              >
                                <TextField
                                  fullWidth
                                  name='plan'
                                  disableMargin
                                  error={!!errors?.plan}
                                  onChange={(e) => {
                                    const name = e.target.name;
                                    const value = e.target.value;
                                    updateEntity(draft => {
                                      draft[name] = value;
                                    })
                                  }}
                                  style={{marginRight: 4}}
                                  helperText={errors?.plan}
                                  value={entity?.plan || ''}
                                  label={t('users.plan_name')}
                                />
                                <TextField
                                  fullWidth
                                  name='price'
                                  disableMargin
                                  error={!!errors?.price}
                                  onChange={(e) => {
                                    const name = e.target.name;
                                    const value = e.target.value;
                                    updateEntity(draft => {
                                      draft[name] = value;
                                    })
                                  }}
                                  style={{marginLeft: 4}}
                                  label={t('users.price')}
                                  helperText={errors?.price}
                                  value={entity?.price ?? ''}
                                  inputProps={{type: 'number', min: 0}}
                                />
                              </Typography>
                              <Typography
                                component='div'
                                className={classes.autoCompleteContainer}
                              >
                                <AutoComplete
                                  fullWidth
                                  disableClearable
                                  style={{marginRight: 4}}
                                  options={PaymentCycleOptions}
                                  value={entity?.billing_cycle}
                                  renderInput={(props) => {
                                    return (
                                      <TextField
                                        type='text'
                                        variant='outlined'
                                        name='free_billing_cycle'
                                        label={t('users.payment_cycle')}
                                        error={!!errors?.billing_cycle}
                                        helperText={errors?.billing_cycle}
                                        {...props}
                                      />
                                    )
                                  }}
                                  onChange={(event, value) => {
                                    updateEntity(draft => {
                                      draft.billing_cycle = value;
                                    })
                                  }}
                                  getOptionLabel={option => option?.label || ''}
                                  getOptionSelected={(option, value) => (value && value.value) === (option && option.value)}
                                />
                                <AutoComplete
                                  fullWidth
                                  style={{marginLeft: 4}}
                                  value={entity?.cycle_count}
                                  renderInput={(props) => {
                                    return (
                                      <TextField
                                        type='text'
                                        variant='outlined'
                                        name='free_cycle_count'
                                        error={!!errors?.cycle_count}
                                        label={t('users.number_of_periods')}
                                        helperText={errors?.cycle_count}
                                        {...props}
                                      />
                                    )
                                  }}
                                  options={NumberOfPeriodsOptions}
                                  onChange={(event, value) => {
                                    updateEntity(draft => {
                                      draft.cycle_count = value;
                                    })
                                  }}
                                  getOptionLabel={option => option?.label || ''}
                                  getOptionSelected={(option, value) => (value && value.value) === (option && option.value)}
                                />
                              </Typography>
                            </>
                          ) : (
                            <>

                              <table
                                className={classes.simpleTable}
                              >
                                <thead>
                                <tr>
                                  <td>
                                    <Typography
                                      variant='body1'
                                      color='textSecondary'
                                    >{t('users.p_name')}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      variant='body1'
                                      color='textSecondary'
                                    >{entity.plan}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      variant='body1'
                                      color='textSecondary'
                                    >{t('users.price')}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      variant='body1'
                                      color='textSecondary'
                                    >{`$${entity.price || 0}`}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      variant='body1'
                                      color='textSecondary'
                                    >{t('users.cycle_count')}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      variant='body1'
                                      color='textSecondary'
                                    >{entity.cycle_count?.value}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      variant='body1'
                                      color='textSecondary'
                                    >{t('users.payment_cycle')}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      variant='body1'
                                      color='textSecondary'
                                    >{entity.billing_cycle?.label}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      variant='body1'
                                      color='textSecondary'
                                    >{t('users.start_date')}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      variant='body1'
                                      color='textSecondary'
                                    >{momentTZ(entity.plan_started_at).format(planStartedAtFormat)}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      variant='body1'
                                      color='textSecondary'
                                    >{t('users.expiration_date')}
                                    </Typography>
                                  </td>

                                  <td>
                                    <Typography
                                      variant='body1'
                                      color='textSecondary'
                                    >{momentTZ(entity.expires_at).format(expiresAtFormat)}
                                    </Typography>
                                  </td>
                                </tr>
                                </thead>
                              </table>
                            </>
                          )}
                        </Typography>
                      </Typography>
                    </>
                  )}
                  <Typography
                    component='div'
                  >
                    {(!!entity.pendingPlan) ? (
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={() => {
                          updateEntity((draft: any) => {
                            const sPlan = draft?.subscriptionPlans?.find((plan: any) => plan.id === draft.pendingPlan);

                            if (sPlan) {
                              draft.selectedPlan = draft.pendingPlan;

                              draft.plan = sPlan.name;
                              draft.acl = sPlan.details;
                              draft.acl = sPlan.details;
                              draft.price = sPlan.price;
                              draft.limits = sPlan.limits;
                              draft.freezeLimits = sPlan.limits;
                              draft.cycle_count = sPlan.free_cycle_count;
                              draft.billing_cycle = sPlan.free_billing_cycle;
                              draft.plan_template_name = sPlan.plan_template_name || sPlan.slug;

                              draft.pendingPlan = void 0;
                            }
                          })
                        }}
                        style={{marginTop: 32}}
                      >{t('users.select_template_plan')}
                      </Button>
                    ) : (
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={() => {
                          dispatch(toggleErrorsAction(void 0));

                          if (uuid) {
                            if (entity.subscriptionPlans?.[0]?.id) {
                              updateEntity((draft: any) => {
                                draft.acl = void 0;
                                draft.pendingPlan = entity.subscriptionPlans?.[0].id;
                                draft.selectedPlan = void 0;
                              })
                            }
                          } else {
                            updateEntity((draft: any) => {
                              draft.acl = void 0;
                              draft.pendingPlan = draft.selectedPlan;
                              draft.selectedPlan = void 0;
                            })
                          }
                        }}
                      >{t('users.replace_with_template_plan')}
                      </Button>
                    )}
                  </Typography>
                </CardContent>
                <div/>
              </Card>
            )}
          </CardContent>
        </Card>
        <Card
          classes={{root: classes.cardRoot}}
        >
          <CardContent
            classes={{root: classes.cardContentRoot}}
          >
            <Typography
              component='div'
              className={classes.buttonsContainer}
            >
              <Button
                color='primary'
                variant='contained'
                onClick={createOrUpdate}
                loading={isCreatingOrUpdating}
                disabled={isCreatingOrUpdating || (isAdmin && !entity.selectedPlan && !uuid)}
              >{t('buttons.save')}
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Typography>
    </Content>
  )
}
