import React from 'react';
import {Webhooks} from './Webhooks';
import Box from '@material-ui/core/Box';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {Card} from '../../../../../../../components/Card';
import {Control} from 'react-hook-form/dist/types/form';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {IOSSwitch} from '../../../../../../../components/IOSSwitch';
import {UseFormGetValues} from 'react-hook-form/dist/types/form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {CardHeader} from '../../../../../../../components/CardHeader';
import {ErrorMessage} from '../../../../../../../components/ErrorMessage';
import {TextField} from '../../../../../../../components/BorderedTextField';
import {ButtonAdornment} from '../../../../../../../components/FileAdornment';

const useStyles = makeStyles((theme: Theme) => ({
  formControlLabel: {
    marginLeft  : 0,
    marginRight : 0,
    marginBottom: 0,
    transition  : theme.transitions.create(['all']),
  },
  labelRoot       : {
    display   : 'flex',
    alignItems: 'center',
    '& > svg' : {
      marginLeft: theme.spacing(.5)
    },
  },
}));

export function GeneralCard(
  {
    tags,
    control,
    setValue,
    disabled,
    getValues,
    hasWebhooksAccess,
    hasForcePromptAccess,
  }: {
    disabled?: boolean;
    hasWebhooksAccess?: boolean;
    tags: Record<string, string>;
    hasForcePromptAccess?: boolean;
    control: Control<Record<string, any>>;
    setValue: UseFormSetValue<Record<string, any>>;
    getValues: UseFormGetValues<Record<string, any>>;
  }
): JSX.Element {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Card>
      <CardHeader
        title={t('app_settings.general')}
        color={disabled ? 'disabled' : 'primary'}
      />
      <CardContent>
        <Controller
          name='title'
          control={control}
          render={(
            {
              field     : {
                ref,
                value,
                ...fieldRest
              },
              fieldState: {
                error: localError
              }
            }
          ) => {
            return (
              <TextField
                fullWidth
                inputRef={ref}
                margin='normal'
                autoComplete='off'
                disabled={disabled}
                value={value || ''}
                error={!!localError?.message}
                helperText={localError?.message}
                label={t('app_settings.app_title')}
                placeholder={t('app_settings.title_placeholder')}
                {...fieldRest}
              />
            );
          }}
        />
        <Controller
          control={control}
          name='default_icon_url'
          render={(
            {
              field     : {
                ref,
                value,
                ...fieldRest
              },
              fieldState: {
                error: localError
              }
            }
          ) => {
            return (
              <ButtonAdornment
                aspectRatio={1}
                label='Icon URL'
                setValue={setValue}
                disabled={disabled}
                name={fieldRest.name}
                getValues={getValues}
                defaultValue={value || ''}
                helperText='192x192px recommended'
                errorMessage={localError?.message}
              />
            )
          }}
        />
        <Controller
          name='domain'
          control={control}
          render={(
            {
              field     : {
                ref,
                value,
                ...fieldRest
              },
              fieldState: {
                error: localError
              }
            }
          ) => {
            return (
              <TextField
                fullWidth
                margin='none'
                label='Domain'
                inputRef={ref}
                autoComplete='off'
                value={value || ''}
                setValue={setValue}
                disabled={disabled}
                error={!!localError?.message}
                helperText={localError?.message}
                placeholder={t('app_settings.default_url_placeholder')}
                {...fieldRest}
              />
            );
          }}
        />
        <Controller
          control={control}
          name='default_url'
          render={(
            {
              field     : {
                ref,
                value,
                ...fieldRest
              },
              fieldState: {
                error: localError
              }
            }
          ) => {
            return (
              <TextField
                fullWidth
                tags={tags}
                margin='none'
                inputRef={ref}
                autoComplete='off'
                value={value || ''}
                setValue={setValue}
                disabled={disabled}
                error={!!localError?.message}
                helperText={localError?.message}
                label={t('app_settings.default_url')}
                placeholder={t('app_settings.default_url_placeholder')}
                {...fieldRest}
              />
            );
          }}
        />
        {hasWebhooksAccess && (
          <Webhooks
            control={control}
            disabled={disabled}
          />
        )}
        {hasForcePromptAccess && (
          <Controller
            control={control}
            name='force_prompt'
            render={(
              {
                field     : {
                  ref,
                  value,
                  ...fieldRest
                },
                fieldState: {
                  error: localError
                }
              }
            ) => {
              return (
                <Box>
                  <FormControlLabel
                    value={value}
                    inputRef={ref}
                    checked={!!value}
                    disabled={disabled}
                    labelPlacement='end'
                    {...fieldRest}
                    classes={{root: classes.formControlLabel}}
                    label={
                      <Typography
                        variant='body1'
                        color='inherit'
                        component='span'
                        className={classes.labelRoot}
                      >
                        <Typography
                          variant='inherit'
                        >{t('app_settings.notification_prompt_available')}
                        </Typography>
                        {disabled ? (
                          <InfoIcon/>
                        ) : (
                          <Tooltip
                            title={t('app_settings.notification_prompt_available_helper')}
                          >
                            <InfoIcon/>
                          </Tooltip>
                        )}
                      </Typography>
                    }
                    control={
                      <IOSSwitch
                        size='small'
                      />
                    }
                  />
                  <ErrorMessage
                    message={localError?.message}
                  />
                </Box>
              )
            }}
          />
        )}
        <Controller
          control={control}
          name='gdpr_compliance'
          render={(
            {
              field     : {
                ref,
                value,
                ...fieldRest
              },
              fieldState: {
                error: localError
              }
            }
          ) => {
            return (
              <Box>
                <FormControlLabel
                  value={value}
                  inputRef={ref}
                  disabled={true}
                  checked={!!value}
                  labelPlacement='end'
                  {...fieldRest}
                  classes={{root: classes.formControlLabel}}
                  label={
                    <Typography
                      variant='body1'
                      color='inherit'
                      component='span'
                      className={classes.labelRoot}
                    >
                      <Typography
                        variant='inherit'
                      >GDPR / CalOPPA Compliance
                      </Typography>
                      <Tooltip
                        title={'When turned on, we will not store any personally-identifying data about your subscribers. You can only change this setting when creating a new app.'}
                      >
                        <InfoIcon/>
                      </Tooltip>
                    </Typography>
                  }
                  control={
                    <IOSSwitch
                      size='small'
                    />
                  }
                />
                <ErrorMessage
                  message={localError?.message}
                />
              </Box>
            )
          }}
        />
      </CardContent>
    </Card>
  )
}
