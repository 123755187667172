import {notification} from 'antd';
import {_dataOptions} from './index';
import {_toggleEntity} from './index';
import ym from 'react-yandex-metrika';
import {momentUTC} from '../../../utils';
import {Globals} from '../../../types/globals';
import {dataLayer} from '../../../utils/dataLayer';
import {urlHelper} from '../../../utils/urlHelper';
import {_languages} from '../../selectors/locales';
import {_countries} from '../../selectors/locales';
import {DEFAULT_ICON_URL} from '../../../constants';
import {_user} from '../../selectors/application/acl';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {_userId} from '../../selectors/application/acl';
import {_cdnUrl} from '../../selectors/application/acl';
import {_currentAppValidation} from '../../selectors/apps';
import {_integrationIsCompleted} from '../../selectors/apps';
import {FiniteStates} from '../../../constants/finiteStates';
import {_hasLocalesAccess} from '../../selectors/application/acl';
import {SHOW_CONGRATULATORY_MESSAGE_AFTER_REGISTERED_AT} from '../../../constants';

export const _get = (
  appUuid: string,
  {
    track,
    skipDelay,
    skipLoading,
  }: {
    track?: () => void;
    skipDelay?: boolean;
    skipLoading?: boolean;
  } = {}
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  if (!skipLoading) {
    dispatch(_dataOptions({
      fetchingAppState: FiniteStates.LOADING,
    }));
  }

  const cndUrl = _cdnUrl(getState());

  const {
    body: {
      data,
      status,
    },
    hasError,
  } = await api.get(urlHelper(ApiRoutes.GET_APP, {appUuid}), {
    skipDelay: (skipDelay !== undefined) ? skipDelay : true,
  });

  if (!hasError) {
    let languages = [] as any;
    let countries = [] as any;

    if (_hasLocalesAccess(getState())) {
      countries = _countries(getState()).map((country: {
        id: number;
        iso: string;
        text: string;
      }) => ({
        _id : country.id,
        iso : country.iso,
        text: country.text,
      }));

      languages = _languages(getState()).map((language: {
        id: number;
        iso: string;
        text: string;
      }) => ({
        _id : language.id,
        iso : language.iso,
        text: language.text,
      }))
    }

    const {
      taggedItems,
      advanced_parameters,
    } = data;

    languages.unshift({
      _id : 'default',
      iso : 'default',
      text: 'Default',
    });

    countries.unshift({
      _id : 'default',
      iso : 'default',
      text: 'Default',
    });

    const launch_url_tags: Record<string, string> = {
      ...taggedItems,
      ...Object.keys(advanced_parameters || {}).reduce((acc: Record<string, string>, curr: string) => {
        acc[`{${curr}}`] = `{${curr}}`;
        return acc;
      }, {}),
    };

    const advancedParameters: Record<string, string> = Object.keys(advanced_parameters || {}).reduce((acc: Record<string, any>, curr: string) => {
      const column = advanced_parameters[curr]['column'];

      acc[column] = {
        ...advanced_parameters[curr],
        parameter: curr,
      };
      return acc;
    }, {});

    const default_icon_url = data.default_icon_url || urlHelper(DEFAULT_ICON_URL, {CDN_URL: cndUrl});

    const diff = momentUTC(SHOW_CONGRATULATORY_MESSAGE_AFTER_REGISTERED_AT) < momentUTC(_user(getState()).created_at);
    let congratulatory = false;
    const userId = _userId(getState());
    if (data.active_users > 0 && diff && !localStorage.getItem(`first_subscriber_message_${userId}`)) {
      congratulatory = true;
    }

    dispatch(_toggleEntity({
      ...data,
      languages,
      countries,
      congratulatory,
      launch_url_tags,
      default_icon_url,
      response_status    : 200,
      advanced_parameters: advancedParameters,
    }));

    const currentAppValidation = _currentAppValidation(getState());
    const integrationIsCompleted = _integrationIsCompleted(getState());

    if (
      !integrationIsCompleted &&
      currentAppValidation?.uuid &&
      currentAppValidation?.script_exist
    ) {
      const {hasError} = await api.post(urlHelper(ApiRoutes.SAVE_STEP, {
        appUuid,
      }), {
        creation_step    : 2,
        integration_state: 1,
      });

      if (!hasError) {
        notification.success({
          duration: 15,
          message : 'Integration is successful',
        });

        dataLayer({
          app_uuid: appUuid,
          event   : 'integration-succeeded',
        });
        try {
          await ym('reachGoal', 'integration-succeeded', {
            app_uuid: appUuid,
          });
        } catch (e) {
          console.error(e)
        }
      }

      await dispatch(_get(appUuid, {skipDelay, skipLoading}))
    }

    dispatch(_dataOptions({
      fetchingAppState: FiniteStates.SUCCESS,
    }));
  } else {
    dispatch(_toggleEntity({
      response_status: status,
    }));
    dispatch(_dataOptions({
      fetchingAppState: FiniteStates.FAILURE,
    }));
  }
};
