import {Spin} from 'antd';
import React from 'react';
import axios from 'axios';
import {Modal} from 'antd';
import {Upload} from 'antd';
import {parse} from 'querystring';
import {useImmer} from 'use-immer';
import Cropper from 'react-easy-crop';
import {RcFile} from 'antd/es/upload';
import {stringify} from 'querystring';
import {useSelector} from 'react-redux';
import * as Sentry from '@sentry/react';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {Area} from 'react-easy-crop/types';
import {Point} from 'react-easy-crop/types';
import {MediaSize} from 'react-easy-crop/types';
import {UseFormSetError} from 'react-hook-form';
import {UploadOutlined} from '@ant-design/icons';
import {ICreateAppForm} from '../../useUpsertApp';
import {UseFormClearErrors} from 'react-hook-form';
import {UploadFile} from 'antd/lib/upload/interface';
import {Control} from 'react-hook-form/dist/types/form';
import {Globals} from '../../../../../../../types/globals';
import {baseURL} from '../../../../../../../store/services';
import {urlHelper} from '../../../../../../../utils/urlHelper';
import {DEFAULT_ICON_URL} from '../../../../../../../constants';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {Text} from '../../../../../../../components/antd/Typography';
import {FiniteStates} from '../../../../../../../constants/finiteStates';
import {_cdnUrl} from '../../../../../../../store/selectors/application/acl';
import {_accessToken} from '../../../../../../../store/selectors/application';
import {errorMessagesTransformer} from '../../../../../../../utils/errorMessagesTransformer';
import './index.less';

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Accept'      : 'application/json',
    'Content-Type': 'application/json',
  }
});

interface IInitialState {
  crop: Point;
  fullUrl?: string;
  mediaSize?: MediaSize;
  fileState: FiniteStates;
  croppedAreaPixels?: Area;
  initialCroppedAreaPixels?: any;
}

const InitialState: IInitialState = {
  crop     : {x: 0, y: 0},
  fileState: FiniteStates.IDLE,
}

export function Uploader(
  {
    error,
    control,
    setValue,
    setError,
    clearErrors,
  }: {
    error?: string;
    control: Control<ICreateAppForm>;
    setError: UseFormSetError<ICreateAppForm>;
    setValue: UseFormSetValue<ICreateAppForm>;
    clearErrors: UseFormClearErrors<ICreateAppForm>;
  }
) {
  const cdnUrl = useSelector(_cdnUrl);
  const accessToken = useSelector(_accessToken);
  const url = urlHelper(DEFAULT_ICON_URL, {CDN_URL: cdnUrl});
  const [data, setData] = useImmer<IInitialState>(InitialState);

  const defaultIconUrl = useWatch({
    control,
    name: 'default_icon_url',
  });

  const uploadProps: Record<string, any> = {
    onStart  : () => setData(draft => {
      draft.fileState = FiniteStates.LOADING
    }),
    onError  : (error: any) => setData(draft => {
      try {
        Sentry.captureException(error, {
          tags: {
            section: 'app_uploader',
          },
        });
        Sentry.captureException(error.response, {
          tags: {
            section: 'app_uploader',
          },
        });
      } catch (e) {
      }
      const errorMessages = errorMessagesTransformer(error.response.data.errors);
      if (errorMessages['files.0']) {
        setError('default_icon_url', {
          message: errorMessages['files.0'].replaceAll('files.0', 'file')
        });
      } else {
        setError('default_icon_url', {
          message: 'Something went wrong please try again later'
        });
      }

      draft.fileState = FiniteStates.FAILURE
    }),
    onSuccess: (a: RcFile, b: RcFile, response: Globals.SPFile) => setData(draft => {
      draft.fullUrl = `${response.full_url}?random=${Math.random()}`;
      draft.fileState = FiniteStates.SUCCESS;
    }),
  }

  function onOk() {
    setValue(
      'default_icon_url',
      `${data.fullUrl.split('?')[0]}?${stringify(data.croppedAreaPixels)}`
    );
    setData(() => {
      return {...InitialState}
    })
  }

  function onRemove() {
    clearErrors('default_icon_url');
    setValue('default_icon_url', url);
  }

  function onCancel() {
    setData(() => {
      return {...InitialState};
    })
  }

  function beforeUpload(file: RcFile) {
    clearErrors('default_icon_url');

    if (file.size <= 2097152) {
      return true;
    } else {
      setError('default_icon_url', {
        message: 'The file may not be greater than 2048Kb.',
      });
      return false;
    }
  }

  function onPreview(file: UploadFile) {
    setData((draft) => {
      const urlArray = file.url.split('?');
      draft.fullUrl = `${urlArray[0]}?random=${Math.random()}`;
      if (urlArray[1]) {
        draft.initialCroppedAreaPixels = parse(urlArray[1])
      }
    })
  }

  function onCropChange(location: Point) {
    setData(draft => {
      draft.crop = location;
    });
  }

  function onMediaLoaded(mediaSize: MediaSize) {
    setData(draft => {
      draft.mediaSize = mediaSize;
    });
  }

  function onCropComplete(croppedArea: Area, croppedAreaPixels: Area) {
    setData(draft => {
      draft.croppedAreaPixels = croppedAreaPixels;
    })
  }

  return (
    <>
      <Upload
        maxCount={1}
        name='files[]'
        action='/media'
        fileList={[
          {
            uid   : '1',
            name  : 'image.png',
            url   : defaultIconUrl,
            status: !!error ? 'error' : 'done',
          }
        ]}
        multiple={false}
        onRemove={onRemove}
        onPreview={onPreview}
        showUploadList={{
          showDownloadIcon: false,
          showPreviewIcon : url !== defaultIconUrl,
          showRemoveIcon  : url !== defaultIconUrl,
        }}
        listType='picture-card'
        beforeUpload={beforeUpload}
        disabled={data.fileState === FiniteStates.LOADING}
        headers={{
          Authorization: `Bearer ${accessToken}`
        }}
        {...uploadProps}
        customRequest={(
          {
            file,
            action,
            headers,
            onError,
            filename,
            onSuccess,
            withCredentials,
          }
        ) => {
          const formData = new FormData();
          formData.append(filename, file);
          axiosInstance.post(action, formData, {
            headers,
            withCredentials,
          }).then(async ({data: response}) => {
            onSuccess(file, response[0]);
          }).catch(onError);
        }}
      >
        <Spin
          spinning={data.fileState === FiniteStates.LOADING}
        >
          <div
            className='upload-button'
          >
            <UploadOutlined/>
            <div
              className='m-t-2'
            >Upload Logo
            </div>
          </div>
        </Spin>
      </Upload>
      <Text
        type='secondary'
      >You can keep default bell or upload your logo Recommended resolution is 192*192
      </Text>
      <Controller
        name='default_icon_url'
        control={control}
        render={(
          {
            field     : {
              ref,
              value,
              ...fieldRest
            },
            fieldState: {
              error,
            }
          }
        ) => {
          return (
            <div>
              <Text
                type='danger'
              >{error?.message}
              </Text>
              <input
                value=''
                type='hidden'
                {...fieldRest}
              />
            </div>
          )
        }}
      />
      <Modal
        onOk={onOk}
        width={520}
        title='Edit image'
        style={{top: 100}}
        onCancel={onCancel}
        visible={!!data?.fullUrl}
        className='img-crop-modal'
        wrapClassName='img-crop-modal'
      >
        <Spin
          spinning={!data.mediaSize}
        >
          <Cropper
            aspect={1}
            crop={data.crop}
            image={data.fullUrl}
            onCropChange={onCropChange}
            onMediaLoaded={onMediaLoaded}
            onCropComplete={onCropComplete}
            classes={{containerClassName: 'img-crop-container'}}
            initialCroppedAreaPixels={data.initialCroppedAreaPixels}
            style={{mediaStyle: {opacity: !!data.mediaSize ? 1 : 0}}}
          />
        </Spin>
      </Modal>
    </>
  )
}