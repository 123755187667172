export function transformUrl(value: string = '') {
  if (!value?.trim()) {
    return value;
  }

  if (
    value.trim().toLowerCase().startsWith('http://') ||
    value.trim().toLowerCase().startsWith('https://')
  ) {
    return value.trim();
  } else {
    return `https://${value.trim()}`;
  }
}
