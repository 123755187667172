import React from 'react';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {TextField} from '../../../BorderedTextField';
import {Control} from 'react-hook-form/dist/types/form';

export function DenyButtonTextField(
  {
    index,
    control,
    disabled,
    getError,
  }: {
    index: number;
    control: Control;
    disabled?: boolean;
    getError: (name: string, index: number) => string;
  }
) {
  const {t} = useTranslation();

  const templateId = useWatch({
    control,
    name: `messages.[${index}].template_id`,
  });

  const isDisabled = disabled || [3].includes(templateId);

  return (
    <Controller
      control={control}
      name={`messages.[${index}].deny_text`}
      render={({field: {ref, ...fieldRest}}) => (
        <TextField
          fullWidth
          inputRef={ref}
          margin='normal'
          autoComplete='off'
          disabled={isDisabled}
          inputProps={{
            disabled: false,
            readOnly: isDisabled,
          }}
          error={!!getError('deny_text', index)}
          helperText={getError('deny_text', index)}
          label={t('app_settings.deny_button_text')}
          {...fieldRest}
        />
      )}
    />
  )
}