export const getList = async (
  url: string,
  accessToken: string | null,
  options: any,
): Promise<{
  hasError: boolean;
  body: {
    data: any;
    status: number;
    message?: string;
  };
}> => {
  try {
    const {
      page,
      queryItem,
      inputValue,
    } = options;

    const response = await fetch(`${url}?type_id=${queryItem.id}&search=${inputValue}&page=${page}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      const {
        message,
      } = await response.json();
      return {
        hasError: true,
        body    : {
          message,
          data  : {
            options   : [],
            additional: {},
            hasMore   : false,
          },
          status: response.status,
        },
      }
    } else {
      const {
        data,
      } = await response.json();
      return {
        hasError: false,
        body    : {
          data  : {
            options   : data.items.map((item: any) => ({
              value: item.id,
              label: item.text
            })),
            hasMore   : data.has_more,
            additional: {
              queryItem,
              page: page + 1,
            },
          },
          status: response.status || 200,
        },
      }
    }
  } catch (e) {
    return {
      hasError: true,
      body    : {
        status : e.status,
        message: e.message,
        data   : {
          options   : [],
          additional: {},
          hasMore   : false,
        },
      },
    }
  }
};

export const fetchList = async (
  url: string,
  accessToken: string | null,
  options: { page: number; search?: string, queryType: Record<string, any> },
): Promise<{
  hasError: boolean;
  body: {
    data: any;
    status: number;
    message?: string;
  };
}> => {
  try {
    const {
      page,
      search,
      queryType,
    } = options;

    const response = await fetch(`${url}?type_id=${queryType.value}&search=${search}&page=${page}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      const {
        message,
      } = await response.json();
      return {
        hasError: true,
        body    : {
          message,
          data  : {
            options   : [],
            additional: {},
            hasMore   : false,
          },
          status: response.status,
        },
      }
    } else {
      const {
        data,
      } = await response.json();
      return {
        hasError: false,
        body    : {
          data  : {
            options   : data.items.map((item: any) => ({
              value: item.id,
              label: item.text
            })),
            hasMore   : data.has_more,
            additional: {
              queryType,
              page: page + 1,
            },
          },
          status: response.status || 200,
        },
      }
    }
  } catch (e) {
    console.error(e);
    return {
      hasError: true,
      body    : {
        status : e.status,
        message: e.message,
        data   : {
          options   : [],
          additional: {},
          hasMore   : false,
        },
      },
    }
  }
};
