import {Dispatch} from 'react';
import {_dataOptions} from './index';
import {SetStateAction} from 'react';
import {Globals} from '../../../types/globals';

export const _downloadWorkerJS = (
  uuid: string,
  setState?: Dispatch<SetStateAction<{
    copied: boolean;
    downloaded: boolean;
  }>>,
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    isDownloading: true,
  }));

  const {
    body: {
      data,
    },
    hasError,
  } = await api.get(`/webnotify/${uuid}/docs/worker`);

  if (!hasError) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'smart-worker.js');
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);

    if (setState) {
      setState(prevState => ({
        ...prevState,
        downloaded: true,
      }))
    }
  }

  dispatch(_dataOptions({
    isDownloading: false,
  }));
  setTimeout(function () {
    dispatch(_dataOptions({
      isDownloading: void 0,
    }));
  }, 1500);
};