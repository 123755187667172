import {DEFAULT_PAGE_SIZES} from '../../../../../constants';
import {ActionTypes} from '../../../../constants/templates';

const InitialState: any = {
  pageNumber: 1,
  columns   : [],
  pageSize  : 10,
  pageSizes : DEFAULT_PAGE_SIZES,
  sortOrder : 'desc',
  sortName  : 'templates.created_at',
};

export default (state: any = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_TEMPLATES_TABLE_OPTIONS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};
