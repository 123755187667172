import React from 'react';
import {useHistory} from 'react-router';
import {ButtonProps} from 'antd/lib/button/button';
import {Segments} from '../../../../../../types/segments';
import EditOutlined from '@ant-design/icons/EditOutlined';
import {urlHelper} from '../../../../../../utils/urlHelper';
import {aclKeys} from '../../../../../../constants/aclKeys';
import {AppRoutes} from '../../../../../../constants/appRoutes';
import {useSmartPush} from '../../../../../../hooks/useSmartPush';
import {TableAction} from '../../../../../../components/antd/TableAction';

export function EditAction(
  {
    row,
  }: {
    row: Segments.Entity;
  }
) {
  const {
    appUuid,
    user: {
      canUpdate,
    },
  } = useSmartPush();
  const {push} = useHistory();

  const buttonProps: ButtonProps = {
    size    : 'small',
    shape   : 'circle',
    type    : 'default',
    icon    : <EditOutlined/>,
    disabled: !canUpdate(aclKeys.SEGMENTS) || (row.type !== 0),
    onClick : () => push(urlHelper(AppRoutes.SEGMENTS_EDIT, {appUuid, id: row.id})),
  };
  const tooltipTitle = canUpdate(aclKeys.SEGMENTS) ? 'Edit' : 'You don\'t have access to manage this action';

  return (
    <TableAction
      buttonProps={buttonProps}
      tooltipProps={{title: tooltipTitle}}
    />
  )
}