import React from 'react';
import Dialog from '../index';

export default function DeleteDialog(
  {
    open,
    loading,
    onClose,
    disabled,
    bodyTitle,
    agreeLabel,
    agreeAction,
    dismissLabel,
    dismissAction,
    bodyDescription,
  }: {
    onClose?: any;
    open: boolean;
    agreeAction: any;
    loading?: boolean;
    disabled?: boolean;
    dismissAction: any;
    agreeLabel: string;
    bodyTitle?: string;
    dismissLabel: string;
    bodyDescription?: React.ReactNode;
  }
) {
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth='sm'
      color='danger'
      onClose={onClose}
      disableBackdropClick
      actions={{
        align  : 'center',
        actions: [
          {
            color  : 'blue',
            variant: 'outlined',
            label  : dismissLabel,
            onClick: dismissAction,
          },
          {
            loading,
            color   : 'danger',
            label   : agreeLabel,
            variant : 'contained',
            onClick : agreeAction,
            disabled: loading || disabled,
          },
        ],
      }}
      dialogTitle={{
        onClose: onClose,
        color  : 'danger',
      }}
      content={{
        bodyTitle,
        color: 'danger',
        bodyDescription,
      }}
    />
  )
}
