import React from 'react';
import Box from '@material-ui/core/Box';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import {Control} from 'react-hook-form/dist/types/form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {IOSSwitch} from '../../../../../../../../components/IOSSwitch';
import {ErrorMessage} from '../../../../../../../../components/ErrorMessage';
import {TextField} from '../../../../../../../../components/BorderedTextField';

const useStyles = makeStyles((theme: Theme) => ({
  formControlLabel: {
    marginLeft  : 0,
    marginRight : 0,
    marginBottom: 0,
    transition  : theme.transitions.create(['all']),
  },
}));

export function Webhooks(
  {
    control,
    disabled,
  }: {
    control: Control;
    disabled?: boolean;
  }
): JSX.Element {
  const classes = useStyles();
  const {t} = useTranslation();
  const webhooks = useWatch({
    control,
    name: 'webhooks',
  });

  return (
    <>
      <Controller
        name='webhooks'
        control={control}
        render={(
          {
            field     : {
              ref,
              value,
              ...fieldRest
            },
            fieldState: {
              error: localError
            }
          }
        ) => {
          return (
            <Box>
              <FormControlLabel
                value={value}
                inputRef={ref}
                checked={!!value}
                disabled={disabled}
                labelPlacement='end'
                classes={{root: classes.formControlLabel}}
                {...fieldRest}
                label={
                  <Typography
                    variant='body1'
                    color='inherit'
                    component='span'
                  >{t('app_settings.webhooks')}
                  </Typography>
                }
                control={
                  <IOSSwitch
                    size='small'
                  />
                }
              />
              <ErrorMessage
                message={localError?.message}
              />
            </Box>
          )
        }}
      />
      <Collapse
        in={webhooks}
      >
        <div>
          <Controller
            name='wh_display'
            control={control}
            render={(
              {
                field     : {
                  ref,
                  value,
                  ...fieldRest
                },
                fieldState: {
                  error: localError
                }
              }
            ) => {
              return (
                <TextField
                  fullWidth
                  margin='none'
                  inputRef={ref}
                  autoComplete='off'
                  disabled={disabled}
                  value={value || ''}
                  error={!!localError?.message}
                  helperText={localError?.message}
                  label={t('app_settings.callback_url_on_display')}
                  placeholder={t('app_settings.display_placeholder')}
                  {...fieldRest}
                />
              );
            }}
          />
          <Controller
            name='wh_click'
            control={control}
            render={(
              {
                field     : {
                  ref,
                  value,
                  ...fieldRest
                },
                fieldState: {
                  error: localError
                }
              }
            ) => {
              return (
                <TextField
                  fullWidth
                  margin='none'
                  inputRef={ref}
                  autoComplete='off'
                  value={value || ''}
                  disabled={disabled}
                  error={!!localError?.message}
                  helperText={localError?.message}
                  label={t('app_settings.callback_url_on_click')}
                  placeholder={t('app_settings.click_placeholder')}
                  {...fieldRest}
                />
              );
            }}
          />
          <Controller
            name='wh_close'
            control={control}
            render={(
              {
                field     : {
                  ref,
                  value,
                  ...fieldRest
                },
                fieldState: {
                  error: localError
                }
              }
            ) => {
              return (
                <TextField
                  fullWidth
                  margin='none'
                  inputRef={ref}
                  autoComplete='off'
                  disabled={disabled}
                  value={value || ''}
                  error={!!localError?.message}
                  helperText={localError?.message}
                  label={t('app_settings.callback_url_on_close')}
                  placeholder={t('app_settings.close_placeholder')}
                  {...fieldRest}
                />
              );
            }}
          />
          <Controller
            control={control}
            name='wh_subscribe'
            render={(
              {
                field     : {
                  ref,
                  value,
                  ...fieldRest
                },
                fieldState: {
                  error: localError
                }
              }
            ) => {
              return (
                <TextField
                  fullWidth
                  margin='none'
                  inputRef={ref}
                  autoComplete='off'
                  value={value || ''}
                  disabled={disabled}
                  error={!!localError?.message}
                  helperText={localError?.message}
                  label={t('app_settings.callback_url_on_subscribe')}
                  placeholder={t('app_settings.subscribe_placeholder')}
                  {...fieldRest}
                />
              );
            }}
          />
        </div>
      </Collapse>
    </>
  )
}