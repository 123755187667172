import React from 'react';
import {Modal} from 'antd';
import {useEdit} from './useEdit';
import Box from '@material-ui/core/Box';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {UseFormRegister} from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import {Control} from 'react-hook-form/dist/types/form';
import states from '../../../../../../../../constants/states.json';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {Select} from '../../../../../../../../components/AsyncPaginate';
import {ErrorMessage} from '../../../../../../../../components/ErrorMessage';
import {TextField} from '../../../../../../../../components/BorderedTextField';

export function Edit(
  {
    open,
    onClose,
    defaultValues,
  }: {
    open: boolean;
    onClose: () => void;
    defaultValues: Record<string, any>;
  }
) {
  const {
    submit,
    control,
    setValue,
    register,
    countries,
    isSubmitting,
  } = useEdit({defaultValues});

  return (
    <form
      onSubmit={submit}
      id='change-billing-info-form'
    >
      <Modal
        okText='Save'
        visible={open}
        onCancel={onClose}
        cancelText='Close'
        maskClosable={false}
        title='Change Billing Info'
        okButtonProps={{
          htmlType: 'submit',
          loading : isSubmitting,
        }}
        getContainer='#change-billing-info-form'
      >
        <EditForm
          control={control}
          setValue={setValue}
          register={register}
          countries={countries}
          defaultValues={defaultValues}
        />
      </Modal>
    </form>
  )
}

function EditForm(
  {
    control,
    register,
    setValue,
    countries,
    defaultValues,
  }: {
    control: Control;
    countries: Record<string, any>;
    defaultValues: Record<string, any>;
    setValue: UseFormSetValue<Record<string, any>>;
    register: UseFormRegister<Record<string, any>>;
  }
) {
  return (
    <div>
      <Typography
        variant='h6'
        component='p'
        color='primary'
      ><strong>Billing Address</strong>
      </Typography>
      <input
        type='hidden'
        {...register('id')}
      />
      <Box
        display='flex'
        flexDirection='column'
      >
        <Controller
          name='country'
          control={control}
          rules={{
            validate: (value) => {
              if (!value) {
                return 'Country field is required';
              }
              return true;
            }
          }}
          render={(
            {
              field     : {
                ref,
                value,
                ...fieldRest
              },
              fieldState: {
                error,
              }
            }
          ) => {
            return (
              <>
                <Select
                  required
                  value={value}
                  {...fieldRest}
                  label='Country'
                  placeholder='Country'
                  boxProps={{marginBottom: 0}}
                  options={countries.map((country: Record<string, any>) => ({
                    value: country.iso,
                    label: country.text,
                  }))}
                />
                <ErrorMessage
                  message={error?.message}
                />
              </>
            )
          }}
        />
        <Controller
          name='line1'
          rules={{
            validate: (value) => {
              if (!value?.trim()) {
                return 'Address field is required';
              }
              return true;
            }
          }}
          control={control}
          render={(
            {
              field     : {
                ref,
                value,
                ...fieldRest
              },
              fieldState: {
                error,
              }
            }
          ) => {
            return (
              <TextField
                required
                fullWidth
                inputRef={ref}
                label='Address'
                autoComplete='off'
                value={value || ''}
                error={!!error?.message}
                helperText={error?.message}
                placeholder='Street address'
                inputProps={{required: false}}
                {...fieldRest}
              />
            )
          }}
        />
        <Controller
          name='line2'
          control={control}
          render={(
            {
              field     : {
                ref,
                value,
                ...fieldRest
              },
              fieldState: {
                error,
              }
            }
          ) => {
            return (
              <TextField
                required
                fullWidth
                inputRef={ref}
                autoComplete='off'
                value={value || ''}
                error={!!error?.message}
                helperText={error?.message}
                inputProps={{required: false}}
                placeholder='Apt, Unit, Suite, etc. (optional)'
                {...fieldRest}
              />
            )
          }}
        />
        <Box
          display='flex'
        >
          <Box
            width='33.33333%'
          >
            <Controller
              name='city'
              control={control}
              rules={{
                validate: (value) => {
                  if (!value?.trim()) {
                    return 'City field is required';
                  }
                  return true;
                }
              }}
              render={(
                {
                  field     : {
                    ref,
                    value,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                  }
                }
              ) => {
                return (
                  <TextField
                    required
                    fullWidth
                    label='City'
                    inputRef={ref}
                    autoComplete='off'
                    value={value || ''}
                    error={!!error?.message}
                    helperText={error?.message}
                    inputProps={{required: false}}
                    placeholder='City, district, suburb, town, or village.'
                    {...fieldRest}
                  />
                )
              }}
            />
          </Box>
          <Box
            marginLeft={2}
            marginRight={2}
            width='33.33333%'
          >
            <StateRegion
              control={control}
              setValue={setValue}
              defaultValues={defaultValues}
            />
          </Box>
          <Box
            width='33.33333%'
          >
            <Controller
              name='postal_code'
              control={control}
              rules={{
                validate: (value) => {
                  if (!value?.trim()) {
                    return 'Postal code field is required';
                  }
                  return true;
                }
              }}
              render={(
                {
                  field     : {
                    ref,
                    value,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                  }
                }
              ) => {
                return (
                  <TextField
                    required
                    fullWidth
                    inputRef={ref}
                    autoComplete='off'
                    label='Postal Code'
                    value={value || ''}
                    error={!!error?.message}
                    helperText={error?.message}
                    inputProps={{required: false}}
                    placeholder='ZIP or postal code.'
                    {...fieldRest}
                  />
                )
              }}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

function StateRegion(
  {
    control,
    setValue,
    defaultValues,
  }: {
    defaultValues: Record<string, any>;
    control: Control<Record<string, any>>;
    setValue: UseFormSetValue<Record<string, any>>;
  }
) {
  const country = useWatch<Record<string, any>>({
    control,
    name        : 'country',
    defaultValue: defaultValues.country
  });

  React.useEffect(() => {
    if (country.value !== defaultValues.country.value) {
      setValue('state', '');
    }
  }, [
    setValue,
    country.value,
    defaultValues.country.value
  ]);

  return (
    <Controller
      name='state'
      rules={{
        validate: (value) => {
          if (country?.value === 'us') {
            if (!value) {
              return 'State field is required';
            }
          } else {
            if (!value?.trim()) {
              return 'State field is required';
            }
          }
          return true;
        }
      }}
      control={control}
      render={(
        {
          field     : {
            ref,
            value,
            ...fieldRest
          },
          fieldState: {
            error,
          }
        }
      ) => {
        return (country?.value === 'us') ? (
          <>
            <Select
              required
              isClearable
              value={value}
              {...fieldRest}
              label='State'
              options={states}
              menuPlacement='top'
              boxProps={{marginTop: 0}}
              hasError={error?.message}
              placeholder='Please select'
              secondaryBoxProps={{marginTop: 1}}
            />
            <ErrorMessage
              message={error?.message}
              style={{marginTop: 4, lineHeight: '19.92px'}}
            />
          </>
        ) : (
          <TextField
            required
            fullWidth
            label='State'
            inputRef={ref}
            autoComplete='off'
            value={value || ''}
            error={!!error?.message}
            helperText={error?.message}
            inputProps={{required: false}}
            {...fieldRest}
          />
        )
      }}
    />
  )
}
