import React from 'react';
import {templateList} from './List';

export function Template(
  {
    icon,
    deny,
    style,
    allow,
    cdnUrl,
    message,
    bgColor,
    position,
    backdrop,
    direction,
    isPreview,
    templateId,
    messageColor,
  }: {
    deny: {
      button: string;
      bgColor: string;
      textColor: string;
    };
    allow: {
      button: string;
      bgColor: string;
      textColor: string;
    };
    icon: string;
    cdnUrl: string;
    message: string;
    bgColor: string;
    position: string;
    direction: string;
    templateId: number;
    backdrop?: boolean;
    isPreview?: boolean;
    messageColor: string;
    style?: React.CSSProperties;
  }
) {
  const template = templateList.find((i: any) => i.id === templateId);
  if (!template) {
    return null;
  }
  const {
    component: Component,
  } = template;

  return (
    <Component
      icon={icon}
      deny={deny}
      style={style}
      allow={allow}
      cdnUrl={cdnUrl}
      bgColor={bgColor}
      message={message}
      position={position}
      backdrop={backdrop}
      direction={direction}
      isPreview={isPreview}
      messageColor={messageColor}
    />
  )
}
