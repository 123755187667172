import React from 'react';
import {useDispatch} from 'react-redux';
import {Modal, Button, Row } from 'antd';
import {Users} from '../../../types/users';
import {archiveUser} from '../../../store/actions/users/services';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  rowData: Users.Entity;
}

const Dialogs: React.FC<IProps> = ({visible, onCancel, rowData}) => {
  const dispatch = useDispatch();

  const handleArchive = () => {
    dispatch(archiveUser(rowData.uuid));
    onCancel();
  };

  return (
    <Modal
      title='Archive User'
      visible={visible}
      onCancel={onCancel}
      bodyStyle={{textAlign: 'center'}}
      footer={(
        <Row justify='center' >
          <Button
            children='Keep User'
            onClick={onCancel}
            type='primary'
            ghost
          />
          <Button
            danger
            type='primary'
            children='Archive User'
            onClick={handleArchive}
          />
        </Row>
      )}
    >
      <h2>Are you sure you want to archive</h2>
      <h2>{rowData.email}?</h2>
    </Modal>
  );
};

export default Dialogs;
