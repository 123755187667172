import React from 'react';
import Dialogs from '../../../Dialogs';
import {useSelector} from 'react-redux';
import ActionFormatter from '../Formatter';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {DataTypeProviderProps} from '@devexpress/dx-react-grid';
import {getActionDialog} from '../../../../../../../store/selectors/campaigns';

export default function ActionProvider(
  {
    actions,
    ...restProps
  }: DataTypeProviderProps & { actions: any }
) {
  const dialog = useSelector(getActionDialog);

  return (
    <>
      {dialog && (
        <Dialogs/>
      )}
      <DataTypeProvider
        formatterComponent={ActionFormatter}
        {...restProps}
      />
    </>
  )
}
