import React from 'react';
import {Modal} from 'antd';
import {useCreate} from './useCreate';
import Box from '@material-ui/core/Box';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {CardField} from '../Components/CardField';
import {UseFormClearErrors} from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import {Control} from 'react-hook-form/dist/types/form';
import {UseFormSetError} from 'react-hook-form/dist/types/form';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import states from '../../../../../../../../constants/states.json';
import {Select} from '../../../../../../../../components/AsyncPaginate';
import {ErrorMessage} from '../../../../../../../../components/ErrorMessage';
import {TextField} from '../../../../../../../../components/BorderedTextField';

export function Create(
  {
    open,
    onClose,
  }: {
    open: boolean;
    onClose: () => void;
  }
) {
  const {
    submit,
    control,
    setValue,
    setError,
    countries,
    clearErrors,
    isSubmitting,
  } = useCreate({onClose})

  return (
    <form
      onSubmit={submit}
      id='change-payment-and-billing-info'
    >
      <Modal
        okText='Save'
        visible={open}
        cancelText='Close'
        onCancel={onClose}
        maskClosable={false}
        okButtonProps={{
          htmlType: 'submit',
          loading : isSubmitting,
        }}
        title='Change Payment and Billing Info'
        getContainer='#change-payment-and-billing-info'
      >
        <CreateForm
          control={control}
          setValue={setValue}
          setError={setError}
          countries={countries}
          clearErrors={clearErrors}
        />
      </Modal>
    </form>
  )
}

function CreateForm(
  {
    control,
    setError,
    setValue,
    countries,
    clearErrors,
  }: {
    control: Control;
    countries: Record<string, any>;
    setValue: UseFormSetValue<Record<string, any>>;
    setError: UseFormSetError<Record<string, any>>;
    clearErrors: UseFormClearErrors<Record<string, any>>;
  }
) {
  return (
    <Box>
      <Box>
        <Typography
          variant='h6'
          component='p'
          color='primary'
        ><strong>Payment Method</strong>
        </Typography>
        <Controller
          name='name'
          control={control}
          rules={{
            validate: (value) => {
              if (!value?.trim()) {
                return 'Name field is required';
              }
              return true;
            }
          }}
          render={(
            {
              field     : {
                ref,
                value,
                ...fieldRest
              },
              fieldState: {
                error,
              }
            }
          ) => {
            return (
              <TextField
                required
                fullWidth
                inputRef={ref}
                margin='normal'
                autoComplete='off'
                value={value || ''}
                error={!!error?.message}
                helperText={error?.message}
                placeholder='Cardholder Name*'
                inputProps={{required: false}}
                {...fieldRest}
              />
            )
          }}
        />
        <Controller
          name='cardField'
          control={control}
          rules={{
            validate: (value) => {
              if (!value?.complete) {
                return 'Card field is required';
              }
              return true;
            }
          }}
          render={(
            {
              field     : {
                onChange,
              },
              fieldState: {
                error,
              }
            }
          ) => {
            return (
              <>
                <CardField
                  hasError={!!error?.message}
                  onChange={(e) => {
                    onChange(e);
                    if (e.error?.message) {
                      setError('cardField', {
                        message: e.error?.message
                      })
                    } else {
                      clearErrors('cardField');
                    }
                  }}
                />
                <ErrorMessage
                  message={error?.message}
                />
              </>
            )
          }}
        />
        <Box
          marginTop={1}
        >
          <Typography
            variant='caption'
            color='textSecondary'
          >* Your credit card issuer may charge foreign transaction or cross-border fees in addition to the total price.
          </Typography>
        </Box>
      </Box>
      <Box
        marginTop={3}
      >
        <Typography
          variant='h6'
          component='p'
          color='primary'
        ><strong>Billing Address</strong>
        </Typography>
        <Controller
          name='country'
          control={control}
          rules={{
            validate: (value) => {
              if (!value) {
                return 'Country field is required';
              }
              return true;
            }
          }}
          render={(
            {
              field     : {
                ref,
                value,
                ...fieldRest
              },
              fieldState: {
                error,
              }
            }
          ) => {
            return (
              <>
                <Select
                  required
                  value={value}
                  {...fieldRest}
                  label='Country'
                  placeholder='Country'
                  hasError={!!error?.message}
                  boxProps={{marginBottom: 0}}
                  options={countries.map((country: Record<string, any>) => ({
                    value: country.iso,
                    label: country.text,
                  }))}
                />
                <ErrorMessage
                  message={error?.message}
                  style={{marginTop: 4, lineHeight: '19.92px'}}
                />
              </>
            )
          }}
        />
        <Controller
          name='line1'
          control={control}
          rules={{
            validate: (value) => {
              if (!value?.trim()) {
                return 'Address field is required';
              }
              return true;
            }
          }}
          render={(
            {
              field     : {
                ref,
                value,
                ...fieldRest
              },
              fieldState: {
                error,
              }
            }
          ) => {
            return (
              <TextField
                required
                fullWidth
                inputRef={ref}
                label='Address'
                autoComplete='off'
                value={value || ''}
                error={!!error?.message}
                helperText={error?.message}
                placeholder='Street address'
                inputProps={{required: false}}
                {...fieldRest}
              />
            )
          }}
        />
        <Controller
          name='line2'
          control={control}
          render={(
            {
              field     : {
                ref,
                value,
                ...fieldRest
              },
              fieldState: {
                error,
              }
            }
          ) => {
            return (
              <TextField
                required
                fullWidth
                inputRef={ref}
                autoComplete='off'
                value={value || ''}
                error={!!error?.message}
                helperText={error?.message}
                inputProps={{required: false}}
                placeholder='Apt, Unit, Suite, etc. (optional)'
                {...fieldRest}
              />
            )
          }}
        />
        <Box
          display='flex'
        >
          <Box
            width='33.33333%'
          >
            <Controller
              name='city'
              control={control}
              rules={{
                validate: (value) => {
                  if (!value?.trim()) {
                    return 'City field is required';
                  }
                  return true;
                }
              }}
              render={(
                {
                  field     : {
                    ref,
                    value,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                  }
                }
              ) => {
                return (
                  <TextField
                    required
                    fullWidth
                    label='City'
                    inputRef={ref}
                    autoComplete='off'
                    value={value || ''}
                    error={!!error?.message}
                    helperText={error?.message}
                    inputProps={{required: false}}
                    {...fieldRest}
                  />
                )
              }}
            />
          </Box>
          <Box
            marginLeft={2}
            marginRight={2}
            width='33.33333%'
          >
            <StateRegion
              control={control}
              setValue={setValue}
            />
          </Box>
          <Box
            width='33.33333%'
          >
            <Controller
              control={control}
              name='postal_code'
              rules={{
                validate: (value) => {
                  if (!value?.trim()) {
                    return 'ZIP Code field is required';
                  }
                  return true;
                }
              }}
              render={(
                {
                  field     : {
                    ref,
                    value,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                  }
                }
              ) => {
                return (
                  <TextField
                    required
                    fullWidth
                    inputRef={ref}
                    label='ZIP Code'
                    autoComplete='off'
                    value={value || ''}
                    error={!!error?.message}
                    helperText={error?.message}
                    inputProps={{required: false}}
                    {...fieldRest}
                  />
                )
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function StateRegion(
  {
    control,
    setValue,
  }: {
    control: Control<Record<string, any>>;
    setValue: UseFormSetValue<Record<string, any>>;
  }
) {
  const country = useWatch({
    control,
    name: 'country',
  });

  React.useEffect(() => {
    if (country) {
      setValue('state', '');
    }
  }, [
    country,
    setValue
  ])

  return (
    <Controller
      name='state'
      control={control}
      rules={{
        validate: (value) => {
          if (country?.value === 'us') {
            if (!value) {
              return 'State field is required';
            }
          } else {
            if (!value?.trim()) {
              return 'State field is required';
            }
          }
          return true;
        }
      }}
      render={(
        {
          field     : {
            ref,
            value,
            ...fieldRest
          },
          fieldState: {
            error,
          }
        }
      ) => {
        return (country?.value === 'us') ? (
          <>
            <Select
              required
              isClearable
              value={value}
              {...fieldRest}
              label='State'
              options={states}
              menuPlacement='top'
              boxProps={{marginTop: 0}}
              hasError={error?.message}
              placeholder='Please select'
              secondaryBoxProps={{marginTop: 1}}
            />
            <ErrorMessage
              message={error?.message}
              style={{marginTop: 4, lineHeight: '19.92px'}}
            />
          </>
        ) : (
          <TextField
            required
            fullWidth
            label='State'
            inputRef={ref}
            autoComplete='off'
            value={value || ''}
            error={!!error?.message}
            helperText={error?.message}
            inputProps={{required: false}}
            {...fieldRest}
          />
        )
      }}
    />
  )
}
