import React from 'react';
import {Ctr} from './Ctr';
import {Card} from 'antd';
import {Total} from './Total';
import {Axis} from 'highcharts';
import {Clicks} from './Clicks';
import {Closes} from './Closes';
import {Chart} from 'highcharts';
import {useImmer} from 'use-immer';
import {Displays} from './Displays';
import {useSelector} from 'react-redux';
import * as Highcharts from 'highcharts';
import {Unsubscribed} from './Unsubscribed';
import {DailyInactive} from './DailyInactive';
import {AxisSetExtremesEventObject} from 'highcharts';
import {PeriodUnsubscribed} from './PeriodUnsubscribed';
import {Text} from '../../../../../../components/antd/Typography';
import {_selectedCharts} from '../../../../../../store/selectors/reports';

const eventTypes = [
  'mousemove',
  'touchmove',
  'touchstart',
];

const chartMapping: any[] = [
  {
    component: PeriodUnsubscribed,
    value    : 'period_unsubscribed_and_subscribed',
  },
  {
    component: DailyInactive,
    value    : 'dailyInactive',
  },
  {
    component: Total,
    value    : 'total',
  },
  {
    component: Unsubscribed,
    value    : 'unsubscribed',
  },
  {
    component: Clicks,
    value    : 'clicks',
  },
  {
    component: Closes,
    value    : 'closes',
  },
  {
    component: Displays,
    value    : 'displays',
  },
  {
    component: Ctr,
    value    : 'ctr',
  },
]

export function Charts() {
  const [charts, setCharts] = useImmer([]);
  const selectedCharts = useSelector(_selectedCharts);

  const callback = React.useCallback((chart: Chart) => {
    setCharts((draft) => {
      if (chart) {
        draft.push(chart);
      }
    });
  }, [
    setCharts,
  ]);

  React.useEffect(() => {
    const newCharts = charts.filter((item) => Object.keys(item || {}).length);

    function eventCallback(e: any) {
      let chart: any;
      let point: any;
      let event: any;

      for (let i = 0; i < newCharts.length; i++) {
        chart = newCharts[i];
        if (chart.pointer) {
          event = chart.pointer.normalize(e);
          point = chart.series[0].searchPoint(event, true);
          if (point) {
            point.highlight(e);
          }
        }
      }
    }

    const container = document.getElementById('chartsContainer');

    if (container) {
      eventTypes.forEach((event: string) => {
        container.removeEventListener(event, eventCallback);
        container.addEventListener(event, eventCallback);
      });
    }

    return function () {
      if (container) {
        eventTypes.forEach((event: string) => {
          container.removeEventListener(event, eventCallback);
        });
      }
    }
  }, [
    charts,
  ]);

  return (
    <div
      id='chartsContainer'
    >
      {!!selectedCharts.length ? (
        chartMapping.map((
          {
            value,
            component: Component,
          }
        ) => {
          const index = selectedCharts.findIndex((sC) => sC.value === value)
          if (index !== -1) {
            return (
              <Component
                key={value}
                height={300}
                callback={callback}
              />
            )
          } else {
            return null
          }
        })
      ) : (
        <Card
          className='m-t-2'
        >
          <Text
            align='center'
            fontSize='large'
            fontWeight='medium'
          >No metrics selected to show on the Dashboard.
          </Text>
          <Text
            align='center'
            fontSize='large'
            fontWeight='medium'
          >Either select a metric from above or turn on the table view by clicking on the Show Table toggle.
          </Text>
        </Card>
      )}
    </div>
  )
}

export const syncExtremes = (_this: Axis, e: AxisSetExtremesEventObject) => {
  const thisChart = _this.chart;
  if (e.trigger !== 'syncExtremes') {
    Highcharts.each(Highcharts.charts, (chart: any) => {
      if (chart !== thisChart) {
        if (chart.xAxis[0].setExtremes) {
          chart.xAxis[0].setExtremes(
            e.min,
            e.max,
            undefined,
            false,
            {
              trigger: 'syncExtremes',
            },
          );
        }
      }
    });
  }
};

(Highcharts.Point.prototype as any).highlight = function (event: any) {
  event = this.series.chart.pointer.normalize(event);
  this.onMouseOver();
  this.series.chart.tooltip.refresh(this);
  this.series.chart.xAxis[0].drawCrosshair(event, this);
};

Highcharts.Pointer.prototype.reset = function () {
  return undefined;
};