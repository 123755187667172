import React from 'react';
import {Route} from 'react-router';
import {Switch} from 'react-router';
import * as Auth from './pages/Auth';
import {aclKeys} from './constants/aclKeys';
import {SmartPushProvider} from './context';
import * as Pages from './pages/Application';
import {Guest} from './components/antd/Guest';
import {BrowserRouter} from 'react-router-dom';
import {AppRoutes} from './constants/appRoutes';
import {OAuthGoogle} from './pages/OAuthGoogle';
import {Private} from './components/antd/Private';
import {PrivateRoutes} from './constants/appRoutes';
import {surveyIsEnabled} from './utils/surveyIsEnabled';
import {checkPermissions} from './utils/checkPermissions';
import {AccessDenied} from './components/antd/AccessDenied';
import {EmptyComponent} from './components/antd/EmptyComponent';
import './App.less';

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          component={OAuthGoogle}
          path={AppRoutes.OAUTH_GOOGLE}
        />
        <Guest
          exact
          component={Auth.Login}
          path={AppRoutes.LOGIN}
        />
        <Guest
          exact
          component={Auth.AppSumoRegister}
          path={AppRoutes.APP_SUMO_REGISTER}
        />
        <Guest
          exact
          component={Auth.Register}
          path={AppRoutes.REGISTER}
        />
        <Guest
          exact
          component={Auth.Register}
          path={AppRoutes.REGISTER_PLAN}
        />
        <Guest
          exact
          component={Auth.ForgotPassword}
          path={AppRoutes.PASSWORD_FORGOT}
        />
        <Guest
          exact
          component={Auth.ResetPassword}
          path={AppRoutes.PASSWORD_RESET}
        />
        <Route
          exact
          component={Auth.ConfirmRegistration}
          path={AppRoutes.CONFIRM_REGISTRATION}
        />
        <Route
          exact
          path={AppRoutes.EMAIL_CHANGE}
          component={Auth.ConfirmEmailChange}
        />
        <SmartPushProvider>
          {({user}) => (
            <Switch>
              <Private
                exact
                path='/'
                component={EmptyComponent}
              />
              {PrivateRoutes().map((
                {
                  path,
                  exact,
                  aclKey,
                  Component,
                },
                index
              ) => {
                const {
                  visible,
                } = checkPermissions({
                  user,
                  aclKey,
                });

                return (
                  <Private
                    key={index}
                    path={path}
                    exact={exact}
                    visible={visible}
                    component={Component}
                  />
                )
              })}

              {/** Automation **/}
              <Private
                component={Pages.Automation}
                path={AppRoutes.AUTOMATIONS}
                visible={checkPermissions({user, aclKey: aclKeys.AUTOMATIONS}).visible}
              />
              {/** Automation **/}

              {/** Analytics **/}
              <Private
                exact
                component={Pages.Reports}
                path={AppRoutes.ANALYTICS_REPORTS}
                visible={checkPermissions({user, aclKey: aclKeys.DASHBOARD}).visible}
              />
              <Private
                component={Pages.Subscribers}
                path={AppRoutes.ANALYTICS_SUBSCRIBERS}
                visible={checkPermissions({user, aclKey: aclKeys.SUBSCRIBERS}).visible}
              />
              <Private
                exact
                component={Pages.SubscriberInfo}
                path={AppRoutes.ANALYTICS_SUBSCRIBERS_INFO}
                visible={checkPermissions({user, aclKey: aclKeys.SUBSCRIBERS}).visible}
              />
              {/** Analytics **/}

              {/** Apps **/}
              {user.isAdmin()&&(
                <Private
                  exact
                  path={AppRoutes.APPS}
                  visible={user.isAdmin()}
                  component={Pages.AppsList}
                />
              )}
              {surveyIsEnabled() && (
                <Private
                  exact
                  path={[
                    AppRoutes.SURVEY,
                    AppRoutes.SURVEY_APP,
                  ]}
                  visible={true}
                  component={Pages.Survey}
                />
              )}
              <Private
                path={[
                  AppRoutes.APPS_CREATE,
                  AppRoutes.APPS_EDIT_APP,
                  AppRoutes.APPS_CREATE_APP,
                ]}
                component={Pages.AppCreate}
                visible={checkPermissions({user, aclKey: aclKeys.APPS}).visible}
              />
              {/** Apps **/}

              {/** Manage Account **/}
              <Private
                path={[
                  AppRoutes.MANAGE_ACCOUNT,
                  AppRoutes.MANAGE_ACCOUNT_APP,
                ]}
                visible={true}
                component={Pages.ManageAccount}
              />
              {/** Manage Account **/}

              {/** Campaigns **/}
              <Private
                component={Pages.PushNotificationRoutes}
                path={AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS}
                visible={checkPermissions({user, aclKey: aclKeys.CAMPAIGNS}).visible}
              />
              {/** Campaigns **/}

              {/** Segments **/}
              <Private
                path={AppRoutes.SEGMENTS}
                component={Pages.SegmentRoutes}
                visible={checkPermissions({user, aclKey: aclKeys.SEGMENTS}).visible}
              />
              {/** Segments **/}

              {/** Settings **/}
              <Private
                path={AppRoutes.SETTINGS}
                component={Pages.Settings}
                visible={checkPermissions({user, aclKey: aclKeys.APP_SETTINGS}).visible}
              />
              {/** Settings **/}

              {/** Others **/}
              <Route
                exact
                component={AccessDenied}
                path={AppRoutes.ACCESS_DENIED}
              />
              <Route
                path='/*'
              >{() => window.location.replace('/')}
              </Route>
              {/** Others **/}
            </Switch>
          )}
        </SmartPushProvider>
      </Switch>
    </BrowserRouter>
  );
}
