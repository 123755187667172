if (!localStorage.getItem('swv')) {
  localStorage.setItem('swv', '1');
}

function askPermission() {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  });
}

export async function register(title: string, options?: NotificationOptions) {
  if (!('serviceWorker' in navigator)) {
    console.error('Service Worker isn\'t supported on this browser.')
    return;
  }

  if (!('PushManager' in window)) {
    console.error('Push isn\'t supported on this browser.')
    return;
  }

  try {
    const clientURL = `${window.location.protocol}//${window.location.host}/`;
    let serviceWorkerRegistration = await navigator.serviceWorker.getRegistration(clientURL);
    if (!serviceWorkerRegistration) {
      await navigator.serviceWorker.register('/sw.js?v=1');
      serviceWorkerRegistration = await navigator.serviceWorker.ready;
    } else {
      if (localStorage.getItem('swv') === '1') {
        await serviceWorkerRegistration.unregister();

        await navigator.serviceWorker.register('/sw.js?v=1.1');
        serviceWorkerRegistration = await navigator.serviceWorker.ready;
        localStorage.setItem('swv', '1.1');
      }
    }
    const permissionResult = await askPermission();

    if (permissionResult === 'granted') {
      await serviceWorkerRegistration.showNotification(title, options)
    }
  } catch (e) {
    console.error(e);
  }
}