import React from 'react';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import {Button} from '../../../../../../../components/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {_dataOptions} from '../../../../../../../store/actions/apps';
import {_currentApp} from '../../../../../../../store/selectors/apps';
import {_destroy} from '../../../../../../../store/actions/apps/destroy';
import DeleteDialog from '../../../../../../../components/Dialog/Delete';
import {_dataOptions as _appDataOptions} from '../../../../../../../store/selectors/apps';

export function DeleteApp() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const currentApp = useSelector(_currentApp);
  const [confirmed, setConfirmed] = React.useState<boolean>(false);
  const {
    isDeleting,
    dialogIsOpen,
  } = useSelector(_appDataOptions);

  const destroyApp = React.useCallback(() => {
    dispatch(_destroy(currentApp?.uuid))
  }, [
    dispatch,
    currentApp?.uuid,
  ]);

  const handleCheckboxChange = React.useCallback((e, checked) => {
    setConfirmed(checked);
  }, []);

  const dataOptions = React.useCallback((options: { [key: string]: string | boolean }) => {
    dispatch(_dataOptions(options))
  }, [
    dispatch,
  ]);

  return (
    <>
      <Button
        type='button'
        color='danger'
        variant='contained'
        disabled={isDeleting}
        onClick={() => dataOptions({dialogIsOpen: true})}
      >{t('app_settings.delete_app')}
      </Button>
      <DeleteDialog
        disabled={!confirmed}
        open={!!dialogIsOpen}
        loading={!!isDeleting}
        agreeAction={destroyApp}
        dismissLabel={t('app_settings.keep_app')}
        agreeLabel={t('app_settings.delete_app')}
        onClose={() => dataOptions({dialogIsOpen: false})}
        bodyDescription={
          <FormControlLabel
            style={{margin: 0, alignItems: 'flex-start'}}
            label={
              <Typography
                align='left'
                component='span'
                style={{paddingTop: 6.5, paddingBottom: 9, display: 'block'}}
              >{t('app_settings.delete_app_warning', {name: currentApp?.title})}
              </Typography>
            }
            control={
              <Checkbox
                size='small'
                color='primary'
                checked={confirmed}
                onChange={handleCheckboxChange}
              />
            }
          />
        }
        dismissAction={() => dataOptions({dialogIsOpen: false})}
        bodyTitle={t('app_settings.delete_body_title', {name: currentApp?.title})}
      />
    </>
  )
}