import React from 'react';
import Typography from '@material-ui/core/Typography';
import {DataTypeProvider} from '@devexpress/dx-react-grid';

export default function ConversionsSumFormatter(
  {
    value,
  }: DataTypeProvider.ValueFormatterProps
) {
  return (
    <Typography
      noWrap
      component='p'
      align='center'
      style={{fontSize: 12}}
    >{(value || Number(value) === 0) ? `$${Number(value).toFixed(3)}` : '-'}
    </Typography>
  )
}
