import {_setTags} from './index';
import {_dataOptions} from './index';
import {stringify} from 'querystring';
import {filterObject} from '../../../utils';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {_tagsSearchText} from '../../selectors/templates';
import {_tagsPageNumber} from '../../selectors/templates';
import {FiniteStates} from '../../../constants/finiteStates';

export const _fetchTags = (appUuid: string): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    fetchingTemplateTagsState: FiniteStates.LOADING,
  }));

  const searchText = _tagsSearchText(getState());
  const pageNumber = _tagsPageNumber(getState());

  const params = stringify(filterObject({
    pageNumber,
    searchText,
  }));

  const {
    hasError,
    body: {
      data,
    },
  } = await api.post(`${urlHelper(ApiRoutes.TEMPLATES_TAGS, {
    appUuid,
  })}?${params}`, {
    skipDelay: true,
  });

  if (!hasError) {
    const {
      rows,
      has_more,
      pageNumber,
    } = data;

    dispatch(_setTags(rows.map(({id}: { id: string; text: string }) => {
      return {
        value: id,
        label: id,
      }
    })));

    dispatch(_dataOptions({
      hasMoreTags   : has_more,
      tagsPageNumber: pageNumber + 1,
    }));
  }

  dispatch(_dataOptions({
    fetchingTemplateTagsState: FiniteStates.SUCCESS,
  }));
};