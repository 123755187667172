import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams, useHistory} from 'react-router';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {Radio, Button,Row, Input, Form, Select, DatePicker, Tooltip } from 'antd';
import {_fetchTemplates, _fetchSegments} from './actions';
import {_fetchTags} from '../../../../store/actions/tags';
import {createOrUpdateAutomation} from '../../../../store/actions/automation';
import {_toggleErrors} from '../../../../store/actions/automation/actions';
import FormHeader from './FormHeader';
import TaggedUrl from './TaggedUrl';
import DynamicSelect from '../../../../components/antd/DynamicSelect';
import TimeRangeSelect from './TimeRangeSelect';
import {Content} from '../../../../components/antd/Content';
import {_currentApp} from '../../../../store/selectors/apps';
import {getPrefixCls} from '../../../../utils/getPrefixCls';
import {Automations} from '../../../../types/automations';
import {SendSegmentsType} from '../../../../constants/automation';
import {_getErrorsTypes} from '../../../../store/selectors/automation';
import {convertDataForRequest, checkSegmentValidation, getDefaultValues} from './helpers';
import './index.less';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Item } = Form;

const style: React.CSSProperties = {
  width: '100%',
  minWidth: 300,
};

interface IProps {
  data?: Automations.AutomationDataType;
  headerText: string;
  isActionClone?: boolean;
}

const CreateOrUpdateForms: React.FC<IProps> = ({data, headerText, isActionClone}) => {
  const { appUuid } = useParams<{appUuid: string}>();
  const currentApp = useSelector(_currentApp);
  const backEndErrors = useSelector(_getErrorsTypes);
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, setValue, handleSubmit, watch, setError } = useForm<Automations.AutomationForm>({defaultValues: getDefaultValues(currentApp, data, isActionClone)});
  const { fields, append, remove } = useFieldArray({control, name: 'hour_ranges'});

  React.useEffect(() => {
    Object.entries(backEndErrors).forEach(([name, ms]) => {
      if (name === 'from_date' || name === 'to_date') {
        name = 'dates';
      }
      setError(name as keyof Automations.AutomationForm, {message: ms});
    });
  }, [backEndErrors, setError]);

  React.useEffect(() => {
    return () => {
      dispatch(_toggleErrors({}));
    };
  }, [dispatch]);

  const onSave = (data: Automations.AutomationForm) => {
    const isSegmentDataValid = checkSegmentValidation(data, setError);
    if (!isSegmentDataValid) {
      return;
    };
    const dataForRequest = convertDataForRequest(data);
    dispatch(createOrUpdateAutomation(appUuid, dataForRequest, history));
  };

  const tagsDisabled = watch('all_tags');
  const segmentsDisabled = watch('send_to') === SendSegmentsType.all;

  return (
    <Content>
      <div className={`${getPrefixCls('segments-form-container')} p-5`}>
        <form onSubmit={handleSubmit(onSave)}>
          <Controller
            render={(({ field: { value, onChange } }) =>
              <FormHeader
                headerText={headerText}
                onChange={onChange}
                checked={value}
              />
            )}
            name='active'
            control={control}
          />
          <Row justify='space-between' align='top'>
            <Row align='middle' className='sections'>
              <Form
                component='div'
                layout='vertical'
              >
                <Controller
                  name='url'
                  control={control}
                  rules={{required: 'The url field is required.'}}
                  render={(({ field: { value, onChange, }, fieldState: {invalid, error} }) =>
                    <Item
                      validateStatus={invalid && 'error'}
                      help={error?.message}
                      label='Launch URL'
                      style={style}
                      className='launch_url'
                    >
                      <TaggedUrl
                        value={value}
                        onChange={onChange}
                        setValue={setValue}
                        formName={'url'}
                      />
                    </Item>
                  )}
                />
                <Row align='top' justify='start'>
                  <Controller
                    name='ttl_value'
                    control={control}
                    render={(({ field: { value, onChange }, fieldState: {invalid, error} }) =>
                      <Item
                        validateStatus={invalid && 'error'}
                        help={error?.message}
                        label='TTL'
                        tooltip='TTL is a value that describes how long a push message is retained by the push service.'
                        style={{minWidth: 110}}
                        className='m-r-4'
                      >
                        <Input
                          value={value}
                          onChange={onChange}
                          type='number'
                          min={0}
                          className='m-r-3'
                        />
                      </Item>
                    )}
                  />
                  <Controller
                    name='ttl_unit'
                    control={control}
                    render={(({ field: { value, onChange }, fieldState: {invalid, error} }) =>
                      <Item
                        validateStatus={invalid && 'error'}
                        help={error?.message}
                        style={{marginTop: 30, minWidth: 110}}
                      >
                        <Select defaultValue='day' onChange={onChange} value={value}>
                          <Option value='second'>second</Option>
                          <Option value='minute'>minute</Option>
                          <Option value='hour'>hour</Option>
                          <Option value='day'>day</Option>
                        </Select>
                      </Item>
                    )}
                  />
                </Row>
              </Form>
            </Row>
            <Row align='middle' className='sections'>
              <Form component='div' layout='vertical' style={{width: '100%'}}>
                <Controller
                  name='name'
                  rules={{ required: 'Automation name is required' }}
                  control={control}
                  render={(({ field: { value, onChange }, fieldState: { invalid, error } }) =>
                    <Item validateStatus={invalid && 'error'} help={error?.message} label='Name' style={style}>
                      <Input value={value} onChange={onChange} placeholder='Automation Name'/>
                    </Item>
                  )}
                />
                <Controller
                  name='dates'
                  control={control}
                  render={(({ field: { value, onChange }, fieldState: { invalid, error } }) =>
                  <Item label='Active date range' validateStatus={invalid && 'error'} help={error?.message}>
                    <RangePicker
                      allowClear={false}
                      onChange={onChange}
                      value={value}
                    />
                  </Item>
                  )}
                />
                <Item>
                  <Controller
                    name='is_direct'
                    control={control}
                    render={(({ field: { value, onChange } }) =>
                      <Radio.Group onChange={onChange} value={value}>
                        <Radio value={false}>Direct</Radio>
                        <Radio value={true}>Time Zone</Radio>
                      </Radio.Group>
                    )}
                  />
                </Item>
              </Form>
            </Row>
          </Row>
          <Row justify='space-between' align='top'>
            <Row className='sections s_left'>
              <TimeRangeSelect
                fieldsArray={fields}
                control={control}
                remove={remove}
                append={append}
              />
            </Row>
            <Row className='sections s_right'>
              <Form component='div' layout='vertical' style={{width: '100%'}}>
                <Controller
                  name='start_template'
                  control={control}
                  rules={{required: 'Please specify template to start from'}}
                  render={(({ field: { value, onChange }, fieldState: { invalid, error } }) =>
                    <Item label='Starting Template' validateStatus={invalid && 'error'} help={error?.message}>
                      <DynamicSelect
                        value={value}
                        onChange={onChange}
                        fetchData={_fetchTemplates}
                        dataKey='rows'
                        titleKey='id'
                        valueKey='id'
                      />
                    </Item>
                  )}
                />
              </Form>
            </Row>
          </Row>
          <Row justify='space-between' align='top'>
            <Row className='sections s_left'>
              <Item style={{width: '100%'}}>
                <Controller
                  name='all_tags'
                  control={control}
                  render={(({ field: { value, onChange } }) =>
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={false}>Particular tags</Radio>
                      <Radio value={true}>All tags</Radio>
                    </Radio.Group>
                  )}
                />
              </Item>
              <Row align='bottom' className='tagsIncludeRow' style={{width: '100%'}}>
                <Form component='div' layout='vertical' style={{width: 'calc(100% - 150px)'}}>
                  <Item label='Tags to include'>
                    <Controller
                      name='include_tags'
                      control={control}
                      render={(({ field: { value, onChange } }) =>
                        <DynamicSelect
                          disabled={tagsDisabled}
                          value={value}
                          onChange={onChange}
                          fetchData={_fetchTags}
                          mode='multiple'
                          maxTagCount='responsive'
                          dataKey='rows'
                          titleKey='text'
                          valueKey='id'
                        />
                      )}
                    />
                  </Item>
                </Form>
                <Item>
                  <Controller
                    name='include_all_tags'
                    control={control}
                    render={(({ field: { value, onChange } }) =>
                      <Radio.Group value={value} onChange={onChange} disabled={tagsDisabled}>
                        <Radio.Button value={false}>
                          <span className='m-r-1'>OR</span>
                          <Tooltip title='Prompt Text'>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </Radio.Button>
                        <Radio.Button value={true}>
                          <span className='m-r-1'>AND</span>
                          <Tooltip title='Prompt Text'>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  />
                </Item>
              </Row>
              <Row align='bottom' className='tagsExcludeRow' style={{width: '100%'}}>
                <Form component='div' layout='vertical' style={{width: 'calc(100% - 150px)'}}>
                  <Item label='Tags to exclude'>
                    <Controller
                      name='exclude_tags'
                      control={control}
                      render={(({ field: { value, onChange } }) =>
                        <DynamicSelect
                          value={value}
                          disabled={tagsDisabled}
                          onChange={onChange}
                          fetchData={_fetchTags}
                          mode='multiple'
                          maxTagCount='responsive'
                          dataKey='rows'
                          titleKey='text'
                          valueKey='id'
                        />
                      )}
                    />
                  </Item>
                </Form>
                <Item>
                  <Controller
                    name='exclude_all_tags'
                    control={control}
                    render={(({ field: { value, onChange } }) =>
                    <Radio.Group value={value} onChange={onChange} disabled={tagsDisabled}>
                      <Radio.Button value={false}>
                        <span className='m-r-1'>OR</span>
                        <Tooltip title='Prompt Text'>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </Radio.Button>
                      <Radio.Button value={true}>
                        <span className='m-r-1'>AND</span>
                        <Tooltip title='Prompt Text'>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </Radio.Button>
                    </Radio.Group>
                    )}
                  />
                </Item>
              </Row>
            </Row>
            <Row className='sections s_right'>
              <Item style={{width: '100%'}}>
                <Controller
                  name='send_to'
                  control={control}
                  render={(({ field: { value, onChange } }) =>
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={SendSegmentsType.particular}>Particular segments</Radio>
                      <Radio value={SendSegmentsType.all}>All users</Radio>
                    </Radio.Group>
                  )}
                />
              </Item>
              <Row align='bottom' style={{width: '100%'}}>
                <Form component='div' layout='vertical' style={{width: '100%'}}>
                  <Controller
                    name='include_segments'
                    control={control}
                    render={(({ field: { value, onChange }, fieldState: { invalid, error } }) =>
                      <Item
                        label='Segments to include'
                        validateStatus={invalid && 'error'}
                        help={error?.message}
                      >
                        <DynamicSelect
                          disabled={segmentsDisabled}
                          value={value}
                          labelInValue
                          onChange={onChange}
                          fetchData={_fetchSegments}
                          dataKey='rows'
                          titleKey='name'
                          valueKey='id'
                          mode='multiple'
                          maxTagCount='responsive'
                        />
                      </Item>
                    )}
                  />
                </Form>
              </Row>
              <Row align='bottom' style={{width: '100%'}}>
                <Form component='div' layout='vertical' style={{width: '100%'}}>
                  <Controller
                    name='exclude_segments'
                    control={control}
                    render={(({field: {value, onChange}, fieldState: {invalid, error}}) =>
                      <Item
                        label='Segments to exclude'
                        validateStatus={invalid && 'error'}
                        help={error?.message}
                      >
                        <DynamicSelect
                          disabled={segmentsDisabled}
                          value={value}
                          labelInValue
                          onChange={onChange}
                          fetchData={_fetchSegments}
                          dataKey='rows'
                          titleKey='name'
                          valueKey='id'
                          mode='multiple'
                          maxTagCount='responsive'
                        />
                      </Item>
                    )}
                  />
                </Form>
              </Row>
            </Row>
          </Row>
          <Row justify='center'>
            <Button type='primary' htmlType='submit' style={{width: 150}}>Save</Button>
          </Row>
        </form>
      </div>
    </Content>
  );
};

export default CreateOrUpdateForms;
