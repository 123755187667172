import React from 'react';
import {Button} from 'antd';
import {Result} from 'antd';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router';
import logo from '../../../../images/antd/logo.svg';
import {Redirecting} from '../../Common/Redirecting';
import {AppRoutes} from '../../../../constants/appRoutes';
import {Spinner} from '../../../../components/antd/Spinner';
import {useConfirmEmailChange} from './useConfirmEmailChange';

export function ConfirmEmailChange() {
  const {
    formInfo,
  } = useConfirmEmailChange();
  const {replace} = useHistory();

  if (!formInfo) {
    return <Spinner/>
  }

  if (formInfo.status !== 200) {
    return (
      <Result
        status='error'
        title={formInfo.message || 'Something Went Wrong'}
        extra={
          <Button
            type='primary'
            onClick={() => replace(AppRoutes.LOGIN)}
          >Back to Login
          </Button>
        }
      />
    )
  }

  return (formInfo.status === 200) && (
    <div
      className='container'
    >
      <div
        className='form-container'
      >
        <div
          className='m-b-5'
        >
          <Link
            to='/'
            className='d-flex justify-content-center'
          >
            <img
              src={logo}
              height={64}
              alt='Smart Push Logo'
            />
          </Link>
        </div>
        <Redirecting
          title={formInfo.message}
          subTitle='Redirecting ...'
        />
      </div>
    </div>
  )
}
