import React from 'react';
import {Button} from 'antd';
import {useWatch} from 'react-hook-form';
import {Control} from 'react-hook-form/dist/types/form';
import {dataPrefixes} from '../../../../../../constants';
import {IResetPasswordForm} from '../../useResetPassword';

const {auth: {resetPassword}} = dataPrefixes;

export function SubmitButton(
  {
    control,
    loading,
    disabled,
  }: {
    loading: boolean;
    disabled?: boolean;
    control: Control<IResetPasswordForm>,
  }
) {
  const password = useWatch({
    control,
    name: 'password',
  });

  return (
    <Button
      block
      size='large'
      type='primary'
      htmlType='submit'
      loading={loading}
      disabled={!password || disabled}
      data-qa={`${resetPassword}-update-password-button`}
    >Update Password
    </Button>
  )
}
