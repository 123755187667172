import {getSmartPush} from '../index';
import {createSelector} from 'reselect';
import {emptyObject} from '../../../constants';

export const getCampaigns = createSelector(getSmartPush, (smartPush) => {
  return smartPush.campaigns;
});

export const getData = createSelector(getCampaigns, (data) => {
  return data.data;
});

export const getById = createSelector(getData, (data) => {
  return data.byId;
});

export const getAllIds = createSelector(getData, (data) => {
  return data.allIds;
});

export const getEntity = createSelector(getData, (data) => {
  return data.entity;
});

export const getCommonEntity = createSelector(getEntity, (data) => {
  return {
    values     : data?.values,
    locale_type: data?.locale_type,
  };
});

export const getMessage = createSelector(getData, (data) => {
  return data.message;
});

export const getIsLoading = createSelector(getData, (data) => {
  return data.loading;
});

export const getEntityLang = createSelector(getEntity, (data) => {
  return data?.messages;
});

export const getErrors = createSelector(getData, (data) => {
  return data.errors || emptyObject;
});

export const getCampaignStats = createSelector(getData, (data) => {
  return data.campaignStats;
});

export const getActionDialogObj = createSelector(getData, (data) => {
  return data.actionDialog;
});

export const getDataOptions = createSelector(getData, (data) => {
  return data.dataOptions || emptyObject;
});

export const getActionDialogData = createSelector(getActionDialogObj, (data) => {
  return data?.data;
});

export const getActionDialog = createSelector(getActionDialogObj, (data) => {
  return data?.dialog;
});

export const getOptions = createSelector(getCampaigns, (data) => {
  return data.options;
});

export const getLastQuery = createSelector(getOptions, (data) => {
  return data.lastQuery;
});

export const getPagination = createSelector(getCampaigns, (data) => {
  return data.pagination;
});

export const getCampaignDefaultLang = createSelector(getEntity, getEntityLang, (entity, data) => {
  return {
    url      : entity?.url,
    icon_url : entity?.icon_url,
    image_url: entity?.image_url,
    ...data?.default
  };
});
