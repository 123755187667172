import React from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {useTitle} from '../../../../hooks/useTitle';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {_login} from '../../../../store/actions/application/login';

export interface ILoginForm {
  email: string;
  message: string;
  password: string;
  remember: boolean;
}

const defaultValues: Partial<ILoginForm> = {
  message : '',
  remember: false,
  email   : process.env.REACT_APP_EMAIL,
  password: process.env.REACT_APP_PASSWORD
}

export function useLogin() {
  const {
    setError,
    handleSubmit,
    ...formRest
  } = useForm<ILoginForm>({
    mode         : 'onSubmit',
    defaultValues: defaultValues,
  });
  const {search} = useLocation();
  const dispatch = useDispatch();
  useTitle('Sign in | SmartPush');
  const {executeRecaptcha} = useGoogleReCaptcha();

  const searchString = search.replace('?', '');

  const submit = React.useCallback((formValues: ILoginForm) => {
    return dispatch(_login({
      ...formValues,
      email: formValues.email?.trim()
    }, {
      setError,
      executeRecaptcha,
    }));
  }, [
    setError,
    dispatch,
    executeRecaptcha,
  ]);

  return {
    ...formRest,
    searchString,
    onSubmit: handleSubmit(submit),
  }
}