export enum ActionTypes {
  UPDATE_TIMER = 'UPDATE_TIMER',
  CORE_HANDLE_SETTINGS = 'CORE_HANDLE_SETTINGS',
  CORE_GENERAL_SETTINGS_FORM_ERROR = 'CORE_GENERAL_SETTINGS_FORM_ERROR',
  CORE_HANDLE_GENERAL_SETTINGS_FORM = 'CORE_HANDLE_GENERAL_SETTINGS_FORM',
  CORE_GENERAL_SETTINGS_FORM_LOADING = 'CORE_GENERAL_SETTINGS_FORM_LOADING',
  CORE_GENERAL_SETTINGS_FORM_SUCCESS = 'CORE_GENERAL_SETTINGS_FORM_SUCCESS',
  CORE_REQUESTING_GENERAL_SETTINGS_FORM_START = 'CORE_REQUESTING_GENERAL_SETTINGS_FORM_START',
  CORE_SECURITY_SETTINGS_FORM_ERROR = 'CORE_SECURITY_SETTINGS_FORM_ERROR',
  CORE_HANDLE_SECURITY_SETTINGS_FORM = 'CORE_HANDLE_SECURITY_SETTINGS_FORM',
  CORE_SECURITY_SETTINGS_FORM_LOADING = 'CORE_SECURITY_SETTINGS_FORM_LOADING',
  CORE_REQUESTING_SECURITY_SETTINGS_FORM_START = 'CORE_REQUESTING_SECURITY_SETTINGS_FORM_START',
}
