import React from 'react';
import {Link} from '../../../../../../../components/antd/Typography';

export function PrivacyPolicy() {
  return (
    <Link
      target='_blank'
      href='https://www.smartpush.ai/privacy-policy'
    >Privacy Policy.
    </Link>
  )
}
