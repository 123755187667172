import React from 'react';
import Paper from '@material-ui/core/Paper';
import {ColumnChooser} from '@devexpress/dx-react-grid-material-ui';

export default function ColumnChooserContainerComponent(props: ColumnChooser.ContainerProps) {
  return (
    <Paper
      square
      variant='outlined'
      data-id='column-chooser-container'
      {...props}
    />
  )
}
