import React from 'react';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {ColumnsType} from 'antd/es/table';
import {useTranslation} from 'react-i18next';
import {Segments} from '../../../../../types/segments';
import {useTitle} from '../../../../../hooks/useTitle';
import * as TableCell from '../../components/TableCell';
import {urlHelper} from '../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {useSmartPush} from '../../../../../hooks/useSmartPush';
import {_fetch} from '../../../../../store/actions/segments/fetch';
import {_dataOptions} from '../../../../../store/actions/segments';
import * as selectors from '../../../../../store/selectors/segments';

export function useIndex() {
  const {push} = useHistory();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  useTitle(t('segments.document_title'));

  const {
    appUuid,
    user: {
      canCreate,
    }
  } = useSmartPush();

  const total = useSelector(selectors._total);
  const pageSize = useSelector(selectors._pageSize);
  const segments = useSelector(selectors._segments);
  const current = useSelector(selectors._pageNumber);
  const searchText = useSelector(selectors._searchText);
  const sorterField = useSelector(selectors._sorterField);
  const sorterOrder = useSelector(selectors._sorterOrder);
  const segmentsState = useSelector(selectors._fetchingSegmentsState);

  React.useEffect(() => {
    dispatch(_fetch(appUuid));
  }, [
    current,
    appUuid,
    dispatch,
    pageSize,
    searchText,
    sorterField,
    sorterOrder,
  ]);

  const onReload = React.useCallback(() => {
    dispatch(_fetch(appUuid));
  }, [
    appUuid,
    dispatch,
  ]);

  const onCreate = React.useCallback(() => {
    push(urlHelper(AppRoutes.SEGMENTS_CREATE, {appUuid}));
  }, [
    push,
    appUuid,
  ]);

  const tableOnChange = React.useCallback((pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      dispatch(_dataOptions({
        total     : pagination.total,
        pageNumber: pagination.current,
        pageSize  : pagination.pageSize,
      }));
    } else if (extra.action === 'sort') {
      dispatch(_dataOptions({
        sorterOrder: sorter.order,
        sorterField: sorter.field,
      }));
    }
  }, [
    dispatch,
  ]);

  const columns: ColumnsType<Segments.Entity> = React.useMemo(() => {
    return [
      {
        key      : 'name',
        dataIndex: 'name',
        title    : 'Name',
        render   : (value, record) => <TableCell.Name value={value} record={record}/>,
      },
      {
        title : 'Description',
        key   : 'description',
        render: (value, record) => <TableCell.Description record={record}/>,
      },
      {
        key      : 'updated_at',
        dataIndex: 'updated_at',
        title    : 'Updated at',
        render   : (value) => <TableCell.DateTime value={value}/>,
      },
      {
        sorter          : true,
        defaultSortOrder: 'descend',
        key             : 'created_at',
        dataIndex       : 'created_at',
        title           : 'Created at',
        render          : (value) => <TableCell.DateTime value={value}/>,
      },
      {
        width : '9rem',
        fixed : 'right',
        title : 'Actions',
        key   : 'operation',
        render: (record) => <TableCell.Actions record={record}/>,
      },
    ]
  }, []);

  return {
    total,
    columns,
    current,
    pageSize,
    segments,
    onReload,
    onCreate,
    canCreate,
    tableOnChange,
    segmentsState,
  }
}
