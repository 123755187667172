import React from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {_user} from '../../../store/selectors/application/acl';
import {_loginUnauthorized} from '../../../store/actions/application/loginUnauthorized';

export interface IForm {
  email: string;
  message: string;
  password: string;
}

export function useUnauthorized() {
  const user = useSelector(_user);
  const {
    reset,
    setError,
    handleSubmit,
    ...formRest
  } = useForm<IForm>({
    mode         : 'onSubmit',
    defaultValues: {
      email: user?.email
    },
  });
  const dispatch = useDispatch();
  const {executeRecaptcha} = useGoogleReCaptcha();

  React.useEffect(() => {
    if (user?.email) {
      reset({
        email: user?.email
      })
    }
  }, [
    reset,
    user?.email,
  ]);

  const submit = React.useCallback((formValues: IForm) => {
    return dispatch(_loginUnauthorized({
      ...formValues,
      email: formValues.email?.trim()
    }, {
      setError,
      executeRecaptcha,
    }));
  }, [
    setError,
    dispatch,
    executeRecaptcha,
  ]);

  return {
    ...formRest,
    onSubmit: handleSubmit(submit),
  }
}