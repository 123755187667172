import React from 'react';
import Typography from '@material-ui/core/Typography';

export function TtlLabel() {
  return (
    <Typography
      noWrap
      color='inherit'
      variant='inherit'
    >When the user closed the prompt, show it again after:
    </Typography>
  )
}