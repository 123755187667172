import React from 'react';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import Creatable from 'react-select/creatable';
import {Card} from '../../../../../components/Card';
import {getList} from '../../../../../services/tags';
import WarningIcon from '@material-ui/icons/Warning';
import Dialog from '../../../../../components/Dialog';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Actions from '../../../../../components/Actions';
import {urlHelper} from '../../../../../utils/urlHelper';
import {ApiRoutes} from '../../../../../constants/apiRoutes';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {Spinner} from '../../../../../components/antd/Spinner';
import MultiTabs from '../../../../../components/LanguageTabs';
import {CardHeader} from '../../../../../components/CardHeader';
import {_currentApp} from '../../../../../store/selectors/apps';
import {getAction} from '../../../../../store/actions/templates';
import AlertMessage from '../../../../../components/AlertMessage';
import {getErrors} from '../../../../../store/selectors/templates';
import {getTemplate} from '../../../../../store/selectors/templates';
import {_apiBaseUrl} from '../../../../../store/selectors/application';
import {_accessToken} from '../../../../../store/selectors/application';
import {getDataOptions} from '../../../../../store/selectors/templates';
import {removeErrorAction} from '../../../../../store/actions/templates';
import {getTemplateLang} from '../../../../../store/selectors/templates';
import {toggleEntityAction} from '../../../../../store/actions/templates';
import {toggleErrorsAction} from '../../../../../store/actions/templates';
import {updateEntityAction} from '../../../../../store/actions/templates';
import {_userLimits} from '../../../../../store/selectors/application/acl';
import {emptyLanguagesAction} from '../../../../../store/actions/templates';
import {removeLanguageAction} from '../../../../../store/actions/templates';
import {updateEntityLangAction} from '../../../../../store/actions/templates';
import {CreatableAsyncPaginate} from '../../../../../components/AsyncPaginate';
import {getTemplateDefaultLang} from '../../../../../store/selectors/templates';

const useStyles = makeStyles((theme: Theme) => ({
  cardHeaderRoot       : {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  cardContentRoot      : {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  nestedCard           : {
    overflow    : 'unset',
    marginBottom: theme.spacing(1),
  },
  nestedTitleTypography: {
    fontSize  : 16,
    fontWeight: 'bold',
    color     : '#ffffff',
  },
}));

export function Upsert(
  {
    row,
    dialogState,
    updateDialogState,
    cloneFromCampaign,
  }: {
    row?: any;
    dialogState: any;
    updateDialogState: any;
    cloneFromCampaign?: boolean;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    uuid,
    appUuid,
  } = useParams<{ appUuid: string, uuid?: string }>();

  const errors = useSelector(getErrors);
  const lang = useSelector(getTemplateLang);
  const template = useSelector(getTemplate);
  const userLimits = useSelector(_userLimits);
  const apiBaseUrl = useSelector(_apiBaseUrl);
  const currentApp = useSelector(_currentApp);
  const accessToken = useSelector(_accessToken);
  const defaultLang = useSelector(getTemplateDefaultLang);

  const {
    isGetting,
  } = useSelector(getDataOptions);

  React.useEffect(() => {
    if (currentApp?.default_icon_url) {
      dispatch(updateEntityLangAction({
        id      : 'default',
        icon_url: currentApp.default_icon_url,
      }));
    }
  }, [
    dispatch,
    currentApp,
  ]);

  React.useEffect(() => {
    let controller: null | AbortController = null;

    if (appUuid && (row?.id || uuid)) {
      controller = new AbortController();

      if (Number(row?.id)) {
        dispatch(getAction(appUuid, Number(row?.id), {
          signal  : controller.signal,
          redirect: () => {
            history.push(urlHelper(AppRoutes.TEMPLATES, {
              appUuid,
            }));
          }
        }));
      } else if (uuid && cloneFromCampaign) {
        dispatch(getAction(appUuid, uuid, {
          cloneFromCampaign,
          signal  : controller.signal,
          redirect: () => {
            history.push(urlHelper(AppRoutes.TEMPLATES, {
              appUuid,
            }));
          }
        }));
      }
    }

    return function cleanup() {
      controller?.abort();

      dispatch(toggleErrorsAction(void 0));
      dispatch(toggleEntityAction(void 0));
    }
  }, [
    row,
    uuid,
    appUuid,
    history,
    dispatch,
    cloneFromCampaign,
  ]);

  const [isOpen, toggleMessage] = React.useState<number>(0);

  const removeError = React.useCallback((inputName: string) => {
    dispatch(removeErrorAction(inputName));
  }, [
    dispatch,
  ])

  const showWarning = React.useCallback(() => {
    if (template && template.id && template.used_in_campaigns && !isOpen) {
      toggleMessage(prevState => prevState + 1);
    }
    if (uuid) {
      toggleMessage(prevState => prevState + 1);
    }
  }, [
    uuid,
    isOpen,
    template,
  ]);

  const messageCardLabels = React.useMemo(() => ({
    title                    : t('templates.title'),
    message                  : t('templates.message'),
    english                  : t('templates.english'),
    icon_url                 : t('templates.icon_url'),
    image_url                : t('templates.image_url'),
    title_placeholder        : t('templates.title_placeholder'),
    message_placeholder      : t('templates.message_placeholder'),
    icon_url_helper_text     : t('templates.icon_url_helper_text'),
    icon_url_placeholder     : t('templates.icon_url_placeholder'),
    image_url_helper_text    : t('templates.image_url_helper_text'),
    image_url_placeholder    : t('templates.image_url_placeholder'),
    launch_url_placeholder   : t('templates.launch_url_placeholder'),
    push_notification_message: t('templates.push_notification_message'),
  }), [
    t,
  ]);

  const actionsCardLabels = React.useMemo(() => ({
    delete                     : t('templates.delete'),
    actions                    : t('templates.actions'),
    icon_url                   : t('templates.icon_url'),
    add_action                 : t('templates.add_action'),
    action_url                 : t('templates.action_url'),
    action_title               : t('templates.action_title'),
    delete_action              : t('templates.delete_action'),
    action_action              : t('templates.action_action'),
    actions_max_limit          : t('templates.actions_max_limit'),
    action_url_placeholder     : t('templates.action_url_placeholder'),
    action_title_placeholder   : t('templates.action_title_placeholder'),
    action_action_placeholder  : t('templates.action_action_placeholder'),
    action_icon_url_placeholder: t('templates.action_icon_url_placeholder'),
  }), [
    t,
  ]);

  const toggleErrors = React.useCallback((key: string) => {
    setTimeout(() => {
      if (errors[key]) {
        dispatch(toggleErrorsAction({
          ...errors,
          [key]: void 0,
        }));
      }
    }, 0)
  }, [
    errors,
    dispatch,
  ]);

  const removeLanguage = React.useCallback((lang: string) => {
    dispatch(removeLanguageAction(lang))
  }, [
    dispatch,
  ]);

  const updateEntity = React.useCallback((inputName: string, value: any) => {
    showWarning();
    dispatch(updateEntityAction({
      [inputName]: value,
    }));
  }, [
    dispatch,
    showWarning,
  ]);

  const emptyLanguages = React.useCallback(() => dispatch(emptyLanguagesAction()), [
    dispatch
  ]);

  const handleOnChange = React.useCallback((payload: { [key: string]: any }) => {
    dispatch(toggleEntityAction({
      ...template,
      ...payload
    }))
  }, [
    template,
    dispatch,
  ]);

  const updateEntityLang = React.useCallback((id: string, inputName: string, value: any) => {
    showWarning();
    dispatch(updateEntityLangAction({
      id,
      [inputName]: value
    }));
    removeError(`messages.${id}.${inputName}`);
  }, [
    dispatch,
    removeError,
    showWarning,
  ]);

  const copyLanguageData = React.useCallback((id: string) => {
    dispatch(updateEntityLangAction({
      id,
      ...defaultLang
    }));
  }, [
    dispatch,
    defaultLang,
  ]);

  const handleActionsOnChange = React.useCallback((action: string, value: any, inputName: string) => {
    showWarning();
    const index = Object.values((template?.action) || {}).map((action: any) => {
      const {
        ...rest
      } = action;
      return {...rest}
    }).findIndex((i: any) => Number(i.identity) === Number(value.identity));
    switch (action) {
      case 'add': {
        dispatch(toggleErrorsAction(void 0));

        handleOnChange({
          ...template,
          action: {
            ...(template && template.action),
            [value.identity]: {
              identity: value.identity,
              type    : `action_${Object.keys(template?.action || {}).length + 1}`,
            }
          }
        });
        break;
      }
      case 'delete': {
        dispatch(toggleErrorsAction(void 0));

        const prevState: any = ((template && template.action) || []);
        delete prevState[value.identity];

        const restAction: any = Object.values(prevState || {}).find((e: any) => {
          return Number(e.identity) !== Number(value.identity)
        });

        if (restAction) {
          handleOnChange({
            ...template,
            action: {
              [restAction.identity]: {
                ...restAction,
                type: 'action_1',
              }
            }
          });
        } else {
          handleOnChange({
            ...template,
            action: {...prevState}
          });
        }
        break;
      }
      case 'update': {
        handleOnChange({
          ...template,
          action: {
            ...(template && template.action),
            [value.identity]: {
              ...((template && template.action) || {})[value.identity],
              ...value
            }
          }
        });
        if ((index !== -1) && inputName) {
          removeError(`action.${index}.${inputName}`);
        }
        break;
      }
      default: {
        console.error('Invalid case');
      }
    }
  }, [
    template,
    dispatch,
    showWarning,
    removeError,
    handleOnChange,
  ]);

  const tagsLoadOptions = React.useCallback(async (inputValue: string, prevOptions: any[], {pageNumber}: any) => {
    const {
      body: {
        data,
      }
    } = await getList(`${apiBaseUrl}${urlHelper(ApiRoutes.TEMPLATES_TAGS, {
      appUuid
    })}`, accessToken, {
      pageNumber,
      searchText: inputValue,
    });
    return data;
  }, [
    appUuid,
    apiBaseUrl,
    accessToken,
  ]);

  if (isGetting) {
    return (
      <Spinner/>
    )
  }

  function showNotice(tags: any[]) {
    if (Number(userLimits?.templates?.per_item_max_tags_count)) {
      if (tags.length > Number(userLimits?.templates?.per_item_max_tags_count)) {
        dispatch(toggleErrorsAction({
          tags: `You have reached your limit of ${userLimits?.templates?.per_item_max_tags_count} Tags`
        }));
      } else {
        toggleErrors('tags');
      }
    } else {
      toggleErrors('tags');
    }
  }

  return (
    <Typography
      component='div'
    >
      <AlertMessage
        open={isOpen === 1}
        onClose={() => toggleMessage(prevState => prevState + 1)}
      />
      <Card
        elevation={3}
        className={classes.nestedCard}
      >
        <CardHeader
          color='primary'
          title={t('templates.tags')}
        />
        <CardContent
          classes={{root: classes.cardContentRoot}}
        >
          <div
            data-id='tags-select'
          >
            <CreatableAsyncPaginate
              isMulti
              error={!!errors?.tags}
              closeMenuOnSelect={false}
              onChange={(v: any) => {
                handleOnChange({
                  tags: [
                    ...(v ? v : []),
                  ]
                });
                showNotice((v ? v : []))
              }}
              additional={{pageNumber: 1}}
              loadOptions={tagsLoadOptions as any}
              onCreateOption={(v: any) => {
                handleOnChange({
                  tags: [
                    ...((template && template.tags) || []),
                    {label: v, value: v}
                  ]
                });
                showNotice([
                  ...((template && template.tags) || []),
                  {label: v, value: v}
                ])
              }}
              SelectComponent={Creatable as any}
              value={(template && template.tags) || []}
              placeholder={t('templates.select_or_create_tags')}
            />
            {!!errors?.tags && (
              <Typography
                color='error'
                style={{
                  fontSize: '12px',
                  margin  : '8px 8px 0px',
                }}
              >{errors.tags}
              </Typography>
            )}
          </div>
        </CardContent>
        <div/>
      </Card>
      <MultiTabs
        lang={lang}
        errors={errors}
        entity={template}
        onClose={removeLanguage}
        defaultLang={defaultLang}
        actions={template?.action}
        updateEntity={updateEntity}
        emptyLanguages={emptyLanguages}
        copyLanguageData={copyLanguageData}
        updateEntityLang={updateEntityLang}
        messageCardLabels={messageCardLabels}
      />
      <Actions
        errors={errors}
        entity={template?.action}
        labels={actionsCardLabels}
        className={classes.nestedCard}
        onChange={handleActionsOnChange}
      />
      {dialogState.open && (
        <WarningDialog
          onClose={() => {
            updateDialogState({open: false})
          }}
          dialogState={dialogState}
        />
      )}
    </Typography>
  )
}

function WarningDialog(
  {
    onClose,
    loading,
    dialogState,
  }: {
    loading?: boolean,
    dialogState: {
      open: boolean;
      timer?: number;
      bodyTitle?: string;
      buttonText?: string;
      agreeAction?: () => void;
      bodyDescription?: string;
    };
    onClose: () => void;
  }
) {
  const {t} = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      color='warning'
      onClose={onClose}
      open={dialogState.open}
      actions={{
        align  : 'center',
        actions: [
          {
            onClick: onClose,
            color  : 'primary',
            variant: 'outlined',
            label  : t('buttons.cancel'),
          },
          {
            loading,
            color   : 'primary',
            variant : 'contained',
            label   : dialogState.buttonText,
            onClick : dialogState.agreeAction,
            disabled: loading || !!dialogState.timer,
          },
        ],
      }}
      content={{
        color          : 'warning',
        bodyIcon       : WarningIcon,
        bodyTitle      : dialogState.bodyTitle,
        bodyDescription: dialogState.bodyDescription
      }}
      dialogTitle={{
        onClose,
        color: 'warning',
      }}
    />
  )
}