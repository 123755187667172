import React from 'react';
import {Space} from 'antd';
import {useSelector} from 'react-redux';
import {_currentApp} from '../../../../../../../store/selectors/apps';
import {Text} from '../../../../../../../components/antd/Typography';

export function Subheader() {
  const currentApp = useSelector(_currentApp);

  return (
    <Space
      wrap
      size={12}
    >
      <Space
        size={4}
      >
        <Text
          strong
          fontSize='small'
        >UUID:
        </Text>
        <Text
          copyable
          fontSize='small'
        >{currentApp?.uuid}
        </Text>
      </Space>
      <Space
        size={4}
      >
        <Text
          strong
          fontSize='small'
        >Secret:
        </Text>
        <Text
          copyable
          fontSize='small'
        >{currentApp?.secret}
        </Text>
      </Space>
    </Space>
  )
}
