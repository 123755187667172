import {Button} from 'antd';
import {Title} from '../../Typography';
import {getPrefixCls} from '../../../../utils/getPrefixCls';

export function Welcome(
  {
    onClick,
    firstName,
  }: {
    firstName: string;
    onClick: () => void;
  }
) {
  return (
    <div
      className={`${getPrefixCls('survey-welcome')} d-flex flex-column align-items-center`}
    >
      <div
        className={`${getPrefixCls('survey-welcome-textWrapper')} d-flex flex-column`}
      >
        <Title
          fontWeight='bold'
          className={getPrefixCls('survey-welcome-hello-name')}
        >Hello {firstName},
        </Title>
        <div
          className='m-t-7'
        >
          <Title
            level={2}
            className={getPrefixCls('survey-welcome-description')}
          >Let’s personalize your SmartPush experience & <br/>make it perfect to your needs.
          </Title>
        </div>
        <div
          className='m-t-7'
        >
          <Button
            onClick={onClick}
            className={`${getPrefixCls('survey-startButton')} ${getPrefixCls('survey-startButtonAnimation')}`}
          >Let’s do it
          </Button>
        </div>
      </div>
    </div>
  )
}