import React from 'react';
import {useDispatch} from 'react-redux';
import {ButtonProps} from 'antd/lib/button/button';
import EditOutlined from '@ant-design/icons/EditOutlined';
import {ApiKeys} from '../../../../../../../../types/apiKeys';
import {aclKeys} from '../../../../../../../../constants/aclKeys';
import {ModalTypes} from '../../../../../../../../constants/modalTypes';
import {useSmartPush} from '../../../../../../../../hooks/useSmartPush';
import {_dataOptions} from '../../../../../../../../store/actions/apiKeys';
import {TableAction} from '../../../../../../../../components/antd/TableAction';

export function EditAction(
  {
    row,
  }: {
    row: ApiKeys.Entity;
  }
) {
  const {
    user: {
      canUpdate,
    }
  } = useSmartPush();
  const dispatch = useDispatch();

  const buttonProps: ButtonProps = {
    size    : 'small',
    shape   : 'circle',
    type    : 'default',
    onClick : () => {
      dispatch(_dataOptions({
        modal: ModalTypes.EDIT,
        row  : {
          limits_notice: '',
          id           : row.id,
          name         : row.name,
          enabled      : !!row.enabled,
        },
      }));
    },
    icon    : <EditOutlined/>,
    disabled: !canUpdate(aclKeys.API_KEYS),
  };
  const tooltipTitle = canUpdate(aclKeys.API_KEYS) ? 'Edit' : 'You don\'t have access to manage this action';

  return (
    <TableAction
      buttonProps={buttonProps}
      tooltipProps={{title: tooltipTitle}}
    />
  )
}
