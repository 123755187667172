import React from 'react';
import {duration} from 'moment';
import {now} from '../../../../../../../utils';
import {momentTZ} from '../../../../../../../utils';
import {Text} from '../../../../../../../components/antd/Typography';
import DATE_COLUMNS from '../../../../../../../constants/dateColumns';

export function WaitUntil(value: string) {
  const dateFormat = (DATE_COLUMNS.find(dC => dC.column === 'wait_until') || {format: 'YYYY-MM-DD hh:mm:ss A'}).format;
  const newValue = momentTZ(value);
  const diff = newValue.isValid() ? duration(newValue.diff(now())).humanize(true) : null;

  return (
    <>
      {!value && (typeof value !== 'string') ? (
        '-'
      ) : (
        <Text
          style={{whiteSpace: 'nowrap'}}
        ><Text strong>{newValue.format(dateFormat)}</Text> {diff && `(${diff})`}
        </Text>
      )}
    </>
  )
}