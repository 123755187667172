import {CancelToken} from 'axios';
import {_dataOptions} from './index';
import {stringify} from 'querystring';
import {filterObject} from '../../../utils';
import {_setTemplateDetails} from './index';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';
import {_detailsPageNumber} from '../../selectors/templates';
import {_detailsSorterField} from '../../selectors/templates';
import {_detailsSorterOrder} from '../../selectors/templates';

export const _getDetails = (appUuid: string, id: string, {
  cancelToken,
}: {
  cancelToken?: CancelToken;
} = {}): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    fetchingTemplateDetailsState: FiniteStates.LOADING,
  }));

  const pageNumber = _detailsPageNumber(getState());
  const sorterField = _detailsSorterField(getState());
  const sorterOrder = _detailsSorterOrder(getState());

  let sortName = 'data->wait_until';
  switch (sorterField) {
    case 'ctr': {
      sortName = 'ctr_get';
      break;
    }
  }

  const params = stringify(filterObject({
    sortName,
    pageNumber,
    sortOrder: (sorterOrder === 'ascend') ? 'asc' : 'desc',
  }));

  const {
    hasError,
    body: {
      data,
    },
  } = await api.get(`${urlHelper(ApiRoutes.GET_TEMPLATE_DETAILS, {
    id,
    appUuid,
  })}?${params}`, {
    cancelToken,
  });

  if (!hasError) {
    const {
      rows,
    } = data;
    dispatch(_setTemplateDetails(rows));

    dispatch(_dataOptions({
      fetchingTemplateDetailsState: FiniteStates.SUCCESS,
    }));
  } else {
    dispatch(_dataOptions({
      fetchingTemplateDetailsState: FiniteStates.FAILURE,
    }));
  }
};