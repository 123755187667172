import React from 'react';
import {Button} from 'antd';
import {useWatch} from 'react-hook-form';
import {IRegisterForm} from '../../useRegister';
import {Control} from 'react-hook-form/dist/types/form';
import {dataPrefixes} from '../../../../../../../constants';

const {
  auth: {
    register: registerPrefix
  }
} = dataPrefixes;

export function SubmitButton(
  {
    control,
    loading,
  }: {
    loading: boolean;
    control: Control<IRegisterForm>,
  }
) {
  const email = useWatch({
    control,
    name: 'email',
  });
  const password = useWatch({
    control,
    name: 'password',
  });
  const firstName = useWatch({
    control,
    name: 'first_name',
  });
  const lastName = useWatch({
    control,
    name: 'last_name',
  });
  const disabled = !(password && email?.trim() && firstName?.trim() && lastName?.trim());

  return (
    <Button
      block
      size='large'
      type='primary'
      htmlType='submit'
      loading={loading}
      disabled={disabled}
      data-qa={`${registerPrefix}-register-button`}
    >Sign Up
    </Button>
  )
}
