import {ActionTypes} from '../../../../constants/users';
import {ActionTypes as CoreActionTypes} from '../../../../constants/application';

const InitialState: any = {
  byId        : {},
  allIds      : [],
  loading     : false,
  errors      : void 0,
  entity      : void 0,
  dataOptions : void 0,
  permissions : void 0,
  actionDialog: void 0,
};

export default (state: any = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_USERS_DATA_OPTIONS: {
      return {
        ...state,
        dataOptions: {
          ...state.dataOptions,
          ...payload
        },
      };
    }
    case ActionTypes.WEB_NOTIFY_USERS_TOGGLE_PERMISSIONS: {
      return {
        ...state,
        permissions: payload
      };
    }
    case ActionTypes.WEB_NOTIFY_USERS_SUBSCRIPTION_PLANS: {
      return {
        ...state,
        subscriptionPlans: payload
      };
    }
    case ActionTypes.WEB_NOTIFY_USERS_FEATURE_GROUPS: {
      const t: any = (payload as Array<any>).reduce((acc: any, current: any) => {
        acc[current.slug] = current;
        return acc;
      }, {});

      return {
        ...state,
        featureGroups: t,
        groups       : payload,
      };
    }
    case ActionTypes.WEB_NOTIFY_USERS_TOGGLE_DIALOG: {
      return {
        ...state,
        actionDialog: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_USERS_TOGGLE_ERRORS: {
      return {
        ...state,
        errors: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_USERS_UPDATE_ERRORS: {
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_USERS_TOGGLE_ENTITY: {
      return {
        ...state,
        entity: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_USERS_UPDATE_ENTITY: {
      return {
        ...state,
        entity: {
          ...state.entity,
          ...payload
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_USERS_TOGGLE_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case CoreActionTypes.LOGOUT: {
      return InitialState
    }
    default:
      return state;
  }
};
