import React from 'react';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import {formatDate} from '../../../../../utils';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {Button} from '../../../../../components/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import customRanges from '../../../../../utils/customRanges';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {optionsAction} from '../../../../../store/actions/campaigns';
import {DateRangePicker} from '../../../../../components/DateRangePicker';

const useStyles = makeStyles((theme: Theme) => ({
  btnRoot: {
    width                         : 220,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    }
  }
}));

const getInputValue = (value?: string): string => (value === undefined ? '' : value);

export function ToolbarRightActions() {
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState<any>('');
  const [error, setError] = React.useState<string | undefined>(void 0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectionRange, setSelectionRange] = React.useState({
    endDate  : new Date(),
    key      : 'selection',
    startDate: new Date((new Date()).setDate(new Date().getDate() - 7)),
  });

  React.useEffect(() => {
    setValue((`${(formatDate(selectionRange.startDate))} - ${formatDate(selectionRange.endDate)}`) || '');
    // eslint-disable-next-line
  }, [
    dispatch,
  ]);

  const handleClose = () => {
    setAnchorEl(null);
    setError(void 0);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event);
  };

  const dateRangePickerOnChange = React.useCallback((range: any) => {
    const endDate: moment.Moment = moment(formatDate(new Date(range.selection.endDate)));
    const startDate: moment.Moment = moment(formatDate(new Date(range.selection.startDate)));
    setError('');
    if (endDate.diff(moment(), 'days') > 0) {
      setError('Maximum date is today');
      return;
    }
    if (startDate.diff(moment(), 'days') > 0) {
      setError('Maximum date is today');
      return;
    }
    if (endDate.diff(startDate, 'days') > 30) {
      setError('Date range must be within 30 days');
      return;
    }

    setSelectionRange(range.selection);
  }, []);

  return (
    <>
      <OutlinedInput
        fullWidth
        margin='dense'
        className={classes.btnRoot}
        value={getInputValue(value)}
        inputProps={{style: {textAlign: 'center'}}}
        onClick={(e: any) => handleClick(e.currentTarget)}
      />
      <Menu
        elevation={0}
        PaperProps={{
          style: {
            borderRadius: 0,
            border      : '1px solid #F5F5F5',
          }
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical  : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical  : 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          button={false}
          component='nav'
          style={{
            display      : 'flex',
            outline      : 'none',
            flexDirection: 'column',
          }}
        >
          <DateRangePicker
            ranges={[selectionRange]}
            staticRanges={customRanges(t)}
            onChange={dateRangePickerOnChange}
            maxDate={(new Date(Date.now())) as any}
          />
          <Box
            width='100%'
          >
            {!!error && (
              <Typography
                color='error'
                style={{margin: 8, fontSize: 12}}
              >{error}
              </Typography>
            )}
            <Box
              width={100}
              marginLeft='auto'
            >
              <Button
                fullWidth
                size='small'
                color='primary'
                variant='contained'
                onClick={() => {
                  setAnchorEl(null);
                  setValue((`${(formatDate(selectionRange.startDate))} - ${formatDate(selectionRange.endDate)}`) || '');
                  dispatch(optionsAction({
                    pageNumber: 1,
                    searchText: void 0,
                    to        : formatDate(selectionRange.endDate),
                    from      : formatDate(selectionRange.startDate),
                  }))
                }}
              >{t('buttons.apply')}
              </Button>
            </Box>
          </Box>
        </MenuItem>
      </Menu>
    </>
  )
}
