import clsx from 'clsx';
import React from 'react';
import Image from 'material-ui-image';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  selected: {
    transform: 'scale(.8)',
  },
}));

export default function Img(
  {
    selected,
    thumbnail,
  }: {
    thumbnail: string;
    selected: string | undefined;
  }
) {
  const classes = useStyles();

  return (
    <Image
      style={{
        padding: 0,
        margin : 8,
        width  : 200,
        height : 180,
      }}
      disableSpinner
      src={thumbnail}
      disableTransition
      imageStyle={{
        objectFit: 'cover',
      }}
      className={clsx((String(selected).includes(thumbnail)) && classes.selected)}
    />
  )
}
