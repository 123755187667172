import React from 'react';
import {useSelector} from 'react-redux';
import {_searchText} from '../../../../../../store/selectors/templates';

export function EmptyTextDescription() {
  const searchText = useSelector(_searchText);

  return !!searchText ? (
    <span>No templates found.</span>
  ) : (
    <>
      <span>You do not have any templates.</span>
      <br/>
      <span>Why don't you go ahead and create a new one by clicking on the Create button above?</span>
    </>
  )
}