import React from 'react';
import Dialog from '../Dialog';
import Gallery from '../Gallery';
import {useTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {

  }
}));

export function PhotoGalleryDialog(
  {
    open,
    onClose,
    selected,
    setSelected,
    aspectRatio,
  }: {
    onClose: any,
    selected: any,
    open?: boolean,
    setSelected: any,
    aspectRatio: number,
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth='lg'
      scroll='paper'
      PaperProps={{style: {backgroundColor: '#fafafa'}}}
      content={{
        children: (
          <Typography
            component='div'
            className={classes.root}
          >
            <Gallery
              onClose={onClose}
              selected={selected}
              onClick={setSelected}
              aspectRatio={aspectRatio}
            />
          </Typography>
        )
      }}
      onClose={onClose}
      actions={{
        align  : 'left',
        actions: [
          {
            size   : 'small',
            onClick: onClose,
            color  : 'primary',
            variant: 'outlined',
            label  : t('buttons.close'),
          },
        ]
      }}
      dialogTitle={{
        onClose : onClose,
        color   : 'primary',
        children: t('upload_new_image'),
      }}
    />
  )
}
