import React from 'react';
import {Popover} from 'antd';
import {useSelector} from 'react-redux';
import {Segments} from '../../../../../../types/segments';
import {Text} from '../../../../../../components/antd/Typography';
import {_hasConversionsAccess} from '../../../../../../store/selectors/application/acl';

export function Description({record}: { record: Segments.Entity }) {
  switch (record.type) {
    /** Opened */
    case 1: {
      return (
        <Text
        >Total users to whom notifications were displayed.
        </Text>
      )
    }
    /** Clicked */
    case 2: {
      return (
        <Text
        >Total users who clicked on notification or action buttons at least once.
        </Text>
      )
    }
    /** Closes */
    case 3: {
      return (
        <Text
        >Total users who clicked on notification close button at least once.
        </Text>
      )
    }
    /** Not opened */
    case 4: {
      return (
        <Text
        >Total users who never received a notification.
        </Text>
      )
    }
    /** Not clicked */
    case 5: {
      return (
        <Text
        >Total users who received notifications but never clicked on it.
        </Text>
      )
    }
    default: {
      return (
        <Params
          record={record}
        />
      )
    }
  }
}

function Params(
  {
    record,
  }: {
    record: Segments.Entity;
  }
) {
  const hasConversionsAccess = useSelector(_hasConversionsAccess);

  const query: {
    type: string;
    table: string;
    filter: string;
    values: string[] | number[];
    labels: Record<string, any>;
  }[] = React.useMemo(() => {
    try {
      if (Array.isArray(record.query)) {
        return record.query;
      } else if (record.query && typeof record.query === 'string') {
        return JSON.parse(record.query)
      }
    } catch (e) {
      console.error(e);
      return [];
    }
  }, [
    record,
  ]);

  const elem = React.useMemo(() => {
    let elems: React.ReactElement[] = [];

    if (Array.isArray(query)) {
      elems = query.map((q: {
        type: string;
        table: string;
        filter: string;
        values: string[] | number[];
        labels: Record<string, any>;
      }) => {
        if (
          q &&
          q.labels &&
          q.labels.filter &&
          q.labels.params &&
          q.labels.options
        ) {
          const {
            labels: {
              filter,
              params,
              options
            }
          } = q;

          let option: any;
          if (Array.isArray(options)) {
            option = options.map((item: any) => {
              if (typeof item === 'string') {
                return item
              } else if (typeof item === 'number') {
                return item
              } else {
                return item && item.label
              }
            }).join(', ')
          } else {
            option = options.label || options
          }

          if (params && params.label && filter && filter.label && option) {
            if (!hasConversionsAccess) {
              if ([
                'conversion_sum',
                'conversion_count',
                'last_converted_at',
              ].includes(q.type)) {
                return null
              }
            }

            return (
              <Text
              >{`${params.label} ${filtersMapping[filter.label] || filter.label} ${option}`}
              </Text>
            )
          }

          return void 0;
        }

        return void 0;
      })
    }

    return elems.filter((e) => !!e);
  }, [
    query,
    hasConversionsAccess,
  ]);

  const content = (
    <ul>
      {elem.map((item: React.ReactElement, index: number) => {
        return item && (
          <li
            key={`Table.Cell_Description_In_Tooltip_${index}`}
          >{item}
          </li>
        )
      })}
    </ul>
  );

  return (
    <Popover
      content={content}
      visible={elem.length <= 3 ? false : void 0}
    >
      <ul
        style={{paddingLeft: '1.5rem'}}
      >
        {elem.map((item: Record<string, any>, index: number) => {
          return item && index < 3 && (
            <li
              key={`Table.Cell_Description_Without_Tooltip${index}`}
            >{item}
            </li>
          )
        })}
      </ul>
    </Popover>
  );
}

const filtersMapping: Record<string, string> = {
  in       : 'in',
  equal    : 'equal',
  not_in   : 'not in',
  not_equal: 'not equal',
};
