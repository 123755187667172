import React from 'react';
import {Result} from 'antd';

export function Redirecting(
  {
    title,
    subTitle,
  }: {
    title: React.ReactNode;
    subTitle?: React.ReactNode;
  }
) {
  return (
    <Result
      title={title}
      status='success'
      subTitle={subTitle}
      className='redirecting-container p-0'
    />
  )
}