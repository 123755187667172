import React from 'react';
import {Button} from 'antd';
import {useWatch} from 'react-hook-form';
import {Control} from 'react-hook-form/dist/types/form';
import {dataPrefixes} from '../../../../../../constants';
import {IForgotPasswordForm} from '../../useForgotPassword';

const {auth: {forgotPassword}} = dataPrefixes;

export function SubmitButton(
  {
    control,
    disabled,
    loading,
  }: {
    loading: boolean;
    disabled?: boolean;
    control: Control<IForgotPasswordForm>,
  }
) {
  const email = useWatch({
    control,
    name: 'email',
  });

  return (
    <Button
      block
      size='large'
      type='primary'
      htmlType='submit'
      loading={loading}
      disabled={!email?.trim() || disabled}
      data-qa={`${forgotPassword}-reset-password-button`}
    >Reset Password
    </Button>
  )
}
