import React from 'react';
import {Result} from 'antd';
import {Button} from 'antd';
import {parse} from 'querystring';
import {ucFirst} from '../../utils';
import {useStore} from 'react-redux';
import {momentUTC} from '../../utils';
import {stringify} from 'querystring';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router';
import {Redirect} from 'react-router-dom';
import {isMobile} from '../../utils/isMobile';
import {dataLayer} from '../../utils/dataLayer';
import {tidioChat} from '../../utils/tidioChatApi';
import {ApiRoutes} from '../../constants/apiRoutes';
import {AppRoutes} from '../../constants/appRoutes';
import {Link} from '../../components/antd/Typography';
import {_fetch} from '../../store/actions/apps/fetch';
import {_appsLength} from '../../store/selectors/apps';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {_apiBaseUrl} from '../../store/selectors/application';
import {ActionTypes} from '../../store/constants/application';
import {RecaptchaActions} from '../../constants/recaptchaActions';
import {_isAuthenticated} from '../../store/selectors/application';

export function OAuthGoogle(): JSX.Element | null {
  const {push} = useHistory();
  const {getState} = useStore();
  const {search} = useLocation();
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(_apiBaseUrl);
  const {executeRecaptcha} = useGoogleReCaptcha();
  const searchObject = parse(search.replace('?', ''));
  const authenticated = useSelector(_isAuthenticated);
  const [response, setResponse] = React.useState<{ message: string; status: number } | null>(null);

  React.useEffect(() => {
    (async () => {
      if (searchObject?.code && (typeof executeRecaptcha === 'function')) {
        let plan = '';
        let searchString = '';
        let action: 'gmail_login' | 'gmail_registration' = 'gmail_login';

        if (typeof searchObject.state === 'string') {
          const stateAsArray = searchObject.state.split(',');

          if (stateAsArray?.[0]) {
            plan = stateAsArray[0];
          }

          if (stateAsArray[2]) {
            searchString = decodeURIComponent(stateAsArray[2])
          }
        }

        const params = stringify({
          plan                  : plan,
          provider              : 'google',
          referrer_params       : searchString,
          code                  : searchObject?.code,
          'g-recaptcha-response': await executeRecaptcha(RecaptchaActions.REGISTER),
        });

        const res = await fetch(`${apiBaseUrl}${ApiRoutes.OAUTH_COMPLETE}?${params}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (res.ok) {
          const {data} = await res.json();
          localStorage.setItem('access_token', data.access_token);

          let userRes: any = await fetch(`${apiBaseUrl}${ApiRoutes.ME}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization : `Bearer ${data.access_token}`,
            },
          });
          userRes = await userRes.json();
          const registered = momentUTC().diff(momentUTC(userRes.data.user.created_at), 'minute') <= 2;
          if (registered) {
            action = 'gmail_registration';
          }

          dataLayer({
            event               : action,
            authenticationMethod: userRes?.data?.user?.email || 'email',
          });
          await dispatch(_fetch());

          const mobile = isMobile();
          const appsLength = _appsLength(getState());

          if (mobile && !!appsLength) {
            dispatch({
              payload: isMobile(),
              type   : ActionTypes.IS_MOBILE,
            });
          }

          dispatch({
            payload: {
              authenticated: true,
              accessToken  : data.access_token,
            },
            type   : ActionTypes.AUTHENTICATE,
          });
        } else {
          const data = await res.json();
          if (typeof data?.error === 'string') {
            setResponse({
              status : 422,
              message: data?.error,
            });
          } else if (data?.message) {
            setResponse({
              status : 401,
              message: data?.message,
            });
          }
        }
      }
    })().catch(console.error);
  }, [
    getState,
    dispatch,
    apiBaseUrl,
    executeRecaptcha,
    searchObject?.code,
    searchObject?.state,
  ]);

  if (authenticated) {
    return (
      <Redirect
        to='/'
      />
    )
  }

  if (response?.message) {
    const splitMessage = response.message.toLowerCase().split('contact us');

    if (splitMessage.length === 2) {
      return (
        <Result
          status='error'
          subTitle={<>{ucFirst(splitMessage[0])}<Link style={{whiteSpace: 'nowrap'}} underline onClick={() => tidioChat().popUpOpen()} target='_blank'>Contact us</Link> {splitMessage[1]}</>}
          extra={
            <Button
              type='primary'
              onClick={() => push(AppRoutes.LOGIN)}
            >Back to Sign In
            </Button>
          }
        />
      )
    } else {
      return (
        <Result
          status='error'
          subTitle={response.message}
          extra={
            <Button
              type='primary'
              onClick={() => push(AppRoutes.LOGIN)}
            >Back to Sign In
            </Button>
          }
        />
      )
    }
  }

  return null;
}
