import React from 'react';
import {Card} from 'antd';
import {Form} from 'antd';
import {Input} from 'antd';
import {Modal} from 'antd';
import {Button} from 'antd';
import {Result} from 'antd';
import {Dispatch} from 'react';
import Icon from '@ant-design/icons';
import {SetStateAction} from 'react';
import {useParams} from 'react-router';
import {MutableRefObject} from 'react';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {momentUTC} from '../../../../../../../utils';
import {urlHelper} from '../../../../../../../utils/urlHelper';
import {surveyState} from '../../../../../../../constants/survey';
import {AppRoutes} from '../../../../../../../constants/appRoutes';
import {_dataOptions} from '../../../../../../../store/actions/apps';
import {Text} from '../../../../../../../components/antd/Typography';
import {isValidEmail} from '../../../../../../../utils/isValidEmail';
import {getPrefixCls} from '../../../../../../../utils/getPrefixCls';
import {_emailError} from '../../../../../../../store/selectors/apps';
import {Title} from '../../../../../../../components/antd/Typography';
import {FiniteStates} from '../../../../../../../constants/finiteStates';
import {surveyIsEnabled} from '../../../../../../../utils/surveyIsEnabled';
import {_user} from '../../../../../../../store/selectors/application/acl';
import {_sendingToDeveloper} from '../../../../../../../store/selectors/apps';
import {SHOW_SURVEY_AFTER_REGISTERED_AT} from '../../../../../../../constants';
import {_surveyData} from '../../../../../../../store/selectors/application/acl';
import {_showPlanChange} from '../../../../../../../store/selectors/application/acl';
import {_storeSurvey} from '../../../../../../../store/actions/application/storeSurvey';
import {_sendToDeveloper} from '../../../../../../../store/actions/apps/sendToDeveloper';
import './index.less';

function Coding() {
  return (
    <svg
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        clipPath='url(#clip0_5182_53724)'
      >
        <path
          fill='url(#paint0_linear_5182_53724)'
          d='M35 1.66666H5C2.24333 1.66666 0 3.90999 0 6.66666V33.3333C0 36.09 2.24333 38.3333 5 38.3333H35C37.7567 38.3333 40 36.09 40 33.3333V6.66666C40 3.90999 37.7567 1.66666 35 1.66666Z'
        />
        <path
          fill='white'
          d='M35.0002 35H5.00016C4.08183 35 3.3335 34.2533 3.3335 33.3333V10H36.6668V33.3333C36.6668 34.2533 35.9185 35 35.0002 35Z'
        />
        <path
          fill='#096DD9'
          d='M25.4167 27.5C25.1284 27.5 24.8401 27.4017 24.6034 27.1984C24.0801 26.7484 24.0184 25.96 24.4684 25.435L26.2701 23.3334L24.4667 21.23C24.0167 20.7067 24.0784 19.9167 24.6017 19.4667C25.1284 19.0167 25.9167 19.0784 26.3651 19.6017L28.8651 22.5184C29.2667 22.9867 29.2667 23.6767 28.8651 24.1467L26.3651 27.0634C26.1184 27.3517 25.7684 27.5 25.4167 27.5Z'
        />
        <path
          fill='#096DD9'
          d='M14.5833 27.5C14.2316 27.5 13.8816 27.3517 13.6333 27.0633L11.1333 24.1467C10.7316 23.6783 10.7316 22.9883 11.1333 22.5183L13.6333 19.6017C14.0833 19.0783 14.8716 19.015 15.3966 19.4667C15.9199 19.9167 15.9816 20.705 15.5316 21.23L13.7299 23.3333L15.5333 25.4367C15.9833 25.96 15.9216 26.75 15.3983 27.2C15.1616 27.4 14.8716 27.5 14.5833 27.5Z'
        />
        <path
          fill='#096DD9'
          d='M18.47 29.9684C17.7967 29.8134 17.3783 29.1417 17.5317 28.47L20.0317 17.6367C20.1867 16.965 20.8583 16.55 21.53 16.6983C22.2033 16.8533 22.6217 17.525 22.4683 18.1967L19.9683 29.03C19.8167 29.69 19.16 30.1234 18.47 29.9684Z'
        />
      </g>
      <defs>
        <linearGradient
          y1='2.08079'
          x2='28.2797'
          y2='44.2039'
          x1='3.84624e-07'
          id='paint0_linear_5182_53724'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#02B1FF'
          />
          <stop
            offset='1'
            stopColor='#096DD9'
          />
          <stop
            offset='1'
            stopColor='#096DD9'
          />
        </linearGradient>
        <clipPath
          id='clip0_5182_53724'
        >
          <rect
            width='40'
            height='40'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>

  )
}

function Arroba() {
  return (
    <svg
      width='40'
      fill='none'
      height='40'
      viewBox='0 0 40 40'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill='url(#paint0_linear_5182_53736)'
        d='M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z'
      />
      <path
        fill='#FAFAFA'
        d='M21.6575 7.605C17.5675 7.0875 13.625 8.495 10.835 11.4975C8.05002 14.5 6.93252 18.56 7.77002 22.64C8.74252 27.365 12.3975 31.105 17.0825 32.17C18.0625 32.395 19.0425 32.505 20.01 32.505C21.9325 32.505 23.8075 32.0675 25.5425 31.2075C26.16 30.9 26.4125 30.15 26.1075 29.53C25.7975 28.91 25.0525 28.6625 24.43 28.965C22.3425 30.0025 19.9925 30.2675 17.6375 29.7325C13.9625 28.8975 10.9825 25.8425 10.22 22.1375C9.54502 18.8575 10.44 15.6 12.6675 13.1975C14.8975 10.7975 18.055 9.665 21.3375 10.085C26.2775 10.725 30 15.21 30 20.5225V21.25C30 22.63 28.88 23.75 27.5 23.75C26.12 23.75 25 22.63 25 21.25V16.25C25 15.56 24.44 15 23.75 15C23.2375 15 22.8 15.31 22.6075 15.75C21.8625 15.28 20.9875 15 20 15C17.055 15 15 17.3125 15 20.625C15 23.9375 17.055 26.25 20 26.25C21.5525 26.25 22.845 25.595 23.7325 24.5C24.65 25.5625 25.99 26.25 27.5 26.25C30.2575 26.25 32.5 24.0075 32.5 21.25V20.5225C32.5 13.9575 27.84 8.405 21.6575 7.605ZM20 23.75C18.4575 23.75 17.5 22.5525 17.5 20.625C17.5 18.6975 18.4575 17.5 20 17.5C21.5425 17.5 22.5 18.6975 22.5 20.625C22.5 22.5525 21.5425 23.75 20 23.75Z'
      />
      <defs>
        <linearGradient
          x2='31.7784'
          y2='43.8418'
          y1='0.451786'
          x1='3.84625e-07'
          id='paint0_linear_5182_53736'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#02B1FF'
          />
          <stop
            offset='1'
            stopColor='#096DD9'
          />
          <stop
            offset='1'
            stopColor='#096DD9'
          />
        </linearGradient>
      </defs>
    </svg>
  )
}

function SendInstructionsForm(
  {
    fireIntegrationSkipped,
  }: {
    fireIntegrationSkipped: MutableRefObject<boolean>;
  }
) {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(_user);
  const error = useSelector(_emailError);
  const surveyData = useSelector(_surveyData);
  const {appUuid} = useParams<{ appUuid: string }>();
  const showPlanChange = useSelector(_showPlanChange);
  const sendingToDeveloper = useSelector(_sendingToDeveloper);
  const [email, setEmail] = React.useState<string | null>(null);
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const diff = momentUTC(SHOW_SURVEY_AFTER_REGISTERED_AT) < momentUTC(user.created_at);

  function send() {
    dispatch(_sendToDeveloper({
      email,
      appUuid,
      fireIntegrationSkipped,
      onSuccess: () => {
        const modal = Modal.info({
          title     : '',
          width     : 480,
          icon      : null,
          okCancel  : false,
          className : getPrefixCls('integration-options-success-modal'),
          afterClose: async () => {
            dispatch(_dataOptions({
              emailError        : void 0,
              sendingToDeveloper: FiniteStates.IDLE,
            }));
            if (showPlanChange) {
              history.push(urlHelper(AppRoutes.APPS_PAY_AND_LAUNCH_APP, {appUuid}));
            } else {
              if (surveyIsEnabled() && !surveyData.state && diff) {
                await dispatch(_storeSurvey({
                  key  : 'state',
                  value: surveyState.STARTED,
                }));
              } else {
                history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid}));
              }
            }
          },
          content   : (
            <Result
              status='success'
              subTitle='Redirecting...'
              title='Instructions sent to your developer'
            />
          ),
        });
        setTimeout(() => {
          modal.destroy();
        }, 1000);
      },
    }))
  }

  function handleOnBlur(e: React.ChangeEvent<HTMLInputElement>) {
    if (!!e.target.value && !isValidEmail(e.target.value)) {
      dispatch(_dataOptions({
        emailError: 'Please enter a valid email address',
      }));
    } else {
      dispatch(_dataOptions({
        emailError: void 0,
      }));
    }
  }

  function onPressEnter(e: React.KeyboardEvent<HTMLInputElement>) {
    e.preventDefault();
    if (!isValidEmail(e.currentTarget.value)) {
      dispatch(_dataOptions({
        emailError: 'Please enter a valid email address',
      }));
    } else {
      dispatch(_dataOptions({
        emailError: void 0,
      }));
      send();
    }
  }

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (error) {
      dispatch(_dataOptions({
        emailError: void 0,
      }));
    }
    if (isValidEmail(e.target.value)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setEmail(e.target.value);
  }

  React.useEffect(() => {
    return function () {
      dispatch(_dataOptions({
        emailError: void 0,
      }));
    }
  }, [
    dispatch,
  ]);

  return (
    <Form
      component='div'
      style={{textAlign: 'left'}}
    >
      <Form.Item
        name='email'
        help={error}
        className='m-b-4'
        validateStatus={!!error ? 'error' : void 0}
      >
        <Input
          size='large'
          type='email'
          value={email}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onPressEnter={onPressEnter}
          placeholder='Email of your developer'
        />
      </Form.Item>
      <Button
        block
        ghost
        size='large'
        type='primary'
        onClick={send}
        htmlType='button'
        disabled={disabled}
        data-qa='send-to-developer-button'
        loading={sendingToDeveloper === FiniteStates.LOADING}
      >Send
      </Button>
    </Form>
  )
}

export function IntegrationOptions(
  {
    stepsIsVisible,
    toggleStepsVisibility,
    fireIntegrationSkipped,
  }: {
    stepsIsVisible: boolean;
    fireIntegrationSkipped: MutableRefObject<boolean>;
    toggleStepsVisibility: Dispatch<SetStateAction<boolean>>;
  }
) {
  return (
    <div
      className='d-flex align-items-center flex-column m-b-2'
    >
      <div
        className={getPrefixCls('integration-options-container')}
      >
        <Card
          bordered={false}
          className={getPrefixCls('integration-options-container-box')}
        >
          <Icon
            className='m-b-4'
            component={Coding}
          />
          <div
            className='m-b-4'
          >
            <Title
              level={4}
            >I’ll do it myself
            </Title>
          </div>
          <div>
            <Text
              type='secondary'
            >Do the SmartPush integration yourself.<br/>It takes minutes to finish!
            </Text>
          </div>
          <Button
            block
            size='large'
            type='primary'
            ghost={!stepsIsVisible}
            onClick={() => toggleStepsVisibility(prev => !prev)}
            className={getPrefixCls('integration-options-show-steps-button')}
          >Show Me The Steps!
          </Button>
        </Card>
        <Card
          bordered={false}
          className={getPrefixCls('integration-options-container-box')}
        >
          <Icon
            className='m-b-4'
            component={Arroba}
          />
          <div
            className='m-b-4'
          >
            <Title
              level={4}
            >Send instructions to my<br/>developer
            </Title>
          </div>
          <SendInstructionsForm
            fireIntegrationSkipped={fireIntegrationSkipped}
          />
        </Card>
      </div>
    </div>
  )
}
