import React from 'react';
import {Card} from 'antd';
import {Spin} from 'antd';
import {Space} from 'antd';
import {Tooltip} from 'antd';
import {useCard} from './useCard';
import {RenderFunction} from 'antd/lib/tooltip';
import {nFormatter} from '../../../../../../utils';
import {formatWithCommas} from '../../../../../../utils';
import {ANIMATION_DELAY} from '../../../../../../constants';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import {Text} from '../../../../../../components/antd/Typography';
import {getPrefixCls} from '../../../../../../utils/getPrefixCls';
import {Title} from '../../../../../../components/antd/Typography';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import './index.less';

const bodyStyle: React.CSSProperties = {
  padding: '1.2rem',
};

export function Cards() {
  const {
    total,
    active,
    loading,
    subscribed,
    limitPassed,
    unsubscribed,
  } = useCard()

  return (
    <Space
      wrap
      size={16}
      className='d-flex align-items-center justify-content-center'
    >
      <Spin
        spinning={loading}
        delay={ANIMATION_DELAY}
      >
        <Card
          bodyStyle={bodyStyle}
          className={getPrefixCls('reports-widgets')}
        >
          <Title
            level={4}
            fontWeight='bold'
            className={`d-flex ${getPrefixCls('reports-widgets-totalColor')}`}
          >
            <span>Total</span>
            <Helper
              warning={limitPassed}
              title='Amount of subscriptions during the lifetime of the app. Some of these subscribers might have already unsubscribed, some of them are  active.The metric shows the total number of subscription events.'
            />
          </Title>
          <div
            className='m-t-2 d-flex justify-content-between'
          >
            <Tooltip
              title={formatWithCommas(total)}
            >
              <Title
                level={3}
                fontWeight='bold'
                className={`d-flex ${getPrefixCls('reports-widgets-totalColor')}`}
              >{nFormatter(total, 1)}
              </Title>
            </Tooltip>
            <GraphSVG
              color1='#0DCFF9'
              color2='#BCF3FF'
            />
          </div>
        </Card>
      </Spin>
      <Spin
        spinning={loading}
        delay={ANIMATION_DELAY}
      >
        <Card
          bodyStyle={bodyStyle}
          className={getPrefixCls('reports-widgets')}
        >
          <Title
            level={4}
            fontWeight='bold'
            className={`d-flex ${getPrefixCls('reports-widgets-subscribedColor')}`}
          >
            <span>Subscribed</span>
            <Helper
              warning={limitPassed}
              title='Number of users who are currently subscribed.'
            />
          </Title>
          <div
            className='m-t-2 d-flex justify-content-between'
          >
            <Tooltip
              title={formatWithCommas(subscribed)}
            >
              <Title
                level={3}
                fontWeight='bold'
                className={`d-flex ${getPrefixCls('reports-widgets-subscribedColor')}`}
              >{nFormatter(subscribed, 1)}
              </Title>
            </Tooltip>
            <GraphSVG
              color1='#2CBD95'
              color2='#AAE5D4'
            />
          </div>
        </Card>
      </Spin>
      <Spin
        spinning={loading}
        delay={ANIMATION_DELAY}
      >
        <Card
          bodyStyle={bodyStyle}
          className={getPrefixCls('reports-widgets')}
        >
          <Title
            level={4}
            fontWeight='bold'
            className={`d-flex ${getPrefixCls('reports-widgets-unsubscribedColor')}`}
          >
            <span>Unsubscribed</span>
            <Helper
              warning={limitPassed}
              title='Number of users who have unsubscribed during the life of the app. Unsubscribed = Total - Subscribed'
            />
          </Title>
          <div
            className='m-t-2 d-flex justify-content-between'
          >
            <Tooltip
              title={formatWithCommas(unsubscribed)}
            >
              <Title
                level={3}
                fontWeight='bold'
                className={`d-flex ${getPrefixCls('reports-widgets-unsubscribedColor')}`}
              >{nFormatter(unsubscribed, 1)}
              </Title>
            </Tooltip>
            <GraphSVG
              color1='#E83F4B'
              color2='#FFA5AB'
            />
          </div>
        </Card>
      </Spin>
      <Spin
        spinning={loading}
        delay={ANIMATION_DELAY}
      >
        <Card
          bodyStyle={bodyStyle}
          className={getPrefixCls('reports-widgets')}
        >
          <Title
            level={4}
            fontWeight='bold'
            className={`d-flex ${getPrefixCls('reports-widgets-activeColor')}`}
          >
            <span>Active</span>
            <Helper
              title='Number of subscribers, who have received at least one push notification from you during the past 30 calendar days.'
            />
          </Title>
          <div
            className='m-t-2 d-flex justify-content-between'
          >
            <Tooltip
              title={formatWithCommas(active)}
            >
              <Title
                level={4}
                fontWeight='bold'
                className={`d-flex ${getPrefixCls('reports-widgets-activeColor')}`}
              >{nFormatter(active, 1)}
              </Title>
            </Tooltip>
            <GraphSVG
              color1='#C400BC'
              color2='#E69BE3'
            />
          </div>
        </Card>
      </Spin>
    </Space>
  )
}

function Helper(
  {
    title,
    warning,
  }: {
    warning?: boolean;
    title: React.ReactNode | RenderFunction
  }
) {
  return (
    <Tooltip
      title={warning ? "This metric is calculated using the total number of subscribers, including those over your limit." : title}
    >
      <Text
        fontSize='small'
        className='d-flex'
        type={!!warning ? 'warning' : 'secondary'}
      >
        {!!warning ? (
          <WarningOutlined
            className='m-l-1'
          />
        ) : (
          <InfoCircleOutlined
            className='m-l-1'
          />
        )}
      </Text>
    </Tooltip>
  )
}

function GraphSVG(
  {
    color1,
    color2,
  }: {
    color1: string;
    color2: string;
  }
) {
  return (
    <svg
      width='23'
      height='27'
      viewBox='0 0 23 27'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        transform='translate(-760.881 -138)'
      >
        <rect
          rx='1.5'
          width='3'
          height='21'
          fill={color1}
          transform='translate(770.881 144)'
        />
        <rect
          rx='1.5'
          width='3'
          height='27'
          fill={color1}
          transform='translate(780.881 138)'
        />
        <rect
          rx='1.5'
          width='3'
          height='11'
          fill={color2}
          transform='translate(770.881 154)'
        />
        <rect
          rx='1.5'
          width='3'
          height='18'
          fill={color2}
          transform='translate(780.881 147)'
        />
        <rect
          width='3'
          rx='1.5'
          height='15'
          fill={color1}
          transform='translate(760.881 150)'
        />
        <rect
          width='3'
          rx='1.5'
          height='6'
          fill={color2}
          transform='translate(760.881 159)'
        />
      </g>
    </svg>
  )
}
