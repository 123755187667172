import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {ScrollPlayground} from '../../ScrollPlayground';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useRemoveTabStyles = makeStyles((theme: Theme) => ({
  closeIcon        : {
    fontSize  : 15,
    marginLeft: theme.spacing(1),
    '&:hover' : {
      borderRadius: '50%',
      border      : '1px solid',
    }
  },
  header           : {
    paddingTop: theme.spacing(1),
  },
  actionsTypography: {
    fontSize: 14,
  },
  actionsContainer : {
    display       : 'flex',
    justifyContent: 'center',
    padding       : theme.spacing(1, 2),
  },
  paper            : {
    paddingTop  : theme.spacing(1),
    paddingLeft : theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export function RemoveTab(
  {
    item,
    remove,
  }: {
    item: any;
    remove: () => void;
  }
) {
  const {t} = useTranslation();
  const classes = useRemoveTabStyles();
  const anchorRef = React.useRef<any>(null);
  const [open, setOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setOpen(prevOpen => {
      return !prevOpen
    });
  }, []);

  const handleClose = React.useCallback((event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }, []);

  const handleRemove = React.useCallback((event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
    remove();
  }, [
    remove,
  ]);

  return (
    <Box
      display='flex'
    >
      <CloseIcon
        ref={anchorRef}
        onClick={handleToggle}
        className={classes.closeIcon}
      />
      <ClickAwayListener
        onClickAway={handleClose}
      >
        <div>
          <ScrollPlayground
            open={open}
            transition={true}
            placement='top-start'
            anchorEl={anchorRef.current}
          >
            <Paper
              elevation={3}
              className={classes.paper}
            >
              <Typography
                noWrap
                align='center'
                color='primary'
                className={classes.header}
              >{t('messages.remove_language_country', {language_country: item.text})}
              </Typography>
              <Typography
                component='div'
                className={classes.actionsContainer}
              >
                <MenuItem
                  onClick={handleClose}
                >
                  <Typography
                    noWrap
                    color='primary'
                    variant='inherit'
                    className={classes.actionsTypography}
                  >{t('buttons.cancel')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleRemove}
                >
                  <Typography
                    noWrap
                    color='error'
                    variant='inherit'
                    className={classes.actionsTypography}
                  >{t('buttons.remove')}
                  </Typography>
                </MenuItem>
              </Typography>
            </Paper>
          </ScrollPlayground>
        </div>
      </ClickAwayListener>
    </Box>
  )
}