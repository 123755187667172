import {Row} from 'antd';
import {Col} from 'antd';
import React from 'react';
import {Layout} from 'antd';
import {Toolbar} from '../Toolbar';
import * as Alerts from '../Alerts';
import {getPrefixCls} from '../../../../../utils/getPrefixCls';

export function Content(
  {
    appUuid,
    children,
  }: {
    children: React.ReactNode,
    appUuid: string | undefined;
  }
) {
  return (
    <>
      <Toolbar/>
      <Alerts.ActiveGraceAlert
        appUuid={appUuid}
      />
      <Layout.Content
        className={getPrefixCls('mainLayout-layoutContent')}
      >
        <Row>
          <Col
            span={24}
          >{children}
          </Col>
        </Row>
      </Layout.Content>
    </>
  )
}