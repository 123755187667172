export function tidioChat(): any {
  if ((window as any).tidioChatApi !== undefined) {
    return (window as any).tidioChatApi
  } else {
    return {
      hide          : () => {
      },
      show          : () => {
      },
      track         : () => {
      },
      popUpOpen     : () => {
      },
      setVisitorData: (data: Record<string, any>) => {
      },
    };
  }
}