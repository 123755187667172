import React from 'react';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import Card from '@material-ui/core/Card';
import {useTranslation} from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import {Theme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CardContent from '@material-ui/core/CardContent';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {copyToClipboard} from '../../../../../../../utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {urlHelper} from '../../../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../../../constants/appRoutes';
import {notification} from 'antd';

const STATUSES = {
  PENDING  : 0,
  QUEUED   : 1,
  SENT     : 2,
  FAILED   : 3,
  COMPLETED: 4,
  SCHEDULED: 5,
};

const useStyles = makeStyles((theme: Theme) => ({
  cardAvatar       : {
    marginRight: theme.spacing(1),
  },
  card             : {
    borderRadius   : 0,
    width          : '100%',
    backgroundColor: 'inherit',
  },
  cardHeaderContent: {
    overflow: 'auto',
  },
  copyToClipboard  : {
    fontSize  : 10,
    marginLeft: 5,
    cursor    : 'pointer',
    '&:hover' : {
      color: theme.palette.primary.main,
    },
  },
  cardHeader       : {
    padding      : 0,
    whiteSpace   : 'unset',
    paddingBottom: theme.spacing(1),
  },
  cardContent      : {
    padding      : 0,
    flexDirection: 'row',
    display      : 'flex',
    whiteSpace   : 'unset',
    alignItems   : 'center',
  },
  avatar           : {
    borderRadius   : 0,
    height         : 30,
    width          : 30,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  bodyAvatar       : {
    borderRadius   : 0,
    height         : 48,
    width          : 90,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    marginRight    : theme.spacing(1),
  },
}));

export default function TitleFormatter(
  {
    row,
  }: DataTypeProvider.ValueFormatterProps
) {
  const classes = useStyles();
  const {t} = useTranslation();
  const params = useParams<{ appUuid?: string }>();

  const copyUuid = React.useCallback((uuid: string) => {
    if (copyToClipboard(uuid)) {
      notification.success({
        message: t('messages.successfully_copied'),
      });
    } else {
      notification.error({
        message: t('messages.something_went_wrong'),
      });
    }
  }, [
    t,
  ]);

  return (
    <Card
      elevation={0}
      className={classes.card}
    >
      <CardHeader
        className={classes.cardHeader}
        classes={{
          avatar : classes.cardAvatar,
          content: classes.cardHeaderContent,
        }}
        avatar={row?.messages?.default?.icon_url && (
          <Tooltip
            title={
              <Avatar
                style={{
                  borderRadius: 0,
                  width       : 'auto',
                  height      : 'auto',
                }}
                src={row?.messages?.default?.icon_url}
              />
            }
          >
            <Avatar
              className={classes.avatar}
              src={row?.messages?.default?.icon_url}
            />
          </Tooltip>
        )}
        title={
          ([
            STATUSES.SENT,
            STATUSES.SCHEDULED,
            STATUSES.COMPLETED,
          ].includes(Number(row.status)) || row.is_system) ? (
            <Link
              style={{backgroundColor: 'transparent !important', textDecoration: 'none'}}
              to={urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_INFO, {
                uuid   : row.uuid,
                appUuid: params.appUuid,
              })}
            >
              <Tooltip
                title={row.title || row?.messages?.default?.title}
              >
                <span>{row.title || row?.messages?.default?.title}</span>
              </Tooltip>
            </Link>
          ) : (
            <Tooltip
              title={row.title || row?.messages?.default?.title}
            >
              <span>{row.title || row?.messages?.default?.title}</span>
            </Tooltip>
          )
        }
        subheader={
          <>
            <Typography
              noWrap
              style={{fontSize: 10, cursor: 'default', width: 210}}
            ><b>ID:</b> {row.uuid}
            </Typography>
            <Typography
              component='span'
              className={classes.copyToClipboard}
              onClick={() => copyUuid(row.uuid)}
            >
              <FileCopyIcon
                style={{fontSize: 15}}
              />
            </Typography>
          </>
        }
        titleTypographyProps={{
          noWrap: true,
          style : {
            fontSize  : 12,
            fontWeight: 'bold',
          },
        }}
        subheaderTypographyProps={{
          noWrap: true,
          style : {
            fontSize      : 10,
            display       : 'flex',
            alignItems    : 'center',
            justifyContent: 'flex-start',
          },
        }}
      />
      <CardContent
        className={classes.cardContent}
      >
        {row?.messages?.default?.image_url && (
          <Tooltip
            title={
              <Avatar
                style={{
                  borderRadius: 0,
                  width       : 'auto',
                  height      : 'auto',
                }}
                src={row?.messages?.default?.image_url}
              />
            }
          >
            <Avatar
              className={classes.bodyAvatar}
              src={row?.messages?.default?.image_url}
            />
          </Tooltip>
        )}
        <Tooltip
          title={row.message || row?.messages?.default?.message || ''}
        >
          <Typography
            noWrap
            variant='body2'
            component='span'
            color='textSecondary'
          >{row.message || row?.messages?.default?.message}
          </Typography>
        </Tooltip>
      </CardContent>
      <div/>
    </Card>
  )
}
