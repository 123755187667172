import React from 'react';
import {useWatch} from 'react-hook-form';
import {Header} from '../../../../Common/Header';
import {Control} from 'react-hook-form/dist/types/form';
import {IPayAndLaunchForm} from '../../usePayAndLaunch';

export function HeaderWrapper(
  {
    control
  }: {
    control: Control<IPayAndLaunchForm>
  }
) {
  const city = useWatch({
    control,
    name: 'city'
  });
  const name = useWatch({
    control,
    name: 'name'
  });
  const line1 = useWatch({
    control,
    name: 'line1'
  });
  const state = useWatch({
    control,
    name: 'state'
  });
  const cardField = useWatch({
    control,
    name: 'cardField'
  });
  const postalCode = useWatch({
    control,
    name: 'postal_code'
  });

  const sum = function () {
    return (
      Number(!!postalCode) +
      Number(!!name?.trim()) +
      Number(!!state?.trim()) +
      Number(!!line1?.trim()) +
      Number(!!cardField?.complete) +
      Number(!!city?.trim())
    )
  }();

  return (
    <Header
      current={2}
      percent={Math.round((100 / 6) * sum)}
    />
  )
}