import {Store} from 'redux';
import nativeAxios from 'axios';
import {AxiosInstance} from 'axios';
import {sleep} from '../../utils/sleep';
import * as Sentry from '@sentry/react';
import {AxiosRequestConfig} from 'axios';
import {AbortError} from '../../constants';
import {Globals} from '../../types/globals';
import {getResponseErrorMsgs} from '../../utils';
import {_getResponseErrorMsgs} from '../../utils';
import {_suspended} from '../actions/application';
import {_unauthorized} from '../actions/application';
import {_accessToken} from '../selectors/application';
import {errorMessagesTransformer} from '../../utils/errorMessagesTransformer';

export interface IResponse {
  hasError: boolean;
  body: {
    data: any;
    blob?: any;
    name?: string;
    status: number;
    message: string;
    error_code?: number;
  };
}

export class Api {
  private store?: Store<Globals.RootReducer>;

  public setStore(store: Store<Globals.RootReducer>) {
    this.store = store;
  }

  public get = async (url: string, init: RequestInit = {}): Promise<{
    hasError: boolean;
    body: {
      data: any;
      name?: string;
      status: number;
      message?: string;
    };
  }> => {
    try {
      const response = await this.fetch(url, init);
      if (!response.ok) {
        const {
          data,
          errors,
          message,
        } = await response.json();
        const errorMessages = _getResponseErrorMsgs(errors);
        return {
          hasError: true,
          body    : {
            data   : {
              errors: {
                ...errorMessages
              }
            },
            status : response.status,
            message: data?.message || errorMessages?.message || message,
          },
        };
      } else {
        const {
          data,
          message,
        } = await response.json();
        return {
          hasError: false,
          body    : {
            data,
            message,
            status: response.status,
          },
        };
      }
    } catch (e) {
      if (e.name !== 'AbortError') {
        Sentry.captureException(e);
      }
      return {
        hasError: true,
        body    : {
          data   : {},
          name   : e.name,
          status : e.status,
          message: 'Something went wrong',
        },
      };
    }
  };

  public getList = async (url: string): Promise<{
    hasError: boolean;
    body: {
      data: any;
      status: number;
      message?: string;
    };
  }> => {
    try {
      const response = await this.fetch(url);
      if (!response.ok) {
        const {
          data,
          message,
        } = await response.json();
        return {
          hasError: true,
          body    : {
            data   : {},
            status : response.status,
            message: (data && data.message) || message,
          },
        };
      } else {
        const {data} = await response.json();
        return {
          hasError: false,
          body    : {
            data  : {
              rows        : data.rows,
              hasMorePages: data.hasMorePages,
              total       : Number(data.total),
            },
            status: response.status,
          },
        };
      }
    } catch (e) {
      Sentry.captureException(e);
      return {
        hasError: true,
        body    : {
          data   : {},
          status : e.status,
          message: e.message,
        },
      };
    }
  };

  public post = async (url: string, entity: any = {}): Promise<{
    hasError: boolean;
    body: {
      data: any;
      status: number;
      message?: string;
    };
  }> => {
    try {
      const response = await this.fetch(url, {
        method: 'POST',
        body  : JSON.stringify(entity),
      });

      if (!response.ok) {
        const {
          data,
          errors,
          message,
        } = await response.json();
        const errorMessages = getResponseErrorMsgs(errors);
        return {
          hasError: true,
          body    : {
            data   : {
              errors : {
                ...errorMessages,
                ..._getResponseErrorMsgs(errors),
              },
              _errors: {
                ..._getResponseErrorMsgs(errors),
              }
            },
            status : response.status,
            message: data?.message || message,
          },
        };
      } else {
        const {
          data,
          message,
        } = await response.json();
        return {
          hasError: false,
          body    : {
            data,
            status : response.status,
            message: data?.message || message,
          },
        };
      }
    } catch (e) {
      Sentry.captureException(e);
      return {
        hasError: true,
        body    : {
          data   : {},
          status : e.status,
          message: e.message,
        },
      };
    }
  };

  public request = async (url: string, method: string = 'GET', init?: RequestInit): Promise<{
    hasError: boolean;
    body: {
      data: any;
      status: number;
      message?: string;
      error_code?: number,
    };
  }> => {
    try {
      const response = await this.fetch(url, {
        method,
        ...init,
      });

      if (!response.ok) {
        const {
          errors,
          message,
          error_code,
        } = await response.json();
        const errorMessage = _getResponseErrorMsgs(errors);
        return {
          hasError: true,
          body    : {
            data      : {},
            status    : response.status,
            error_code: Number(error_code) || undefined,
            message   : message || (errorMessage && errorMessage.message),
          },
        }
      } else {
        const {
          message,
        } = await response.json();
        return {
          hasError: false,
          body    : {
            message,
            data  : {},
            status: response.status || 200,
          },
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      return {
        hasError: true,
        body    : {
          data   : {},
          status : e.status,
          message: 'Something went wrong',
        },
      }
    }
  };

  public upload = async (url: string, method: string = 'POST', init?: RequestInit): Promise<{
    hasError: boolean;
    body: {
      data: any;
      status: number;
      message?: string;
    };
  }> => {
    try {
      const response = await this.fetch(url, {
        method,
        ...init,
      }, true);

      if (!response.ok) {
        const {
          errors,
          message,
        } = await response.json();
        const errorMessage = _getResponseErrorMsgs(errors);
        return {
          hasError: true,
          body    : {
            data   : {
              errors: {
                ..._getResponseErrorMsgs(errors),
              },
            },
            status : response.status,
            message: message || (errorMessage && errorMessage.message),
          },
        }
      } else {
        const {
          data,
          message,
        } = await response.json();
        return {
          hasError: false,
          body    : {
            data,
            message,
            status: response.status || 200,
          },
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      return {
        hasError: true,
        body    : {
          data   : {},
          status : e.status,
          message: 'Something went wrong',
        },
      }
    }
  };

  private getState() {
    return this.store?.getState();
  }

  private fetch = async (input: RequestInfo, init: RequestInit = {}, skipHeaders?: boolean): Promise<any> => {
    let headers = new Headers({
      'Content-Type': 'application/json',
      ...init.headers,
    });

    if (skipHeaders) {
      headers = new Headers();
    }

    const state = this.getState();

    if (state) {
      headers.set('Authorization', 'Bearer ' + _accessToken(state))
    }

    let response = await fetch(input, {
      ...init,
      headers: headers,
    });

    if (response.status === 401) {
      localStorage.removeItem('access_token');
      this.store?.dispatch(_unauthorized(true));
    }

    return response;
  };
}

const {
  REACT_APP_HOSTNAME,
  REACT_APP_PROTOCOL,
} = process.env;

export const baseURL = `${REACT_APP_PROTOCOL}://${REACT_APP_HOSTNAME}`;

export class ApiV2 {
  private storeInstance: Store<Record<string, any>>;

  private readonly axiosInstance: AxiosInstance;

  public constructor() {
    this.axiosInstance = nativeAxios.create({
      baseURL,
      headers: {
        'Accept'      : 'application/json',
        'Content-Type': 'application/json',
      }
    });

    this.axiosInstance.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      Sentry.setExtra('axiosInterceptorsResponse', error);
      Sentry.captureException(error);

      if (error?.response?.data?.error_code === 103) {
        this.store.dispatch(_suspended({
          message: error?.response?.data?.message,
        }));
      }

      return Promise.reject(error);
    });
  }

  public get axios(): AxiosInstance {
    const accessToken = localStorage.getItem('access_token');

    this.axiosInstance.defaults.headers['common']['Authorization'] = `Bearer ${accessToken}`;

    return this.axiosInstance;
  }

  public get store(): Store<Record<string, any>> {
    return this.storeInstance;
  };

  public set store(store: Store<Record<string, any>>) {
    this.storeInstance = store;
  };

  public delete = async (url: string): Promise<IResponse> => {
    try {
      const response = await this.axios.delete(url);

      return {
        hasError: false,
        body    : {
          status : response.status,
          message: response.data.message,
          data   : response.data.data || {},
        }
      };
    } catch (e) {
      const {
        errors,
        status,
        message,
        error_code,
      } = e.response?.data || {};
      return {
        hasError: true,
        body    : {
          name      : e.name,
          error_code: error_code,
          message   : message || e.message,
          status    : status || e.response?.status,
          data      : errorMessagesTransformer(errors),
        }
      };
    }
  };

  public put = async (url: string, data: Record<string, any> = {}): Promise<IResponse> => {
    try {
      const response = await this.axios.put(url, data);

      return {
        hasError: false,
        body    : {
          status : response.status,
          message: response.data.message,
          data   : response.data.data || {},
        }
      };
    } catch (e) {
      const {
        errors,
        status,
        message,
        error_code,
      } = e.response?.data || {};
      return {
        hasError: true,
        body    : {
          name      : e.name,
          error_code: error_code,
          message   : message || e.message,
          status    : status || e.response?.status,
          data      : errorMessagesTransformer(errors),
        }
      };
    }
  };

  public post = async (url: string, data: Record<string, any> = {}, config: AxiosRequestConfig = {}): Promise<IResponse> => {
    const start = performance.now();

    try {
      const response = await this.axios.post(url, data, config);
      const diff = Math.ceil((performance.now() - start));
      if (diff < 400) {
        await sleep(400 - diff)
      }

      return {
        hasError: false,
        body    : {
          blob   : response.data,
          status : response.status,
          message: response.data.message,
          data   : response.data.data || response.data || {},
        }
      };
    } catch (e) {
      const {
        errors,
        status,
        message,
        error_code,
      } = e.response?.data || {};
      const diff = Math.ceil((performance.now() - start));
      if (diff < 400) {
        await sleep(400 - diff)
      }

      if (nativeAxios.isCancel(e)) {
        return {
          hasError: true,
          body    : {
            data   : {},
            message: '',
            status : 500,
            name   : AbortError,
          }
        };
      }

      const {
        message: bodyMessage,
        ...restErrorMessages
      } = errorMessagesTransformer(errors);

      return {
        hasError: true,
        body    : {
          name      : e.name,
          error_code: error_code,
          data      : restErrorMessages,
          message   : message || bodyMessage,
          status    : status || e.response?.status,
        }
      };
    }
  };

  public get = async (url: string, {
    skipDelay,
    ...config
  }: AxiosRequestConfig & {
    skipDelay?: boolean;
  } = {
    skipDelay: false,
  }): Promise<IResponse> => {
    const start = performance.now();

    try {
      const response = await this.axios.get(url, config);
      const diff = Math.ceil((performance.now() - start));
      if ((diff < 400) && !skipDelay) {
        await sleep(400 - diff)
      }

      return {
        hasError: false,
        body    : {
          status : response.status,
          message: response.data.message,
          data   : response.data.data || response.data || {},
        }
      };
    } catch (e) {
      const diff = Math.ceil((performance.now() - start));
      if ((diff < 400) && !skipDelay) {
        await sleep(400 - diff)
      }

      if (nativeAxios.isCancel(e)) {
        return {
          hasError: true,
          body    : {
            data   : {},
            message: '',
            status : 500,
            name   : AbortError,
          }
        };
      }

      const {
        errors,
        status,
        message,
        error_code,
      } = e.response?.data || {};

      return {
        hasError: true,
        body    : {
          name      : e.name,
          error_code: error_code,
          message   : message || e.message,
          status    : status || e.response?.status,
          data      : errorMessagesTransformer(errors),
        }
      };
    }
  };
}
