import {Globals} from '../../../types/globals';
import {dataLayer} from '../../../utils/dataLayer';
import {tidioChat} from '../../../utils/tidioChatApi';
import {AppRoutes} from '../../../constants/appRoutes';

export const _logout = (): Globals.ThunkAction => async () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('display_time_zone');
  localStorage.removeItem('show_recommendation');

  (window as any)[`disableYaCounter${process.env.REACT_APP_YM_ACCOUNT}`] = false;
  tidioChat().setVisitorData({
    email      : '',
    name       : '',
    distinct_id: '',
  });
  dataLayer({
    event: 'logged_out'
  });
  if (document.getElementById('tidio-chat')) {
    document.getElementById('tidio-chat').style.display = 'block';
  }

  window.location.replace(AppRoutes.LOGIN);
};
