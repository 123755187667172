import React from 'react';
import {Plugin} from '@devexpress/dx-react-core';
import {Template} from '@devexpress/dx-react-core';
import {TemplatePlaceholder} from '@devexpress/dx-react-core';

export default function CustomHeaderMarkup(
  {
    refresh,
    headerTop   : HeaderTop,
    headerBottom: HeaderBottom,
  }: {
    refresh: () => void;
    headerTop?: React.ComponentType<any>;
    headerBottom?: React.ComponentType<any>;
  }
) {
  return (
    <Plugin
      name='customHeaderMarkup'
    >
      <Template
        name='header'
      >
        {HeaderTop && (
          <HeaderTop
            refresh={refresh}
          />
        )}
        <TemplatePlaceholder/>
        {HeaderBottom && (
          <HeaderBottom
            refresh={refresh}
          />
        )}
      </Template>
    </Plugin>
  )
}
