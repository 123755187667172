import {_unauthorized} from './index';
import {sleep} from '../../../utils/sleep';
import {reactPixel} from '../../../utils/fbq';
import {Globals} from '../../../types/globals';
import {dataLayer} from '../../../utils/dataLayer';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {ActionTypes} from '../../constants/application';
import {isValidEmail} from '../../../utils/isValidEmail';
import {UseFormSetError} from 'react-hook-form/dist/types/form';
import {RecaptchaActions} from '../../../constants/recaptchaActions';
import {IForm} from '../../../components/antd/Unauthorized/useUnauthorized';

export const _loginUnauthorized = (
  {
    message: tmpInput,
    ...restFormValues
  }: IForm, {
    setError,
    executeRecaptcha,
  }: {
    setError: UseFormSetError<IForm>;
    executeRecaptcha: (action?: string) => Promise<string>;
  }): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  if (!isValidEmail(restFormValues.email)) {
    await sleep();
    return setError('email', {
      message: 'Please enter a valid email address',
    });
  }

  let token: string | undefined = void 0;

  if (typeof executeRecaptcha === 'function') {
    token = await executeRecaptcha(RecaptchaActions.LOGIN);
  }

  const {
    body: {
      data,
      message,
    },
    hasError,
  } = await api.post(ApiRoutes.LOGIN, {
    ...restFormValues,
    'g-recaptcha-response': token || 'token',
  });

  if (hasError) {
    if (Object.keys(data).length) {
      Object.keys(data).forEach((inputName) => {
        setError(inputName as keyof IForm, {
          message: data[inputName],
        });
      });
    } else {
      setError('message', {
        message,
      });
    }
  } else {
    try {
      reactPixel('trackCustom', 'Login (App)');
      dataLayer({
        authenticationMethod: 'email',
        event               : 'login',
      });
    } catch (e) {
      console.error(e)
    }

    dispatch({
      payload: {
        authenticated: true,
        accessToken  : data.access_token,
      },
      type   : ActionTypes.AUTHENTICATE,
    });

    dispatch(_unauthorized(false));
  }
};
