import moment from 'moment-timezone';
import {Automations} from '../../../../types/automations';
import {SendSegmentsType} from '../../../../constants/automation';

export const checkSegmentValidation = (data: Automations.AutomationForm, setError: (name: any, op: { type?: string, message: string }) => void) => {
  if (data.send_to === SendSegmentsType.all) {
    return true;
  } else {
    const isIncludeEmpty = !data.include_segments.length;
    const isExcludeEmpty = !data.exclude_segments.length;
    if (isIncludeEmpty && isExcludeEmpty) {
      setError('include_segments', {
        type: 'required',
        message: 'You need to include or exclude at least one segment.',
      });
      setError('exclude_segments', {
        type: 'required',
        message: 'You need to include or exclude at least one segment.',
      });
    }
    return !isIncludeEmpty || !isExcludeEmpty;
  }
};

export const convertDataForRequest = (data: Automations.AutomationForm) => {
  const newData = {
    id: data.id,
    url: data.url,
    active: data.active,
    name: data.name,
    is_direct: data.is_direct,
    hour_ranges: data.hour_ranges,
    send_to: data.send_to,
    start_template: data.start_template,
    from_date: moment(data.dates[0]).format('YYYY-MM-DD'),
    to_date: moment(data.dates[1]).format('YYYY-MM-DD'),
    ttl_unit: data.ttl_unit,
    ttl_value: +data.ttl_value,
    front_data: { },
  } as Automations.AutomationDataType;

  if (data.send_to === SendSegmentsType.particular) {
    if (!data.include_segments.length) {
      newData.include_segments = [];
    } else {
      const incSegments: {[key: number]: number } = {};
      data.include_segments.forEach(item => incSegments[item.value] = item.value);
      newData.include_segments = incSegments;
      newData.front_data.include_segments = data.include_segments;
    }

    if (!data.exclude_segments.length) {
      newData.exclude_segments = [];
    } else {
      const excSegments: {[key: number]: number } = {};
      data.exclude_segments.forEach(item => excSegments[item.value] = item.value);
      newData.exclude_segments = excSegments;
      newData.front_data.exclude_segments = data.exclude_segments;
    }
  }

  if (!data.all_tags) {
    if (data.include_tags.length) {
      newData.include_tags = data.include_tags;
      newData.include_all_tags = data.include_all_tags;
    }
    if (data.exclude_tags.length) {
      newData.exclude_tags = data.exclude_tags;
      newData.exclude_all_tags = data.exclude_all_tags;
    }
  }

  return newData;
}

export const getDefaultValues = (currentApp: any, data?: Automations.AutomationDataType, isClone?: boolean) => {
  const allTagsSelected = () => {
    if (data?.exclude_tags.length || data?.include_tags.length) {
      return false;
    }
    return true;
  };

  const sendTo = () => {
    if (!data || data?.send_to === SendSegmentsType.all) {
      return SendSegmentsType.all;
    } else {
      return SendSegmentsType.particular;
    }
  };

  return {
    id: (!data || isClone) ? null : data.id,
    active: data ? data.active : true,
    url: data?.url || currentApp?.default_url,
    name: data?.name || '',
    ttl_unit: data?.ttl_unit || 'day',
    ttl_value: data?.ttl_value || '1',
    front_data: {},
    dates: data ? [moment(data.from_date), moment(data.to_date)] : [moment().add(1, 'days'), moment().add(1, 'days')],
    hour_ranges: data?.hour_ranges || [
      {
        to: '',
        from: '',
      }
    ],
    is_direct: data ? data.is_direct : false,
    start_template: data ? data.start_template : null,
    all_tags: allTagsSelected(),
    include_all_tags: data ? data.include_all_tags : true,
    exclude_all_tags: data ? data.exclude_all_tags : true,
    include_tags: data?.include_tags || [],
    exclude_tags: data?.exclude_tags || [],
    send_to: sendTo(),
    include_segments: data?.front_data?.include_segments || [],
    exclude_segments: data?.front_data?.exclude_segments || [],
  };
};
