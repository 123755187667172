import {Segments} from '../../../types/segments';
import {ActionTypes} from '../../constants/segments';

export const _setData = (data: Segments.Entity[]) => ({
  payload: data,
  type   : ActionTypes.SEGMENTS_SET_DATA,
});

export const _setParams = (payload?: Segments.Param[]) => ({
  payload,
  type: ActionTypes.SEGMENTS_SET_PARAMS,
});

export const _removeEntity = () => ({
  type: ActionTypes.SEGMENTS_REMOVE_ENTITY,
});

export const _upsertEntity = (payload: Partial<Segments.Entity>) => ({
  payload,
  type: ActionTypes.SEGMENTS_UPSERT_ENTITY,
});

export const _upsertDataItem = (apiKey: Partial<Segments.Entity>) => ({
  payload: apiKey,
  type   : ActionTypes.SEGMENTS_UPSERT_DATA_ITEM,
});

export const _dataOptions = (options: Partial<Segments.DataOptions>) => ({
  payload: options,
  type   : ActionTypes.SEGMENTS_DATA_OPTIONS,
});
