import React from 'react';
import {Route} from 'react-router';
import {Switch} from 'react-router';
import {App} from '../Components/App';
import {useParams} from 'react-router';
import {Redirect} from 'react-router-dom';
import {Prompt} from '../Components/Prompt';
import {urlHelper} from '../../../../utils/urlHelper';
import {Integrations} from '../Components/Integrations';
import WelcomeMessage from '../Components/WelcomeMessage';
import {AppRoutes} from '../../../../constants/appRoutes';

export function SettingRoutes(
  {
    hasWelcomeMessageAccess,
  }: {
    hasWelcomeMessageAccess?: boolean;
  }
) {
  const {appUuid} = useParams<{ appUuid: string }>();

  return (
    <div>
      <Switch>
        <Route
          exact
          component={App}
          path={AppRoutes.SETTINGS}
        />
        <Route
          exact
          component={Integrations}
          path={AppRoutes.SETTINGS_INTEGRATIONS}
        />
        <Route
          exact
          component={Prompt}
          path={AppRoutes.SETTINGS_PROMPT_CONFIGS}
        />
        <Route
          exact
          path={AppRoutes.SETTINGS_WELCOME_MESSAGE}
          component={hasWelcomeMessageAccess ? WelcomeMessage : RedirectTo}
        />
        <Route
          path={`${AppRoutes.SETTINGS}/*`}
        >
          <Redirect
            to={urlHelper(AppRoutes.SETTINGS, {appUuid})}
          />
        </Route>
      </Switch>
    </div>
  )
}

function RedirectTo() {
  const {appUuid} = useParams<{ appUuid: string }>();

  return (
    <Redirect
      to={urlHelper(AppRoutes.SETTINGS, {appUuid})}
    />
  )
}