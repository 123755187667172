import React from 'react';
import {useSelector} from 'react-redux';
import {AppRoutes} from '../../constants/appRoutes';
import {PassiveGracePeriod} from '../../components/antd/PassiveGracePeriod';
import {_showPassiveGracePeriodWarning} from '../../store/selectors/application/acl';

const availablePaths: string[] = [
  AppRoutes.MANAGE_ACCOUNT,
  AppRoutes.MANAGE_ACCOUNT_APP,

  AppRoutes.CHECKOUT,
  AppRoutes.CHECKOUT_APP,

  AppRoutes.SECURITY,
  AppRoutes.SECURITY_APP,

  AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS,
  AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP,

  AppRoutes.CHANGE_PLAN,
  AppRoutes.CHANGE_PLAN_APP,

  AppRoutes.PAY_AND_ACTIVATE,
  AppRoutes.PAY_AND_ACTIVATE_APP,
];

export function PGPeriodProvider(
  {
    children,
    matchPath,
  }: {
    matchPath: string;
    children: React.ReactNode;
  }
) {
  const showPGPeriodWarning = useSelector(_showPassiveGracePeriodWarning);

  if (showPGPeriodWarning && !availablePaths.includes(matchPath)) {
    return (
      <PassiveGracePeriod/>
    )
  } else {
    return <>{children}</>
  }
}
