import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Table, TablePaginationConfig} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {FilterValue} from 'antd/lib/table/interface';
import {SortTypes} from '../../../constants/sortTypes';
import {_updateFilters} from'../../../store/actions/builder/title/actions';
import {getTitlesData, getTotal, getFilters, getFetchState} from '../../../store/selectors/titles';
import ActionCell from './ActionCell';

const TitlesTable: React.FC = () => {
  const dispatch = useDispatch();
  const titlesData = useSelector(getTitlesData);
  const fetched = useSelector(getFetchState);
  const total = useSelector(getTotal);
  const filters = useSelector(getFilters);

  const columns: ColumnsType<any> = React.useMemo(() => {
    return [
      {
        key      : 'id',
        title    : 'Id',
        dataIndex: 'id',
        sorter: true,
        minWidth : 200,
        elipsis: true
      },
      {
        key      : 'value',
        title    : 'Value',
        dataIndex: 'value',
        minWidth : 200,
        sorter: true,
        elipsis: true
      },
      {
        key      : 'tags',
        title    : 'Tags',
        dataIndex: 'tags',
        sorter: true,
        minWidth : 200,
        elipsis: true
      },
      {
        key      : 'actions',
        title    : 'Actions',
        align    : 'center',
        fixed    : 'right',
        width    : 50,
        render   : (row) => <ActionCell rowData={row}/>,
      },
    ]
  }, []);

  const handleTableChange = (p: TablePaginationConfig, f: Record<string, FilterValue>, sorter: any) => {
    const order: 'descend' | 'ascend' = sorter.order;
    dispatch(_updateFilters({ sortName: sorter.field, sortOrder: SortTypes[order] }));
  };

  const onPageChanges = (pageNumber: number, pageSize: number) => {
    dispatch(_updateFilters({ pageNumber, pageSize }));
  };

  return (
    <Table
      rowKey='id'
      columns={columns}
      scroll={{x: true, scrollToFirstRowOnChange: true}}
      dataSource={titlesData}
      loading={!fetched}
      style={{marginTop: 20}}
      onChange={handleTableChange}
      pagination={{
        current: filters.pageNumber,
        onChange: (p, s) => onPageChanges(p, s),
        total,
        position: ['bottomCenter'],
        showSizeChanger: true,
      }}
    />
  )
};

export default TitlesTable;
