import React from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {useSelector} from 'react-redux';
import {useTitle} from '../../../../hooks/useTitle';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {_formInfo} from '../../../../store/selectors/application';
import {_upsertForm} from '../../../../store/actions/application';
import {_forgotPassword} from '../../../../store/actions/application/forgotPassword';

export interface IForgotPasswordForm {
  email: string;
  message: string;
}

export function useForgotPassword() {
  const {search} = useLocation();
  const formInfo = useSelector(_formInfo);
  const searchString = search.replace('?', '');
  useTitle('Reset Password | SmartPush');
  const {
    setError,
    handleSubmit,
    ...formRest
  } = useForm<IForgotPasswordForm>({
    mode: 'onSubmit',
  });
  const dispatch = useDispatch();
  const {executeRecaptcha} = useGoogleReCaptcha();

  const submit = React.useCallback(async (formValues: IForgotPasswordForm) => {
    return dispatch(_forgotPassword({
      ...formValues,
      email: formValues.email?.trim()
    }, {
      setError,
      executeRecaptcha,
    }))
  }, [
    setError,
    dispatch,
    executeRecaptcha,
  ]);

  React.useEffect(() => {
    return function () {
      dispatch(_upsertForm(void 0));
    }
  }, [
    dispatch,
  ]);

  return {
    ...formRest,
    formInfo,
    searchString,
    onSubmit: handleSubmit(submit),
  }
}