import {notification} from 'antd';
import {stringify} from 'querystring';
import {filterObject} from '../../../../utils';
import {Globals} from '../../../../types/globals';
import {urlHelper} from '../../../../utils/urlHelper';
import {ApiRoutes} from '../../../../constants/apiRoutes';
import {_setData, _deleteData, _setLoading} from './actions';
import {getFilters as getTitleFilters} from '../../../selectors/titles';

export const getTitlesData = (appUuid: string): Globals.ThunkAction => async (dispatch, getState, {api}) => {
  dispatch(_setLoading());
  const filters = getTitleFilters(getState());
  const {pageSize, pageNumber, searchText, sortOrder, sortName} = filters;
  const params = stringify(filterObject({
    pageSize,
    pageNumber,
    searchText,
    sortOrder,
    sortName,
  }));
  const endpoint = urlHelper(ApiRoutes.LIST_TITLES, { appUuid });
  const url = `${endpoint}?${params}`;
  const response = await api.get(url);

  if (!response.hasError) {
    dispatch(_setData(response.body.data));
  }
};

export const deleteTitle = (appUuid: string, id: number): Globals.ThunkAction => {
  return async (dispatch, _, { api }) => {
    const {
      hasError,
      body: {
        message,
      },
    } = await api.delete(urlHelper(ApiRoutes.DELETE_TITLE, {appUuid, id}));

    if (hasError) {
      notification.error({message});
    } else {
      notification.success({message: 'Title has been successfully deleted'});
      dispatch(_deleteData(id));
    }
  }
};

export const createOrUpdateTitle = (appUuid: string, titleData: any, id: number): Globals.ThunkAction => {
  return async (dispatch, _, { api }) => {
    let url = id ?
      urlHelper(ApiRoutes.UPDATE_TITLE, { appUuid, id })
    : urlHelper(ApiRoutes.CREATE_TITLE, { appUuid });

    const { hasError, body: { message } } = await api.post(url, titleData);
    if (hasError) {
      notification.error({message});
    } else {
      notification.success({message: `Title has been successfully ${id ? 'updated' : 'created'}`});
      //Can use data update logic if response data will be match title data
      dispatch(getTitlesData(appUuid));
    }
  };
};
