import {_setParams} from './index';
import {_dataOptions} from './index';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';
import {_hasConversionsAccess} from '../../selectors/application/acl';

export const _fetchParams = (appUuid: string): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    fetchingSegmentParamsState: FiniteStates.LOADING
  }));

  const {
    hasError,
    body: {
      data,
    },
  } = await api.get(`${urlHelper(ApiRoutes.GET_SEGMENT_PARAMS, {appUuid})}`);

  if (!hasError) {
    const hasConversionsAcl = _hasConversionsAccess(getState());

    dispatch(_setParams(data.filter((datum: {
      type: string;
      field: string;
      label: string;
      table: string;
      search: boolean;
      filters: string[];
      id: string | number;
    }) => {
      if (hasConversionsAcl) {
        return true;
      }

      return ![
        'conversion_sum',
        'conversion_count',
        'last_converted_at',
      ].includes(datum.field)
    })))

    dispatch(_dataOptions({
      fetchingSegmentParamsState: FiniteStates.SUCCESS
    }));
  } else {
    dispatch(_dataOptions({
      fetchingSegmentParamsState: FiniteStates.FAILURE
    }));
  }
};