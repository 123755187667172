import clsx from 'clsx';
import React from 'react';
import {Button} from '../../Button';
import {TextField} from '../../Input';
import ReactSelect from 'react-select';
import {useSelector} from 'react-redux';
import Menu from '@material-ui/core/Menu';
import Chip from '@material-ui/core/Chip';
import {formatDate} from '../../../utils';
import Paper from '@material-ui/core/Paper';
import {filterObject} from '../../../utils';
import {IconButton} from '../../IconButton';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {DateRangePicker} from '../../DateRangePicker';
import {AsyncPaginate} from 'react-select-async-paginate';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import {customStyles} from '../../../constants/reactSelectStyles';
import {_accessToken} from '../../../store/selectors/application';

const useTableContainerComponentStyles = makeStyles(() => ({
  root             : {
    flexGrow: 1,
    display : 'flex',
    position: 'relative',
  },
  childrenContainer: {
    flexGrow               : 1,
    overflow               : 'auto',
    width                  : '100%',
    WebkitOverflowScrolling: 'touch',
  },
}));

export default function TableContainerComponent(
  {
    filters,
    children,
    filtersIsOpen,
    updateTableOptions,
    ...rest
  }: any,
) {
  const classes = useTableContainerComponentStyles();

  return (
    <div
      className={classes.root}
    >
      <div
        {...rest}
        className={clsx(rest.className, classes.childrenContainer)}
      >{children}
      </div>
      <Filters
        filters={filters}
        open={!!filtersIsOpen}
        updateTableOptions={updateTableOptions}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  filters          : {
    padding: 10,
  },
  divider          : {
    marginTop   : theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipRoot         : {
    margin  : 3,
    height  : 28,
    fontSize: 11,
  },
  btnFilter        : {
    marginBottom: theme.spacing(1),
  },
  formControl      : {
    marginBottom: theme.spacing(3),
  },
  filtersContainer : {
    flexGrow     : 1,
    display      : 'flex',
    overflow     : 'auto',
    flexDirection: 'column',
  },
  root             : {
    width        : 0,
    padding      : 0,
    flexGrow     : 1,
    display      : 'flex',
    overflowY    : 'auto',
    flexDirection: 'column',
    // transition   : theme.transitions.create('width'),
  },
  open             : {
    width        : 450,
    overflowX    : 'unset',
    padding      : theme.spacing(2.5, 1),
    paddingBottom: 0,
  },
  filtersTypography: {
    fontWeight: 'bold',
  },
  selectLabel      : {
    margin: '0px 8px 4px 0px',
  },
}));

function Filters(
  {
    open,
    filters,
    updateTableOptions,
  }: {
    open: boolean;
    filters: any[];
    updateTableOptions: any;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();

  const [values, setValues] = React.useState<any>(void 0);

  const handleOnChange = React.useCallback((name: any, value: any) => {
    setValues((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))
  }, []);

  React.useEffect(() => {
    setValues({
      ...(filters || []).reduce((acc: any, curr: any) => {
        if (curr && curr.value) {
          acc[curr.columnName] = curr.value;
        }
        return acc;
      }, {})
    })
  }, [
    filters,
  ]);

  return (
    <Paper
      square
      elevation={0}
      className={clsx(classes.root, open && classes.open)}
    >
      <Typography
        color='primary'
        className={classes.filtersTypography}
      >{t('table.filters')}
      </Typography>
      <Divider
        className={classes.divider}
      />
      <div
        className={classes.filtersContainer}
      >
        <div
          className={classes.filters}
        >
          <FilterTags
            values={values}
            setValues={setValues}
          />
          {(filters || []).map((filter: any, index: number) => {
            switch (filter.type) {
              case 'text': {
                return (
                  <TextFilter
                    key={index}
                    label={filter.label}
                    name={filter.columnName}
                    onChange={handleOnChange}
                    value={(values && values[filter.columnName]) || ''}
                  />
                )
              }
              case 'select': {
                return (
                  <SelectFilter
                    key={index}
                    label={filter.label}
                    isMulti={filter.isMulti}
                    options={filter.options}
                    name={filter.columnName}
                    onChange={handleOnChange}
                    value={(values && values[filter.columnName]) || ''}
                  />
                )
              }
              case 'daterange': {
                return (
                  <DateRangeEditor
                    key={index}
                    label={filter.label}
                    name={filter.columnName}
                    onChange={handleOnChange}
                    value={(values && values[filter.columnName]) || ''}
                  />
                )
              }
              case 'async_select': {
                return (
                  <AsyncSelectFilter
                    key={index}
                    url={filter.url}
                    label={filter.label}
                    isMulti={filter.isMulti}
                    name={filter.columnName}
                    onChange={handleOnChange}
                    value={(values && values[filter.columnName]) || ''}
                  />
                )
              }
              default: {
                return <></>
              }
            }
          })}
        </div>
      </div>
      <Divider
        className={classes.divider}
      />
      <Button
        color='primary'
        disabled={!values}
        variant='contained'
        onClick={() => {
          updateTableOptions({
            filtersIsOpen: false,
            filters      : (filters || []).map((filter: any) => {
              return {
                ...filter,
                value: values[filter.columnName]
              };
            }),
          });
        }}
        className={classes.btnFilter}
      >{t('table.filter')}
      </Button>
      <Button
        variant='contained'
        onClick={() => {
          updateTableOptions({
            filtersIsOpen: false,
            filters      : (filters || []).map((filter: any) => {
              return {
                ...filter,
                value: void 0
              };
            }),
          });
        }}
        className={classes.btnFilter}
      >{t('table.reset')}
      </Button>
    </Paper>
  )
}

function FilterTags(
  {
    values,
    setValues,
  }: any
) {
  const classes = useStyles();
  const selectedValues = Object.keys(values || {});

  return (
    <>
      {selectedValues.map((k: any, index: number) => {
        const tmp: any = values[k];
        if (tmp) {
          if (tmp.constructor === String) {
            return (
              <Chip
                label={tmp}
                variant='outlined'
                onDelete={() => {
                  setValues((prevState: any) => filterObject({
                    ...prevState,
                    [k]: void 0,
                  }))
                }}
                key={`FilterTags_${index}`}
                classes={{root: classes.chipRoot}}
              />
            )
          } else if (tmp.constructor === Object) {
            return (
              <Chip
                label={tmp.label}
                variant='outlined'
                onDelete={() => {
                  setValues((prevState: any) => filterObject({
                    ...prevState,
                    [k]: void 0,
                  }))
                }}
                key={`FilterTags_${index}`}
                classes={{root: classes.chipRoot}}
              />
            )
          } else if (tmp.constructor === Array) {
            return tmp.map((aa: any, subIndex: number) => {
              return (
                <Chip
                  label={aa.label}
                  variant='outlined'
                  onDelete={() => {
                    setValues((prevState: any) => filterObject({
                      ...prevState,
                      [k]: [
                        ...tmp.filter((f: any) => f.value !== aa.value)
                      ],
                    }))
                  }}
                  classes={{root: classes.chipRoot}}
                  key={`FilterSTags_${index}_${subIndex}`}
                />
              )
            });
          } else {
            return null;
          }
        } else {
          return null;
        }
      })}
      {!!selectedValues.length && (
        <Divider
          style={{marginTop: 10, marginBottom: 10}}
        />
      )}
    </>
  )
}

function TextFilter(
  {
    label,
    onChange,
    ...rest
  }: any
) {
  return (
    <TextField
      fullWidth
      label={label}
      onChange={(e) => {
        if (typeof onChange === 'function') {
          onChange(e.target.name, e.target.value)
        }
      }}
      {...rest}
    />
  )
}

function SelectFilter(
  {
    label,
    options,
    isMulti,
    onChange,
    ...rest
  }: any
) {
  const classes = useStyles();
  return (
    <Typography
      component='div'
      className={classes.formControl}
    >
      <Typography
        color='primary'
        component='span'
        className={classes.selectLabel}
      >{label}
      </Typography>
      <ReactSelect
        isClearable
        placeholder={''}
        isMulti={isMulti}
        options={options}
        styles={customStyles()}
        closeMenuOnSelect={!isMulti}
        onChange={(v: any, {name}: any) => {
          onChange(name, v);
        }}
        components={{IndicatorSeparator: () => null}}
        {...rest}
      />
    </Typography>
  )
}

function AsyncSelectFilter(
  {
    url,
    label,
    value,
    column,
    isMulti,
    onChange,
    ...rest
  }: any
) {
  const classes = useStyles();
  const accessToken = useSelector(_accessToken);

  const loadOptions = React.useCallback(async (inputValue: string, prevOptions: any[], {pageNumber}: any) => {
    try {
      const response = await fetch(`${url}?searchText=${inputValue}&pageNumber=${pageNumber}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        return {
          options: [],
          hasMore: false,
        }
      } else {
        const {
          data,
        } = await response.json();
        return {
          options   : data.items.map((item: any) => ({
            value: item.id,
            label: item.text,
          })).filter((item: any) => (item.label)),
          hasMore   : data.has_more,
          additional: {
            pageNumber: pageNumber + 1,
          },
        }
      }

    } catch (e) {
      return {
        options: [],
        hasMore: false,
      }
    }
  }, [
    url,
    accessToken,
  ]);

  return (
    <Typography
      component='div'
      className={classes.formControl}
    >
      <Typography
        color='primary'
        component='span'
        className={classes.selectLabel}
      >{label}
      </Typography>
      <AsyncPaginate
        value={value}
        placeholder={''}
        styles={customStyles()}
        isMulti={isMulti}
        loadOptions={loadOptions as any}
        additional={{pageNumber: 1}}
        closeMenuOnSelect={!isMulti}
        components={{IndicatorSeparator: () => null}}
        onChange={(v: any, {name}) => {
          onChange(name, v);
        }}
        {...rest}
      />
    </Typography>
  );
}

const getInputValue = (value?: string): string => (value === undefined ? '' : value);

function DateRangeEditor(
  {
    name,
    value,
    label,
    onChange,
  }: any
) {
  const {t} = useTranslation();
  const [selectionRange, setSelectionRange] = React.useState({
    startDate: new Date(),
    endDate  : new Date(),
    key      : 'selection',
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event);
  };

  return (
    <>
      <TextField
        fullWidth
        label={label}
        placeholder={''}
        InputProps={{
          style       : {fontSize: 14},
          endAdornment: (
            <InputAdornment
              position='end'
            >
              <IconButton
                edge='end'
                disabled={!value}
                color='transparent'
                onClick={(e: any) => {
                  e.stopPropagation();
                  setAnchorEl(null);
                  onChange(name, void 0);
                }}
              ><CloseIcon/>
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          style: {
            paddingTop   : 11,
            paddingBottom: 11,
            height       : 'unset',
          }
        }}
        onClick={(e: any) => {
          handleClick(e.currentTarget)
        }}
        value={getInputValue(value)}
      />
      <Menu
        elevation={0}
        PaperProps={{
          style: {
            borderRadius: 0,
            border      : '1px solid #F5F5F5',
          }
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          style: {
            outline: 'none !important'
          }
        }}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical  : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical  : 'top',
          horizontal: 'center',
        }}
      >
        <div
          style={{
            display      : 'flex',
            flexDirection: 'column',
            outline      : 'none !important'
          }}
        >
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={((range: any) => {
              setSelectionRange(range.selection);
            })}
          />
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              setAnchorEl(null);
              onChange(name, (`${(formatDate(selectionRange.startDate))} - ${formatDate(selectionRange.endDate)}`) || '');
            }}
            style={{
              borderRadius: 0,
              alignSelf   : 'flex-end',
            }}
          >{t('buttons.set')}
          </Button>
        </div>
      </Menu>
    </>
  );
}
