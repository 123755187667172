import React from 'react';
import Progress from '../Progress';
import {Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  gadgetRoot : {
    flexGrow     : 1,
    display      : 'flex',
    flexDirection: 'column',
    padding      : theme.spacing(2),
  },
  gadgetHead : {
    marginBottom: 18,
    display     : 'flex',
    alignItems  : 'center',
  },
  gadgetTitle: {
    marginLeft: 8,
    fontWeight: 'bold',
  },
}));

export default function Gadget(
  {
    icon,
    title,
    stats,
    total,
  }: {
    total: number;
    title: string;
    icon: React.ReactNode;
    stats: { name: string; count: number }[];
  }
) {
  const classes = useStyles();

  return (
    <Typography
      component='div'
      className={classes.gadgetRoot}
    >
      <Typography
        component='div'
        className={classes.gadgetHead}
      >
        {icon}
        <Typography
          color='primary'
          className={classes.gadgetTitle}
        >{title}
        </Typography>
      </Typography>
      <Typography
        component='div'
      >
        {stats.map((i, index) => {
          const value = Number(i.count) || 0;
          const intTotal = Number(total) || 1;
          const percent = Number((value * 100 / intTotal).toFixed(2));

          return (
            <Progress
              value={value}
              label={i.name}
              percent={percent}
              key={`${i.name}_${index}`}
            />
          )
        })}
      </Typography>
    </Typography>
  )
}
