import React from 'react';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import MultiTabs from '../../LanguageTabs';
import {useTranslation} from 'react-i18next';
import AlertMessage from '../../AlertMessage';
import {updateEntityAction} from '../../../store/actions/campaigns';
import {removeLanguageAction} from '../../../store/actions/campaigns';
import {emptyLanguagesAction} from '../../../store/actions/campaigns';
import {updateEntityLangAction} from '../../../store/actions/campaigns';

export default React.memo((
  {
    lang,
    errors,
    actions,
    disabled,
    currentApp,
    removeError,
    defaultLang,
    commonEntity,
  }: any
) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [count, setCount] = React.useState<number>(0);

  const {
    uuid,
  } = useParams<{ uuid: string }>();

  const messageCardLabels = React.useMemo(() => ({
    title                    : t('campaigns.title'),
    message                  : t('campaigns.message'),
    english                  : t('campaigns.english'),
    icon_url                 : t('campaigns.icon_url'),
    image_url                : t('campaigns.image_url'),
    launch_url               : t('campaigns.launch_url'),
    title_placeholder        : t('campaigns.title_placeholder'),
    message_placeholder      : t('campaigns.message_placeholder'),
    icon_url_placeholder     : t('campaigns.icon_url_placeholder'),
    icon_url_helper_text     : t('campaigns.icon_url_helper_text'),
    image_url_helper_text    : t('campaigns.image_url_helper_text'),
    image_url_placeholder    : t('campaigns.image_url_placeholder'),
    launch_url_placeholder   : t('campaigns.launch_url_placeholder'),
    push_notification_message: t('campaigns.push_notification_message'),
  }), [
    t,
  ]);

  const emptyLanguages = React.useCallback(() => {
    dispatch(emptyLanguagesAction());
  }, [
    dispatch,
  ]);

  const removeLanguage = React.useCallback((lang: string) => {
    dispatch(removeLanguageAction(lang));
    if (Number.isInteger(Number(uuid))) {
      setCount(prevState => prevState === 1 ? prevState : prevState + 1)
    }
  }, [
    uuid,
    dispatch,
  ]);

  const copyLanguageData = React.useCallback((id: string) => {
    dispatch(updateEntityLangAction({
      id,
      ...defaultLang
    }));
  }, [
    dispatch,
    defaultLang,
  ]);

  const updateEntity = React.useCallback((inputName: string, value: any) => {
    dispatch(updateEntityAction({
      [inputName]: value,
    }));
    if (Number.isInteger(Number(uuid))) {
      setCount(prevState => prevState === 1 ? prevState : prevState + 1)
    }
  }, [
    uuid,
    dispatch,
  ]);

  const updateEntityLang = React.useCallback((id: string, inputName: string, value: any) => {
    dispatch(updateEntityLangAction({
      id,
      [inputName]: value
    }));
    if (Number.isInteger(Number(uuid)) && (inputName !== 'url')) {
      setCount(prevState => prevState === 1 ? prevState : prevState + 1)
    }
    if (typeof removeError === 'function') {
      removeError(`messages.${id}.${inputName}`);
    }
  }, [
    uuid,
    dispatch,
    removeError,
  ]);

  return (
    <>
      <MultiTabs
        lang={lang}
        errors={errors}
        actions={actions}
        disabled={disabled}
        entity={commonEntity}
        onClose={removeLanguage}
        defaultLang={defaultLang}
        updateEntity={updateEntity}
        emptyLanguages={emptyLanguages}
        tags={currentApp?.launch_url_tags}
        copyLanguageData={copyLanguageData}
        updateEntityLang={updateEntityLang}
        messageCardLabels={messageCardLabels}
      />
      {(count === 1) && Number.isInteger(Number(uuid)) && (
        <AlertMessage
          open={count === 1}
          onClose={() => setCount(prevState => prevState + 1)}
        />
      )}
    </>
  )
})

