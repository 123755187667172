import React from 'react';
import {Button} from '../Button';
import {TextField} from '../Input';
import Box from '@material-ui/core/Box';
import {replaceBetween} from '../../utils';
import {Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {TextFieldProps} from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  tags: {
    display       : 'flex',
    flexWrap      : 'wrap',
    alignItems    : 'center',
    justifyContent: 'center',
    marginBottom  : theme.spacing(3),
    marginTop     : theme.spacing(-2),
    '& button'    : {
      textTransform: 'unset',
      marginTop    : theme.spacing(1),
      marginRight  : theme.spacing(1),
    }
  },
}));

export const TagsInput = React.memo((
  {
    tags,
    value,
    change,
    loading,
    onChange,
    InputProps,
    inputRef: ref,
    disablePreview,
    ...rest
  }: TextFieldProps & {
    tags: any;
    loading?: boolean;
    disableMargin?: boolean;
    disablePreview?: boolean;
    change: (val: string | undefined | unknown | number) => string | undefined | unknown | number;
    color?: 'primary' | 'default' | 'success' | 'error';
  }
) => {
  const classes = useStyles();

  const launchUrlRef = React.createRef<any>();
  const [inputValue, setInputValue] = React.useState<any>('');

  React.useEffect(() => {
    setInputValue(value || '');
  }, [
    value
  ]);

  const handleOnChange = React.useCallback((e) => {
    change(e.target.value);
    setInputValue(e.target.value);
  }, [
    change,
  ]);

  return (
    <>
      <Box
        display='flex'
      >
        <TextField
          loading={loading}
          value={inputValue}
          inputRef={launchUrlRef}
          onChange={handleOnChange}
          {...rest}
        />
      </Box>
      {!!tags && (
        <Tags
          tags={tags}
          change={change}
          classes={classes}
          inputValue={inputValue}
          disabled={rest.disabled}
          launchUrlRef={launchUrlRef}
          setInputValue={setInputValue}
        />
      )}
    </>
  )
});

function Tags(
  {
    tags,
    change,
    classes,
    disabled,
    inputValue,
    launchUrlRef,
    setInputValue,
  }: any
) {
  const handleOnClick = React.useCallback((item: string) => {
    if (!(inputValue || '').includes(tags[item])) {
      const newValue = replaceBetween(
        launchUrlRef.current.value,
        tags[item],
        launchUrlRef.current.selectionStart,
        launchUrlRef.current.selectionEnd
      );
      setInputValue(newValue);
      change(newValue);
    } else {
      const t = getUrlVars(launchUrlRef.current.value || '');
      const a = t[tags[item]];
      if (!a) {
        setInputValue((launchUrlRef.current.value || '').replace(tags[item], ''));
        change((launchUrlRef.current.value || '').replace(tags[item], ''));
      } else {
        setInputValue((launchUrlRef.current.value || '').replace(`${tags[item]}=${a}`, ''));
        change((launchUrlRef.current.value || '').replace(`${tags[item]}=${a}`, ''));
      }
    }
  }, [
    tags,
    change,
    inputValue,
    launchUrlRef,
    setInputValue,
  ]);

  return (
    <Typography
      component='div'
      className={classes.tags}
    >
      {Object.keys(tags || {}).map((item: string, index: number) => (
        <Button
          size='small'
          data-value={item}
          variant='outlined'
          disabled={disabled}
          key={`TaggedItem_${item}`}
          onClick={() => handleOnClick(item)}
          color={(inputValue || '').includes(tags[item]) ? 'primary' : void 0}
        >{item}
        </Button>
      ))}
    </Typography>
  )
}

function getUrlVars(str: string) {
  const vars: any = {};
  str.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m: any, key: any, value: any) => {
    vars[key] = value;
    return vars;
  });
  return vars;
}
