import React from 'react';
import {Card} from 'antd';
import {Space} from 'antd';
import {Edit} from './Edit';
import {Button} from 'antd';
import {Create} from './Create';
import {parse} from 'querystring';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {ucFirst} from '../../../../../../../utils';
import {Text} from '../../../../../../../components/antd/Typography';
import {getPrefixCls} from '../../../../../../../utils/getPrefixCls';
import './index.less';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export function PaymentMethodAndBillingAddress(
  {
    paymentMethod,
    billingAddress,
  }: {
    paymentMethod: null | Record<string, any>;
    billingAddress: null | Record<string, any>;
  }
) {
  const {push} = useHistory();
  const {search} = useLocation();
  const [editForm, toggleEditForm] = React.useState<boolean>(false);
  const [createForm, toggleCreateForm] = React.useState<boolean>(false);

  React.useEffect(() => {
    const params = parse(search.replace('?', ''));
    if (params.showChangeCard === 'true') {
      toggleCreateForm(true);
    }
  }, [
    search,
  ]);

  return (
    <Card
      title='Payment Method and Billing Address'
    >
      <div
        className={getPrefixCls('payment-method-and-billing-address-info')}
      >
        {!!paymentMethod && (
          <div
            className={`${getPrefixCls('payment-method-and-billing-address-card-info')} align-items-flex-start d-inline-flex`}
          >
            <div
              style={{maxWidth: 200}}
            >
              <Text
                strong
                ellipsis={{tooltip: true}}
              >{paymentMethod.billing_address.name}
              </Text>
              <br/>
              <Text>{ucFirst(paymentMethod.card.brand)} ****{paymentMethod.card.last4}</Text>
              <br/>
              <Text>Expires on {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</Text>
            </div>
            <div
              className='m-l-6'
            >
              <div>
                <Text
                  strong
                >Address name
                </Text>
              </div>
              <div
                style={{maxWidth: 200}}
              >
                <Text
                  ellipsis={{tooltip: true}}
                >{paymentMethod.billing_address.address.city}, {paymentMethod.billing_address.address.postal_code}
                </Text>
              </div>
              <div>
                <Text>{paymentMethod.billing_address.address.country?.label}</Text>
              </div>
            </div>
          </div>
        )}
        {!!paymentMethod ? (
          <Space>
            <Button
              type='primary'
              onClick={() => toggleEditForm(prevState => !prevState)}
            >Change Billing address
            </Button>
            <Button
              type='primary'
              onClick={() => toggleCreateForm(prevState => !prevState)}
            >Change Card
            </Button>
          </Space>
        ) : (
          <div>
            <Button
              type='primary'
              onClick={() => toggleCreateForm(prevState => !prevState)}
            >Add Card
            </Button>
          </div>
        )}
      </div>
      {editForm && (
        <Edit
          open={editForm}
          defaultValues={billingAddress}
          onClose={() => toggleEditForm(prevState => !prevState)}
        />
      )}
      {createForm && (
        <Elements
          stripe={stripePromise}
          options={{locale: 'en'}}
        >
          <Create
            open={createForm}
            onClose={() => {
              toggleCreateForm(prevState => !prevState);
              if (search) {
                push({search: ''});
              }
            }}
          />
        </Elements>
      )}
    </Card>
  )
}
