import {Menu} from 'antd';
import React from 'react';
import {Space} from 'antd';
import {Button} from 'antd';
import {Tooltip} from 'antd';
import {Dropdown} from 'antd';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {LocationDescriptor} from 'history';
import useMediaQuery from 'use-media-antd-query';
import {isUUID} from '../../../../../../../utils/isUUID';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import {urlHelper} from '../../../../../../../utils/urlHelper';
import {tidioChat} from '../../../../../../../utils/tidioChatApi';
import QuestionOutlined from '@ant-design/icons/QuestionOutlined';
import {AppRoutes} from '../../../../../../../constants/appRoutes';
import {_userId} from '../../../../../../../store/selectors/application/acl';
import {_canCreateSegment} from '../../../../../../../store/selectors/application/acl';
import {_canCreateCampaign} from '../../../../../../../store/selectors/application/acl';
import {_canCreateTemplate} from '../../../../../../../store/selectors/application/acl';

export function SecondaryActions(
  {
    appUuid,
  }: {
    appUuid: string | undefined;
  }
) {
  const colSize = useMediaQuery();

  return (
    <Space
      size={[
        'sm',
        'xs',
        'md',
      ].includes(colSize) ? 8 : 16}
    >
      <CreateNewMenu
        appUuid={appUuid}
      />
      <HelpMenu/>
    </Space>
  )
}

function HelpMenu() {
  const colSize = useMediaQuery();
  const [{
    tooltipVisible,
    dropdownVisible,
  }, setState] = React.useState<{
    tooltipVisible: boolean,
    dropdownVisible: boolean,
  }>({
    tooltipVisible : false,
    dropdownVisible: false,
  })

  const handleTooltipOnVisibleChange = React.useCallback((visible: boolean) => {
    setState((prevState) => {
      return {
        ...prevState,
        tooltipVisible: visible,
      }
    })
  }, []);

  const handleDropdownOnVisibleChange = React.useCallback((visible: boolean) => {
    setState(() => {
      return {
        tooltipVisible : false,
        dropdownVisible: visible,
      }
    })
  }, []);

  const onClick = React.useCallback(() => {
    tidioChat().popUpOpen();
    setState({
      tooltipVisible : false,
      dropdownVisible: false,
    })
  }, []);

  const overlayMenu = React.useCallback(() => {
    return (
      <Menu>
        <Menu.Item
          onClick={onClick}
          key='contact-support'
        >Contact Support
        </Menu.Item>
      </Menu>
    )
  }, [
    onClick,
  ]);

  return (
    <Dropdown
      arrow
      placement='bottom'
      trigger={['click']}
      overlay={overlayMenu}
      visible={dropdownVisible}
      onVisibleChange={handleDropdownOnVisibleChange}
    >
      <Tooltip
        title='Help'
        onVisibleChange={handleTooltipOnVisibleChange}
        visible={dropdownVisible ? false : tooltipVisible}
      >
        <Button
          type='ghost'
          shape='circle'
          icon={<QuestionOutlined/>}
          size={[
            'sm',
            'xs',
            'md',
          ].includes(colSize) ? 'small' : void 0}
        />
      </Tooltip>
    </Dropdown>
  )
}

function CreateNewMenu(
  {
    appUuid
  }: {
    appUuid: string | undefined;
  }
) {
  const {push} = useHistory();
  const colSize = useMediaQuery();

  const [{
    tooltipVisible,
    dropdownVisible,
  }, setState] = React.useState<{
    tooltipVisible: boolean,
    dropdownVisible: boolean,
  }>({
    tooltipVisible : false,
    dropdownVisible: false,
  })

  const handleTooltipOnVisibleChange = React.useCallback((visible: boolean) => {
    setState((prevState) => {
      return {
        ...prevState,
        tooltipVisible: visible,
      }
    })
  }, []);

  const handleDropdownOnVisibleChange = React.useCallback((visible: boolean) => {
    setState(() => {
      return {
        tooltipVisible : false,
        dropdownVisible: visible,
      }
    })
  }, []);

  const userId = useSelector(_userId);
  const canCreateSegment = useSelector(_canCreateSegment);
  const canCreateTemplate = useSelector(_canCreateTemplate);
  const canCreateCampaign = useSelector(_canCreateCampaign);

  const onClick = React.useCallback((url: string | LocationDescriptor) => () => {
    if (typeof url === 'string' && url.includes('push-notifications/create')) {
      localStorage.setItem(`create_push_notification_tooltip_has_shown_${userId}`, 'true')
    }
    setState({
      tooltipVisible : false,
      dropdownVisible: false,
    });
    push(url);
  }, [
    push,
    userId,
  ]);

  const overlayMenu = React.useCallback(() => {
    return (
      <Menu>
        <Menu.ItemGroup
          title='Create New'
        >
          {canCreateCampaign && (
            <Menu.Item
              key='pushNotification'
              disabled={!canCreateCampaign}
              onClick={onClick(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_CREATE, {appUuid}))}
            >Push Notification
            </Menu.Item>
          )}
          <Menu.Item
            disabled
            key='Automation'
          >Automation
          </Menu.Item>
          {canCreateSegment && (
            <Menu.Item
              key='segment'
              disabled={!canCreateSegment}
              onClick={onClick(urlHelper(AppRoutes.SEGMENTS_CREATE, {appUuid}))}
            >Segment
            </Menu.Item>
          )}
          {canCreateTemplate && (
            <Menu.Item
              key='template'
              disabled={!canCreateTemplate}
              onClick={onClick(urlHelper(AppRoutes.TEMPLATES_CREATE, {appUuid}))}
            >Template
            </Menu.Item>
          )}
        </Menu.ItemGroup>
      </Menu>
    )
  }, [
    appUuid,
    onClick,
    canCreateSegment,
    canCreateTemplate,
    canCreateCampaign,
  ]);

  return isUUID(appUuid) ? (
    <Dropdown
      arrow
      placement='bottom'
      trigger={['click']}
      overlay={overlayMenu}
      visible={dropdownVisible}
      onVisibleChange={handleDropdownOnVisibleChange}
    >
      <Tooltip
        title='Create New'
        onVisibleChange={handleTooltipOnVisibleChange}
        visible={dropdownVisible ? false : tooltipVisible}
      >
        <Button
          type='ghost'
          shape='circle'
          icon={<PlusOutlined/>}
          size={[
            'sm',
            'xs',
            'md',
          ].includes(colSize) ? 'small' : void 0}
        />
      </Tooltip>
    </Dropdown>
  ) : null;
}
