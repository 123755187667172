import {getState} from '../index';
import {createSelector} from 'reselect';

export const _subscriptionsAndBilling = createSelector(getState, (state) => {
  return state.subscriptionsAndBilling;
});

export const _downgradeState = createSelector(_subscriptionsAndBilling, (state) => {
  return state.downgradeState;
});

export const _existingCardErrors = createSelector(_subscriptionsAndBilling, (portal) => {
  return portal?.existingCardErrors;
});

export const _showDowngradeModal = createSelector(_subscriptionsAndBilling, (state) => {
  return state.showDowngradeModal;
});

export const _downgradeErrors = createSelector(_subscriptionsAndBilling, (state) => {
  return state.downgradeErrors;
});

export const _portal = createSelector(_subscriptionsAndBilling, (state) => {
  return state.portal;
});

export const _scheduledPlan = createSelector(_portal, (portal) => {
  return portal?.scheduled_plan;
});

export const _subscriptionPlans = createSelector(_subscriptionsAndBilling, (state) => {
  return state.subscriptionPlans?.data;
});

export const _paidPlans = createSelector(_subscriptionPlans, (plans) => {
  const filteredPlans = plans?.filter((p: Record<string, any>) => p.unit_amount > 0);
  const filteredPlansLength = filteredPlans.length;

  return filteredPlans?.map((p: Record<string, any>, index: number) => {
    const markPosition = 100 * (index / (filteredPlansLength - 1))
    return {
      ...p,
      markPosition
    }
  });
});

export const _subscriptionPlansFiniteState = createSelector(_subscriptionsAndBilling, (state) => {
  return state.subscriptionPlans?.subscriptionPlansFiniteState;
});

export const _freePlan = createSelector(_subscriptionPlans, (subscriptionPlans) => {
  return (subscriptionPlans || []).find((sp: any) => sp.plan_slug.toLowerCase().startsWith('free'));
});

export const _currentPlan = createSelector(_portal, (portal) => {
  return portal?.current_plan;
});

export const _isCustomPlan = createSelector(_currentPlan, (currentPlan) => {
  return !!currentPlan?.is_custom
});

export const _isAppSumoPlan = createSelector(_currentPlan, (currentPlan) => {
  return !!currentPlan?.app_sumo_plan
});

export const _paymentMethod = createSelector(_portal, (portal) => {
  return portal?.payment_method;
});

export const _billingAddress = createSelector(_paymentMethod, (paymentMethod) => {
  return {
    id         : paymentMethod?.id,
    city       : paymentMethod?.billing_address?.address?.city,
    state      : paymentMethod?.billing_address?.address?.state,
    line1      : paymentMethod?.billing_address?.address?.line1,
    line2      : paymentMethod?.billing_address?.address?.line2,
    country    : paymentMethod?.billing_address?.address?.country,
    postal_code: paymentMethod?.billing_address?.address?.postal_code,
  };
});

export const _invoices = createSelector(_portal, (portal) => {
  return (portal?.invoices || []).filter((i: any) => i?.data?.status !== 'draft');
});

export const _loading = createSelector(_portal, (portal) => {
  return !!portal?.loading;
});

export const _portalFiniteState = createSelector(_portal, (portal) => {
  return portal?.portalFiniteState;
});

export const _skippingCheckout = createSelector(_portal, (portal) => {
  return !!portal?.skippingCheckout;
});

export const _prorationTotal = createSelector(_subscriptionsAndBilling, (state) => {
  return state?.prorationTotal;
});

export const _prorationTotalFiniteState = createSelector(_prorationTotal, (prorationTotal) => {
  return prorationTotal?.prorationTotalFiniteState;
});

export const _isDowngrading = createSelector(_subscriptionsAndBilling, (state) => {
  return !!state?.downgrade?.loading;
});
