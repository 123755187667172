import React from 'react';
import {PickerProps} from 'emoji-mart';
import {Picker as NativePicker} from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

export const Picker: React.FC<PickerProps> = (props) => {
  return (
    <NativePicker
      {...props}
    />
  )
};
