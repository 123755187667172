import React from 'react';
import {useWatch} from 'react-hook-form';
import {Control} from 'react-hook-form/dist/types/form';
import {Text} from '../../../../../../../../../components/antd/Typography';
import {Title} from '../../../../../../../../../components/antd/Typography';
import {formatCount} from '../../../../../../../../../utils/abbreviateNumber';

export function GrowthPlanHeader(
  {
    control,
    subscriptionPlans,
  }: {
    control: Control<any>;
    subscriptionPlans: Record<string, any>[];
  }
) {
  const plan = useWatch({
    control,
    name: 'plan_slug',
  });
  const planObj = subscriptionPlans?.find((sp: any) => plan === sp.plan_slug);

  return (
    <div
      className='m-b-4'
    >
      <Title
        level={3}
      >${planObj?.unit_amount}
      </Title>
      <div>
        <Text
          type='secondary'
        >
          <Text
            type='secondary'
            fontWeight='bold'
          >{formatCount(planObj?.free_displays_count, true)}
          </Text>
          <Text
            type='secondary'
          > delivered messages included
          </Text>
        </Text>
      </div>
      <div>
        <Text
          type='secondary'
        >
          <Text
            type='secondary'
            fontWeight='bold'
          >${planObj?.price_per_1000}
          </Text>
          <Text
            type='secondary'
          > per
          </Text>
          <Text
            type='secondary'
            fontWeight='bold'
          > 1K
          </Text>
          <Text
            type='secondary'
          > delivered message overage
          </Text>
        </Text>
      </div>
    </div>
  )
}