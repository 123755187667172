import {getState} from '../index';
import {createSelector} from 'reselect';

export const getImages = createSelector(getState, (state) => {
  return state.builder.images;
});

export const getImagesData = createSelector(getImages, (icons) => {
  return icons.dataOptions.entities;
});

export const getFetchState = createSelector(getImages, (icons) => {
  return icons.dataOptions.fetched;
});

export const getTotal= createSelector(getImages, (icons) => {
  return icons.dataOptions.total;
});

export const getFilters = createSelector(getImages, (icons) => {
  return icons.filters;
});
