import React from 'react';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import debounce from 'lodash/debounce';
import {Select, Form} from 'antd';
import {SelectProps} from 'antd/lib/select';
import {_apiBaseUrl, _accessToken} from '../../../store/selectors/application';

const { Option } = Select;
type FormLayout = 'horizontal' | 'inline' | 'vertical';

interface IProps extends SelectProps {
  dataKey: string;
  titleKey?: string;
  label?: string | JSX.Element;
  layout?: FormLayout;
  valueKey?: string | number;
  fetchData?: any;
  optionDisabled?: () => void;
};
const initialPageNumber = 1;
const debounceTimeout = 500;

const DynamicSelect: React.FC<IProps> = ({ dataKey, titleKey, valueKey, fetchData, optionDisabled,  label, layout, ...props }) => {
  const {appUuid} = useParams<{ appUuid: string }>();
  const apiBaseUrl = useSelector(_apiBaseUrl);
  const accessToken = useSelector(_accessToken);

  const [dataForSelect, setDataForSelect] = React.useState([]);
  const [total, setTotal] = React.useState<number>(0);
  const [params, setParams] = React.useState<{searchText: string, pageNumber: number}>({searchText: '', pageNumber: initialPageNumber});

  const getData = React.useCallback(async () => {
    const response = await fetchData(appUuid, apiBaseUrl, accessToken, params.pageNumber, params.searchText);

    if (response?.[dataKey]) {
      const newData = response[dataKey].map((item: any) => {
        return {
          value: valueKey ? item[valueKey] : item.id,
          label: titleKey ? item[titleKey] : item.value,
        }
      })
      setDataForSelect(dataForSelect.concat(newData));
      setTotal(response.total);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, appUuid, apiBaseUrl, accessToken, params.pageNumber, params.searchText, dataKey]);

  React.useEffect(() => {
    getData();
  },[getData, params]);

  const onSearchDebounceFetcher = React.useMemo(() => {
    const loadOptions = (searchText: string) => {
      setDataForSelect([]);
      setParams({ pageNumber: initialPageNumber, searchText });
    };
    return debounce(loadOptions, debounceTimeout);
  }, []);

  const onScrollDebounceFetcher = React.useMemo(() => {
    const loadOptions = (e: any) => {
      const current = e.target;
      const isScrollEnds = current.offsetHeight + current.scrollTop >= current.scrollHeight;
      if (isScrollEnds && total > dataForSelect.length) {
        setParams({...params, pageNumber: params.pageNumber + 1});
      }
    };
    return debounce(loadOptions, debounceTimeout);
  }, [dataForSelect.length, params, total]);

  const options = React.useMemo(() => {
    return dataForSelect.map((item, index) =>
      <Option key={index} value={item.value} disabled={optionDisabled}>
        {item.label}
      </Option>
    );
  }, [dataForSelect, optionDisabled]);

  return (
    <Form layout={layout || 'vertical'}>
      {label &&
        <Form.Item
          label={label}
        >
          <Select
            showSearch
            allowClear
            filterOption={false}
            onSearch={onSearchDebounceFetcher}
            onPopupScroll={onScrollDebounceFetcher}
            {...props}
          >
            {options}
          </Select>
        </Form.Item>
      }
      {!label &&
        <Select
          showSearch
          allowClear
          filterOption={false}
          onSearch={onSearchDebounceFetcher}
          onPopupScroll={onScrollDebounceFetcher}
          {...props}
        >
          {options}
        </Select>
      }
    </Form>
  );
};

export default DynamicSelect;
