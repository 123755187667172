import produce from 'immer';
import {Builder} from '../../../../types/builder';
import {ActionTypes} from '../../../constants/builders';
import {ActionTypes as CoreActionTypes} from '../../../constants/application';

const InitialState: Builder.BuildersReducer = {
  icons: {
    fetched: false,
    entities: [],
    total: 0,
  },
  images: {
    fetched: false,
    entities: [],
    total: 0,
  },
  titles: {
    fetched: false,
    entities: [],
    total: 0,
  },
  messages: {
    fetched: false,
    entities: [],
    total: 0,
  },
  filters: {
    pageSize: 5,
    parentTags: [],
    iconTags: [],
    iconPageNumber: 1,
    imageTags: [],
    imagePageNumber: 1,
    titleSearchText: '',
    titlePageNumber: 1,
    messageSearchText: '',
    messagePageNumber: 1,
  },
};

export const builders = produce((draft, action) => {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.BUILDER_BUILDERS_UPDATE_FILTERS: {
      draft.filters = {
        ...draft.filters,
        ...payload,
      };
      return;
    }
    case ActionTypes.BUILDER_BUILDERS_SET_ICON_DATA: {
      draft.icons.entities = draft.icons.entities.concat(payload.rows);
      draft.icons.total = payload.total || 0;
      draft.icons.fetched = true;
      return;
    }
    case ActionTypes.BUILDER_BUILDERS_CLEAUP_ICON_DATA: {
      draft.icons.entities = [];
      draft.icons.total =  0;
      return;
    }
    case ActionTypes.BUILDER_BUILDERS_SET_IMAGE_DATA: {
      draft.images.entities = draft.images.entities.concat(payload.rows);
      draft.images.total = payload.total || 0;
      draft.images.fetched = true;
      return;
    }
    case ActionTypes.BUILDER_BUILDERS_CLEAUP_IMAGE_DATA: {
      draft.images.entities = [];
      draft.images.total =  0;
      return;
    }
    case ActionTypes.BUILDER_BUILDERS_SET_TITLE_DATA: {
      draft.titles.entities = draft.titles.entities.concat(payload.rows);
      draft.titles.total = payload.total || 0;
      draft.titles.fetched = true;
      return;
    }
    case ActionTypes.BUILDER_BUILDERS_CLEAUP_TITLE_DATA: {
      draft.titles.entities = [];
      draft.titles.total =  0;
      return;
    }
    case ActionTypes.BUILDER_BUILDERS_SET_MESSAGE_DATA: {
      draft.messages.entities = draft.messages.entities.concat(payload.rows);
      draft.messages.total = payload.total || 0;
      draft.messages.fetched = true;
      return;
    }
    case ActionTypes.BUILDER_BUILDERS_CLEAUP_MESSAGE_DATA: {
      draft.messages.entities = [];
      draft.messages.total =  0;
      return;
    }
    case ActionTypes.BUILDER_BUILDERS_SET_LOADING: {
      draft[payload as 'icons' | 'images' | 'titles' | 'messages'].fetched = false;
      return;
    }
    case ActionTypes.BUILDER_BUILDERS_CLEAUP: {
      return {...InitialState};
    }
    case CoreActionTypes.LOGOUT: {
      return {...InitialState};
    }
    default:
      return void 0;
  }
}, InitialState);
