export enum Permissions {
  READ            = 'r',
  SEND            = 's',
  UPDATE          = 'u',
  CREATE          = 'c',
  DELETE          = 'd',
  VISIBLE         = 'v',
  CANCEL          = 'cl',
  DISPLAY_UPGRADE = 'd_u',
  VISIBLE_DATA    = 'v_d',
}

export const FEATURES = 'frs';
export const FEATURE_GROUP = 'fg';
