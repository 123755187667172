export enum ActionTypes {
  BUILDER_BUILDERS_UPDATE_FILTERS = 'builder/builders/UPDATE_FILTERS',
  BUILDER_BUILDERS_SET_ICON_DATA = 'builder/builders/SET_ICON_DATA',
  BUILDER_BUILDERS_CLEAUP_ICON_DATA = 'builder/builders/CLEAUP_ICON_DATA',
  BUILDER_BUILDERS_SET_IMAGE_DATA = 'builder/builders/SET_IMAGE_DATA',
  BUILDER_BUILDERS_CLEAUP_IMAGE_DATA = 'builder/builders/CLEAUP_IMAGE_DATA',
  BUILDER_BUILDERS_SET_TITLE_DATA = 'builder/builders/SET_TITLE_DATA',
  BUILDER_BUILDERS_CLEAUP_TITLE_DATA = 'builder/builders/CLEAUP_TITLE_DATA',
  BUILDER_BUILDERS_SET_MESSAGE_DATA = 'builder/builders/SET_MESSAGE_DATA',
  BUILDER_BUILDERS_CLEAUP_MESSAGE_DATA = 'builder/builders/CLEAUP_MESSAGE_DATA',
  BUILDER_BUILDERS_SET_LOADING = 'builder/builders/SET_LOADING',
  BUILDER_BUILDERS_CLEAUP = 'builder/builders/CLEAUP',
};
