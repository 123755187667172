import clsx from 'clsx';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  circularProgress: {
    animationDuration: '550ms',
    margin           : '0 auto',
  },
}));

export default function CircularProgress(
  {
    text,
    size,
    className,
  }: {
    size?: number;
    text?: string;
    className?: string
  }
) {
  const classes = useStyles();
  return (
    <>
      <MuiCircularProgress
        thickness={4}
        disableShrink
        size={size || 30}
        variant='indeterminate'
        className={clsx(classes.circularProgress, className)}
      />
      {!!text && (
        <Typography
          align='center'
        >{text}
        </Typography>
      )}
    </>
  );
}
