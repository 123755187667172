import React from 'react';
import {Button} from 'antd';
import {Tooltip} from 'antd';
import {Popconfirm} from 'antd';
import {TooltipProps} from 'antd/lib/tooltip';
import {ButtonProps} from 'antd/lib/button/button';
import {PopconfirmProps} from 'antd/lib/popconfirm';

export function TableAction(
  {
    buttonProps,
    tooltipProps,
    popconfirmProps,
  }: {
    buttonProps: ButtonProps;
    tooltipProps: TooltipProps;
    popconfirmProps?: PopconfirmProps;
  }
) {
  const [{
    tooltipVisible,
    dropdownVisible,
  }, setState] = React.useState<{
    tooltipVisible: boolean,
    dropdownVisible: boolean,
  }>({
    tooltipVisible : false,
    dropdownVisible: false,
  });

  const handleTooltipOnVisibleChange = React.useCallback((visible: boolean) => {
    setState((prevState) => ({
      ...prevState,
      tooltipVisible: visible,
    }))
  }, []);

  const handlePopconfirmOnVisibleChange = React.useCallback((visible: boolean) => {
    setState(() => ({
      tooltipVisible : false,
      dropdownVisible: visible,
    }))
  }, []);

  return (
    <Tooltip
      {...tooltipProps}
      onVisibleChange={handleTooltipOnVisibleChange}
      visible={dropdownVisible ? false : tooltipVisible}
    >
      {popconfirmProps ? (
        <Popconfirm
          {...popconfirmProps}
          visible={dropdownVisible}
          onVisibleChange={handlePopconfirmOnVisibleChange}
        >
          <Button
            {...buttonProps}
          />
        </Popconfirm>
      ) : (
        <Button
          {...buttonProps}
        />
      )}
    </Tooltip>
  )
}