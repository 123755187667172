import React from 'react';
import Box from '@material-ui/core/Box';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import {Control} from 'react-hook-form/dist/types/form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {IOSSwitch} from '../../../../../../../components/IOSSwitch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(() => ({
  formControlLabel: {
    marginLeft : 0,
    marginRight: 0,
  },
}));

export function DimTheBackground(
  {
    control,
    disabled,
  }: {
    disabled?: boolean;
    control: Control<Record<string, any>>;
  }
) {
  const classes = useStyles();

  return (
    <Box
      display='flex'
      margin={-0.25}
      alignItems='center'
    >
      <Controller
        name='dimmed'
        control={control}
        render={(
          {
            field     : {
              ref,
              value,
              ...fieldRest
            },
            fieldState: {
              error,
            }
          }
        ) => {
          return (
            <FormControlLabel
              value={value}
              inputRef={ref}
              label={<Label/>}
              checked={!!value}
              disabled={disabled}
              labelPlacement='end'
              control={<IOSSwitch size='small'/>}
              classes={{root: classes.formControlLabel}}
              {...fieldRest}
            />
          )
        }}
      />
    </Box>
  )
}

function Label() {
  const {t} = useTranslation();

  return (
    <Box
      marginLeft={.5}
    >
      <Typography
        color='inherit'
        variant='body1'
        component='span'
      >{t('app_settings.dim_the_background')}
      </Typography>
    </Box>
  )
}