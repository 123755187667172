import React from 'react';
import List from '../List';

export default function ListContainer(
  {
    width,
    onClick,
    selected,
    aspectRatio,
    onCloseGallery,
    uploaderIsOpen,
  }: {
    width?: any;
    selected?: any;
    aspectRatio: number;
    onCloseGallery?: any;
    uploaderIsOpen?: boolean;
    onClick?: (selected: any) => any;
  }
) {
  if (!!uploaderIsOpen) {
    return <></>
  }

  return (
    <List
      width={width}
      onClick={onClick}
      selected={selected}
      aspectRatio={aspectRatio}
      onCloseGallery={onCloseGallery}
    />
  )
}