import React from 'react'
import TagsProvider from './Tags/Provider';
import ActionProvider from './Action/Provider';
import {Plugin} from '@devexpress/dx-react-core';
import TimezoneProvider from './Timezone/Provider';
import ConversionsSumProvider from './ConversionsSum/Provider';

export default function Providers(
  {
    actions,
  }: any
) {
  return React.useMemo(() => (
    <Plugin>
      <ActionProvider
        for={[
          'actions',
        ]}
        actions={actions}
      />
      <TagsProvider
        for={[
          'tags',
        ]}
        actions={actions}
      />
      <TimezoneProvider
        for={[
          'time_zone',
        ]}
        actions={actions}
      />
      <ConversionsSumProvider
        for={[
          'conversions_sum',
        ]}
        actions={actions}
      />
    </Plugin>
  ), [
    actions,
  ])
}
