import React from 'react'
import {Plugin} from '@devexpress/dx-react-core';
import DateTypeProvider from './DateType/Provider';
import DATE_COLUMNS from '../../../constants/dateColumns';

export default function DefaultProviders() {
  return (
    <Plugin>
      <DateTypeProvider
        for={DATE_COLUMNS.map(i => i.column)}
      />
    </Plugin>
  )
}
