import React from 'react';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useTitle} from '../../../../../hooks/useTitle';
import {FiniteStates} from '../../../../../constants/finiteStates';
import {_portalFiniteState} from '../../../../../store/selectors/portal';
import {_setPortal} from '../../../../../store/actions/subscriptionsAndBilling';
import {_subscriptionPlansFiniteState} from '../../../../../store/selectors/portal';
import {_setSubscriptionPlans} from '../../../../../store/actions/subscriptionsAndBilling';
import {_fetchPortal} from '../../../../../store/actions/subscriptionsAndBilling/fetchPortal';
import {_fetchSubscriptionPlans} from '../../../../../store/actions/subscriptionsAndBilling/fetchSubscriptionPlans';

export function usePaymentsAndSubscriptions() {
  const dispatch = useDispatch();
  const portalFiniteState = useSelector(_portalFiniteState);
  const subscriptionPlansFiniteState = useSelector(_subscriptionPlansFiniteState);

  const loading = [
    FiniteStates.IDLE,
    FiniteStates.LOADING,
  ].includes(portalFiniteState) || [
    FiniteStates.IDLE,
    FiniteStates.LOADING,
  ].includes(subscriptionPlansFiniteState);

  useTitle('Subscriptions and Billing | SmartPush');

  React.useEffect(() => {
    dispatch(_fetchPortal());
    dispatch(_fetchSubscriptionPlans());

    return function () {
      dispatch(_setPortal({
        loading          : void 0,
        portalFiniteState: FiniteStates.IDLE
      }));
      dispatch(_setSubscriptionPlans({
        loading                     : void 0,
        subscriptionPlansFiniteState: FiniteStates.IDLE,
      }));
    }
  }, [
    dispatch,
  ]);

  return {
    loading,
  }
}
