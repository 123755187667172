import {_dataOptions} from './index';
import {MutableRefObject} from 'react';
import {_skipForNow} from './skipForNow';
import {Globals} from '../../../types/globals';
import {dataLayer} from '../../../utils/dataLayer';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _sendToDeveloper = (
  {
    email,
    appUuid,
    onSuccess,
    fireIntegrationSkipped,
  }: {
    email: string;
    appUuid: string;
    onSuccess?: () => void;
    fireIntegrationSkipped?: MutableRefObject<boolean>;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    sendingToDeveloper: FiniteStates.LOADING,
  }));

  const {
    body: {
      message,
    },
    hasError,
  } = await api.post(urlHelper(ApiRoutes.SEND_TO_DEVELOPER, {
    appUuid,
  }), {
    email,
  });

  if (hasError) {
    dispatch(_dataOptions({
      emailError: message,
    }));

    dispatch(_dataOptions({
      sendingToDeveloper: FiniteStates.FAILURE,
    }));
  } else {
    if (fireIntegrationSkipped) {
      fireIntegrationSkipped.current = false;
    }
    dataLayer({
      app_uuid: appUuid,
      event   : 'send_to_developer',
    });

    await dispatch(_skipForNow({
      appUuid,
      disableIntegrationSkip: true,
    }));

    dispatch(_dataOptions({
      emailError: void 0,
    }));

    dispatch(_dataOptions({
      sendingToDeveloper: FiniteStates.SUCCESS,
    }));

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  }
};
