import {History} from 'history';
import {notification} from 'antd';
import {_toggleErrors} from './actions';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {AppRoutes} from '../../../constants/appRoutes';
import {Automations} from '../../../types/automations';

export const createOrUpdateAutomation = (appUuid: string, autData: Automations.AutomationDataType, history: History): Globals.ThunkAction => {
  return async (dispatch, _, { api }) => {

    let url = autData.id ?
      urlHelper(ApiRoutes.UPDATE_AUTOMATION, { appUuid, id: autData.id })
    : urlHelper(ApiRoutes.CREATE_AUTOMATION, { appUuid });

    const { hasError, body: { data, message } } = await api.post(url, autData);

    if (hasError) {
      dispatch(_toggleErrors(data));
      notification.error({ message: message || 'Something went wrong' });
    } else {
      history.push(urlHelper(AppRoutes.AUTOMATIONS, { appUuid: appUuid }));
      notification.success({message});
    }
  }
};

export const getPerAutomationData = (appUuid: string, automationId: string): Globals.ThunkAction => {
  return async (_, getState, { api }) => {
    const { hasError, body: { data, message } } = await api.get(urlHelper(ApiRoutes.GET_AUTOMATION, { appUuid, id: automationId }));

    if (hasError) {
      // TODO: check status 404
      notification.error({message});
      return;
    }
    return data;
  }
};
