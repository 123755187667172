import {getState} from '../index';
import {createSelector} from 'reselect';

export const _automations = createSelector(getState, (state) => {
  return state.automations;
});

export const _automationsData = createSelector(_automations, (data) => {
  return data.entities;
});

export const _automationsDatOptions = createSelector(_automations, (data) => {
  return data.dataOptions;
});

export const _pageSize = createSelector(_automationsDatOptions, (options) => {
  return options.pageSize;
});

export const _pageNumber = createSelector(_automationsDatOptions, (options) => {
  return options.pageNumber;
});

export const _sortName = createSelector(_automationsDatOptions, (options) => {
  return options.sortName;
});

export const _sortOrder = createSelector(_automationsDatOptions, (options) => {
  return options.sortOrder;
});

export const _total = createSelector(_automationsDatOptions, (options) => {
  return options.total;
});

export const _fetchingAutomations = createSelector(_automationsDatOptions, (options) => {
  return options.fetchingAutomationsState;
});

export const _searchText = createSelector(_automationsDatOptions, (options) => {
  return options.searchText;
});

export const _getErrorsTypes = createSelector(_automations, (data) => {
  return data.errors;
});
