import {IObject} from '../../../../types';
import {ActionTypes} from '../../../constants/gallery';
import {ActionTypes as CoreActionTypes} from '../../../constants/application';

const InitialState: IObject = {
  byId        : {},
  allIds      : [],
  loading     : false,
  errors      : void 0,
  entity      : void 0,
  actionDialog: void 0,
};

export default (state = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.WEB_NOTIFY_GALLERY_SET_DATA: {
      const byId: any = {};
      const allIds: any[] = [];
      (payload as Array<any>).forEach((
        {
          id,
          full_url,
          mime_type,
          file_name,
          ...rest
        }
      ) => {
        allIds.push(id);
        byId[id] = {
          id,
          full_url,
          mime_type,
          file_name,
          thumbnailWidth : 1,
          thumbnailHeight: 1,
          tags           : [
            {
              value: mime_type, title: mime_type
            }
          ],
          thumbnail      : full_url,
          src            : full_url,
          caption        : file_name,
          ...rest
        };
      });
      return {
        ...state,
        byId,
        allIds,
      };
    }
    case ActionTypes.WEB_NOTIFY_GALLERY_EMPTY_DATA: {
      return InitialState;
    }
    case ActionTypes.WEB_NOTIFY_GALLERY_DATA_OPTIONS: {
      return {
        ...state,
        dataOptions: {
          ...state.dataOptions,
          ...payload
        }
      }
    }
    case ActionTypes.WEB_NOTIFY_GALLERY_TOGGLE_DIALOG: {
      return {
        ...state,
        actionDialog: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_GALLERY_TOGGLE_ERRORS: {
      return {
        ...state,
        errors: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_GALLERY_TOGGLE_ENTITY: {
      return {
        ...state,
        entity: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_GALLERY_TOGGLE_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case CoreActionTypes.LOGOUT: {
      return InitialState
    }
    default:
      return state;
  }
};
