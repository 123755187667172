const DATE_COLUMNS: {
  column: string;
  format: string;
}[] = [
  {
    column: 'created_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'read_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'updated_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'completed_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'uploaded_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'wait_until',
    format: 'YYYY-MM-DD hh:mm A',
  },
  {
    column: 'scheduled_at',
    format: 'YYYY-MM-DD hh:mm A',
  },
  {
    column: 'last_closed_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'last_active_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'last_opened_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'last_checked_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'last_clicked_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'last_displayed_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'last_converted_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'expires_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'plan_started_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'last_sent_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
  {
    column: 'last_visited_at',
    format: 'YYYY-MM-DD hh:mm:ss A',
  },
];

export default DATE_COLUMNS;
