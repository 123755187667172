import produce from 'immer';
import {Builder} from '../../../../types/builder';
import {ActionTypes} from '../../../constants/icons';
import {ActionTypes as CoreActionTypes} from '../../../constants/application';

const InitialState: Builder.IconsReducer = {
  dataOptions: {
    fetched: false,
    entities: [],
    total: 0,
  },
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortOrder: 'desc',
    sortName: 'id',
    tags: [],
  },
};

export const icons = produce((draft, action) => {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.BUILDER_ICONS_SET_DATA: {
      draft.dataOptions.entities = payload.rows;
      draft.dataOptions.total = payload.total || 0;
      draft.dataOptions.fetched = true;
      return;
    }
     case ActionTypes.BUILDER_ICONS_DELETE_DATA: {
      const filteredData = draft.dataOptions.entities.filter((item: Builder.IconEntity) => item.id !== payload);
      draft.dataOptions.entities = filteredData;
      return;
    }
    case ActionTypes.BUILDER_ICONS_SET_LOADING: {
      draft.dataOptions.fetched = payload || false;
      return;
    }
    case ActionTypes.BUILDER_ICONS_UPDATE_FILTERS: {
      draft.filters = {
        ...draft.filters,
        ...payload,
      };
      return;
    }
    case CoreActionTypes.LOGOUT: {
      return {...InitialState}
    }
    default:
      return void 0;
  }
}, InitialState);
