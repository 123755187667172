import React from 'react';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme: Theme) => ({
  customAdornment: {
    fontSize       : 14,
    backgroundColor: '#EAEAEA',
    padding        : theme.spacing(.5),
    paddingTop     : 2,
  },
}));

export function TTLEndAdornment() {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <InputAdornment
      position='end'
    >
      <Typography
        color='inherit'
        component='span'
        className={classes.customAdornment}
      >{t('app_settings.minutes')}
      </Typography>
    </InputAdornment>
  )
}