import App from './App';
import React from 'react';
import loadI18n from './i18n';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {setEnv} from './utils/setEnv';
import {configureStore} from './store';
import {upgrade} from './utils/upgrade';
import * as Sentry from '@sentry/react';
import {AppProvider} from './providers/App';
import {Integrations} from '@sentry/tracing';
import {I18nextProvider} from 'react-i18next';
import {themeOptions} from './constants/theme';
import {PersistGate} from 'redux-persist/integration/react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const gTagManagerId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
if (!gTagManagerId) {
  console.error('Missing google tag manager id')
}

Sentry.init({
  tracesSampleRate: 1.0,
  environment     : process.env.NODE_ENV,
  dsn             : process.env.REACT_APP_SENTRY_DSN,
  integrations    : [new Integrations.BrowserTracing()],
});

async function main() {
  const {
    store,
    persistor,
  } = configureStore();

  await upgrade(persistor);
  const darkTheme = createMuiTheme(themeOptions);
  setEnv(store);

  const i18n = await loadI18n();
  const {REACT_APP_RE_CAPTCHA_KEY} = process.env;

  ReactDOM.render(
    <I18nextProvider
      i18n={i18n}
      defaultNS='common'
    >
      <GoogleReCaptchaProvider
        reCaptchaKey={REACT_APP_RE_CAPTCHA_KEY}
      >
        <Provider
          store={store}
        >
          <PersistGate
            loading={null}
            persistor={persistor}
          >
            <ThemeProvider
              theme={darkTheme}
            >
              <AppProvider>
                <App/>
              </AppProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </GoogleReCaptchaProvider>
    </I18nextProvider>,
    document.getElementById('root')
  );
}

window.onload = function () {
  main().catch(function (e) {
    Sentry.captureException(e);
  });

  window.addEventListener('storage', (e) => {
    if ((e.key === 'access_token') && (e.oldValue !== e.newValue)) {
      window.location.replace('/');
    }
  });
};
