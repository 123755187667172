import React from 'react';
import {Unauthorized} from '../../../components/antd/Unauthorized';

export function UnauthorizedProvider(
  {
    children,
  }: {
    children: React.ReactNode;
  }
) {
  return (
    <>
      {children}
      <Unauthorized/>
    </>
  )
}