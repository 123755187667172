import {notification} from 'antd';
import {_setProrationTotal} from './index';
import {Globals} from '../../../types/globals';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _updateProrationTotal = (plan: string): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_setProrationTotal({
    prorationTotalFiniteState: FiniteStates.LOADING,
  }));

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.get(`${ApiRoutes.SUBSCRIPTION_UPDATE_PRORATION_TOTAL}?plan_slug=${plan}`);

  if (hasError) {
    notification.error({message});
  } else {
    dispatch(_setProrationTotal({
      ...data,
      prorationTotalFiniteState: FiniteStates.SUCCESS,
    }));
  }
};
