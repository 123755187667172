import clsx from 'clsx';
import {Col} from 'antd';
import {Row} from 'antd';
import React from 'react';
import {Form} from 'antd';
import {Input} from 'antd';
import {Controller} from 'react-hook-form';
import {ICreateAppForm} from '../../useUpsertApp';
import {Control} from 'react-hook-form/dist/types/form';
import {Text} from '../../../../../../../components/antd/Typography';
import {transformUrl} from '../../../../../../../utils/transformUrl';

export function SiteUrlComponent(
  {
    control,
  }: {
    control: Control<ICreateAppForm>
  }
) {
  const [isFocuses, toggleFocusState] = React.useState<boolean>(false);

  const handleOnBlur = (
    value: any,
    onBlur: () => void,
    onChange: (...event: any[]) => void
  ) => () => {
    onBlur();
    toggleFocusState(false);
    onChange(transformUrl(value));
  }

  return (
    <Row
      gutter={24}
      align='middle'
      justify='space-between'
      className='create-app-site-info-row'
    >
      <Col
        xs={24}
        sm={12}
      >
        <Controller
          name='domain'
          control={control}
          render={(
            {
              field     : {
                ref,
                value,
                onBlur,
                ...fieldRest
              },
              fieldState: {
                error,
                invalid,
              }
            }
          ) => {
            return (
              <Form.Item
                label='Site URL'
                help={error?.message}
                validateStatus={invalid ? 'error' : void 0}
              >
                <Input
                  ref={ref}
                  autoFocus
                  value={value || ''}
                  placeholder='https://www.yoursite.com'
                  onFocus={() => toggleFocusState(true)}
                  onBlur={handleOnBlur(value, onBlur, fieldRest.onChange)}
                  {...fieldRest}
                />
              </Form.Item>
            )
          }}
        />
      </Col>
      <Col
        xs={24}
        sm={12}
      >
        <Text
          fontWeight='regular'
          className={clsx('hoverable-item m-b-4 d-block', isFocuses && 'opacity-1')}
        >The URL will be used as a default URL in push notifications.
        </Text>
      </Col>
    </Row>
  )
}