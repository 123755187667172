import React from 'react';
import {Form} from 'antd';
import {Input} from 'antd';
import {Text} from '../Typography';
import {Title} from '../Typography';
import {FormAlert} from '../FormAlert';
import {useSelector} from 'react-redux';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import whoops from '../../../images/whoops.svg';
import {useUnauthorized} from './useUnauthorized';
import {SubmitButton} from './components/SubmitButton';
import {_isUnauthorized} from '../../../store/selectors/application';
import './index.less';

const {Item} = Form;
const {Password} = Input;

export function Unauthorized() {
  const isUnauthorized = useSelector(_isUnauthorized);

  return isUnauthorized ? <UnauthorizedContent/> : null;
}

function UnauthorizedContent() {
  const {
    control,
    onSubmit,
    clearErrors,
    formState: {
      isSubmitting,
    },
  } = useUnauthorized();

  const {t} = useTranslation();

  React.useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return function () {
      document.body.style.overflowY = 'scroll';
    }
  }, []);

  return (
    <form
      onSubmit={onSubmit}
    >
      <div
        className='unauthorized-container d-flex flex-column justify-content-center align-items-center '
      >
        <img
          src={whoops}
          className='m-b-5'
          alt='Something Went Wrong'
        />
        <Title
          level={4}
          fontWeight='medium'
        >{t('unauthorized.title')}
        </Title>
        <Text
          className='m-b-5'
        >{t('unauthorized.description')}
        </Text>
        <div
          className='unauthorized-form'
        >
          <Controller
            name='message'
            control={control}
            render={(
              {
                fieldState: {
                  error,
                }
              }
            ) => {
              return (
                <FormAlert
                  type='error'
                  message={error?.message}
                  onClose={() => clearErrors('message')}
                />
              )
            }}
          />
          <Form
            component='div'
            className='form'
            layout='vertical'
          >
            <Controller
              name='email'
              control={control}
              render={(
                {
                  field     : {
                    ref,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                    invalid,
                  }
                }
              ) => {
                return (
                  <Item
                    label='Email'
                    help={error?.message}
                    validateStatus={invalid ? 'error' : void 0}
                  >
                    <Input
                      ref={ref}
                      autoComplete='off'
                      {...fieldRest}
                    />
                  </Item>
                );
              }}
            />
            <Controller
              name='password'
              control={control}
              render={(
                {
                  field     : {
                    ref,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                    invalid,
                  }
                }
              ) => {
                return (
                  <Item
                    label='Password'
                    help={error?.message}
                    validateStatus={invalid ? 'error' : void 0}
                  >
                    <Password
                      ref={ref}
                      autoComplete='current-password'
                      {...fieldRest}
                    />
                  </Item>
                );
              }}
            />
            <SubmitButton
              control={control}
              loading={isSubmitting}
            />
          </Form>
        </div>
      </div>
    </form>
  )
}