import React from 'react';
import {Picker} from '../Picker';
import {TextField} from '../Input';
import {IconButton} from '../IconButton';
import Menu from '@material-ui/core/Menu';
import {replaceBetween} from '../../utils';
import Typography from '@material-ui/core/Typography';
import {TextFieldProps} from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';

const useStyles = makeStyles(() => ({
  menuListProps: {
    padding: 0,
  },
  paperProps   : {
    borderRadius: 0,
    border      : '1px solid #F5F5F5',
  },
}));

export function EmojiInput(
  {
    value,
    onBlur,
    change,
    loading,
    onChange,
    InputProps,
    characterLimit,
    inputRef: ref,
    ...rest
  }: TextFieldProps & {
    change: any;
    loading?: boolean;
    characterLimit?: number;
    disableMargin?: boolean;
    value?: string | undefined;
    color?: 'primary' | 'default' | 'success' | 'error';
  }
) {
  const classes = useStyles();
  const inputRef = React.createRef<any>();

  const [selectionEnd, setSelectionEnd] = React.useState<any>(void 0);
  const [selectionStart, setSelectionStart] = React.useState<any>(void 0);
  const [inputValue, setInputValue] = React.useState<string | undefined>('');
  const [anchorEmoji, setAnchorEmoji] = React.useState<undefined | HTMLElement>(void 0);

  React.useEffect(() => {
    setInputValue(value || '');
  }, [
    value,
  ]);

  const handleOnChange = React.useCallback((e) => {
    setInputValue(e.target.value);
    change(e.target.value);
  }, [
    change,
  ]);

  const handleOnBlur = React.useCallback((e) => {
    setSelectionEnd(e.currentTarget.selectionEnd);
    setSelectionStart(e.currentTarget.selectionStart);
  }, []);

  const handleOnSelect = React.useCallback((emoji) => {
    setInputValue(replaceBetween(
      inputValue || '',
      emoji.native,
      selectionStart,
      selectionEnd
    ));
    change(replaceBetween(
      inputValue || '',
      emoji.native,
      selectionStart,
      selectionEnd
    ));
    setSelectionEnd(selectionEnd + String(emoji.native).length);
    setSelectionStart(selectionStart + String(emoji.native).length);
  }, [
    change,
    inputValue,
    selectionEnd,
    selectionStart,
  ]);

  return (
    <>
      <TextField
        loading={loading}
        value={inputValue}
        inputRef={inputRef}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        characterLimit={characterLimit}
        InputProps={{
          endAdornment: (
            <EndAdornment
              disabled={rest.disabled}
              setAnchorEmoji={setAnchorEmoji}
            />
          ),
        }}
        {...rest}
      />
      {anchorEmoji && (
        <Menu
          transitionDuration={0}
          anchorEl={anchorEmoji}
          open={Boolean(anchorEmoji)}
          transformOrigin={{
            vertical  : 'center',
            horizontal: 'left',
          }}
          onClose={() => setAnchorEmoji(void 0)}
          MenuListProps={{className: classes.menuListProps}}
          PaperProps={{elevation: 0, className: classes.paperProps}}
        >
          <Typography
            component='div'
          >
            <Picker
              onSelect={handleOnSelect}
            />
          </Typography>
        </Menu>
      )}
    </>
  )
}

function EndAdornment(
  {
    disabled,
    setAnchorEmoji
  }: any
) {
  const handleOnClick = React.useCallback((e) => {
    setAnchorEmoji(e.currentTarget)
  }, [
    setAnchorEmoji,
  ]);

  return (
    <InputAdornment
      position='end'
    >
      <IconButton
        size='small'
        color='transparent'
        disabled={disabled}
        onClick={handleOnClick}
      >
        <SentimentSatisfiedOutlinedIcon
          fontSize='small'
        />
      </IconButton>
    </InputAdornment>
  )
}
