import React from 'react';
import {Moment} from 'moment';
import {DatePicker} from 'antd';
import moment from 'moment-timezone';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {getPrefixCls} from '../../../../../../utils/getPrefixCls';
import {_setDates} from '../../../../../../store/actions/reports';
import {_reportsDates} from '../../../../../../store/selectors/reports';
import './index.less';

const {RangePicker} = DatePicker;

const ranges: Record<string, [any, any]> = {
  Today        : [
    moment().utc().startOf('day'),
    moment().utc().startOf('day'),
  ],
  Yesterday    : [
    moment().utc().startOf('day').subtract(1, 'day'),
    moment().utc().startOf('day').subtract(1, 'day'),
  ],
  'Last 3 days': [
    moment().utc().startOf('day').subtract(2, 'days'),
    moment().utc().startOf('day'),
  ],
  'Last 7 days': [
    moment().utc().startOf('day').subtract(6, 'days'),
    moment().utc().startOf('day'),
  ],
  'Last month' : [
    moment().utc().subtract(1, 'month').startOf('month'),
    moment().utc().subtract(1, 'month').endOf('month'),
  ],
  'This Month' : [
    moment().utc().startOf('month'),
    moment().utc().endOf('month').subtract(moment().utc().endOf('month').diff(moment().utc(), 'days'), 'days'),
  ],
};

export function DateRangePicker() {
  const dispatch = useDispatch();
  const value = useSelector(_reportsDates);

  const [dates, setDates] = React.useState<any>([]);
  const [hackValue, setHackValue] = React.useState<any>();

  const disabledDate = (current: Moment): boolean => {
    const disabled = current.utc().startOf('day').diff(moment().utc().startOf('day') as any, 'days') > 0;

    if (!dates || dates.length === 0) {
      return disabled;
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 31;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 31;

    return tooEarly || tooLate || disabled;
  };

  const onOpenChange = (open: any) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  return (
    <RangePicker
      ranges={ranges}
      allowClear={false}
      value={hackValue || value}
      allowEmpty={[false, false]}
      disabledDate={disabledDate}
      onOpenChange={onOpenChange}
      onCalendarChange={val => setDates(val)}
      onChange={val => dispatch(_setDates(val))}
      format={value => value.format('ddd, MMM. D, YYYY')}
      className={getPrefixCls('analytics-reports-dateRangePicker')}
    />
  )
}
