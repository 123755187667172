import React from 'react';
import {Draft} from 'immer';
import {Button} from 'antd';
import {Surveys} from '../../../../types/surveys';
import {getPrefixCls} from '../../../../utils/getPrefixCls';

export function Skip(
  {
    skip,
    data,
    setData,
  }: {
    skip: () => void;
    data: Surveys.Data;
    setData: (f: (draft: Draft<Surveys.Data>) => void | Surveys.Data) => void;
  }
) {
  const [disabled, setDisabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      data.competitor &&
      data.profession &&
      data.howDidYouFindUs &&
      data.reason
    ) {
      setData(draft => {
        draft.layerIsVisible = true;
      })
    }
  }, [
    setData,
    data.reason,
    data.profession,
    data.competitor,
    data.howDidYouFindUs,
  ]);

  function layerOnClick() {
    setData(draft => {
      draft.layerIsVisible = false;
    })
  }

  if (!!data.competitor?.trim()) {
    return null;
  }


  if (!!data.howDidYouFindUs?.trim()) {
    return null;
  }

  return (
    <div
      className={getPrefixCls('survey-skipWrapper')}
    >
      <div
        onClick={layerOnClick}
        className={getPrefixCls('survey-skipLayer')}
        style={{display: data.layerIsVisible ? 'block' : 'none'}}
      />
      <Button
        type='text'
        loading={disabled}
        onClick={() => {
          setDisabled(true);
          skip();
        }}
      >Skip
      </Button>
    </div>
  )
}
