import React from 'react';
import Box from '@material-ui/core/Box';
import {Theme} from '@material-ui/core/styles';
import {Controller, useWatch} from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import {Control} from 'react-hook-form/dist/types/form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import {TextField} from '../../../../../../../components/BorderedTextField';

const useStyles = makeStyles((theme: Theme) => ({
  customAdornment: {
    fontSize       : 14,
    backgroundColor: '#EAEAEA',
    padding        : theme.spacing(.5),
    paddingTop     : 2,
  },
}));

export function Percentage(
  {
    control,
    disabled,
  }: {
    disabled?: boolean;
    control: Control<Record<string, any>>;
  }
) {
  const classes = useStyles();

  const displayType = useWatch({
    control,
    name: 'display_type',
  });

  if (displayType !== 'percentage') {
    return null;
  }

  return (
    <Box
      marginTop={1}
      display='flex'
      marginLeft={4}
      alignItems='center'
    >
      <Box
        marginRight={1}
      >
        <Typography
          variant='body1'
          color={disabled ? 'secondary' : 'primary'}
        >When
        </Typography>
        <Box
          height={23}
        />
      </Box>
      <Controller
        control={control}
        name='percentage'
        render={(
          {
            field: {
              ref,
              ...fieldRest
            },
            fieldState: {
              error: localError,
            },
          }
        ) => {
          return (
            <Box
              width={220}
            >
              <TextField
                fullWidth
                type='number'
                inputRef={ref}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                    >
                      <Typography
                        color='inherit'
                        component='span'
                        className={classes.customAdornment}
                      >%
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                disabled={disabled}
                error={!!localError?.message}
                helperText={localError?.message}
                inputProps={{
                  min     : 0,
                  disabled: false,
                  readOnly: disabled,
                }}
                {...fieldRest}
              />
            </Box>
          )
        }}
      />
      <Box
        marginLeft={1}
      >
        <Typography
          variant='body1'
          color={disabled ? 'secondary' : 'primary'}
        >scrolled
        </Typography>
        <Box
          height={23}
        />
      </Box>
    </Box>
  )
}