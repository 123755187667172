import {Apps} from '../../../types/apps';
import {ActionTypes} from '../../constants/apps';

export const _dataOptions = (options: Partial<Apps.DataOptions>) => ({
  payload: options,
  type   : ActionTypes.APPS_DATA_OPTIONS,
});

export const _setData = (data: Record<string, any>) => ({
  payload: data,
  type   : ActionTypes.APPS_SET_DATA,
});

export const _toggleEntity = (entity?: Partial<Apps.Entity>) => ({
  payload: entity,
  type   : ActionTypes.APPS_TOGGLE_ENTITY,
});

export const _upsertEntity = (entity?: Partial<Apps.Entity>) => ({
  payload: entity,
  type   : ActionTypes.APPS_UPSERT_ENTITY,
});

export const _upsertDataItem = (entity?: Partial<Omit<Apps.Entity, 'uuid'>> & { uuid: string }) => ({
  payload: entity,
  type   : ActionTypes.APPS_UPSERT_DATA_ITEM,
});
