import {_upsertSurvey} from './index';
import {Globals} from '../../../types/globals';
import {dataLayer} from '../../../utils/dataLayer';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {_surveyData} from '../../selectors/application/acl';

export const _storeSurvey = (input: {
  key: string;
  value: string
}): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  const {
    body: {
      data,
    },
    hasError,
  } = await api.post(ApiRoutes.SURVEY, input);

  if (!hasError) {
    dispatch(_upsertSurvey(data));

    const surveyData = _surveyData(getState());
    if ((input.key === 'state') && (input.value === 'finished')) {
      dataLayer({
        event                 : 'survey_complete',
        survey_signup_reason  : surveyData.reason,
        survey_profession     : surveyData.profession,
        survey_current_service: surveyData.competitor,
      })
    }
  }
};
