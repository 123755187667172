import {Store} from 'redux';
import {ActionTypes} from '../store/constants/application';

export function setEnv({dispatch}: Store) {
  let hostname = process.env.REACT_APP_HOSTNAME;

  if (!hostname) {
    let tmp = window.location.hostname.split('.');
    tmp[0] = process.env.REACT_APP_API_PREFIX || 'api';
    hostname = tmp.join('.');
  }

  dispatch({
    type   : ActionTypes.SET_APP_ENV,
    payload: {
      PUBLIC_URL              : '',
      REACT_APP_HOSTNAME      : hostname,
      REACT_APP_RE_CAPTCHA_KEY: process.env.REACT_APP_RE_CAPTCHA_KEY,
      REACT_APP_PROTOCOL      : process.env.REACT_APP_PROTOCOL || 'https',
      ...process.env
    },
  });
}