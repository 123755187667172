import React from 'react';
import {IObject} from '../../../types';
import {useDispatch} from 'react-redux';
import ActionsCard from '../../ActionsCard';
import {useTranslation} from 'react-i18next';
import {addEntityActionsAction} from '../../../store/actions/campaigns';
import {updateEntityActionsAction} from '../../../store/actions/campaigns';
import {deleteEntityActionsAction} from '../../../store/actions/campaigns';

export default React.memo((
  {
    entity,
    errors,
    disabled,
    removeError,
    removeErrors,
  }: {
    entity: IObject;
    errors?: IObject;
    removeError?: any;
    removeErrors?: any;
    disabled?: boolean;
  }
) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const actionsCardLabels = React.useMemo(() => ({
    delete                     : t('campaigns.delete'),
    actions                    : t('campaigns.actions'),
    icon_url                   : t('campaigns.icon_url'),
    max_limit                  : t('campaigns.max_limit'),
    add_action                 : t('campaigns.add_action'),
    action_url                 : t('campaigns.action_url'),
    action_title               : t('campaigns.action_title'),
    delete_action              : t('campaigns.delete_action'),
    action_action              : t('campaigns.action_action'),
    action_url_placeholder     : t('campaigns.action_url_placeholder'),
    action_title_placeholder   : t('campaigns.action_title_placeholder'),
    action_action_placeholder  : t('campaigns.action_action_placeholder'),
    action_icon_url_placeholder: t('campaigns.action_icon_url_placeholder'),
  }), [
    t,
  ]);

  const updateEntity = React.useCallback((action: string, value: any, inputName?: string) => {
    const index = Object.values(entity || {}).map((action: any) => {
      const {
        ...rest
      } = action;
      return {...rest}
    }).findIndex((i: any) => Number(i.identity) === Number(value.identity));
    switch (action) {
      case 'add': {
        dispatch(addEntityActionsAction({
          [value.identity]: {
            identity: value.identity,
            type    : `action_${Object.keys(entity || {}).length + 1}`,
          }
        }));
        if (typeof removeErrors === 'function') {
          removeErrors()
        }
        break;
      }
      case 'delete': {
        dispatch(deleteEntityActionsAction(value.identity));
        const restAction: any = Object.values(entity || {}).find((e: any) => {
          return Number(e.identity) !== Number(value.identity)
        });
        if (restAction) {
          dispatch(updateEntityActionsAction({
            ...restAction,
            type: 'action_1',
          }));
        }
        if (typeof removeErrors === 'function') {
          removeErrors()
        }
        break;
      }
      case 'update': {
        const {
          identity,
          ...rest
        } = value;
        const inputName = Object.keys(rest || {})?.[0];
        dispatch(updateEntityActionsAction(value));

        if ((index !== -1) && inputName && removeError) {
          removeError(`action.${index}.${inputName}`);
        }
        break;
      }
      default: {
        console.error('Invalid case');
      }
    }
  }, [
    entity,
    dispatch,
    removeError,
    removeErrors,
  ]);

  return (
    <ActionsCard
      errors={errors}
      entity={entity}
      disabled={disabled}
      onChange={updateEntity}
      labels={actionsCardLabels}
    />
  )
})
