import {_setPortal} from './index';
import {_fetchPortal} from './fetchPortal';
import {Globals} from '../../../types/globals';
import {blankFeatures} from '../../../constants';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {_upsertSubscriptionsAndBilling} from './index';

export const _downgradeToFree = (
  {
    redirect,
  }: {
    redirect?: () => void;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_setPortal({
    skippingCheckout: true,
  }));

  const {
    hasError,
    body: {
      data,
      message
    }
  } = await api.post(ApiRoutes.UPDATE_SUBSCRIPTION, {
    plan_slug: 'free',
    interval : 'month',
  });

  if (!hasError) {
    await dispatch(_fetchPortal())
    if (data.need_interaction && data.hosted_invoice_url) {
      window.open(data.hosted_invoice_url, '_blank', blankFeatures);
      redirect && redirect();
    } else {
      redirect && redirect();
    }
  } else {
    dispatch(_upsertSubscriptionsAndBilling({
      existingCardErrors: {
        message,
        errors: Object.values(data || {}),
      }
    }));
  }

  dispatch(_setPortal({
    skippingCheckout: false,
  }));
};
