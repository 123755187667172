import React from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {_countries} from '../../../../../../../../store/selectors/locales';
import {_updateBillingAddress} from '../../../../../../../../store/actions/subscriptionsAndBilling/updateBillingAddress';

export function useEdit(
  {
    defaultValues
  }: {
    defaultValues: Record<string, any>;
  }
) {
  const form = useForm({
    defaultValues,
  });
  const dispatch = useDispatch();
  const countries = useSelector(_countries);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: {
      isSubmitting,
    }
  } = form;

  const onSubmit = React.useCallback((formValues: Record<string, any>) => {
    return dispatch(_updateBillingAddress({
      formValues,
    }));
  }, [
    dispatch,
  ]);

  return {
    control,
    register,
    setValue,
    countries,
    isSubmitting,
    submit: handleSubmit(onSubmit),
  }
}
