import React from 'react';
import {Template} from '../Templates';
import {Button} from '../../../Button';
import {useSelector} from 'react-redux';
import {useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {TemplateList} from '../Templates/List';
import {Theme} from '@material-ui/core/styles';
import {renderToString} from 'react-dom/server';
import Dialog from '../../../../components/Dialog';
import Typography from '@material-ui/core/Typography';
import {Control} from 'react-hook-form/dist/types/form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {_system} from '../../../../store/selectors/application/acl';

const useStyles = makeStyles((theme: Theme) => ({
  btnWrapper: {
    display                       : 'flex',
    justifyContent                : 'flex-start',
    alignItems                    : 'flex-start',
    marginLeft                    : theme.spacing(1),
    marginRight                   : theme.spacing(1),
    '& > button'                  : {
      width      : 180,
      marginLeft : theme.spacing(.5),
      marginRight: theme.spacing(.5),
    },
    '& > button:first-child'      : {
      marginLeft: 0,
    },
    '& > button:last-child'       : {
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection           : 'column',
      alignItems              : 'center',
      '& > button'            : {
        margin: 0,
      },
      '& > button:first-child': {
        marginBottom: theme.spacing(1.5),
      },
    }
  },
}));

export function TemplateHtml(
  {
    index,
    control,
    setValue,
    disabled,
  }: {
    index: number;
    control: Control;
    disabled?: boolean;
    setValue: UseFormSetValue<any>;
  }
): null | JSX.Element {
  const classes = useStyles();
  const {t} = useTranslation();
  const system = useSelector(_system);
  const [open, toggleDialog] = React.useState<boolean>(false);
  const [preview, togglePreview] = React.useState<boolean>(false);

  const [
    dimmed,
    bg_color,
    icon_url,
    position,
    deny_text,
    direction,
    allow_text,
    template_id,
    message_text,
    deny_text_color,
    allow_text_color,
    deny_button_color,
    allow_button_color,
    message_text_color,
  ] = useWatch({
    control,
    name: [
      'dimmed',
      `messages.[${index}].bg_color`,
      `messages.[${index}].icon_url`,
      `messages.[${index}].position`,
      `messages.[${index}].deny_text`,
      `messages.[${index}].direction`,
      `messages.[${index}].allow_text`,
      `messages.[${index}].template_id`,
      `messages.[${index}].message_text`,
      `messages.[${index}].deny_text_color`,
      `messages.[${index}].allow_text_color`,
      `messages.[${index}].deny_button_color`,
      `messages.[${index}].allow_button_color`,
      `messages.[${index}].message_text_color`,
    ],
  });

  React.useEffect(() => {
    setValue(`messages.[${index}].template_html`, renderToString(
      <Template
        isPreview
        icon={icon_url}
        backdrop={dimmed}
        bgColor={bg_color}
        direction={direction}
        message={message_text}
        cdnUrl={system?.cdn_url}
        position={position?.value}
        deny={{
          button   : deny_text,
          textColor: deny_text_color,
          bgColor  : deny_button_color,
        }}
        allow={{
          button   : allow_text,
          textColor: allow_text_color,
          bgColor  : allow_button_color,
        }}
        templateId={Number(template_id)}
        messageColor={message_text_color}
      />
    ))
  }, [
    index,
    dimmed,
    bg_color,
    icon_url,
    setValue,
    position,
    deny_text,
    direction,
    allow_text,
    template_id,
    message_text,
    deny_text_color,
    system?.cdn_url,
    allow_text_color,
    deny_button_color,
    allow_button_color,
    message_text_color,
  ]);

  return (
    <div>
      {preview && (
        <div
          dangerouslySetInnerHTML={{
            __html: renderToString(
              <Template
                isPreview
                icon={icon_url}
                backdrop={dimmed}
                bgColor={bg_color}
                direction={direction}
                message={message_text}
                cdnUrl={system?.cdn_url}
                position={position?.value}
                deny={{
                  button   : deny_text,
                  textColor: deny_text_color,
                  bgColor  : deny_button_color,
                }}
                allow={{
                  button   : allow_text,
                  textColor: allow_text_color,
                  bgColor  : allow_button_color,
                }}
                templateId={Number(template_id)}
                messageColor={message_text_color}
              />
            )
          }}
        />
      )}
      <ClickAwayListener
        onClickAway={() => togglePreview(false)}
      >
        <div>
          <Typography
            component='div'
            className={classes.btnWrapper}
          >
            <Button
              size='small'
              type='button'
              color='primary'
              variant='contained'
              disabled={disabled}
              onClick={() => toggleDialog(prevState => !prevState)}
            >{t('app_settings.open_templates')}
            </Button>
            <Button
              size='small'
              type='button'
              color='primary'
              variant='contained'
              disabled={disabled}
              onClick={() => togglePreview(prevState => !prevState)}
            >{!preview ? t('app_settings.self_test') : t('app_settings.close')}
            </Button>
          </Typography>
        </div>
      </ClickAwayListener>
      {open && (
        <ListDialog
          open={open}
          value={template_id}
          cdnUrl={system?.cdn_url}
          onChange={(value) => {
            if (value === 4) {
              setValue(`messages.[${index}].direction`, 'ltr', {
                shouldDirty: true,
              })
            }
            setValue(`messages.[${index}].template_id`, value, {
              shouldDirty: true,
            })
          }}
          onClose={() => toggleDialog(false)}
        />
      )}
    </div>
  );
}

function ListDialog(
  {
    open,
    value,
    cdnUrl,
    onClose,
    onChange,
  }: {
    open?: boolean;
    cdnUrl: string;
    value?: number;
    onClose: () => void;
    onChange: (value: any) => void;
  }
) {
  const {t} = useTranslation();
  const [template, setTemplate] = React.useState<any>(value);

  return (
    <Dialog
      fullWidth
      open={open}
      content={{
        children: (
          <div>
            <div/>
            <TemplateList
              cdnUrl={cdnUrl}
              value={template}
              onClose={onClose}
              onChange={(value: any) => setTemplate(value)}
            />
          </div>
        )
      }}
      onClose={onClose}
      disableBackdropClick
      actions={{
        align  : 'left',
        actions: [
          {
            disabled: false,
            loading : false,
            color   : 'blue',
            onClick : () => {
              onChange(template);
              onClose();
            },
            variant : 'contained',
            label   : t('buttons.save'),
          },
          {
            onClick: onClose,
            color  : 'blue',
            variant: 'outlined',
            label  : t('buttons.cancel'),
          },
        ]
      }}
      dialogTitle={{
        onClose,
        color   : 'primary',
        children: t('app_settings.select_template'),
      }}
    />
  )
}