import React from 'react';
import {Edit} from './Pages/Edit';
import {Route} from 'react-router';
import {Index} from './Pages/Index';
import {Switch} from 'react-router';
import {Create} from './Pages/Create';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {aclKeys} from '../../../constants/aclKeys';
import {urlHelper} from '../../../utils/urlHelper';
import {AppRoutes} from '../../../constants/appRoutes';
import {useSmartPush} from '../../../hooks/useSmartPush';
import {Spinner} from '../../../components/antd/Spinner';
import {FiniteStates} from '../../../constants/finiteStates';
import {_fetchParams} from '../../../store/actions/segments/fetchParams';
import {_fetchingSegmentsParamsState} from '../../../store/selectors/segments';

export function SegmentRoutes() {
  const dispatch = useDispatch();
  const segmentsParamsState = useSelector(_fetchingSegmentsParamsState);
  const {
    appUuid,
    user: {
      canUpdate,
      canCreate,
    }
  } = useSmartPush();

  React.useEffect(() => {
    if (![
      FiniteStates.LOADING,
      FiniteStates.SUCCESS,
    ].includes(segmentsParamsState)) {
      dispatch(_fetchParams(appUuid));
    }
  }, [
    appUuid,
    dispatch,
    segmentsParamsState,
  ]);

  if (![
    FiniteStates.SUCCESS,
    FiniteStates.FAILURE,
  ].includes(segmentsParamsState)) {
    return (
      <Spinner/>
    )
  }

  return (
    <Switch>
      <Route
        exact
        component={Index}
        path={AppRoutes.SEGMENTS}
      />
      <Route
        exact
        path={AppRoutes.SEGMENTS_CREATE}
        component={canCreate(aclKeys.SEGMENTS) ? Create : RedirectTo}
      />
      <Route
        exact
        path={AppRoutes.SEGMENTS_EDIT}
        component={canUpdate(aclKeys.SEGMENTS) ? Edit : RedirectTo}
      />
      <Route
        component={RedirectTo}
        path={`${AppRoutes.SEGMENTS}/*`}
      />
    </Switch>
  );
}

function RedirectTo() {
  const {appUuid} = useParams<{ appUuid: string }>();

  return (
    <Redirect
      to={urlHelper(AppRoutes.SEGMENTS, {appUuid})}
    />
  )
}
