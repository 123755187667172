import React from 'react';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {ButtonProps} from 'antd/lib/button/button';
import {PopconfirmProps} from 'antd/lib/popconfirm';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import {Templates} from '../../../../../../../types/templates';
import {Text} from '../../../../../../../components/antd/Typography';
import {FiniteStates} from '../../../../../../../constants/finiteStates';
import {TableAction} from '../../../../../../../components/antd/TableAction';
import {_exclude} from '../../../../../../../store/actions/templates/exclude';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';

export function ExcludeAction(
  {
    row,
  }: {
    row: Templates.EntityDetail;
  }
) {
  const dispatch = useDispatch();
  const {id} = useParams<{ id: string }>();

  const buttonProps: ButtonProps = {
    danger : true,
    size   : 'small',
    type   : 'default',
    shape  : 'circle',
    icon   : <CloseOutlined/>,
    loading: row.excluding === FiniteStates.LOADING,
  }
  const popconfirmProps: PopconfirmProps = {
    placement : 'topRight',
    cancelText: 'Keep Campaign',
    okText    : 'Exclude Campaign',
    title     : <PopconfirmTitle name={row.app_title}/>,
    onConfirm : () => dispatch(_exclude({
      uuid   : row.uuid,
      id     : Number(id),
      appUuid: row.app_uuid,
    })),
    icon      : <Text type='danger' className='anticon'><QuestionCircleOutlined/></Text>,
  };
  const tooltipTitle = 'Exclude Campaign';

  return (
    <TableAction
      buttonProps={buttonProps}
      popconfirmProps={popconfirmProps}
      tooltipProps={{title: tooltipTitle}}
    />
  )
}

function PopconfirmTitle(
  {
    name,
  }: {
    name: string;
  }
) {
  return (
    <>
      <div>
        <Text
          align='left'
        >Are you sure you want to exclude <Text style={{maxWidth: '20rem'}} ellipsis>'{name}'</Text>?
        </Text>
      </div>
      <Text
        align='left'
        type='secondary'
        fontSize='small'
        fontWeight='regular'
      >Once you are done excluding, you cannot undo this action.
      </Text>
    </>
  )
}
