import clsx from 'clsx';
import React from 'react';
import Popper from '@material-ui/core/Popper';
import {PopperProps} from '@material-ui/core/Popper/Popper';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles1 = makeStyles((theme) => ({
  popper: {
    zIndex                           : 1500,
    '&[x-placement*="bottom"] $arrow': {
      top        : 0,
      left       : 0,
      marginTop  : '-0.9em',
      width      : '3em',
      height     : '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="right"] $arrow' : {
      left       : 0,
      marginLeft : '-0.9em',
      height     : '3em',
      width      : '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow'  : {
      right      : 0,
      marginRight: '-0.9em',
      height     : '3em',
      width      : '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
    '&[x-placement*="top"] $arrow'   : {
      bottom      : 0,
      left        : 0,
      marginBottom: '-0.9em',
      width       : '3em',
      height      : '1em',
      '&::before' : {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
  },
  arrow : {
    position   : 'absolute',
    fontSize   : 7,
    width      : '3em',
    height     : '3em',
    '&::before': {
      content    : '""',
      margin     : 'auto',
      display    : 'block',
      width      : 0,
      height     : 0,
      borderStyle: 'solid',
    },
  },
}));

export default function ScrollPlayground1(
  {
    open,
    children,
    className,
    modifiers,
    ...rest
  }: PopperProps
) {
  const classes = useStyles1();
  const [arrowRef, setArrowRef] = React.useState(null);

  React.useEffect(() => {
    const t = document.getElementsByClassName('MuiDialog-scrollBody')[0];
    if (open) {
      document.body.style.overflow = 'hidden';
      if (t) {
        t.setAttribute('style', 'overflow: hidden');
      }
    } else {
      document.body.style.overflow = 'auto';
      if (t) {
        t.removeAttribute('style');
      }
    }

    return function () {
      document.body.style.overflow = 'auto';
      if (t) {
        t.removeAttribute('style');
      }
    }
  }, [
    open,
  ]);

  return (
    <Popper
      open={open}
      modifiers={{
        arrow          : {
          enabled: true,
          element: arrowRef,
        },
        preventOverflow: {
          enabled: false,
        },
      }}
      className={clsx(classes.popper, className)}
      {...rest}
    >
      <span
        ref={setArrowRef}
        className={classes.arrow}
      />{children}
    </Popper>
  );
}

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex                           : 1500,
    '&[x-placement*="bottom"] $arrow': {
      top        : 0,
      left       : 0,
      marginTop  : '-0.9em',
      width      : '3em',
      height     : '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="right"] $arrow' : {
      left       : 0,
      marginLeft : '-0.9em',
      height     : '3em',
      width      : '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow'  : {
      right      : 0,
      marginRight: '-0.9em',
      height     : '3em',
      width      : '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
    '&[x-placement*="top"] $arrow'   : {
      bottom      : 0,
      left        : 0,
      marginBottom: '-0.9em',
      width       : '3em',
      height      : '1em',
      '&::before' : {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
  },
  arrow : {
    position   : 'absolute',
    fontSize   : 7,
    width      : '3em',
    height     : '3em',
    '&::before': {
      content    : '""',
      margin     : 'auto',
      display    : 'block',
      width      : 0,
      height     : 0,
      borderStyle: 'solid',
    },
  },
}));

export function ScrollPlayground(
  {
    open,
    children,
    className,
    modifiers,
    ...rest
  }: PopperProps
) {
  const classes = useStyles();
  const [arrowRef, setArrowRef] = React.useState<any>(null);

  return (
    <Popper
      open={open}
      modifiers={{
        arrow          : {
          enabled: true,
          element: arrowRef,
        },
        preventOverflow: {
          enabled: false,
        },
        hide           : {
          enabled: false
        }
      }}
      className={clsx(classes.popper, className)}
      {...rest}
    >
      <span
        ref={setArrowRef}
        className={classes.arrow}
      />{children}
    </Popper>
  );
}