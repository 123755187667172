import React from 'react';
import {Modal} from 'antd';
import {Button} from 'antd';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {urlHelper} from '../../utils/urlHelper';
import {AppRoutes} from '../../constants/appRoutes';
import {getPrefixCls} from '../../utils/getPrefixCls';
import {Text} from '../../components/antd/Typography';
import {Title} from '../../components/antd/Typography';
import {_currentApp} from '../../store/selectors/apps';
import {_upsertEntity} from '../../store/actions/apps';
import {_userId} from '../../store/selectors/application/acl';
import congratsAnimation from '../../images/antd/congratsAnimation.gif';
import './index.less';

export function CongratulatoryProvider(
  {
    children,
  }: {
    children: React.ReactNode;
  }
) {
  const {push} = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(_userId);
  const app = useSelector(_currentApp);
  const {appUuid} = useParams<{ appUuid: string }>();

  function onClick() {
    localStorage.setItem(`first_subscriber_message_${userId}`, 'true');
    dispatch(_upsertEntity({congratulatory: false}));
    setTimeout(() => {
      push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_CREATE, {appUuid}))
    }, 0)
  }

  function onCancel() {
    localStorage.setItem(`first_subscriber_message_${userId}`, 'true');
    dispatch(_upsertEntity({congratulatory: false}));
  }

  return (
    <>
      {app?.congratulatory && (
        <Modal
          centered
          footer={null}
          onCancel={onCancel}
          visible={app?.congratulatory}
          className={getPrefixCls('congratulatory-modal')}
        >
          <div
            className={getPrefixCls('congratulatory-modal-image-wrapper')}
          >
            <img
              src={congratsAnimation}
              alt='Congrats Animation'
              className={getPrefixCls('congratulatory-modal-image')}
            />
          </div>
          <div
            className='m-b-4'
          >
            <Title
              level={2}
              align='center'
              fontWeight='bold'
            >Your 1st subscriber is here!
            </Title>
          </div>
          <div
            className='m-b-5'
          >
            <Text
              align='center'
              type='secondary'
            >Congrats! Now you can ahead and send<br/>your subscriber a push notification.
            </Text>
          </div>
          <div
            className='d-flex justify-content-center'
          >
            <Button
              type='primary'
              onClick={onClick}
            >Create Push Notification
            </Button>
          </div>
        </Modal>
      )}
      {children}
    </>
  )
}
