import React from 'react';
import Box from '@material-ui/core/Box';
import {useSelector} from 'react-redux';
import {useWatch} from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import {_countries} from '../../store/selectors/apps';
import {_languages} from '../../store/selectors/apps';
import {LOCALE_TYPE} from '../../constants/localeType';
import {getDefaultMessageValues} from '../../constants';
import {UseFormReturn} from 'react-hook-form/dist/types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {_system} from '../../store/selectors/application/acl';
import {fade} from '@material-ui/core/styles/colorManipulator';
import {UseFieldArrayReturn} from 'react-hook-form/dist/types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles((theme: Theme) => ({
  inputBase          : {
    padding     : 10,
    width       : '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input'   : {
      borderRadius   : 0,
      padding        : 8,
      fontSize       : 14,
      border         : '1px solid #ced4da',
      backgroundColor: theme.palette.common.white,
      transition     : theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus'      : {
        borderColor: theme.palette.primary.main,
        boxShadow  : `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      },
    },
  },
  popperDisablePortal: {
    position: 'static',
    width   : '300px !important',
  },
}));

export function Locales(
  {
    form,
    disabled,
    formFieldArray,
  }: {
    disabled?: boolean;
    form: UseFormReturn<any>;
    formFieldArray: UseFieldArrayReturn<any, any, any>;
  }
) {
  const {
    control,
    getValues,
  } = form;

  const localeType = useWatch({
    control,
    name: 'locale_type',
  });

  const classes = useStyles();
  const {t} = useTranslation();
  const system = useSelector(_system);
  const countries = useSelector(_countries);
  const languages = useSelector(_languages);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onChange = React.useCallback((event, value, reason, details) => {
    if (details) {
      const {option} = details;
      formFieldArray.append({
        ...getDefaultMessageValues(t, {
          cdn_url: system?.cdn_url,
        }),
        ...form.getValues()?.messages?.[0],
        ...option,
      })
    }
  }, [
    t,
    form,
    formFieldArray,
    system?.cdn_url,
  ]);

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }, [
    anchorEl,
  ]);

  React.useEffect(() => {
    if (form.formState.isDirty && (formFieldArray.fields.length > 1)) {
      form.setValue('tab_value', 'default');

      form.setValue('messages', [
        form.getValues('messages[0]'),
      ]);
    }
    // eslint-disable-next-line
  }, [
    t,
    // eslint-disable-next-line
    localeType
  ])

  return (
    <Box
      display='flex'
    >
      <ClickAwayListener
        onClickAway={onClose}
      >
        <Box
          display='inline-flex'
        >
          <IconButton
            color='primary'
            disabled={disabled}
            onClick={handleClick}
          ><AddCircleIcon/>
          </IconButton>
          <Popover
            keepMounted
            onClose={onClose}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            anchorOrigin={{
              vertical  : 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical  : 'top',
              horizontal: 'center',
            }}
          >
            <Paper
              square
              variant='outlined'
            >
              <Autocomplete
                open
                multiple
                disablePortal
                disableClearable
                onChange={onChange}
                disableCloseOnSelect
                filterSelectedOptions
                value={getValues('messages')}
                PaperComponent={PaperComponent}
                getOptionLabel={(option) => option?.text}
                classes={{
                  popperDisablePortal: classes.popperDisablePortal,
                }}
                getOptionSelected={(option, value) => option?.iso === value?.iso}
                options={(localeType?.value === LOCALE_TYPE.COUNTRY) ? countries : languages}
                renderInput={params => (
                  <InputBase
                    autoFocus
                    ref={params.InputProps.ref}
                    className={classes.inputBase}
                    inputProps={params.inputProps}
                  />
                )}
              />
            </Paper>
          </Popover>
        </Box>
      </ClickAwayListener>
    </Box>
  )
}

function PaperComponent(props: React.HTMLAttributes<HTMLElement>) {
  return (
    <Paper
      {...props}
      elevation={0}
    />
  )
}