import React from 'react';
import {useWatch} from 'react-hook-form';
import {UseFormRegister} from 'react-hook-form';
import {Control} from 'react-hook-form/dist/types/form';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';

export function TableField(
  {
    name,
    index,
    control,
    register,
    setValue,
  }: {
    name: string;
    index: number;
    control: Control;
    setValue: UseFormSetValue<Record<string, any>>;
    register: UseFormRegister<Record<string, any>>;
  }
) {
  const queryType = useWatch({
    name,
    control,
  });

  React.useEffect(() => {
    if (!queryType) {
      setValue(`query.${index}.table`, '');
    } else {
      setValue(`query.${index}.table`, queryType.table);
    }
  }, [
    index,
    setValue,
    queryType,
  ])

  return (
    <input
      type='hidden'
      {...register(`query.${index}.table`)}
    />
  )
}