import React from 'react';
import {Button} from 'antd';
import {Breadcrumb} from 'antd';
import {useCreate} from './useCreate';
import {SegmentsForm} from '../../components/Form';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import {Link} from '../../../../../components/antd/Typography';
import {Content} from '../../../../../components/antd/Content';
import {getPrefixCls} from '../../../../../utils/getPrefixCls';
import {FiniteStates} from '../../../../../constants/finiteStates';
import {Navigation} from '../../../../../components/antd/Navigation';
import './index.less';

export function Create() {
  const {
    fields,
    params,
    control,
    onCancel,
    setValue,
    onRemove,
    onAppend,
    register,
    onSubmit,
    redirectToSegments,
    segmentsParamsState,
    formState: {
      isSubmitting,
    },
  } = useCreate();

  return (
    <form
      onSubmit={onSubmit}
    >
      <Navigation
        extra={[
          <Button
            onClick={onCancel}
            key='cancelSaveSegment'
            disabled={isSubmitting}
          >Cancel
          </Button>,
          <Button
            type='primary'
            key='saveSegment'
            htmlType='submit'
            loading={isSubmitting}
            icon={<CheckOutlined/>}
          >Save
          </Button>,
        ]}
        title='New Segment'
        breadcrumbItems={[
          <Breadcrumb.Item
            key='app.segments'
            onClick={redirectToSegments}
          >
            <Link
              ellipsis
              type='secondary'
            >Segments
            </Link>
          </Breadcrumb.Item>,
          <Breadcrumb.Item
            key='app.segments.newSegment'
          >New Segment
          </Breadcrumb.Item>,
        ]}
      />
      <Content>
        <div
          className={`${getPrefixCls('segments-form-container')} p-5`}
        >
          <SegmentsForm
            fields={fields}
            params={params}
            control={control}
            setValue={setValue}
            onRemove={onRemove}
            onAppend={onAppend}
            register={register}
            isLoading={segmentsParamsState === FiniteStates.LOADING}
          />
        </div>
      </Content>
    </form>
  )
}