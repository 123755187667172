import {_fetch} from '../apps/fetch';
import * as Sentry from '@sentry/react';
import {sleep} from '../../../utils/sleep';
import {filterObject} from '../../../utils';
import {reactPixel} from '../../../utils/fbq';
import {Globals} from '../../../types/globals';
import {isMobile} from '../../../utils/isMobile';
import {_appsLength} from '../../selectors/apps';
import {dataLayer} from '../../../utils/dataLayer';
import {ANIMATION_DELAY} from '../../../constants';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {ActionTypes} from '../../constants/application';
import {UseFormSetError} from 'react-hook-form/dist/types/form';
import {RecaptchaActions} from '../../../constants/recaptchaActions';
import {IRegisterForm} from '../../../pages/Auth/Pages/Register/useRegister';

export const _appSumoRegister = (
  {
    plan,
    token,
    searchString,
    message: tmpInput,
    ...restFormValues
  }: IRegisterForm & { searchString?: string, token: string }, {
    setError,
    executeRecaptcha,
  }: {
    setError: UseFormSetError<IRegisterForm>;
    executeRecaptcha: (action?: string) => Promise<string>;
  }): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  let error = false;

  if (restFormValues.password?.length < 8) {
    if (!error) {
      await sleep();
    }
    error = true;
    setError('password', {
      message: 'Please enter at least 8 characters',
    });
  }

  if (error) {
    return void 0;
  }

  let gRecaptchaResponse: string | undefined = void 0;

  if (typeof executeRecaptcha === 'function') {
    gRecaptchaResponse = await executeRecaptcha(RecaptchaActions.REGISTER);
  }

  const {
    body: {
      data,
      message,
    },
    hasError,
  } = await api.post(ApiRoutes.APP_SUMO_REGISTER, filterObject({
    ...restFormValues,
    token,
    agreed                : true,
    referrer_params       : searchString,
    'g-recaptcha-response': gRecaptchaResponse || 'token',
  }));

  if (hasError) {
    if (Object.keys(data).length) {
      Object.keys(data).forEach((inputName) => {
        if (![
          'email',
          'password',
          'last_name',
          'first_name',
        ].includes(inputName)) {
          setError('message', {
            message: data[inputName],
          });
        } else {
          setError(inputName as keyof IRegisterForm, {
            message: data[inputName],
          });
        }
      });
    } else {
      setError('message', {
        message,
      });
    }

    Sentry.setExtra('register', {data, message});
    Sentry.captureException({data, message});
  } else {
    try {
      reactPixel('trackCustom', 'Registration Complete (App)');
      dataLayer({
        authenticationMethod: restFormValues.email,
        event               : 'registration_complete',
      });
    } catch (e) {
      console.error(e)
    }

    setTimeout(async function () {
      dispatch({
        payload: {
          authenticated: true,
          accessToken  : data.access_token,
        },
        type   : ActionTypes.AUTHENTICATE,
      });

      await dispatch(_fetch());

      const mobile = isMobile();
      const appsLength = _appsLength(getState());

      if (mobile && !!appsLength) {
        dispatch({
          payload: mobile,
          type   : ActionTypes.IS_MOBILE,
        });
      }
    }, 2 * ANIMATION_DELAY);
  }

  return void 0;
};
