import React from 'react';
import {useHistory} from 'react-router';
import {ButtonProps} from 'antd/lib/button/button';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import {Templates} from '../../../../../../types/templates';
import {aclKeys} from '../../../../../../constants/aclKeys';
import {urlHelper} from '../../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../../constants/appRoutes';
import {useSmartPush} from '../../../../../../hooks/useSmartPush';
import {FiniteStates} from '../../../../../../constants/finiteStates';
import {TableAction} from '../../../../../../components/antd/TableAction';

export function CreatePushNotificationAction(
  {
    row,
  }: {
    row: Templates.Entity;
  }
) {
  const {push} = useHistory();

  const {
    appUuid,
    user: {
      canCreate,
    }
  } = useSmartPush();
  const buttonProps: ButtonProps = {
    size    : 'small',
    shape   : 'circle',
    type    : 'default',
    icon    : <PlusOutlined/>,
    disabled: !canCreate(aclKeys.CAMPAIGNS),
    loading : row.deleting === FiniteStates.LOADING,
    onClick : () => {
      push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_CLONE, {
        appUuid,
        uuid: row.id
      }));
    }
  }
  const tooltipTitle = canCreate(aclKeys.CAMPAIGNS) ? 'Create Push Notification' : 'You don\'t have access to manage this action';

  return (
    <TableAction
      buttonProps={buttonProps}
      tooltipProps={{title: tooltipTitle}}
    />
  )
}
