import React from 'react';
import {Spin} from 'antd';
import {Alert} from 'antd';
import {Modal} from 'antd';
import {Button} from 'antd';
import {Progress} from 'antd';
import {useImmer} from 'use-immer';
import {Text} from '../Typography';
import {Title} from '../Typography';
import ym from 'react-yandex-metrika';
import {MutableRefObject} from 'react';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {Apps} from '../../../types/apps';
import {blankFeatures} from '../../../constants';
import {urlHelper} from '../../../utils/urlHelper';
import {dataLayer} from '../../../utils/dataLayer';
import {_get} from '../../../store/actions/apps/get';
import {tidioChat} from '../../../utils/tidioChatApi';
import {AppRoutes} from '../../../constants/appRoutes';
import {_currentApp} from '../../../store/selectors/apps';
import {usePageVisibility} from '../../../hooks/usePageVisibility';

const timeToGo: number = 1;
const maxPercent: number = 95;

let timeout: NodeJS.Timeout | undefined;
let interval: NodeJS.Timeout | undefined;

export function IntegrationTesting(
  {
    submit,
    appUuid,
    visible,
    savingStep,
    validation,
    skipForNow,
    toggleVisibility,
    additionalActions,
    fireIntegrationSkipped,
  }: {
    appUuid: string;
    visible: boolean;
    submit: () => void;
    savingStep?: boolean;
    skipForNow: () => void;
    additionalActions?: boolean;
    validation?: Apps.EntityDetailsValidation;
    toggleVisibility: React.Dispatch<boolean>;
    fireIntegrationSkipped: MutableRefObject<boolean>;
  }
) {
  const {push} = useHistory();
  const history = useHistory();
  const dispatch = useDispatch();
  const app = useSelector(_currentApp);
  const isVisible = usePageVisibility();

  const domain = app?.domain;

  const [{
    loading,
    percent,
    checking,
  }, setState] = useImmer({
    percent : 0,
    loading : false,
    checking: false,
  });
  const toggleLoading = React.useCallback(() => {
    setState((draft) => {
      draft.loading = !draft.loading;
    });
  }, [
    setState,
  ]);

  const removePercent = React.useCallback(() => {
    setState((draft) => {
      draft.percent = undefined;
    });
  }, [
    setState,
  ]);

  const openWebsite = React.useCallback(() => {
    window.open(domain, '_blank', blankFeatures);

    toggleLoading();

    timeout = setTimeout(async function () {
      fireIntegrationSkipped.current = false;
      await dispatch(_get(appUuid, {
        skipDelay  : true,
        skipLoading: true,
      }));

      removePercent();

      toggleLoading();
    }, 10000);
    // eslint-disable-next-line
  }, [
    domain,
    appUuid,
    dispatch,
    toggleLoading,
    removePercent,
  ]);

  const incrementPercent = React.useCallback(() => {
    setState((draft) => {
      if (draft.percent < maxPercent) {
        ++draft.percent;
      }
    });
  }, [
    setState,
  ]);

  React.useEffect(() => {
    if ((percent === maxPercent) && interval) {
      clearInterval(interval);
      interval = void 0;
    }
  }, [
    percent,
  ]);

  React.useEffect(() => {
    if (
      !!appUuid &&
      !validation?.uuid &&
      !validation?.script_exist &&
      (percent === undefined) &&
      !loading
    ) {
      dataLayer({
        app_uuid: appUuid,
        event   : 'integration-failed',
      });
      ym('reachGoal', 'integration-failed', {
        app_uuid: appUuid,
      });
    }
  }, [
    loading,
    percent,
    appUuid,
    validation?.uuid,
    validation?.script_exist
  ]);

  React.useEffect(() => {
    interval = setInterval(() => {
      incrementPercent();
    }, (timeToGo / 100) * 1000);

    return function () {
      if (interval) {
        clearInterval(interval);
      }
      interval = void 0;
    }
  }, [
    incrementPercent,
  ]);

  React.useEffect(() => {
    return function () {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, []);

  React.useEffect(() => {
    if (
      !!submit &&
      isVisible &&
      validation?.uuid &&
      validation?.script_exist &&
      (percent === undefined)
    ) {
      fireIntegrationSkipped.current = false;
      submit();
      localStorage.setItem(`integration_${appUuid}`, 'true');
    }
    // eslint-disable-next-line
  }, [
    push,
    submit,
    appUuid,
    percent,
    isVisible,
    validation?.uuid,
    validation?.script_exist,
  ]);

  return (
    <Modal
      centered
      width={480}
      visible={visible}
      maskClosable={false}
      onCancel={() => toggleVisibility(false)}
      closable={(percent === maxPercent) || (percent === undefined)}
      footer={(
        !loading ? (
          (percent === maxPercent) ? [
            <Button
              type='text'
              key='skipForNow'
              onClick={skipForNow}
              loading={savingStep}
            >Skip for Now
            </Button>,
            <Button
              type='primary'
              key='openWebsite'
              disabled={savingStep}
              onClick={openWebsite}
            >Open Website
            </Button>,
          ] : (percent !== undefined) ? null : (
            (validation?.uuid && validation?.script_exist) ? null : (!additionalActions ? [
              <Button
                type='text'
                key='skipForNow'
                disabled={checking}
                onClick={skipForNow}
                loading={savingStep}
              >Skip for Now
              </Button>,
              <Button
                key='checkAgain'
                loading={checking}
                onClick={() => {
                  setState((draft) => {
                    draft.percent = 1;

                    interval = setInterval(() => {
                      incrementPercent();
                    }, (timeToGo / 100) * 1000);
                  });
                }}
                disabled={savingStep}
              >Check Again
              </Button>,
              <Button
                type='primary'
                key='contactSupport'
                disabled={savingStep || checking}
                onClick={() => tidioChat().popUpOpen()}
              >Contact Support
              </Button>,
            ] : [
              <Button
                type='text'
                key='skipForNow_1'
                disabled={checking}
                onClick={skipForNow}
                loading={savingStep}
              >Skip for Now
              </Button>,
              <Button
                type='primary'
                key='setUpIntegration'
                onClick={() => {
                  toggleVisibility(false);
                  history.push(urlHelper(AppRoutes.APPS_INTEGRATE_APP, {appUuid}));
                }}
                disabled={savingStep}
              >Set Up Integration
              </Button>,
            ])
          )
        ) : null
      )}
      title={((percent === maxPercent) || (percent === undefined)) ? 'Integration Validation' : void 0}
    >
      <div
        className='d-flex align-items-center justify-content-center flex-column'
      >
        {loading ? (
          <>
            <Spin
              size='large'
            />
            <PartialContent
              className='d-flex justify-content-center flex-column align-items-center m-t-3'
            />
          </>
        ) : (
          <>
            {(percent !== undefined) ? (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
              >
                <div
                  className='m-b-5'
                >
                  <ModalTitle
                    percent={percent}
                    validation={validation}
                  />
                </div>
                <div
                  className='m-b-5'
                >
                  <Progress
                    type='circle'
                    percent={percent}
                  />
                </div>
              </div>
            ) : (
              (validation?.script_exist && validation?.uuid) ? (
                <div
                  className='d-flex justify-content-center flex-column align-items-center'
                >
                  <div
                    className='m-b-5'
                  >
                    <Progress
                      type='circle'
                      percent={100}
                    />
                  </div>
                  <div>
                    <Title
                      level={3}
                      align='center'
                      fontWeight='medium'
                    >Integration is successful
                    </Title>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className='m-b-5'
                  >
                    <Title
                      level={3}
                      align='center'
                      fontWeight='medium'
                    >Integration Failed
                    </Title>
                  </div>
                  <Alert
                    showIcon
                    type='error'
                    message='The Code Not Found on Your Site*'
                    description='Please make sure that the code was added to the <head> section on all pages of your site.'
                  />
                  <div>
                    <Text
                      type='secondary'
                    >*Or if you have placed it somewhere else, You can skip this step.
                    </Text>
                  </div>
                </div>
              )
            )}
            {(percent === maxPercent) && (
              <PartialContent/>
            )}
          </>
        )}
      </div>
    </Modal>
  )
}

function ModalTitle(
  {
    percent,
    validation,
  }: {
    percent: number | undefined;
    validation?: Apps.EntityDetailsValidation;
  }
) {
  return (
    <Title
      level={3}
      align='center'
      fontWeight='medium'
    >
      {(percent !== undefined) ? (
        'Testing Integration...'
      ) : (
        (validation?.uuid && validation?.script_exist) ? (
          'Congrats! You are all set'
        ) : (
          'Integration Failed'
        )
      )}
    </Title>
  )
}

function PartialContent(
  {
    className,
  }: {
    className?: string
  }
) {
  return (
    <div
      className={className || ''}
    >
      <div>
        <Text>Time to <Text fontWeight='bold'>subscribe</Text> to your notifications!</Text>
      </div>
      <div>
        <Text>Just open your website and <Text fontWeight='bold'>allow</Text> notifications.</Text>
      </div>
    </div>
  )
}
