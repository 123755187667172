import clsx from 'clsx';
import React from 'react';
import {Space} from 'antd';
import {Badge} from 'antd';
import {Avatar} from 'antd';
import {Dropdown} from 'antd';
import {useProfile} from './useProfile';
import {Text} from '../../../../../Typography';
import DownOutlined from '@ant-design/icons/DownOutlined';
import {getPrefixCls} from '../../../../../../../utils/getPrefixCls';

export function Profile(
  {
    appUuid,
  }: {
    appUuid: string | undefined;
  }
) {
  const {
    hasDot,
    isMobile,
    shortName,
    firstName,
    overlayMenu,
    dropdownProps,
    isProfilePaths,
  } = useProfile({appUuid});

  return (
    <div>
      <Dropdown
        arrow
        {...dropdownProps}
        trigger={['click']}
        overlay={overlayMenu}
      >
        <Space>
          <Badge
            dot={hasDot}
            offset={[-5, 5]}
          >
            <Avatar
              className='avatar pointer'
              size={isMobile ? 'small' : void 0}
            >{shortName}
            </Avatar>
          </Badge>
          {!isMobile && (
            <Text
              className='pointer'
              fontWeight='regular'
            >
              <Text
                ellipsis
                fontWeight='regular'
                className={clsx(
                  'full-name-width',
                  isProfilePaths && getPrefixCls('highlighted'))
                }
              >{firstName}
              </Text>
              <DownOutlined
                className='profile-down-outlined'
              />
            </Text>
          )}
        </Space>
      </Dropdown>
    </div>
  )
}