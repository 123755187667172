import {formatDate} from '../../../../../utils';
import {DEFAULT_PAGE_SIZES} from '../../../../../constants';
import {ActionTypes} from '../../../../constants/campaigns';

const activeTab = localStorage.getItem('push_notifications_active_tab') || 'active';

function isHistory() {
  return activeTab === 'history';
}

const InitialState: any = {
  pageSize                    : 10,
  columns                     : [],
  sorting                     : [
    {
      direction : 'desc',
      columnName: 'created_at',
    },
  ],
  columnOrder                 : [
    'title',
    'status',
    'total',
    'conversions',
    'revenue',
    'ctr_get',
    'cr',
    'epc',
    'sent',
    'fails',
    'close',
    'errors',
    'click',
    'open',
    'front_data',
    'wait_until',
    'deliver_at',
    'created_at',
    'updated_at',
    'actions',
  ],
  tableColumnVisibility       : {
    columnExtensions        : [
      {
        togglingEnabled: false,
        columnName     : 'cr',
      },
      {
        togglingEnabled: false,
        columnName     : 'epc',
      },
      {
        togglingEnabled: false,
        columnName     : 'ctr_get',
      },
      {
        togglingEnabled: false,
        columnName     : 'actions',
      },
    ],
    defaultHiddenColumnNames: [
      'sent',
      'open',
      'close',
      'click',
      'fails',
      'total',
      'errors',
      'updated_at',
    ],
  },
  tableColumnExtensions       : [
    {
      width     : 350,
      columnName: 'title'
    },
    {
      width     : 120,
      columnName: 'total'
    },
    {
      width     : 120,
      columnName: 'sent'
    },
    {
      width     : 147,
      columnName: 'fails'
    },
    {
      width     : 120,
      columnName: 'errors'
    },
    {
      width     : 120,
      columnName: 'open'
    },
    {
      width     : 120,
      columnName: 'close'
    },
    {
      width     : 150,
      columnName: 'status'
    },
    {
      width     : 120,
      columnName: 'click'
    },
    {
      wordWrapEnabled: true,
      columnName     : 'ctr_get'
    },
    {
      wordWrapEnabled: true,
      columnName     : 'epc'
    },
    {
      wordWrapEnabled: true,
      columnName     : 'cr'
    },
    {
      width     : 120,
      columnName: 'revenue'
    },
    {
      width     : 140,
      columnName: 'conversions'
    },
    {
      width     : 150,
      columnName: 'wait_until'
    },
    {
      width     : 180,
      columnName: 'deliver_at'
    },
    {
      width     : 180,
      columnName: 'created_at'
    },
    {
      width     : 180,
      columnName: 'updated_at'
    },
    {
      width     : 80,
      align     : 'center',
      columnName: 'actions'
    },
    {
      width     : 220,
      columnName: 'front_data'
    }
  ],
  sortingStateColumnExtensions: [
    {
      sortingEnabled: false,
      columnName    : 'cr',
    },
    {
      sortingEnabled: true,
      columnName    : 'ctr',
    },
    {
      sortingEnabled: false,
      columnName    : 'epc',
    },
    {
      sortingEnabled: false,
      columnName    : 'icon',
    },
    {
      sortingEnabled: false,
      columnName    : 'image',
    },
    {
      sortingEnabled: false,
      columnName    : 'actions',
    },
    {
      sortingEnabled: false,
      columnName    : 'message',
    },
    {
      sortingEnabled: false,
      columnName    : 'front_data',
    },
  ],
  tab                         : activeTab,
  pageSizes                   : DEFAULT_PAGE_SIZES,
  to                          : isHistory() ? formatDate(new Date()) : void 0,
  from                        : isHistory() ? formatDate(new Date((new Date()).setDate(new Date().getDate() - 7))) : void 0,
};

export default (state: any = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_TABLE_OPTIONS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};
