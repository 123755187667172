import {_me} from '../application/me';
import stripeJs from '@stripe/stripe-js';
import {_fetchPortal} from './fetchPortal';
import {_saveStep} from '../apps/saveStep';
import {Globals} from '../../../types/globals';
import {UseFormSetError} from 'react-hook-form';
import {blankFeatures} from '../../../constants';
import {dataLayer} from '../../../utils/dataLayer';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {_upsertSubscriptionsAndBilling} from './index';

export const _payAndLaunch = (
  {
    card,
    stripe,
    appUuid,
    setError,
    redirect,
    formValues,
  }: {
    appUuid?: string;
    redirect?: () => void;
    stripe: stripeJs.Stripe;
    formValues: Record<string, any>;
    card: stripeJs.StripeCardElement;
    setError: UseFormSetError<Record<string, any>>;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  if (formValues.card === 'existing_card') {

    const {
      hasError,
      body: {
        data,
        message
      }
    } = await api.post(ApiRoutes.UPDATE_SUBSCRIPTION, {
      interval      : formValues.interval,
      plan_slug     : formValues.plan_slug,
      payment_method: formValues.payment_method,
    });

    if (!hasError) {
      if (data.need_interaction && data.hosted_invoice_url) {
        window.open(data.hosted_invoice_url, '_blank', blankFeatures);
      }

      if (redirect) {
        await redirect();
      }
    } else {
      dispatch(_upsertSubscriptionsAndBilling({
        existingCardErrors: {
          message,
          errors: Object.values(data || {}),
        }
      }))
    }

  } else {

    try {
      const {
        hasError,
        body: {
          message,
          data: intentData,
        }
      } = await api.get(ApiRoutes.SETUP_INTENT);
      if (hasError) {
        setError('name', {message});
      } else {
        const payload = await stripe.confirmCardSetup(intentData?.client_secret, {
          payment_method: {
            card,
            billing_details: {
              name   : formValues.name,
              address: {
                city       : formValues.city,
                state      : formValues.state,
                line1      : formValues.line1,
                line2      : formValues.line2,
                country    : formValues.country,
                postal_code: formValues.postal_code,
              },
            }
          },
        });

        if (payload.error) {
          if (payload.error.message) {
            setError('cardField', {message: payload.error.message});
          } else {
            setError('cardField', {message: 'Something went wrong'});
          }
        } else {
          const {
            hasError,
            body: {
              data,
              message,
            }
          } = await api.post(ApiRoutes.UPDATE_SUBSCRIPTION, {
            interval      : formValues.interval,
            plan_slug     : formValues.plan_slug,
            payment_method: payload.setupIntent.payment_method,
          });

          if (hasError) {
            if (Object.keys(data || {}).length) {
              Object.keys(data).forEach((errorKey) => {
                if (errorKey === 'payment_method') {
                  setError('cardField', {
                    message: data[errorKey]
                  });
                } else {
                  setError(errorKey, {
                    message: data[errorKey]
                  });
                }
              });
            } else {
              if (message) {
                setError('cardField', {message});
              }
            }
          } else {
            if (data.need_interaction && data.hosted_invoice_url) {
              window.open(data.hosted_invoice_url, '_blank', blankFeatures);
            }

            if (redirect) {
              await redirect();
            }

            await Promise.all([
              dispatch(_fetchPortal()),
              dispatch(_saveStep({
                appUuid,
                creationStep: 2,
              })),
              dispatch(_me()),
            ]);
            dataLayer({
              event             : 'upgrade_click',
              upgrade_click_plan: formValues.plan_slug,
            });
          }
        }
      }
    } catch (e) {
      if (e.message) {
        setError('cardField', {message: e.message});
      }
    }

  }
};
