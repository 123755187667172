import clsx from 'clsx';
import React from 'react';
import {Text} from '../Typography';
import {getPrefixCls} from '../../../utils/getPrefixCls';
import {formatCount} from '../../../utils/abbreviateNumber';
import './index.less';

export function SPProgress(
  {
    total,
    extra,
    header,
  }: {
    total: number;
    extra: number[];
    header?: React.ReactNode;
  }
) {
  const max = Math.max(...extra);

  let scrubbers: {
    value: number,
    type: 'warning' | 'exception' | 'normal',
  }[] = [
    {
      type : 'normal',
      value: (max * 100) / total,
    },
  ];

  if (max > total) {
    scrubbers = [
      {
        value: 100,
        type : 'exception',
      },
      {
        type : 'normal',
        value: (total * 100) / max,
      },
    ];
  }

  return (
    <div>
      {!!header && (
        <div
          className='m-b-4'
        >
          <Text
            fontWeight='medium'
            className={getPrefixCls('progress-header')}
          >{header}
          </Text>
        </div>
      )}
      <div
        className='m-b-4'
      >
        <div>
          <div
            className={getPrefixCls('progress-inner')}
          >
            {scrubbers.map((scrubber, index) => {
              return (
                <Scrubber
                  key={index}
                  type={scrubber.type}
                  value={scrubber.value}
                />
              )
            })}
          </div>
          <div
            className='m-t-1'
          >
            <Text
              className={getPrefixCls('progress-extra-text')}
            >
              <Text
                type='secondary'
                className='m-r-1'
                fontWeight='medium'
              >{formatCount(extra[0] || 0, true)}
              </Text>
              <Text
                type='secondary'
                className='m-r-1'
                fontWeight='regular'
              >of
              </Text>
              <Text
                type='secondary'
                fontWeight='medium'
              >{formatCount(total, true)}
              </Text>
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export function SPProgressDeliveredMessages(
  {
    total,
    extra,
    header,
  }: {
    total: number;
    extra: number[];
    header?: React.ReactNode;
  }
) {
  const max = Math.max(...extra);

  let scrubbers: {
    value: number,
    type: 'warning' | 'exception' | 'normal',
  }[] = [
    {
      type : 'normal',
      value: (max * 100) / total,
    },
  ];

  if (max > total) {
    scrubbers = [
      {
        value: 100,
        type : 'warning',
      },
      {
        type : 'normal',
        value: (total * 100) / max,
      },
    ];
  }

  return (
    <div>
      {!!header && (
        <div
          className='m-b-4'
        >
          <Text
            fontWeight='medium'
            className={getPrefixCls('progress-header')}
          >{header}
          </Text>
        </div>
      )}
      <div
        className='m-b-4'
      >
        <div>
          <div
            className={getPrefixCls('progress-inner')}
          >
            {scrubbers.map((scrubber, index) => {
              return (
                <Scrubber
                  key={index}
                  type={scrubber.type}
                  value={scrubber.value}
                />
              )
            })}
          </div>
          <div
            className='m-t-1'
          >
            <Text
              className={getPrefixCls('progress-extra-text')}
            >
              <Text
                type='secondary'
                className='m-r-1'
                fontWeight='medium'
              >{formatCount(extra[0] || 0, true)}
              </Text>
              <Text
                type='secondary'
                className='m-r-1'
                fontWeight='regular'
              >of
              </Text>
              <Text
                type='secondary'
                fontWeight='medium'
              >{formatCount(total, true)}
              </Text>
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

function Scrubber(
  {
    type,
    value,
  }: {
    value: number;
    type?: 'warning' | 'exception' | 'normal';
  }
) {
  function getColorClassName() {
    switch (type) {
      case 'warning': {
        return getPrefixCls('progress-scrubber-bg-color-warning');
      }
      case 'exception': {
        return getPrefixCls('progress-scrubber-bg-color-exception');
      }
      default: {
        return getPrefixCls('progress-scrubber-bg-color-normal');
      }
    }
  }

  return (
    <div
      style={{width: `${value}%`}}
      className={clsx(getPrefixCls('progress-scrubber'), getColorClassName())}
    />
  )
}
