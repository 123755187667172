import clsx from 'clsx';
import React from 'react';
import {Tabs} from 'antd';
import {Steps} from 'antd';
import {getPrefixCls} from '../../../../../utils/getPrefixCls';
import {Navigation} from '../../../../../components/antd/Navigation';
import {PageHeader} from '../../../../../components/antd/PageHeader';
import './index.less';

export function ManageAccountHeader(
  {
    hideTabs,
    pathname,
    currentStep,
    apiKeysPath,
    securityPath,
    tabsOnChange,
    showPlanChange,
    hasApiKeysAccess,
    manageAccountPath,
    paymentsAndSubscriptionsPath,
  }: {
    pathname: string;
    hideTabs: boolean;
    apiKeysPath: string;
    currentStep?: number;
    securityPath: string;
    showPlanChange: boolean;
    hasApiKeysAccess: boolean;
    manageAccountPath: string;
    paymentsAndSubscriptionsPath: string;
    tabsOnChange: (activeKey: string) => void;
  }
) {
  return !hideTabs ? (
    <Navigation
      title='Manage Account'
      hideDefaultBreadcrumbItem
      footer={
        <Tabs
          size='large'
          animated={false}
          activeKey={pathname}
          onChange={tabsOnChange}
        >
          <Tabs.TabPane
            tab='Personal Info'
            key={manageAccountPath}
          />
          {!showPlanChange && (
            <Tabs.TabPane
              tab='Payments & Subscriptions'
              key={paymentsAndSubscriptionsPath}
            />
          )}
          {hasApiKeysAccess && (
            <Tabs.TabPane
              tab='API Keys'
              key={apiKeysPath}
            />
          )}
          <Tabs.TabPane
            tab='Security'
            key={securityPath}
          />
        </Tabs>
      }
    />
  ) : (
    <div
      className={getPrefixCls('change-plan-page-header-wrapper')}
    >
      <PageHeader
        align='center'
        title='Upgrade Plan'
      >
        <Steps
          responsive
          current={currentStep}
          className={clsx('max-width-50', getPrefixCls('change-plan-steps'))}
        >
          <Steps.Step
            title='Choose a New Plan'
          />
          <Steps.Step
            title='Pay and Activate'
          />
        </Steps>
      </PageHeader>
    </div>
  )
}
