import React from 'react';
import {Space} from 'antd';
import {Layout} from 'antd';
import {Logo} from './components/Logo';
import {AllApps} from './components/AllApps';
import {Profile} from './components/Profile';
import useMediaQuery from 'use-media-antd-query';
import {getPrefixCls} from '../../../../../utils/getPrefixCls';
import {SecondaryActions} from './components/SecondaryActions';
import MenuUnfoldOutlined from '@ant-design/icons/MenuUnfoldOutlined';
import './index.less';

const menuUnfoldOutlinedStyle: React.CSSProperties = {
  fontSize: '2rem',
  display : 'flex',
}

export function Header(
  {
    appUuid,
    toggleDrawer,
  }: {
    appUuid: string | undefined;
    toggleDrawer: React.Dispatch<boolean>;
  }
) {
  const colSize = useMediaQuery();

  return (
    <Layout.Header
      className={getPrefixCls('mainLayout-header')}
    >
      <Space
        size={16}
        className='d-flex align-items-center'
      >
        <Logo
          appUuid={appUuid}
        />
        {[
          'xs',
          'sm',
        ].includes(colSize) && (
          <MenuUnfoldOutlined
            style={menuUnfoldOutlinedStyle}
            onClick={() => toggleDrawer(true)}
          />
        )}
      </Space>
      <AllApps
        appUuid={appUuid}
      />
      <Space
        size={[
          'sm',
          'xs',
          'md',
        ].includes(colSize) ? 8 : 16}
        className='d-flex justify-content-between align-items-center'
      >
        <SecondaryActions
          appUuid={appUuid}
        />
        <Profile
          appUuid={appUuid}
        />
      </Space>
    </Layout.Header>
  )
}