import {_setData} from './index';
import {_dataOptions} from './index';
import {stringify} from 'querystring';
import {momentTZ} from '../../../utils';
import {filterObject} from '../../../utils';
import {Globals} from '../../../types/globals';
import {Segments} from '../../../types/segments';
import {urlHelper} from '../../../utils/urlHelper';
import * as selectors from '../../selectors/segments';
import {ApiRoutes} from '../../../constants/apiRoutes';
import DATE_COLUMNS from '../../../constants/dateColumns';
import {FiniteStates} from '../../../constants/finiteStates';

export const _fetch = (appUuid: string): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    fetchingSegmentsState: FiniteStates.LOADING,
  }));

  const pageSize = selectors._pageSize(getState());
  const pageNumber = selectors._pageNumber(getState());
  const searchText = selectors._searchText(getState());
  const sorterOrder = selectors._sorterOrder(getState());
  const sorterField = selectors._sorterField(getState());

  const params = stringify(filterObject({
    pageSize,
    pageNumber,
    searchText,
    sortName : sorterField,
    sortOrder: (sorterOrder === 'ascend') ? 'asc' : 'desc',
  }));

  const {
    hasError,
    body: {
      data,
    },
  } = await api.get(`${urlHelper(ApiRoutes.LIST_SEGMENTS, {
    appUuid,
  })}?${params}`, {
    skipDelay: true,
  });

  if (!hasError) {
    const {
      rows,
      ...rest
    } = data;

    const createdAtFormat = (DATE_COLUMNS.find(dC => dC.column === 'created_at')).format;
    const updatedAtFormat = (DATE_COLUMNS.find(dC => dC.column === 'updated_at')).format;

    dispatch(_setData(rows.map((
      {
        updated_at,
        created_at,
        ...rest
      }: Segments.Entity) => {

      return {
        ...rest,
        created_at: momentTZ(created_at).format(createdAtFormat),
        updated_at: momentTZ(updated_at).format(updatedAtFormat),
      }
    })));

    dispatch(_dataOptions({
      ...rest,
    }));
  }

  dispatch(_dataOptions({
    fetchingSegmentsState: FiniteStates.SUCCESS,
  }));
};