import {_fetch} from './fetch';
import {notification} from 'antd';
import {_upsertDataItem} from './index';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _destroy = (
  {
    id,
    appUuid,
  }: {
    id: number;
    appUuid: string;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_upsertDataItem({
    id,
    deleting: FiniteStates.LOADING,
  }));

  const {
    hasError,
    body: {
      message,
    },
  } = await api.delete(urlHelper(ApiRoutes.DELETE_SEGMENT, {
    id,
    appUuid,
  }));

  if (!hasError) {
    notification.success({
      message,
    });
    dispatch(_upsertDataItem({
      id,
      deleting: FiniteStates.SUCCESS,
    }));
  } else {
    notification.error({
      message,
    });
    dispatch(_upsertDataItem({
      id,
      deleting: FiniteStates.FAILURE,
    }));
  }

  await dispatch(_fetch(appUuid));
};