import {ActionTypes} from '../../../../constants/templateCampaigns';
import {ActionTypes as CoreActionTypes} from '../../../../constants/application';

const InitialState: any = {
  byId        : {},
  allIds      : [],
  loading     : false,
  errors      : void 0,
  entity      : void 0,
  dataOptions : void 0,
  actionDialog: void 0,
};

export default (state: any = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_TEMPLATE_CAMPAIGNS_SET_DATA: {
      const byId: any = {};
      const allIds: any[] = [];
      (payload as Array<any>).forEach((
        {
          ...rest
        },
        index
      ) => {
        const id = index + 1;
        allIds.push(id);
        byId[id] = {
          id,
          ...rest,
          ctr_get           : rest.ctr,
          'data->wait_until': rest.wait_until,
        };
      });
      return {
        ...state,
        byId,
        allIds,
      };
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATE_CAMPAIGNS_EMPTY_DATA: {
      return InitialState;
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATE_CAMPAIGNS_DATA_OPTIONS: {
      return {
        ...state,
        dataOptions: {
          ...state.dataOptions,
          ...payload
        },
      };
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TOGGLE_DIALOG: {
      return {
        ...state,
        actionDialog: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TOGGLE_ERRORS: {
      return {
        ...state,
        errors: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TOGGLE_ENTITY: {
      return {
        ...state,
        entity: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TOGGLE_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case CoreActionTypes.LOGOUT: {
      return InitialState
    }
    default:
      return state;
  }
};
