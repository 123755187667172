import {_upsertForm} from './index';
import {UseFormReset} from 'react-hook-form';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {IResetPasswordForm} from '../../../pages/Auth/Pages/ResetPassword/useResetPassword';

export const _checkPasswordResetToken = (
  {
    reset,
    email,
    token,
  }: {
    email: string;
    token: string;
    reset: UseFormReset<IResetPasswordForm>;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  const {
    body: {
      data,
      status,
      message,
    },
    hasError,
  } = await api.get(urlHelper(ApiRoutes.CHECK_PASSWORD_RESET_TOKEN, {
    token,
    email,
  }));

  if (hasError) {
    dispatch(_upsertForm({
      status,
      message,
    }))
  } else {
    reset({
      email: data.user.email,
      token: data.reset_token,
    }, {
      keepDirty: false,
    })
    dispatch(_upsertForm({
      data,
      status,
      message,
    }))
  }
};