import React from 'react';
import Paper from '@material-ui/core/Paper';
import {Theme} from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {AutocompleteProps} from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) => ({
  option   : {
    fontSize                                        : 14,
    '&:hover'                                       : {
      backgroundColor: 'transparent',
      color          : theme.palette.primary.main,
    },
    '&:active'                                      : {
      backgroundColor: 'transparent',
    },
    '&.MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: '#E6F7FF',
      color          : theme.palette.primary.main,
    },
  },
  inputRoot: {
    '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      paddingTop   : 3.2,
      paddingBottom: 3.2,
    },
  },
}));

export function AutoComplete(
  {
    classes,
    ...restProps
  }: AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>
) {
  const localClasses = useStyles();
  return (
    <Autocomplete
      {...restProps}
      classes={{
        ...classes,
        option   : localClasses.option,
        inputRoot: localClasses.inputRoot,
      }}
      PaperComponent={CPaper}
      ListboxProps={{style: {paddingTop: 0, paddingBottom: 0}}}
      size='small'
    />
  );
}

function CPaper(props: Record<string, any>) {
  return <Paper {...props} variant='outlined'/>
}