import {useSelector} from 'react-redux';
import {_currentApp} from '../../../../../../store/selectors/apps';
import {FiniteStates} from '../../../../../../constants/finiteStates';
import {_fetchingAppState} from '../../../../../../store/selectors/apps';
import {_subscribersLimits} from '../../../../../../store/selectors/application/acl';

export function useCard() {
  const app = useSelector(_currentApp);
  const appState = useSelector(_fetchingAppState);
  const subscribersLimits = useSelector(_subscribersLimits);

  const subscribed = Number(app?.active_users) || 0;
  const active = Number(app?.last_active_users) || 0;
  const unsubscribed = Number(app?.archived_users) || 0;
  const total = (Number(app?.active_users) + Number(app?.archived_users)) || 0;

  const loading = appState === FiniteStates.LOADING;
  const limitPassed = subscribed >= subscribersLimits;

  return {
    total,
    active,
    loading,
    subscribed,
    limitPassed,
    unsubscribed,
  }
}