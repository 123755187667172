import React from 'react';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import AlertTitle from '@material-ui/lab/AlertTitle';

export default React.memo((
  {
    open,
    onClose,
  }: {
    open?: boolean;
    onClose?: () => void;
  }
) => {
  const {t} = useTranslation();
  return (
    <div
      style={{
        top     : 8,
        left    : 0,
        right   : 0,
        width   : 400,
        zIndex  : 2500,
        position: 'fixed',
        margin  : '0 auto',
      }}
    >
      <Collapse
        in={open}
      >
        <Alert
          onClose={onClose}
          severity='warning'
        >
          <AlertTitle
          >{t('campaigns.warning')}
          </AlertTitle>
          {t('campaigns.warning_descriptions')}
        </Alert>
      </Collapse>
    </div>
  )
});
