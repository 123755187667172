import {stringify} from 'querystring';
import {_setData, _dataOptions} from './actions';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {momentTZ, filterObject} from '../../../utils';
import {ApiRoutes} from '../../../constants/apiRoutes';
import DATE_COLUMNS from '../../../constants/dateColumns';
import {FiniteStates} from '../../../constants/finiteStates';
import {_pageSize, _pageNumber, _searchText, _sortName, _sortOrder} from '../../selectors/automation';

export const _fetch = (appUuid: string): Globals.ThunkAction => {
  return async (dispatch, getState, { api }) => {

    dispatch(_dataOptions({ fetchingAutomationsState: FiniteStates.LOADING }));

    const pageSize = _pageSize(getState());
    const pageNumber = _pageNumber(getState());
    const searchText = _searchText(getState());
    const sortOrder = _sortOrder(getState());
    const sortName = _sortName(getState());
    const params = stringify(filterObject({
      pageSize,
      pageNumber,
      searchText,
      sortOrder,
      sortName,
    }));

    const {
      hasError,
      body: {
        data,
      },
    } = await api.get(`${urlHelper(ApiRoutes.LIST_AUTOMATIONS, { appUuid })}?${params}`, {
      skipDelay: true,
    });
    
    if (!hasError) {
      const {
        rows,
        ...rest
      } = data;

      const createdAtFormat = (DATE_COLUMNS.find(dC => dC.column === 'created_at')).format;
      const updatedAtFormat = (DATE_COLUMNS.find(dC => dC.column === 'updated_at')).format;

      dispatch(_setData(rows.map((
        {
          updated_at,
          created_at,
          ...rest
        }: any) => {

        return {
          ...rest,
          created_at: momentTZ(created_at).format(createdAtFormat),
          updated_at: momentTZ(updated_at).format(updatedAtFormat),
        }
      })));

      dispatch(_dataOptions({ ...rest }));
    }

    dispatch(_dataOptions({ fetchingAutomationsState: FiniteStates.SUCCESS }));
  }
};
