import React from 'react';
import {Result} from 'antd';
import {Button} from 'antd';
import {useHistory} from 'react-router';
import {useResetPassword} from './useResetPassword';
import {Redirecting} from '../../Common/Redirecting';
import {FormContent} from './components/FormContent';
import {AppRoutes} from '../../../../constants/appRoutes';
import {Spinner} from '../../../../components/antd/Spinner';
import '../../index.less';

export function ResetPassword() {
  const {
    control,
    onSubmit,
    formInfo,
    register,
    clearErrors,
    formState: {
      isSubmitting,
      isSubmitSuccessful,
    },
  } = useResetPassword();
  const {replace} = useHistory();

  if (!formInfo) {
    return <Spinner/>;
  }

  if (formInfo.status === 401) {
    return (
      <Result
        status='error'
        title={formInfo.message}
        extra={
          <Button
            type='primary'
            onClick={() => replace(AppRoutes.LOGIN)}
          >Back to Login
          </Button>
        }
      />
    )
  }

  return (
    <>
      {isSubmitSuccessful ? (
        <Redirecting
          title='Your password has been reset.'
          subTitle='Redirecting to the App ...'
        />
      ) : (
        <FormContent
          control={control}
          onSubmit={onSubmit}
          register={register}
          clearErrors={clearErrors}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  )
}
