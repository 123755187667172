import {notification} from 'antd';
import {_setSubscriptionPlans} from './index';
import {Globals} from '../../../types/globals';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _fetchSubscriptionPlans = (): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_setSubscriptionPlans({
    loading                     : true,
    subscriptionPlansFiniteState: FiniteStates.LOADING
  }));

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.get(ApiRoutes.PUBLIC_SUBSCRIPTION_PLANS);

  if (hasError) {
    dispatch(_setSubscriptionPlans({
      loading                     : void 0,
      subscriptionPlansFiniteState: FiniteStates.FAILURE,
    }));

    notification.error({message});
  } else {
    dispatch(_setSubscriptionPlans({
      loading                     : void 0,
      subscriptionPlansFiniteState: FiniteStates.SUCCESS,
      data                        : data.map((d: Record<string, any>) => {
        return {
          ...d,
          plan_full_name: d.plan_name,
          plan_slug     : d.plan_slug?.toLowerCase(),
          plan_name     : d.plan_name?.split(' - ')?.[0] || d.plan_name,
        }
      })
    }));
  }
};
