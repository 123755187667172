import {IObject} from '../types';
import {TFunction} from 'i18next';
import {filterObject} from './index';
import {LOCALE_TYPE} from '../constants/localeType';

export default function mutateTemplate(
  data: IObject,
  additionalData: IObject,
) {
  return {
    messages         : {
      ...data?.data?.messages,
      default: {
        title    : data?.title,
        message  : data?.data?.message,
        icon_url : data?.data?.icon_url,
        image_url: data?.data?.image_url,
        ...data?.data?.messages?.default
      }
    },
    id               : data?.id,
    title            : data?.title,
    campaign_id      : data?.campaign_id,
    message          : data?.data?.message,
    used_in_campaigns: data?.used_in_campaigns,
    locale_type      : data?.data?.locale_type || 'language',
    action           : (data?.data?.action || []).map((item: any, index: number) => ({
      ...item,
      identity: index,
    })),
    values           : Object.keys(data?.data?.messages || {}).map((id: string) => {
      if (Number(id)) {
        const current = (additionalData.body.data?.items || []).find((i: any) => (Number(i.id) === Number(id)));
        if (current) {
          return {
            value: current.id,
            label: current.text
          }
        }
      }
      return undefined;
    }).filter(i => !!i),
    tags             : (data?.tags || []).map((item: { name: string; normalized: string; [key: string]: any }) => ({
      label: item.normalized,
      value: item.normalized,
    })),
  }
}

export function requestTransformer(formData: Record<string, any>): Record<string, any> {
  return filterObject({
    campaign_id: formData.campaign_id,
    tags       : formData.tags.map((tag:any) => tag.value).join(','),
    locale_type: formData?.locale_type?.value || LOCALE_TYPE.LANGUAGE,
    messages   : (formData.messages || []).reduce((acc: any, message: any) => {
      let newMessage: any = {};

      if (message?.title) {
        newMessage = {
          ...newMessage,
          title: message.title
        }
      }
      if (message?.message) {
        newMessage = {
          ...newMessage,
          message: message.message
        }
      }
      if (message?.icon_url) {
        newMessage = {
          ...newMessage,
          icon_url: message.icon_url
        }
      }
      if (message?.image_url) {
        newMessage = {
          ...newMessage,
          image_url: message.image_url
        }
      }

      const actions = message?.actions;
      if (Array.isArray(actions) && actions.length) {
        newMessage = {
          ...newMessage,
          actions: actions.map((action) => {
            let newAction: any = {};

            if (action?.url) {
              newAction = {
                ...newAction,
                url: action?.url
              }
            }
            if (action?.type) {
              newAction = {
                ...newAction,
                type: action?.type
              }
            }
            if (action?.title) {
              newAction = {
                ...newAction,
                title: action?.title
              }
            }
            if (action?.icon_url) {
              newAction = {
                ...newAction,
                icon_url: action?.icon_url
              }
            }

            return newAction;
          })
        };
      }

      acc[message._id] = newMessage;
      return acc;
    }, {})
  })
}

export function responseTransformer(dataFromServer: Record<string, any>, app: Record<string, any>, t: TFunction) {
  const localeType: string = dataFromServer?.data?.locale_type || LOCALE_TYPE.LANGUAGE;

  let values = app.languages;
  if (localeType === LOCALE_TYPE.COUNTRY) {
    values = app.countries;
  }

  const tags: Record<string, any>[] = (dataFromServer?.tags || []).map((item: { name: string; normalized: string; [key: string]: any }) => ({
    label: item.name,
    value: item.normalized,
  }));

  const defaultActions = Object.values(
    dataFromServer?.data?.action ||
    dataFromServer?.data?.actions ||
    []
  ).map((action: any) => {
    return {
      url     : action?.url ?? '',
      type    : action?.type ?? '',
      title   : action?.title ?? '',
      icon_url: action?.icon_url || '',
    };
  });

  const actions = Object.values(dataFromServer?.data?.messages?.default?.actions || []).map((action: any) => {
    return {
      url     : action?.url ?? '',
      type    : action?.type ?? '',
      title   : action?.title ?? '',
      icon_url: action?.icon_url || '',
    };
  });

  const defaultMessage = {
    _id              : 'default',
    iso              : 'default',
    text             : t('default'),
    title            : dataFromServer?.title || '',
    message          : dataFromServer?.data?.message || '',
    icon_url         : dataFromServer?.data?.icon_url || '',
    image_url        : dataFromServer?.data?.image_url || '',
    used_in_campaigns: dataFromServer?.used_in_campaigns || '',
    ...dataFromServer?.data?.messages?.default,
    actions: defaultActions.length ? defaultActions : actions,
  };

  const messages: Record<string, any> = filterObject({
    ...dataFromServer?.data?.messages,
    default: void 0,
  });
  const newMessages = Object.keys(messages).reduce((acc: any, current) => {
    const value = values.find((v: {
      _id: number;
      iso: string;
      text: string;
    }) => v._id === Number(current));
    const message = messages?.[current];
    acc.push({
      iso      : value?.iso,
      text     : value?.text,
      title    : message?.title || '',
      message  : message?.message || '',
      icon_url : message?.icon_url || '',
      image_url: message?.image_url || '',
      _id      : Number(current) || current,
      actions  : Object.values(message?.actions || []).map((action: any) => {
        return {
          url     : action?.url ?? '',
          type    : action?.type ?? '',
          title   : action?.title ?? '',
          icon_url: action?.icon_url || '',
        };
      })
    });
    return acc;
  }, []);
  newMessages.unshift(defaultMessage);

  return {
    tags,
    tab_value  : 'default',
    id         : dataFromServer.id || '',
    locale_type: {
      value: localeType,
      label: t(`locale.${localeType}`),
    },
    messages   : newMessages,
  };
}
