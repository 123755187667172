import React from 'react';
import Typography from '@material-ui/core/Typography';
import {filterObject} from '../../../../../../../utils';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import ObjectDrawer from '../../../../../../../components/ObjectDrawer';

export default function TagsFormatter(
  {
    value,
  }: DataTypeProvider.ValueFormatterProps
) {
  return (
    <Typography
      noWrap
      component='div'
    >
      {(value && (Object.prototype.toString.call(value) === '[object Object]')) ? (
        <>
          {(() => {
            const v = filterObject(value)
            if (Object.keys(v).length) {
              return (
                <ObjectDrawer
                  value={v}
                />
              )
            } else {
              return '-'
            }
          })()}
        </>
      ) : (
        '-'
      )}
    </Typography>
  )
}
