import React from 'react';
import Box from '@material-ui/core/Box';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useFieldArray} from 'react-hook-form';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import {Card} from '../../../../../../../components/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import {Control} from 'react-hook-form/dist/types/form';
import {IOSSwitch} from '../../../../../../../components/IOSSwitch';
import {CardHeader} from '../../../../../../../components/CardHeader';
import {IconButton} from '../../../../../../../components/IconButton';
import {TextField} from '../../../../../../../components/BorderedTextField';

export function AdvancedParametersCard(
  {
    control,
    disabled,
  }: {
    disabled?: boolean;
    control: Control<Record<string, any>>;
  }
) {
  const {t} = useTranslation();

  const {
    fields,
  } = useFieldArray({
    control,
    name: 'advanced_parameters',
  });

  return (
    <Card>
      <CardHeader
        color={disabled ? 'disabled' : 'primary'}
        title={t('app_settings.advanced_parameters')}
      />
      <CardContent>
        <Box
          display='flex'
          marginTop={1.5}
          alignItems='center'
          borderBottom='1px solid rgba(0, 0, 0, 0.12)'
        >
          <Box
            width={55}
            minWidth={55}
          >
            <Typography
              style={{color: 'transparent'}}
            >_
            </Typography>
            <Typography
              color='textSecondary'
            >cid
            </Typography>
            <Typography
              style={{color: 'transparent'}}
            >_
            </Typography>
          </Box>
          <TextField
            disabled
            fullWidth
            value='cid'
            margin='none'
            autoComplete='off'
            label={<Label disabled={disabled}/>}
          />
          <Box
            width={90}
            minWidth={90}
            display='flex'
            alignItems='center'
            flexDirection='column'
          >
            <Typography
              color='primary'
              component='span'
              style={{display: 'flex', color: disabled ? 'rgba(0, 0, 0, 0.38)' : ''}}
            >
              <span>{t('app_settings.want_track')}</span>
              {disabled ? (
                <Typography
                  component='div'
                >
                  <IconButton
                    size='small'
                    color='default'
                    disabled={disabled}
                  >
                    <HelpIcon
                      fontSize='small'
                    />
                  </IconButton>
                </Typography>
              ) : (
                <Tooltip
                  title={t('app_settings.track_helper')}
                >
                  <Typography
                    component='div'
                  >
                    <IconButton
                      size='small'
                      color='transparent'
                    >
                      <HelpIcon
                        fontSize='small'
                      />
                    </IconButton>
                  </Typography>
                </Tooltip>
              )}
            </Typography>
            <IOSSwitch
              disabled
              size='small'
              checked={true}
            />
            <Typography
              style={{color: 'transparent'}}
            >_
            </Typography>
          </Box>
        </Box>
        {fields.map((field: any, index) => {
          return (
            <Box
              display='flex'
              key={field.id}
              marginTop={1.5}
              alignItems='center'
              borderBottom={(index !== 9) ? '1px solid rgba(0, 0, 0, 0.12)' : void 0}
            >
              <Box
                width={55}
                minWidth={55}
              >
                <Typography
                  color='textSecondary'
                >{field.column}
                </Typography>
                <Typography
                  style={{color: 'transparent'}}
                >_
                </Typography>
              </Box>
              <Controller
                control={control}
                name={`advanced_parameters.[${index}].name`}
                render={(
                  {
                    field     : {
                      ref,
                      value,
                      ...fieldRest
                    },
                    fieldState: {
                      error: localError
                    }
                  }
                ) => {
                  return (
                    <TextField
                      fullWidth
                      margin='none'
                      inputRef={ref}
                      autoComplete='off'
                      disabled={disabled}
                      value={value || ''}
                      error={!!localError?.message}
                      helperText={localError?.message}
                      {...fieldRest}
                    />
                  );
                }}
              />
              <Box
                width={90}
                minWidth={90}
                display='flex'
                alignItems='center'
                flexDirection='column'
              >
                <Controller
                  control={control}
                  defaultValue={field.value}
                  name={`advanced_parameters.[${index}].track`}
                  render={(
                    {
                      field: {
                        value,
                        onChange,
                      },
                    }
                  ) => {
                    return (
                      <IOSSwitch
                        size='small'
                        checked={value}
                        disabled={disabled}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    );
                  }}
                />
                <Typography
                  style={{color: 'transparent'}}
                >_
                </Typography>
              </Box>
            </Box>
          )
        })}
      </CardContent>
    </Card>
  )
}

const labelTypographyStyles: React.CSSProperties = {
  top       : -3,
  display   : 'flex',
  alignItems: 'center',
  position  : 'absolute',
  width     : 'max-content',
};

function Label(
  {
    disabled,
  }: {
    disabled?: boolean;
  }
) {
  const {t} = useTranslation();

  return (
    <Typography
      style={labelTypographyStyles}
      color={disabled ? 'inherit' : 'primary'}
    >
      <span>{t('app_settings.choose_parameter')}</span>
      {disabled ? (
        <Box
          component='span'
          display='inline-flex'
        >
          <IconButton
            size='small'
            color='default'
            disabled={disabled}
          >
            <HelpIcon
              fontSize='small'
            />
          </IconButton>
        </Box>
      ) : (
        <Tooltip
          title={t('app_settings.choose_parameter_helper')}
        >
          <Box
            component='span'
            display='inline-flex'
          >
            <IconButton
              size='small'
              color='transparent'
            >
              <HelpIcon
                fontSize='small'
              />
            </IconButton>
          </Box>
        </Tooltip>
      )}
    </Typography>
  )
}