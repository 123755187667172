import React from 'react';
import FilterSection from './FilterSection';
import BuilderView from './BuilderView';
import './index.less';

const Bulders: React.FC = () => {
  
  return (
    <>
      <h3>Create a template</h3>
      <FilterSection />
      <BuilderView />
    </>
  );
};

export default Bulders;
