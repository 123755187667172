import React from 'react';
import Dialog from '../../../../../../components/Dialog';
import WarningIcon from '@material-ui/icons/Warning';

export default function CloneDialog(
  {
    open,
    loading,
    onClose,
    bodyTitle,
    agreeLabel,
    agreeAction,
    dismissLabel,
    dismissAction,
    bodyDescription,
  }: {
    onClose?: any;
    open: boolean;
    agreeAction: any;
    loading?: boolean;
    dismissAction: any;
    agreeLabel: string;
    bodyTitle?: string;
    dismissLabel: string;
    bodyDescription?: string;
  }
) {
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth='sm'
      color='warning'
      onClose={onClose}
      actions={{
        align  : 'center',
        actions: [
          {
            color    : 'primary',
            variant  : 'outlined',
            label    : dismissLabel,
            onClick  : dismissAction,
          },
          {
            loading,
            disabled : loading,
            color    : 'primary',
            variant  : 'contained',
            label    : agreeLabel,
            onClick  : agreeAction,
          },
        ],
      }}
      dialogTitle={{
        onClose: onClose,
        color  : 'warning',
      }}
      content={{
        bodyTitle,
        bodyDescription,
        color   : 'warning',
        bodyIcon: WarningIcon
      }}
    />
  )
}
