import React from 'react';
import {Row, Button, Card, Radio} from 'antd';
import {_fetchTags} from '../../../store/actions/tags';
import DynamicSelect from '../../../components/antd/DynamicSelect';
import {RadioChangeEvent} from 'antd/lib/radio';
import {Builder} from '../../../types/builder';

interface IProps {
  label?: string;
  type: string;
  value: string | number;
  selectedTagsValue: string | string[];
  updateTMPConfigs: (data: number, type: string) => void;
  updateTags: (value: string[]) => void;
  onBtnClick?: () => void;
  data: Builder.IconDataOptions | Builder.ImageDataOptions;
  disabled?: boolean;
};

const MediaCard: React.FC<IProps> = ({label, type, value, selectedTagsValue, updateTags, onBtnClick, updateTMPConfigs, data, disabled}) => {

  const btnVisible = React.useMemo(() => {
    return data.entities?.length < data.total;
  }, [data]);

  const getMetaContent = () => {
    return (
      data.entities?.map((item) => {
        return {
          value: item?.id,
          label: (
            <img
              key={item?.id}
              src={item?.value}
              width={'100%'}
              height={'80px'}
              alt={item.value}
            />
          )
        };
      })
    );
  };

  const handleTagsChange = (value: string[]) => {
    updateTags(value);
  };

  const handleRadioCheck = (e: RadioChangeEvent) => {
    updateTMPConfigs(e.target.value, type);
  };

  const onReset = () => {
    updateTMPConfigs(null, type);
  };

  return (
    <div className='card_content' >
      <DynamicSelect
        titleKey='id'
        valueKey='id'
        dataKey='rows'
        mode='multiple'
        label={
          <Row align='middle' justify='space-between' style={{width: '100%'}}>
            <div>{label}</div>
            {value &&
              <Button size='small' danger type='text' onClick={onReset}>
                Reset
              </Button>
            }
          </Row>
        }
        layout='vertical'
        placeholder='Filter by Tags'
        maxTagCount='responsive'
        disabled={disabled}
        value={selectedTagsValue}
        onChange={handleTagsChange}
        fetchData={_fetchTags}
        dropdownMatchSelectWidth={false}
      />
      <div className='card_body'>
        <Card bordered={false}>
          <Radio.Group
            value={value}
            onChange={handleRadioCheck}
            optionType='button'
            buttonStyle='outline'
            className='radio-group'
            options={getMetaContent()}
          />
        </Card>
        {btnVisible &&
          <Row justify='center' className='m-b-5 m-t-3' >
            <Button size='small' onClick={onBtnClick}>
              Show More
            </Button>
          </Row>
        }
      </div>
    </div>
  );
};

export default MediaCard;
