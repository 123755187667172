import React from 'react';
import {Alert} from 'antd';
import {Button} from 'antd';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {urlHelper} from '../../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../../constants/appRoutes';
import {_activeGraceMessage} from '../../../../../../store/selectors/application/acl';
import {_showActiveGracePeriodWarning} from '../../../../../../store/selectors/application/acl';

export function ActiveGraceAlert(
  {
    appUuid,
  }: {
    appUuid: string | undefined;
  }
) {
  const {push} = useHistory();
  const activeGraceMessage = useSelector(_activeGraceMessage);
  const showActiveGracePeriodWarning = useSelector(_showActiveGracePeriodWarning);

  const onClick = React.useCallback(() => {
    if (!!appUuid) {
      push(`${urlHelper(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP, {
        appUuid,
      })}?showChangeCard=true`)
    } else {
      push(`${AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS}?showChangeCard=true`)
    }
  }, [
    push,
    appUuid,
  ]);

  return showActiveGracePeriodWarning ? (
    <Alert
      closable
      showIcon
      type='warning'
      message={activeGraceMessage}
      action={
        <Button
          type='link'
          onClick={onClick}
        >Go to Payment Methods
        </Button>
      }
    />
  ) : null;
}