import React from 'react';
import {Space} from 'antd';
import {EditAction} from '../../TableActions/Edit';
import {DeleteAction} from '../../TableActions/Delete';
import {Segments} from '../../../../../../types/segments';

export function Actions({record}: { record: Segments.Entity }) {
  return (
    <Space>
      <EditAction
        row={record}
      />
      <DeleteAction
        row={record}
      />
    </Space>
  )
}