import {Col} from 'antd';
import {Row} from 'antd';
import React from 'react';
import {Spin} from 'antd';

export function Spinner() {
  return (
    <Row
      justify='center'
    >
      <Col>
        <Spin
          size='large'
          className='m-t-5'
        />
      </Col>
    </Row>
  )
}