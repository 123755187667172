import React from 'react';
import {Breadcrumb} from 'antd';
import {Link} from '../Typography';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {PageHeader} from '../PageHeader';
import {urlHelper} from '../../../utils/urlHelper';
import {AppRoutes} from '../../../constants/appRoutes';
import {getPrefixCls} from '../../../utils/getPrefixCls';
import {_currentAppTitle} from '../../../store/selectors/apps';
import './index.less';

const {Item} = Breadcrumb;

var lastScrollTop = 0;

function guessTop() {
  const antPageHeaderFooter = document.getElementsByClassName('ant-page-header-footer')?.item(0);
  const antPageHeaderHeadingExtra = document.getElementsByClassName('ant-page-header-heading-extra')?.item(0);

  if (!antPageHeaderFooter && !antPageHeaderHeadingExtra) {
    return 13;
  }

  if (antPageHeaderHeadingExtra) {
    return 13;
  }

  return -29;
}

// eslint-disable-next-line
function onScroll() {
  const antPageHeaderWrapper = document.getElementById('pageHeaderWrapper');
  const antLayoutHeader = document.getElementsByClassName('layout-header').item(0);

  var st = window.pageYOffset || document.documentElement.scrollTop;
  const top = Number(window.getComputedStyle(antPageHeaderWrapper).top.replace('px', ''));

  if (st > lastScrollTop) {
    if (top >= guessTop()) {
      antPageHeaderWrapper.style.top = `${Math.round(top) - 1}px`;
    }
  } else {
    if (top <= antLayoutHeader.clientHeight) {
      antPageHeaderWrapper.style.top = `${Math.ceil(top) + 1}px`;
    }
  }
  lastScrollTop = st <= 0 ? 0 : st;
}

export function Navigation(
  {
    title,
    extra,
    footer,
    subTitle,
    breadcrumbItems,
    hideDefaultBreadcrumbItem,
  }: {
    title: React.ReactNode;
    extra?: React.ReactNode;
    footer?: React.ReactNode;
    subTitle?: React.ReactNode;
    hideDefaultBreadcrumbItem?: boolean;
    breadcrumbItems?: React.ReactElement<typeof Item>[];
  }
) {
  const {push} = useHistory();
  const appTitle = useSelector(_currentAppTitle);
  const {appUuid} = useParams<{ appUuid: string }>();

  React.useEffect(() => {
    const antPageHeaderWrapper = document.getElementById('pageHeaderWrapper');
    antPageHeaderWrapper.style.top = `${guessTop()}px`;
  }, []);

  return (
    <div
      id='pageHeaderWrapper'
      className={getPrefixCls('navigation-sticky')}
    >
      <PageHeader
        ghost={false}
        extra={extra}
        title={title}
        footer={footer}
        subTitle={subTitle}
        breadcrumb={(
          <Breadcrumb
            separator={<>{'>'}</>}
            className={getPrefixCls('breadcrumb')}
          >
            {!hideDefaultBreadcrumbItem && (
              <Item
                key='app'
                onClick={() => {
                  push(urlHelper(AppRoutes.SETTINGS, {appUuid}))
                }}
              >
                <Link
                  ellipsis
                  type='secondary'
                  className={getPrefixCls('breadcrumb-item-appLink')}
                >{appTitle}
                </Link>
              </Item>
            )}
            {breadcrumbItems}
          </Breadcrumb>
        )}
      />
    </div>
  )
}