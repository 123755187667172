export enum surveyState {
  SKIPPED = 'skipped',
  STARTED = 'started',
  FINISHED = 'finished',
}

export const reasons = [
  {
    value: 'Manual Outreach',
  },
  {
    value: 'Better Pricing',
  },
  {
    value: 'Trying Web Push',
  },
  {
    value: 'Better Retargeting',
  },
  {
    value: 'Better Features',
  },
  {
    value: 'Audience Migration',
  },
];

export const professions = [
  {
    value: 'Data Analyst',
  },
  {
    value: 'Product Manager',
  },
  {
    value: 'Affiliate Marketer',
  },
  {
    value: 'Marketing Specialist',
  },
  {
    value: 'Marketing Manager',
  },
  {
    value: 'Marketing Director',
  },
  {
    value: 'Marketing Research Analyst',
  },
  {
    value: 'Marketing Communications Manager',
  },
  {
    value: 'Marketing Consultant',
  },
  {
    value: 'Public Relations',
  },
  {
    value: 'Social Media Assistant',
  },
  {
    value: 'Brand Manager',
  },
  {
    value: 'SEO Manager',
  },
  {
    value: 'Content Marketing Manager',
  },
  {
    value: 'Copywriter',
  },
  {
    value: 'Media Buyer',
  },
  {
    value: 'Digital Marketing Manager',
  },
  {
    value: 'eCommerce Marketing Specialist',
  },
  {
    value: 'Brand Strategist',
  },
  {
    value: 'Vice President of Marketing',
  },
  {
    value: 'Media Relations Coordinator',
  },
  {
    value: 'Administrative Assistant',
  },
  {
    value: 'Branch Manager',
  },
  {
    value: 'Business Manager',
  },
  {
    value: 'Business Analyst',
  },
  {
    value: 'Program Administrator',
  },
  {
    value: 'Program Manager',
  },
  {
    value: 'CEO—Chief Executive Officer',
  },
  {
    value: 'COO—Chief Operating Officer',
  },
  {
    value: 'CIO—Chief Information Officer',
  },
  {
    value: 'CTO—Chief Technology Officer',
  },
  {
    value: 'CMO—Chief Marketing Officer',
  },
  {
    value: 'CPO—Chief Product Officer',
  },
  {
    value: 'CCO—Chief Customer Officer',
  },
  {
    value: 'Computer Scientist',
  },
  {
    value: 'IT Professional',
  },
  {
    value: 'UX Designer & UI Developer',
  },
  {
    value: 'SQL Developer',
  },
  {
    value: 'Web Designer',
  },
  {
    value: 'Web Developer',
  },
  {
    value: 'Help Desk Worker/Desktop Support',
  },
  {
    value: 'Software Engineer',
  },
  {
    value: 'Data Entry',
  },
  {
    value: 'DevOps Engineer',
  },
  {
    value: 'Computer Programmer',
  },
  {
    value: 'Network Administrator',
  },
  {
    value: 'Information Security Analyst',
  },
  {
    value: 'Artificial Intelligence Engineer',
  },
  {
    value: 'Cloud Architect',
  },
  {
    value: 'IT Manager',
  },
  {
    value: 'Technical Specialist',
  },
  {
    value: 'Application Developer',
  },
  {
    value: 'Retail Worker',
  },
  {
    value: 'Store Manager',
  },
  {
    value: 'Sales Representative',
  },
  {
    value: 'Sales Manager',
  },
  {
    value: 'Sales Associate',
  },
  {
    value: 'Account Executive',
  },
  {
    value: 'Account Manager',
  },
  {
    value: 'Director of Inside Sales',
  },
  {
    value: 'Outside Sales Manager',
  },
  {
    value: 'Sales Analyst',
  },
  {
    value: 'Market Development Manager',
  },
  {
    value: 'B2B Sales Specialist',
  },
  {
    value: 'Sales Engineer',
  },
  {
    value: 'Merchandising Associate',
  },
  {
    value: 'Customer Service',
  },
  {
    value: 'Customer Support',
  },
  {
    value: 'Help Desk Specialist',
  },
  {
    value: 'Customer Service Manager',
  },
  {
    value: 'Technical Support Specialist',
  },
  {
    value: 'Account Representative',
  },
  {
    value: 'Client Service Specialist',
  },
  {
    value: 'Customer Care Associate',
  },
]

export const competitors = [
  {
    value: 'Push7',
    label: 'Push7',
  },
  {
    value: 'OneSignal',
    label: 'OneSignal',
  },
  {
    value: 'Webpushr',
    label: 'Webpushr',
  },
  {
    value: 'iZooto',
    label: 'iZooto',
  },
  {
    value: 'PushEngage',
    label: 'PushEngage',
  },
  {
    value: 'VWO Engage',
    label: 'VWO Engage',
  },
  {
    value: 'Whisper Najva',
    label: 'Whisper Najva',
  },
  {
    value: 'Gravitec',
    label: 'Gravitec',
  },
  {
    value: 'Aimtell',
    label: 'Aimtell',
  },
  {
    value: 'Truepush',
    label: 'Truepush',
  },
  {
    value: 'Subscribers',
    label: 'Subscribers',
  },
  {
    value: 'PushAlert',
    label: 'PushAlert',
  },
  {
    value: 'Pushnami',
    label: 'Pushnami',
  },
  {
    value: 'FoxPush',
    label: 'FoxPush',
  },
  {
    value: 'CleverPush',
    label: 'CleverPush',
  },
  {
    value: 'Pushly',
    label: 'Pushly',
  },
  {
    value: 'Push.World',
    label: 'Push.World',
  },
  {
    value: 'PushPushGo',
    label: 'PushPushGo',
  },
  {
    value: 'WonderPush',
    label: 'WonderPush',
  },
  {
    value: 'Batch',
    label: 'Batch',
  },
  {
    value: 'Roost',
    label: 'Roost',
  },
  {
    value: 'PushWoosh',
    label: 'PushWoosh',
  },
  {
    value: 'Notix',
    label: 'Notix',
  },
  {
    value: 'PowerInbox Jeeng',
    label: 'PowerInbox Jeeng',
  },
  {
    value: 'AlertMe',
    label: 'AlertMe',
  },
  {
    value: 'Pushpad',
    label: 'Pushpad',
  },
  {
    value: 'Push4site',
    label: 'Push4site',
  },
  {
    value: 'Getback',
    label: 'Getback',
  },
  {
    value: 'Ably Realtime',
    label: 'Ably Realtime',
  },
  {
    value: 'NotifyVisitors',
    label: 'NotifyVisitors',
  },
  {
    value: 'pushAd',
    label: 'pushAd',
  },
  {
    value: 'COINs WebPush',
    label: 'COINs WebPush',
  },
  {
    value: 'Jeeng',
    label: 'Jeeng',
  },
  {
    value: 'PushPros',
    label: 'PushPros',
  },
  {
    value: 'Push Monkey',
    label: 'Push Monkey',
  },
  {
    value: 'Wisp',
    label: 'Wisp',
  },
  {
    value: 'PushPanda',
    label: 'PushPanda',
  },
  {
    value: 'Killtarget',
    label: 'Killtarget',
  },
  {
    label: 'Not Using Any Service',
    value: 'am not using any service',
  },
];

export const frequencies = [
  {
    label   : '1x per week',
    value   : '1x per week',
    perMonth: 4.33,
  },
  {
    label   : '2x per week',
    value   : '2x per week',
    perMonth: 8.66,
  },
  {
    label   : '3x per week',
    value   : '3x per week',
    perMonth: 12.99,
  },
  {
    label   : '4x per week',
    value   : '4x per week',
    perMonth: 17.32,
  },
  {
    label   : '1x per day',
    value   : '1x per day',
    perMonth: 30.41,
  },
  {
    label   : '2x per day',
    value   : '2x per day',
    perMonth: 60.82,
  },
  {
    label   : '3x per day',
    value   : '3x per day',
    perMonth: 91.23,
  },
];

export const subscribers = [
  {
    value: '5000',
    label: '5,000'
  },
  {
    value: '10000',
    label: '10,000'
  },
  {
    value: '15000',
    label: '15,000'
  },
  {
    value: '20000',
    label: '20,000'
  },
  {
    value: '25000',
    label: '25,000'
  },
  {
    value: '30000',
    label: '30,000'
  },
  {
    value: '35000',
    label: '35,000'
  },
  {
    value: '40000',
    label: '40,000'
  },
  {
    value: '45000',
    label: '45,000'
  },
  {
    value: '50000',
    label: '50,000'
  },
  {
    value: '60000',
    label: '60,000'
  },
  {
    value: '70000',
    label: '70,000'
  },
  {
    value: '80000',
    label: '80,000'
  },
  {
    value: '90000',
    label: '90,000'
  },
  {
    value: '100000',
    label: '100,000'
  },
  {
    value: '110000',
    label: '110,000'
  },
  {
    value: '120000',
    label: '120,000'
  },
  {
    value: '130000',
    label: '130,000'
  },
  {
    value: '140000',
    label: '140,000'
  },
  {
    value: '150000',
    label: '150,000'
  },
  {
    value: '160000',
    label: '160,000'
  },
  {
    value: '170000',
    label: '170,000'
  },
  {
    value: '180000',
    label: '180,000'
  },
  {
    value: '190000',
    label: '190,000'
  },
  {
    value: '200000',
    label: '200,000'
  },
  {
    value: '210000',
    label: '210,000'
  },
  {
    value: '220000',
    label: '220,000'
  },
  {
    value: '230000',
    label: '230,000'
  },
  {
    value: '240000',
    label: '240,000'
  },
  {
    value: '250000',
    label: '250,000'
  },
  {
    value: '300000',
    label: '300,000'
  },
  {
    value: '350000',
    label: '350,000'
  },
  {
    value: '400000',
    label: '400,000'
  },
  {
    value: '450000',
    label: '450,000'
  },
  {
    value: '500000',
    label: '500,000+'
  }
];

export const howDidYouFindUsOptions = [
  {
    value: 'Search Engine (Google, Bing, etc.)',
    label: 'Search Engine (Google, Bing, etc.)',
  },
  {
    value: 'Recommendation by friend or colleague',
    label: 'Recommendation by friend or colleague',
  },
  {
    value: 'Social media',
    label: 'Social media',
  },
  {
    value: 'AppSumo',
    label: 'AppSumo',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const tryingWebPush = 'trying web push';

export const amNotUsingAnyService = 'am not using any service';

export function calculateCoast(
  {
    perMonth,
    subscriber,
  }: {
    perMonth: number;
    subscriber: number;
  }
) {
  const display = Number(subscriber) * 0.4 * perMonth;

  let cost: number;
  if (display <= 200000) {
    cost = 0;
  } else if (display > 200000 && display <= 500000) {
    cost = 30 + 0.00014 * (display - 200000);
  } else if (display > 500000 && display <= 1000000) {
    cost = 70 + 0.00013 * (display - 500000);
  } else if (display > 1000000 && display < 5000000) {
    cost = 130 + 0.00012 * (display - 1000000);
  } else {
    cost = 600 + 0.00011 * (display - 5000000);
  }

  return cost.toFixed(1);
}
