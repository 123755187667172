import React from 'react';
import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useTitle} from '../../hooks/useTitle';
import {useStripe} from '@stripe/react-stripe-js';
import {CardElement} from '@stripe/react-stripe-js';
import {useElements} from '@stripe/react-stripe-js';
import {_loading} from '../../store/selectors/portal';
import {_countries} from '../../store/selectors/locales';
import {_scheduledPlan} from '../../store/selectors/portal';
import {_user} from '../../store/selectors/application/acl';
import {_paymentMethod} from '../../store/selectors/portal';
import {_prorationTotal} from '../../store/selectors/portal';
import {_skippingCheckout} from '../../store/selectors/portal';
import {_isAdmin} from '../../store/selectors/application/acl';
import {_subscriptionPlans} from '../../store/selectors/portal';
import {_showPlanChange} from '../../store/selectors/application/acl';
import {_fetchPortal} from '../../store/actions/subscriptionsAndBilling/fetchPortal';
import {_skipCheckout} from '../../store/actions/subscriptionsAndBilling/skipCheckout';
import {_updateSubscription} from '../../store/actions/subscriptionsAndBilling/updateSubscription';
import {_updateProrationTotal} from '../../store/actions/subscriptionsAndBilling/updateProrationTotal';
import {_fetchSubscriptionPlans} from '../../store/actions/subscriptionsAndBilling/fetchSubscriptionPlans';

export function useCheckout() {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const user = useSelector(_user);
  const isAdmin = useSelector(_isAdmin);
  useTitle('Complete Payment | SmartPush');
  const countries = useSelector(_countries);
  const fetchingPortal = useSelector(_loading);
  const scheduledPlan = useSelector(_scheduledPlan);
  const paymentMethod = useSelector(_paymentMethod);
  const prorationTotal = useSelector(_prorationTotal);
  const showPlanChange = useSelector(_showPlanChange);
  const skippingCheckout = useSelector(_skippingCheckout);
  const subscriptionPlans = useSelector(_subscriptionPlans);
  const {plan, appUuid} = useParams<{ plan: string, appUuid?: string }>();
  const form = useForm<Record<string, any>>({
    defaultValues: {
      interval: 'month',
      card    : 'existing_card',
      country : {
        value: 'us',
        label: 'UNITED STATES (US)',
      }
    }
  });
  const currentPlan: {
    plan_name: string;
    plan_slug: string;
    unit_amount: number;
    price_per_1000: number;
    free_displays_count: number;
    max_subscribers_count?: number;
  } = React.useMemo(() => {
    return (subscriptionPlans || []).find((sPlan: Record<string, any>) => {
      return sPlan.plan_slug === plan;
    })
  }, [
    plan,
    subscriptionPlans,
  ]);

  const {
    control,
    setError,
    setValue,
    register,
    clearErrors,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    },
  } = form;

  const onSubmit = React.useCallback((formValues: Record<string, any>) => {
    if (!stripe || !elements) {
      return;
    }

    elements.getElement('card').focus();

    return dispatch(_updateSubscription({
      stripe,
      setError,
      formValues,
      paymentMethod,
      card    : elements.getElement(CardElement),
      redirect: () => {
        window.location.replace('/');
      },
    }));
  }, [
    stripe,
    elements,
    dispatch,
    setError,
    paymentMethod,
  ]);

  const skipCheckout = React.useCallback((e) => {
    e.preventDefault();
    dispatch(_skipCheckout({
      redirect: () => {
        window.location.replace('/');
      },
    }));
  }, [
    dispatch,
  ]);

  React.useEffect(() => {
    dispatch(_fetchPortal())
  }, [
    dispatch,
  ]);

  React.useEffect(() => {
    dispatch(_fetchSubscriptionPlans())
  }, [
    dispatch,
  ]);

  React.useEffect(() => {
    setValue('payment_method', paymentMethod?.id, {
      shouldValidate: true,
    })
  }, [
    setValue,
    paymentMethod?.id,
  ]);

  React.useEffect(() => {
    setValue('plan_slug', plan, {
      shouldValidate: true,
    })
  }, [
    plan,
    setValue,
  ]);

  React.useEffect(() => {
    dispatch(_updateProrationTotal(plan))
  }, [
    plan,
    dispatch,
  ]);

  return {
    user,
    errors,
    appUuid,
    control,
    setError,
    register,
    countries,
    currentPlan,
    clearErrors,
    skipCheckout,
    isSubmitting,
    scheduledPlan,
    paymentMethod,
    prorationTotal,
    fetchingPortal,
    setValue,
    skippingCheckout,
    submit  : handleSubmit(onSubmit),
    showSkip: !isAdmin && showPlanChange,
  };
}