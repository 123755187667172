import React from 'react';
import Cropper from 'react-easy-crop'
import {Area} from 'react-easy-crop/types';
import {Point} from 'react-easy-crop/types';
import {MediaSize} from 'react-easy-crop/types';
import Typography from '@material-ui/core/Typography';

export default function ImageCropper(
  {
    crop,
    image,
    style,
    aspect,
    className,
    mediaStyle,
    onCropChange,
    onMediaLoaded,
    cropAreaStyle,
    containerStyle,
    onCropComplete,
    initialCroppedAreaPixels,
  }: {
    crop: Point;
    image: string;
    aspect: number;
    className?: string;
    mediaStyle?: object;
    cropAreaStyle?: object;
    containerStyle?: object;
    style?: React.CSSProperties;
    initialCroppedAreaPixels?: Area;
    onCropChange: (location: Point) => void;
    onCropComplete?: (...args: any[]) => void;
    onMediaLoaded?: (mediaSize: MediaSize) => void;
  }
) {
  return (
    <Typography
      style={style}
      component='div'
      className={className}
    >
      <Cropper
        crop={crop}
        image={image}
        aspect={aspect}
        onCropChange={onCropChange}
        onMediaLoaded={onMediaLoaded}
        onCropComplete={onCropComplete}
        style={{
          mediaStyle    : mediaStyle,
          cropAreaStyle : cropAreaStyle,
          containerStyle: containerStyle,
        }}
        initialCroppedAreaPixels={initialCroppedAreaPixels}
      />
    </Typography>
  )
}