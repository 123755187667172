import React from 'react';
import {Card} from 'antd';
import {Table} from 'antd';
import {Tooltip} from 'antd';
import {useSelector} from 'react-redux';
import {ColumnsType} from 'antd/lib/table/interface';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import {Text} from '../../../../../../components/antd/Typography';
import {FiniteStates} from '../../../../../../constants/finiteStates';
import {_reportsTable} from '../../../../../../store/selectors/reports';
import {_reportsState} from '../../../../../../store/selectors/reports';
import {_reportsShowTable} from '../../../../../../store/selectors/reports';
import {_isUnique as _isUniqueClicks} from '../../../../../../store/selectors/reports/clicks';
import {_isUnique as _isUniqueDisplays} from '../../../../../../store/selectors/reports/displays';

export function ReportsTable() {
  const ref = React.useRef<any>()
  const data = useSelector(_reportsTable);
  const checked = useSelector(_reportsShowTable);

  const reportsState = useSelector(_reportsState);
  const isUniqueClicks = useSelector(_isUniqueClicks);
  const isUniqueDisplays = useSelector(_isUniqueDisplays);

  React.useEffect(() => {
    if (checked && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [
    checked,
  ]);

  const columns: ColumnsType<any> = React.useMemo(() => {
    const isUnique = isUniqueClicks && isUniqueDisplays;
    const showWarning = isUniqueClicks !== isUniqueDisplays;

    return [
      {
        fixed    : true,
        key      : 'date',
        dataIndex: 'date',
        sorter   : (a, b) => (new Date(a.date)).getTime() - (new Date(b.date)).getTime(),
        title    : (
          <Text
            strong
            style={{whiteSpace: 'nowrap'}}
          >Date
          </Text>
        ),
        render   : (value) => <Text style={{whiteSpace: 'nowrap'}} strong>{value}</Text>
      },
      {
        dataIndex: 'total',
        key      : 'total',
        sorter   : (a, b) => a.total - b.total,
        title    : (
          <Text
            strong
            style={{
              whiteSpace: 'nowrap',
              color     : '#0DCFF9',
            }}
          >Total
          </Text>
        ),
        render   : (value) => ((value === '#') || (reportsState === FiniteStates.LOADING)) ? <LoadingOutlined/> : <>{value}</>
      },
      {
        key      : 'dailyInactive',
        dataIndex: 'dailyInactive',
        sorter   : (a, b) => a.dailyInactive - b.dailyInactive,
        title    : (
          <Text
            strong
            style={{
              whiteSpace: 'nowrap',
              color     : '#00e5ff',
            }}
          >Daily Inactive Users
          </Text>
        ),
        render   : (value) => ((value === '#') || (reportsState === FiniteStates.LOADING)) ? <LoadingOutlined/> : <>{value}</>
      },
      {
        dataIndex: 'unsubscribed',
        key      : 'unsubscribed',
        sorter   : (a, b) => a.unsubscribed - b.unsubscribed,
        title    : (
          <Text
            strong
            style={{
              whiteSpace: 'nowrap',
              color     : '#E8414D',
            }}
          >Unsubscribed
          </Text>
        ),
        render   : (value) => ((value === '#') || (reportsState === FiniteStates.LOADING)) ? <LoadingOutlined/> : <>{value}</>
      },
      {
        key      : 'periodUnsubscribed',
        dataIndex: 'periodUnsubscribed',
        sorter   : (a, b) => a.periodUnsubscribed - b.periodUnsubscribed,
        title    : (
          <Text
            strong
            style={{
              whiteSpace: 'nowrap',
              color     : '#CF5D66',
            }}
          >Unsubscribed in Period
          </Text>
        ),
        render   : (value) => ((value === '#') || (reportsState === FiniteStates.LOADING)) ? <LoadingOutlined/> : <>{value}</>
      },
      {
        key      : 'subscribed',
        dataIndex: 'subscribed',
        sorter   : (a, b) => a.subscribed - b.subscribed,
        title    : (
          <Text
            strong
            style={{
              whiteSpace: 'nowrap',
              color     : '#2CBD95',
            }}
          >Subscribed
          </Text>
        ),
        render   : (value) => ((value === '#') || (reportsState === FiniteStates.LOADING)) ? <LoadingOutlined/> : <>{value}</>
      },
      {
        key      : 'displays',
        dataIndex: 'displays',
        sorter   : (a, b) => a.displays - b.displays,
        title    : (
          <Text
            strong
            style={{
              whiteSpace: 'nowrap',
              color     : '#cddc39',
            }}
          >Displays
          </Text>
        ),
        render   : (value) => ((value === '#') || (reportsState === FiniteStates.LOADING)) ? <LoadingOutlined/> : <>{value}</>
      },
      {
        key      : 'clicks',
        dataIndex: 'clicks',
        sorter   : (a, b) => a.clicks - b.clicks,
        title    : (
          <Text
            strong
            style={{
              whiteSpace: 'nowrap',
              color     : '#FFC400',
            }}
          >Clicks
          </Text>
        ),
        render   : (value) => ((value === '#') || (reportsState === FiniteStates.LOADING)) ? <LoadingOutlined/> : <>{value}</>
      },
      {
        key      : 'closes',
        dataIndex: 'closes',
        sorter   : (a, b) => a.closes - b.closes,
        title    : (
          <Text
            strong
            style={{
              whiteSpace: 'nowrap',
              color     : '#673ab7',
            }}
          >Closes
          </Text>
        ),
        render   : (value) => ((value === '#') || (reportsState === FiniteStates.LOADING)) ? <LoadingOutlined/> : <>{value}</>
      },
      {
        key      : 'ctr',
        dataIndex: 'ctr',
        sorter   : !showWarning ? (a, b) => a.ctr - b.ctr : false,
        title    : (
          <Text
            strong
            style={{
              whiteSpace: 'nowrap',
              color     : '#C400BC',
            }}
          >{isUnique ? 'CTR (Unique)' : 'CTR'} {showWarning && <WarningTooltip/>}
          </Text>
        ),
        render   : (value) => {
          if ((value === '#') || (reportsState === FiniteStates.LOADING)) {
            return <LoadingOutlined/>;
          }

          return <>{Number.isNaN(Number(value)) ? value : `${Number(value).toFixed(2)}%`}</>
        }
      },
    ];
  }, [
    reportsState,
    isUniqueClicks,
    isUniqueDisplays,
  ]);

  return (
    <div
      ref={ref}
    >
      {checked && (
        <Card
          className='m-t-2'
        >
          <Table
            rowKey='date'
            columns={columns}
            scroll={{x: true}}
            dataSource={(isUniqueClicks !== isUniqueDisplays) ? data.map(d => ({...d, ctr: '-'})) : data}
          />
        </Card>
      )}
    </div>
  );
}

function WarningTooltip() {
  return (
    <Tooltip
      title={
        <>
          Cannot calculate CTR as Displays and Clicks charts are not in the same state.
          <br/>
          Please set the "Unique" toggle for both of them to On or Off at the same time.
        </>
      }
    >
      <Text type='warning'>
        <WarningOutlined/>
      </Text>
    </Tooltip>
  )
}