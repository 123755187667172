import React from 'react';
import {TagsInput} from '../TagsInput';
import {EmojiInput} from '../EmojiInput';
import SelfTesting from '../SelfTesting';
import {UploadButton} from '../UploadInput';
import {TITLE_LIMIT} from '../../constants';
import {useTranslation} from 'react-i18next';
import Hidden from '@material-ui/core/Hidden';
import {MESSAGE_LIMIT} from '../../constants';
import {Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {CampaignPreview} from '../WebNotifyCampaignPreview';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  campaignPreviewWrapper: {
    display                    : 'flex',
    justifyContent             : 'space-between',
    '& > div:first-child'      : {
      marginRight: theme.spacing(.5),
    },
    '& > div:last-child'       : {
      marginLeft: theme.spacing(.5),
    },
    '@media (max-width:1199px)': {
      justifyContent       : 'unset',
      flexDirection        : 'column',
      '& > div:first-child': {
        marginRight: 0,
      },
      '& > div:last-child' : {
        margin: '0 auto',
      },
    }
  },
}));

export default React.memo((
  {
    tags,
    index,
    labels,
    errors,
    entity,
    actions,
    disabled,
    updateEntityLang,
  }: {
    tags?: any;
    labels: any;
    errors: any;
    entity: any;
    index: number;
    actions?: any;
    loading?: boolean;
    disabled?: boolean;
    className?: string;
    updateEntityLang: any;
  }
) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <>
      {!!tags && (
        <TagsInput
          fullWidth
          name='url'
          tags={tags}
          disabled={disabled}
          error={!!errors.url}
          helperText={errors.url}
          label={labels.launch_url}
          value={entity?.url || ''}
          placeholder={labels.launch_url_placeholder}
          inputProps={{'data-id': `tags-field-${index}`}}
          change={(e: any) => updateEntityLang('url', e)}
        />
      )}
      <Typography
        component='div'
        className={classes.campaignPreviewWrapper}
      >
        <Typography
          component='div'
        >
          <UploadButton
            fullWidth
            name='icon_url'
            disabled={disabled}
            label={labels.icon_url}
            error={!!errors?.icon_url}
            value={entity?.icon_url || ''}
            errorMessage={errors?.icon_url}
            helperText={labels.icon_url_helper_text}
            placeholder={labels.icon_url_placeholder}
            inputProps={{'data-id': `icon-url-field-${index}`}}
            change={(e: any) => updateEntityLang('icon_url', e)}
          />
          <UploadButton
            fullWidth
            name='image_url'
            disabled={disabled}
            label={labels.image_url}
            error={!!errors?.image_url}
            value={entity?.image_url || ''}
            errorMessage={errors?.image_url}
            helperText={labels.image_url_helper_text}
            placeholder={labels.image_url_placeholder}
            inputProps={{'data-id': `image-url-field-${index}`}}
            change={(e: any) => updateEntityLang('image_url', e)}
          />
          <EmojiInput
            fullWidth
            name='title'
            disabled={disabled}
            label={labels.title}
            error={!!errors?.title}
            helperText={errors?.title}
            value={entity?.title || ''}
            characterLimit={TITLE_LIMIT}
            placeholder={labels.title_placeholder}
            inputProps={{'data-id': `title-field-${index}`}}
            change={(e: any) => updateEntityLang('title', e)}
          />
          <EmojiInput
            fullWidth
            name='message'
            disabled={disabled}
            label={labels.message}
            error={!!errors?.message}
            helperText={errors?.message}
            value={entity?.message || ''}
            characterLimit={MESSAGE_LIMIT}
            placeholder={labels.message_placeholder}
            inputProps={{'data-id': `message-field-${index}`}}
            change={(e: any) => updateEntityLang('message', e)}
          />
        </Typography>
        <Hidden
          xsDown
        >
          <div>
            <CampaignPreview
              disabled={disabled}
              title={entity?.title}
              icon={entity?.icon_url}
              image={entity?.image_url}
              message={entity?.message}
              actions={(Object.values(actions || {})).map((action: any) => (action.title))}
            />
            <SelfTesting
              color='primary'
              variant='outlined'
              disabled={disabled}
              notification={{...entity, url: `${entity?.url}`, actions}}
              style={{margin: '0 auto', marginTop: 16, display: 'flex', width: '100%'}}
            >{t('campaigns.self_test')}
            </SelfTesting>
          </div>
        </Hidden>
      </Typography>
    </>
  )
});
