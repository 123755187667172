import React from 'react';
import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {useTitle} from '../../../../hooks/useTitle';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {_register} from '../../../../store/actions/application/register';

export interface IRegisterForm {
  plan: string;
  email: string;
  password: string;
  message: string;
  last_name: string;
  first_name: string;
}

export function useRegister() {
  const {search} = useLocation();
  useTitle('Sign up | SmartPush');
  const {plan} = useParams<{ plan?: string }>();
  const defaultValues: Partial<IRegisterForm> = {
    plan,
    message   : '',
    email     : process.env.REACT_APP_EMAIL,
    password  : process.env.REACT_APP_PASSWORD,
    last_name : process.env.REACT_APP_LAST_NAME,
    first_name: process.env.REACT_APP_FIRST_NAME,
  }

  const searchString = search.replace('?', '');

  const {
    setError,
    handleSubmit,
    ...formRest
  } = useForm<IRegisterForm>({
    mode         : 'onSubmit',
    defaultValues: defaultValues,
  });
  const dispatch = useDispatch();
  const {executeRecaptcha} = useGoogleReCaptcha();

  const submit = React.useCallback((formValues: IRegisterForm) => {
    return dispatch(_register({
      ...formValues,
      searchString,
      email     : formValues.email?.trim(),
      last_name : formValues.last_name?.trim(),
      first_name: formValues.first_name?.trim(),
    }, {
      setError,
      executeRecaptcha,
    }));
  }, [
    setError,
    dispatch,
    searchString,
    executeRecaptcha,
  ]);

  return {
    ...formRest,
    searchString,
    onSubmit: handleSubmit(submit),
  }
}
