import users from './users';
import campaigns from './campaigns';
import templates from './templates';
import settings from './appSettings';
import {combineReducers} from 'redux';
import subscribers from './subscribers';
import templateCampaigns from './templateCampaigns';

export default combineReducers({
  users,
  settings,
  templates,
  campaigns,
  subscribers,
  templateCampaigns,
});
