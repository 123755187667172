import React from 'react';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {TableActions} from '../../../../../constants/tableActions';
import DeleteDialog from '../../../../../components/Dialog/Delete';
import {destroyAction} from '../../../../../store/actions/subscribers';
import {getDataOptions} from '../../../../../store/selectors/subscribers';
import {getActionDialog} from '../../../../../store/selectors/subscribers';
import {toggleDialogAction} from '../../../../../store/actions/subscribers';

export default function Dialogs() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {appUuid} = useParams<{ appUuid: string }>();

  const handleClose = React.useCallback(() => {
    dispatch(toggleDialogAction(void 0));
  }, [
    dispatch,
  ]);

  const handleDelete = React.useCallback(() => {
    dispatch(destroyAction(appUuid));
  }, [
    appUuid,
    dispatch,
  ]);

  const dialog = useSelector(getActionDialog);
  const {
    isDeleting,
  } = useSelector(getDataOptions);

  switch (dialog) {
    case TableActions.DELETE: {
      return (
        <DeleteDialog
          onClose={handleClose}
          loading={!!isDeleting}
          agreeAction={handleDelete}
          dismissAction={handleClose}
          open={TableActions.DELETE === dialog}
          bodyTitle={t('subscribers.delete_body_title')}
          dismissLabel={t('subscribers.keep_subscriber')}
          agreeLabel={t('subscribers.archive_subscriber')}
          bodyDescription={t('subscribers.delete_body_description')}
        />
      )
    }
    default: {
      return null;
    }
  }
}
