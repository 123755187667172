import clsx from 'clsx';
import React from 'react';
import './index.less';

export function Pre(props: React.HTMLProps<HTMLPreElement>) {
  return (
    <pre
      {...props}
      className={clsx('naked-pre', props.className)}
    />
  )
}