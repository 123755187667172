import React from 'react';
import {useTranslation} from 'react-i18next';
import {DataTypeProvider} from '@devexpress/dx-react-grid';

export default function DeliverAtFormatter(
  {
    value,
  }: DataTypeProvider.ValueFormatterProps
) {
  const {t} = useTranslation();
  return (
    <>{(String(value).trim() === '-') ? t('campaigns.direct') : value}</>
  )
}
