import React from 'react';
import CustomPager from './Pager';
import StickyTable from './Table';
import {Loading} from '../Loading';
import {IObject} from '../../types';
import {stringify} from 'querystring';
import {filterObject} from '../../utils';
import DefaultProviders from './Providers';
import {useTranslation} from 'react-i18next';
import CustomHeaderMarkup from './HeaderMarkup';
import {Column} from '@devexpress/dx-react-grid';
import {Sorting} from '@devexpress/dx-react-grid';
import CustomToolbarMarkup from './ToolbarMarkup';
import {DEFAULT_PAGE_SIZES} from '../../constants';
import TableRowComponent from './TableRowComponent';
import Typography from '@material-ui/core/Typography';
import {SearchState} from '@devexpress/dx-react-grid';
import {PagingState} from '@devexpress/dx-react-grid';
import {CustomPaging} from '@devexpress/dx-react-grid';
import {SortingState} from '@devexpress/dx-react-grid';
import {SelectionState} from '@devexpress/dx-react-grid';
import ToolbarRootComponent from './ToolbarRootComponent';
import {Grid} from '@devexpress/dx-react-grid-material-ui';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import ToggleButtonComponent from './ToggleButtonComponent';
import {Table as TableBase} from '@devexpress/dx-react-grid';
import {IntegratedSelection} from '@devexpress/dx-react-grid';
import {ColumnChooserBaseItem} from './ColumnChooserBaseItem';
import {Toolbar} from '@devexpress/dx-react-grid-material-ui';
import TableContainerComponent from './TableContainerComponent';
import {PagingPanel} from '@devexpress/dx-react-grid-material-ui';
import {SearchPanel} from '@devexpress/dx-react-grid-material-ui';
import {ColumnChooser} from '@devexpress/dx-react-grid-material-ui';
import {TableHeaderRow} from '@devexpress/dx-react-grid-material-ui';
import {TableSelection} from '@devexpress/dx-react-grid-material-ui';
import {SearchPanelInputComponent} from './SearchPanelInputComponent';
import {TableFixedColumns} from '@devexpress/dx-react-grid-material-ui';
import TableSelectionCellComponent from './TableSelectionCellComponent';
import {TableColumnVisibility} from '@devexpress/dx-react-grid-material-ui';
import {TableColumnReordering} from '@devexpress/dx-react-grid-material-ui';
import TableFixedColumnsCellComponent from './TableFixedColumnsCellComponent';
import ColumnChooserContainerComponent from './ColumnChooserContainerComponent';
import TableSelectionHeaderCellComponent from './TableSelectionHeaderCellComponent';
// import {DragDropProvider} from '@devexpress/dx-react-grid-material-ui';

export default function (
  {
    url,
    byId,
    allIds,
    loading,
    getRowId,
    loadData,
    messages,
    headerTop,
    hasMorePages,
    handleExport,
    headerBottom,
    disableSearch,
    disableReload,
    disableToolbar,
    simplePagination,
    updateTableOptions,
    toolbarLeftActions,
    toolbarRightActions,
    integratedSelection,
    options           : tableOptions,
    customProviders   : CustomProviders,
    tableCellComponent: TableCellComponent,
  }: {
    url: string;
    byId: IObject;
    options: {
      to?: string;
      tab?: string;
      total: number;
      from?: string;
      filters?: any[];
      pageSize: number;
      pageNumber: number;
      sorting: Sorting[];
      pageSizes?: number[];
      columnOrder?: string[];
      filtersIsOpen?: boolean;
      defaultSorting?: Sorting[];
      disableColumnOrder?: boolean;
      tableColumnVisibility?: {
        defaultHiddenColumnNames?: Array<string>;
        columnExtensions?: Array<TableColumnVisibility.ColumnExtension>;
      };
      columns: ReadonlyArray<Column>;
      searchText: string | undefined;
      others: IObject;
      selection?: Array<number | string>;
      tableColumnExtensions?: Array<Table.ColumnExtension>;
      sortingStateColumnExtensions?: Array<SortingState.ColumnExtension>;
    };
    allIds: string[];
    messages?: {
      export?: string;
      exportAgree?: string;
      tableNoData?: string;
      exportCancel?: string;
      exportMainText?: string;
      searchPlaceholder?: string;
      exportSecondaryText?: string;
    };
    loading?: boolean;
    hasMorePages?: boolean;
    disableSearch?: boolean;
    disableReload?: boolean;
    disableToolbar?: boolean;
    simplePagination?: boolean;
    loadData: () => any | void;
    integratedSelection?: boolean;
    handleExport?: () => any | void;
    customProviders?: React.FC<any>;
    headerTop?: React.ComponentType<any>;
    headerBottom?: React.ComponentType<any>;
    getRowId?: (row: any) => number | string;
    toolbarLeftActions?: React.ComponentType<any>;
    toolbarRightActions?: React.ComponentType<any>;
    updateTableOptions: (options: any) => any | void;
    tableCellComponent?: React.ComponentType<Table.DataCellProps>;
  }
) {
  const {t} = useTranslation();

  React.useEffect(() => {
    const filters = (tableOptions.filters || [] as Array<any>).reduce((acc: any, current: any) => {
      if (Array.isArray(current.value)) {
        acc[current.columnName] = current.value.map((item: any) => item.value).join(',');
      } else {
        if (current.value && current.value.value === 0) {
          acc[current.columnName] = 0;
        } else {
          if (current.type === 'daterange') {
            if (current.value) {
              const dateRanges = current.value.split(' - ');
              acc[`${current.columnName}_start_date`] = dateRanges[0];
              acc[`${current.columnName}_end_date`] = dateRanges[1];
            }
          } else {
            acc[current.columnName] = (current.value && current.value.value) || current.value;
          }
        }
      }
      return acc;
    }, {});

    const queryString = {
      ...filterObject({
        pageSize  : tableOptions.pageSize,
        pageNumber: tableOptions.pageNumber,
        searchText: tableOptions.searchText,
        sortOrder : tableOptions.sorting?.[0]?.direction,
        sortName  : tableOptions.sorting?.[0]?.columnName,
        to        : tableOptions.to ? tableOptions.to : void 0,
        tab       : tableOptions.tab ? tableOptions.tab : void 0,
        from      : tableOptions.from ? tableOptions.from : void 0,
      }),
      ...filterObject(tableOptions.others || {}),
      ...filterObject(filters || {})
    };

    updateTableOptions({lastQuery: `${url}?${stringify(queryString)}`});
    loadData();
    // eslint-disable-next-line
  }, [
    url,
    tableOptions.to,
    tableOptions.tab,
    tableOptions.from,
    tableOptions.others,
    tableOptions.sorting,
    tableOptions.filters,
    tableOptions.pageSize,
    tableOptions.pageNumber,
    tableOptions.searchText,
  ]);

  React.useEffect(() => {
    return function () {
      updateTableOptions({
        pageNumber : 1,
        total      : void 0,
        options    : void 0,
        lastQuery  : void 0,
        selection  : void 0,
        searchText : void 0,
        allSelected: void 0,
      });
    }
    // eslint-disable-next-line
  }, []);

  const rows = React.useMemo(() => {
    return allIds.map((item) => byId && byId[item]);
  }, [
    byId,
    allIds,
  ]);

  const toggleFilters = React.useCallback(() => {
    updateTableOptions({
      filtersIsOpen: !tableOptions.filtersIsOpen
    })
  }, [
    updateTableOptions,
    tableOptions.filtersIsOpen
  ]);

  const orderChange = React.useCallback((nextOrder) => {
    if (!nextOrder.includes('actions')) {
      updateTableOptions({columnOrder: nextOrder});
    } else {
      if (nextOrder.indexOf('actions') === (nextOrder.length - 1)) {
        updateTableOptions({columnOrder: nextOrder});
      }
    }
  }, [
    updateTableOptions,
  ]);

  const changePageSize = React.useCallback((value: number) => {
    updateTableOptions({
      pageNumber: 1,
      pageSize  : value,
    })
  }, [
    updateTableOptions
  ]);

  const changeSorting = React.useCallback((value: Sorting[]) => {
    updateTableOptions({
      sorting: value,
    });
  }, [
    updateTableOptions,
  ]);

  const TableContainerComponentT = React.useCallback((props: any) => {
    return (
      <TableContainerComponent
        filters={tableOptions.filters || []}
        updateTableOptions={updateTableOptions}
        filtersIsOpen={tableOptions.filtersIsOpen}
        {...props}
      />
    )
    // eslint-disable-next-line
  }, [
    tableOptions.filters,
    tableOptions.filtersIsOpen,
  ]);

  const changeCurrentPage = React.useCallback((currentPage: number) => {
    updateTableOptions({
      allSelected: void 0,
      pageNumber : currentPage + 1,
    })
  }, [
    updateTableOptions
  ]);

  const changeSearchText = React.useCallback((value: string | undefined) => {
    updateTableOptions({
      pageNumber : 1,
      searchText : value,
      selection  : void 0,
      allSelected: void 0,
    })
  }, [
    updateTableOptions,
  ]);

  const onSelectionChange = React.useCallback((selection: Array<number | string>) => {
    updateTableOptions({
      selection,
      allSelected: void 0,
    });
  }, [
    updateTableOptions,
  ]);

  const onHiddenColumnNamesChange = React.useCallback((hiddenColumnNames: string[]) => {
    updateTableOptions({
      defaultHiddenColumnNames: hiddenColumnNames,
    })
  }, [
    updateTableOptions
  ]);

  return (
    <>
      <Grid
        rows={rows}
        getRowId={getRowId}
        columns={tableOptions.columns}
      >
        {!!integratedSelection && (
          <SelectionState
            onSelectionChange={onSelectionChange}
            selection={tableOptions.selection || []}
          />
        )}
        <SortingState
          sorting={tableOptions.sorting}
          onSortingChange={changeSorting}
          columnExtensions={tableOptions.sortingStateColumnExtensions}
        />
        {!disableSearch && (
          <SearchState
            onValueChange={changeSearchText}
            value={((tableOptions && tableOptions.searchText) || '').trim()}
          />
        )}
        <PagingState
          pageSize={tableOptions.pageSize}
          onPageSizeChange={changePageSize}
          onCurrentPageChange={changeCurrentPage}
          currentPage={tableOptions.pageNumber - 1}
        />
        <CustomPaging
          totalCount={tableOptions.total}
        />
        <DefaultProviders/>
        {CustomProviders && (
          <CustomProviders
            actions={React.useMemo<any>((() => ({
              refresh: () => loadData()
            })), [
              loadData,
            ])}
          />
        )}
        {/*{tableOptions.columnOrder && (*/}
        {/*  <DragDropProvider/>*/}
        {/*)}*/}
        {!!integratedSelection && (
          <IntegratedSelection/>
        )}
        <Table
          messages={{
            noData: messages?.tableNoData || t('table.no_data'),
          }}
          tableComponent={StickyTable}
          rowComponent={TableRowComponent}
          noDataCellComponent={NoDataCell}
          containerComponent={TableContainerComponentT}
          columnExtensions={tableOptions.tableColumnExtensions}
          cellComponent={TableCellComponent ? TableCellComponent : Table.Cell}
        />
        {!disableToolbar && (
          <Toolbar
            rootComponent={ToolbarRootComponent}
          />
        )}
        <TableHeaderRow
          messages={{
            sortingHint: t('table.sorting_hint'),
          }}
          showSortingControls
        />
        {!disableSearch && (
          <SearchPanel
            messages={{
              searchPlaceholder: ((messages || {}).searchPlaceholder) || t('table.search_placeholder'),
            }}
            inputComponent={SearchPanelInputComponent}
          />
        )}
        {tableOptions.columnOrder && !tableOptions.disableColumnOrder && (
          <TableColumnReordering
            onOrderChange={orderChange}
            order={tableOptions.columnOrder}
          />
        )}
        <PagingPanel
          messages={{
            showAll    : t('table.show_all'),
            rowsPerPage: t('table.rows_per_page'),
            info       : ({from, to, count}) => t('table.from_to_count', {from, to, count}),
          }}
          pageSizes={tableOptions.pageSizes || DEFAULT_PAGE_SIZES}
          containerComponent={(props) => (
            <CustomPager
              {...props}
              hasMorePages={hasMorePages}
              simplePagination={simplePagination}
            />
          )}
        />
        <CustomToolbarMarkup
          messages={{
            export             : messages.export,
            refresh            : t('table.refresh'),
            exportAgree        : messages.exportAgree,
            tableNoData        : messages.tableNoData,
            exportCancel       : messages.exportCancel,
            show_filters       : t('table.show_filters'),
            exportMainText     : messages.exportMainText,
            export_as_csv      : t('table.export_as_csv'),
            exportSecondaryText: messages.exportSecondaryText
          }}
          refresh={loadData}
          handleExport={handleExport}
          toggleFilters={toggleFilters}
          disableReload={disableReload}
          showFilters={!!tableOptions.filters}
          toolbarLeftActions={toolbarLeftActions}
          toolbarRightActions={toolbarRightActions}
        />
        <CustomHeaderMarkup
          headerTop={headerTop}
          refresh={() => loadData()}
          headerBottom={headerBottom}
        />
        {!!tableOptions.tableColumnVisibility && (
          <TableColumnVisibility
            onHiddenColumnNamesChange={onHiddenColumnNamesChange}
            messages={{noColumns: t('table.nothing_to_show')}}
            columnExtensions={tableOptions.tableColumnVisibility.columnExtensions}
            defaultHiddenColumnNames={tableOptions.tableColumnVisibility.defaultHiddenColumnNames}
          />
        )}
        {!!tableOptions.tableColumnVisibility && (
          <ColumnChooser
            itemComponent={ColumnChooserBaseItem}
            toggleButtonComponent={ToggleButtonComponent}
            containerComponent={ColumnChooserContainerComponent}
            messages={{showColumnChooser: t('table.show_column_chooser')}}
          />
        )}
        {!!integratedSelection && (
          <TableSelection
            highlightRow
            showSelectAll
            showSelectionColumn
            cellComponent={TableSelectionCellComponent}
            headerCellComponent={TableSelectionHeaderCellComponent}
          />
        )}
        <TableFixedColumns
          rightColumns={['actions']}
          leftColumns={[TableSelection.COLUMN_TYPE]}
          cellComponent={TableFixedColumnsCellComponent}
        />
      </Grid>
      <Loading
        isLoading={!!loading}
        bgColor='rgba(0,0,0,.1)'
      />
    </>
  )
}

function NoDataCell(
  {
    getMessage,
    ...rest
  }: TableBase.NoDataCellProps & {
    // noinspection JSUnusedLocalSymbols
    [x: string]: any;
    className?: string;
    style?: React.CSSProperties;
  }
) {
  const text = getMessage('noData');
  const textArray = text.split('\n');

  return (
    <Table.StubCell
      {...rest}
      style={{padding: '48px 0px'}}
    >
      <Typography
        align='center'
        style={{
          left     : '50%',
          position : 'sticky',
          fontSize : 'larger',
          display  : 'inline-block',
          transform: 'translateX(-50%)',
        }}
      >
        {textArray.map((text: string, index: number) => {
          return (
            <span
              key={index}
            >
              <span>{text}</span>
              <br/>
            </span>
          )
        })}
      </Typography>
    </Table.StubCell>
  )
}
