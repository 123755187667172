import {Row} from 'antd';
import {Col} from 'antd';
import {Form} from 'antd';
import {Card} from 'antd';
import React from 'react';
import {Space} from 'antd';
import {Switch} from 'antd';
import {Tooltip} from 'antd';
import {Controller} from 'react-hook-form';
import {useUpsertApp} from './useUpsertApp';
import {Uploader} from './components/Uploader';
import {PromptPreview} from './components/Preview';
import {SubmitButton} from './components/SubmitButton';
import {HeaderWrapper} from './components/HeaderWrapper';
import {TitleListener} from './components/TitleListener';
import {Text} from '../../../../../components/antd/Typography';
import {Content} from '../../../../../components/antd/Content';
import {SiteUrlComponent} from './components/SiteUrlComponent';
import {SiteTitleComponent} from './components/SiteTitleComponent';
import {FormAlert} from '../../../../../components/antd/FormAlert';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

export function UpsertApp() {
  const {
    submit,
    control,
    register,
    setError,
    setValue,
    isCreating,
    clearErrors,
    formState: {
      errors,
      dirtyFields,
      isSubmitting,
    },
  } = useUpsertApp();

  return (
    <div>
      <form
        onSubmit={submit}
      >
        <input
          type='hidden'
          {...register('uuid')}
        />
        <input
          type='hidden'
          {...register('default_url')}
        />
        <TitleListener
          control={control}
        />
        <HeaderWrapper
          control={control}
        />
        <Content
          gutter={8}
        >
          <div
            className='d-flex align-items-center flex-column'
          >
            <Card
              bordered={false}
              title='1. Enter Site Info'
              bodyStyle={{paddingBottom: 8}}
              className='create-app-card m-b-2'
            >
              <Form
                component='div'
                layout='vertical'
              >
                <Controller
                  control={control}
                  name='limits_notice'
                  render={(
                    {
                      fieldState: {
                        error,
                      }
                    }
                  ) => {
                    return (
                      <FormAlert
                        type='error'
                        message={error?.message}
                        onClose={() => clearErrors('limits_notice')}
                      />
                    )
                  }}
                />
                <SiteUrlComponent
                  control={control}
                />
                <SiteTitleComponent
                  control={control}
                  setValue={setValue}
                  dirtyFields={dirtyFields}
                />
              </Form>
            </Card>
            <Card
              bordered={false}
              title='2. Upload Logo (optional)'
              className='create-app-card m-b-2'
            >
              <Row
                gutter={24}
                align='top'
                justify='space-between'
                className='create-app-site-info-row'
              >
                <Col
                  xs={24}
                  sm={12}
                >
                  <Uploader
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                    error={errors?.default_icon_url?.message}
                  />
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  className='hoverable-item'
                >
                  <div
                    className='m-b-2'
                  >
                    <Text
                      fontWeight='regular'
                    >It will be used in prompt message & push notifications.
                    </Text>
                  </div>
                  <PromptPreview
                    control={control}
                  />
                </Col>
              </Row>
              <Row
                gutter={24}
                align='top'
                justify='space-between'
                className='create-app-site-info-row'
              >
                <Col
                  xs={24}
                  sm={12}
                >
                  <Controller
                    control={control}
                    name='gdpr_compliance'
                    render={(
                      {
                        field     : {
                          ref,
                          value,
                          onBlur,
                          ...fieldRest
                        },
                        fieldState: {
                          error,
                          invalid,
                        }
                      }
                    ) => {
                      return (
                        <Form.Item
                          colon={false}
                          help={error?.message}
                          label={<SwitchLabel/>}
                          validateStatus={invalid ? 'error' : void 0}
                        >
                          <Switch
                            ref={ref}
                            autoFocus
                            checked={!!value}
                            disabled={!isCreating}
                            {...fieldRest}
                          />
                        </Form.Item>
                      )
                    }}
                  />
                </Col>
              </Row>
            </Card>
            <div
              className='create-app-card right'
            >
              <SubmitButton
                control={control}
                loading={isSubmitting}
              />
            </div>
          </div>
        </Content>
      </form>
    </div>
  )
}

function SwitchLabel() {
  return (
    <Space
      size={3}
      align='start'
    >
      <Text>GDPR / CalOPPA Compliance</Text>
      <Tooltip
        title='When turned on, we will not store any personally-identifying data about your subscribers. You can only change this setting when creating a new app.'
      >
        <Text fontSize='small'><InfoCircleOutlined/></Text>
      </Tooltip>
    </Space>
  )
}
