import React from 'react';

export function useTitle(title: string) {
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = title;
    }
  }, [
    title,
  ]);
}