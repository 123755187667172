import {notification} from 'antd';
import {Globals} from '../../../types/globals';
import {UseFormSetError} from 'react-hook-form';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {requestTransformer} from '../../../utils/segmentsTransformer';

export const _create = (
  appUuid: string,
  formValues: Record<string, any>,
  {
    setError,
    redirect,
    unregisterCallback,
  }: {
    redirect: () => void;
    unregisterCallback: () => void;
    setError: UseFormSetError<Record<string, any>>;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  const {
    hasError,
    body: {
      data,
      message,
    }
  } = await api.post(urlHelper(ApiRoutes.CREATE_SEGMENT, {
    appUuid,
  }), requestTransformer(formValues));

  if (hasError) {
    const {
      limits_notice,
      ...restErrors
    } = data || {};

    if (limits_notice || message) {
      notification.error({message: limits_notice || message});
    }

    Object.keys(restErrors).forEach(value => {
      let inputName = value;
      if (inputName.endsWith('.value')) {
        inputName = inputName.replace('value', 'values');
      }
      const match = inputName.match(/\d+$/);
      if (match) {
        let fName = inputName.slice(0, -(match[0].length + 1));
        if (fName.endsWith('.value')) {
          fName = fName.replace('value', 'values');
        }
        const iName = `${fName.replace('values', '_values')}.${match[0]}.value`;
        setError(iName, {
          message: data[value],
        });
      } else {
        setError(inputName, {
          message: data[value],
        });
      }
    });
  } else {
    unregisterCallback();

    redirect();
  }
};
