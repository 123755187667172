import React from 'react';
import {Card} from '../Card';
import {TextField} from '../Input';
import Box from '@material-ui/core/Box';
import {IconButton} from '../IconButton';
import {CardHeader} from '../CardHeader';
import {AutoComplete} from '../AutoComplete';
import HelpIcon from '@material-ui/icons/Help';
import {Theme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {options} from '../../constants/ttlOptions';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  nestedCard           : {
    marginBottom: theme.spacing(1),
  },
  cardHeaderRoot       : {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  cardContentRoot      : {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  helperRoot           : {
    display                            : 'flex',
    alignItems                         : 'center',
    marginTop                          : theme.spacing(-2),
    marginBottom                       : theme.spacing(3),
    '& button'                         : {
      marginLeft  : theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& button .MuiTypography-root'     : {
      fontSize: 12,
    },
    '& span.MuiTypography-colorPrimary': {
      marginBottom: theme.spacing(1),
    },
  },
  cardHeaderContent    : {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'space-between',
  },
  nestedTitleTypography: {
    fontSize  : 16,
    fontWeight: 'bold',
    color     : theme.palette.text.primary,
  },
  ttlLabel             : {
    alignItems    : 'center',
    justifyContent: 'flex-start',
    display       : 'inline-flex',
  },
  nestedCardHeaderRoot : {
    color          : theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
  },
}));

export default function ScheduleCard(
  {
    labels,
    errors,
    entity,
    disabled,
    onChange,
  }: {
    labels: any;
    errors: any;
    entity: any;
    onChange: any;
    disabled?: boolean;
  }
) {
  const classes = useStyles();

  return (
    <Card
      elevation={3}
      className={classes.nestedCard}
    >
      <CardHeader
        title={labels.schedule}
        color={disabled ? 'disabled' : 'primary'}
      />
      <CardContent
        classes={{root: classes.cardContentRoot}}
      >
        <Typography
          component='div'
          style={{display: 'flex', alignItems: 'center'}}
        >
          <TextField
            type='number'
            disabled={disabled}
            inputProps={{min: 1}}
            name='wait_until_value'
            style={{marginRight: 8}}
            label={labels.wait_before_send}
            value={entity?.wait_until_value || ''}
            error={!!errors?.wait_until_value || !!errors?.wait_until}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            helperText={errors?.wait_until_value || errors?.wait_until}
          />
          <Box
            marginTop='-5px'
          >
            <AutoComplete
              disableClearable
              options={options}
              style={{width: 140}}
              disabled={disabled}
              value={entity?.wait_until_unit}
              getOptionLabel={option => option?.label || ''}
              onChange={(e: any, value: any) => onChange('wait_until_unit', value)}
              getOptionSelected={(option, value) => value?.value === option?.value}
              renderInput={(props) => {
                return (
                  <TextField
                    name='role'
                    type='text'
                    variant='outlined'
                    disabled={disabled}
                    style={{marginTop: 28}}
                    error={!!errors?.wait_until_unit}
                    helperText={errors?.wait_until_unit}
                    {...props}
                  />
                )
              }}
            />
            {(!!errors?.wait_until_value || !!errors?.wait_until) && (
              <Box height={27}/>
            )}
          </Box>
        </Typography>
        <Typography
          component='div'
          style={{display: 'flex'}}
        >
          <TextField
            type='number'
            name='ttl_value'
            disabled={disabled}
            inputProps={{min: 1}}
            style={{marginRight: 8}}
            value={entity.ttl_value || ''}
            error={!!errors?.ttl_value || !!errors?.ttl}
            helperText={errors?.ttl_value || errors?.ttl}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            label={
              <Typography
                component='div'
                className={classes.ttlLabel}
              >
              <span
              >{labels.ttl}
              </span>
                <Tooltip
                  title={labels.ttl_helper_text}
                >
                  <div>
                    <IconButton
                      size='small'
                      color='transparent'
                    >
                      <HelpIcon
                        fontSize='small'
                      />
                    </IconButton>
                  </div>
                </Tooltip>
              </Typography>
            }
          />
          <Box
            marginTop='2px'
          >
            <AutoComplete
              disableClearable
              options={options}
              disabled={disabled}
              style={{width: 140}}
              value={entity?.ttl_unit}
              getOptionLabel={option => option?.label || ''}
              onChange={(e: any, value: any) => onChange('ttl_unit', value)}
              getOptionSelected={(option, value) => value?.value === option?.value}
              renderInput={(props) => (
                <TextField
                  name='role'
                  type='text'
                  variant='outlined'
                  disabled={disabled}
                  style={{marginTop: 30}}
                  error={!!errors?.ttl_unit}
                  helperText={errors?.ttl_unit}
                  {...props}
                />
              )}
            />
          </Box>
        </Typography>
      </CardContent>
      <div/>
    </Card>
  )
}
