import React from 'react';
import {TextField} from '../../Input';
import {useSelector} from 'react-redux';
import MuiTab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import {useTranslation} from 'react-i18next';
import {TabProps} from '@material-ui/core/Tab';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import {Theme, darken} from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from '@material-ui/core/styles/withStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {CircularProgress} from '../../StrictCircularProgress';
import createStyles from '@material-ui/core/styles/createStyles';
import {_accessToken} from '../../../store/selectors/application';
import {AutocompleteRenderInputParams} from '@material-ui/lab/Autocomplete';

const useAddNewStyles = makeStyles((theme: Theme) => ({
  root                    : {
    width                         : 200,
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      width     : 'unset',
      marginLeft: theme.spacing(1),
    },
  },
  divider                 : {
    marginTop   : 8,
    marginBottom: 8,
  },
  tabRoot                 : {
    marginLeft: 3,
    width     : 35,
    height    : 35,
  },
  searchTextField         : {
    paddingLeft : 5,
    paddingRight: 5,
  },
  labelRoot               : {
    flexGrow      : 1,
    display       : 'flex',
    overflow      : 'hidden',
    whiteSpace    : 'nowrap',
    textOverflow  : 'ellipsis',
    justifyContent: 'space-between',
  },
  renderedOption          : {
    flexGrow      : 1,
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'space-between',
  },
  btnSelectAppLabel       : {
    flexGrow  : 1,
    fontSize  : 12,
    display   : 'flex',
    alignItems: 'center',
  },
  popper                  : {
    borderRadius   : 0,
    fontSize       : 13,
    zIndex         : 1500,
    color          : '#586069',
    backgroundColor: '#ffffff',
    width          : '100% !important',
  },
  doneIcon                : {
    color: '#3ACC83',
  },
  parentPopper            : {
    borderRadius   : 0,
    fontSize       : 13,
    zIndex         : 1500,
    color          : '#586069',
    backgroundColor: '#ffffff',
    width          : '200px !important',
    border         : '1px solid rgb(233, 230, 235)',
  },
  paper                   : {
    margin      : 0,
    borderRadius: 0,
    fontSize    : 13,
    boxShadow   : 'none',
    color       : '#586069',
  },
  option                  : {
    minHeight                : 'auto',
    alignItems               : 'flex-start',
    padding                  : '9px 8px 8px 15px',
    borderLeft               : '3px solid transparent',
    borderRight              : '3px solid transparent',
    '&[aria-selected="true"]': {
      backgroundColor: darken('#F6F6F6', .1),
      color          : darken('#586069', .1),
      borderLeft     : '3px solid #096dd9',
    },
    '&[data-focus="true"]'   : {
      backgroundColor: '#F6F6F6',
    }
  },
  btnSelectAppLabelAppName: {
    flex      : 1,
    fontSize  : 12,
    textAlign : 'left',
    fontWeight: 'bold',
  },
  transparent             : {
    color: 'transparent',
  },
  popperDisablePortal     : {
    borderRadius: 0,
    position    : 'relative',
  },
  btnCreateApp            : {
    marginLeft   : 5,
    marginRight  : 5,
    marginTop    : 12,
    marginBottom : 12,
    textTransform: 'unset',
  },
  linkCreateApp           : {
    textDecoration: 'none',
    display       : 'table',
    color         : '#ffffff !important',
  },
  btnSelectApp            : {
    textTransform : 'unset',
    justifyContent: 'space-between',
  },
  endIcon                 : {
    paddingLeft: 8,
    borderLeft : '1px solid',
  },
}));

const Tab: any = withStyles<any, any, TabProps>((theme: Theme) => createStyles({
  root    : {
    marginRight     : 2,
    marginBottom    : 0,
    borderRadius    : '50%',
    minWidth        : 'unset',
    minHeight       : 'unset',
    textTransform   : 'unset',
    borderBlockColor: '#ffffff',
    backgroundColor : '#fafafa',
    border          : '1px solid #f0f0f0',
    transition      : 'all .3s cubic-bezier(.645,.045,.355,1)',
    '&:hover'       : {
      opacity        : 1,
      color          : '#ffffff',
      backgroundColor: '#a9adb0',
      border         : '1px solid #a9adb0',
    },
  },
  selected: {
    backgroundColor  : '#ffffff',
    borderBottomColor: '#ffffff',
  },
}))((props: TabProps) => <MuiTab disableRipple {...props} />);

export default function AddNew(
  {
    url,
    iso,
    values,
    onChange,
    disabled,
  }: {
    url?: string;
    values?: any;
    iso?: boolean;
    onChange?: any;
    disabled?: boolean;
  }
) {
  const {t} = useTranslation();
  const classes = useAddNewStyles();

  const [loading, setLoading] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [autocompleteOptions, setAutocompleteOptions] = React.useState<any[]>([]);

  const accessToken = useSelector(_accessToken);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
    setAutocompleteOptions([]);
  }, []);

  const handleOnChange = React.useCallback((event: any, newValue: any, reason: string) => {
    if (reason !== 'remove-option') {
      onChange(newValue)
    }
  }, [
    onChange,
  ]);

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    const currentTarget = event.currentTarget;
    setAnchorEl(prevState => {
      if (prevState) {
        return null;
      } else {
        if (!autocompleteOptions.length) {
          setLoading(true);
          getCountriesConstants(url, accessToken, iso)
            .then(({body: {data}}) => {
              setLoading(false);
              setAutocompleteOptions(data?.options || [])
            })
        }
        return currentTarget;
      }
    });
  }, [
    url,
    iso,
    accessToken,
    autocompleteOptions.length,
  ]);

  return (
    <div
      className={classes.root}
    >
      <Tab
        label={false}
        icon={<AddIcon/>}
        disabled={disabled}
        onClick={handleClick}
        style={{marginBottom: -1}}
        classes={{root: classes.tabRoot}}
        data-id='add-new-lang-country-button'
      />
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical  : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical  : 'top',
          horizontal: 'center',
        }}
      >
        <div>
          {loading ? (
            <Typography
              align='center'
              component='div'
              style={{paddingTop: 9, paddingBottom: 8}}
            ><CustomProgress/>
            </Typography>
          ) : (
            <Autocomplete
              open
              multiple
              disablePortal
              value={values}
              disableCloseOnSelect
              filterSelectedOptions
              onChange={handleOnChange}
              options={autocompleteOptions}
              classes={{
                paper              : classes.paper,
                popper             : classes.popper,
                option             : classes.option,
                popperDisablePortal: classes.popperDisablePortal
              }}
              ListboxProps={{style: {padding: 0}}}
              noOptionsText={t('messages.not_found')}
              renderInput={params => <RenderInput {...params}/>}
              getOptionLabel={(option: any) => option?.label || ''}
              getOptionSelected={(option: any, value: any) => (option && option.value) === (value && value.value)}
            />
          )}
        </div>
      </Popover>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  divider        : {
    marginTop   : 8,
    marginBottom: 8,
  },
  searchTextField: {
    padding: theme.spacing(1),
  },
}));

const RenderInput: React.NamedExoticComponent<AutocompleteRenderInputParams> = React.memo((params) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <>
      <TextField
        autoFocus
        fullWidth
        disableMargin
        placeholder={t('search')}
        inputRef={params.InputProps.ref}
        className={classes.searchTextField}
        InputProps={{endAdornment: <SearchIcon style={{transform: 'rotate(90deg)'}}/>}}
        inputProps={{...params.inputProps, value: undefined, 'data-id': 'search-lang-country-field'}}
      />
      <Divider
        className={classes.divider}
      />
    </>
  )
});

const useCustomProgressStyles = makeStyles((theme: Theme) => ({
  top   : {
    color: '#eef3fd',
  },
  root  : {
    position: 'relative',
    display : 'inline-flex',
  },
  bottom: {
    left             : 0,
    animationDuration: '550ms',
    position         : 'absolute',
    color            : theme.palette.primary.main,
  },
}));

function CustomProgress(props: any) {
  const classes = useCustomProgressStyles();

  return (
    <div
      className={classes.root}
    >
      <CircularProgress
        size={24}
        value={100}
        thickness={4}
        variant='determinate'
        className={classes.top}
        {...props}
      />
      <CircularProgress
        size={24}
        thickness={4}
        disableShrink
        variant='indeterminate'
        className={classes.bottom}
        {...props}
      />
    </div>
  );
}

const getCountriesConstants = async (
  url: string,
  accessToken: string | null,
  iso?: boolean,
): Promise<{
  hasError: boolean;
  body: {
    data: any;
    status: number;
    message?: string;
  };
}> => {
  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      const {
        message,
      } = await response.json();
      return {
        hasError: true,
        body    : {
          message,
          data  : {
            options   : [],
            additional: {},
            hasMore   : false,
          },
          status: response.status,
        },
      }
    } else {
      const {
        data,
      } = await response.json();
      return {
        hasError: false,
        body    : {
          data  : {
            additional: {},
            hasMore   : data.has_more,
            options   : data.items.map((item: any) => ({
              label: item.text,
              value: !!iso ? item.iso : item.id,
            })),
          },
          status: response.status || 200,
        },
      }
    }
  } catch (e) {
    return {
      hasError: true,
      body    : {
        status : e.status,
        message: e.message,
        data   : {
          options   : [],
          additional: {},
          hasMore   : false,
        },
      },
    }
  }
};
