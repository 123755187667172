import React from 'react';
import {Input} from 'antd';
import {Space} from 'antd';
import {Button} from 'antd';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import {aclKeys} from '../../../../../../../constants/aclKeys';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {ModalTypes} from '../../../../../../../constants/modalTypes';
import {getPrefixCls} from '../../../../../../../utils/getPrefixCls';
import {useSmartPush} from '../../../../../../../hooks/useSmartPush';
import {_fetch} from '../../../../../../../store/actions/apiKeys/fetch';
import {_dataOptions} from '../../../../../../../store/actions/apiKeys';
import {FiniteStates} from '../../../../../../../constants/finiteStates';
import {_searchText} from '../../../../../../../store/selectors/apiKeys';
import {_fetchingApiKeysState} from '../../../../../../../store/selectors/apiKeys';
import './index.less';

const {Search} = Input;

export function TableHeader() {
  const {
    user: {
      canCreate,
    }
  } = useSmartPush();
  const dispatch = useDispatch();
  const searchText = useSelector(_searchText);
  const apiKeysState = useSelector(_fetchingApiKeysState);

  const onReload = React.useCallback(() => {
    dispatch(_fetch());
  }, [
    dispatch,
  ]);

  const onCreate = React.useCallback(() => {
    dispatch(_dataOptions({modal: ModalTypes.CREATE}));
  }, [
    dispatch,
  ]);

  const onSearch = React.useCallback((value) => {
    dispatch(_dataOptions({
      searchText: value,
    }));
  }, [
    dispatch,
  ]);

  const onChange = React.useCallback((event) => {
    if (!event.target.value) {
      dispatch(_dataOptions({
        searchText: void 0,
      }));
    }
  }, [
    dispatch,
  ]);

  return (
    <div
      className={`d-flex align-items-center justify-content-between ${getPrefixCls('table-header')}`}
    >
      <Search
        onChange={onChange}
        onSearch={onSearch}
        placeholder='Search...'
        defaultValue={searchText}
        className={getPrefixCls('table-header-search')}
        disabled={apiKeysState === FiniteStates.LOADING}
      />
      <Space>
        {canCreate(aclKeys.API_KEYS) && (
          <Button
            type='primary'
            onClick={onCreate}
            icon={<PlusOutlined/>}
          >Create API Key
          </Button>
        )}
        <Button
          onClick={onReload}
          icon={<ReloadOutlined/>}
          disabled={apiKeysState === FiniteStates.LOADING}
        />
      </Space>
    </div>
  )
}
