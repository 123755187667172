import {_toggleEntity} from '../apps';
import {filterObject} from '../../../utils';
import {_currentApp} from '../../selectors/apps';
import {mutateCampaignData} from '../../../utils';
import {urlHelper} from '../../../utils/urlHelper';
import {getEntity} from '../../selectors/campaigns';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {ActionTypes} from '../../constants/appSettings';
import {_apiBaseUrl} from '../../selectors/application';
import {_hasLocalesAccess} from '../../selectors/application/acl';
import {toggleEntityAction as toggleCampaignAction} from '../campaigns';
import {notification} from 'antd';

export const setLoadingAction = (loading: boolean) => ({
  payload: loading,
  type   : ActionTypes.WEB_NOTIFY_SETTINGS_LOADING,
});

export const toggleErrorsAction = (errors?: { [key: string]: any }) => ({
  payload: errors,
  type   : ActionTypes.WEB_NOTIFY_SETTINGS_TOGGLE_ERRORS,
});

export const removeErrorAction = (inputName: string) => ({
  payload: inputName,
  type   : ActionTypes.WEB_NOTIFY_SETTINGS_REMOVE_ERROR,
});

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // ///

export const createOrUpdateWMessageAction = (appUuid: string, welcomeNfUuid?: string) => (
  async (dispatch: any, getState: any, {
    post,
  }: any) => {
    dispatch(setLoadingAction(true));
    dispatch(toggleErrorsAction(void 0));

    const campaign = getEntity(getState());
    const apiBaseUrl = _apiBaseUrl(getState());
    const currentApp = _currentApp(getState());

    let url = `${apiBaseUrl}${urlHelper(ApiRoutes.CREATE_WELCOME_MESSAGE, {appUuid})}`;

    if (welcomeNfUuid) {
      url = `${apiBaseUrl}${urlHelper(ApiRoutes.UPDATE_WELCOME_MESSAGE, {
        appUuid,
        uuid: welcomeNfUuid,
      })}`;
    }

    let wMessageData = {
      ...campaign,
      messages       : {
        ...campaign?.messages,
        default: {
          ...campaign?.messages?.default,
          url     : campaign?.messages?.default?.url || currentApp.default_url,
          icon_url: campaign?.messages?.default?.icon_url || currentApp.default_icon_url,
        }
      },
      ttl_unit       : campaign?.ttl_unit?.value,
      wait_until_unit: campaign?.wait_until_unit?.value,
      action         : Object.values(campaign?.action || {}).map((item: any) => {
        const {
          identity,
          ...rest
        } = item;
        return {
          ...rest
        };
      })
    }

    if (!_hasLocalesAccess(getState())) {
      wMessageData = {
        ...campaign,
        messages       : {
          default: {
            ...campaign?.messages?.default,
            url     : campaign?.messages?.default?.url || currentApp.default_url,
            icon_url: campaign?.messages?.default?.icon_url || currentApp.default_icon_url,
          }
        },
        ttl_unit       : campaign?.ttl_unit?.value,
        wait_until_unit: campaign?.wait_until_unit?.value,
        action         : Object.values(campaign?.action || {}).map((item: any) => {
          const {
            identity,
            ...rest
          } = item;
          return {
            ...rest
          };
        })
      }
    }

    wMessageData = {
      ...wMessageData,
      messages: Object.keys(wMessageData?.messages || {}).reduce((acc: any, curr: any) => {
        const {
          url,
          ...restMessage
        } = filterObject(wMessageData?.messages[curr]);

        acc[curr] = {
          ...restMessage,
          url: url || '',
        }
        return acc;
      }, {}),
    }

    const {
      hasError,
      body: {
        data,
        message,
      },
    } = await post(url, wMessageData);

    if (hasError) {
      dispatch(toggleErrorsAction(data.errors));
      notification.error({message});
    } else {
      notification.success({message});

      dispatch(_toggleEntity({
        ...currentApp,
        welcome_nf_uuid: data.uuid,
      }));

      const newData: any = mutateCampaignData(data, {});
      dispatch(toggleCampaignAction({
        ...newData,
        data  : {
          ...newData.data,
          action: Object.values(newData.data?.action || {}).map((a: any, index: number) => ({
            ...a,
            identity: index
          })),
        },
        action: Object.values(newData.action || {}).map((a: any, index: number) => ({
          ...a,
          identity: index
        })),
      }));
    }

    dispatch(setLoadingAction(false));
  }
);

export const toggleWelcomeMessageAction = (appUuid: string, welcome_nf_uuid: string) => (
  async (dispatch: any, getState: any, {
    post,
  }: any) => {
    dispatch(setLoadingAction(true));

    const apiBaseUrl = _apiBaseUrl(getState());

    let url = `${apiBaseUrl}${urlHelper(ApiRoutes.TOGGLE_WELCOME_MESSAGE, {
      appUuid,
      uuid: welcome_nf_uuid
    })}`;

    const {
      hasError,
      body: {
        data,
        message,
      },
    } = await post(url);

    if (hasError) {
      notification.error({message});
    } else {
      dispatch(toggleCampaignAction(mutateCampaignData(data, {})));
    }

    dispatch(setLoadingAction(false));
  }
);
