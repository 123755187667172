import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {urlHelper} from '../../utils/urlHelper';
import {surveyState} from '../../constants/survey';
import {AppRoutes} from '../../constants/appRoutes';
import {surveyIsEnabled} from '../../utils/surveyIsEnabled';
import {_surveyData} from '../../store/selectors/application/acl';

export function SurveyProvider(
  {
    appUuid,
    children,
    pathname,
    matchPath,
  }: {
    appUuid?: string;
    pathname: string;
    matchPath: string;
    children: React.ReactNode;
  }
) {
  const surveyData = useSelector(_surveyData);

  if (!surveyIsEnabled()) {
    return <>{children}</>
  }

  if ((surveyData?.state === surveyState.STARTED) && !([
    AppRoutes.SURVEY,
    AppRoutes.SURVEY_APP,
  ].includes(matchPath))) {
    return (
      <Redirect
        to={{
          state   : {from: pathname},
          pathname: appUuid ? urlHelper(AppRoutes.SURVEY_APP, {appUuid}) : AppRoutes.SURVEY,
        }}
      />
    )
  } else {
    return <>{children}</>
  }
}
