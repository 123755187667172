import React from 'react';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import {Theme} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  pageSizeSelector           : {
    ...theme.typography.caption,
    display     : 'flex',
    alignItems  : 'center',
    paddingRight: theme.spacing(5),
    fontSize    : 12,
  },
  label                      : {
    paddingRight: theme.spacing(3)
  },
  pageSizeTitle              : {
    width      : 'auto',
    marginRight: theme.spacing(.5)
  },
  inputRoot                  : {
    textAlign: 'right',
    fontSize : theme.spacing(1.75),
  },
  selectIcon                 : {
    top: 2,
  },
  '@media (max-width: 768px)': {
    label           : {
      display: 'none'
    },
    pageSizeSelector: {
      paddingRight: theme.spacing(2),
    }
  }
}));

export const PageSizeSelector = (
  {
    pageSize,
    pageSizes,
    getMessage,
    onPageSizeChange,
  }: any
) => {
  const classes = useStyles();
  const showAll = getMessage('showAll');

  return (
    <div
      className={classes.pageSizeSelector}
    >
      <span
        className={classes.label}
      >{getMessage('rowsPerPage')}
      </span>
      <Select
        value={pageSize}
        data-id='page-size-selector-select'
        classes={{icon: classes.selectIcon}}
        onChange={event => onPageSizeChange(event.target.value)}
        input={
          <Input
            disableUnderline
            classes={{root: classes.inputRoot}}
          />
        }
      >
        {pageSizes.map((item: any, index: number) => (
          <MenuItem
            selected
            key={item}
            value={item}
            data-id={`page-sizes-${index + 1}`}
          >{item !== 0 ? item : showAll}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
