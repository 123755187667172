import React from 'react';
import {Card} from 'antd';
import {Alert} from 'antd';
import {Space} from 'antd';
import {Modal} from 'antd';
import {Radio} from 'antd';
import {Button} from 'antd';
import {Divider} from 'antd';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useWatch} from 'react-hook-form';
import {useFormState} from 'react-hook-form';
import {CheckOutlined} from '@ant-design/icons';
import {useStripe} from '@stripe/react-stripe-js';
import {useElements} from '@stripe/react-stripe-js';
import {CardElement} from '@stripe/react-stripe-js';
import {Control} from 'react-hook-form/dist/types/form';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {urlHelper} from '../../../../../../../../../utils/urlHelper';
import jcb from '../../../../../../../../../images/cardBrands/jcb.svg';
import amex from '../../../../../../../../../images/cardBrands/amex.png';
import visa from '../../../../../../../../../images/cardBrands/visa.png';
import {AppRoutes} from '../../../../../../../../../constants/appRoutes';
import {Text} from '../../../../../../../../../components/antd/Typography';
import {getPrefixCls} from '../../../../../../../../../utils/getPrefixCls';
import {Title} from '../../../../../../../../../components/antd/Typography';
import {_paidPlans} from '../../../../../../../../../store/selectors/portal';
import unionpay from '../../../../../../../../../images/cardBrands/unionpay.png';
import discover from '../../../../../../../../../images/cardBrands/discover.svg';
import {_paymentMethod} from '../../../../../../../../../store/selectors/portal';
import {_prorationTotal} from '../../../../../../../../../store/selectors/portal';
import defaultCard from '../../../../../../../../../images/cardBrands/default.png';
import mastercard from '../../../../../../../../../images/cardBrands/mastercard.png';
import {_existingCardErrors} from '../../../../../../../../../store/selectors/portal';
import dinersClub from '../../../../../../../../../images/cardBrands/diners_club.svg';
import {PaymentDetails} from '../../../../../../../../../components/antd/PaymentDetails';
import cartesBancaires from '../../../../../../../../../images/cardBrands/cartes_bancaires.png';
import {_payAndLaunch} from '../../../../../../../../../store/actions/subscriptionsAndBilling/payAndLaunch';
import {_upsertSubscriptionsAndBilling} from '../../../../../../../../../store/actions/subscriptionsAndBilling';

export function Form() {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const paidPlans = useSelector(_paidPlans);
  const paymentMethod = useSelector(_paymentMethod);
  const prorationTotal = useSelector(_prorationTotal);
  const {appUuid, plan} = useParams<{ appUuid: string; plan: string }>()

  const selectedPlan = paidPlans.find((paidPlan: any) => {
    return paidPlan.plan_slug === plan
  });

  const {
    control,
    setError,
    setValue,
    register,
    handleSubmit,
  } = useForm<any>({
    defaultValues: {
      country       : 'us',
      plan_slug     : plan,
      interval      : 'month',
      payment_method: paymentMethod?.id,
      card          : !!paymentMethod?.card ? 'existing_card' : 'new_card',
    }
  });

  function onSubmit(formValues: any): any {
    if (formValues.card !== 'existing_card') {
      if (!stripe || !elements) {
        setError('cardField', {
          message: 'Failed to load stripe please reload the page.'
        });

        return void 0;
      }

      elements.getElement('card').focus();
    }

    return dispatch(_payAndLaunch({
      stripe,
      appUuid,
      formValues,
      setError: setError as any,
      card    : elements.getElement(CardElement),
      redirect: () => {
        if (appUuid) {
          window.location.replace(urlHelper(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP, {appUuid}))
        } else {
          window.location.replace(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS)
        }
      },
    }));
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='d-flex align-items-center justify-content-center'
    >
      <input
        type='hidden'
        {...register('card')}
      />
      <input
        type='hidden'
        {...register('interval')}
      />
      <input
        type='hidden'
        {...register('plan_slug')}
      />
      <input
        type='hidden'
        {...register('payment_method')}
      />
      <div
        className={getPrefixCls('pay-and-activate-card-container')}
      >
        <div
          className={getPrefixCls('pay-and-activate-new-plan-wrapper')}
        >
          <Card
            bordered={false}
            title='New Plan'
            className='m-b-2'
          >
            <div
              className='m-b-2'
            >
              <Title
                level={5}
                fontWeight='bold'
                className='d-flex justify-content-between align-items-center'
              ><span>{selectedPlan.plan_name}</span><span>${prorationTotal.amount_due}</span>
              </Title>
            </div>
            <div
              className='m-b-2'
            >
              <Text
                type='secondary'
              >
                <span>${selectedPlan.price_per_1000} per 1,000</span>
                <br/>
                <span>additional delivered messages</span>
              </Text>
            </div>
            <div>
              {!selectedPlan.max_subscribers_count && (
                <Text>Unlimited Subscribers,</Text>
              )}
              <br/>
              {!selectedPlan?.plan_limits?.apps?.per_account_max_count && (
                <Text>Unlimited Websites,</Text>
              )}
              <br/>
              {!selectedPlan?.plan_limits?.campaigns?.per_app_max_active_count && (
                <Text>Unlimited Active Campaigns</Text>
              )}
            </div>
            <Divider
              style={{marginBottom: '1.6rem', marginTop: '1.6rem'}}
            />
            <Descriptions/>
            <div
              className='m-b-2'
            >
              <Title
                level={5}
                fontWeight='bold'
                className='d-flex justify-content-between align-items-center'
              ><span>Total</span><span>${prorationTotal.amount_due}</span>
              </Title>
            </div>
          </Card>
          <div>
            <div
              className='m-b-2'
            >
              <Text
                fontSize='small'
                type='secondary'
              >* When changing your plan, we will charge the prorated difference between the new plan and your current
                plan.
              </Text>
            </div>
            <Text
              type='secondary'
              fontSize='small'
            >Your credit card issuer may charge foreign transaction or cross-border fees in addition to the total price.
            </Text>
          </div>
        </div>
        <div
          className={getPrefixCls('pay-and-activate-payment-details-wrapper')}
        >
          {!!paymentMethod?.card ? (
            <ExistingCard
              control={control}
              setValue={setValue}
            />
          ) : (
            <PaymentDetails
              control={control}
              setValue={setValue}
            />
          )}
          <div
            className='d-flex justify-content-end'
          >
            <Space>
              <Link
                to={!!appUuid ? urlHelper(AppRoutes.CHANGE_PLAN_APP, {appUuid}) : AppRoutes.CHANGE_PLAN}
              >
                <Button
                  type='default'
                >Go Back
                </Button>
              </Link>
              <SubmitButton
                control={control}
              />
            </Space>
          </div>
        </div>
      </div>
    </form>
  )
}

function Descriptions() {
  const prorationTotal = useSelector(_prorationTotal);
  if (!prorationTotal?.descriptions?.length) {
    return null;
  }

  return (
    <>
      <div
        className='m-b-2'
      >
        <Title
          level={5}
          fontWeight='bold'
        >Summary
        </Title>
      </div>
      {prorationTotal?.descriptions?.map((
        {
          amount,
          description,
        }: { description: string; amount: number }, index: number) => {
        return (
          <Text
            key={index}
            className='d-flex justify-content-between m-b-2'
          >
            <Text className='m-r-2'>{description}</Text>
            <Text strong>{(amount < 0) ? '-$' : '$'}{Math.abs(amount)}</Text>
          </Text>
        )
      })}
      <Divider
        style={{marginBottom: '1.6rem', marginTop: '1.6rem'}}
      />
    </>
  )
}

function Brands({cardBrand}: { cardBrand: string }) {
  switch (cardBrand) {
    case 'jcb': {
      return <img height={30} src={jcb} alt='jcb'/>;
    }
    case 'amex': {
      return <img height={20} src={amex} alt='amex'/>;
    }
    case 'visa': {
      return <img height={20} src={visa} alt='visa'/>;
    }
    case 'discover': {
      return <img height={20} src={discover} alt='discover'/>;
    }
    case 'unionpay': {
      return <img height={20} src={unionpay} alt='unionpay'/>;
    }
    case 'mastercard': {
      return <img height={20} src={mastercard} alt='mastercard'/>;
    }
    case 'diners_club': {
      return <img height={20} src={dinersClub} alt='dinersClub'/>;
    }
    case 'cartes_bancaires': {
      return <img height={20} src={cartesBancaires} alt='cartesBancaires'/>;
    }
    default: {
      return <img height={20} src={defaultCard} alt='defaultCard'/>;
    }
  }
}

function SubmitButton({control, text}: { control: Control<any>; text?: string }) {
  const {
    isSubmitting,
  } = useFormState({
    control,
  });

  const [
    city,
    card,
    name,
    state,
    line1,
    cardField,
    postal_code,
  ] = useWatch({
    control,
    name: [
      'city',
      'card',
      'name',
      'state',
      'line1',
      'cardField',
      'postal_code',
    ]
  });

  let disabled = !(
    name?.trim() &&
    city?.trim() &&
    state?.trim() &&
    line1?.trim() &&
    postal_code?.trim() &&
    cardField?.complete
  );
  if (card === 'existing_card') {
    disabled = false;
  }

  return (
    <Button
      type='primary'
      htmlType='submit'
      disabled={disabled}
      loading={isSubmitting}
      icon={<CheckOutlined/>}
    >{!text ? 'Pay Securely' : text}
    </Button>
  )
}

function ExistingCard({setValue, control}: { setValue: UseFormSetValue<any>; control: Control<any> }) {
  const {
    card,
    billing_address,
  } = useSelector(_paymentMethod);
  const dispatch = useDispatch();
  const existingCardErrors = useSelector(_existingCardErrors);
  const [visible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    setValue('card', visible ? 'new_card' : 'existing_card')
  }, [
    visible,
    setValue,
  ]);

  React.useEffect(() => {
    return function () {
      dispatch(_upsertSubscriptionsAndBilling({existingCardErrors: void 0}))
    }
  }, [dispatch]);

  return (
    <Card
      bordered={false}
      title='Payment Details'
      className='enter-payment-details m-b-2'
    >
      {!!existingCardErrors && (
        <Alert
          closable
          type='error'
          className='m-b-2'
          message={existingCardErrors?.message}
          description={existingCardErrors?.errors?.map((error: string) => <Text>{error}</Text>)}
          afterClose={() => dispatch(_upsertSubscriptionsAndBilling({existingCardErrors: void 0}))}
        />
      )}
      <div id='customContainer'/>
      <Radio.Group style={{width: '100%'}} value={1}>
        <Radio
          value={1}
          className={getPrefixCls('pay-and-activate-existing-card-radio')}
        >
          <div
            className='d-flex align-items-center m-b-2'
          >
            <Brands
              cardBrand={card.brand}
            />
            <Text className='m-l-2'>****{card.last4}</Text>
          </div>
          <Text ellipsis={{tooltip:true}}>{billing_address.name} {card.exp_month}/{String(card.exp_year).slice(2)}</Text>
        </Radio>
      </Radio.Group>
      <div
        onClick={() => setVisible(true)}
        className={getPrefixCls('pay-and-activate-existing-card-change-card')}
      >
        <PlusOutlined/>
        <Text
          type='secondary'
        >Add New Card
        </Text>
      </div>
      {visible && (
        <Modal
          visible={true}
          title='Add New Card'
          maskClosable={false}
          getContainer='#customContainer'
          onCancel={() => setVisible(false)}
          footer={[
            <Button
              key='cancel'
              onClick={() => setVisible(false)}
            >Cancel
            </Button>,
            <SubmitButton
              control={control}
              key='saveAndPaySecurely'
              text='Save & Pay Securely'
            />
          ]}
        >
          <PaymentDetails
            control={control}
            cardProps={{
              title    : null,
              bodyStyle: {padding: 0}
            }}
            setValue={setValue}
          />
        </Modal>
      )}
    </Card>
  )
}
