import {Draft} from 'immer';
import * as React from 'react';
import {useDispatch} from 'react-redux';
import {Text} from '../../../../Typography';
import {Title} from '../../../../Typography';
import {Surveys} from '../../../../../../types/surveys';
import {reasons} from '../../../../../../constants/survey';
import {competitors} from '../../../../../../constants/survey';
import {SurveyAutocomplete} from '../../../SurveyAutocomplete';
import {tryingWebPush} from '../../../../../../constants/survey';
import {getPrefixCls} from '../../../../../../utils/getPrefixCls';
import {amNotUsingAnyService} from '../../../../../../constants/survey';
import {_storeSurvey} from '../../../../../../store/actions/application/storeSurvey';

let reasonOldValue: string;
let competitorOldValue: string;
let howDidYouFindUsOldValue: string;

export function Line2(
  {
    data,
    setData,
  }: {
    data: Surveys.Data;
    setData: (f: (draft: Draft<Surveys.Data>) => void | Surveys.Data) => void;
  }
) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (data.reason) {
      reasonOldValue = data.reason;
    }
    if (data.competitor) {
      competitorOldValue = data.competitor;
    }
    // eslint-disable-next-line
  }, []);

  function reasonOnBlur() {
    if (reasonOldValue?.trim()?.toLowerCase() !== data.reason?.trim()?.toLowerCase()) {
      reasonOldValue = data.reason;

      dispatch(_storeSurvey({
        key  : 'reason',
        value: data.reason?.trim()
      }));
    }

    setData(draft => {
      draft.reasonIsOpen = false;

      if (data.reason?.toLowerCase()?.trim() === tryingWebPush) {
        draft.reasonSelected = false;
      } else {
        draft.reasonSelected = !!data.reason;
      }
    })
  }

  function reasonOnClick() {
    setData(draft => {
      draft.reasons = reasons;
      draft.reasonIsOpen = true;
    })
  }

  function reasonOnFocus() {
    setData(draft => {
      draft.reasonIsOpen = true;

      if (reasons.length !== draft.reasons?.length) {
        draft.reasons = reasons;
      }
    })
  }

  function reasonOnSelect() {
    setData(draft => {
      draft.reasonIsOpen = false;

      draft.reasonSelected = draft.reason?.toLowerCase()?.trim() !== tryingWebPush;
    })
  }

  function reasonOnMouseDown() {
    setData(draft => {
      draft.reasons = reasons;
      draft.reasonIsOpen = true;
    })
  }

  function reasonOnPressEnter(e: any) {
    const value = e.currentTarget.value;

    setData(draft => {
      if (value?.toLowerCase()?.trim() === tryingWebPush) {
        draft.reasonSelected = false;
      } else {
        draft.reasonSelected = !!value?.trim();
      }
    })

    if (!!value?.trim()) {
      e.currentTarget.blur();
    }
  }

  function reasonOnChange(value: string) {
    setData(draft => {
      draft.reason = value;

      if (draft.reasons.length !== reasons.length) {
        draft.reasons = reasons;
      }

      if (
        !value?.trim() ||
        (value?.toLowerCase()?.trim() === tryingWebPush)
      ) {
        draft.reasonSelected = false;

        draft.frequency = void 0;
        draft.competitor = void 0;
        draft.subscriber = void 0;

        draft.frequencySelected = false;
        draft.subscriberSelected = false;
        draft.competitorSelected = false;
      }
    })
  }

  function reasonOnSearch(value: string) {
    setData(draft => {
      draft.reasons = reasons.filter((p) => p.value.toUpperCase().trim().indexOf(value.toUpperCase()) !== -1)
    })
  }

  ////////////////////////////////////////////

  function competitorOnBlur() {
    if (competitorOldValue?.trim()?.toLowerCase() !== data.competitor?.trim()?.toLowerCase()) {
      competitorOldValue = data.competitor;

      dispatch(_storeSurvey({
        key  : 'competitor',
        value: data.competitor?.trim(),
      }));
    }

    setData(draft => {
      draft.competitorIsOpen = false;

      if (data.competitor?.toLowerCase()?.trim() === amNotUsingAnyService) {
        draft.competitorSelected = false;
      } else {
        draft.competitorSelected = !!draft.competitor;
      }
    })
  }

  function competitorOnFocus() {
    setData(draft => {
      draft.competitorIsOpen = true;

      if (competitors.length !== draft.competitors?.length) {
        draft.competitors = competitors;
      }
    })
  }

  function competitorOnClick() {
    setData(draft => {
      draft.competitors = competitors;
      draft.competitorIsOpen = true;
    })
  }

  function competitorOnSelect() {
    setData(draft => {
      draft.competitorIsOpen = false;

      draft.competitorSelected = draft.competitor?.toLowerCase()?.trim() !== amNotUsingAnyService;
    })
  }

  function competitorOnMouseDown() {
    setData(draft => {
      draft.competitors = competitors;
      draft.competitorIsOpen = true;
    })
  }

  function competitorOnPressEnter(e: any) {
    const value = e.currentTarget.value;

    setData(draft => {
      if (value?.toLowerCase()?.trim() === amNotUsingAnyService) {
        draft.competitorSelected = false;
      } else {
        draft.competitorSelected = !!value?.trim();
      }
    })

    if (!!value?.trim()) {
      e.currentTarget.blur();
    }
  }

  function competitorOnChange(value: string) {
    setData(draft => {
      draft.competitor = value;
      draft.competitorIsOpen = true;

      if (draft.competitors.length !== competitors.length) {
        draft.competitors = competitors;
      }

      if (
        !value?.trim() ||
        (value?.toLowerCase()?.trim() === amNotUsingAnyService)
      ) {
        draft.competitorSelected = false;

        draft.frequency = void 0;
        draft.subscriber = void 0;

        draft.frequencySelected = false;
        draft.subscriberSelected = false;

        if (!value?.trim()) {
          draft.howDidYouFindUs = void 0;
          draft.howDidYouFindUsSelected = false;
        }
      }
    })
  }

  function competitorOnSearch(value: string) {
    setData(draft => {
      draft.competitors = competitors.filter((p) => p.value.toUpperCase().trim().indexOf(value.toUpperCase()) !== -1)
    })
  }

  ////////////////////////////////////////////

  function howDidYouFindUsOnBlur() {
    if (howDidYouFindUsOldValue?.trim()?.toLowerCase() !== data.howDidYouFindUs?.trim()?.toLowerCase()) {
      howDidYouFindUsOldValue = data.howDidYouFindUs;

      dispatch(_storeSurvey({
        key  : 'howDidYouFindUs',
        value: data.howDidYouFindUs?.trim(),
      }));
    }

    setData(draft => {
      draft.howDidYouFindUsIsOpen = false;

      draft.howDidYouFindUsSelected = !!draft.howDidYouFindUs;
    })
  }

  function howDidYouFindUsOnFocus() {
    setData(draft => {
      draft.howDidYouFindUsIsOpen = true;
    })
  }

  function howDidYouFindUsOnClick() {
    // setData(draft => {
    //   draft.competitors = competitors;
    //   draft.competitorIsOpen = true;
    // })
  }

  function howDidYouFindUsOnSelect() {
    setData(draft => {
      draft.howDidYouFindUsIsOpen = false;

      draft.howDidYouFindUsSelected = true;
    })
  }

  function howDidYouFindUsOnMouseDown() {
    // setData(draft => {
    //   draft.competitors = competitors;
    //   draft.competitorIsOpen = true;
    // })
  }

  function howDidYouFindUsOnPressEnter(e: any) {
    const value = e.currentTarget.value;

    setData(draft => {
      draft.howDidYouFindUsSelected = !!value?.trim();
    })

    if (!!value?.trim()) {
      e.currentTarget.blur();
    }
  }

  function howDidYouFindUsOnChange(value: string) {
    setData(draft => {
      draft.howDidYouFindUs = value;
      draft.howDidYouFindUsIsOpen = true;
    })
  }

  const trying = tryingWebPush === data.reason?.toLowerCase()?.trim();
  const notUsing = amNotUsingAnyService === data.competitor?.toLowerCase()?.trim();

  return (
    <>
      <div
        className={`m-b-5 d-flex align-items-center ${getPrefixCls('survey-word-break')}`}
      >
        <div
          className='d-flex align-items-center m-b-md-5'
        >
          <Title
            level={3}
            className='m-r-1'
          >for
          </Title>
          <SurveyAutocomplete
            value={data.reason}
            onBlur={reasonOnBlur}
            options={data.reasons}
            onFocus={reasonOnFocus}
            onClick={reasonOnClick}
            onSelect={reasonOnSelect}
            onChange={reasonOnChange}
            onSearch={reasonOnSearch}
            open={!!data.reasonIsOpen}
            onMouseDown={reasonOnMouseDown}
            autoFocus={!data.reason?.trim()}
            onPressEnter={reasonOnPressEnter}
            dropdownStyle={{minWidth: 180, maxWidth: 180}}
          />
          {(data.reasonSelected || trying) && (
            <Text
              className={`${getPrefixCls('survey-wizardDot')} m-r-1`}
            >.
            </Text>
          )}
          {(data.reasonSelected && !trying) && (
            <Title
              level={3}
              className='m-r-1'
            >Currently
            </Title>
          )}
        </div>
        {(data.reasonSelected && !trying) && (
          <div
            className='d-flex align-items-center'
          >
            <Title
              level={3}
              className='m-r-1'
            >I <Text delete={notUsing}>use</Text>
            </Title>
            <SurveyAutocomplete
              value={data.competitor}
              onBlur={competitorOnBlur}
              options={data.competitors}
              onFocus={competitorOnFocus}
              onClick={competitorOnClick}
              onSelect={competitorOnSelect}
              onChange={competitorOnChange}
              onSearch={competitorOnSearch}
              open={!!data.competitorIsOpen}
              onMouseDown={competitorOnMouseDown}
              autoFocus={!data.competitor?.trim()}
              onPressEnter={competitorOnPressEnter}
              dropdownStyle={{minWidth: 180, maxWidth: 180}}
            />
            {(notUsing || data.competitorSelected) && (
              <Text
                className={`${getPrefixCls('survey-wizardDot')} m-r-1`}
              >,
              </Text>
            )}
          </div>
        )}
      </div>
      {(trying || notUsing || data.competitorSelected || (data.reasonSelected && data.howDidYouFindUsSelected)) && (
        <div
          className={`d-flex align-items-center ${getPrefixCls('survey-word-break')}`}
        >
          <div
            className='m-b-md-5'
          >
            <Title
              level={3}
              className='m-r-1'
            >I found Smartpush from
            </Title>
          </div>
          <div
            className='d-flex align-items-center m-b-md-5'
          >
            <SurveyAutocomplete
              value={data.howDidYouFindUs}
              onBlur={howDidYouFindUsOnBlur}
              onFocus={howDidYouFindUsOnFocus}
              onClick={howDidYouFindUsOnClick}
              onSelect={howDidYouFindUsOnSelect}
              onChange={howDidYouFindUsOnChange}
              open={!!data.howDidYouFindUsIsOpen}
              options={data.howDidYouFindUsOptions}
              onMouseDown={howDidYouFindUsOnMouseDown}
              autoFocus={!data.howDidYouFindUs?.trim()}
              onPressEnter={howDidYouFindUsOnPressEnter}
              dropdownStyle={{minWidth: 280, maxWidth: 280}}
            />
            {data.howDidYouFindUsSelected && (
              <Text
                className={`${getPrefixCls('survey-wizardDot')} m-r-1`}
              >.
              </Text>
            )}
          </div>
        </div>
      )}
    </>
  );
}
