import React from 'react';
import {useHistory, useParams} from 'react-router';
import {Breadcrumb} from 'antd';
import {urlHelper} from '../../../../utils/urlHelper';
import {AppRoutes} from '../../../../constants/appRoutes';
import {Link} from '../../../../components/antd/Typography';
import {Navigation} from '../../../../components/antd/Navigation';

interface IProps {
  title: string;
}

const Header: React.FC<IProps> = ({title}) => {
  const {appUuid} = useParams<{ appUuid: string }>();
  const history = useHistory();

  const redirectToAutomations = () => {
    history.push(urlHelper(AppRoutes.AUTOMATIONS, {appUuid}));
  };

  return (
    <Navigation
      title={title}
      breadcrumbItems={[
        <>
          <Breadcrumb.Item key='app.Automation' >
            <Link
              ellipsis
              type='secondary'
              onClick={redirectToAutomations}
            >Automations
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item key={title}>
            {title}
          </Breadcrumb.Item>
        </>
      ]}
    />
  );
};

export default Header;
