import {notification} from 'antd';
import {_upsertUser} from '../application';
import {UseFormReset} from 'react-hook-form';
import {Globals} from '../../../types/globals';
import {UseFormSetError} from 'react-hook-form';
import {_user} from '../../selectors/application/acl';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {ActionTypes as AppActionTypes} from '../../constants/application';

export const _changePassword = (formValues: Record<string, any>, {
  reset,
  setError,
}: {
  reset: UseFormReset<Record<string, any>>;
  setError: UseFormSetError<Record<string, any>>;
}): Globals.ThunkAction => async (dispatch, getState, {api}) => {
  const user = _user(getState());

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.post(ApiRoutes.UPDATE_PASSWORD, formValues);

  if (!hasError) {
    if (data?.access_token) {
      reset();
      localStorage.setItem('access_token', data.access_token);

      dispatch({
        payload: {
          authenticated: true,
          accessToken  : data.access_token,
        },
        type   : AppActionTypes.AUTHENTICATE,
      });
    }

    if (!user.show_password) {
      dispatch(_upsertUser({
        ...user,
        show_password: true,
      }))
    }

    notification.success({
      message: 'Your password has been updated and all other device sessions has been invalidated.',
    });
  } else {
    if (!Object.keys(data).length) {
      setError('current_password', {
        message,
      });
    } else {
      Object.keys(data).forEach((inputName: string) => {
        setError(inputName, {
          message: data[inputName],
        });
      });
    }
  }
};
