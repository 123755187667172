import {ActionTypes} from '../../../constants/messages';
import {Builder} from '../../../../types/builder';

export const _updateFilters = (data: {[key: string]: any}) => ({
  payload: data,
  type   : ActionTypes.BUILDER_MESSAGES_UPDATE_FILTERS,
});

export const _setData = (data: Builder.MessageEntity[]) => ({
  payload: data,
  type   : ActionTypes.BUILDER_MESSAGES_SET_DATA,
});

export const _deleteData = (data: number) => ({
  payload: data,
  type   : ActionTypes.BUILDER_MESSAGES_DELETE_DATA,
});

export const _setLoading = (data?: boolean) => ({
  payload: data,
  type   : ActionTypes.BUILDER_MESSAGES_SET_LOADING,
});

export const _cleanUp = () => ({
  type   : ActionTypes.BUILDER_MESSAGES_CLEANUP,
});
