import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {IObject} from '../../../types';
import CampaignScheduleCard from '../../CampaignScheduleCard';
import {updateEntityAction} from '../../../store/actions/campaigns';

export default React.memo((
  {
    errors,
    entity,
    removeError,
  }: {
    entity: IObject;
    errors?: IObject;
    removeError?: any;
  }
) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const campaignScheduleCardLabels = React.useMemo(() => ({
    ttl                               : t('campaigns.ttl'),
    delivery                          : t('campaigns.delivery'),
    deliver_at                        : t('campaigns.deliver_at'),
    schedule                          : t('campaigns.send_schedule'),
    ttl_helper_text                   : t('campaigns.ttl_helper_text'),
    wait_before_send                  : t('campaigns.wait_before_send'),
    send_immediately                  : t('campaigns.send_immediately'),
    per_user_particular               : t('campaigns.per_user_particular'),
    per_user_optimization             : t('campaigns.per_user_optimization'),
    in_their_local_timezone           : t('campaigns.in_their_local_timezone'),
    begin_sending_immediately         : t('campaigns.begin_sending_immediately'),
    begin_sending_at_a_particular_time: t('campaigns.begin_sending_at_a_particular_time'),
  }), [
    t,
  ]);

  const updateEntity = React.useCallback((inputName: string, value: any) => {
    dispatch(updateEntityAction({
      [inputName]: value,
    }));
    if (typeof removeError === 'function') {
      removeError(inputName)
    }
  }, [
    dispatch,
    removeError,
  ]);

  return (
    <CampaignScheduleCard
      errors={errors}
      entity={entity}
      onChange={updateEntity}
      labels={campaignScheduleCardLabels}
    />
  )
})
