import React from 'react';
import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import stripeJs from '@stripe/stripe-js';
import {momentUTC} from '../../../../../utils';
import {useStripe} from '@stripe/react-stripe-js';
import {CardElement} from '@stripe/react-stripe-js';
import {useElements} from '@stripe/react-stripe-js';
import {urlHelper} from '../../../../../utils/urlHelper';
import {surveyState} from '../../../../../constants/survey';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {surveyIsEnabled} from '../../../../../utils/surveyIsEnabled';
import {_user} from '../../../../../store/selectors/application/acl';
import {_skippingCheckout} from '../../../../../store/selectors/portal';
import {SHOW_SURVEY_AFTER_REGISTERED_AT} from '../../../../../constants';
import {_subscriptionPlans} from '../../../../../store/selectors/portal';
import {_surveyData} from '../../../../../store/selectors/application/acl';
import {_planTemplateName} from '../../../../../store/selectors/application/acl';
import {_storeSurvey} from '../../../../../store/actions/application/storeSurvey';
import {_fetchPortal} from '../../../../../store/actions/subscriptionsAndBilling/fetchPortal';
import {_payAndLaunch} from '../../../../../store/actions/subscriptionsAndBilling/payAndLaunch';
import {_skipCheckout} from '../../../../../store/actions/subscriptionsAndBilling/skipCheckout';
import {_fetchSubscriptionPlans} from '../../../../../store/actions/subscriptionsAndBilling/fetchSubscriptionPlans';

export interface IPayAndLaunchForm {
  name: string;
  state: string;
  city: string;
  line1: string;
  line2: string;
  country: string;
  interval: string;
  plan_slug: string;
  postal_code: string;
  free_plan_message: string;
  cardField: stripeJs.StripeCardElementChangeEvent;
}

export function usePayAndLaunch() {
  const stripe = useStripe();
  const history = useHistory();
  const dispatch = useDispatch();
  const elements = useElements();
  const user = useSelector(_user);
  const surveyData = useSelector(_surveyData);
  const {appUuid} = useParams<{ appUuid: string }>();
  const planTemplateName = useSelector(_planTemplateName);
  const skippingCheckout = useSelector(_skippingCheckout);
  const {
    setError,
    handleSubmit,
    ...restForm
  } = useForm<IPayAndLaunchForm>({
    defaultValues: {
      free_plan_message: '',
      country          : 'us',
      interval         : 'month',
      plan_slug        : planTemplateName || 'paid_500k',
    }
  });

  React.useEffect(() => {
    dispatch(_fetchPortal())
  }, [
    dispatch,
  ]);

  React.useEffect(() => {
    dispatch(_fetchSubscriptionPlans())
  }, [
    dispatch,
  ]);

  const subscriptionPlans = useSelector(_subscriptionPlans);

  const skipCheckout = React.useCallback(() => {
    const diff = momentUTC(SHOW_SURVEY_AFTER_REGISTERED_AT) < momentUTC(user.created_at);

    dispatch(_skipCheckout({
      appUuid,
      redirect: async () => {
        if (surveyIsEnabled() && !surveyData.state && diff) {
          await dispatch(_storeSurvey({
            key  : 'state',
            value: surveyState.STARTED,
          }));
        } else {
          history.replace(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid}))
        }
      },
    }));
  }, [
    appUuid,
    history,
    dispatch,
    user?.created_at,
    surveyData?.state,
  ]);

  const onSubmit = React.useCallback((formValues: IPayAndLaunchForm) => {
    if (!stripe || !elements) {
      setError('cardField', {
        message: 'Something went wrong',
      })
      return void 0;
    }

    elements.getElement('card').focus();
    const diff = momentUTC(SHOW_SURVEY_AFTER_REGISTERED_AT) < momentUTC(user.created_at);
    return dispatch(_payAndLaunch({
      stripe,
      appUuid,
      formValues,
      setError: setError as any,
      card    : elements.getElement(CardElement),
      redirect: async () => {
        if (surveyIsEnabled() && !surveyData.state && diff) {
          await dispatch(_storeSurvey({
            key  : 'state',
            value: surveyState.STARTED,
          }));
        } else {
          history.replace(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid}))
        }
      },
    }));
  }, [
    stripe,
    appUuid,
    history,
    setError,
    elements,
    dispatch,
    user?.created_at,
    surveyData?.state,
  ]);

  const goBack = React.useCallback(() => {
    history.replace(urlHelper(AppRoutes.APPS_INTEGRATE_APP, {appUuid}))
  }, [
    appUuid,
    history,
  ]);

  return {
    ...restForm,
    goBack,
    skipCheckout,
    skippingCheckout,
    subscriptionPlans,
    submit: handleSubmit(onSubmit),
  }
}
