import React from 'react';
import {useTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {orderedAclKeys} from '../../../../../../constants/aclKeys';

const useStyles = makeStyles(() => ({
  table: {
    borderCollapse: 'collapse',
  },
  tr   : {
    height      : 100,
    borderTop   : '1px solid rgba(0, 0, 0, 0.54)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.54)',
  }
}));

export default function Permissions(
  {
    permissions,
  }: {
    permissions: any;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <table
      cellPadding={12}
      className={classes.table}
    >
      <tbody>
      {Object.values(orderedAclKeys).map((aclKey, index) => {
        return (
          <tr
            key={index}
            className={classes.tr}
          >
            <td>
              <Typography
                noWrap
                color='primary'
              >{t(`acl.${aclKey}`)}
              </Typography>
            </td>
            <td>
              <Typography
                color='textSecondary'
              >
                {(() => {
                  let fg: any = permissions[aclKey]?.fg;
                  if (aclKey === 'webhooks') {
                    fg = permissions.apps?.frs?.webhooks;
                  }

                  const t_1: any[] = Object.keys(fg || {}).map((f: any) => {
                    return !!fg[f] && t(`acl.${f}`)
                  }).filter((f: any) => f);

                  return t_1.join(', ') || t('no_access');
                })()}
              </Typography>
            </td>
          </tr>
        )
      })}
      </tbody>
    </table>
  )
}