import React from 'react';
import {Templates} from '../../../../../../../types/templates';
import {urlHelper} from '../../../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../../../constants/appRoutes';
import {Link} from '../../../../../../../components/antd/Typography';

export function Uuid(value: string, record: Templates.EntityDetail) {
  return (
    <Link
      copyable
      ellipsis
      underline
      target='_blank'
      style={{maxWidth: '25rem'}}
      href={urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_INFO, {
        uuid   : value,
        appUuid: record.app_uuid,
      })}
    >{value}
    </Link>
  )
}