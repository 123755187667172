export const RecaptchaActions = {
  LOGIN               : 'login',
  RESEND              : 'resend',
  REGISTER            : 'register',
  PASSWORD_RESET      : 'password_reset',
  CONFIRM_REGISTER    : 'confirm_register',
  CHECK_APPSUMO_TOKEN : 'check_appsumo_token',
  CONFIRM_EMAIL_CHANGE: 'confirm_email_change',
  PASSWORD_RESET_TOKEN: 'password_reset_token',
}
