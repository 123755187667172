import React from 'react';
import {useSelector} from 'react-redux';
import {AppRoutes} from '../../constants/appRoutes';
import {IncompleteApp} from './components/IncompleteApp';
import {FiniteStates} from '../../constants/finiteStates';
import {LimitsAlerts} from '../../components/LimitsAlerts';
import {_fetchingAppState} from '../../store/selectors/apps';

export function AlertsProvider(
  {
    children,
    matchPath,
  }: {
    matchPath: string;
    children: React.ReactNode;
  }
) {
  const appState = useSelector(_fetchingAppState);

  return (
    <>
      {![
        AppRoutes.SURVEY,
        AppRoutes.SURVEY_APP,
      ].includes(matchPath) && (appState !== FiniteStates.LOADING) && (
        <>
          <LimitsAlerts
            matchPath={matchPath}
          />
          <IncompleteApp/>
        </>
      )}
      <>{children}</>
    </>
  )
}