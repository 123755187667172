import React from 'react';
import {useSelector} from 'react-redux';
import {parse} from 'querystring';
import {Input, Row, Tag} from 'antd';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {_currentApp} from '../../../../store/selectors/apps';

const { CheckableTag } = Tag;

interface IProps {
  value: string;
  onChange: () => void;
  setValue: UseFormSetValue<any>;
  formName: string;
}

const TaggedUrl: React.FC<IProps> = ({value, onChange, setValue, formName}) => {
  const currentApp = useSelector(_currentApp);
  const [launchUrlTags, setLaunchUrlTags] = React.useState<string[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);

  const tags = React.useMemo(() => {
    return Object.keys(currentApp.launch_url_tags)
  }, [currentApp]);

  React.useEffect(() => {
    if (currentApp) {
      setLaunchUrlTags(tags);
    }
  }, [currentApp, tags]);

  React.useEffect(() => {
    const selectedTags: string[] = [];
    tags.forEach((tag: string) => {
      if (value.includes(tag)) {
        selectedTags.push(tag);
      };
    });
    setSelectedTags(selectedTags);
  }, [tags, value]);

  const handleChange = (tag: string, checked: boolean) => {
    let newUrl = value;
    if(checked) {
      if (!newUrl.includes('?')) {
        newUrl += '?';
      }
      if (newUrl.endsWith('?')) {
        newUrl += tag;
      } else {
        newUrl += '&' + tag;
      }

    } else {
      if (!newUrl.includes('?')) {
        const pos = newUrl.indexOf('{');
        newUrl = newUrl.slice(0, pos) + '?' + newUrl.slice(pos);
      }
      const pos = newUrl.indexOf('?');
      if (pos !== -1) {
        const params = parse(newUrl.slice(pos + 1));
        delete params[tag];
        const queryString = Object.keys(params)
          .map(key => key + '=' + params[key])
          .join('&');
        newUrl = value.slice(0, pos) + '?' + queryString;
      }
    }
    setValue(formName, newUrl);
  };

  return (
    <Row align='middle'>
      <Input value={value} onChange={onChange} style={{marginBottom: '10px'}}/>
      <div className='tagsContent'>
        {launchUrlTags.map(tag => (
          <CheckableTag
            key={tag}
            checked={selectedTags.indexOf(tag) > -1}
            onChange={checked => handleChange(tag, checked)}
          >
            {tag}
          </CheckableTag>
        ))}
      </div>
    </Row>
  );
};

export default TaggedUrl;
