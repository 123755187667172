import {Card} from 'antd';
import React from 'react';
import {Button} from 'antd';
import {useParams} from 'react-router';
import {blankFeatures} from '../../../../constants';
import LinkOutlined from '@ant-design/icons/LinkOutlined';
import {Text} from '../../../../components/antd/Typography';

export function WordPressPlugin() {
  const {appUuid} = useParams<{ appUuid: string }>();

  return (
    <>
      <Card
        bordered={false}
        title='2. Install WP Plugin'
        className='create-app-card m-b-2'
      >
        <div
          className='m-b-2'
        >
          <Button
            icon={<LinkOutlined/>}
            onClick={() => {
              window.open('https://wordpress.org/plugins/smartpush-web-push-notifications/', '_blank', blankFeatures)
            }}
          >Get SmartPush Plugin
          </Button>
        </div>
        <Text
          className='m-b-2 d-block'
        >Install & Activate <Text fontWeight='bold'>SmartPush WordPress</Text> plugin
        </Text>
      </Card>
      <Card
        bordered={false}
        title='3. Copy UUID'
        className='create-app-card m-b-2'
      >
        <div
          className='m-b-2'
        >
          <Text
          >Add the App UUID in the <Text fontWeight='bold'>SmartPush WordPress plugin</Text>
          </Text>
        </div>
        <Text
          code
          copyable
          className='m-b-2 d-block'
        >{appUuid}
        </Text>
      </Card>
    </>
  )
}
