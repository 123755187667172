import clsx from 'clsx';
import React from 'react';
import Tooltip from '../Tooltip';
import MuiTable from '@material-ui/core/Table';
import {Theme} from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  cellRoot      : {
    height        : 58,
    fontSize      : 12,
    borderLeft    : 'none',
    borderRight   : 'none',
    color         : '#707070',
    '& *:not(svg)': {
      fontSize: 12,
    },
  },
  cellBold      : {
    fontWeight: 'bold',
    color     : theme.palette.text.primary,
  },
  cellTypography: {
    flexDirection : 'row',
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'flex-end',
  },
}));

export default function Table(
  {
    data,
  }: {
    data: { [key: string]: any }[];
  }
) {
  const classes = useStyles();
  return (
    <MuiTable
      className='table-main'
    >
      <TableBody>
        {data.map((item: any, index: number) => (
          <TableRow
            key={index}
          >
            <TableCell
              scope='row'
              component='td'
              className={clsx(classes.cellRoot, classes.cellBold)}
            >{item.name}
            </TableCell>
            <TableCell
              className={classes.cellRoot}
            >
              <Typography
                component='div'
                className={classes.cellTypography}
              >
                <Typography
                  component='div'
                >{item.value}
                </Typography>
                {!!(item.helperText && !item.percent) && (
                  <Tooltip
                    title={item.helperText}
                  />
                )}
              </Typography>
            </TableCell>
            {!!item.percent && (
              <TableCell
                className={classes.cellRoot}
              >
                <Typography
                  component='div'
                  className={classes.cellTypography}
                >
                  <Typography
                    component='div'
                  >{item.percent}
                  </Typography>
                  {!!item.helperText && (
                    <Tooltip
                      title={item.helperText}
                    />
                  )}
                </Typography>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  )
}
