import React from 'react';
import {filterObject} from '../../../../../../utils';

export default function (
  {
    deny,
    style,
    allow,
    cdnUrl,
    message,
    bgColor,
    position,
    backdrop,
    direction,
    isPreview,
    messageColor,
  }: {
    icon: string;
    cdnUrl: string;
    bgColor: string;
    message: string;
    position: string;
    direction: string;
    backdrop?: boolean;
    isPreview?: boolean;
    messageColor: string;
    style?: React.CSSProperties;
    deny: {
      button: string;
      bgColor: string;
      textColor: string;
    };
    allow: {
      button: string;
      bgColor: string;
      textColor: string;
    };
  }
) {

  return (
    <div>
      <div
        style={style}
        id='ppialog-popover-container'
        className='smart-root-container smart-root-container-4 ppialog-popover-container'
      >
        {!!backdrop && (
          <div
            style={{
              top            : 0,
              left           : 0,
              width          : '100%',
              height         : '100%',
              position       : 'fixed',
              minWidth       : '100vw',
              minHeight      : '100vh',
              zIndex         : 100000,
              backgroundColor: 'rgba(0,0,0,.5)',
            }}
          />
        )}
        <link rel="stylesheet" href={`${cdnUrl}/templates/style_4.css`}/>
        <div
          className={`smart-prompt smart-prompt-container smart-prompt-modal ${position.split('_').join('-')}`}
          style={filterObject({
            backgroundColor: bgColor,
            transform      : !isPreview && 'unset',
            zIndex         : !isPreview ? 0 : 9999999,
            width          : !isPreview ? '100%' : 380,
            position       : !isPreview ? 'static' : 'fixed',
          })}
        >
          <div
            className='smart-prompt-body-inside'
          >
            <div
              className='smart-prompt-message-animation'
            >
              <div
                className='smart-prompt-message-icon'
              >
                <div
                  className='smart-prompt-bubble'
                >
                  <div
                    className='smart-prompt-dot'
                  />
                  <div
                    className='smart-prompt-dot'
                  />
                  <div
                    className='smart-prompt-dot'
                  />
                </div>
                <div
                  className='smart-prompt-notification-dot'
                >1
                </div>
              </div>
              <div
                className='smart-prompt-message-icon-shadow'
              />
            </div>
            <div
              className='smart-prompt-body'
            >
              <h4
                className={`${direction}`}
                style={{
                  color: messageColor,
                }}
              >{message}
              </h4>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                {!!deny.button && (
                  <div
                    id='ppialog-popover-cancel-button'
                    style={{
                      backgroundColor: deny.bgColor,
                      color          : deny.textColor,
                    }}
                    className='smart-prompt-close smart-btn-block'
                  >{deny.button}
                  </div>
                )}
                {!!allow.button && (
                  <div
                    id='ppialog-popover-allow-button'
                    style={{
                      backgroundColor: allow.bgColor,
                      color          : allow.textColor,
                    }}
                    className='smart-prompt-start smart-btn-allow'
                  >{allow.button}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
