import React from 'react';
import {Breadcrumb} from 'antd';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import TableContainer from './TableContainer';
import ChartContainer from './ChartContainer';
import PreviewContainer from './PreviewContainer';
import {urlHelper} from '../../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../../constants/appRoutes';
import {Spinner} from '../../../../../../components/antd/Spinner';
import {Content} from '../../../../../../components/antd/Content';
import {getAction} from '../../../../../../store/actions/campaigns';
import {getEntity} from '../../../../../../store/selectors/campaigns';
import {Navigation} from '../../../../../../components/antd/Navigation';
import {getCampaignStats} from '../../../../../../store/selectors/campaigns';
import {toggleEntityAction} from '../../../../../../store/actions/campaigns';
import {emptyCampaignStatAction} from '../../../../../../store/actions/campaigns';
import {getCampaignDefaultLang} from '../../../../../../store/selectors/campaigns';
import {getSingleChartStatsAction} from '../../../../../../store/actions/campaigns';

export default function CampaignInfo() {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    uuid,
    appUuid,
  } = useParams<{ appUuid: string, uuid: string }>();

  const campaign = useSelector(getEntity);
  const campaignStats = useSelector(getCampaignStats);
  const campaignDefault = useSelector(getCampaignDefaultLang);

  React.useEffect(() => {
    if (campaign?.title) {
      document.title = `${campaign.title} Details | SmartPush`
    }
  }, [
    campaign,
  ]);

  React.useEffect(() => {
    let controller: null | AbortController = null;

    if (appUuid && uuid) {
      controller = new AbortController();
      dispatch(getAction(appUuid, uuid, {
        signal  : controller.signal,
        redirect: () => {
          history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid}))
        }
      }));
      dispatch(getSingleChartStatsAction(appUuid, uuid, {
        signal: controller.signal,
      }));
    }

    return function () {
      controller?.abort();

      dispatch(emptyCampaignStatAction());
      dispatch(toggleEntityAction(void 0));
    }
  }, [
    uuid,
    appUuid,
    history,
    dispatch,
  ]);

  if (!campaign.uuid || !campaignStats) {
    return (
      <Spinner/>
    )
  }

  const isWelcomeMessage = campaign?.automation?.type === 'welcome';

  return (
    <>
      <Navigation
        title={campaign.title}
        breadcrumbItems={[
          <Breadcrumb.Item
            key='app.pushNotifications'
          >
            <Link
              to={urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid})}
            >Push Notifications
            </Link>
          </Breadcrumb.Item>,
          <Breadcrumb.Item
            key='app.pushNotifications'
          >{campaign.title}
          </Breadcrumb.Item>
        ]}
      />
      <Content>
        <ChartContainer
          stats={campaignStats}
          total={Number(campaign.total) || 0}
          isWelcomeMessage={isWelcomeMessage}
        />
        <TableContainer
          campaign={campaign}
          stats={campaignStats}
          isWelcomeMessage={isWelcomeMessage}
        />
        <PreviewContainer
          isWelcomeMessage={isWelcomeMessage}
          campaign={{
            ...campaignDefault,
            uuid  : campaign.uuid,
            action: campaign?.action,
          }}
        />
      </Content>
    </>
  )
}
