import clsx from 'clsx';
import React from 'react';
import {duration} from 'moment';
import {now} from '../../utils';
import {momentTZ} from '../../utils';
import Box from '@material-ui/core/Box';
import {StatusItem} from '../StatusItem';
import Chip from '@material-ui/core/Chip';
import {ProgressBar} from '../ProgressBar';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DATE_COLUMNS from '../../constants/dateColumns';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme: Theme) => createStyles({
  chip                       : {
    borderRadius: 0,
    width       : 128,
  },
  root                       : {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'center',
    flexDirection : 'column',
  },
  icon                       : {
    width      : 8,
    height     : 8,
    marginRight: 1,
    color      : '#ffffff',
  },
  statusLabel                : {
    fontSize  : 12,
    fontWeight: 'bold',
    color     : '#ffffff',
  },
  popover                    : {
    pointerEvents: 'none',
  },
  backgroundPending          : {
    backgroundColor: '#484A63',
  },
  backgroundQueued           : {
    backgroundColor: '#8D9AFC',
  },
  backgroundSent             : {
    backgroundColor: '#13609B',
  },
  backgroundFails            : {
    backgroundColor: '#E8505B',
  },
  backgroundCompleted        : {
    backgroundColor: '#4A992A',
  },
  backgroundScheduled        : {
    backgroundColor: '#8ECAE6',
  },
  backgroundUnknown          : {
    backgroundColor: '#7C7C7C',
  },
  backgroundAutomated        : {
    backgroundColor: '#096dd9',
    border         : '1px solid #096dd9',
  },
  backgroundOutlinedAutomated: {
    '& *'          : {
      color: '#096dd9',
    },
    backgroundColor: '#ffffff',
    border         : '1px solid #096dd9',
  },
  paper                      : {
    borderRadius: 0,
    border      : '1px solid #24083C',
  },
  progressWrapper            : {
    width          : 128,
    backgroundColor: 'lightgray',
    marginTop      : theme.spacing(1)
  },
  tooltip                    : {
    padding: theme.spacing(.5),
  },
  disabled                   : {
    color : 'rgba(0, 0, 0, 0.26)',
    '& *' : {
      color: 'rgba(0, 0, 0, 0.26)',
    },
    border: '1px solid rgba(0, 0, 0, 0.12)',
  }
}));

export const Status: React.FC<{
  progress?: any;
  status: number;
  scheduledAt?: any;
  disabled?: boolean;
  variant?: 'contained' | 'outlined';
}> = (
  {
    status,
    variant,
    progress,
    disabled,
    scheduledAt,
  }
) => {
  const {t} = useTranslation();
  const classes: any = useStyles();
  const [readings, setReadings] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (progress) {
      setReadings([
        {
          color: 'success',
          value: Number(progress.success),
          name : t('old_labels.success'),
        },
        {
          color: 'fails',
          value: Number(progress.fails),
          name : t('old_labels.unsubscribed'),
        },
        {
          color: 'errors',
          value: Number(progress.errors),
          name : t('old_labels.errors'),
        },
        {
          color: 'pending',
          value: Number(progress.pending),
          name : t('old_labels.pending'),
        },
      ])
    }
  }, [
    t,
    progress,
  ]);

  const dateFormat = (DATE_COLUMNS.find(dC => dC.column === 'scheduled_at') || {format: 'YYYY-MM-DD hh:mm:ss A'}).format;
  const newValue = momentTZ(scheduledAt);

  const diff = duration(newValue.diff(now())).humanize(true);

  const statusKey = t(`old_statuses.${status}`);
  const statusLabel = t(`old_labels.${statusKey}`);

  let style;

  switch (statusKey) {
    case 'pending':
    case 'file_export_pending':
    case 'file_upload_pending': {
      style = classes.backgroundPending;
      break;
    }
    case 'queued':
    case 'file_export_in_queue':
    case 'file_upload_in_queue': {
      style = classes.backgroundQueued;
      break;
    }
    case 'sent':
    case 'processing':
    case 'file_export_processing':
    case 'file_upload_processing': {
      style = classes.backgroundSent;
      break;
    }
    case 'fails': {
      style = classes.backgroundFails;
      break;
    }
    case 'completed':
    case 'file_export_completed':
    case 'file_upload_completed': {
      style = classes.backgroundCompleted;
      break;
    }
    case 'scheduled': {
      style = classes.backgroundScheduled;
      break;
    }
    case 'unknown': {
      style = classes.backgroundUnknown;
      break;
    }
    case 'automated': {
      style = classes.backgroundAutomated;
      if (variant === 'outlined') {
        style = classes.backgroundOutlinedAutomated;
      }
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Typography
      component='div'
      className={classes.root}
    >
      {statusLabel && (
        <>
          {(status === 5) ? (
            <Tooltip
              title={
                <>
                  <Typography
                    component='p'
                  >{newValue.format(dateFormat)}
                  </Typography>
                  <Typography
                    component='p'
                  >({diff})
                  </Typography>
                </>
              }
            >
              <div>
                <Chip
                  size='small'
                  title={statusLabel}
                  label={
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <FiberManualRecordIcon
                        fontSize='small'
                        className={classes.icon}
                      />
                      <Typography
                        component='p'
                        className={classes.statusLabel}
                      >{statusLabel !== 'Pending' ? statusLabel : 'Draft'}
                      </Typography>
                    </Box>
                  }
                  className={clsx(classes.chip, style, !!disabled && classes.disabled)}
                />
              </div>
            </Tooltip>
          ) : (
            <Chip
              size='small'
              title={statusLabel}
              label={
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  <FiberManualRecordIcon
                    fontSize='small'
                    className={classes.icon}
                  />
                  <Typography
                    component='p'
                    className={classes.statusLabel}
                  >{statusLabel !== 'Pending' ? statusLabel : 'Draft'}
                  </Typography>
                </Box>
              }
              className={clsx(classes.chip, style, !!disabled && classes.disabled)}
            />
          )}
        </>
      )}
      {progress && (
        <>
          <Tooltip
            placement='right'
            classes={{
              tooltip: classes.tooltip
            }}
            title={
              <div>
                {readings.map((item: any, index: number) => (
                  <StatusItem
                    label={item.name}
                    color={item.color}
                    current={item.value}
                    key={`StatusItem_${index}`}
                    total={Number(progress.total)}
                  />
                ))}
              </div>
            }
          >
            <div>
              <Typography
                component='div'
                className={classes.progressWrapper}
              >
                <ProgressBar
                  readings={readings}
                  total={Number(progress.total)}
                />
              </Typography>
            </div>
          </Tooltip>
        </>
      )}
    </Typography>
  )
};
