import React from 'react';
import {Card} from '../Card';
import {stringify} from 'querystring';
import {useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import {CardHeader} from '../CardHeader';
import Radio from '@material-ui/core/Radio';
import {ErrorMessage} from '../ErrorMessage';
import {Theme} from '@material-ui/core/styles';
import {AsyncPaginate} from '../AsyncPaginate';
import Divider from '@material-ui/core/Divider';
import {urlHelper} from '../../utils/urlHelper';
import {ApiRoutes} from '../../constants/apiRoutes';
import RadioGroup from '@material-ui/core/RadioGroup';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {_apiBaseUrl} from '../../store/selectors/application';
import {_accessToken} from '../../store/selectors/application';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme: Theme) => ({
  cardContentRoot: {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  nestedCard     : {
    overflow    : 'unset',
    marginBottom: theme.spacing(1),
  },
}));

export default function AudienceCard(
  {
    labels,
    errors,
    entity,
    appUuid,
    onChange,
  }: {
    labels: any;
    entity: any;
    errors?: any;
    onChange: any;
    appUuid: string;
    loading?: boolean;
  }
) {
  const classes = useStyles();

  let include_segments_e: undefined | string;
  let exclude_segments_e: undefined | string;

  Object.keys(errors || {}).forEach((e: any) => {
    if (e.startsWith('include_segments')) {
      include_segments_e = errors[e];
    } else if (e.startsWith('exclude_segments')) {
      exclude_segments_e = errors[e];
    }
  });

  return (
    <Card
      elevation={0}
      className={classes.nestedCard}
    >
      <CardHeader
        color='primary'
        title={labels.audience}
      />
      <CardContent
        classes={{root: classes.cardContentRoot}}
      >
        <>
          <FormControl
            component='fieldset'
          >
            <RadioGroup
              name='send_to'
              value={entity.send_to || 'all'}
              onChange={(e, value) => onChange(e.target.name, value)}
            >
              <FormControlLabel
                value='all'
                control={<Radio color='primary'/>}
                label={labels.send_to_subscribed_users}
              />
              <FormControlLabel
                value='particular'
                control={<Radio color='primary'/>}
                label={labels.send_to_particular_segment}
              />
            </RadioGroup>
          </FormControl>
          {String(entity?.send_to).toLowerCase() === 'particular' && (
            <div>
              <Box
                marginTop={1}
                marginBottom={2}
              ><Divider/>
              </Box>
              <AsyncSelect
                appUuid={appUuid}
                errors={{
                  ...errors,
                  type: 'include_segments',
                }}
                value={entity?.include_segments || ''}
                label={labels.send_to_users_in_segments}
                error={errors?.include_segments || include_segments_e}
                onChange={(e: any, a: any) => onChange('include_segments', e, a)}
              />
              <AsyncSelect
                appUuid={appUuid}
                errors={{
                  ...errors,
                  type: 'exclude_segments',
                }}
                value={entity?.exclude_segments || ''}
                label={labels.exclude_users_in_segments}
                error={errors?.exclude_segments || exclude_segments_e}
                onChange={(e: any, a: any) => onChange('exclude_segments', e, a)}
              />
            </div>
          )}
        </>
      </CardContent>
    </Card>
  )
}

function AsyncSelect(
  {
    label,
    error,
    value,
    errors,
    appUuid,
    disabled,
    onChange,
  }: any
) {
  const apiBaseUrl = useSelector(_apiBaseUrl);
  const accessToken = useSelector(_accessToken);

  async function loadOptions(inputValue: string, prevOptions: any[], {pageNumber}: any): Promise<any> {
    try {
      const endpoint = `${apiBaseUrl}${urlHelper(ApiRoutes.LIST_SEGMENTS, {
        appUuid,
      })}`;
      const options = stringify({
        pageNumber,
        searchText: inputValue
      });
      const response = await fetch(`${endpoint}?${options}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const {
          data: {
            rows,
            total,
            pageSize,
            pageNumber,
          },
        } = await response.json();

        return {
          hasMore   : pageNumber * pageSize <= total,
          additional: {
            pageNumber: pageNumber + 1,
          },
          options   : (rows || []).map((item: any) => ({
            value: item.id,
            label: item.name,
          })),
        }
      }

      return {
        options: [],
        hasMore: false,
      }
    } catch (e) {
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  return (
    <Box
      marginBottom={3}
    >
      <AsyncPaginate
        isMulti
        label={label}
        value={value}
        error={!!error}
        onChange={onChange}
        isDisabled={disabled}
        closeMenuOnSelect={false}
        additional={{pageNumber: 1}}
        loadOptions={loadOptions as any}
      />
      {!!error && (
        <ErrorMessage
          message={error}
        />
      )}
    </Box>
  )
}