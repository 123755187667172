import {DEFAULT_PAGE_SIZES} from '../../../../../constants';
import {ActionTypes} from '../../../../constants/subscribers';

const InitialState: any = {
  pageSize                    : 10,
  columns                     : [],
  sorting                     : [
    {
      direction : 'desc',
      columnName: 'created_at',
    },
  ],
  pageSizes                   : DEFAULT_PAGE_SIZES,
  columnOrder                 : [
    'uuid',
    'brand',
    'browser',
    'browser_with_version',
    'city',
    'country',
    'device',
    'ip_address',
    'isp_provider',
    'conversion_count',
    'conversion_sum',
    'language',
    'model',
    'os',
    'state_region',
    'tags',
    'time_zone',
    'last_active_at',
    'last_clicked_at',
    'last_closed_at',
    'last_displayed_at',
    'sessions',
    'last_visited_at',
    'created_at',
    'last_converted_at',
    'actions',
  ],
  tableColumnVisibility       : {
    columnExtensions: [
      {
        togglingEnabled: false,
        columnName     : 'uuid',
      },
      {
        togglingEnabled: false,
        columnName     : 'email',
      },
      {
        togglingEnabled: false,
        columnName     : 'actions',
      },
    ],
  },
  tableColumnExtensions       : [
    {
      wordWrapEnabled: true,
      columnName     : 'uuid',
    },
    {
      width     : 120,
      columnName: 'brand',
    },
    {
      width     : 180,
      columnName: 'last_visited_at',
    },
    {
      width     : 120,
      columnName: 'browser',
    },
    {
      width     : 180,
      columnName: 'conversion_count',
    },
    {
      width     : 150,
      columnName: 'browser_with_version',
    },
    {
      width     : 180,
      columnName: 'conversion_sum',
    },
    {
      width     : 120,
      columnName: 'city',
    },
    {
      width     : 120,
      columnName: 'country',
    },
    {
      width     : 120,
      columnName: 'device',
    },
    {
      width     : 120,
      columnName: 'email',
    },
    {
      width     : 150,
      columnName: 'ip_address',
    },
    {
      width          : 150,
      wordWrapEnabled: true,
      columnName     : 'isp_provider',
    },
    {
      width     : 120,
      columnName: 'language',
    },
    {
      width     : 120,
      columnName: 'model',
    },
    {
      width     : 180,
      columnName: 'os',
    },
    {
      width     : 150,
      columnName: 'state_region',
    },
    {
      width     : 120,
      columnName: 'tags',
    },
    {
      width     : 120,
      columnName: 'time_zone',
    },
    {
      width     : 180,
      columnName: 'last_active_at',
    },
    {
      width     : 180,
      columnName: 'last_clicked_at',
    },
    {
      width     : 180,
      columnName: 'last_closed_at',
    },
    {
      width     : 180,
      columnName: 'last_displayed_at',
    },
    {
      width     : 180,
      columnName: 'last_converted_at',
    },
    {
      width     : 180,
      columnName: 'created_at',
    },
    {
      width     : 80,
      align     : 'center',
      columnName: 'actions',
    },
  ],
  sortingStateColumnExtensions: [
    {
      sortingEnabled: false,
      columnName    : 'actions',
    },
    {
      sortingEnabled: false,
      columnName    : 'tags',
    },
  ],
};

export default (state: any = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_SUBSCRIBERS_TABLE_OPTIONS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};
