import React from 'react';
import {Modal} from 'antd';
import {useSelector} from 'react-redux';
import {_beforeunload} from '../../../store/selectors/application';

export function BeforeunloadProvider(
  {
    children
  }: {
    children: React.ReactNode;
  }
) {
  const beforeunload = useSelector(_beforeunload);

  React.useEffect(() => {
    if (beforeunload?.open) {
      Modal.warning({
        okCancel  : true,
        centered  : true,
        title     : beforeunload.bodyTitle,
        okText    : beforeunload.agreeLabel,
        onOk      : beforeunload.agreeAction,
        cancelText: beforeunload.dismissLabel,
        onCancel  : beforeunload.dismissAction,
        content   : beforeunload.bodyDescription,
      })
    }
  }, [
    beforeunload,
  ]);

  return (
    <>{children}</>
  )
}