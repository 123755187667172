import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '../../../../../../components/Dialog';
import {TColor} from '../../../../../../components/Dialog';

export default function CancelDialog(
  {
    open,
    color,
    loading,
    onClose,
    disabled,
    bodyTitle,
    agreeLabel,
    agreeAction,
    dismissLabel,
    dismissAction,
    bodyDescription,
  }: {
    onClose?: any;
    open: boolean;
    color?: TColor;
    agreeAction: any;
    loading?: boolean;
    dismissAction: any;
    disabled?: boolean;
    bodyTitle?: string;
    dismissLabel: string;
    bodyDescription?: string;
    agreeLabel: React.ReactNode;
  }
) {
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth='sm'
      onClose={onClose}
      color={color || 'danger'}
      actions={{
        align  : 'center',
        actions: [
          {
            color    : 'primary',
            variant  : 'outlined',
            label    : dismissLabel,
            onClick  : dismissAction,
            'data-id': 'close-button'
          },
          {
            loading,
            label    : agreeLabel,
            variant  : 'contained',
            onClick  : agreeAction,
            'data-id': 'submit-cancel',
            color    : color || 'danger',
            disabled : loading || disabled
          },
        ],
      }}
      dialogTitle={{
        onClose: onClose,
        color  : color || 'danger',
      }}
      content={{
        bodyTitle,
        bodyDescription,
        bodyIcon: CancelIcon,
        color   : color || 'danger'
      }}
    />
  )
}
