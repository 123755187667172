import {getSmartPush} from '../index';
import {createSelector} from 'reselect';
import {emptyObject} from '../../../constants';

export const getSettings = createSelector(getSmartPush, (smartPush) => {
  return smartPush.settings;
});

export const getMessage = createSelector(getSettings, (settings) => {
  return settings.message;
});

export const getIsLoading = createSelector(getSettings, (settings) => {
  return settings.loading;
});

export const getErrors = createSelector(getSettings, (settings) => {
  return settings.errors || emptyObject;
});
