import React from 'react';
import {useParams, useHistory} from 'react-router';
import {Menu, Dropdown, Row} from 'antd';
import {urlHelper} from '../../../../../utils/urlHelper';
import {aclKeys} from '../../../../../constants/aclKeys';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {TableActions} from '../../../../../constants/tableActions';
import {MoreOutlined, EyeFilled, EyeInvisibleFilled, EditFilled, CopyFilled, DeleteFilled} from '@ant-design/icons';
import {Automations} from '../../../../../types/automations';
import Dialogs from '../Dialogs';
import {useSmartPush} from '../../../../../hooks/useSmartPush';

interface IProps {
  row: Automations.Entity,
}

const iconStyle = {
  marginRight: 10,
  marginTop: 2,
  color: '#0000008a',
};

const ActionCell: React.FC<IProps> = ({ row }) => {
  const history = useHistory();
  const {appUuid} = useParams<{ appUuid: string }>();
  const {
    user:{
      canUpdate,
      canCreate,
      canDelete,
    }
  } = useSmartPush();

  const [dialogType, setDialogType] = React.useState<string | undefined>(undefined);

  const deleteOrStatusChange = (dialogType: TableActions) => {
    setDialogType(dialogType);
  };

  const menu = (
    <Menu>
      {!Boolean(row.active) && canUpdate(aclKeys.AUTOMATIONS) && (
        <Menu.Item onClick={() => deleteOrStatusChange(TableActions.TOGGLE_STATE)} key={row.id}>
          <Row align='middle'>
            <EyeFilled style={iconStyle}/>
            <div>Activate</div>
          </Row>
        </Menu.Item>
      )}
      {canUpdate(aclKeys.AUTOMATIONS) && (
        <Menu.Item
          onClick={() => history.push(urlHelper(AppRoutes.AUTOMATION_UPDATE, {appUuid, id: row.id}))}
          key={row.name}
        >
          <Row align='middle'>
            <EditFilled style={iconStyle}/>
            <div>Edit</div>
          </Row>
        </Menu.Item>
      )}
      {canCreate(aclKeys.AUTOMATIONS) && (
        <Menu.Item onClick={()=> history.push(urlHelper(AppRoutes.AUTOMATION_CLONE, {appUuid, id: row.id}))} key={row.created_at}>
          <Row align='middle'>
            <CopyFilled style={iconStyle}/>
            <div>Clone</div>
          </Row>
        </Menu.Item>
      )}
      {Boolean(row.active) && canUpdate(aclKeys.AUTOMATIONS) && (
        <Menu.Item onClick={() => deleteOrStatusChange(TableActions.TOGGLE_STATE)} key={`${row.updated_at}_${row.created_at}`}>
          <Row align='middle'>
            <EyeInvisibleFilled style={{...iconStyle, color: '#f44336'}}/>
            <div>Deactivate</div>
          </Row>
        </Menu.Item>
      )}
      {canDelete(aclKeys.AUTOMATIONS) && (
        <Menu.Item onClick={() => deleteOrStatusChange(TableActions.DELETE)} key={row.from_date}>
          <Row align='middle'>
            <DeleteFilled style={{...iconStyle, color: '#f44336'}}/>
            <div>Delete</div>
          </Row>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      {!!dialogType &&
        <Dialogs
          dialogType={dialogType}
          visible={!!dialogType}
          onCancel={() => setDialogType(undefined)}
          rowData={row}
        />
      }
      <Dropdown overlay={menu} trigger={['click']} key={row.id}>
        <MoreOutlined style={{fontSize: '18px'}}/>
      </Dropdown>
    </>
  );
}

export default ActionCell;
