import React from 'react';
import {useParams} from 'react-router';
import {useSelector, useDispatch} from 'react-redux';
import {Pagination, Row, Card} from 'antd';
import {deleteIcon} from '../../../store/actions/builder/icon/services';
import {_updateFilters} from'../../../store/actions/builder/icon/actions';
import {getIconsData, getTotal, getFilters, getFetchState} from '../../../store/selectors/icons';
import {Builder} from '../../../types/builder';
import MediaCard from '../MediaCard';
import {Spinner} from '../../../components/antd/Spinner';
import DeleteDialog from '../../../components/antd/Dialogs/DeleteDialog';
import CreateOrEditDialog from './Dialogs/CreateOrEditIcon';

const gridStyle = {
  width: '200px',
  margin: '5px',
  padding: '0px'
};

const IconsView: React.FC = () => {
  const dispatch = useDispatch();
  const {appUuid} = useParams<{appUuid: string}>();
  const iconsData = useSelector(getIconsData);
  const fetched = useSelector(getFetchState);
  const total = useSelector(getTotal);
  const filters = useSelector(getFilters);
  const [deletableItem, setDeletableItem] = React.useState(null);
  const [editableItem, setEditableItem] = React.useState(null);

  const onDeleteClick = (iconData: Builder.IconEntity) => {
    setDeletableItem(iconData);
  };

  const handleDelete = (data: Builder.IconEntity) => {
    dispatch(deleteIcon(appUuid, data.id));
  };

  const onEditClick = (iconData: Builder.IconEntity) => {
    setEditableItem(iconData);
  };

  const getCardsContent = () => {
    if (!fetched) {
      return <Spinner />
    } else {
      return (
        <Card>
          {iconsData.map(item => {
            return (
              <Card.Grid style={gridStyle} key={item.id}>
                <MediaCard
                  rowData={item}
                  key={item.id}
                  onDeleteClick={onDeleteClick}
                  onEditClick={onEditClick}
                />
              </Card.Grid>
            );
          })}
        </Card>
      );
    }
  };

  const getDeleteDialogBody = () => {
    return (
      <>
        <h2>Are you sure you want to delete this icon?</h2>
        <span>Once you are done deleting, you cannot undo this action.</span>
      </>
    );
  };

  const onPageChanges = (pageNumber: number, pageSize: number) => {
    dispatch(_updateFilters({ pageNumber, pageSize }));
  };

  return (
    <>
      {getCardsContent()}
      <Row style={{marginTop: 20}} className='justify-content-center'>
        <Pagination
          total={total}
          showSizeChanger
          current={filters.pageNumber}
          onChange={(p, s) => onPageChanges(p, s)}
        />
      </Row>
      {deletableItem &&
        <DeleteDialog
          title='Delete Icon'
          onCancelText='Keep Icon'
          onAcceptText='Delete Icon'
          visible={deletableItem}
          onCancel={() => setDeletableItem(null)}
          onAccept={handleDelete}
          rowData={deletableItem}
          body={getDeleteDialogBody}
        />
      }
      {editableItem &&
        <CreateOrEditDialog
          visible={editableItem}
          onCancel={() => setEditableItem(null)}
          rowData={editableItem}
        />
      }
    </>
  )
};

export default IconsView;
