import {ActionTypes} from '../../../../constants/subscribers';
import {ActionTypes as CoreActionTypes} from '../../../../constants/application';

const InitialState: any = {
  byId        : {},
  allIds      : [],
  loading     : false,
  errors      : void 0,
  entity      : void 0,
  dataOptions : void 0,
  actionDialog: void 0,
};

export default (state: any = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_SUBSCRIBERS_SET_DATA: {
      const byId: any = {};
      const allIds: any[] = [];
      (payload as Array<any>).forEach((
        {
          uuid,
          ...rest
        }
      ) => {
        allIds.push(uuid);
        const otherKeys = {...rest};
        byId[uuid] = {
          uuid,
          ...Object.keys(otherKeys).reduce((acc: any, curr: string) => {
            if (
              (String(otherKeys[curr]).toLowerCase() === 'undefined') &&
              curr !== 'tags'
            ) {
              acc[curr] = 'Unknown';
            } else {
              acc[curr] = otherKeys[curr];
            }
            return acc;
          }, {}),
        };
      });
      return {
        ...state,
        byId,
        allIds,
      };
    }
    case ActionTypes.WEB_NOTIFY_SUBSCRIBERS_EMPTY_DATA: {
      return InitialState;
    }
    case ActionTypes.WEB_NOTIFY_SUBSCRIBERS_DATA_OPTIONS: {
      return {
        ...state,
        dataOptions: {
          ...state.dataOptions,
          ...payload
        }
      }
    }
    case ActionTypes.WEB_NOTIFY_SUBSCRIBERS_TOGGLE_ENTITY: {
      return {
        ...state,
        entity: payload
      }
    }
    case ActionTypes.WEB_NOTIFY_SUBSCRIBERS_TOGGLE_DIALOG: {
      return {
        ...state,
        actionDialog: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_SUBSCRIBERS_TOGGLE_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case CoreActionTypes.LOGOUT: {
      return InitialState
    }
    default:
      return state;
  }
};
