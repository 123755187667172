import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    transition: theme.transitions.create(['background-color']),
    '&:hover' : {
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
    }
  },
}));

export default function TableRowComponent(
  {
    children,
    ...rest
  }: Table.DataRowProps) {
  const classes = useStyles();
  return (
    <Table.Row
      {...rest}
      className={classes.root}
    >{children}
    </Table.Row>
  )
}