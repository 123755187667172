import clsx from 'clsx';
import React from 'react';
import Box from '@material-ui/core/Box';
import {useWatch} from 'react-hook-form';
import {ucFirst} from '../../../../utils';
import {Theme} from '@material-ui/core/styles';
import {useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Control} from 'react-hook-form/dist/types/form';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  rootTypography    : {
    fontSize     : 16,
    fontWeight   : 400,
    lineHeight   : 1.625,
    display      : 'flex',
    flexDirection: 'column',
    color        : '#000000',
  },
  disabledTypography: {
    color: theme.palette.text.disabled,
  },
}));

export function ExistingCard(
  {
    control,
    paymentMethod,
  }: {
    paymentMethod: Record<string, any>;
    control: Control<Record<string, any>>;
  }
) {
  const theme = useTheme();
  const classes = useStyles();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const card = useWatch({
    control,
    name: 'card',
  });
  const disabled = card === 'new_card';

  return (
    <Box
      paddingLeft={smDown ? 0 : 4}
    >
      <Typography
        className={clsx(
          classes.rootTypography,
          disabled && classes.disabledTypography,
        )}
      >
        <Typography
          color='inherit'
          variant='inherit'
        >
          <Typography
            component='span'
          >{ucFirst(paymentMethod?.card?.brand || '')}
          </Typography>
          <Box
            component='span'
            marginLeft={1.5}
            display='inline-flex'
          >
            <Typography
              component='span'
            >**** {paymentMethod?.card?.last4}
            </Typography>
          </Box>
        </Typography>
        <Typography
          color='inherit'
          variant='inherit'
        >Expires {paymentMethod?.card?.exp_month}/{paymentMethod?.card?.exp_year}
        </Typography>
      </Typography>
    </Box>
  )
}
