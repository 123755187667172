import React from 'react';
import {useWatch} from 'react-hook-form';
import {ICreateAppForm} from '../../useUpsertApp';
import {Preview} from '../../../../Common/Preview';
import {Control} from 'react-hook-form/dist/types/form';

export function PromptPreview(
  {
    control,
  }: {
    control: Control<ICreateAppForm>;
  }
) {
  const defaultIconUrl = useWatch({
    control,
    name: 'default_icon_url',
  });

  return (
    <Preview
      xlinkHref={defaultIconUrl}
    />
  )
}