import React from 'react';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import {SkeletonProps} from '@material-ui/lab/Skeleton';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  withChildren: {
    '&>*': {
      visibility: 'unset',
    },
  },
  fitContent  : {
    maxWidth: 'unset',
  },
}));

export default function Skeleton(props: SkeletonProps) {
  const classes = useStyles();

  return (
    <MuiSkeleton
      {...props}
      classes={{
        ...props.classes,
        fitContent  : classes.fitContent,
        withChildren: classes.withChildren,
      }}
    />
  )
}