import React from 'react';
import {ManageAccountRoutes} from './Routes';
import {useManageAccount} from './useManageAccount';
import {Content} from '../../../components/antd/Content';
import {ManageAccountHeader} from './Components/ManageAccountHeader';

export function ManageAccount() {
  const {
    hideTabs,
    pathname,
    currentStep,
    apiKeysPath,
    securityPath,
    tabsOnChange,
    showPlanChange,
    hasApiKeysAccess,
    manageAccountPath,
    paymentsAndSubscriptionsPath,
  } = useManageAccount();

  return (
    <>
      <ManageAccountHeader
        hideTabs={hideTabs}
        pathname={pathname}
        currentStep={currentStep}
        apiKeysPath={apiKeysPath}
        tabsOnChange={tabsOnChange}
        securityPath={securityPath}
        showPlanChange={showPlanChange}
        hasApiKeysAccess={hasApiKeysAccess}
        manageAccountPath={manageAccountPath}
        paymentsAndSubscriptionsPath={paymentsAndSubscriptionsPath}
      />
      <Content>
        <ManageAccountRoutes
          showPlanChange={showPlanChange}
          hasApiKeysAccess={hasApiKeysAccess}
        />
      </Content>
    </>
  )
}

