import clsx from 'clsx';
import React from 'react';
import {TColor} from '../index';
import Box from '@material-ui/core/Box';
import red from '@material-ui/core/colors/red';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {DialogTitleProps} from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme: Theme) => ({
  root   : {
    top           : 0,
    fontSize      : 16,
    zIndex        : 1101,
    fontWeight    : 500,
    display       : 'flex',
    textAlign     : 'center',
    position      : 'sticky',
    color         : '#ffffff',
    justifyContent: 'space-between',
    padding       : theme.spacing(1, 2),
  },
  success: {
    backgroundColor: green[500],
  },
  danger : {
    backgroundColor: red[500],
  },
  warning: {
    backgroundColor: amber[500],
  },
  primary: {
    fontWeight     : 500,
    color          : theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    borderBottom   : '1px solid rgb(240, 240, 240)',
  },
}));

let colorClass = '';

export default function Title(
  {
    color,
    onClose,
    disabled,
    children,
    className,
    rightIcon: RightIcon,
    ...rest
  }: TitleProps
) {
  const classes = useStyles();
  switch (color) {
    case 'danger': {
      colorClass = classes.danger;
      break;
    }
    case 'success': {
      colorClass = classes.success;
      break;
    }
    case 'warning': {
      colorClass = classes.warning;
      break;
    }
    case 'primary': {
      colorClass = classes.primary;
      break;
    }
    default : {
      colorClass = '';
      break;
    }
  }

  return (
    <MuiDialogTitle
      disableTypography
      className={clsx(classes.root, className, colorClass)}
      {...rest}
    >
      <Box
        display='flex'
        alignItems='center'
      >{children}
      </Box>
      {onClose && (
        <IconButton
          size='small'
          onClick={onClose}
          disabled={disabled}
          data-id='close-icon'
        >
          <CloseIcon
            fontSize='small'
          />
        </IconButton>
      )}
      {RightIcon && <RightIcon/>}
    </MuiDialogTitle>
  )
}

export interface TitleProps extends DialogTitleProps {
  color?: TColor;
  rightIcon?: any;
  disabled?: boolean;
  onClose?: () => any;
}
