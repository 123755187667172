import {createSelector} from 'reselect';
import {getSmartPush, getState} from '../index';
import {emptyArray, emptyObject} from '../../../constants';
import {ACCESS_TYPES_BY_STRING} from '../../../constants/users';

export const getUsers = createSelector(getSmartPush, (smartPush) => {
  return smartPush.users;
});

export const getData = createSelector(getUsers, (users) => {
  return users.data;
});

export const getById = createSelector(getData, (data) => {
  return data.byId;
});

export const getAllIds = createSelector(getData, (data) => {
  return data.allIds;
});

export const getEntity = createSelector(getData, (data) => {
  return data.entity;
});

export const getUserIsEnabled = createSelector(getEntity, (entity) => {
  return !!entity?.enabled;
});

export const getPendingPlan = createSelector(getEntity, (entity) => {
  return entity?.pending_plan || 0;
});

export const getSelectedPlan = createSelector(getEntity, (entity) => {
  return entity?.selected_plan || 0;
});

export const getUserModulePermissions = createSelector(getEntity, (entity) => {
  return entity?.permissions || emptyArray;
});

export const getGeneralInformationData = createSelector(getEntity, (entity) => {
  return {
    email     : entity?.email,
    last_name : entity?.last_name,
    first_name: entity?.first_name,
  };
});

export const getFeatureGroups = createSelector(getData, (data) => {
  return data.featureGroups || emptyArray;
});

export const getGroups = createSelector(getData, (data) => {
  return data.groups || emptyArray;
});

export const getSubscriptionPlans = createSelector(getData, (data) => {
  return data.subscriptionPlans || emptyArray;
});

export const getPermissions = createSelector(getData, (data) => {
  return data.permissions || emptyArray;
});

export const getErrors = createSelector(getData, (data) => {
  return data.errors || emptyObject;
});

export const getDataOptions = createSelector(getData, (data) => {
  return data.dataOptions || emptyObject;
});

export const getOptions = createSelector(getUsers, (users) => {
  return users.options;
});

export const getLastQuery = createSelector(getOptions, (options) => {
  return options.lastQuery;
});

//new
export const _users = createSelector(getState, (state) => {
  return state.users;
});

export const _getUsersData = createSelector(_users, (users) => {
  return users.dataOptions.entities;
});

export const _getFetchState = createSelector(_users, (users) => {
  return users.dataOptions.fetched;
});

export const _getTotal= createSelector(_users, (users) => {
  return users.dataOptions.total;
});

export const _getFilters = createSelector(_users, (users) => {
  return users.filters;
});

export const _getSubscriptionPlans = createSelector(_users, (users) => {
  return users.subScriptionPlans;
});

export const _getPermissions = createSelector(_users, (users) => {
  return users.featureGroups.map((fg: any) => {
    return (fg?.access_types).map((access_type: string) => {
      const accType = ACCESS_TYPES_BY_STRING[access_type] as unknown as typeof ACCESS_TYPES_BY_STRING;
      return [
        {
          value: `${fg.slug}_1_${access_type}`,
          label: `${fg.name}-${accType} On`,
        },
        {
          value: `${fg.slug}_0_${access_type}`,
          label: `${fg.name}-${accType} Off`,
        }
      ]
    }).flat();
  }).flat();
});
