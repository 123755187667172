import React from 'react';
import Dialog from '../../Dialog';
import Box from '@material-ui/core/Box';
import {IconButton} from '../../IconButton';
import csvPng from '../../../images/csv.png';
import Tooltip from '@material-ui/core/Tooltip';
import {Plugin} from '@devexpress/dx-react-core';
import {Template} from '@devexpress/dx-react-core';
import RefreshIcon from '@material-ui/icons/Refresh';
import FilterListIcon from '@material-ui/icons/FilterList';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {TemplatePlaceholder} from '@devexpress/dx-react-core';

const useStyles = makeStyles(() => ({
  actionsRoot: {
    display   : 'flex',
    alignItems: 'center',
  },
}));

export default function CustomToolbarMarkup(
  {
    refresh,
    messages,
    showFilters,
    handleExport,
    toggleFilters,
    disableReload,
    toolbarLeftActions : LeftActions,
    toolbarRightActions: RightActions,
  }: {
    refresh: () => void;
    showFilters?: boolean;
    disableReload?: boolean;
    handleExport?: () => void;
    toggleFilters: () => void;
    toolbarLeftActions?: React.ComponentType<any>;
    toolbarRightActions?: React.ComponentType<any>;
    messages: {
      refresh: string;
      export?: string;
      show_filters: string;
      exportAgree?: string;
      tableNoData?: string;
      exportCancel?: string;
      export_as_csv: string;
      exportMainText?: string;
      exportSecondaryText?: string;
    }
  }
) {
  const classes = useStyles();
  const [dialog, setDialog] = React.useState<string | undefined>(void 0);

  return (
    <Plugin
      name='customToolbarMarkup'
    >
      <Template
        name='toolbarContent'
      >
        <TemplatePlaceholder
          children={(content: any) => {
            if ((content.constructor === Array) && content.length === 2) {
              return (
                <>
                  {content[1]}
                  {LeftActions && (
                    <LeftActions
                      refresh={refresh}
                    />
                  )}
                  {content[0]}
                </>
              )
            } else {
              return (
                <>
                  {content}
                  {LeftActions && (
                    <LeftActions
                      refresh={refresh}
                    />
                  )}
                </>
              )
            }
          }}
        />
        <div
          className={classes.actionsRoot}
        >
          {RightActions && (
            <RightActions
              refresh={refresh}
            />
          )}
          {typeof handleExport === 'function' && (
            <>
              <Dialogs
                dialog={dialog}
                messages={messages}
                handleExport={handleExport}
                onClose={() => setDialog(void 0)}
              />
              <Tooltip
                title={messages.export_as_csv}
              >
                <div>
                  <Box
                    marginLeft={.5}
                    marginRight={.5}
                  >
                    <IconButton
                      size='small'
                      onClick={() => setDialog('EXPORT_AS_CSV')}
                    >
                      <img
                        src={csvPng}
                        alt={messages.export_as_csv}
                      />
                    </IconButton>
                  </Box>
                </div>
              </Tooltip>
            </>
          )}
          {!disableReload && (
            <Tooltip
              title={messages.refresh}
            >
              <div>
                <Box
                  marginLeft={.5}
                  marginRight={.5}
                >
                  <IconButton
                    size='small'
                    onClick={refresh}
                  >
                    <RefreshIcon/>
                  </IconButton>
                </Box>
              </div>
            </Tooltip>
          )}
          {!!showFilters && (
            <Tooltip
              title={messages.show_filters}
            >
              <div>
                <Box
                  marginLeft={.5}
                  marginRight={.5}
                >
                  <IconButton
                    size='small'
                    onClick={toggleFilters}
                  ><FilterListIcon/>
                  </IconButton>
                </Box>
              </div>
            </Tooltip>
          )}
        </div>
      </Template>
    </Plugin>
  )
}

function Dialogs(
  {
    dialog,
    onClose,
    messages,
    handleExport,
  }: {
    onClose: any;
    messages: any;
    dialog: string;
    handleExport: any;
  }
) {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleOnClick = React.useCallback(async () => {
    setLoading(true);
    try {
      await handleExport();
      setLoading(false);
      onClose();
    } catch (e) {
      setLoading(false);
      onClose()
    }
  }, [
    onClose,
    handleExport,
  ]);

  switch (dialog) {
    case 'EXPORT_AS_CSV': {
      return (
        <Dialog
          fullWidth
          maxWidth='sm'
          onClose={onClose}
          actions={{
            align  : 'center',
            actions: [
              {
                color    : 'blue',
                onClick  : onClose,
                variant  : 'outlined',
                'data-id': 'cancel-button',
                label    : messages.exportCancel,
              },
              {
                loading  : loading,
                disabled : loading,
                color    : 'success',
                variant  : 'contained',
                onClick  : handleOnClick,
                'data-id': 'agree-button',
                label    : messages.exportAgree,
              }
            ]
          }}
          content={{
            color          : 'success',
            bodyTitle      : messages.exportMainText,
            bodyDescription: messages.exportSecondaryText,
          }}
          open={'EXPORT_AS_CSV' === dialog}
          dialogTitle={{
            onClose: onClose,
            color  : 'success',
          }}
        />
      )
    }
    default: {
      return <></>
    }
  }
}
