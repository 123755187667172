import React from 'react';
import {Card} from 'antd';
import {Form} from 'antd';
import {Input} from 'antd';
import {Space} from 'antd';
import {Button} from 'antd';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {UseFormReset} from 'react-hook-form';
import {useChangePassword} from './useChangePassword';
import {Control} from 'react-hook-form/dist/types/form';

const {Item} = Form;
const {Password} = Input;

export function ChangePassword() {
  const {
    reset,
    submit,
    control,
    isSubmitting,
    show_password,
  } = useChangePassword();

  return (
    <Card
      title='Change Password'
    >
      <form
        onSubmit={submit}
        style={{maxWidth: '50rem', margin: '0 auto'}}
      >
        <Form
          component='div'
          className='form'
          layout='vertical'
        >
          {show_password && (
            <Controller
              control={control}
              name='current_password'
              render={(
                {
                  field     : {
                    ref,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                    invalid,
                  }
                }
              ) => {
                return (
                  <Item
                    help={error?.message}
                    label='Current password'
                    validateStatus={invalid ? 'error' : void 0}
                  >
                    <Password
                      ref={ref}
                      autoFocus
                      autoComplete='off'
                      {...fieldRest}
                    />
                  </Item>
                )
              }}
            />
          )}
          <Controller
            name='password'
            control={control}
            render={(
              {
                field     : {
                  ref,
                  ...fieldRest
                },
                fieldState: {
                  error,
                  invalid,
                }
              }
            ) => {
              return (
                <Item
                  help={error?.message}
                  label='New password'
                  validateStatus={invalid ? 'error' : void 0}
                >
                  <Password
                    ref={ref}
                    autoComplete='off'
                    autoFocus={!show_password}
                    {...fieldRest}
                  />
                </Item>
              )
            }}
          />
          <Actions
            reset={reset}
            control={control}
            showPassword={show_password}
            isSubmitting={isSubmitting}
          />
        </Form>
      </form>
    </Card>
  )
}

function Actions(
  {
    reset,
    control,
    showPassword,
    isSubmitting,
  }: {
    isSubmitting: boolean;
    showPassword: boolean;
    control: Control<Record<string, any>>;
    reset: UseFormReset<Record<string, any>>;
  }
) {
  const [
    password,
    current_password,
  ] = useWatch({
    control,
    name: [
      'password',
      'current_password',
    ],
  });

  return (
    <Space
      className='d-flex justify-content-end'
    >
      <Button
        type='primary'
        htmlType='submit'
        loading={isSubmitting}
        disabled={!password?.trim() || (showPassword && !current_password?.trim())}
      >Save
      </Button>
      <Button
        type='default'
        htmlType='button'
        disabled={isSubmitting || !password?.trim() || (showPassword && !current_password?.trim())}
        onClick={() => reset()}
      >Discard
      </Button>
    </Space>
  )
}
