import {combineReducers} from 'redux';
import {titles} from './titles';
import {messages} from './messages';
import {icons} from './icons';
import {images} from './images';
import {builders} from './builders';

export default combineReducers({
  builders,
  titles,
  messages,
  icons,
  images,
});
