import produce from 'immer';
import {ActionTypes} from '../../constants/automation';
import {FiniteStates} from '../../../constants/finiteStates';
import {ActionTypes as CoreActionTypes} from '../../constants/application';
import {Automations} from '../../../types/automations';
import DATE_COLUMNS from '../../../constants/dateColumns';
import {momentTZ} from '../../../utils';

//TODO: set types
const InitialState: any = {
  dataOptions: {
    pageNumber          : 1,
    pageSize            : 10,
    fetchingAutomationState : FiniteStates.IDLE,
    fetchingAutomationsState: FiniteStates.IDLE,
    sortName: 'name',
    sortOrder: 'desc',
  },
  errors: {},
};

export const automations = produce((draft, action) => {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.AUTOMATIONS_SET_DATA: {
      draft.entities = payload;
      return;
    }
    case ActionTypes.AUTOMATIONS_DELETE_DATA: {
      const filteredData = draft.entities.filter((item: Automations.Entity) => item.id !== payload);
      draft.entities = filteredData;
      return;
    }
    case ActionTypes.AUTOMATIONS_UPDATE_DATA: {
      const filteredData = draft.entities.map((item: Automations.Entity) => {
        if (item.id === payload.id) {
          const updatedAtFormat = (DATE_COLUMNS.find(dC => dC.column === 'updated_at')).format;
          item.active = payload.active;
          item.updated_at = momentTZ(payload.updated_at).format(updatedAtFormat)
        }
        return item;
      });

      draft.entities = filteredData;
      return;
    }
    case ActionTypes.AUTOMATIONS_DATA_OPTIONS: {
      if (!draft.dataOptions) {
        draft.dataOptions = {} as any;
      }
      Object.keys(payload || {}).forEach((key: string) => {
        (draft.dataOptions as any)[key] = payload[key];
      });
      return;
    }
    case ActionTypes.AUTOMATIONS_CLEAR_DATA: {
      return InitialState;
    }
    case ActionTypes.AUTOMATIONS_TOGGLE_ERRORS: {
      draft.errors = payload;
      return;
    }
    case CoreActionTypes.LOGOUT: {
      return {...InitialState}
    }
    default:
      return void 0;
  }
}, InitialState);
