import {_fetch} from '../apps/fetch';
import * as Sentry from '@sentry/react';
import {Globals} from '../../../types/globals';
import {isMobile} from '../../../utils/isMobile';
import {_appsLength} from '../../selectors/apps';
import {ANIMATION_DELAY} from '../../../constants';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {ActionTypes} from '../../constants/application';
import {UseFormSetError} from 'react-hook-form/dist/types/form';
import {RecaptchaActions} from '../../../constants/recaptchaActions';
import {IResetPasswordForm} from '../../../pages/Auth/Pages/ResetPassword/useResetPassword';

export const _resetPassword = (
  {
    message: tmpInput,
    ...restFormValues
  }: IResetPasswordForm, {
    setError,
    executeRecaptcha,
  }: {
    setError: UseFormSetError<IResetPasswordForm>;
    executeRecaptcha: (action?: string) => Promise<string>;
  }): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  let token: string | undefined = void 0;

  if (typeof executeRecaptcha === 'function') {
    token = await executeRecaptcha(RecaptchaActions.PASSWORD_RESET_TOKEN);
  }

  const {
    body: {
      data,
      message,
    },
    hasError,
  } = await api.post(ApiRoutes.PASSWORD_RESET_TOKEN, {
    ...restFormValues,
    'g-recaptcha-response': token || 'token',
  });

  if (hasError) {
    if (Object.keys(data).length) {
      Object.keys(data).forEach((inputName) => {
        setError(inputName as keyof IResetPasswordForm, {
          message: data[inputName],
        });
      });
    } else {
      setError('message', {
        message,
      });
    }

    Sentry.setExtra('resetPassword', {data, message});
    Sentry.captureException({data, message});
  } else {
    let token: string | undefined = void 0;

    if (typeof executeRecaptcha === 'function') {
      token = await executeRecaptcha(RecaptchaActions.LOGIN);
    }

    const {
      body: {
        data,
      },
      hasError,
    } = await api.post(ApiRoutes.LOGIN, {
      remember              : true,
      'g-recaptcha-response': token,
      email                 : restFormValues.email,
      password              : restFormValues.password,
    });
    if (!hasError) {
      setTimeout(async function () {
        dispatch({
          payload: {
            authenticated: true,
            accessToken  : data.access_token,
          },
          type   : ActionTypes.AUTHENTICATE,
        });
        await dispatch(_fetch());

        const mobile = isMobile();
        const appsLength = _appsLength(getState());

        if (mobile && !!appsLength) {
          dispatch({
            payload: mobile,
            type   : ActionTypes.IS_MOBILE,
          });
        }
      }, 2 * ANIMATION_DELAY);
    }
  }
};
