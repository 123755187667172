export enum ActionTypes {
  LOGOUT                 = 'application/LOGOUT',
  IS_MOBILE              = 'application/IS_MOBILE',
  SUSPENDED              = 'application/SUSPENDED',
  EMPTY_FORM             = 'application/EMPTY_FORM',
  UPSERT_USER            = 'application/UPSERT_USER',
  SET_APP_ENV            = 'application/SET_APP_ENV',
  UPSERT_FORM            = 'application/UPSERT_FORM',
  UNAUTHORIZED           = 'application/UNAUTHORIZED',
  AUTHENTICATE           = 'application/AUTHENTICATE',
  BEFORE_UNLOAD          = 'application/BEFORE_UNLOAD',
  UPSERT_SURVEY          = 'application/UPSERT_SURVEY',
  UPSERT_APPLICATION     = 'application/UPSERT_APPLICATION',
  UPSERT_SIDEBAR_DATA    = 'application/UPSERT_SIDEBAR_DATA',
  SET_APPLICATION_STATUS = 'application/SET_APPLICATION_STATUS',
}
