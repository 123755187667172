import React from 'react';
import Chart from './Chart';
import {useSelector} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {_hasConversionsAccess} from '../../../../../../../store/selectors/application/acl';

const useStyles = makeStyles((theme: Theme) => ({
  root           : {
    marginBottom: theme.spacing(1),
  },
  cardHeader     : {
    fontSize       : 16,
    fontWeight     : 500,
    textAlign      : 'start',
    padding        : theme.spacing(1, 2),
    color          : theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    border         : '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom   : 'none',
  },
  chartDivider   : {
    width          : 2,
    height         : 'auto',
    backgroundColor: 'rgb(216, 216, 216)'
  },
  chartsContainer: {
    flexGrow       : 1,
    display        : 'flex',
    backgroundColor: 'white',
    flexWrap       : 'wrap',
  },
  chartRoot      : {
    flexGrow                      : 1,
    width                         : 320,
    minWidth                      : 320,
    flexDirection                 : 'column',
    position                      : 'relative',
    display                       : 'inline-flex',
    [theme.breakpoints.down('xs')]: {
      width   : 270,
      minWidth: 270,
    },
  },
}));

export default function ChartContainer(
  {
    total,
    stats,
    isWelcomeMessage,
  }: {
    total: any;
    stats: any;
    isWelcomeMessage?: boolean;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();

  const hasConversionsAcl = useSelector(_hasConversionsAccess);

  const active = Number(stats?.delivery?.active?.value) || 0;
  const errors = Number(stats?.delivery?.errors?.value) || 0;
  const pending = Number(stats?.delivery?.pending?.value) || 0;
  const inactive = Number(stats?.delivery?.inactive?.value) || 0;
  const unsubscribed = Number(stats?.delivery?.unsubscribed?.value) || 0;

  const converted = Number(stats?.conversions?.converted?.value) || 0;
  const not_converted = Number(stats?.conversions?.not_converted?.value) || 0;

  const closed = Number(stats?.actions?.closed?.value) || 0;
  const clicked = Number(stats?.actions?.clicked?.value) || 0;
  const not_clicked = Number(stats?.actions?.not_clicked?.value) || 0;

  return (
    <div
      className={classes.root}
    >
      <Typography
        className={classes.cardHeader}
      >{!isWelcomeMessage ? t('campaigns.statistics_title') : 'Welcome Message Statistics'}
      </Typography>
      <Typography
        component='div'
        className={classes.chartsContainer}
      >
        <Paper
          square
          variant='outlined'
          className={classes.chartRoot}
        >
          <Chart
            chartData={[
              {
                y    : active,
                color: '#81B214',
                name : !isWelcomeMessage ? t('campaigns.chart_active', {
                  percent: ((active * 100) / (total || 1)).toFixed(2),
                }) : `Delivered ${((active * 100) / (total || 1)).toFixed(2)}%`,
              },
              {
                y    : inactive,
                color: '#0A0D2E',
                name : !isWelcomeMessage ? t('campaigns.chart_inactive', {
                  percent: ((inactive * 100) / (total || 1)).toFixed(2),
                }) : `Not Delivered ${((inactive * 100) / (total || 1)).toFixed(2)}%`,
              },
              {
                y    : pending,
                color: '#8ECAE6',
                name : t('campaigns.chart_pending', {
                  percent: ((pending * 100) / (total || 1)).toFixed(2),
                }),
              },
              {
                color: '#E8505B',
                y    : unsubscribed,
                name : t('campaigns.chart_unsubscribed', {
                  percent: ((unsubscribed * 100) / (total || 1)).toFixed(2),
                }),
              },
              {
                y    : errors,
                color: '#F5651F',
                name : t('campaigns.chart_errors', {
                  percent: ((errors * 100) / (total || 1)).toFixed(2),
                }),
              },
            ]}
            innerItemColor='#18CF73'
            title={!isWelcomeMessage ? t('campaigns.status') : 'Delivery'}
            innerItem={
              <InnerItem
                value={`${((active * 100) / (total || 1)).toFixed(2)}%`}
                label={!isWelcomeMessage ? t('campaigns.active') : 'Delivered'}
              />
            }
          />
        </Paper>
        <Paper
          square
          variant='outlined'
          className={classes.chartRoot}
        >
          <Chart
            chartData={[
              {
                y    : clicked,
                color: '#81B214',
                name : t('campaigns.chart_clicked', {
                  percent: ((clicked * 100) / (active || 1)).toFixed(2),
                }),
              },
              {
                y    : closed,
                color: '#A2C556',
                name : t('campaigns.chart_closed', {
                  percent: ((closed * 100) / (active || 1)).toFixed(2),
                }),
              },
              {
                color: '#C6D99A',
                y    : not_clicked,
                name : t('campaigns.chart_not_clicked', {
                  percent: ((not_clicked * 100) / (active || 1)).toFixed(2),
                }),
              },
            ]}
            innerItemColor='#00BE5F'
            title={t('campaigns.actions')}
            innerItem={
              <InnerItem
                label={t('campaigns.clicked')}
                value={`${((clicked * 100) / (active || 1)).toFixed(2)}%`}
              />
            }
          />
        </Paper>

        {hasConversionsAcl && (
          <Paper
            square
            variant='outlined'
            className={classes.chartRoot}
          >
            <Chart
              chartData={[
                {
                  color: '#81B214',
                  y    : converted,
                  name : t('campaigns.chart_converted', {
                    percent: ((converted * 100) / (clicked || 1)).toFixed(2),
                  }),
                },
                {
                  color: '#C6D99A',
                  y    : not_converted,
                  name : t('campaigns.chart_not_converted', {
                    percent: ((not_converted * 100) / (clicked || 1)).toFixed(2),
                  }),
                },
              ]}
              innerItemColor='#37BB79'
              title={t('campaigns.conversions')}
              innerItem={
                <InnerItem
                  label={t('campaigns.converted')}
                  value={`${((converted * 100) / (clicked || 1)).toFixed(2)}%`}
                />
              }
            />
          </Paper>
        )}
      </Typography>
    </div>
  )
}

function InnerItem(
  {
    label,
    value,
  }: {
    label: string;
    value: string;
  }
) {
  return (
    <>
      <span>{label}</span>
      <span>{value}</span>
    </>
  )
}
