import {notification} from 'antd';
import {_setPortal} from './index';
import {_setDowngrade} from './index';
import {Globals} from '../../../types/globals';
import {ApiRoutes} from '../../../constants/apiRoutes';

export const _cancelDowngrade = (): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_setDowngrade({
    loading: true,
  }));

  const {
    hasError,
    body: {
      data: {
        message,
      },
    },
  } = await api.post(ApiRoutes.CANCEL_SCHEDULE);
  notification.open({
    message,
    type: hasError ? 'error' : 'success',
  });
  dispatch(_setDowngrade({
    loading: void 0,
  }));
  dispatch(_setPortal({
    scheduled_plan: void 0,
  }));
};