import React from 'react';
import Clone from './Pages/Clone';
import {Route} from 'react-router';
import {Switch} from 'react-router';
import {useParams} from 'react-router';
import CampaignInfo from './Pages/Info';
import {Redirect} from 'react-router-dom';
import {PushNotifications} from './Pages';
import CreateOrUpdate from './Pages/CreateOrUpdate';
import {urlHelper} from '../../../../utils/urlHelper';
import {AppRoutes} from '../../../../constants/appRoutes';

export function PushNotificationRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={PushNotifications}
        path={AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS}
      />
      <Route
        exact
        component={CreateOrUpdate}
        path={AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_CREATE}
      />
      <Route
        exact
        component={CreateOrUpdate}
        path={AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_EDIT}
      />
      <Route
        exact
        component={CampaignInfo}
        path={AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_INFO}
      />
      <Route
        exact
        component={Clone}
        path={AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_CLONE}
      />
      <Route
        component={RedirectTo}
        path={AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS}
      />
    </Switch>
  )
}

function RedirectTo() {
  const {appUuid} = useParams<{ appUuid: string }>();

  return (
    <Redirect
      to={urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid})}
    />
  )
}
