import {getState} from '../index';
import {getSmartPush} from '../index';
import {createSelector} from 'reselect';
import {emptyObject} from '../../../constants';

export const getTemplates = createSelector(getSmartPush, (smartPush) => {
  return smartPush.templates;
});

export const getData = createSelector(getTemplates, (templates) => {
  return templates.data;
});

export const getById = createSelector(getData, (data) => {
  return data.byId;
});

export const getAllIds = createSelector(getData, (data) => {
  return data.allIds;
});

export const getTemplate = createSelector(getData, (data) => {
  return data.entity;
});

export const getTemplateLang = createSelector(getTemplate, (data) => {
  return data?.messages;
});

export const getTemplateDefaultLang = createSelector(getTemplate, getTemplateLang, (entity, data) => {
  return {
    icon_url : entity?.icon_url,
    image_url: entity?.image_url,
    ...data?.default,
  };
});

export const getMessage = createSelector(getData, (data) => {
  return data.message;
});

export const getIsLoading = createSelector(getData, (data) => {
  return data.loading;
});

export const getErrors = createSelector(getData, (data) => {
  return data.errors || emptyObject;
});

export const getDataOptions = createSelector(getData, (data) => {
  return data.dataOptions || emptyObject;
});

export const getActionDialogObj = createSelector(getData, (data) => {
  return data.actionDialog;
});

export const getActionDialog = createSelector(getActionDialogObj, (actionDialog) => {
  return actionDialog?.dialog;
});

export const getActionDialogData = createSelector(getActionDialogObj, (actionDialog) => {
  return actionDialog?.data;
});

export const getOptions = createSelector(getTemplates, (templates) => {
  return templates.options;
});

export const getLastQuery = createSelector(getOptions, (options) => {
  return options.lastQuery;
});

//////////////////////////

const _templatesReducer = createSelector(getState, (application) => {
  return application.templates;
});

export const _template = createSelector(_templatesReducer, (templates) => {
  return templates.entity;
});

export const _templates = createSelector(_templatesReducer, (templates) => {
  return templates.entities;
});

export const _dataOptions = createSelector(_templatesReducer, (templates) => {
  return templates.dataOptions;
});

export const _total = createSelector(_dataOptions, (options) => {
  return options.total;
});

export const _sorterOrder = createSelector(_dataOptions, (options) => {
  return options.sorterOrder;
});

export const _sorterField = createSelector(_dataOptions, (options) => {
  return options.sorterField;
});

export const _pageSize = createSelector(_dataOptions, (options) => {
  return options.pageSize;
});

export const _tags = createSelector(_dataOptions, (options) => {
  return options.tags;
});

export const _tagsArray = createSelector(_templatesReducer, (templates) => {
  return templates.tagsArray;
});

export const _templateDetails = createSelector(_templatesReducer, (templates) => {
  return templates.entityDetails;
});

export const _pageNumber = createSelector(_dataOptions, (options) => {
  return options.pageNumber;
});

export const _detailsPageNumber = createSelector(_dataOptions, (options) => {
  return options.detailsPageNumber;
});

export const _detailsTotal = createSelector(_dataOptions, (options) => {
  return options.detailsTotal;
});

export const _detailsSorterField = createSelector(_dataOptions, (options) => {
  return options.detailsSorterField;
});

export const _detailsSorterOrder = createSelector(_dataOptions, (options) => {
  return options.detailsSorterOrder;
});

export const _searchText = createSelector(_dataOptions, (options) => {
  return options.searchText;
});

export const _hasMoreTags = createSelector(_dataOptions, (options) => {
  return options.hasMoreTags;
});

export const _tagsPageNumber = createSelector(_dataOptions, (options) => {
  return options.tagsPageNumber;
});

export const _tagsSearchText = createSelector(_dataOptions, (options) => {
  return options.tagsSearchText;
});

export const _fetchingTemplateState = createSelector(_dataOptions, (options) => {
  return options.fetchingTemplateState;
});

export const _fetchingTemplatesState = createSelector(_dataOptions, (options) => {
  return options.fetchingTemplatesState;
});

export const _exportTemplatesState = createSelector(_dataOptions, (options) => {
  return options.exportTemplatesState;
});

export const _fetchingTemplateTagsState = createSelector(_dataOptions, (options) => {
  return options.fetchingTemplateTagsState;
});

export const _fetchingTemplateDetailsState = createSelector(_dataOptions, (options) => {
  return options.fetchingTemplateDetailsState;
});
