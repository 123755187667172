import {notification} from 'antd';
import {_dataOptions} from './index';
import {Apps} from '../../../types/apps';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {_currentAppTitle} from '../../selectors/apps';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _downloadDocs = (
  {
    appUuid,
    docType,
  }: {
    appUuid: string;
    docType?: Apps.DocType
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    isDownloadingDoc: FiniteStates.LOADING,
  }));

  const {
    body: {
      data,
      message,
    },
    hasError,
  } = await api.get(urlHelper(ApiRoutes.DOCS_DOWNLOAD, {
    appUuid,
    guide: docType || '',
  }), {
    responseType: 'arraybuffer'
  });

  if (!hasError) {
    const title = _currentAppTitle(getState());
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    link.href = url;
    if (docType === 'wp') {
      link.setAttribute('download', `${title} - WordPress-Guide.zip`);
    } else if (docType === 'gtm') {
      link.setAttribute('download', `${title} - GTM-Guide.zip`);
    } else {
      link.setAttribute('download', `${title} - Integration-Guides.zip`);
    }
    document.body.appendChild(link);

    link.click();

    window.URL.revokeObjectURL(url);

    dispatch(_dataOptions({
      isDownloadingDoc: FiniteStates.SUCCESS,
    }));
  } else {
    notification.error({
      message: message || 'Something went wrong',
    });

    dispatch(_dataOptions({
      isDownloadingDoc: FiniteStates.FAILURE,
    }));
  }
};