import React from 'react';
import {Modal} from 'antd';
import {Button} from 'antd';
import {Text} from '../Typography';
import {Title} from '../Typography';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import display from '../../../images/display.gif';
import {getPrefixCls} from '../../../utils/getPrefixCls';
import {_isMobile} from '../../../store/selectors/application';
import {ActionTypes} from '../../../store/constants/application';
import './index.less';

export function IsMobile() {
  const dispatch = useDispatch();
  const isMobile = useSelector(_isMobile);

  const continueToMobile = React.useCallback(() => {
    localStorage.setItem('show_recommendation', Math.random().toString(36).substring(7));
    dispatch({
      payload: false,
      type   : ActionTypes.IS_MOBILE,
    })
  }, [
    dispatch,
  ]);

  if (localStorage.getItem('show_recommendation')) {
    return null;
  }

  if (!isMobile) {
    return null
  }

  return (
    <div
      className={getPrefixCls('is-mobile-container')}
    >
      <Modal
        visible
      />
      <img
        width='100%'
        alt='Display'
        src={display}
        className={getPrefixCls('is-mobile-animation')}
      />
      <div
        className='m-b-6'
      >
        <Title
          level={4}
          align='center'
          fontWeight='bold'
        >Try SmartPush on Desktop
        </Title>
      </div>
      <div
        className='m-b-6'
      >
        <Title
          level={5}
          align='center'
          fontWeight='regular'
        >SmartPush’s interface and features are better experienced on desktop browsers.
        </Title>
      </div>
      <div
        className='m-b-6'
      >
        <Title
          level={5}
          align='center'
          fontWeight='regular'
        >Just open <Text className={getPrefixCls('is-mobile-app-link')} fontWeight='bold'>app.smartpush.ai</Text> on preferred desktop browser and continue!
        </Title>
      </div>
      <div
        className='d-flex align-items-center justify-content-center'
      >
        <Button
          ghost
          size='large'
          type='primary'
          onClick={continueToMobile}
          className={getPrefixCls('continue-with-mobile-button')}
        >Continue with mobile version
        </Button>
      </div>
    </div>
  )
}
