import {Prompt} from '../../../types/prompt';
import {ActionTypes} from '../../constants/prompt';

export const _reset = () => ({
  type: ActionTypes.PROMPT_RESET,
});

export const _dataOptions = (options: Partial<Prompt.DataOptions>) => ({
  payload: options,
  type   : ActionTypes.PROMPT_DATA_OPTIONS,
});

export const _upsertEntity = (entity?: Partial<Prompt.Entity>) => ({
  payload: entity,
  type   : ActionTypes.PROMPT_UPSERT_ENTITY,
});