import logo from '../../../../images/antd/logo.svg';
import {getPrefixCls} from '../../../../utils/getPrefixCls';

export function Header() {
  return (
    <div
      className={getPrefixCls('survey-header')}
    >
      <img
        alt='Logo'
        src={logo}
        width={155}
        height={32}
      />
    </div>
  )
}