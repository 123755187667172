import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {SearchPanel} from '@devexpress/dx-react-grid-material-ui';

export function SearchPanelInputComponent(
  {
    value,
    getMessage,
    onValueChange,
  }: SearchPanel.InputProps
) {
  const [localValue, setLocalValue] = React.useState<string | undefined>(value || void 0);

  const onChange = React.useCallback((e) => {
    setLocalValue(e.target.value);
    if (!e.target.value) {
      onValueChange('');
    }
  }, [
    onValueChange,
  ]);

  const onKeyPress = React.useCallback((e) => {
    if (e.key === 'Enter') {
      onValueChange(localValue || '');
    }
  }, [
    localValue,
    onValueChange,
  ]);

  React.useEffect(() => {
    setLocalValue(value)
  }, [
    value
  ]);

  return (
    <Input
      margin='dense'
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={localValue || ''}
      placeholder={getMessage('searchPlaceholder')}
      endAdornment={
        <InputAdornment
          position='end'
        >
          <IconButton
            edge='end'
            size='small'
            onClick={() => onValueChange(localValue || '')}
          >
            <SearchIcon/>
          </IconButton>
        </InputAdornment>
      }
    />
  )
}
