import React from 'react';
import {Drawer} from 'antd';
import {Layout} from 'antd';
import {SiderMenu} from '../SiderMenu';
import {useSelector} from 'react-redux';
import {SiderTrigger} from '../SiderTrigger';
import useMediaQuery from 'use-media-antd-query';
import logo from '../../../../../images/antd/logo.svg';
import {getPrefixCls} from '../../../../../utils/getPrefixCls';
import {_appsLength} from '../../../../../store/selectors/apps';
import './index.less';

const layoutSiderStyle: React.CSSProperties = {
  height: '100%',
};

const drawerBodyStyle: React.CSSProperties = {
  padding      : 0,
  flexDirection: 'row',
  display      : 'flex',
  height       : '100vh',
};

export function LayoutSider(
  {
    appUuid,
    toggleDrawer,
    drawerVisible,
  }: {
    appUuid: string;
    drawerVisible: boolean;
    toggleDrawer: React.Dispatch<boolean>;
  }
) {
  const colSize = useMediaQuery();
  const apps = useSelector(_appsLength);

  const [collapsed, toggle] = React.useState<boolean>(false);

  React.useEffect(() => {
    toggleDrawer(false);
    if ([
      'sm',
      'xs',
    ].includes(colSize)) {
      toggle(true);
    } else if ([
      'xl',
      'lg',
      'xxl',
    ].includes(colSize)) {
      toggle(false);
    }
  }, [
    colSize,
    toggleDrawer,
  ]);

  const LayoutSider = React.useCallback((
    {
      style,
      toggle,
      trigger,
      collapsed,
      className,
    }
  ) => {
    return (
      <Layout.Sider
        collapsible
        width={200}
        theme='light'
        style={style}
        trigger={trigger}
        collapsed={collapsed}
        className={className}
      >
        {[
          'xs',
          'sm',
        ].includes(colSize) && (
          <span
            className='p-2 d-flex'
          >
            <img
              src={logo}
              width='100%'
              alt='Smart Push Logo'
            />
          </span>
        )}
        <SiderMenu
          appUuid={appUuid}
          collapsed={collapsed}
          toggleDrawer={toggle}
        />
      </Layout.Sider>
    )
  }, [
    appUuid,
    colSize,
  ]);

  return !!apps ? (
    (['xs', 'sm'].includes(colSize)) ? (
      <Drawer
        width={200}
        placement='left'
        closeIcon={null}
        visible={drawerVisible}
        bodyStyle={drawerBodyStyle}
        className={getPrefixCls('drawer')}
        onClose={() => toggleDrawer(false)}
      >
        <LayoutSider
          trigger={null}
          style={layoutSiderStyle}
          toggle={() => toggleDrawer(false)}
        />
      </Drawer>
    ) : (
      <LayoutSider
        collapsed={collapsed}
        className='layout-sider'
        trigger={<SiderTrigger collapsed={collapsed} toggle={toggle}/>}
      />
    )
  ) : null;
}
