import {Moment} from 'moment';
import {_setData} from './index';
import {_dataOptions} from './index';
import {CancelTokenSource} from 'axios';
import {AbortError} from '../../../../constants';
import {Globals} from '../../../../types/globals';
import {urlHelper} from '../../../../utils/urlHelper';
import {ApiRoutes} from '../../../../constants/apiRoutes';
import {FiniteStates} from '../../../../constants/finiteStates';

export const _fetch = (
  appUuid: string,
  dates: [Moment, Moment],
  {
    cancelTokenSource
  }: {
    cancelTokenSource?: CancelTokenSource
  } = {}
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    fetchingState: FiniteStates.LOADING
  }));

  const to = dates[1].format('YYYY-MM-DD');
  const from = dates[0].format('YYYY-MM-DD');

  const {
    hasError,
    body: {
      data,
      name,
    },
  } = await api.get(`${urlHelper(ApiRoutes.DAILY_INACTIVE, {
    appUuid,
  })}?to=${to}&from=${from}`, {
    cancelToken: cancelTokenSource?.token,
  });

  if (!hasError) {
    dispatch(_setData(data));

    dispatch(_dataOptions({
      fetchingState: FiniteStates.SUCCESS,
    }));
  } else {
    if (name === AbortError) {
      dispatch(_setData([]));
      dispatch(_dataOptions({
        fetchingState: FiniteStates.IDLE,
      }))
    } else {
      dispatch(_dataOptions({
        messages     : data,
        fetchingState: FiniteStates.FAILURE,
      }))
    }
  }
};