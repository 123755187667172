import React from 'react';
import {Tab} from '../Tabs';
import {Tabs} from '../Tabs';
import {Button} from '../Button';
import {TabPanel} from '../Tabs';
import {TextField} from '../Input';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import ListContainer from './ListContainer';
import Typography from '@material-ui/core/Typography';
import {fetchAction} from '../../store/actions/gallery';
import MultipleFilesUpload from '../MultipleFilesUpload';
import {optionsAction} from '../../store/actions/gallery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {getDataOptions} from '../../store/selectors/gallery';
import {dataOptionsAction} from '../../store/actions/gallery';
import {toggleErrorsAction} from '../../store/actions/gallery';

const useStyles = makeStyles(() => ({
  rootContainer: {
    display        : 'flex',
    flexDirection  : 'column',
    backgroundColor: '#fafafa',
    minHeight      : 'calc(100vh - 250px)',
  },
  tabsRoot     : {
    '& div.MuiTabs-scrollable': {
    },
  },
  tabRoot      : {
    border                   : 'none',
    '& > span.MuiTab-wrapper': {
      textTransform: 'unset',
    }
  },
}));

const tabPanelStyles: React.CSSProperties = {
  flexGrow     : 1,
  marginBottom : 0,
  display      : 'flex',
  flexDirection: 'column',
};

const itemWidth = 200;
const itemMargins = 8 * 2;

export default function Gallery(
  {
    onClose,
    onClick,
    selected,
    aspectRatio,
  }: {
    onClose?: any;
    selected?: any;
    aspectRatio: number;
    onClick?: (selected: any) => any;
  }
) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const ref = React.createRef<any>();

  const [width, setWidth] = React.useState<any>('100%');
  const [value, setValue] = React.useState<number>(!!selected ? 2 : 1);

  React.useEffect(() => {
    function resize() {
      if (ref && ref.current) {
        let m: number = 7;
        while (((itemWidth + itemMargins) * m) > ref.current.offsetWidth) {
          m--;
        }
        setWidth((itemWidth + itemMargins) * m);
      }
    }

    resize();

    window.removeEventListener('resize', resize);
    window.addEventListener('resize', resize);

    return function cleanup() {
      window.removeEventListener('resize', resize);
    }
  }, [
    ref,
  ]);

  React.useEffect(() => {
    if (value === 1) {
      dispatch(optionsAction({
        pageNumber: 1,
        searchText: void 0,
      }));

      dispatch(dataOptionsAction({
        isDeleting           : void 0,
        isUploading          : void 0,
        uploaderIsOpen       : void 0,
        disable_upload_button: void 0,
      }));

      dispatch(toggleErrorsAction(void 0));
    }
  }, [
    value,
    dispatch,
  ]);

  const {
    uploaderIsOpen,
  } = useSelector(getDataOptions);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Typography
      ref={ref}
      component='div'
      className={classes.rootContainer}
    >
      <Tabs
        value={value}
        scrollButtons='off'
        variant='fullWidth'
        onChange={handleChange}
        className={classes.tabsRoot}
      >
        <Tab
          value={1}
          disableRipple
          label='UPLOAD'
          textColor='primary'
          className={classes.tabRoot}
        />
        <Tab
          value={2}
          disableRipple
          textColor='primary'
          className={classes.tabRoot}
          label='CHOOSE FROM GALLERY'
        />
      </Tabs>
      <TabPanel
        index={1}
        value={value}
        boxProps={{style: tabPanelStyles}}
        typographyProps={{style: tabPanelStyles}}
        style={(value === 1) ? tabPanelStyles : void 0}
      >
        <>
          <MultipleFilesUpload
            onSuccess={(medias) => {
              if (medias?.length) {
                setTimeout(() => {
                  setValue(2)
                }, 0)
                dispatch(optionsAction({
                  pageNumber: 1,
                  searchText: void 0,
                }));
                dispatch(fetchAction());
              }
            }}
            onStartUploading={() => {
            }}
          />
          <LinkTextField
            onClose={onClose}
            onClick={onClick}
          />
        </>
      </TabPanel>
      <TabPanel
        index={2}
        value={value}
        style={(value === 2) ? tabPanelStyles : void 0}
      >
        <ListContainer
          width={width}
          onClick={onClick}
          selected={selected}
          onCloseGallery={onClose}
          aspectRatio={aspectRatio}
          uploaderIsOpen={uploaderIsOpen}
        />
      </TabPanel>
    </Typography>
  );
}

function LinkTextField(
  {
    onClose,
    onClick,
  }: any
) {
  const [value, setValue] = React.useState<any>('')
  return (
    <div
      style={{
        marginTop     : 0,
        paddingLeft   : 24,
        paddingRight  : 24,
        display       : 'flex',
        textAlign     : 'center',
        alignItems    : 'center',
        flexDirection : 'column',
        justifyContent: 'center',
      }}
    >
      <Typography
        color='primary'
        style={{
          flexGrow      : 1,
          fontSize      : 20,
          marginBottom  : 12,
          fontWeight    : 500,
          width         : '100%',
          display       : 'flex',
          justifyContent: 'center',
          alignItems    : 'center',
        }}
      >
        <div
          style={{
            flexGrow       : 1,
            marginRight    : 8,
            height         : 1.5,
            display        : 'flex',
            backgroundColor: '#dee2e6',
          }}
        />
        <span
          style={{
            display: 'block',
            width  : 'max-content',
          }}
        >or paste a link
        </span>
        <div
          style={{
            flexGrow       : 1,
            marginLeft     : 8,
            height         : 1.5,
            display        : 'flex',
            backgroundColor: '#dee2e6',
          }}
        />
      </Typography>
      <TextField
        fullWidth
        size='small'
        disableMargin
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <Button
              color='primary'
              disabled={!value}
              variant='contained'
              style={{paddingTop: 5, marginRight: -13}}
              onClick={() => {
                onClick(value);
                onClose();
              }}
            >Select
            </Button>
          )
        }}
      />
    </div>
  )
}
