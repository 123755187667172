import React from 'react';
import {Select} from 'antd';
import {useAllApps} from './useAllApps';
import {Link} from '../../../../../Typography';
import {Text} from '../../../../../Typography';
import {isUUID} from '../../../../../../../utils/isUUID';
import {AppRoutes} from '../../../../../../../constants/appRoutes';
import {getPrefixCls} from '../../../../../../../utils/getPrefixCls';
import {FiniteStates} from '../../../../../../../constants/finiteStates';

export function AllApps(
  {
    appUuid
  }: {
    appUuid: string | undefined;
  }
) {
  const {
    push,
    apps,
    options,
    onChange,
    isMobile,
    selectStyle,
    filterOption,
    isCreatingApp,
    dropdownRender,
    temporaryTitle,
    selectOnSearch,
    fetchingAppState,
    fetchingAppsState,
    onDropdownVisibleChange,
  } = useAllApps({appUuid});

  if (fetchingAppState === FiniteStates.LOADING) {
    return (
      <Text
        fontWeight='regular'
        ellipsis={{tooltip: temporaryTitle || ''}}
        className={getPrefixCls('mainLayout-header-allApps-newAppText')}
      >Getting App...
      </Text>
    )
  }

  if (!isUUID(appUuid)) {
    return isCreatingApp ? (
      <>
        <Text
          fontWeight='regular'
          ellipsis={{tooltip: temporaryTitle || ''}}
          className={getPrefixCls('mainLayout-header-allApps-newAppText')}
        >{temporaryTitle || 'New App'}
        </Text>
      </>
    ) : (
      <Link
        onClick={() => {
          if (typeof document !== undefined) {
            document.body.style.overflow = 'initial';
          }
          push(AppRoutes.APPS_CREATE);
        }}
      >New App
      </Link>
    )
  }

  return (
    <div
      className={isMobile ? '' : getPrefixCls('mainLayout-header-allAppsSelectContainer')}
    >
      {isCreatingApp ? (
        <Text
          fontWeight='regular'
          ellipsis={{tooltip: temporaryTitle || ''}}
          className={getPrefixCls('mainLayout-header-allApps-newAppText')}
        >{temporaryTitle || 'New App'}
        </Text>
      ) : (
        <Select
          showSearch
          bordered={false}
          style={selectStyle}
          onChange={onChange}
          optionFilterProp='title'
          onSearch={selectOnSearch}
          filterOption={filterOption}
          placeholder='Getting Apps...'
          dropdownRender={dropdownRender}
          dropdownStyle={{minWidth: '20rem'}}
          value={!!apps?.length ? appUuid : null}
          onDropdownVisibleChange={onDropdownVisibleChange}
          loading={fetchingAppsState === FiniteStates.LOADING}
          className={getPrefixCls('mainLayout-header-allAppsSelectContainer-allAppsSelect')}
        >{options}
        </Select>
      )}
    </div>
  )
}
