import React from 'react';
import {Link} from 'react-router-dom';
import useMediaQuery from 'use-media-antd-query';
import logo from '../../../../../../../images/antd/logo.svg';
import logoXS from '../../../../../../../images/antd/logoXS.svg';
import {AppRoutes} from '../../../../../../../constants/appRoutes';

export function Logo(
  {
    appUuid
  }: {
    appUuid: string | undefined;
  }
) {
  const colSize = useMediaQuery();

  return (
    <div>
      <Link
        to={(appUuid === undefined) ? AppRoutes.APPS_CREATE : '/'}
        className='d-flex justify-content-center align-items-center m-t-1'
      >
        {[
          'sm',
          'xs',
        ].includes(colSize) ? (
          <img
            width={36}
            height={36}
            src={logoXS}
            alt='Smart Push Logo'
          />
        ) : (
          <img
            src={logo}
            height={36}
            width={175}
            alt='Smart Push Logo'
          />
        )}
      </Link>
    </div>
  )
}
