export enum aclKeys {
  APPS             = 'apps',
  USERS            = 'users',
  BUILDER          = 'builder',
  API_KEYS         = 'api-keys',
  SEGMENTS         = 'segments',
  CAMPAIGNS        = 'campaigns',
  DASHBOARD        = 'dashboard',
  TEMPLATES        = 'templates',
  AUTOMATIONS      = 'automations',
  SUBSCRIBERS      = 'subscribers',
  APP_SETTINGS     = 'app-settings',
  DOCUMENTATION    = 'documentation',
  WELCOME_MESSAGES = 'welcome-messages',
}

export enum orderedAclKeys {
  APPS             = 'apps',
  APP_SETTINGS     = 'app-settings',
  LOCALES          = 'locales',
  WEBHOOKS         = 'webhooks',
  WELCOME_MESSAGES = 'welcome-messages',
  DASHBOARD        = 'dashboard',
  DOCUMENTATION    = 'documentation',
  SUBSCRIBERS      = 'subscribers',
  CAMPAIGNS        = 'campaigns',
  TEMPLATES        = 'templates',
  SEGMENTS         = 'segments',
  AUTOMATIONS      = 'automations',
  BUILDER          = 'builder',
  USERS            = 'users',
  API_KEYS         = 'api-keys',
}