import React from 'react';
import Widget from './Widget';
import Gadget from './Gadget';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import AndroidIcon from '@material-ui/icons/Android';
import Typography from '@material-ui/core/Typography';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import WebRoundedIcon from '@material-ui/icons/WebRounded';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import {_currentApp} from '../../../../../store/selectors/apps';
import PublicRoundedIcon from '@material-ui/icons/PublicRounded';
import {_currentAppStats} from '../../../../../store/selectors/apps';
import {convertTimezone} from '../../../../../utils/convertTimezone';
import LanguageRoundedIcon from '@material-ui/icons/LanguageRounded';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';

const useStyles = makeStyles((theme: Theme) => ({
  paperRoot       : {
    display                       : 'flex',
    flexWrap                      : 'wrap',
    padding                       : theme.spacing(2),
    marginBottom                  : theme.spacing(4),
    '& > div'                     : {
      width: '20%'
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      '& > div'   : {
        width: '50%'
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& > div': {
        width: '100%'
      },
    },
  },
  widgetsContainer: {
    display                       : 'flex',
    justifyContent                : 'center',
    marginTop                     : theme.spacing(3),
    marginBottom                  : theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexWrap    : 'wrap',
      marginTop   : theme.spacing(0),
      marginBottom: theme.spacing(1),
      '& > .MuiPaper-root'            : {
        marginTop   : theme.spacing(1),
      },
    },
  },
}));

export default React.memo(() => {
  const classes = useStyles();
  const {t} = useTranslation();
  const currentApp = useSelector(_currentApp);
  const stats = useSelector(_currentAppStats);

  const top10Os = React.useMemo(() => {
    return stats?.os?.slice(0, 10) || [];
  }, [
    stats,
  ]);

  const top10Browser = React.useMemo(() => {
    return stats?.browser_with_version?.slice(0, 10) || [];
  }, [
    stats,
  ]);

  const top10Languages = React.useMemo(() => {
    return stats?.languages?.slice(0, 10) || [];
  }, [
    stats,
  ]);

  const top10Countries = React.useMemo(() => {
    return stats.countries?.slice(0, 10) || [];
  }, [
    stats,
  ]);

  const top10Timezones = React.useMemo(() => {
    return (stats.time_zones?.slice(0, 10) as any || []).map((i: any) => ({
      count: i.count,
      name : convertTimezone(i.name),
    }))
  }, [
    stats,
  ]);

  const mobile = stats?.devices?.find((i: any) => String(i.name).toLowerCase() === 'mobile')?.count || 0;
  const tablet = stats?.devices?.find((i: any) => String(i.name).toLowerCase() === 'tablet')?.count || 0;
  const desktop = stats?.devices?.find((i: any) => String(i.name).toLowerCase() === 'desktop')?.count || 0;

  const other = (Number(currentApp?.active_users) || 0) - mobile - tablet - desktop;

  return (
    <Typography
      component='div'
    >
      <Typography
        component='div'
        className={classes.widgetsContainer}
      >
        <Widget
          value={mobile}
          label={t('subscribers.mobile')}
          total={Number(currentApp?.active_users) || 0}
          icon={<PhoneIphoneIcon style={{color: '#69afbf'}} color='primary' fontSize='large'/>}
        />
        <Widget
          value={desktop}
          label={t('subscribers.desktop')}
          total={Number(currentApp?.active_users) || 0}
          icon={<DesktopMacIcon style={{color: '#f1ca56'}} color='primary' fontSize='large'/>}
        />
        <Widget
          value={tablet}
          label={t('subscribers.tablet')}
          total={Number(currentApp?.active_users) || 0}
          icon={<TabletMacIcon style={{color: '#dd5344'}} color='primary' fontSize='large'/>}
        />
        <Widget
          value={other}
          label={t('subscribers.other')}
          total={Number(currentApp?.active_users) || 0}
          icon={<DevicesOtherIcon style={{color: '#113559'}} color='primary' fontSize='large'/>}
        />
      </Typography>
      <Paper
        elevation={3}
        className={classes.paperRoot}
      >
        {!!top10Countries.length && (
          <Gadget
            stats={top10Countries}
            total={currentApp?.active_users}
            title={t('subscribers.country')}
            icon={<PublicRoundedIcon color='primary'/>}
          />
        )}
        {!!top10Languages.length && (
          <Gadget
            stats={top10Languages}
            total={currentApp?.active_users}
            title={t('subscribers.language')}
            icon={<LanguageRoundedIcon color='primary'/>}
          />
        )}
        {!!top10Timezones.length && (
          <Gadget
            stats={top10Timezones}
            total={currentApp?.active_users}
            title={t('subscribers.time_zone')}
            icon={<AccessTimeRoundedIcon color='primary'/>}
          />
        )}
        {!!top10Os.length && (
          <Gadget
            stats={top10Os}
            title={t('subscribers.os')}
            total={currentApp?.active_users}
            icon={<AndroidIcon color='primary'/>}
          />
        )}
        {!!top10Browser.length && (
          <Gadget
            stats={top10Browser}
            total={currentApp?.active_users}
            icon={<WebRoundedIcon color='primary'/>}
            title={t('subscribers.browser_with_version')}
          />
        )}
        {!top10Os.length && !top10Timezones.length && !top10Languages.length && !top10Countries.length && (
          <Typography
            align='center'
            style={{flexGrow: 1}}
          >
            <Trans
              i18nKey='subscribers.no_data'
            />
          </Typography>
        )}
      </Paper>
    </Typography>
  )
})

