import React from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {UnregisterCallback} from 'history';
import {useTranslation} from 'react-i18next';
import {useTitle} from '../../../../../hooks/useTitle';
import {_currentApp} from '../../../../../store/selectors/apps';
import {_update} from '../../../../../store/actions/apps/update';
import {_onBeforeunload} from '../../../../../store/actions/application';
import {_canUpdateApp} from '../../../../../store/selectors/application/acl';
import {_canDeleteApp} from '../../../../../store/selectors/application/acl';
import {_hasWebhooksAccess} from '../../../../../store/selectors/application/acl';
import {responseTransformer} from '../../../../../utils/generalSettingTransformer';
import {_hasForcePromptAccess} from '../../../../../store/selectors/application/acl';

let unregisterCallback: UnregisterCallback | null = null;

const beforeUnload = function (e: BeforeUnloadEvent) {
  e.returnValue = 'Changes you made may not be saved.';

  return 'Changes you made may not be saved.';
}

export function useApp() {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  useTitle(t('app_settings.a_welcome_message'));

  const currentApp = useSelector(_currentApp);
  const canDeleteApp = useSelector(_canDeleteApp);
  const canUpdateApp = useSelector(_canUpdateApp);
  const hasWebhooksAccess = useSelector(_hasWebhooksAccess);
  const hasForcePromptAccess = useSelector(_hasForcePromptAccess);

  const form = useForm<Record<string, any>>({
    defaultValues: responseTransformer(currentApp),
  });
  const {
    setError,
    formState,
  } = form;
  const {
    isDirty,
    dirtyFields,
    isSubmitting,
  } = formState;
  const hasDirtyFields = !!Object.keys(dirtyFields || {}).length;

  React.useEffect(() => {
    if (isDirty && hasDirtyFields) {
      unregisterCallback = history.block((location) => {
        dispatch(_onBeforeunload({
          open           : true,
          bodyTitle      : 'Warning',
          agreeAction    : () => {
            dispatch(_onBeforeunload(void 0));
            unregisterCallback && unregisterCallback();
            history.push(location);
          },
          onClose        : () => {
            dispatch(_onBeforeunload(void 0));
          },
          dismissAction  : () => {
            dispatch(_onBeforeunload(void 0));
          },
          dismissLabel   : 'Stay on Page',
          agreeLabel     : 'Discard Changes',
          bodyDescription: 'You have unsaved changes in your settings. If you continue, your changes will be lost.',
        }));

        return false;
      });

      window.addEventListener('beforeunload', beforeUnload);
    }

    return function () {
      unregisterCallback && unregisterCallback();

      window.removeEventListener('beforeunload', beforeUnload);
    }
  }, [
    history,
    isDirty,
    dispatch,
    hasDirtyFields,
  ]);

  const onSubmit = React.useCallback((formValues: Record<string, any>) => {
    return dispatch(_update(formValues, {
      setError,
      skipLoading       : true,
      unregisterCallback: () => {
        unregisterCallback && unregisterCallback();
        window.removeEventListener('beforeunload', beforeUnload);
      },
    }));
  }, [
    dispatch,
    setError,
  ]);

  return {
    form,
    onSubmit,
    isSubmitting,
    canDeleteApp,
    canUpdateApp,
    hasWebhooksAccess,
    hasForcePromptAccess,
    tags: currentApp?.launch_url_tags || {},
  }
}
