import React from 'react';
import {Button} from 'antd';
import {Breadcrumb} from 'antd';
import {useEdit} from './useEdit';
import {useEditForm} from './useEdit';
import {SegmentsForm} from '../../components/Form';
import {useTitle} from '../../../../../hooks/useTitle';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import {Link} from '../../../../../components/antd/Typography';
import {Spinner} from '../../../../../components/antd/Spinner';
import {Content} from '../../../../../components/antd/Content';
import {getPrefixCls} from '../../../../../utils/getPrefixCls';
import {Navigation} from '../../../../../components/antd/Navigation';

export function Edit() {
  const {
    canRender,
  } = useEdit();
  useTitle('Edit Segment | SmartPush')

  if (!canRender) {
    return (
      <Spinner/>
    )
  }

  return (
    <EditForm/>
  )
}

function EditForm() {
  const {
    fields,
    params,
    control,
    segment,
    onCancel,
    setValue,
    onRemove,
    onAppend,
    register,
    onSubmit,
    redirectToSegments,
    formState: {
      isSubmitting,
    },
  } = useEditForm();

  return (
    <form
      onSubmit={onSubmit}
    >
      <Navigation
        extra={[
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
            key='cancelSaveSegment'
          >Cancel
          </Button>,
          <Button
            type='primary'
            htmlType='submit'
            key='updateSegment'
            loading={isSubmitting}
            icon={<CheckOutlined/>}
          >Update
          </Button>,
        ]}
        title={segment?.name}
        breadcrumbItems={[
          <Breadcrumb.Item
            key='app.segments'
            onClick={redirectToSegments}
          >
            <Link
              ellipsis
              type='secondary'
            >Segments
            </Link>
          </Breadcrumb.Item>,
          <Breadcrumb.Item
            key='app.segments.newSegment'
          >{segment?.name}
          </Breadcrumb.Item>,
        ]}
      />
      <Content>
        <div
          className={`${getPrefixCls('segments-form-container')} p-5`}
        >
          <SegmentsForm
            fields={fields}
            params={params}
            control={control}
            setValue={setValue}
            onRemove={onRemove}
            onAppend={onAppend}
            register={register}
          />
        </div>
      </Content>
    </form>
  )
}