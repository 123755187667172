import produce from 'immer';
import {Reports} from '../../../../types/reports';
import {ActionTypes} from '../../../constants/reports/clicks';
import {FiniteStates} from '../../../../constants/finiteStates';
import {ActionTypes as RActionTypes} from '../../../constants/reports';
import {ActionTypes as CoreActionTypes} from '../../../constants/application';

const InitialState: Reports.Chart.Reducer = {
  data       : [],
  dataOptions: {
    fetchingState: FiniteStates.IDLE,
  },
};

export const clicks = produce((draft, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.REPORTS_CLICKS_SET_DATA: {
      draft.data = payload;

      return void 0;
    }
    case ActionTypes.REPORTS_CLICKS_DATA_OPTIONS: {
      Object.keys(payload).forEach((key) => {
        (draft.dataOptions as any)[key] = payload[key];
      });

      return void 0;
    }
    case CoreActionTypes.LOGOUT: {
      return {...InitialState}
    }
    case RActionTypes.REPORTS_RESET: {
      return {
        ...InitialState,
        dataOptions: {
          ...InitialState.dataOptions,
          unique: draft.dataOptions.unique,
        }
      }
    }
    case RActionTypes.REPORTS_ALL_DATA_OPTIONS: {
      Object.keys(payload).forEach((key) => {
        (draft.dataOptions as any)[key] = payload[key];
      });

      return void 0;
    }
    default:
      return void 0;
  }
}, InitialState);