import React from 'react';
import {useHistory} from 'react-router';
import {ButtonProps} from 'antd/lib/button/button';
import EditOutlined from '@ant-design/icons/EditOutlined';
import {Templates} from '../../../../../../types/templates';
import {aclKeys} from '../../../../../../constants/aclKeys';
import {urlHelper} from '../../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../../constants/appRoutes';
import {useSmartPush} from '../../../../../../hooks/useSmartPush';
import {FiniteStates} from '../../../../../../constants/finiteStates';
import {TableAction} from '../../../../../../components/antd/TableAction';

export function EditAction(
  {
    row,
  }: {
    row: Templates.Entity;
  }
) {
  const {push} = useHistory();

  const {
    appUuid,
    user: {
      canUpdate,
    }
  } = useSmartPush();
  const buttonProps: ButtonProps = {
    size    : 'small',
    type    : 'default',
    shape   : 'circle',
    icon    : <EditOutlined/>,
    disabled: !canUpdate(aclKeys.CAMPAIGNS),
    loading : row.deleting === FiniteStates.LOADING,
    onClick : () => {
      push(urlHelper(AppRoutes.TEMPLATES_EDIT, {
        appUuid,
        id: row.id
      }));
    }
  }
  const tooltipTitle = canUpdate(aclKeys.CAMPAIGNS) ? 'Edit' : 'You don\'t have access to manage this action';

  return (
    <TableAction
      buttonProps={buttonProps}
      tooltipProps={{title: tooltipTitle}}
    />
  )
}
