import React from 'react';
import {useDispatch} from 'react-redux';
import {useWatch} from 'react-hook-form';
import {ICreateAppForm} from '../../useUpsertApp';
import {Control} from 'react-hook-form/dist/types/form';
import {_dataOptions} from '../../../../../../../store/actions/apps';

export function TitleListener(
  {
    control,
  }: {
    control: Control<ICreateAppForm>
  }
): JSX.Element | null {
  const dispatch = useDispatch();
  const title = useWatch({
    control,
    name: 'title',
  });

  React.useEffect(() => {
    dispatch(_dataOptions({
      temporaryTitle: title,
    }));
  }, [
    title,
    dispatch,
  ]);

  React.useEffect(() => {
    return function () {
      dispatch(_dataOptions({
        temporaryTitle: void 0,
      }));
    }
  }, [
    dispatch,
  ])

  return null;
}
