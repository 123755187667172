import React from 'react';
import ConversionsSumFormatter from '../Formatter';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {DataTypeProviderProps} from '@devexpress/dx-react-grid';

export default function ConversionsSumProvider(
  {
    actions,
    ...restProps
  }: DataTypeProviderProps & { actions: any }
) {
  return (
    <DataTypeProvider
      formatterComponent={ConversionsSumFormatter}
      {...restProps}
    />
  )
}
