import React from 'react';
import {Card} from 'antd';
import {Alert} from 'antd';
import {Button} from 'antd';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {momentTZ} from '../../../../../../../utils';
import {formatWithCommas} from '../../../../../../../utils';
import {urlHelper} from '../../../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../../../constants/appRoutes';
import {Text} from '../../../../../../../components/antd/Typography';
import {getPrefixCls} from '../../../../../../../utils/getPrefixCls';
import {_isAppSumoPlan} from '../../../../../../../store/selectors/portal';
import './index.less';

export function CurrentPlan(
  {
    currentPlan,
    scheduledPlan,
    isDowngrading,
    cancelDowngrade,
  }: {
    currentPlan: {
      plan_name: string;
      plan_slug: string;
      unit_amount: number;
      price_per_1000: number;
      free_displays_count: number;
      max_subscribers_count?: number;
    };
    isDowngrading?: boolean;
    cancelDowngrade: () => void;
    scheduledPlan: Record<string, any>;
  }
) {
  const {push} = useHistory();
  const isAppSumoPlan = useSelector(_isAppSumoPlan);
  const {appUuid} = useParams<{ appUuid: string }>();

  const changePlan = () => {
    let url = AppRoutes.CHANGE_PLAN;
    if (appUuid) {
      url = urlHelper(AppRoutes.CHANGE_PLAN_APP, {appUuid})
    }
    push(url);
  }

  const message = () => {
    return (
      <div
        className='d-flex align-items-center'
      >
        <div>
          <Text fontSize='large'>Plan downgrade from <Text strong>{currentPlan.plan_name}</Text> to <strong>{scheduledPlan.plan_name}</strong> is scheduled on <Text strong>{momentTZ(scheduledPlan.date).format('YYYY-MM-DD hh:mm A')}</Text>.</Text>
          <br/>
          <Text>Your subscription will change when previous plan period is over.</Text>
        </div>
      </div>
    )
  }

  return (
    <Card
      title='Current Plan'
    >
      <div
        className={getPrefixCls('current-plan-card-body')}
      >
        <div
          className={`d-flex justify-content-between align-items-center p-4 ${getPrefixCls('current-plan-box')}`}
        >
          <div className='m-r-4'>
            <Text>
              <Text strong>{currentPlan.plan_name?.split(' - ')?.[0] || currentPlan.plan_name}</Text>
            </Text>
          </div>
          <div>
            <div>
              <Text strong>${formatWithCommas(currentPlan.unit_amount)}</Text>
              <Text>/</Text>
              <Text>{isAppSumoPlan ? 'forever' : 'month'}</Text>
            </div>
            <div>
              <Text strong className='m-r-1'>{formatWithCommas(currentPlan.free_displays_count)}</Text>
              <Text>delivered messages included</Text>
            </div>
            {!!currentPlan.price_per_1000 && (
              <div>
                <Text strong className='m-r-1'>${currentPlan.price_per_1000}</Text>
                <Text>per 1,000 delivered message overage</Text>
              </div>
            )}
            {!!currentPlan.max_subscribers_count && (
              <div>
                <Text strong className='m-r-1'>{formatWithCommas(currentPlan.max_subscribers_count)}</Text>
                <Text>subscribers</Text>
              </div>
            )}
          </div>
        </div>
        <Button
          ghost
          type='primary'
          onClick={changePlan}
          disabled={!!scheduledPlan}
          className={getPrefixCls('current-plan-card-change-plan-action')}
        >Change Plan
        </Button>
      </div>
      {!!scheduledPlan && (
        <div
          className='m-t-5'
        >
          <Alert
            type='warning'
            message={message()}
            action={(
              <Button
                type='primary'
                loading={isDowngrading}
                disabled={isDowngrading}
                onClick={cancelDowngrade}
              >Cancel Downgrade
              </Button>
            )}
          />
        </div>
      )}
    </Card>
  )
}
