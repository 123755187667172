import {Spin} from 'antd';
import React from 'react';
import {useParams} from 'react-router';
import Box from '@material-ui/core/Box';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import {Control} from 'react-hook-form/dist/types/form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {IOSSwitch} from '../../../../../../../components/IOSSwitch';
import {FiniteStates} from '../../../../../../../constants/finiteStates';
import {_customPromptState} from '../../../../../../../store/selectors/apps';
import {_isTogglingPrompt} from '../../../../../../../store/selectors/prompt';
import {_onBeforeunload} from '../../../../../../../store/actions/application';
import {_togglePrompt} from '../../../../../../../store/actions/prompt/togglePrompt';
import {_hasLocalesAccess} from '../../../../../../../store/selectors/application/acl';

const useStyles = makeStyles(() => ({
  formControlLabel: {
    marginLeft : 0,
    marginRight: 0,
  },
}));

export function ToggleCustomPrompt(
  {
    control,
    disabled,
  }: {
    disabled?: boolean;
    control: Control<Record<string, any>>;
  }
) {
  const hasLocales = useWatch({
    control,
    name: 'has_locales',
  });
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {appUuid} = useParams<{ appUuid: string }>();
  const isTogglingPrompt = useSelector(_isTogglingPrompt);
  const hasLocalesAccess = useSelector(_hasLocalesAccess);
  const customPromptState = useSelector(_customPromptState);

  const togglePrompt = React.useCallback(() => {
    if (hasLocales && !hasLocalesAccess) {
      dispatch(_onBeforeunload({
        open           : true,
        agreeAction    : () => {
          dispatch(_onBeforeunload(void 0));
          dispatch(_togglePrompt(appUuid))
        },
        onClose        : () => {
          dispatch(_onBeforeunload(void 0));
        },
        dismissAction  : () => {
          dispatch(_onBeforeunload(void 0));
        },
        agreeLabel     : t('buttons.save'),
        dismissLabel   : t('buttons.cancel'),
        bodyTitle      : t('app_settings.warning_body_title'),
        bodyDescription: t('app_settings.warning_body_description'),
      }));
    } else {
      dispatch(_togglePrompt(appUuid))
    }
  }, [
    t,
    appUuid,
    dispatch,
    hasLocales,
    hasLocalesAccess,
  ]);

  return (
    <Box
      padding={2}
      marginTop={1}
      marginBottom={1}
      style={{backgroundColor: '#fff'}}
    >
      <Box
        display='flex'
        alignItems='center'
      >
        <FormControlLabel
          labelPlacement='end'
          onChange={togglePrompt}
          checked={customPromptState}
          control={<IOSSwitch size='small'/>}
          classes={{root: classes.formControlLabel}}
          disabled={disabled || (isTogglingPrompt === FiniteStates.LOADING)}
          label={<Label loading={isTogglingPrompt === FiniteStates.LOADING}/>}
        />
      </Box>
    </Box>
  )
}

function Label(
  {
    loading,
  }: {
    loading?: boolean;
  }
) {
  const {t} = useTranslation();

  return (
    <Box
      display='flex'
      marginLeft={.5}
      alignItems='center'
    >
      <Typography
        color='inherit'
        variant='body1'
        component='span'
      ><strong>{t('app_settings.default_enabled')}</strong>
      </Typography>
      {loading && (
        <Box
          display='flex'
          marginLeft={1}
        >
          <Spin
            size='small'
            style={{display: 'flex'}}
          />
        </Box>
      )}
    </Box>
  )
}