import {IObject} from '../../../types';
import {ActionTypes} from '../../constants/uploader';

const InitialState: IObject = {
  open         : false,
  minimized    : false,
  fullScreen   : true,
  asyncSettings: {
    retryCount     : 2,
    retryAfterDelay: 3000,
    saveUrl        : void 0,
    removeUrl      : void 0,
    chunkSize      : 1024 * 1024,
  }
};

export default (state = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.CORE_UPDATE_OPTIONS: {
      return {
        ...state,
        ...payload,
      };
    }
    case ActionTypes.CORE_TOGGLE_UPLOADER: {
      return {
        ...state,
        open: !state.open,
      };
    }
    case ActionTypes.CORE_UPDATE_ASYNC_SETTINGS: {
      return {
        ...state,
        asyncSettings: {
          ...state.asyncSettings,
          ...payload,
        }
      };
    }
    default:
      return state;
  }
};
