import React from 'react';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import Menu from '@material-ui/core/Menu';
import {useTranslation} from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {FEATURE} from '../../../../../../../constants/users';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {urlHelper} from '../../../../../../../utils/urlHelper';
import {aclKeys} from '../../../../../../../constants/aclKeys';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {ACCESS_TYPES} from '../../../../../../../constants/users';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {AppRoutes} from '../../../../../../../constants/appRoutes';
import {Permissions} from '../../../../../../../constants/permission';
import {FEATURE_GROUP} from '../../../../../../../constants/permission';
import {TableActions} from '../../../../../../../constants/tableActions';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import {_userAcl} from '../../../../../../../store/selectors/application/acl';
import {toggleDialogAction} from '../../../../../../../store/actions/campaigns';
import {CampaignStatuses} from '../../../../../../../constants/campaignStatuses';

export default function ActionFormatter(
  {
    row,
  }: DataTypeProvider.ValueFormatterProps
) {
  const history = useHistory();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const userAcl = useSelector(_userAcl);
  const {appUuid} = useParams<{ appUuid: string }>();

  const [open, setOpen] = React.useState(false);

  const hasSegments = React.useMemo(() => {
    return !!(
      Object.values(row?.exclude_segments || {}).length ||
      Object.values(row?.include_segments || {}).length
    )
  }, [
    row,
  ]);
  const hasSegmentsAccess = React.useMemo(() => {
    return !!(
      userAcl?.segments?.[FEATURE_GROUP]?.[Permissions.READ] &&
      userAcl?.segments?.[FEATURE_GROUP]?.[Permissions.VISIBLE]
    )
  }, [
    userAcl,
  ]);

  const hasLocales = React.useMemo(() => {
    return (Object.keys(row?.messages || {}).length > 1)
  }, [
    row,
  ]);
  const hasLocalesAccess = React.useMemo(() => {
    return (
      !!userAcl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE]
    )
  }, [
    userAcl,
  ]);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = React.useCallback(() => {
    setOpen(prevOpen => {
      return !prevOpen
    });
  }, []);

  const handleClose = React.useCallback((event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }, []);

  const toggleDialog = React.useCallback((e) => {
    handleClose(e);
    if (e.currentTarget.getAttribute('data-action')) {
      dispatch(toggleDialogAction({
        data  : row,
        dialog: e.currentTarget.getAttribute('data-action'),
      }));
    }
  }, [
    row,
    dispatch,
    handleClose,
  ]);

  const toggleCloneDialog = React.useCallback((e) => {
    handleClose(e);

    if (hasSegments && !hasSegmentsAccess) {
      return dispatch(toggleDialogAction({
        data  : {
          ...row,
          dialogState: {
            agreeLabel     : t('buttons.clone'),
            dismissLabel   : t('buttons.cancel'),
            bodyTitle      : t('campaigns.segments_warning_title'),
            bodyDescription: t('campaigns.segments_warning_description_clone'),
            agreeAction    : () => {
              if (hasLocales && !hasLocalesAccess) {
                return dispatch(toggleDialogAction({
                  data  : {
                    ...row,
                    dialogState: {
                      agreeLabel     : t('buttons.clone'),
                      dismissLabel   : t('buttons.cancel'),
                      bodyTitle      : t('campaigns.clone_body_title'),
                      bodyDescription: t('campaigns.clone_body_description'),
                      agreeAction    : () => {
                        dispatch(toggleDialogAction(void 0));
                        return history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_CLONE, {
                          appUuid,
                          uuid: row?.uuid
                        }));
                      },
                      onClose        : () => dispatch(toggleDialogAction(void 0)),
                      dismissAction  : () => dispatch(toggleDialogAction(void 0)),
                    },
                  },
                  dialog: TableActions.CLONE,
                }));
              }

              dispatch(toggleDialogAction(void 0));
              return history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_CLONE, {
                appUuid,
                uuid: row?.uuid
              }));
            },
            onClose        : () => dispatch(toggleDialogAction(void 0)),
            dismissAction  : () => dispatch(toggleDialogAction(void 0)),
          },
        },
        dialog: TableActions.CLONE,
      }));
    }

    if (hasLocales && !hasLocalesAccess) {
      return dispatch(toggleDialogAction({
        data  : {
          ...row,
          dialogState: {
            agreeLabel     : t('buttons.clone'),
            dismissLabel   : t('buttons.cancel'),
            bodyTitle      : t('campaigns.clone_body_title'),
            bodyDescription: t('campaigns.clone_body_description'),
            agreeAction    : () => {
              dispatch(toggleDialogAction(void 0));
              return history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_CLONE, {
                appUuid,
                uuid: row?.uuid
              }));
            },
            onClose        : () => dispatch(toggleDialogAction(void 0)),
            dismissAction  : () => dispatch(toggleDialogAction(void 0)),
          },
        },
        dialog: TableActions.CLONE,
      }));
    }

    dispatch(toggleDialogAction(void 0));
    return history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_CLONE, {
      appUuid,
      uuid: row?.uuid
    }));
  }, [
    t,
    row,
    appUuid,
    history,
    dispatch,
    hasLocales,
    hasSegments,
    handleClose,
    hasLocalesAccess,
    hasSegmentsAccess,
  ]);

  const toggleCreateTemplateDialog = React.useCallback((e) => {
    handleClose(e);

    if (hasLocales && !hasLocalesAccess) {
      if (e.currentTarget.getAttribute('data-action')) {
        dispatch(toggleDialogAction({
          data  : row,
          dialog: e.currentTarget.getAttribute('data-action'),
        }));
      }
    } else {
      history.push(urlHelper(AppRoutes.TEMPLATE_CLONE_FROM_CAMPAIGN, {
        appUuid,
        uuid: row.uuid
      }));
    }
  }, [
    row,
    appUuid,
    history,
    dispatch,
    hasLocales,
    handleClose,
    hasLocalesAccess,
  ]);

  return (
    <Typography
      align='center'
      color='primary'
      component='div'
    >
      <IconButton
        size='small'
        disableRipple
        ref={anchorRef}
        color='default'
        disableFocusRipple
        disableTouchRipple
        onClick={handleToggle}
        data-id={`actions-button-${row.index}`}
      >
        <CloseIcon
          fontSize='small'
          style={!open ? {display: 'none'} : undefined}
        />
        <MoreVertIcon
          fontSize='small'
          style={open ? {display: 'none'} : undefined}
        />
      </IconButton>
      <Menu
        open={open}
        elevation={0}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorEl={anchorRef.current}
        PaperProps={{
          square : true,
          variant: 'outlined',
          style  : {border: '1px solid lightgrey'},
        }}
        anchorOrigin={{
          vertical  : 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical  : 'center',
          horizontal: 'right',
        }}
        MenuListProps={{
          style    : {
            paddingTop: 5, paddingBottom: 5
          },
          'data-id': `actions-menu-${row.index}`
        } as any}
      >
        {[
          CampaignStatuses.SENT,
          CampaignStatuses.SCHEDULED,
          CampaignStatuses.COMPLETED,
        ].includes(Number(row.status)) && (
          <MenuItem
            component={Link}
            onClick={toggleDialog}
            data-id={TableActions.INFO}
            to={urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_INFO, {
              appUuid,
              uuid: row.uuid
            })}
          >
            <ListItemIcon
              style={{minWidth: 26}}
            >
              <HelpOutlineIcon
                fontSize='small'
              />
            </ListItemIcon>
            <Typography
              noWrap
              color='primary'
              variant='inherit'
            >{t('campaigns.info')}
            </Typography>
          </MenuItem>
        )}

        {[
          CampaignStatuses.PENDING,
        ].includes((Number(row.status))) && !!userAcl?.[aclKeys.CAMPAIGNS]?.fg?.[Permissions.UPDATE] && (
          <MenuItem
            component={Link}
            onClick={toggleDialog}
            data-id={TableActions.EDIT}
            to={urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_EDIT, {
              appUuid,
              uuid: row.uuid
            })}
          >
            <ListItemIcon
              style={{minWidth: 26}}
            >
              <EditIcon
                fontSize='small'
              />
            </ListItemIcon>
            <Typography
              noWrap
              color='primary'
              variant='inherit'
            >{t('campaigns.edit')}
            </Typography>
          </MenuItem>
        )}

        {!!userAcl?.[aclKeys.CAMPAIGNS]?.fg?.[Permissions.CREATE] && (
          <MenuItem
            onClick={toggleCloneDialog}
            data-id={TableActions.CLONE}
            data-action={TableActions.CLONE}
          >
            <ListItemIcon
              style={{minWidth: 26}}
            >
              <FileCopyIcon
                fontSize='small'
              />
            </ListItemIcon>
            <Typography
              noWrap
              color='primary'
              variant='inherit'
            >{t('campaigns.clone')}
            </Typography>
          </MenuItem>
        )}

        {!!userAcl?.[aclKeys.TEMPLATES]?.fg?.[Permissions.CREATE] && (
          <MenuItem
            data-id={TableActions.CLONE_FROM}
            onClick={toggleCreateTemplateDialog}
            data-action={TableActions.CLONE_FROM}
          >
            <ListItemIcon
              style={{minWidth: 26}}
            >
              <CreateNewFolderIcon
                fontSize='small'
              />
            </ListItemIcon>
            <Typography
              noWrap
              color='primary'
              variant='inherit'
            >{t('campaigns.create_template')}
            </Typography>
          </MenuItem>
        )}

        {[
          CampaignStatuses.SCHEDULED,
        ].includes(Number(row.status)) && !!userAcl?.[aclKeys.CAMPAIGNS]?.fg?.[Permissions.CANCEL] && (
          <MenuItem
            onClick={toggleDialog}
            data-id={TableActions.CANCEL}
            data-action={TableActions.CANCEL}
          >
            <ListItemIcon
              style={{minWidth: 26}}
            >
              <CancelIcon
                color='error'
                fontSize='small'
              />
            </ListItemIcon>
            <Typography
              noWrap
              color='primary'
              variant='inherit'
            >{t('campaigns.cancel')}
            </Typography>
          </MenuItem>
        )}

        {[
          CampaignStatuses.PENDING,
        ].includes((Number(row.status))) && !!userAcl?.[aclKeys.CAMPAIGNS]?.fg?.[Permissions.DELETE] && (
          <MenuItem
            onClick={toggleDialog}
            data-id={TableActions.DELETE}
            data-action={TableActions.DELETE}
          >
            <ListItemIcon
              style={{minWidth: 26}}
            >
              <DeleteIcon
                color='error'
                fontSize='small'
              />
            </ListItemIcon>
            <Typography
              noWrap
              color='primary'
              variant='inherit'
            >{t('campaigns.delete')}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </Typography>
  )
}
