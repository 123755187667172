import clsx from 'clsx';
import React from 'react';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import Box from '@material-ui/core/Box';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import Dialog from '../../../../../components/Dialog';
import {FEATURE} from '../../../../../constants/users';
import FormGroup from '@material-ui/core/FormGroup';
import {Button} from '../../../../../components/Button';
import WarningIcon from '@material-ui/icons/Warning';
import {aclKeys} from '../../../../../constants/aclKeys';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import {ACCESS_TYPES} from '../../../../../constants/users';
import {IOSSwitch} from '../../../../../components/IOSSwitch';
import {Spinner} from '../../../../../components/antd/Spinner';
import {Permissions} from '../../../../../constants/permission';
import {_currentApp} from '../../../../../store/selectors/apps';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {getEntity} from '../../../../../store/selectors/campaigns';
import {getErrors} from '../../../../../store/selectors/appSettings';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {getEntityLang} from '../../../../../store/selectors/campaigns';
import {_userAcl} from '../../../../../store/selectors/application/acl';
import {getIsLoading} from '../../../../../store/selectors/appSettings';
import {_welcomeMessageUuid} from '../../../../../store/selectors/apps';
import {getCommonEntity} from '../../../../../store/selectors/campaigns';
import {toggleEntityAction} from '../../../../../store/actions/campaigns';
import {toggleErrorsAction} from '../../../../../store/actions/campaigns';
import {removeErrorAction} from '../../../../../store/actions/appSettings';
import {updateEntityLangAction} from '../../../../../store/actions/campaigns';
import {getCampaignDefaultLang} from '../../../../../store/selectors/campaigns';
import MultiTabsWrapper from '../../../../../components/Campaigns/MultiTabsWrapper';
import {toggleWelcomeMessageAction} from '../../../../../store/actions/appSettings';
import {getAction as getCampaignAction} from '../../../../../store/actions/campaigns';
import {createOrUpdateWMessageAction} from '../../../../../store/actions/appSettings';
import ActionsCardWrapper from '../../../../../components/Campaigns/ActionsCardWrapper';
import ScheduleCardWrapper from '../../../../../components/Campaigns/ScheduleCardWrapper';
import {toggleErrorsAction as toggleSettingErrorsAction} from '../../../../../store/actions/appSettings';
import {useTitle} from '../../../../../hooks/useTitle';

const useStyles = makeStyles((theme: Theme) => ({
  btnSave          : {
    [theme.breakpoints.down('sm')]: {
      textAlign : 'center',
      '& button': {
        minWidth: 120,
      },
    },
  },
  iOSSwitchRoot    : {
    marginTop   : 0,
    marginLeft  : 0,
    marginBottom: 0,
  },
  cardHeaderRoot   : {
    padding                       : theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
    },
  },
  cardContentRoot  : {
    padding                       : 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 1, 1),
    },
  },
  formControlLabel : {
    marginLeft  : 0,
    marginRight : 0,
    marginTop   : theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  cardHeaderContent: {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'space-between',
  },
  loaderWrapper    : {
    display     : 'table',
    margin      : '0 auto',
    marginTop   : theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  defaultEnabled   : {
    marginLeft: 4,
    fontWeight: 'bold',
  },
  titleTypography  : {
    fontSize  : 22,
    fontWeight: 'bold',
    color     : theme.palette.primary.main,
  },
  cardRoot         : {
    borderRadius   : 0,
    position       : 'relative',
    backgroundColor: 'transparent',
    transition     : theme.transitions.create(['border-color'], {
      delay: theme.transitions.duration.leavingScreen
    }),
  },
  disabledCardRoot : {
    borderColor: '#ffe9c4',
  },
  formGroup        : {
    alignItems: 'flex-start',
  },
  isDisabled       : {
    backgroundColor        : '#fff0d5',
    paddingTop             : theme.spacing(1.5),
    '& .MuiTypography-root': {
      color: '#855d16',
    }
  },
  disabled         : {
    color: 'rgba(0, 0, 0, 0.38) !important'
  }
}));

let timeout: any = null;

export default function WelcomeMessage() {
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {appUuid} = useParams<{
    appUuid: string;
  }>();

  const [dialogState, updateDialogState] = React.useState<{
    open: boolean;
    bodyTitle?: string;
    buttonText?: string;
    bodyDescription?: string;
    agreeAction?: () => void;
  }>({
    open: false,
  })

  const userAcl = useSelector(_userAcl);
  const entity = useSelector(getEntity);
  const errors = useSelector(getErrors);
  const lang = useSelector(getEntityLang);
  const campaign = useSelector(getEntity);
  useTitle(t('campaigns.s_welcome_message'));
  const isLoading = useSelector(getIsLoading);
  const currentApp = useSelector(_currentApp);
  const commonEntity = useSelector(getCommonEntity);
  const welcomeNfUuid = useSelector(_welcomeMessageUuid);
  const defaultLang = useSelector(getCampaignDefaultLang);

  const [wait, setWait] = React.useState<boolean>(true);
  const [wmState, setWmState] = React.useState<boolean>(false);

  const removeError = React.useCallback((key: string) => {
    dispatch(removeErrorAction(key))
  }, [
    dispatch,
  ]);

  const memorizedErrors = React.useMemo(() => {
    return {
      title  : errors['messages.default.title'],
      message: errors['messages.default.message'],
      ...errors,
    };
  }, [
    errors
  ]);
  const memorizedDefaultLang = React.useMemo(() => {
    return defaultLang;
    // eslint-disable-next-line
  }, [
    welcomeNfUuid,
    // eslint-disable-next-line
    defaultLang.url,
    // eslint-disable-next-line
    defaultLang.title,
    // eslint-disable-next-line
    defaultLang.message,
    // eslint-disable-next-line
    defaultLang.icon_url,
    // eslint-disable-next-line
    defaultLang.image_url,
    // eslint-disable-next-line
    currentApp.default_url,
    // eslint-disable-next-line
    currentApp.default_icon_url,
  ]);
  const memorizedCommonEntity = React.useMemo(() => {
    return commonEntity;
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    commonEntity.values,
    // eslint-disable-next-line
    commonEntity.locale_type,
  ]);
  const memorizedActionsEntity = React.useMemo(() => {
    return entity?.action || {}
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    entity?.action,
  ]);
  const memorizedScheduleEntity = React.useMemo(() => {
    return {
      ttl_unit        : entity?.ttl_unit,
      ttl_value       : entity?.ttl_value,
      wait_until_unit : entity?.wait_until_unit,
      wait_until_value: entity?.wait_until_value,
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    entity?.ttl_unit,
    // eslint-disable-next-line
    entity?.ttl_value,
    // eslint-disable-next-line
    entity?.wait_until_unit,
    // eslint-disable-next-line
    entity?.wait_until_value,
  ]);

  const getTitle = React.useCallback(() => {
    if (!currentApp?.welcome_nf_uuid) {
      return t('campaigns.save_to_enable_w_m');
    } else if (wmState) {
      return t('campaigns.w_m_is_disabled');
    } else {
      return t('campaigns.w_m_is_enabled');
    }
  }, [
    t,
    wmState,
    currentApp,
  ]);

  const handleSubmit = React.useCallback(() => {
    if (
      welcomeNfUuid &&
      (Object.keys(entity?.messages || {}).length > 1) &&
      !userAcl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE]
    ) {
      updateDialogState({
        open           : true,
        agreeAction    : () => {
          dispatch(createOrUpdateWMessageAction(appUuid, welcomeNfUuid))

          updateDialogState({open: false});
        },
        buttonText     : t('buttons.save'),
        bodyTitle      : t('campaigns.w_m_warning_body_title'),
        bodyDescription: t('campaigns.w_m_warning_body_description_submit'),
      })
    } else {
      dispatch(createOrUpdateWMessageAction(appUuid, welcomeNfUuid))
    }
  }, [
    t,
    entity,
    userAcl,
    appUuid,
    dispatch,
    welcomeNfUuid,
  ]);

  const toggleWelcomeMessage = React.useCallback((appUuid, welcomeNfUuid) => {
    if (
      (Object.keys(entity?.messages || {}).length > 1) &&
      !userAcl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE]
    ) {
      updateDialogState({
        open           : true,
        agreeAction    : () => {
          dispatch(toggleWelcomeMessageAction(appUuid, welcomeNfUuid));

          updateDialogState({open: false});
        },
        buttonText     : t('buttons.save'),
        bodyTitle      : t('campaigns.w_m_warning_body_title'),
        bodyDescription: !!campaign.automation?.enabled ?
          t('campaigns.w_m_warning_body_description_toggle') :
          t('campaigns.w_m_warning_body_description_toggle_disabled'),
      })
    } else {
      dispatch(toggleWelcomeMessageAction(appUuid, welcomeNfUuid))
    }
  }, [
    t,
    entity,
    userAcl,
    dispatch,
    campaign?.automation?.enabled,
  ]);

  React.useEffect(() => {
    if (campaign?.automation) {
      setWmState(!campaign.automation?.enabled);
    } else {
      const canCreate = !!userAcl?.[aclKeys.WELCOME_MESSAGES]?.fg?.[Permissions.CREATE];
      setWmState(!canCreate);
    }
  }, [
    userAcl,
    campaign,
  ]);

  React.useEffect(() => {
    let controller: null | AbortController = null;

    if (appUuid && welcomeNfUuid) {
      controller = new AbortController();

      dispatch(getCampaignAction(appUuid, welcomeNfUuid, {
        signal: controller.signal,
      }))
    }

    return function () {
      controller?.abort();

      dispatch(toggleErrorsAction(void 0));
      dispatch(toggleEntityAction(void 0));
      dispatch(toggleSettingErrorsAction(void 0));
    }
  }, [
    appUuid,
    dispatch,
    welcomeNfUuid,
  ]);

  React.useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setWait(false);
    }, 1000);

    return function () {
      clearTimeout(timeout);
    }
  }, []);

  React.useEffect(() => {
    if (!welcomeNfUuid) {
      let payload: any = {
        id: 'default',
      }

      if (currentApp.default_url) {
        payload = {
          ...payload,
          url: currentApp.default_url,
        }
      }

      if (currentApp.default_icon_url) {
        payload = {
          ...payload,
          icon_url: currentApp.default_icon_url,
        }
      }

      dispatch(updateEntityLangAction(payload));
    }
  }, [
    dispatch,
    currentApp,
    welcomeNfUuid,
  ]);

  const loading = isLoading;

  if (wait) {
    return (
      <Spinner/>
    );
  }

  const isFirstTime = !currentApp?.welcome_nf_uuid;
  const canEdit = !!userAcl?.[aclKeys.WELCOME_MESSAGES]?.fg?.[Permissions.UPDATE];
  const canCreate = !!userAcl?.[aclKeys.WELCOME_MESSAGES]?.fg?.[Permissions.CREATE];

  return (
    <>
      <Box
        padding={2}
        marginBottom={1}
        style={{backgroundColor: '#fff'}}
      >
        <FormGroup
          className={classes.formGroup}
        >
          <FormControlLabel
            labelPlacement='end'
            classes={{root: classes.formControlLabel}}
            label={
              <Typography
                variant='body1'
                component='span'
                className={clsx(
                  classes.defaultEnabled,
                  isFirstTime ? (canCreate ? '' : classes.disabled) : (canEdit ? '' : classes.disabled)
                )}
              >{getTitle()}
              </Typography>
            }
            control={
              isFirstTime ? (
                <></>
              ) : (
                <IOSSwitch
                  size='small'
                  checked={!wmState}
                  disabled={loading || !canEdit}
                  classes={{root: classes.iOSSwitchRoot}}
                  onChange={() => toggleWelcomeMessage(appUuid, welcomeNfUuid)}
                />
              )
            }
          />
        </FormGroup>
      </Box>
      <CardContent
        classes={{root: clsx(classes.cardContentRoot)}}
      >
        <MultiTabsWrapper
          lang={lang}
          currentApp={currentApp}
          errors={memorizedErrors}
          removeError={removeError}
          actions={memorizedActionsEntity}
          defaultLang={memorizedDefaultLang}
          commonEntity={memorizedCommonEntity}
          disabled={wmState || (isFirstTime ? !canCreate : !canEdit)}
        />
        <ActionsCardWrapper
          errors={memorizedErrors}
          removeError={removeError}
          entity={memorizedActionsEntity}
          disabled={wmState || (isFirstTime ? !canCreate : !canEdit)}
        />
        <ScheduleCardWrapper
          errors={errors}
          removeError={removeError}
          entity={memorizedScheduleEntity}
          disabled={wmState || (isFirstTime ? !canCreate : !canEdit)}
        />
        <Typography
          align='left'
          component='div'
          className={classes.btnSave}
        >
          <Button
            color='primary'
            loading={loading}
            variant='contained'
            style={{width: 230}}
            onClick={handleSubmit}
            disabled={wmState || (isFirstTime ? !canCreate : !canEdit) || loading}
          >{isFirstTime ? t('campaigns.create_welcome_message') : t('campaigns.save')}
          </Button>
        </Typography>
      </CardContent>
      {dialogState.open && (
        <WarningDialog
          dialogState={dialogState}
          onClose={() => {
            updateDialogState({open: false})
          }}
        />
      )}
      <div/>
    </>
  )
}

function WarningDialog(
  {
    onClose,
    loading,
    dialogState,
  }: {
    loading?: boolean,
    dialogState: {
      open: boolean;
      bodyTitle?: string;
      buttonText?: string;
      agreeAction?: () => void;
      bodyDescription?: string;
    };
    onClose: () => void;
  }
) {
  const {t} = useTranslation();
  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      color='warning'
      onClose={onClose}
      open={dialogState.open}
      actions={{
        align  : 'center',
        actions: [
          {
            onClick: onClose,
            color  : 'primary',
            variant: 'outlined',
            label  : t('buttons.cancel'),
          },
          {
            loading,
            disabled: loading,
            color   : 'primary',
            variant : 'contained',
            label   : dialogState.buttonText,
            onClick : dialogState.agreeAction,
          },
        ],
      }}
      content={{
        color          : 'warning',
        bodyIcon       : WarningIcon,
        bodyTitle      : dialogState.bodyTitle,
        bodyDescription: dialogState.bodyDescription
      }}
      dialogTitle={{
        onClose,
        color: 'warning',
      }}
    />
  )
}