export const PaymentCycleOptions = [
  {
    label: 'Annual',
    value: 'annual',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Daily',
    value: 'daily',
  },
];