import {_me} from './me';
import {History} from 'history';
import {_logout} from './logout';
import {_get as _getApp} from '../apps/get';
import {isUUID} from '../../../utils/isUUID';
import {_setApplicationStatus} from './index';
import {_firstApp} from '../../selectors/apps';
import {Globals} from '../../../types/globals';
import {_currentApp} from '../../selectors/apps';
import {urlHelper} from '../../../utils/urlHelper';
import {_fetch as _fetchApps} from '../apps/fetch';
import {_creationStep} from '../../selectors/apps';
import {_user} from '../../selectors/application/acl';
import {surveyState} from '../../../constants/survey';
import {AppRoutes} from '../../../constants/appRoutes';
import {_deliveredMessages} from './deliveredMessages';
import {_fetchingAppsState} from '../../selectors/apps';
import {_fetch as _fetchLocales} from '../locales/fetch';
import {_canReadApp} from '../../selectors/application/acl';
import {_surveyData} from '../../selectors/application/acl';
import {FiniteStates} from '../../../constants/finiteStates';
import {_integrationIsCompleted} from '../../selectors/apps';
import {_appIsVisible} from '../../selectors/application/acl';
import {surveyIsEnabled} from '../../../utils/surveyIsEnabled';
import {_applicationStatus} from '../../selectors/application';
import {_showPlanChange} from '../../selectors/application/acl';
import {_status as localesStatus} from '../../selectors/locales';
import {_fetchPortal} from '../subscriptionsAndBilling/fetchPortal';

export const _init = (
  {
    appUuid,
    history,
  }: {
    history: History;
    appUuid: string | undefined;
  },
): Globals.ThunkAction => async (dispatch, getState) => {
  const pathname = history.location.pathname;

  if (_applicationStatus(getState()) !== FiniteStates.SUCCESS) {
    dispatch(_setApplicationStatus(FiniteStates.LOADING));
  }

  let user = _user(getState());

  if (!user?.email) {
    await Promise.all([
      dispatch(_me()),
      dispatch(_fetchLocales()),
    ]);
  } else {
    if (localesStatus(getState()) === FiniteStates.IDLE) {
      await dispatch(_fetchLocales());
    }
  }

  user = _user(getState());

  const canReadApp = _canReadApp(getState());
  const appIsVisible = _appIsVisible(getState());

  if (user.response_status !== 200) {
    await dispatch(_logout());
  } else {
    if (!user.isAdmin()) {
      Promise.all([
        dispatch(_deliveredMessages()),
        dispatch(_fetchPortal()),
      ]).catch(console.error)
    }
    if (!appIsVisible || !canReadApp) {
      history.replace(AppRoutes.ACCESS_DENIED);
    } else {
      const appsState = _fetchingAppsState(getState());

      if (appsState !== FiniteStates.SUCCESS) {
        await dispatch(_fetchApps());
      }

      if (isUUID(appUuid)) {
        let currentApp = _currentApp(getState());

        if (currentApp?.uuid !== appUuid) {
          await dispatch(_getApp(appUuid));

          currentApp = _currentApp(getState());
        }

        if (currentApp.response_status !== 200) {
          const firstApp = _firstApp(getState());

          if (firstApp?.uuid) {
            await dispatch(_getApp(firstApp.uuid));

            const creationStep = _creationStep(getState());
            const showPlanChange = _showPlanChange(getState());
            const integrationIsCompleted = _integrationIsCompleted(getState());

            switch (creationStep) {
              case 1: {
                history.replace(urlHelper(AppRoutes.APPS_INTEGRATE_APP, {
                  appUuid: firstApp.uuid,
                }));
                break;
              }
              case 2: {
                if (!integrationIsCompleted) {
                  history.replace(urlHelper(AppRoutes.APPS_INTEGRATE_APP, {
                    appUuid: firstApp.uuid,
                  }));
                } else {
                  if (showPlanChange) {
                    history.replace(urlHelper(AppRoutes.APPS_PAY_AND_LAUNCH_APP, {
                      appUuid: firstApp.uuid,
                    }));
                  } else {
                    history.replace(urlHelper(AppRoutes.ANALYTICS_REPORTS, {
                      appUuid: firstApp.uuid,
                    }))
                  }
                }
                break;
              }
              default: {
                history.replace(urlHelper(AppRoutes.ANALYTICS_REPORTS, {
                  appUuid: firstApp?.uuid,
                }));
                break;
              }
            }
          } else {
            const surveyData = _surveyData(getState());

            if (
              surveyIsEnabled() &&
              (surveyData.state === surveyState.STARTED)
            ) {
              history.replace(AppRoutes.SURVEY);
            } else {
              history.replace(AppRoutes.APPS_CREATE);
            }
          }
        }
      } else if (pathname === '/') {
        const firstApp = _firstApp(getState());

        if (firstApp?.uuid) {
          await dispatch(_getApp(firstApp.uuid));

          const creationStep = _creationStep(getState());
          const showPlanChange = _showPlanChange(getState());
          const integrationIsCompleted = _integrationIsCompleted(getState());

          switch (creationStep) {
            case 1: {
              history.replace(urlHelper(AppRoutes.APPS_INTEGRATE_APP, {
                appUuid: firstApp?.uuid,
              }))
              break;
            }
            case 2: {
              if (!integrationIsCompleted) {
                history.replace(urlHelper(AppRoutes.APPS_INTEGRATE_APP, {
                  appUuid: firstApp.uuid,
                }));
              } else {
                if (showPlanChange) {
                  history.replace(urlHelper(AppRoutes.APPS_PAY_AND_LAUNCH_APP, {
                    appUuid: firstApp?.uuid,
                  }));
                } else {
                  history.replace(urlHelper(AppRoutes.ANALYTICS_REPORTS, {
                    appUuid: firstApp?.uuid,
                  }))
                }
              }
              break;
            }
          }
        } else {
          const surveyData = _surveyData(getState());

          if (
            surveyIsEnabled() &&
            (surveyData.state === surveyState.STARTED)
          ) {
            history.replace(AppRoutes.SURVEY);
          } else {
            history.replace(AppRoutes.APPS_CREATE);
          }
        }
      } else if (appUuid !== undefined) {
        return history.replace('/');
      }
    }
  }

  if (_applicationStatus(getState()) !== FiniteStates.SUCCESS) {
    dispatch(_setApplicationStatus(FiniteStates.SUCCESS));
  }
};
