import React from 'react';
import {Table} from 'antd';
import {InputNumber} from 'antd';
import {useList} from './useList';
import {Apps} from '../../../../../types/apps';
import {Content} from '../../../../../components/antd/Content';
import {Text} from '../../../../../components/antd/Typography';

export function List() {
  const {
    data,
    columns,
    expandable,
    inputNumberOnChange,
    inputNumberOnChangeLast24,
  } = useList();

  return (
    <Content>
      <Table<Apps.ListEntity>
        // sticky
        rowKey='uuid'
        columns={columns}
        loading={data.loading}
        dataSource={data.apps}
        expandable={expandable}
        scroll={{x: true, scrollToFirstRowOnChange: true}}
        summary={() => {
          return (
            <Table.Summary
              fixed='bottom'
            >
              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={0}
                  colSpan={2}
                >Total Apps: <Text strong>{data.apps?.length}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  colSpan={5}
                >
                  <div
                    className='m-b-2'
                  >
                    <InputNumber
                      min={1}
                      value={data.subCount}
                      addonBefore='In App >='
                      style={{maxWidth: 350}}
                      onChange={inputNumberOnChange}
                      addonAfter={<>Subscribers, Apps Count: <Text strong>{data.topAppsLength}</Text></>}
                    />
                  </div>
                  <InputNumber
                    min={1}
                    addonBefore='In App >='
                    style={{maxWidth: 480}}
                    value={data.subCountLast24}
                    onChange={inputNumberOnChangeLast24}
                    addonAfter={<>Last 24 hours Active Subscribers, Apps Count: <Text strong>{data.top24HAppsLength}</Text></>}
                  />
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )
        }}
      />
    </Content>
  )
}