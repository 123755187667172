import {_upsertDataItem} from './index';
import {Globals} from '../../../types/globals';
import {ApiKeys} from '../../../types/apiKeys';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _toggleStatus = (apiKey: ApiKeys.Entity): Globals.ThunkAction => async (dispatch, getState, {
  api
}) => {
  dispatch(_upsertDataItem({
    ...apiKey,
    updating: FiniteStates.LOADING,
  }));

  const {
    hasError,
    body: {
      data,
      status,
      message,
    },
  } = await api.post(urlHelper(ApiRoutes.UPDATE_API_TOKEN, {
    id: apiKey.id,
  }), apiKey);

  if (!hasError) {
    dispatch(_upsertDataItem({
      ...apiKey,
      errors  : void 0,
      enabled : data.enabled,
      updating: FiniteStates.SUCCESS,
    }));
  } else {
    const msg = (status === 403) ? 'You don\'t have access to manage this action' : message

    dispatch(_upsertDataItem({
      ...apiKey,
      enabled : !apiKey.enabled,
      updating: FiniteStates.FAILURE,
      errors  : {
        message: msg,
        inputs : !!Object.keys(data).length ? data : void 0,
      }
    }));
  }
};
