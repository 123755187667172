import React from 'react';
import {YMProvider} from './YandexMetrika';
import {IsMobileProvider} from './IsMobile';
import {BeforeunloadProvider} from './Beforeunload';
import {UnauthorizedProvider} from './Unauthorized';

export function AppProvider(
  {
    children
  }: {
    children: React.ReactNode;
  }
) {
  return (
    <YMProvider>
      <IsMobileProvider>
        <UnauthorizedProvider>
          <BeforeunloadProvider
          >{children}
          </BeforeunloadProvider>
        </UnauthorizedProvider>
      </IsMobileProvider>
    </YMProvider>
  );
}