import React from 'react';
import {notification} from 'antd';
import {TFunction} from 'react-i18next';
import {filterObject} from '../../../utils';
import {Globals} from '../../../types/globals';
import {DEFAULT_TTL} from '../../../constants';
import {FEATURE} from '../../../constants/users';
import {_currentApp} from '../../selectors/apps';
import {urlHelper} from '../../../utils/urlHelper';
import {dataLayer} from '../../../utils/dataLayer';
import {ActionTypes} from '../../constants/campaigns';
import {ACCESS_TYPES} from '../../../constants/users';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {getLastQuery} from '../../selectors/campaigns';
import {AppRoutes} from '../../../constants/appRoutes';
import {_apiBaseUrl} from '../../selectors/application';
import {_userAcl} from '../../selectors/application/acl';
import {getDataOptions} from '../../selectors/campaigns';
import {Permissions} from '../../../constants/permission';
import {LOCALE_TYPE} from '../../../constants/localeType';
import {FEATURE_GROUP} from '../../../constants/permission';
import {TableActions} from '../../../constants/tableActions';
import {getActionDialogData} from '../../selectors/campaigns';
import {campaignFilter} from '../../../utils/campaignTransformer';
import {getEntity as getCampaign} from '../../selectors/campaigns';
import {_hasTimezonesAccess} from '../../selectors/application/acl';
import {campaignTransformer} from '../../../utils/campaignTransformer';

export const setDataAction = (data: any) => ({
  payload: data,
  type   : ActionTypes.WEB_NOTIFY_CAMPAIGNS_SET_DATA,
});

export const optionsAction = (options: any) => ({
  payload: options,
  type   : ActionTypes.WEB_NOTIFY_CAMPAIGNS_TABLE_OPTIONS,
});

export const setCampaignStatAction = (data?: any) => ({
  payload: data,
  type   : ActionTypes.WEB_NOTIFY_CAMPAIGNS_SET_CAMPAIGN_STAT,
});

export const loadingAction = (loading: boolean) => ({
  payload: loading,
  type   : ActionTypes.WEB_NOTIFY_CAMPAIGNS_SET_LOADING,
});

export const dataOptionsAction = (options: any) => ({
  payload: options,
  type   : ActionTypes.WEB_NOTIFY_CAMPAIGNS_DATA_OPTIONS,
});

export const updateEntityAction = (payload: any) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_CAMPAIGNS_UPDATE_ENTITY,
});

export const removeLanguageAction = (payload: any) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_CAMPAIGNS_REMOVE_LANG,
});

export const toggleDialogAction = (payload?: {
  data?: any;
  dialog: string;
}) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_CAMPAIGNS_TOGGLE_DIALOG,
});

export const updateEntityLangAction = (payload: any) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_CAMPAIGNS_UPDATE_ENTITY_LANG,
});

export const addEntityActionsAction = (payload: any) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_CAMPAIGNS_ADD_ENTITY_ACTION,
});

export const updateEntityActionsAction = (payload: any) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_CAMPAIGNS_UPDATE_ENTITY_ACTIONS,
});

export const deleteEntityActionsAction = (payload: any) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_CAMPAIGNS_DELETE_ENTITY_ACTION,
});

export const toggleErrorsAction = (errors?: { [key: string]: any }) => ({
  payload: errors,
  type   : ActionTypes.WEB_NOTIFY_CAMPAIGNS_TOGGLE_ERRORS,
});

export const removeErrorAction = (inputName: string) => ({
  payload: inputName,
  type   : ActionTypes.WEB_NOTIFY_CAMPAIGNS_REMOVE_ERROR,
});

export const toggleEntityAction = (payload: { [key: string]: any }) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_CAMPAIGNS_TOGGLE_ENTITY,
});

export const emptyDataAction = () => ({
  type: ActionTypes.WEB_NOTIFY_CAMPAIGNS_EMPTY_TABLE_DATA,
});

export const emptyLanguagesAction = () => ({
  type: ActionTypes.WEB_NOTIFY_CAMPAIGNS_EMPTY_LANGUAGES,
});

export const emptyCampaignStatAction = () => ({
  type: ActionTypes.WEB_NOTIFY_CAMPAIGNS_EMPTY_CAMPAIGN_STAT,
});

export const fetchAction = (): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(loadingAction(true));

  let lastQuery = getLastQuery(getState());
  lastQuery = lastQuery.replace('wait_until', 'scheduled_at');

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.getList(lastQuery);

  if (hasError) {
    dispatch(loadingAction(false));

    notification.error({message});
  } else {
    const {
      rows,
      ...rest
    } = data;

    dispatch(setDataAction(rows));
    dispatch(optionsAction(rest));
    dispatch(loadingAction(false));
  }
};

export const cancelAction = (
  appUuid: string,
  t: TFunction,
  removeCampaign?: boolean,
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isRequesting: true,
  }));

  const row = getActionDialogData(getState());
  const apiBaseUrl = _apiBaseUrl(getState());
  const {uuid} = getActionDialogData(getState());

  const {
    hasError,
    body: {
      message,
      error_code,
    },
  } = await api.request(`${apiBaseUrl}${urlHelper(ApiRoutes.CANCEL_CAMPAIGN, {
    uuid,
    appUuid,
  })}?remove_campaign=${!!removeCampaign}`, 'DELETE');

  if (hasError) {
    if (error_code === 103) {
      dispatch(toggleDialogAction({
        data  : {
          ...row,
          dialogState: {
            timer          : 3,
            dismissLabel   : t('buttons.cancel'),
            agreeLabel     : t('buttons.delete'),
            bodyTitle      : t('campaigns.cancel_warning_title'),
            bodyDescription: t('campaigns.cancel_warning_description'),
            onClose        : () => dispatch(toggleDialogAction(void 0)),
            dismissAction  : () => dispatch(toggleDialogAction(void 0)),
            agreeAction    : () => dispatch(cancelAction(appUuid, t, true)),
          },
        },
        dialog: TableActions.SUBMIT_CANCEL,
      }));

    } else {
      dispatch(toggleDialogAction(void 0));
      notification.error({message});
    }
  } else {
    dispatch(toggleDialogAction(void 0));

    notification.success({message});

    await dispatch(fetchAction());
  }

  dispatch(dataOptionsAction({
    isRequesting: void 0,
  }));
};

export const handleExportAction = (
  as: string,
): Globals.ThunkAction => async (dispatch, getState, api) => {
  let lastQuery = getLastQuery(getState());
  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.get(`${lastQuery}&export=${as}`);

  notification.open({
    type   : hasError ? 'error' : 'success',
    message: data?.errors?.limits_notice || message,
  });
};

export const destroyAction = (
  appUuid: string,
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isRequesting: true,
  }));

  const apiBaseUrl = _apiBaseUrl(getState());
  const {uuid} = getActionDialogData(getState());

  const {
    hasError,
    body: {
      message,
    },
  } = await api.request(`${apiBaseUrl}${urlHelper(ApiRoutes.DELETE_CAMPAIGN, {
    uuid,
    appUuid,
  })}`, 'DELETE');

  dispatch(toggleDialogAction(void 0));

  notification.open({
    message,
    type: hasError ? 'error' : 'success',
  });

  dispatch(dataOptionsAction({
    isRequesting: void 0,
  }));

  if (!hasError) {
    await dispatch(fetchAction());
  }
};

export const getAction = (
  appUuid: string,
  uuid: string,
  {
    signal,
    isClone,
    redirect,
  }: {
    isClone?: boolean,
    signal?: AbortSignal,
    redirect?: () => void;
  } = {},
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isGetting: true,
  }));

  const userAcl = _userAcl(getState());
  const apiBaseUrl = _apiBaseUrl(getState());
  const currentApp = _currentApp(getState());

  let newUrl: string = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_CAMPAIGN, {
    uuid,
    appUuid,
  })}`;

  const isTemplate: boolean = Number.isInteger(Number(uuid));

  if (isTemplate) {
    newUrl = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_TEMPLATE, {
      uuid,
      appUuid,
    })}`;
  }

  const {
    hasError,
    body: {
      data,
      status,
    },
  } = await api.get(newUrl, {signal});

  if (!hasError) {
    let additionalDataUrl = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_SEGMENT_LANGUAGES, {appUuid})}?type_id=9&page=1&pageSize=500`;
    if (data?.data?.locale_type === LOCALE_TYPE.COUNTRY) {
      additionalDataUrl = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_SEGMENT_COUNTRIES, {appUuid})}?pageSize=500&page=1`;
    }
    const res = await api.get(additionalDataUrl, {signal});

    if (isClone && !isTemplate) {
      const campaignSegments = ((data?.front_data?.exclude_segments) || []).concat(
        data?.front_data?.include_segments || []
      );
      if (campaignSegments.length) {
        if (userAcl?.segments?.[FEATURE_GROUP]?.[Permissions.READ] &&
          userAcl?.segments?.[FEATURE_GROUP]?.[Permissions.VISIBLE]) {
          await dispatch(checkSegmentsAction(appUuid, campaignSegments));
        }
      }
    }

    const {
      availableSegments,
    } = getDataOptions(getState());

    let t_1: any = campaignTransformer({
      ...data,
      availableSegments
    }, res);

    if (isClone) {
      const messages = t_1.messages;
      t_1 = {
        ...t_1,
        uuid                 : void 0,
        deliver_at           : void 0,
        delivery             : 'immediately',
        per_user_optimization: 'immediately',
        wait_until_value     : 10,
        wait_until_unit      : {
          value: 'minute',
          label: 'minute',
        },
        ttl_unit             : DEFAULT_TTL.ttl_unit,
        ttl_value            : DEFAULT_TTL.ttl_value,
        messages             : {
          ...Object.keys(messages || {}).reduce((acc: any, curr: any) => {
            const message = messages[curr];
            acc[curr] = {
              ...message,
              icon_url: message.icon_url || currentApp.default_icon_url
            }
            return acc;
          }, {}),
        },
      }
    }

    if (isTemplate) {
      const messages = t_1.messages;
      let newT_1: any = {
        send_to              : 'all',
        messages             : {
          ...Object.keys(messages || {}).reduce((accumulator: any, currentValue: any) => {
            const message = messages[currentValue];

            accumulator[currentValue] = {
              ...message,
              url     : currentApp?.default_url,
              icon_url: message.icon_url || currentApp.default_icon_url,
            };
            return accumulator;
          }, {}),
        },
        template_id          : t_1.id,
        locale_type          : (data?.data?.locale_type === LOCALE_TYPE.LANGUAGE) ? LOCALE_TYPE.LANGUAGE : LOCALE_TYPE.COUNTRY,
        values               : t_1.values,
        delivery             : 'immediately',
        per_user_optimization: 'immediately',
        wait_until_unit      : {
          value: 'minute',
          label: 'minute',
        },
        ttl_unit             : DEFAULT_TTL.ttl_unit,
        ttl_value            : DEFAULT_TTL.ttl_value,
      };
      if (t_1.action) {
        newT_1 = {
          ...newT_1,
          action: t_1.action
        };
      }
      dispatch(toggleEntityAction(filterObject(newT_1)));
    } else {
      dispatch(toggleEntityAction(filterObject(t_1)));
    }
  } else {
    if (status === 404 && typeof redirect === 'function') {
      redirect();
    }
  }

  dispatch(dataOptionsAction({
    isGetting: void 0,
  }));
};

export const sendAction = (
  appUuid: string,
  {
    redirect,
  }: {
    redirect?: () => void;
  } = {}
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isSending: true,
  }));

  const apiBaseUrl = _apiBaseUrl(getState());
  let data = campaignFilter(getCampaign(getState()));
  const hasTimezonesAccess = _hasTimezonesAccess(getState());

  let newUrl: string = `${apiBaseUrl}${urlHelper(ApiRoutes.CREATE_SEND_CAMPAIGN, {
    appUuid,
  })}`;

  if (data.uuid) {
    newUrl = `${apiBaseUrl}${urlHelper(ApiRoutes.SEND_CAMPAIGN, {
      uuid: data.uuid,
      appUuid,
    })}`;
  }

  const userAcl = _userAcl(getState());
  if (!userAcl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE]) {
    data = {
      ...data,
      locale_type: LOCALE_TYPE.LANGUAGE,
      messages   : {
        default: {
          ...data?.messages?.default,
        },
      },
    }
  }

  if (!(userAcl?.segments?.[FEATURE_GROUP]?.[Permissions.READ] &&
    userAcl?.segments?.[FEATURE_GROUP]?.[Permissions.VISIBLE])
  ) {
    data = filterObject({
      ...data,
      send_to         : 'all',
      front_data      : void 0,
      exclude_segments: void 0,
      include_segments: void 0,
    });
  }

  if (!hasTimezonesAccess) {
    data = {
      ...data,
      per_user_optimization: 'immediately',
    };
  }

  const {
    hasError,
    body: {
      message,
      data: responseData
    },
  } = await api.post(newUrl, data);

  dispatch(dataOptionsAction({
    isSending: void 0,
  }));

  if (hasError) {
    if (responseData?.errors) {
      dispatch(toggleErrorsAction(responseData.errors));
    }
  } else {
    localStorage.setItem('push_notifications_active_tab', 'active');
    dispatch(optionsAction({
      tab: 'active',
    }));
    dataLayer({
      event: 'push_notification_sent',
    });
    if (typeof redirect === 'function') {
      redirect();
    }
  }

  if (responseData?.errors?.limits_notice) {
    const tmp = responseData?.errors?.limits_notice.split('{{UPGRADE}}');
    const style = {
      display   : 'inline-flex',
      alignItems: 'center',
      fontSize  : 16,
      fontWeight: 700,
      cursor    : 'pointer'
    };
    notification.error({
      duration: 30,
      message : <span style={{marginRight: 8}}>{tmp[0]}<a rel='noreferrer' style={style} href={urlHelper(AppRoutes.CHANGE_PLAN_APP, {appUuid})} target='_blank'>Upgrade</a>&nbsp;{tmp[1]}</span>,
    });
  } else {
    notification.open({
      type   : hasError ? 'error' : 'success',
      message: responseData?.errors?.messages || message,
    });
  }
};

export const checkSegmentsAction = (
  appUuid: string,
  uuidList: any[],
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isChecking: true,
  }));

  const apiBaseUrl = _apiBaseUrl(getState());

  let t = '';
  for (let i = 0; i < uuidList.length; ++i) {
    if (t.indexOf('?') === -1) {
      t = t + '?segments[]=' + uuidList[i]?.value;
    } else {
      t = t + '&segments[]=' + uuidList[i]?.value;
    }
  }

  const newUrl: string = `${apiBaseUrl}${urlHelper(ApiRoutes.CHECK_SEGMENTS, {
    appUuid,
    list: t
  })}`;

  const {
    hasError,
    body: {
      data,
    },
  } = await api.get(newUrl);

  dispatch(dataOptionsAction({
    isChecking       : void 0,
    availableSegments: !hasError && (uuidList || []).filter((i: any) => {
      return !!data.find((t1: any) => {
        return t1.id === i.value
      })
    }).map((i: any) => ({
      label: i.label,
      value: i.value,
    })),
  }));

  if (!hasError) {
    const deletedSegments = !hasError && (uuidList || []).filter((i: any) => {
      return !data.find((t1: any) => t1.id === i.value)
    }).map((i: any) => ({
      label: i.label,
      value: i.value,
    }));
    if (deletedSegments.length) {
      notification.warning({
        message: (
          <div>
            <p style={{margin: 0, fontSize: 14, fontWeight: 'bold'}}>There are some deleted segments</p>
            {deletedSegments.map((i: any) => <p key={i.value} style={{margin: 0, fontSize: 12}}>{i.label}</p>)}
          </div>
        ),
      });
    }
  }
};

export const createOrUpdateAction = (
  appUuid: string,
  {
    redirect,
  }: {
    redirect?: () => void;
  } = {},
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isCreatingOrUpdating: true,
  }));

  const apiBaseUrl = _apiBaseUrl(getState());
  const hasTimezonesAccess = _hasTimezonesAccess(getState());

  let data = campaignFilter(getCampaign(getState()));
  const userAcl = _userAcl(getState());
  if (!userAcl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE]) {
    data = {
      ...data,
      locale_type: LOCALE_TYPE.LANGUAGE,
      messages   : {
        default: {
          ...data.messages.default,
        },
      },
    }
  }

  if (!(userAcl?.segments?.[FEATURE_GROUP]?.[Permissions.READ] &&
    userAcl?.segments?.[FEATURE_GROUP]?.[Permissions.VISIBLE])
  ) {
    data = filterObject({
      ...data,
      send_to         : 'all',
      front_data      : void 0,
      exclude_segments: void 0,
      include_segments: void 0,
    });
  }

  if (!hasTimezonesAccess) {
    data = {
      ...data,
      per_user_optimization: 'immediately',
    };
  }

  let newUrl: string = `${apiBaseUrl}${urlHelper(ApiRoutes.CREATE_CAMPAIGN, {
    appUuid,
  })}`;

  if (data.uuid) {
    newUrl = `${apiBaseUrl}${urlHelper(ApiRoutes.UPDATE_CAMPAIGN, {
      uuid: data.uuid,
      appUuid,
    })}`;
  }

  const {
    hasError,
    body: {
      message,
      data: responseData
    },
  } = await api.post(newUrl, data);

  dispatch(dataOptionsAction({
    isCreatingOrUpdating: void 0,
  }));

  if (hasError) {
    dispatch(toggleErrorsAction(responseData?.errors));
  } else {
    localStorage.setItem('push_notifications_active_tab', 'draft');
    dispatch(optionsAction({
      tab: 'draft',
    }));

    if (typeof redirect === 'function') {
      redirect();
    }
  }

  if (responseData?.errors?.limits_notice) {
    const tmp = responseData?.errors?.limits_notice.split('{{UPGRADE}}');
    const style = {
      fontSize  : 16,
      fontWeight: 700,
      alignItems: 'center',
      cursor    : 'pointer',
      display   : 'inline-flex',
    };

    notification.error({
      duration: 30,
      message: <span style={{marginRight: 8}}>{tmp[0]}<a rel='noreferrer' style={style} href={urlHelper(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP, {appUuid})} target='_blank'>Upgrade</a>&nbsp;{tmp[1]}</span>,
    });
  } else {
    notification.open({
      type   : hasError ? 'error' : 'success',
      message: responseData?.errors?.messages || message,
    });
  }
};

export const getSingleChartStatsAction = (
  appUuid: string,
  uuid: string,
  {
    signal,
  }: {
    signal?: AbortSignal;
  } = {},
): Globals.ThunkAction => async (dispatch, getState, api) => {
  const apiBaseUrl = _apiBaseUrl(getState());

  let newUrl: any = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_CAMPAIGN_STATS, {
    uuid,
    appUuid,
  })}`;

  const {
    hasError,
    body: {
      data,
    },
  } = await api.get(newUrl, {signal});

  if (!hasError) {
    dispatch(setCampaignStatAction(data));
  }
};
