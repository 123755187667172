import React from 'react';
import Typography from '@material-ui/core/Typography';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {convertTimezone} from '../../../../../../../utils/convertTimezone';

export default function TimezoneFormatter(
  {
    value,
  }: DataTypeProvider.ValueFormatterProps
) {
  return (
    <Typography
      noWrap
      component='p'
      align='center'
      style={{fontSize: 12}}
    >{convertTimezone(value)}
    </Typography>
  )
}
