import {Menu} from 'antd';
import React from 'react';
import {Popconfirm} from 'antd';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {Text} from '../../../Typography';
import {PlanProgress} from '../PlanProgress';
import {isUUID} from '../../../../../utils/isUUID';
import {ItemType} from 'antd/lib/menu/hooks/useItems';
import {urlHelper} from '../../../../../utils/urlHelper';
import {aclKeys} from '../../../../../constants/aclKeys';
import LockOutlined from '@ant-design/icons/LockOutlined';
import SendOutlined from '@ant-design/icons/SendOutlined';
import BuildOutlined from '@ant-design/icons/BuildOutlined';
import {AppRoutes} from '../../../../../constants/appRoutes';
import RocketOutlined from '@ant-design/icons/RocketOutlined';
import {getPrefixCls} from '../../../../../utils/getPrefixCls';
import {useSmartPush} from '../../../../../hooks/useSmartPush';
import UserAddOutlined from '@ant-design/icons/UserAddOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import {_firstAppUUID} from '../../../../../store/selectors/apps';
import DingdingOutlined from '@ant-design/icons/DingdingOutlined';
import PieChartOutlined from '@ant-design/icons/PieChartOutlined';
import BarChartOutlined from '@ant-design/icons/BarChartOutlined';
import FileImageOutlined from '@ant-design/icons/FileImageOutlined';
import './index.less';

export function SiderMenu(
  {
    appUuid,
    collapsed,
    toggleDrawer,
  }: {
    appUuid?: string;
    collapsed?: boolean;
    toggleDrawer: () => void;
  }
) {
  const {
    user: {
      canRead,
      isAdmin,
      hasVisibility,
    }
  } = useSmartPush();
  const firstAppUUID = useSelector(_firstAppUUID);
  const {
    push,
    location: {
      pathname,
    },
  } = useHistory();
  const uuid = isUUID(appUuid) ? appUuid : firstAppUUID;

  const onClick = React.useCallback((href: string) => () => {
    if (typeof toggleDrawer === 'function') {
      toggleDrawer();
    }
    push(href);
  }, [
    push,
    toggleDrawer,
  ]);

  const [{
    openKeys,
    selectedKeys,
  }, setState] = React.useState<{
    openKeys: string[];
    selectedKeys: string[];
  }>({
    openKeys    : [
      urlHelper(AppRoutes.ANALYTICS, {appUuid: uuid}),
      urlHelper(AppRoutes.CAMPAIGNS, {appUuid: uuid}),
    ],
    selectedKeys: [pathname],
  });

  React.useEffect(() => {
    let selected = pathname;

    if (pathname.split('/').length > 5) {
      selected = pathname.split('/').slice(0, 5).join('/');
    } else if (pathname.includes(urlHelper(AppRoutes.SETTINGS, {appUuid}))) {
      selected = urlHelper(AppRoutes.SETTINGS, {appUuid});
    }

    setState(prevState => {
      return {
        ...prevState,
        selectedKeys: [selected]
      }
    })
  }, [
    appUuid,
    pathname,
  ]);

  const items: ItemType[] = [
    {
      children : [
        {
          title   : 'Analytics',
          label   : 'Analytics',
          icon    : <BarChartOutlined/>,
          key     : urlHelper(AppRoutes.ANALYTICS, {appUuid: uuid}),
          children: [
            {
              title  : 'Reports',
              label  : 'Reports',
              key    : urlHelper(AppRoutes.ANALYTICS_REPORTS, {appUuid: uuid}),
              onClick: onClick(urlHelper(AppRoutes.ANALYTICS_REPORTS, {appUuid: uuid})),
            },
            canRead(aclKeys.SUBSCRIBERS) && hasVisibility(aclKeys.SUBSCRIBERS) && {
              title  : 'Subscribers',
              label  : 'Subscribers',
              key    : urlHelper(AppRoutes.ANALYTICS_SUBSCRIBERS, {appUuid: uuid}),
              onClick: onClick(urlHelper(AppRoutes.ANALYTICS_SUBSCRIBERS, {appUuid: uuid})),
            },
          ]
        },
        canRead(aclKeys.CAMPAIGNS) && hasVisibility(aclKeys.CAMPAIGNS) && {
          title   : 'Campaigns',
          label   : 'Campaigns',
          icon    : <SendOutlined/>,
          key     : urlHelper(AppRoutes.CAMPAIGNS, {appUuid: uuid}),
          children: [
            {
              label  : 'Push Notifications',
              title  : 'Push Notifications',
              key    : urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid: uuid}),
              onClick: onClick(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid: uuid})),
            },
            {
              disabled: true,
              key     : 'Automations',
              title   : 'Automations',
              label   : 'Automations',
              itemIcon: <AutomationItemIcon/>,
            }
          ]
        },
        canRead(aclKeys.TEMPLATES) && hasVisibility(aclKeys.TEMPLATES) && {
          title  : 'Templates',
          label  : 'Templates',
          icon   : <FileImageOutlined/>,
          key    : urlHelper(AppRoutes.TEMPLATES, {appUuid: uuid}),
          onClick: onClick(urlHelper(AppRoutes.TEMPLATES, {appUuid: uuid})),
        },
        canRead(aclKeys.SEGMENTS) && hasVisibility(aclKeys.SEGMENTS) && {
          title  : 'Segments',
          label  : 'Segments',
          icon   : <PieChartOutlined/>,
          key    : urlHelper(AppRoutes.SEGMENTS, {appUuid: uuid}),
          onClick: onClick(urlHelper(AppRoutes.SEGMENTS, {appUuid: uuid})),
        },
        canRead(aclKeys.AUTOMATIONS) && hasVisibility(aclKeys.AUTOMATIONS) && {
          title  : 'Automations',
          label  : 'Automations',
          icon   : <DingdingOutlined/>,
          key    : urlHelper(AppRoutes.AUTOMATIONS, {appUuid: uuid}),
          onClick: onClick(urlHelper(AppRoutes.AUTOMATIONS, {appUuid: uuid})),
        },
        canRead(aclKeys.BUILDER) && hasVisibility(aclKeys.BUILDER) && {
          title  : 'Builder',
          label  : 'Builder',
          icon   : <BuildOutlined/>,
          key    : urlHelper(AppRoutes.BUILDER, {appUuid: uuid}),
          onClick: onClick(urlHelper(AppRoutes.BUILDER, {appUuid: uuid})),
        },
        canRead(aclKeys.USERS) && hasVisibility(aclKeys.USERS) && {
          title  : 'Users',
          label  : 'Users',
          icon   : <UserAddOutlined/>,
          key    : urlHelper(AppRoutes.USERS, {appUuid: uuid}),
          onClick: onClick(urlHelper(AppRoutes.USERS, {appUuid: uuid})),
        },
        isAdmin() && {
          title  : 'Apps',
          label  : 'Apps',
          icon   : <RocketOutlined/>,
          key    : urlHelper(AppRoutes.APPS, {appUuid: uuid}),
          onClick: onClick(urlHelper(AppRoutes.APPS, {appUuid: uuid})),
        },
        !collapsed && {
          type: 'divider',
        },
      ],
      type     : 'group',
      className: getPrefixCls('menu-item-group'),
    } as ItemType,
  ];

  return (
    <div
      className={getPrefixCls('menu-inline')}
    >
      <div>
        <Menu
          mode='inline'
          items={items}
          defaultOpenKeys={openKeys}
          selectedKeys={selectedKeys}
        />
        <PlanProgress
          appUuid={uuid}
          collapsed={collapsed}
        />
      </div>
      <Menu
        mode='inline'
        items={[
          canRead(aclKeys.APP_SETTINGS) && hasVisibility(aclKeys.APP_SETTINGS) && {
            title  : 'Settings',
            label  : 'Settings',
            icon   : <SettingOutlined/>,
            key    : urlHelper(AppRoutes.SETTINGS, {appUuid: uuid}),
            onClick: onClick(urlHelper(AppRoutes.SETTINGS, {appUuid: uuid})),
          }
        ]}
        defaultOpenKeys={openKeys}
        selectedKeys={selectedKeys}
      />
    </div>
  )
}

function AutomationItemIcon() {
  return (
    <Popconfirm
      okText='Ok'
      placement='bottomLeft'
      cancelButtonProps={{hidden: true}}
      title={(
        <Text>
          The Automations will be
          <br/>
          available in upcoming version.
        </Text>
      )}
    >
      <Text
        className={getPrefixCls('automation-item-icon-text')}
      >
        <LockOutlined
          className={getPrefixCls('automation-item-icon-lock-outlined')}
        />
      </Text>
    </Popconfirm>
  )
}
