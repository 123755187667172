import {Text} from '../../../../../../../components/antd/Typography';

export function Description() {
  return (
    <Text>
      This template is not used in any campaign yet.
      <br/>
      Once you have sent your first campaign using this template, you will see details on it shortly.
    </Text>
  )
}