import clsx from 'clsx';

export function Preview(
  {
    xlinkHref,
    className,
  }: {
    xlinkHref: string;
    className?: string;
  }
) {
  return (
    <div
      className={clsx(className)}
    >
      <svg
        fill='none'
        width='240'
        height='162'
        viewBox='0 0 240 162'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <path
          fill='#A3B5D6'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M240 23.0299H0.241211V6.49492C0.241211 2.9081 3.16106 0 6.76238 0H233.479C237.081 0 240 2.9081 240 6.49492V23.0299Z'
        />
        <path
          fill='#CCD7EE'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.0532 10.8127C17.0532 13.0637 15.2322 14.8888 12.9858 14.8888C10.7397 14.8888 8.91895 13.0637 8.91895 10.8127C8.91895 8.56168 10.7397 6.73661 12.9858 6.73661C15.2322 6.73661 17.0532 8.56168 17.0532 10.8127Z'
        />
        <path
          fill='#CCD7EE'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32.2385 10.8127C32.2385 13.0637 30.4177 14.8888 28.1714 14.8888C25.9253 14.8888 24.1045 13.0637 24.1045 10.8127C24.1045 8.56168 25.9253 6.73661 28.1714 6.73661C30.4177 6.73661 32.2385 8.56168 32.2385 10.8127Z'
        />
        <path
          fill='#CCD7EE'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M47.4241 10.8127C47.4241 13.0637 45.6033 14.8888 43.3569 14.8888C41.1108 14.8888 39.29 13.0637 39.29 10.8127C39.29 8.56168 41.1108 6.73661 43.3569 6.73661C45.6033 6.73661 47.4241 8.56168 47.4241 10.8127Z'
        />
        <path
          fill='#E4EBF7'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.26911 162C3.25474 162 0 159.474 0 156.357V22H240V156.357C240 159.474 236.746 162 232.731 162H7.26911Z'
        />
        <g
          filter='url(#filter0_d)'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M119.829 27.7714H8.40039V67.2H119.829V27.7714Z'
            fill='white'
          />
          <rect
            x='39'
            y='34'
            width='74'
            height='4'
            fill='#E4EBF7'
          />
          <rect
            x='39'
            y='42'
            width='47'
            height='4'
            fill='#E4EBF7'
          />
          <rect
            x='86'
            y='58'
            width='27'
            height='4'
            fill='#1890FF'
          />
          <g
            opacity='0.8'
          >
            <rect
              width='24'
              height='24.0002'
              fill='url(#pattern0)'
              transform='translate(11 30)'
            />
          </g>
        </g>
        <defs>
          <filter
            y='21.7714'
            x='0.400391'
            id='filter0_d'
            width='127.429'
            height='55.4286'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood
              floodOpacity='0'
              result='BackgroundImageFix'
            />
            <feColorMatrix
              type='matrix'
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            />
            <feOffset
              dy='2'
            />
            <feGaussianBlur
              stdDeviation='4'
            />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow'
            />
            <feBlend
              mode='normal'
              result='shape'
              in='SourceGraphic'
              in2='effect1_dropShadow'
            />
          </filter>
          <pattern
            width='1'
            height='1'
            id='pattern0'
            patternContentUnits='objectBoundingBox'
          >
            <use
              xlinkHref='#image0'
              transform='translate(-0.00913163) scale(0.00101421)'
            />
          </pattern>
          <image
            id='image0'
            width='1004'
            height='986'
            xlinkHref={xlinkHref}
          />
        </defs>
      </svg>

    </div>
  )
}