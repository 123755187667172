import {Draft} from 'immer';
import * as React from 'react';
import {Text} from '../../Typography';
import {Line1} from './components/Line1';
import {Line2} from './components/Line2';
import {LetsGo} from './components/LetsGo';
import {Surveys} from '../../../../types/surveys';
import {getPrefixCls} from '../../../../utils/getPrefixCls';

export function Wizard(
  {
    data,
    letsGo,
    setData,
  }: {
    data: Surveys.Data;
    letsGo: () => void;
    setData: (f: (draft: Draft<Surveys.Data>) => void | Surveys.Data) => void;
  }
) {
  return (
    <div
      className={`d-flex flex-column align-items-center ${getPrefixCls('survey-wizardContainer')}`}
    >
      <div
        className={`d-flex flex-column ${getPrefixCls('survey-wizardWrapper')}`}
      >
        <Text
          strong
          type='secondary'
          className={getPrefixCls('survey-wizardTitle')}
        >Get Started
        </Text>
        <Line1
          data={data}
          setData={setData}
        />
        {data.professionSelected && (
          <Line2
            data={data}
            setData={setData}
          />
        )}
        <LetsGo
          data={data}
          letsGo={letsGo}
        />
      </div>
    </div>
  )
}
