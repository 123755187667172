import {subDays} from 'date-fns';
import {TFunction} from 'i18next';
import {addMonths} from 'date-fns';
import {endOfToday} from 'date-fns';
import {endOfMonth} from 'date-fns';
import {startOfMonth} from 'date-fns';
import {startOfToday} from 'date-fns';
import {endOfYesterday} from 'date-fns';
import {startOfYesterday} from 'date-fns';
import {createStaticRanges} from 'react-date-range';

export default function customRanges(t: TFunction) {
  return createStaticRanges([
    {
      label: t('ranges.today'),
      range: () => ({
        endDate  : endOfToday(),
        startDate: startOfToday(),
      })
    },
    {
      label: t('ranges.yesterday'),
      range: () => ({
        endDate  : endOfYesterday(),
        startDate: startOfYesterday(),
      })
    },
    {
      label: t('ranges.last_3_days'),
      range: () => ({
        startDate: subDays(new Date(), 2),
        endDate  : new Date(),
      })
    },
    {
      label: t('ranges.last_7_days'),
      range: () => ({
        startDate: subDays(new Date(), 6),
        endDate  : new Date(),
      })
    },
    {
      label: t('ranges.this_month'),
      range: () => ({
        startDate: startOfMonth(new Date()),
        endDate  : new Date(),
      })
    },
    {
      label: t('ranges.last_30_days'),
      range: () => ({
        startDate: subDays(new Date(), 29),
        endDate  : new Date(),
      })
    },
    {
      label: t('ranges.last_month'),
      range: () => {
        return {
          startDate: startOfMonth(addMonths(new Date(), -1)),
          endDate  : endOfMonth(addMonths(new Date(), -1)),
        }
      }
    },
  ])
}