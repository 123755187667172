import {Form} from 'antd';
import React from 'react';
import {Divider} from 'antd';
import {useRegister} from './useRegister';
import {Link as RLink} from 'react-router-dom';
import {dataPrefixes} from '../../../../constants';
import {GoogleAuth} from '../../Common/GoogleAuth';
import {Redirecting} from '../../Common/Redirecting';
import {FormContent} from './components/FormContent';
import {AppRoutes} from '../../../../constants/appRoutes';
import {Text} from '../../../../components/antd/Typography';
import {Title} from '../../../../components/antd/Typography';
import {RecaptchaActions} from '../../../../constants/recaptchaActions';
import '../../index.less';

const {
  auth: {
    register: registerPrefix
  }
} = dataPrefixes;

export function Register() {
  const {
    control,
    onSubmit,
    register,
    clearErrors,
    searchString,
    formState: {
      isSubmitting,
      isSubmitSuccessful,
    },
  } = useRegister();

  return (
    <>
      {!isSubmitSuccessful && (
        <>
          <div
            className='m-b-5'
          >
            <Title
              level={5}
              align='center'
              fontWeight='regular'
            >Sign Up for Free Now
            </Title>
          </div>
          <GoogleAuth
            action='gmail_registration'
            buttonLabel='Sign Up with Google'
            reCaptchaAction={RecaptchaActions.REGISTER}
            buttonProps={{'data-qa': `${registerPrefix}-gmail-register-button`}}
          />
          <Divider>or</Divider>
        </>
      )}
      <form
        noValidate
        className='form'
        autoComplete='off'
        onSubmit={onSubmit}
      >
        <input
          type='hidden'
          {...register('plan')}
        />
        {isSubmitSuccessful ? (
          <Redirecting
            title='You have Signed Up.'
            subTitle='Redirecting to the App ...'
          />
        ) : (
          <FormContent
            control={control}
            clearErrors={clearErrors}
            isSubmitting={isSubmitting}
          />
        )}
      </form>
      {!isSubmitSuccessful && (
        <>
          <Form
            component='div'
            className='form'
            layout='vertical'
          >
            <div
              className='d-flex justify-content-between'
            >
              <Text
              >Have an account?
              </Text>
              <RLink
                className='ant-typography regular'
                data-qa={`${registerPrefix}-login-link`}
                to={!searchString ? AppRoutes.LOGIN : `${AppRoutes.LOGIN}?${searchString}`}
              >Sign In
              </RLink>
            </div>
          </Form>
        </>
      )}
    </>
  )
}
