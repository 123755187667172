import clsx from 'clsx';
import React from 'react';
import './index.less';

export function Content(
  {
    gutter,
    ...props
  }: React.HTMLProps<HTMLDivElement> & { gutter?: number | string }
) {
  return (
    <div
      {...props}
      className={clsx('renderer', props.className)}
      style={(gutter || gutter === 0) ? {padding: gutter} : void 0}
    />
  )
}