import {Row} from 'antd';
import {Col} from 'antd';
import React from 'react';
import {Space} from 'antd';
import {Button} from 'antd';
import {Elements} from '@stripe/react-stripe-js';
import {usePayAndLaunch} from './usePayAndLaunch';
import {GrowthPlan} from './components/GrowthPlan';
import {SubmitButton} from './components/SubmitButton';
import {HeaderWrapper} from './components/HeaderWrapper';
import {Content} from '../../../../../components/antd/Content';
import {initStripePromise} from '../../../../../utils/initStripe';
import {PaymentDetails} from '../../../../../components/antd/PaymentDetails';

export function PayAndLaunch() {
  return (
    <Elements
      options={{locale: 'en'}}
      stripe={initStripePromise()}
    >
      <Form/>
    </Elements>
  )
}

function Form() {
  const {
    goBack,
    submit,
    control,
    register,
    setValue,
    clearErrors,
    skipCheckout,
    skippingCheckout,
    subscriptionPlans,
    formState: {
      isSubmitting,
    }
  } = usePayAndLaunch();

  return (
    <div>
      <form
        onSubmit={submit}
      >
        <input
          type='hidden'
          {...register('interval')}
        />
        <HeaderWrapper
          control={control}
        />
        <Content
          gutter={8}
        >
          <div
            className='d-flex align-items-center justify-content-center flex-column'
          >
            <Row
              gutter={8}
              align='top'
              justify='space-between'
              style={{width: '100%', maxWidth: 792}}
            >
              <Col
                md={9}
                xs={24}
              >
                <GrowthPlan
                  control={control}
                  clearErrors={clearErrors}
                  isSubmitting={isSubmitting}
                  skipCheckout={skipCheckout}
                  skippingCheckout={skippingCheckout}
                  subscriptionPlans={subscriptionPlans}
                />
              </Col>
              <Col
                md={15}
                xs={24}
              >
                <PaymentDetails
                  control={control}
                  setValue={setValue}
                />
              </Col>
              <Col
                xs={24}
              >
                <div
                  className='right'
                >
                  <Space
                    size={8}
                  >
                    <Button
                      onClick={goBack}
                      disabled={isSubmitting || skippingCheckout}
                    >Go Back
                    </Button>
                    <SubmitButton
                      control={control}
                      isSubmitting={isSubmitting}
                      skippingCheckout={skippingCheckout}
                    />
                  </Space>
                </div>
              </Col>
            </Row>
          </div>
        </Content>
      </form>
    </div>
  )
}
