import clsx from 'clsx';
import React from 'react';
import {PageHeader as AntdPageHeader} from 'antd';
import {PageHeaderProps} from 'antd/lib/page-header';
import {globalConfig} from 'antd/lib/config-provider';
import './index.less';

export const PageHeader: React.FC<PageHeaderProps & { align?: 'center' }> = (
  {
    align,
    ...restProps
  }
) => {
  return (
    <AntdPageHeader
      {...restProps}
      className={clsx(restProps.className, align && globalConfig().getPrefixCls('page-header-heading-center', restProps.prefixCls))}
    />
  )
}
