import clsx from 'clsx';
import React from 'react';
import {Omit} from '@material-ui/types';
import {PropTypes} from '@material-ui/core';
import {fade} from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import {Theme} from '@material-ui/core/styles';
import {darken} from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ButtonProps as MuiButtonProps} from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) => ({
  btn           : {
    boxShadow             : 'none !important',
    transition            : theme.transitions.create(['width']),
    '& > .MuiButton-label': {
      transition: theme.transitions.create(['all']),
    }
  },
  btnSuccess    : {
    color          : '#fff',
    backgroundColor: green[500],
    '&:hover'      : {
      backgroundColor: darken(green[500], .2),
      borderColor    : darken(green[500], .2),
    },
    '&:active'     : {
      backgroundColor: darken(green[500], .2),
      borderColor    : darken(green[500], .2),
    },
    '&:disabled'   : {
      boxShadow      : 'none',
      color          : 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  btnWarning    : {
    color          : '#fff',
    backgroundColor: orange[500],
    '&:hover'      : {
      backgroundColor: darken(orange[500], .2),
      borderColor    : darken(orange[500], .2),
    },
    '&:active'     : {
      backgroundColor: darken(orange[500], .2),
      borderColor    : darken(orange[500], .2),
    },
  },
  btnWhite      : {
    color          : '#000000',
    backgroundColor: '#ffffff',
    '&:hover'      : {
      backgroundColor: darken('#ffffff', .2),
      borderColor    : darken('#ffffff', .2),
    },
    '&:active'     : {
      backgroundColor: darken('#ffffff', .2),
      borderColor    : darken('#ffffff', .2),
    },
    '&:focus'      : {
      backgroundColor: darken('#ffffff', .2),
      borderColor    : darken('#ffffff', .2),
    },
  },
  btnTransparent: {
    boxShadow      : 'none',
    color          : theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover'      : {
      boxShadow      : 'none',
      color          : '#EF4526',
      backgroundColor: 'transparent',
      borderColor    : 'transparent',
    },
    '&:active'     : {
      boxShadow      : 'none',
      color          : '#EF4526',
      backgroundColor: 'transparent',
      borderColor    : 'transparent',
    },
    '&:focus'      : {
      boxShadow      : 'none',
      color          : '#EF4526',
      backgroundColor: 'transparent',
      borderColor    : 'transparent',
    },
  },
  outlinedInfo  : {
    color      : '#EF4526',
    borderColor: '#EF4526',
    '&:hover'  : {
      backgroundColor: fade('#EF4526', theme.palette.action.hoverOpacity),
    },
    '&:active' : {
      backgroundColor: fade('#EF4526', theme.palette.action.hoverOpacity),
    },
    '&:focus'  : {
      backgroundColor: fade('#EF4526', theme.palette.action.hoverOpacity),
    },
  },
  containedInfo : {
    color          : '#fff',
    backgroundColor: '#EF4526',
    '&:hover'      : {
      backgroundColor: darken('#EF4526', theme.palette.action.hoverOpacity),
      borderColor    : darken('#EF4526', theme.palette.action.hoverOpacity),
    },
    '&:active'     : {
      backgroundColor: darken('#EF4526', theme.palette.action.hoverOpacity),
      borderColor    : darken('#EF4526', theme.palette.action.hoverOpacity),
    },
    '&:focus'      : {
      backgroundColor: darken('#EF4526', theme.palette.action.hoverOpacity),
      borderColor    : darken('#EF4526', theme.palette.action.hoverOpacity),
    },
  },
  outlinedRed   : {
    color      : '#E03826',
    borderColor: '#E03826',
    '&:hover'  : {
      backgroundColor: fade('#E03826', theme.palette.action.hoverOpacity),
    },
    '&:active' : {
      backgroundColor: fade('#E03826', theme.palette.action.hoverOpacity),
    },
    '&:focus'  : {
      backgroundColor: fade('#E03826', theme.palette.action.hoverOpacity),
    },
  },
  containedRed  : {
    color          : '#fff',
    backgroundColor: '#E03826',
    '&:hover'      : {
      backgroundColor: darken('#E03826', theme.palette.action.hoverOpacity),
      borderColor    : darken('#E03826', theme.palette.action.hoverOpacity),
    },
    '&:active'     : {
      backgroundColor: darken('#E03826', theme.palette.action.hoverOpacity),
      borderColor    : darken('#E03826', theme.palette.action.hoverOpacity),
    },
    '&:focus'      : {
      backgroundColor: darken('#E03826', theme.palette.action.hoverOpacity),
      borderColor    : darken('#E03826', theme.palette.action.hoverOpacity),
    },
  },

  containedDanger: {
    color          : '#fff',
    backgroundColor: red[500],
    '&:hover'      : {
      backgroundColor: darken(red[500], .2),
      borderColor    : darken(red[500], .2),
    },
    '&:active'     : {
      backgroundColor: darken(red[500], .2),
      borderColor    : darken(red[500], .5),
    },
    '&:disabled'   : {
      boxShadow      : 'none',
      color          : 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  outlinedDanger : {
    color      : red[500],
    borderColor: red[500],
    '&:hover'  : {
      backgroundColor: fade(red[500], theme.palette.action.hoverOpacity),
    },
    '&:active' : {
      backgroundColor: fade(red[500], theme.palette.action.hoverOpacity),
    },
    '&:focus'  : {
      backgroundColor: fade(red[500], theme.palette.action.hoverOpacity),
    },
  },

  containedBlue: {
    color          : '#fff',
    backgroundColor: '#096dd9',
    '&:hover'      : {
      backgroundColor: darken('#096dd9', theme.palette.action.hoverOpacity),
      borderColor    : darken('#096dd9', theme.palette.action.hoverOpacity),
    },
    '&:active'     : {
      backgroundColor: darken('#096dd9', theme.palette.action.hoverOpacity),
      borderColor    : darken('#096dd9', theme.palette.action.hoverOpacity),
    },
    '&:focus'      : {
      backgroundColor: darken('#096dd9', theme.palette.action.hoverOpacity),
      borderColor    : darken('#096dd9', theme.palette.action.hoverOpacity),
    },
  },
  outlinedBlue : {
    color      : '#096dd9',
    borderColor: '#096dd9',
    '&:hover'  : {
      backgroundColor: fade('#096dd9', theme.palette.action.hoverOpacity),
    },
    '&:active' : {
      backgroundColor: fade('#096dd9', theme.palette.action.hoverOpacity),
    },
    '&:focus'  : {
      backgroundColor: fade('#096dd9', theme.palette.action.hoverOpacity),
    },
  },
}));

export const Button: React.FC<ButtonProps & { component?: any, htmlFor?: any }> = (
  {
    color,
    loading,
    disabled,
    children,
    className,
    ...rest
  }
) => {
  const classes: any = useStyles();
  switch (color) {
    case 'info': {
      return (
        <MuiButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disabled={disabled}
          color='default'
          className={clsx(classes.btn, className, (rest && rest.variant && classes[`${rest.variant}Info`]))}
          {...rest}
        >
          {!!loading && (
            <CircularProgress
              size={14}
              color='primary'
              style={{marginRight: 8}}
            />
          )}
          {children}
        </MuiButton>
      );
    }
    case 'red': {
      return (
        <MuiButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disabled={disabled}
          color='default'
          className={clsx(classes.btn, className, (rest && rest.variant && classes[`${rest.variant}Red`]))}
          {...rest}
        >
          {!!loading && (
            <CircularProgress
              size={14}
              color='primary'
              style={{marginRight: 8}}
            />
          )}
          {children}
        </MuiButton>
      );
    }
    case 'white': {
      return (
        <MuiButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disabled={disabled}
          {...rest}
          color='default'
          className={clsx(classes.btnWhite, classes.btn, className)}
        >
          {!!loading && (
            <CircularProgress
              size={14}
              color='primary'
              style={{marginRight: 8}}
            />
          )}
          {children}
        </MuiButton>
      );
    }
    case 'danger': {
      return (
        <MuiButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disabled={disabled}
          {...rest}
          color='default'
          className={clsx(classes.btn, className, (rest?.variant && classes[`${rest.variant}Danger`]))}
        >
          {!!loading && (
            <CircularProgress
              size={14}
              color='primary'
              style={{marginRight: 8}}
            />
          )}
          {children}
        </MuiButton>
      );
    }
    case 'success': {
      return (
        <MuiButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disabled={disabled}
          {...rest}
          color='default'
          className={clsx(classes.btnSuccess, classes.btn, className)}
        >
          {!!loading && (
            <CircularProgress
              size={14}
              color='primary'
              style={{marginRight: 8}}
            />
          )}
          {children}
        </MuiButton>
      );
    }
    case 'warning': {
      return (
        <MuiButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disabled={disabled}
          {...rest}
          color='default'
          className={clsx(classes.btnWarning, classes.btn, className)}
        >
          {!!loading && (
            <CircularProgress
              size={14}
              color='primary'
              style={{marginRight: 8}}
            />
          )}
          {children}
        </MuiButton>
      );
    }
    case 'blue': {
      return (
        <MuiButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disabled={disabled}
          color='default'
          className={clsx(classes.btn, className, (rest?.variant && classes[`${rest.variant}Blue`]))}
          {...rest}
        >
          {!!loading && (
            <CircularProgress
              size={14}
              color='primary'
              style={{marginRight: 8}}
            />
          )}
          {children}
        </MuiButton>
      );
    }
    case 'transparent': {
      return (
        <MuiButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disabled={disabled}
          {...rest}
          color='default'
          className={clsx(classes.btnTransparent, classes.btn, className)}
        >
          {!!loading && (
            <CircularProgress
              size={14}
              color='primary'
              style={{marginRight: 8}}
            />
          )}
          {children}
        </MuiButton>
      );
    }
    default:
      return (
        <MuiButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disabled={disabled}
          {...rest}
          color={color}
          className={clsx(className, classes.btn)}
        >
          {!!loading && (
            <CircularProgress
              size={14}
              color='primary'
              style={{marginRight: 8}}
            />
          )}
          {children}
        </MuiButton>
      );
  }
};

type CustomColor = 'blue' | 'red' | 'info' | 'danger' | 'success' | 'warning' | 'white' | 'transparent';
type ButtonColor = CustomColor | PropTypes.Color;

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  to?: any;
  component?: any;
  loading?: boolean;
  'data-id'?: string;
  color?: ButtonColor;

  [key: string]: any
}
