import {useImmer} from 'use-immer';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {Surveys} from '../../../types/surveys';
import {reasons} from '../../../constants/survey';
import {dataLayer} from '../../../utils/dataLayer';
import {urlHelper} from '../../../utils/urlHelper';
import {subscribers} from '../../../constants/survey';
import {frequencies} from '../../../constants/survey';
import {competitors} from '../../../constants/survey';
import {professions} from '../../../constants/survey';
import {surveyState} from '../../../constants/survey';
import {AppRoutes} from '../../../constants/appRoutes';
import {tryingWebPush} from '../../../constants/survey';
import {amNotUsingAnyService} from '../../../constants/survey';
import {howDidYouFindUsOptions} from '../../../constants/survey';
import {_firstName} from '../../../store/selectors/application/acl';
import {_surveyData} from '../../../store/selectors/application/acl';
import {_storeSurvey} from '../../../store/actions/application/storeSurvey';

export function useSurvey() {
  const dispatch = useDispatch();
  const history = useHistory<any>();
  const firstName = useSelector(_firstName);
  const surveyData = useSelector(_surveyData);
  const {appUuid} = useParams<{ appUuid?: string }>();

  const [data, setData] = useImmer<Surveys.Data>({
    reasons,
    professions,
    frequencies,
    competitors,
    subscribers,
    howDidYouFindUsOptions,
    layerIsVisible         : true,
    started                : !!surveyData.profession,
    reason                 : surveyData.reason,
    reasonSelected         : (surveyData.reason === tryingWebPush) ? false : !!surveyData.reason,
    profession             : surveyData.profession,
    professionSelected     : !!surveyData.profession,
    competitor             : surveyData.competitor,
    competitorSelected     : (surveyData.competitor === amNotUsingAnyService) ? false : !!surveyData.competitor,
    subscriber             : surveyData.subscriber,
    subscriberSelected     : !!surveyData.subscriber,
    frequency              : surveyData.frequency,
    frequencySelected      : !!surveyData.frequency,
    howDidYouFindUs        : surveyData.howDidYouFindUs,
    howDidYouFindUsSelected: !!surveyData.howDidYouFindUs,
  });

  function start() {
    setData(draft => {
      draft.started = true
    })
  }

  async function skip() {
    dataLayer({
      event: 'survey_skip',
    });
    await dispatch(_storeSurvey({
      key  : 'state',
      value: surveyState.SKIPPED,
    }));
    if (history.location.state?.from) {
      if ([
        urlHelper(AppRoutes.APPS_INTEGRATE_APP, {appUuid}),
        urlHelper(AppRoutes.APPS_PAY_AND_LAUNCH_APP, {appUuid}),
      ].includes(history.location.state?.from)) {
        if (appUuid) {
          history.replace(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid}));
        } else {
          history.replace(AppRoutes.APPS_CREATE);
        }
      } else {
        history.replace(history.location.state.from);
      }
    } else {
      window.location.replace('/');
    }
  }

  async function letsGo() {
    await dispatch(_storeSurvey({
      key  : 'state',
      value: 'finished',
    }));
  }

  const redirect = surveyData.state === surveyState.FINISHED;

  return {
    data,
    skip,
    start,
    letsGo,
    setData,
    redirect,
    firstName,
  }
}
