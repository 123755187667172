import React from 'react';
import {filterObject} from '../../utils';
import {register} from '../../serviceWorker';
import {useTranslation} from 'react-i18next';
import {Button} from '../Button';
import Tooltip from '@material-ui/core/Tooltip';
import {ButtonProps} from '../Button';

export default function SelfTesting(
  {
    disabled,
    notification: {
      actions,
      ...restNotification
    },
    ...rest
  }: ButtonProps & { notification: any }
) {
  const {t} = useTranslation();
  const entity = Object.keys(filterObject(restNotification)).reduce((acc: any, current: any) => {
    try {
      acc[current] = restNotification[current]?.trim();
    } catch (e) {
      acc[current] = restNotification?.[current];
    }
    return acc;
  }, {});

  const onClick = React.useCallback(() => {
    let newData: any = {};

    const newActions = (Object.keys(actions || {})).map((key: any) => {
      const a = actions[key];
      if (a.type && a.url && a.title) {
        newData = {
          ...newData,
          [a.type]: a.url,
        }
      }
      if (a.type && a.url && a.title) {
        return filterObject({
          action: a.type,
          title : a.title,
          icon  : a.icon_url,
        })
      } else {
        return void 0;
      }
    }).filter(v => v);

    register(entity.title, filterObject({
      body   : entity.message,
      icon   : entity.icon_url,
      image  : entity.image_url,
      data   : {
        url    : entity.url,
        actions: {
          ...newData
        }
      },
      actions: [
        ...newActions
      ]
    })).catch(console.error)
  }, [
    entity,
    actions,
  ])

  return (
    <>
      {(!entity.title || !entity.url) ? (
        <Tooltip
          title={t('messages.title_is_required')}
        >
          <div>
            <Button
              disabled
              {...rest}
              size='small'
              onClick={onClick}
            />
          </div>
        </Tooltip>
      ) : (
        <Button
          {...rest}
          size='small'
          onClick={onClick}
          disabled={disabled}
        />
      )}
    </>
  )
}
