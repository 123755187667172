import React from 'react';
import {useParams, useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {Input, Button, Row, Tooltip, Select} from 'antd';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {urlHelper} from '../../../../utils/urlHelper';
import {aclKeys} from '../../../../constants/aclKeys';
import {AppRoutes} from '../../../../constants/appRoutes';
import {useSmartPush} from '../../../../hooks/useSmartPush';
import {_updateFilters} from'../../../../store/actions/users/actions';
import {getUsersData} from '../../../../store/actions/users/services';
import {SubscriptionPlanType} from '../../../../types/subscriptionPlan';

import {_getFetchState, _getFilters, _getSubscriptionPlans, _getPermissions} from '../../../../store/selectors/users';
import './index.less';

const { Option } = Select;
const {Search} = Input;
const initialPageNumber = 1;

const FilterSection: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {appUuid} = useParams<{ appUuid: string }>();
  const filters = useSelector(_getFilters);
  const subscriptionPlans = useSelector(_getSubscriptionPlans);
  const permissions = useSelector(_getPermissions);
  const fetchingState = useSelector(_getFetchState);
  
  const { user: { canCreate } } = useSmartPush();

  const permissionsList = React.useMemo(() =>
    permissions.map((item: {value: string, label: string}) => {
      return (<Option key={item.value} value={item.value}>{item.label}</Option>);
    }
  ), [permissions]);

  const plansList = React.useMemo(() =>
    subscriptionPlans.map((item: SubscriptionPlanType) => {
      return (<Option key={item.id} value={item.slug}>{item.name}</Option>);
    }
  ), [subscriptionPlans]);

  const handlePermissionChange = (value: string[]) => {
    dispatch(_updateFilters({permissionsFilter: value}));
    if (!value.length) {
      onFilter();
    }
  };

  const handleTemplateChange = (value: string[]) => {
    dispatch(_updateFilters({planTemplateFilter: value}));
    if (!value.length) {
      onFilter();
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    dispatch(_updateFilters({searchText: value}));
    if (!value) {
      onReload();
    }
  };

  const onFilter = () => {
    if (filters.pageNumber === initialPageNumber) {
      dispatch(getUsersData());
    } else {
      dispatch(_updateFilters({pageNumber: initialPageNumber}));
    }
  };

  const onReload = () => {
    dispatch(getUsersData());
  };
 
  return (
    <div className='wrapper'>
      <Row className='filter-section align-items-center '>
        <Select
          mode='multiple'
          maxTagCount='responsive'
          maxTagTextLength={10}
          className='filter-select'
          placeholder='Filter by Permission'
          onChange={handlePermissionChange}
        >
          {permissionsList}
        </Select>
        <Select
          mode='multiple'
          maxTagCount='responsive'
          maxTagTextLength={10}
          className='filter-select'
          placeholder='Filter by Template'
          onChange={handleTemplateChange}
        >
          {plansList}
        </Select>
        <Button
          ghost
          type='primary'
          onClick={onFilter}
          children='Filter'
        />
      </Row>
      <Row className='align-items-center justify-content-between'>
        <Row wrap={false}>
          <Search
            onChange={handleSearchChange}
            onSearch={onFilter}
            placeholder='Search'
            defaultValue={filters.searchText}
          />
          {canCreate(aclKeys.AUTOMATIONS) &&
            <Button
              type='primary'
              className='m-l-1'
              onClick={() => history.push(urlHelper(AppRoutes.CREATE_USER, {appUuid}))}
              icon={<PlusOutlined/>}
              children={'Add User'}
            />
          }
        </Row>
        <Tooltip title='Refresh' placement='left'>
          <Button
            shape='circle'
            onClick={onReload}
            icon={<ReloadOutlined style={{color: '#0000008a'}}/>}
            disabled={!fetchingState}
          />
        </Tooltip>
      </Row>
    </div>
  )
};

export default FilterSection;
