import {notification} from 'antd';
import {_dataOptions} from './index';
import {_upsertEntity} from '../apps';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _togglePrompt = (
  appUuid: string,
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    isTogglingPrompt: FiniteStates.LOADING,
  }));

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.post(urlHelper(ApiRoutes.TOGGLE_PROMPT, {appUuid}));
  dispatch(_upsertEntity(data));

  dispatch(_dataOptions({
    isTogglingPrompt: hasError ? FiniteStates.FAILURE : FiniteStates.SUCCESS,
  }));

  notification.open({
    message,
    type: hasError ? 'error' : 'success'
  });
};
