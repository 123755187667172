import {filterObject} from './index';

const vars = [
  'var1',
  'var2',
  'var3',
  'var4',
  'var5',
  'var6',
  'var7',
  'var8',
  'var9',
  'var10',
];

export function responseTransformer(app: Record<string, any>) {
  const adParams: {
    name: string,
    track: boolean,
    column: string,
    parameter: string,
    placeholder: string,
  }[] = Object.values(app?.advanced_parameters || {});
  const advanced_parameters = vars.map((v) => {
    const current = adParams.find((p) => p.column === v);
    return {
      column: v,
      track : !!current?.track,
      name  : current?.name || '',
    }
  });

  return {
    advanced_parameters,
    uuid            : app?.uuid,
    domain          : app?.domain,
    webhooks        : !!app?.webhooks,
    title           : app?.title || '',
    default_url     : app?.default_url,
    force_prompt    : !!app?.force_prompt,
    wh_click        : app?.wh_click || '',
    wh_close        : app?.wh_close || '',
    wh_display      : app?.wh_display || '',
    wh_subscribe    : app?.wh_subscribe || '',
    default_icon_url: app?.default_icon_url || '',
    gdpr_compliance : app?.details?.gdpr_compliance || false,
  }
}

export function requestTransformer(formValues: Record<string, any>) {
  const {
    advanced_parameters,
    ...rest
  } = formValues;

  const adParams = advanced_parameters.reduce((
    acc: any,
    current: {
      name: string;
      track: boolean;
      column: string;
    }
  ) => {
    acc[current.column] = {
      placeholder: current?.name,
      name       : current?.name,
      parameter  : current?.name,
      track      : current?.track,
    };
    return acc;
  }, {});

  return filterObject({
    ...rest,
    ...adParams,
    force_prompt: !!rest.force_prompt,
    default_url : formValues.default_url || '',
    wh_click    : (rest.webhooks && !!rest.wh_click) ? `${rest.wh_click || ''}` : void 0,
    wh_close    : (rest.webhooks && !!rest.wh_close) ? `${rest.wh_close || ''}` : void 0,
    wh_display  : (rest.webhooks && !!rest.wh_display) ? `${rest.wh_display || ''}` : void 0,
    wh_subscribe: (rest.webhooks && !!rest.wh_subscribe) ? `${rest.wh_subscribe || ''}` : void 0,
  })
}
