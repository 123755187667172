import React from 'react';
import { Card, Button, Dropdown, Menu, Row, Image, Tooltip } from 'antd';
import { TagFilled, EllipsisOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import {Builder} from '../../types/builder';

const iconStyle = {
  marginRight: 10,
  marginTop: 2,
  color: '#0000008a',
};

interface IProps {
  rowData: Builder.IconEntity | Builder.ImageEntity;
  onEditClick: (data: Builder.IconEntity | Builder.ImageEntity) => void;
  onDeleteClick: (data: Builder.IconEntity | Builder.ImageEntity) => void;
};

const MediaCard: React.FC<IProps> = ({rowData, onEditClick, onDeleteClick}) => {

  const menu = React.useMemo(() => {
    return (
      <Menu style={{width: 120}}>
        <Menu.Item
          key='edit'
          onClick={() => onEditClick(rowData)}
        >
          <Row align='middle'>
            <EditFilled style={iconStyle}/>
            <div>Edit</div>
          </Row>
        </Menu.Item>
        <Menu.Item
          key='delete'
          onClick={() => onDeleteClick(rowData)}
        >
          <Row align='middle'>
            <DeleteFilled style={{...iconStyle, color: '#f44336'}}/>
            <div>Delete</div>
          </Row>
        </Menu.Item>
      </Menu>
    );
  },[onDeleteClick, onEditClick, rowData]);

  return (
    <Card
      cover={
        <Image
          width={'100%'}
          height={150}
          alt={rowData.value}
          src={rowData.value}
        />
      }
      actions={[
        <div># {rowData.id}</div>,
        <Tooltip placement='bottom' title={rowData.tags}>
          <TagFilled style={iconStyle}/>
          <span>Tags</span>
        </Tooltip>,
        <Dropdown overlay={menu} trigger={['click']} key={rowData.id}>
          <Button
            title='Actions'
            shape='circle'
            size='small'
            icon={<EllipsisOutlined style={{fontSize: '18px'}}/>}
          />
        </Dropdown>
      ]}
    >
    </Card>
  );
};

export default MediaCard;
