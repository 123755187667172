import React from 'react';
import {IsMobile} from '../../../components/antd/IsMobile';

export function IsMobileProvider(
  {
    children
  }: {
    children: React.ReactNode;
  }
) {
  return (
    <>
      <IsMobile/>
      {children}
    </>
  );
}