import React from 'react';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import {AppRoutes} from '../../../constants/appRoutes';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {dataOptionsAction} from '../../../store/actions/gallery';
import {getDataOptions as getGalleryDataOptions} from '../../../store/selectors/gallery';

const useStyles = makeStyles(() => ({
  message: {
    flexGrow : 1,
    textAlign: 'center',
  },
  alert  : {
    paddingTop     : 0,
    borderRadius   : 0,
    paddingBottom  : 0,
    fontSize       : 16,
    width          : '100%',
    backgroundColor: '#EC5555',
    color          : '#ffffff',
  },
}));

export function LimitsNotice(
  {
    matchPath,
  }: {
    matchPath: string | string[];
  }
): null | JSX.Element {
  const {
    limits_notice,
    limits_notice_is_open,
  } = useSelector(getGalleryDataOptions);

  const render = React.useMemo(() => {
    return (
      !!limits_notice &&
      limits_notice_is_open &&
      !Array.isArray(matchPath) && [
        AppRoutes.BUILDER,
        AppRoutes.ANALYTICS_REPORTS,
        AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS,
        AppRoutes.TEMPLATES,
        AppRoutes.SETTINGS,
      ].includes(matchPath)
    )
  }, [
    matchPath,
    limits_notice,
    limits_notice_is_open,
  ]);

  return render ? <Before/> : null;
}

function Before() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    limits_notice,
  } = useSelector(getGalleryDataOptions);

  const onClose = React.useCallback(() => {
    dispatch(dataOptionsAction({
      limits_notice_is_open: false,
    }))
  }, [
    dispatch,
  ]);

  return (
    <Alert
      icon={false}
      severity='error'
      onClose={onClose}
      className={classes.alert}
      classes={{message: classes.message}}
    >{limits_notice}
    </Alert>
  );
}