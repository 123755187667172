import {IObject} from '../../../types';
import {ActionTypes} from '../../constants/settings';
import {ActionTypes as AppActionTypes} from '../../constants/application';

const InitialState: IObject = {
  general : {
    formData     : {},
    error        : void 0,
    success      : void 0,
    requestParams: void 0,
  },
  security: {
    formData     : {},
    error        : void 0,
    success      : void 0,
    requestParams: void 0,
  },
};

export default (state = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.CORE_HANDLE_SETTINGS: {
      return {
        ...state,
        ...payload
      };
    }
    case ActionTypes.UPDATE_TIMER: {
      let timer;
      if (state.timer) {
        timer = state.timer - 1;
      } else {
        timer = 0;
      }

      return {
        ...state,
        timer: timer
      };
    }
    case ActionTypes.CORE_GENERAL_SETTINGS_FORM_ERROR: {
      return {
        ...state,
        general: {
          ...state.general,
          success: void 0,
          error  : payload,
        },
      };
    }
    case ActionTypes.CORE_HANDLE_GENERAL_SETTINGS_FORM: {
      return {
        ...state,
        general: {
          ...state.general,
          formData: {
            ...state.general.formData,
            ...payload,
          },
        },
      };
    }
    case ActionTypes.CORE_SECURITY_SETTINGS_FORM_ERROR: {
      return {
        ...state,
        security: {
          ...state.security,
          success: void 0,
          error  : payload,
        },
      };
    }
    case ActionTypes.CORE_HANDLE_SECURITY_SETTINGS_FORM: {
      return {
        ...state,
        security: {
          ...state.security,
          formData: {
            ...state.security.formData,
            ...payload,
          }
        },
      };
    }
    case ActionTypes.CORE_GENERAL_SETTINGS_FORM_SUCCESS: {
      return {
        ...state,
        general: {
          ...state.general,
          error        : void 0,
          requestParams: void 0,
          success      : payload,
        },
      };
    }
    case ActionTypes.CORE_GENERAL_SETTINGS_FORM_LOADING: {
      return {
        ...state,
        general: {
          ...state.general,
          loading: payload,
        },
      };
    }
    case ActionTypes.CORE_SECURITY_SETTINGS_FORM_LOADING: {
      return {
        ...state,
        security: {
          ...state.security,
          loading: payload,
        },
      };
    }
    case ActionTypes.CORE_REQUESTING_GENERAL_SETTINGS_FORM_START: {
      return {
        ...state,
        general: {
          ...state.general,
          error        : void 0,
          success      : void 0,
          requestParams: payload,
        },
      };
    }
    case ActionTypes.CORE_REQUESTING_SECURITY_SETTINGS_FORM_START: {
      return {
        ...state,
        security: {
          ...state.security,
          error        : void 0,
          success      : void 0,
          requestParams: payload,
        },
      };
    }
    case AppActionTypes.LOGOUT: {
      return {
        ...InitialState
      }
    }
    default:
      return state;
  }
};
