import React from 'react';
import {parse} from 'querystring';
import Dialog from '../../../Dialog';
import {stringify} from 'querystring';
import {IObject} from '../../../../types';
import {Area} from 'react-easy-crop/types';
import {Point} from 'react-easy-crop/types';
import {useTranslation} from 'react-i18next';
import {MediaSize} from 'react-easy-crop/types';
import ImageCropper from '../../../ImageCropper';
import {CircularProgress} from '../../../StrictCircularProgress';

export default function Crop(
  {
    row,
    open,
    onClick,
    onClose,
    aspectRatio,
    onCloseGallery,
  }: {
    onClick?: any;
    row?: IObject;
    open?: boolean;
    aspectRatio: number;
    onClose?: () => any;
    onCloseGallery?: any;
  }
) {
  const {t} = useTranslation();
  const [crop, setCrop] = React.useState<Point>({x: 0, y: 0});
  const [mediaSize, setMediaSize] = React.useState<MediaSize | undefined>();
  const [initialCroppedAreaPixels, setInitialCroppedAreaPixels] = React.useState<Area | undefined>(void 0);

  React.useEffect(() => {
    if (row.thumbnail.split('?')[1]) {
      setInitialCroppedAreaPixels(prevState => ({
        ...prevState,
        ...parse(row.thumbnail.split('?')[1])
      }))
    }
  }, [
    row.thumbnail,
  ]);

  return (
    <Dialog
      fullWidth
      open={open}
      content={{
        children: (
          <div
            style={{height: 'calc(100vh - 230px)'}}
          >
            {!mediaSize && (
              <div
                style={{
                  display       : 'flex',
                  alignItems    : 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress/>
              </div>
            )}
            <ImageCropper
              crop={crop}
              image={row.src}
              aspect={aspectRatio}
              onCropChange={setCrop}
              onMediaLoaded={setMediaSize}
              style={{opacity: !mediaSize ? 0 : 1}}
              initialCroppedAreaPixels={initialCroppedAreaPixels}
              onCropComplete={(croppedArea, croppedAreaPixels) => {
                setInitialCroppedAreaPixels(croppedAreaPixels)
              }}
            />
          </div>
        )
      }}
      maxWidth={false}
      onClose={onClose}
      disableBackdropClick
      actions={{
        align  : 'left',
        actions: [
          {
            color  : 'success',
            variant: 'contained',
            label  : t('buttons.crop_save'),
            onClick: () => {
              onClick(`${row.thumbnail.split('?')[0]}?${stringify(initialCroppedAreaPixels)}`);
              onClose();
              onCloseGallery();
            },
          },
          {
            onClick: onClose,
            color  : 'primary',
            variant: 'outlined',
            label  : t('buttons.close'),
          },
        ],
      }}
      dialogTitle={{
        onClose: onClose,
        color  : 'primary',
      }}
    />
  )
}
