import React from 'react';
import {Select} from 'antd';
import {useStore} from 'react-redux';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {getPrefixCls} from '../../../../../../utils/getPrefixCls';
import {fetchMapping} from '../../../../../../store/actions/reports';
import {FiniteStates} from '../../../../../../constants/finiteStates';
import {_reportsDates} from '../../../../../../store/selectors/reports';
import {_selectedCharts} from '../../../../../../store/selectors/reports';
import {_setSelectedCharts} from '../../../../../../store/actions/reports';
import {_hasConversionsAccess} from '../../../../../../store/selectors/application/acl';

const chartSelectOptions_1 = [
  {
    'value': 'total',
    'label': 'Total'
  },
  {
    'value': 'eppdm',
    'label': 'EPPDM'
  },
  {
    'value': 'unsubscribed',
    'label': 'Unsubscribed'
  },
  {
    'value': 'period_unsubscribed_and_subscribed',
    'label': 'Subscribed and Unsubscribed in Period'
  },
  {
    'value': 'clicks',
    'label': 'Clicks'
  },
  {
    'value': 'closes',
    'label': 'Closes'
  },
  {
    'value': 'displays',
    'label': 'Displays'
  },
  {
    'value': 'ctr',
    'label': 'CTR'
  },
  {
    'value': 'epc',
    'label': 'EPC'
  },
  {
    'value': 'revenue',
    'label': 'Revenue'
  },
  {
    'value': 'dailyInactive',
    'label': 'Daily Inactive Users'
  }
]

const chartSelectOptions_2 = [
  {
    'value': 'period_unsubscribed_and_subscribed',
    'label': 'Subscribed and Unsubscribed in Period'
  },
  {
    'value': 'dailyInactive',
    'label': 'Daily Inactive Users'
  },
  {
    'value': 'total',
    'label': 'Total'
  },
  {
    'value': 'unsubscribed',
    'label': 'Unsubscribed'
  },
  {
    'value': 'clicks',
    'label': 'Clicks'
  },
  {
    'value': 'closes',
    'label': 'Closes'
  },
  {
    'value': 'displays',
    'label': 'Displays'
  },
  {
    'value': 'ctr',
    'label': 'CTR'
  },
]

export function ChartSelect() {
  const store = useStore();
  const dispatch = useDispatch();
  const dates = useSelector(_reportsDates);
  const {appUuid} = useParams<{ appUuid: string }>();
  const selectedCharts = useSelector(_selectedCharts);
  const hasConversionsAcl = useSelector(_hasConversionsAccess);

  function onChange(value: any, option: any) {
    dispatch(_setSelectedCharts(option))
  }

  function onSelect(value: any) {
    const v = fetchMapping({
      dates,
      appUuid,
      dispatch,
    }).find((v) => v.value === value);

    if (v) {
      if (v.finiteState()(store.getState()) === FiniteStates.IDLE) {
        v?.fetch();
      }
    }
  }

  return (
    <Select
      bordered
      allowClear
      mode='multiple'
      maxTagCount={'responsive'}
      onChange={onChange}
      onSelect={onSelect}
      value={selectedCharts}
      placeholder='Select Metrics to Show on Dashboard'
      className={getPrefixCls('analytics-reports-chartsSelect')}
      options={hasConversionsAcl ? chartSelectOptions_1 : chartSelectOptions_2}
    />
  )
}