import {Text} from '../../../../../../components/antd/Typography';

export function Message({value}: { value: string }) {
  return (
    <Text
      ellipsis
      style={{maxWidth: '60rem'}}
    >{value}
    </Text>
  )
}