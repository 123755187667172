import React from 'react';
import {Button} from '../Button';
import Box from '@material-ui/core/Box';
import {ErrorMessage} from '../ErrorMessage';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import ImageIcon from '@material-ui/icons/Image';
import PublishIcon from '@material-ui/icons/Publish';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {PhotoGalleryDialog} from '../PhotoGalleryDialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {UseFormGetValues} from 'react-hook-form/dist/types/form';

function avatarStyles(aspectRatio: number): React.CSSProperties {
  return {
    borderRadius: 0,
    height      : 120,
    width       : aspectRatio * 120,
  }
}

export function FileAdornment(
  {
    name,
    disabled,
    setValue,
    getValues,
    aspectRatio,
    defaultValue,
  }: {
    name: string;
    disabled?: boolean;
    aspectRatio: number;
    defaultValue?: string;
    setValue: UseFormSetValue<Record<string, any>>;
    getValues: UseFormGetValues<Record<string, any>>;
  }
) {
  const [
    anchorIcon,
    setAnchorIcon
  ] = React.useState<undefined | HTMLElement>(void 0);

  const value = getValues(name) || defaultValue;

  const onClose = React.useCallback(() => {
    setAnchorIcon(void 0)
  }, []);

  const iconButtonOnClick = React.useCallback((e) => {
    setAnchorIcon(e.currentTarget)
  }, []);

  const setSelected = React.useCallback((value: string) => {
    setValue(name, value, {shouldDirty: true, shouldValidate: true});
  }, [
    name,
    setValue,
  ]);

  return (
    <InputAdornment
      position='start'
    >
      <IconButton
        size='small'
        disableRipple
        disableTouchRipple
        disableFocusRipple
        disabled={disabled}
        onClick={iconButtonOnClick}
      >
        <PublishIcon
          fontSize='small'
        />
      </IconButton>
      {!!value && (
        <Tooltip
          placement='left'
          disableFocusListener={disabled}
          disableHoverListener={disabled}
          disableTouchListener={disabled}
          title={
            <Avatar
              src={value}
              style={avatarStyles(aspectRatio)}
            />
          }
        >
          <Typography
            component='div'
          >
            <IconButton
              size='small'
              disableRipple
              disableTouchRipple
              disableFocusRipple
              disabled={disabled}
            >
              <ImageIcon
                fontSize='small'
              />
            </IconButton>
          </Typography>
        </Tooltip>
      )}
      {!!anchorIcon && (
        <PhotoGalleryDialog
          selected={value}
          onClose={onClose}
          aspectRatio={aspectRatio}
          setSelected={setSelected}
          open={Boolean(anchorIcon)}
        />
      )}
    </InputAdornment>
  )
}

function helperTextStyles(disabled?: boolean): React.CSSProperties {
  return {
    marginLeft: 8,
    fontSize  : '12px',
    color     : disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.54)',
  }
}

function valueTypographyStyles(disabled?: boolean): React.CSSProperties {
  return {
    marginLeft: 8,
    maxWidth  : 250,
    fontWeight: 700,
    fontSize  : '12px',
    color     : disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.54)',
  };
}

export function ButtonAdornment(
  {
    name,
    label,
    disabled,
    setValue,
    getValues,
    helperText,
    aspectRatio,
    errorMessage,
    defaultValue,
  }: {
    name: string;
    label: string;
    disabled?: boolean;
    aspectRatio: number;
    helperText?: string;
    errorMessage?: string;
    defaultValue?: string;
    setValue: UseFormSetValue<Record<string, any>>;
    getValues: UseFormGetValues<Record<string, any>>;
  }
) {
  const [
    anchorIcon,
    setAnchorIcon
  ] = React.useState<undefined | HTMLElement>(void 0);

  const value = getValues(name) || defaultValue;

  const onClose = React.useCallback(() => {
    setAnchorIcon(void 0)
  }, []);

  const removeSelected = React.useCallback(() => {
    setValue(name, void 0, {shouldDirty: true, shouldValidate: true});
  }, [
    name,
    setValue,
  ]);

  const iconButtonOnClick = React.useCallback((e) => {
    setAnchorIcon(e.currentTarget)
  }, []);

  const setSelected = React.useCallback((value: string) => {
    setValue(name, value, {shouldDirty: true, shouldValidate: true});
  }, [
    name,
    setValue,
  ]);

  return (
    <Box
      marginTop={2}
      display='flex'
      marginBottom={1}
      flexDirection='column'
    >
      <Box
        display='flex'
        marginRight={1}
        marginBottom={.5}
        alignItems='center'
      >
        <Typography
          color='primary'
          style={disabled ? {color: 'rgba(0, 0, 0, 0.38)'} : void 0}
        >
          <Typography
            component='span'
          >{label}
          </Typography>
          {!!helperText && (
            <Typography
              component='span'
              style={helperTextStyles(disabled)}
            >{helperText}
            </Typography>
          )}
        </Typography>
      </Box>
      <Box
        display='flex'
        alignItems='center'
      >
        <Button
          size='small'
          color='primary'
          variant='outlined'
          disabled={disabled}
          style={{width: 100}}
          onClick={iconButtonOnClick}
        >{!!value ? 'Replace' : 'Select'}
        </Button>
        {!!value && (
          <>
            <Tooltip
              placement='top-start'
              disableFocusListener={disabled}
              disableHoverListener={disabled}
              disableTouchListener={disabled}
              title={
                <Avatar
                  src={value}
                  style={avatarStyles(aspectRatio)}
                />
              }
            >
              <Typography
                noWrap
                style={valueTypographyStyles(disabled)}
              >{value}
              </Typography>
            </Tooltip>
            {disabled ? (
              <div>
                <IconButton
                  size='small'
                  disabled={disabled}
                  onClick={removeSelected}
                >
                  <RemoveCircleIcon
                    style={{fontSize: '16px'}}
                    color={disabled ? 'disabled' : 'error'}
                  />
                </IconButton>
              </div>
            ) : (
              <Tooltip
                title='Remove'
              >
                <div>
                  <IconButton
                    size='small'
                    disabled={disabled}
                    onClick={removeSelected}
                  >
                    <RemoveCircleIcon
                      style={{fontSize: '16px'}}
                      color={disabled ? 'disabled' : 'error'}
                    />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </>
        )}
      </Box>
      <ErrorMessage
        message={errorMessage}
      />
      {!!anchorIcon && (
        <PhotoGalleryDialog
          selected={value}
          onClose={onClose}
          aspectRatio={aspectRatio}
          setSelected={setSelected}
          open={Boolean(anchorIcon)}
        />
      )}
    </Box>
  )
}
