import React from 'react';
import {Info} from './Components/Info';
import Box from '@material-ui/core/Box';
import {Radio} from './Components/Radio';
import {useWatch} from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import {useCheckout} from './useCheckout';
import Link from '@material-ui/core/Link';
import {Controller} from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import {loadStripe} from '@stripe/stripe-js';
import Hidden from '@material-ui/core/Hidden';
import {Theme} from '@material-ui/core/styles';
import {Button} from '../../components/Button';
import {urlHelper} from '../../utils/urlHelper';
import {Elements} from '@stripe/react-stripe-js';
import {useTheme} from '@material-ui/core/styles';
import {UseFormClearErrors} from 'react-hook-form';
import {AppRoutes} from '../../constants/appRoutes';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import {ExistingCard} from './Components/ExistingCard';
import push_logo_3 from '../../images/logoPushDark.svg';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Control} from 'react-hook-form/dist/types/form';
import {PaymentDetails} from './Components/PaymentDetails';
import {ErrorMessage} from '../../components/ErrorMessage';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {CompletePayment} from './Components/CompletePayment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {CircularProgress} from '../../components/StrictCircularProgress';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const useStyles = makeStyles(({spacing, breakpoints}: Theme) => ({
  button          : {
    borderRadius: 4,
  },
  container       : {
    paddingLeft             : spacing(18),
    paddingRight            : spacing(18),
    [breakpoints.down('md')]: {
      paddingLeft : spacing(1),
      paddingRight: spacing(1),
    }
  },
  skipStep        : {
    lineHeight: 1,
    fontSize  : 16,
    fontWeight: 400,
    color     : 'rgba(0,0,0,.4)',
  },
  formControlLabel: {
    fontSize  : 20,
    lineHeight: 1.3,
    fontWeight: 400,
    color     : '#000000',
  },
  box             : {
    backgroundColor: '#f8f8f8',
  },
}));

export function Checkout() {
  return (
    <Elements
      stripe={stripePromise}
      options={{locale: 'en'}}
    ><CheckoutContent/>
    </Elements>
  )
}

export function CheckoutContent() {
  const theme = useTheme();
  const classes = useStyles();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    user,
    errors,
    submit,
    control,
    appUuid,
    setError,
    showSkip,
    register,
    setValue,
    countries,
    clearErrors,
    currentPlan,
    isSubmitting,
    skipCheckout,
    paymentMethod,
    scheduledPlan,
    fetchingPortal,
    prorationTotal,
    skippingCheckout,
  } = useCheckout();

  if (!currentPlan) {
    return null;
  }
  if (fetchingPortal || !prorationTotal) {
    return (
      <Box
        marginTop={3}
        display='flex'
        justifyContent='center'
      >
        <CircularProgress/>
      </Box>
    )
  }
  if (currentPlan.plan_slug === user.plan) {
    return (
      <Redirect to='/'/>
    )
  }
  if (scheduledPlan) {
    return (
      <Redirect
        to={!!appUuid ? urlHelper(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP, {
          appUuid,
        }) : AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS}
      />
    )
  }

  return (
    <form
      onSubmit={submit}
    >
      <ClearErrors
        control={control}
        clearErrors={clearErrors}
      />
      <input
        type='hidden'
        {...register('interval')}
      />
      <input
        type='hidden'
        {...register('plan_slug')}
      />
      <input
        type='hidden'
        {...register('payment_method')}
      />
      <Paper
        square
      >
        <CssBaseline/>
        <Box
          height='100%'
          maxWidth={1200}
          marginLeft='auto'
          minHeight='100vh'
          marginRight='auto'
        >
          <Container
            className={classes.container}
          >
            <Box
              paddingTop={3}
              display='flex'
              marginBottom={3}
              justifyContent={smDown ? 'center' : 'flex-start'}
            >
              <a
                href='https://smartpush.ai/'
              >
                <img
                  height={90}
                  width={284}
                  src={push_logo_3}
                  alt='Smart Push Logo'
                />
              </a>
            </Box>
            {showSkip && (
              <Box
                display='flex'
                marginBottom={4}
                alignItems='center'
                justifyContent='flex-end'
              >
                {skippingCheckout ? (
                  <>
                    <Box
                      display='flex'
                      marginRight={.5}
                    >
                      <CircularProgress
                        size={15}
                      />
                    </Box>
                    <Typography
                      href='#'
                      align='right'
                      component={Link}
                      underline='always'
                      className={classes.skipStep}
                    >Skip this step
                    </Typography>
                  </>
                ) : (
                  <Typography
                    href='#'
                    align='right'
                    component={Link}
                    underline='always'
                    onClick={skipCheckout}
                    className={classes.skipStep}
                  >Skip this step
                  </Typography>
                )}
              </Box>
            )}
            <Box
              display='flex'
              paddingBottom={3}
              alignItems='flex-start'
              justifyContent={!smDown ? 'space-between' : 'center'}
            >
              <Hidden
                smDown
              ><Info/>
              </Hidden>
              <Box
                flexGrow={1}
                maxWidth={800}
              >
                <Box
                  height='100%'
                  borderRadius={8}
                  className={classes.box}
                  padding={smDown ? 3 : 5.5}
                  border='1px solid rgba(87, 87, 87, 0.37)'
                  boxShadow='0px 6px 8px 0px rgba(209, 204, 204, 0.2)'
                >
                  <CompletePayment
                    currentPlan={currentPlan}
                    prorationTotal={prorationTotal}
                  />
                  <ErrorMessage
                    message={errors?.plan_slug?.message}
                    style={{fontSize: 20, textAlign: 'center'}}
                  />
                  <ErrorMessage
                    message={errors?.payment_method?.message}
                    style={{fontSize: 20, textAlign: 'center'}}
                  />
                  {!!paymentMethod ? (
                    <Box>
                      <Controller
                        name='card'
                        control={control}
                        render={(
                          {
                            field: {
                              value,
                              onChange,
                            },
                          }
                        ) => {
                          return (
                            <RadioGroup
                              value={value}
                              onChange={onChange}
                            >
                              <Box
                                marginBottom={2.5}
                              >
                                <FormControlLabel
                                  control={<Radio/>}
                                  value='existing_card'
                                  label='Use Existing Card'
                                  classes={{label: classes.formControlLabel}}
                                />
                                <ExistingCard
                                  control={control}
                                  paymentMethod={paymentMethod}
                                />
                              </Box>
                              <Box>
                                <FormControlLabel
                                  value='new_card'
                                  control={<Radio/>}
                                  label='Change Card'
                                  classes={{label: classes.formControlLabel}}
                                />
                                <PaymentDetails
                                  control={control}
                                  setValue={setValue}
                                  setError={setError}
                                  countries={countries}
                                  clearErrors={clearErrors}
                                  paymentMethod={paymentMethod}
                                />
                              </Box>
                            </RadioGroup>
                          )
                        }}
                      />
                    </Box>
                  ) : (
                    <Box>
                      <PaymentDetails
                        control={control}
                        setValue={setValue}
                        setError={setError}
                        countries={countries}
                        clearErrors={clearErrors}
                        paymentMethod={paymentMethod}
                      />
                    </Box>
                  )}
                  <Box
                    display='flex'
                    justifyContent={xsDown ? 'center' : 'flex-end'}
                  >
                    <Button
                      type='submit'
                      color='primary'
                      fullWidth={xsDown}
                      variant='contained'
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      className={classes.button}
                    >Pay Securely Now
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Paper>
    </form>
  );
}

function ClearErrors(
  {
    control,
    clearErrors,
  }: {
    control: Control<Record<string, any>>;
    clearErrors: UseFormClearErrors<Record<string, any>>;
  }
): null {
  const card = useWatch({
    control,
    name: 'card',
  });

  React.useEffect(() => {
    clearErrors();
  }, [
    card,
    clearErrors,
  ])

  return null;
}