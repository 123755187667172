export enum ActionTypes {
  WEB_NOTIFY_USERS_DATA_OPTIONS = 'WEB_NOTIFY_USERS_DATA_OPTIONS',
  WEB_NOTIFY_USERS_TOGGLE_ERRORS = 'WEB_NOTIFY_USERS_TOGGLE_ERRORS',
  WEB_NOTIFY_USERS_UPDATE_ERRORS = 'WEB_NOTIFY_USERS_UPDATE_ERRORS',
  WEB_NOTIFY_USERS_TOGGLE_DIALOG = 'WEB_NOTIFY_USERS_TOGGLE_DIALOG',
  WEB_NOTIFY_USERS_TOGGLE_ENTITY = 'WEB_NOTIFY_USERS_TOGGLE_ENTITY',
  WEB_NOTIFY_USERS_UPDATE_ENTITY = 'WEB_NOTIFY_USERS_UPDATE_ENTITY',
  WEB_NOTIFY_USERS_FEATURE_GROUPS = 'WEB_NOTIFY_USERS_FEATURE_GROUPS',
  WEB_NOTIFY_USERS_TOGGLE_LOADING = 'WEB_NOTIFY_USERS_TOGGLE_LOADING',
  WEB_NOTIFY_USERS_TOGGLE_PERMISSIONS = 'WEB_NOTIFY_USERS_TOGGLE_PERMISSIONS',
  WEB_NOTIFY_USERS_SUBSCRIPTION_PLANS = 'WEB_NOTIFY_USERS_SUBSCRIPTION_PLANS',
  //refactoring
  USERS_SET_SUBSCRIPTION_PLANS = 'users/SET_SUBSCRIPTION_PLANS',
  USERS_SET_FEATURE_GROUPS = 'users/SET_FEATURE_GROUPS',
  USERS_SET_DATA = 'users/SET_DATA',
  USERS_DELETE_DATA = 'users/DELETE_DATA',
  USERS_UPDATE_FILTERS = 'users/UPDATE_FILTERS',
  USERS_SET_LOADING = 'users/SET_LOADING',
  USERS_CLEANUP = 'user/CLEANUP',
}
