import React from 'react';
import {Row, Select, Button, Form} from 'antd';
import {Controller} from 'react-hook-form';
import {DeleteFilled, PlusCircleOutlined} from '@ant-design/icons';
import {Automations} from '../../../../types/automations';
import {timeOptions} from '../../../../constants/timeOptions';
import {Control} from "react-hook-form/dist/types/form";
const { Item } = Form;
const { Option } = Select;

type Field = {
  to: string;
  from: string;
  id: number;
};

interface IProps {
  fieldsArray: any,
  control: Control<any>,
  append: (data: {to: string, from: string}) => void,
  remove: (index: number) => void,
};

const TimeRangeSelect: React.FC<IProps> = ({ fieldsArray, control, remove, append }) => {

  const memoList = React.useMemo(() =>
    timeOptions.map(item => {
      return (<Option key={item} value={item}>{item}</Option>);
    }
  ), []);

  return (
    <>
      <Form component='div' layout='vertical' style={{width: '100%'}}>
        <Form.Item label='Hour Range'>
          {fieldsArray.map((item: Field, index: number) => {
            return (
              <Row align='middle' justify='start' key={item.id} className='selectTimeInput_row'>
                <div className='input-wrapper'>
                  <Controller
                    name={`hour_ranges.${index}.from` as string as keyof Automations.AutomationForm}
                    control={control}
                    rules={{ required: 'This field is required'}}
                    defaultValue={item.from}
                    render={(({ field: { value, onChange }, fieldState: {invalid, error} }) => {
                      return (
                        <Item
                          validateStatus={invalid && 'error'}
                          help={error?.message}
                        >
                          <Select
                            onSearch={(searchValue)=>{
                              if(searchValue) {
                                onChange(searchValue)
                              }
                            }}
                            autoClearSearchValue={false}
                            onChange={onChange}
                            value={value}
                            className='selectTimeInput'
                            showSearch
                          >
                            {memoList}
                          </Select>
                        </Item>
                      )
                    })}
                  />
                </div>
                <div className='separator'>To</div>
                <div className='input-wrapper'>
                  <Controller
                    name={`hour_ranges.${index}.to`}
                    control={control}
                    defaultValue={item.to}
                    rules={{ required: 'This field is required'}}
                    render={(({ field: { value, onChange }, fieldState: {invalid, error} }) => {
                      return (
                        <Item
                          validateStatus={invalid && 'error'}
                          help={error?.message}
                        >
                          <Select
                            onSearch={(searchValue)=>{
                              if(searchValue) {
                                onChange(searchValue)
                              }
                            }}
                            autoClearSearchValue={false}
                            onChange={onChange}
                            value={value}
                            className='selectTimeInput'
                            showSearch
                          >
                            {memoList}
                          </Select>
                        </Item>
                      )
                    })}
                  />
                </div>
                {fieldsArray.length > 1 &&
                  <DeleteFilled
                    className='deleteIcon'
                    onClick={() => remove(index)}
                    title='Delete'
                  />
                }
              </Row>
            )
          })}
        </Form.Item>
      </Form>
      <Button
        type='link'
        onClick={() => append({ to: '', from: '' })}
        icon={<PlusCircleOutlined/>}
        className='addRange'
      >
        Add Range
      </Button>
    </>
  );
};

export default TimeRangeSelect;
