import clsx from 'clsx';
import React from 'react';
import Box from '@material-ui/core/Box';
import {Theme} from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  sumTypography  : {
    lineHeight                    : 1,
    fontSize                      : 26,
    marginRight                   : 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
  },
  subHeader      : {
    fontSize                      : 16,
    fontWeight                    : 400,
    lineHeight                    : 1.625,
    color                         : '#000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  completePayment: {
    fontSize                      : 30,
    fontWeight                    : 400,
    lineHeight                    : .833,
    color                         : '#000000',
    marginBottom                  : theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  cursorPointer  : {
    cursor: 'pointer',
  },
  table          : {
    borderCollapse: 'collapse',
    '& td'        : {
      padding                       : theme.spacing(1),
      border                        : '1px solid #dddddd',
      fontSize                      : 16,
      fontWeight                    : 400,
      lineHeight                    : 1.625,
      color                         : '#000000',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    }
  },
  infoTypography : {
    lineHeight                    : 1,
    fontSize                      : 12,
    color                         : 'rgba(0, 0, 0, .7)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
  },
}));

export function CompletePayment(
  {
    currentPlan,
    prorationTotal,
  }: {
    prorationTotal: Record<string, any>;
    currentPlan: {
      plan_name: string;
      plan_slug: string;
      unit_amount: number;
      price_per_1000: number;
      free_displays_count: number;
      max_subscribers_count?: number;
    }
  }
) {
  const classes = useStyles();
  const descriptions = React.useMemo(() => {
    if (!(prorationTotal?.descriptions || []).length) {
      return null;
    }
    return (
      <table
        className={classes.table}
      >
        {(prorationTotal?.descriptions || []).map(({description, amount}: Record<string, any>, index: number) => {
          return (
            <tr
              key={index}
            >
              <td
                align='left'
              >
                <span
                  style={{fontSize: 12, color: 'rgba(0, 0, 0, 0.7)'}}
                >{description}
                </span>
              </td>
              <td
                key={index}
                align='right'
              >
                {(amount < 0) ? '-$' : '$'}{Math.abs(amount)}
              </td>
            </tr>
          )
        })}
      </table>
    )
  }, [
    classes,
    prorationTotal?.descriptions,
  ]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      marginBottom={1}
    >
      <Box
        marginBottom={4}
      >
        <Typography
          className={classes.completePayment}
        >Complete Payment
        </Typography>
        <Typography
          className={classes.subHeader}
        >Start sending web push notifications with a service trusted by many.
        </Typography>
      </Box>
      <Box>
        <Box
          display='flex'
          marginBottom={1}
          alignItems='center'
        >
          <Box>
            <Typography
              component='span'
              className={classes.sumTypography}
            >
              <strong
              >{prorationTotal.amount_due}$
              </strong>
            </Typography>
            <Typography
              component='span'
              className={classes.infoTypography}
            >monthly*
            </Typography>
            {descriptions && (
              <Box
                marginLeft={1.5}
                alignItems='flex-end'
                display='inline-flex'
                onClick={handleClick}
              >
                <Box
                  marginRight={.2}
                  alignItems='center'
                  display='inline-flex'
                  className={classes.cursorPointer}
                >
                  <InfoIcon
                    style={{fontSize: 16}}
                  />
                </Box>
                <Typography
                  component='span'
                  className={clsx(classes.infoTypography, classes.cursorPointer)}
                >see details
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Typography
            component='span'
            className={classes.infoTypography}
          ><strong>*${currentPlan.price_per_1000}</strong>
          </Typography>
          <Typography
            component='span'
            className={classes.infoTypography}
          > per 1,000 messages delivered over the amount included
          </Typography>
        </Box>
      </Box>
      {descriptions && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical  : 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical  : 'top',
            horizontal: 'center',
          }}
        >{descriptions}
        </Popover>
      )}
    </Box>
  )
}