import React from 'react';
import {Result} from 'antd';
import {Button} from 'antd';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {_me} from '../../../store/actions/application/me';
import {_logout} from '../../../store/actions/application/logout';
import {_canReadApp} from '../../../store/selectors/application/acl';
import {_appIsVisible} from '../../../store/selectors/application/acl';

let interval: any;

export function AccessDenied() {
  const dispatch = useDispatch();
  const canReadApp = useSelector(_canReadApp);
  const appIsVisible = useSelector(_appIsVisible);

  const logout = React.useCallback(() => {
    dispatch(_logout());
  }, [
    dispatch,
  ]);

  React.useEffect(() => {
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
    interval = setInterval(() => {
      dispatch(_me());
    }, 10000);

    return function () {
      if (interval) {
        clearInterval(interval);
      }
      interval = null;
    }
  }, [
    dispatch,
  ])

  React.useEffect(() => {
    if (canReadApp && appIsVisible) {
      window.location.replace('/');
    }
  }, [
    canReadApp,
    appIsVisible,
  ]);

  return (
    <Result
      status={403}
      subTitle='Please Contact your Administrator for access.'
      title='Sorry, you are not authorized to access this page.'
      extra={
        <Button
          type='primary'
          onClick={logout}
        >Sign Out
        </Button>
      }
    />
  );
}