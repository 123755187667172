import React from 'react';
import {useSelector} from 'react-redux';
import {Spinner} from '../../components/antd/Spinner';
import {FiniteStates} from '../../constants/finiteStates';
import {_applicationStatus} from '../../store/selectors/application';

export function LoadingProvider(
  {
    children,
  }: {
    children: React.ReactNode;
  }
) {
  const applicationStatus = useSelector(_applicationStatus);

  if ([
    FiniteStates.IDLE,
    FiniteStates.LOADING,
  ].includes(applicationStatus)) {
    return (
      <Spinner/>
    );
  } else {
    return <>{children}</>
  }
}