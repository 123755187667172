const SI_SYMBOL = ['', 'k'];

export function abbreviateNumber(number: number, digits: number = 2) {
  let tier = Math.log10(number) / 3 | 0;
  if (tier > 1) {
    tier = 1
  }
  if (tier === 0) return number;

  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;

  return `${scaled.toFixed(digits)}${suffix}`;
}

const COUNT_ABBRS = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

export function formatCount(count: number = 0, withAbbr = false, decimals = 2) {
  const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
  let result: any = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
  if (withAbbr) {
    result += `${COUNT_ABBRS[i]}`;
  }
  return result;
}