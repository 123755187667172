import React from 'react';
import {Button} from 'antd';
import {Tooltip} from 'antd';
import {copyToClipboard} from '../../../../../../utils';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import {Templates} from '../../../../../../types/templates';
import {Text} from '../../../../../../components/antd/Typography';

let timeout: NodeJS.Timeout | undefined;

export function CopyIdAction(
  {
    row,
  }: {
    row: Templates.Entity;
  }
) {
  const [state, setState] = React.useState<boolean>(false);

  const onClick = function () {
    copyToClipboard(`${row.id}`);

    setState(true);

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(function () {
      setState(false);
    }, 1500);
  }

  return (
    <Tooltip
      title={state ? 'Copied' : 'Copy ID'}
    >
      <Button
        size='small'
        type='default'
        shape='circle'
        onClick={onClick}
        icon={state ? <Text type='success'><CheckOutlined/></Text> : <CopyOutlined/>}
      />
    </Tooltip>
  );
}
