import React from 'react';
import {WaitFor} from '../WaitFor';
import {TtlLabel} from '../TtlLabel';
import Box from '@material-ui/core/Box';
import {Percentage} from '../Percentage';
import {PageViewed} from '../PageViewed';
import {Controller} from 'react-hook-form';
import Radio from '@material-ui/core/Radio';
import {useTranslation} from 'react-i18next';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import {TTLEndAdornment} from '../TTLEndAdornment';
import {DimTheBackground} from '../DimTheBackground';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import {Card} from '../../../../../../../components/Card';
import FormControl from '@material-ui/core/FormControl';
import {Control} from 'react-hook-form/dist/types/form';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {CardHeader} from '../../../../../../../components/CardHeader';
import {TextField} from '../../../../../../../components/BorderedTextField';

export function GeneralOptions(
  {
    control,
    disabled,
  }: {
    disabled?: boolean;
    control: Control<Record<string, any>>;
  }
) {
  const {t} = useTranslation();
  return (
    <Card>
      <CardHeader
        color={disabled ? 'disabled' : 'primary'}
        title={t('app_settings.general_prompt_settings')}
      />
      <CardContent>
        <Box
          marginTop={2}
        >
          <Box>
            <Typography
              variant='body1'
              component='span'
              color={disabled ? 'textSecondary' : 'primary'}
            ><strong>Show Prompt</strong>
            </Typography>
          </Box>
          <Box
            marginBottom={2}
          >
            <Controller
              control={control}
              name='display_type'
              render={(
                {
                  field: {
                    ref,
                    value,
                    ...fieldRest
                  },
                }
              ) => {
                return (
                  <FormControl
                    disabled={disabled}
                    component='fieldset'
                  >
                    <RadioGroup
                      ref={ref}
                      value={value}
                      {...fieldRest}
                    >
                      <Box>
                        <FormControlLabel
                          value='delay'
                          control={<Radio color='primary'/>}
                          label='Automatically after the page opens'
                        />
                        <WaitFor
                          control={control}
                          disabled={disabled}
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value='page_viewed'
                          control={<Radio color='primary'/>}
                          label='Automatically after a number of pages browsed'
                        />
                        <PageViewed
                          control={control}
                          disabled={disabled}
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value='percentage'
                          control={<Radio color='primary'/>}
                          label='Automatically after a percent of page is scrolled'
                        />
                        <Percentage
                          control={control}
                          disabled={disabled}
                        />
                      </Box>
                      <FormControlLabel
                        value='manual'
                        label={
                          <Box
                            display='flex'
                            alignItems='center'
                          >
                            <Typography
                              color='inherit'
                              variant='inherit'
                            >Programmatically with SDK
                            </Typography>
                            <Tooltip
                              title={t('app_settings.show_custom_prompt_programmatically_helper')}
                            >
                              <Box
                                display='flex'
                                marginLeft={.5}
                              >
                                <HelpIcon
                                  fontSize='small'
                                />
                              </Box>
                            </Tooltip>
                          </Box>
                        }
                        control={<Radio color='primary'/>}
                      />
                    </RadioGroup>
                  </FormControl>
                )
              }}
            />
          </Box>
          <Box
            marginBottom={1}
          >
            <Typography
              variant='body1'
              component='span'
              color={disabled ? 'textSecondary' : 'primary'}
            >
              <strong>Other Options</strong>
            </Typography>
          </Box>
          <Controller
            name='ttl'
            control={control}
            render={(
              {
                field     : {
                  ref,
                  ...fieldRest
                },
                fieldState: {
                  error: localError,
                },
              }
            ) => (
              <TextField
                type='number'
                margin='none'
                inputRef={ref}
                autoComplete='off'
                disabled={disabled}
                label={<TtlLabel/>}
                error={!!localError?.message}
                helperText={localError?.message}
                inputProps={{
                  min     : 0,
                  disabled: false,
                  readOnly: disabled,
                }}
                InputProps={{endAdornment: <TTLEndAdornment/>}}
                {...fieldRest}
              />
            )}
          />
          <DimTheBackground
            control={control}
            disabled={disabled}
          />
        </Box>
      </CardContent>
    </Card>
  )
}