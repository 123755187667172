import {notification} from 'antd';
import {Globals} from '../../../types/globals';
import {UseFormSetError} from 'react-hook-form';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {requestTransformer} from '../../../utils/segmentsTransformer';

export const _update = (
  appUuid: string,
  formValues: Record<string, any>,
  {
    redirect,
    setError,
    unregisterCallback,
  }: {
    redirect: () => void;
    unregisterCallback: () => void;
    setError: UseFormSetError<Record<string, any>>;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  const {
    hasError,
    body: {
      data,
      message,
    }
  } = await api.post(urlHelper(ApiRoutes.UPDATE_SEGMENT, {
    appUuid,
    id: formValues.id,
  }), requestTransformer(formValues));

  if (hasError) {
    const {
      limits_notice,
      ...restErrors
    } = data._errors || {};

    if (limits_notice || message) {
      notification.error({
        message: limits_notice || message,
      });
    }

    const errors: any = {};
    let name: string | undefined;

    Object.keys(restErrors).forEach(value => {
      let inputName = value;
      if (inputName.endsWith('.value')) {
        inputName = inputName.replace('value', 'values');
      }

      const match = inputName.match(/\d+$/);
      if (match) {
        let fName = inputName.slice(0, -(match[0].length + 1));
        if (fName.endsWith('.value')) {
          fName = fName.replace('value', 'values');
        }
        name = fName;
        errors[match[0]] = data._errors[value];
      } else {
        setError(inputName, {
          message: data._errors[value],
        });
      }
    });
    if (name && !!Object.keys(errors).length) {
      setError(name, {
        message: errors as any,
      });
    }
  } else {
    unregisterCallback();

    if(!!message){
      notification.success({
        message,
      });
    }

    redirect();
  }
};
