import React from 'react';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {TextField} from '../../../BorderedTextField';
import {ColorAdornment} from '../../../ColorAdornment';
import {Control} from 'react-hook-form/dist/types/form';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {UseFormGetValues} from 'react-hook-form/dist/types/form';

export function DenyButtonTextColorTextField(
  {
    index,
    control,
    disabled,
    getError,
    setValue,
    getValues,
  }: {
    index: number;
    control: Control;
    disabled?: boolean;
    setValue: UseFormSetValue<any>;
    getValues: UseFormGetValues<any>;
    getError: (name: string, index: number) => string;
  }
) {
  const {t} = useTranslation();

  const templateId = useWatch({
    control,
    name: `messages.[${index}].template_id`,
  });

  const isDisabled = disabled || [3].includes(templateId);

  return (
    <Controller
      control={control}
      name={`messages.[${index}].deny_text_color`}
      render={({field: {ref, value, ...fieldRest}}) => (
        <TextField
          fullWidth
          value={value}
          inputRef={ref}
          margin='normal'
          autoComplete='off'
          InputProps={{
            endAdornment: (
              <ColorAdornment
                setValue={setValue}
                disabled={isDisabled}
                name={fieldRest.name}
                getValues={getValues}
              />
            ),
          }}
          inputProps={{
            readOnly: true,
            disabled: false,
          }}
          disabled={isDisabled}
          error={!!getError('deny_text_color', index)}
          helperText={getError('deny_text_color', index)}
          label={t('app_settings.deny_button_text_color')}
          {...fieldRest}
        />
      )}
    />
  )
}