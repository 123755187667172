import React from 'react';
import {parse} from 'querystring';
import ym from 'react-yandex-metrika';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {momentUTC} from '../../../../../utils';
import {urlHelper} from '../../../../../utils/urlHelper';
import {dataLayer} from '../../../../../utils/dataLayer';
import {ANIMATION_DELAY} from '../../../../../constants';
import {RadioChangeEvent} from 'antd/lib/radio/interface';
import {tidioChat} from '../../../../../utils/tidioChatApi';
import {surveyState} from '../../../../../constants/survey';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {_savingStep} from '../../../../../store/selectors/apps';
import {_user} from '../../../../../store/selectors/application/acl';
import {_saveStep} from '../../../../../store/actions/apps/saveStep';
import {surveyIsEnabled} from '../../../../../utils/surveyIsEnabled';
import {_downloadingSWFile} from '../../../../../store/selectors/apps';
import {_skipForNow} from '../../../../../store/actions/apps/skipForNow';
import {SHOW_SURVEY_AFTER_REGISTERED_AT} from '../../../../../constants';
import {_currentAppValidation} from '../../../../../store/selectors/apps';
import {_surveyData} from '../../../../../store/selectors/application/acl';
import {EIntegrationTypes} from '../../../../../constants/integrationTypes';
import {TidioCustomEvents} from '../../../../../constants/tidioCustomEvents';
import {_showPlanChange} from '../../../../../store/selectors/application/acl';
import {_storeSurvey} from '../../../../../store/actions/application/storeSurvey';
import {_downloadWorkerJS} from '../../../../../store/actions/apps/downloadWorkerJS';

export function useIntegrate() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(_user);
  const surveyData = useSelector(_surveyData);
  const savingStep = useSelector(_savingStep);
  const {appUuid} = useParams<{ appUuid: string }>();
  const downloading = useSelector(_downloadingSWFile);
  const showPlanChange = useSelector(_showPlanChange);
  const validation = useSelector(_currentAppValidation);
  const [state, setState] = React.useState<{
    copied: boolean;
    downloaded: boolean;
  }>({
    copied    : false,
    downloaded: false,
  });
  const fireIntegrationSkipped = React.useRef<boolean>(true);
  const [visible, toggleVisibility] = React.useState<boolean>(false);
  const [stepsIsVisible, toggleStepsVisibility] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<EIntegrationTypes>(EIntegrationTypes.simpleCode);

  const downloadWorkerJS = React.useCallback(() => {
    dispatch(_downloadWorkerJS(appUuid, setState));
  }, [
    appUuid,
    dispatch,
  ]);

  const onChange = React.useCallback((e: RadioChangeEvent) => {
    setValue(e.target.value)
  }, []);

  const goBack = React.useCallback(() => {
    history.replace(urlHelper(AppRoutes.APPS_EDIT_APP, {appUuid}))
  }, [
    history,
    appUuid,
  ]);

  const onCopy = React.useCallback(() => {
    setState(prevState => ({
      ...prevState,
      copied: true,
    }))
  }, []);

  const submit = React.useCallback(() => {
    const diff = momentUTC(SHOW_SURVEY_AFTER_REGISTERED_AT) < momentUTC(user.created_at);

    dispatch(_saveStep({
      appUuid,
      creationStep: 2,
      redirect    : async () => {
        fireIntegrationSkipped.current = false;

        if (showPlanChange) {
          history.push(urlHelper(AppRoutes.APPS_PAY_AND_LAUNCH_APP, {appUuid}))
        } else {
          if (surveyIsEnabled() && !surveyData.state && diff) {
            await dispatch(_storeSurvey({
              key  : 'state',
              value: surveyState.STARTED,
            }));
          } else {
            history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid}))
          }
        }
      }
    }));
  }, [
    appUuid,
    history,
    dispatch,
    showPlanChange,
    user?.created_at,
    surveyData.state,
  ]);

  const onClick = React.useCallback(() => {
    if (localStorage.getItem(`integration_${appUuid}`)) {
      submit();
    } else {
      toggleVisibility(true);

      switch (value) {
        case EIntegrationTypes.wordpressPlugin: {
          dataLayer({
            app_uuid: appUuid,
            event   : 'wp-integration',
          });
          ym('reachGoal', 'wp-integration', {
            app_uuid: appUuid,
          });
          break;
        }
        case EIntegrationTypes.googleTagManager: {
          dataLayer({
            app_uuid: appUuid,
            event   : 'gtm-integration',
          });
          ym('reachGoal', 'gtm-integration', {
            app_uuid: appUuid,
          });
          break;
        }
      }
    }
  }, [
    value,
    submit,
    appUuid,
  ]);

  const skipForNow = React.useCallback(() => {
    const diff = momentUTC(SHOW_SURVEY_AFTER_REGISTERED_AT) < momentUTC(user.created_at);

    dispatch(_skipForNow({
      appUuid,
      redirect: async () => {
        fireIntegrationSkipped.current = false;
        if (showPlanChange) {
          history.push(urlHelper(AppRoutes.APPS_PAY_AND_LAUNCH_APP, {appUuid}));
        } else {
          if (surveyIsEnabled() && !surveyData.state && diff) {
            await dispatch(_storeSurvey({
              key  : 'state',
              value: surveyState.STARTED,
            }));
          } else {
            history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid}));
          }
        }
      }
    }));
  }, [
    appUuid,
    history,
    dispatch,
    showPlanChange,
    user?.created_at,
    surveyData.state,
  ]);

  React.useEffect(() => {
    const params = parse(history.location.search.replace('?', ''));
    if (
      (params.ti === 'true') &&
      !localStorage.getItem(`integration_${appUuid}`)
    ) {
      history.replace({
        search: void 0,
      });
      toggleVisibility(true);
    }
  }, [
    history,
    appUuid,
  ]);

  React.useEffect(() => {
    if (appUuid) {
      switch (value) {
        case EIntegrationTypes.wordpressPlugin: {
          dataLayer({
            app_uuid: appUuid,
            event   : 'wp-tab-opened',
          });
          ym('reachGoal', 'wp-tab', {
            app_uuid: appUuid,
          });
          break;
        }
        case EIntegrationTypes.googleTagManager: {
          dataLayer({
            app_uuid: appUuid,
            event   : 'gtm-tab-opened',
          });
          ym('reachGoal', 'gtm-tab', {
            app_uuid: appUuid,
          });
          break;
        }
      }
    }
  }, [
    value,
    appUuid,
  ]);

  React.useEffect(() => {
    setTimeout(function () {
      tidioChat().track(TidioCustomEvents.LIVE_DEMO_TEST)
    }, ANIMATION_DELAY);
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem(`steps_is_visible_${appUuid}`)) {
      toggleStepsVisibility(true);
    }
  }, [
    appUuid,
  ]);

  React.useEffect(() => {
    if (stepsIsVisible) {
      localStorage.setItem(`steps_is_visible_${appUuid}`, 'true');
    } else {
      localStorage.removeItem(`steps_is_visible_${appUuid}`);
    }
  }, [
    appUuid,
    stepsIsVisible,
  ]);

  React.useEffect(() => {
    return function () {
      if (fireIntegrationSkipped.current) {
        dataLayer({
          event: 'integration-skipped',
        })
      }
    }
  }, []);

  return {
    value,
    state,
    submit,
    goBack,
    onCopy,
    visible,
    onClick,
    appUuid,
    onChange,
    savingStep,
    validation,
    skipForNow,
    downloading,
    showPlanChange,
    stepsIsVisible,
    toggleVisibility,
    downloadWorkerJS,
    toggleStepsVisibility,
    fireIntegrationSkipped,
  };
}
