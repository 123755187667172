import React from 'react';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {DateRangePicker as RangePicker} from 'react-date-range';
import createStyles from '@material-ui/core/styles/createStyles';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& *'                                                                                     : {
      fontWeight: 'bold !important',
    },
    '& .rdrInputRangeInput'                                                                   : {
      borderRadius: 0,
    },
    '& .rdrDateDisplay'                                                                       : {

    },
    '& .rdrStaticRangeLabel'                                                                  : {
      fontWeight: 'bold',
    },
    '& .rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel': {
    },
    '& .rdrStaticRange.rdrStaticRangeSelected'                                                : {
      color          : `#096dd9 !important`,
    },
    '& .rdrMonthAndYearPickers select'                                                        : {
      borderRadius: 0,
    },
    '& .rdrNextPrevButton'                                                                    : {
      borderRadius   : 0,
    },
    '& .rdrDateDisplayItemActive'                                                             : {
      borderColor: `#096dd9 !important`,
    },
    '& .rdrDateDisplayItem'                                                                   : {
      borderRadius: 0,
      boxShadow   : 'none',
      '& > input' : {
        borderRadius: 0,
        outline     : 'none !important',
        color       : `#096dd9 !important`,
      }
    },
    '& .rdrDayNumber span'                                                                    : {
      fontWeight: 'bold',
    },
    '& button.rdrDay span.rdrInRange'                                                         : {
      borderRadius: 0,
      color       : `#096dd9 !important`,
    },
    '& button.rdrDay span.rdrStartEdge'                                                       : {
      borderRadius: 0,
      color       : `#096dd9 !important`,
    },
    '& button.rdrDay span.rdrEndEdge'                                                         : {
      borderRadius: 0,
      color       : `#096dd9 !important`,
    },
    '& button.rdrDay span.rdrDayStartPreview'                                                 : {
      borderRadius: 0,
      color       : `#096dd9 !important`,
    },
    '& button.rdrDay span.rdrDayEndPreview'                                                   : {
      borderRadius: 0,
      color       : `#096dd9 !important`,
    },
    '& button.rdrDay span.rdrDayInPreview'                                                    : {
      borderRadius: 0,
      color       : `#096dd9 !important`,
    },
    '& .rdrDayToday .rdrDayNumber span:after'                                                 : {
      backgroundColor: `#096dd9`,
    },
    [theme.breakpoints.down('xs')]                                                            : {
      width                                      : `100%`,
      '& .rdrDefinedRangesWrapper'               : {
        width  : `100%`,
        display: `none`,
      },
      '& .rdrMonth'                              : {
        width: `100%`,
      },
      '& .rdrDateRangePickerWrapper'             : {
        width: `100%`,
      },
      '& .rdrCalendarWrapper.rdrDateRangeWrapper': {
        width: `100%`,
      },
    }
  },
}));

export const DateRangePicker: React.FC<any> = (props) => {
  const {
    root,
  } = useStyles();
  return (
    <div
      className={root}
    >
      <RangePicker
        format='YYYY-MM-DD'
        {...props}
      />
    </div>
  );
};
