import React from 'react';
import {Header} from '../../../../Common/Header';

export function HeaderWrapper(
  {
    state,
    radioGroupValue,
  }: {
    state: {
      copied: boolean;
      downloaded: boolean;
    };
    radioGroupValue: string;
  }
) {
  const sum = Number(state.copied) + Number(state.downloaded);

  return (
    <Header
      current={1}
      percent={(radioGroupValue !== 'simple-code') ? 100 : sum * 50}
    />
  )
}
