import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

export default function TableSelectionCellComponent(
  {
    row,
    selected,
    tableRow,
    onToggle,
    tableColumn,
    ...rest
  }: any
) {
  return (
    <td
      {...rest}
    >
      <div
        style={{textAlign: 'center'}}
      >
        <Checkbox
          color='primary'
          checked={selected}
          onChange={onToggle}
        />
      </div>
    </td>
  );
}