import {Tag} from 'antd';
import React from 'react';
import {Spin} from 'antd';
import {Input} from 'antd';
import {Space} from 'antd';
import {Button} from 'antd';
import {Tooltip} from 'antd';
import {useImmer} from 'use-immer';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {Apps} from '../../../../../types/apps';
import {nFormatter} from '../../../../../utils';
import {Typography as AntTypography} from 'antd';
import {ColumnsType} from 'antd/lib/table/interface';
import {formatWithCommas} from '../../../../../utils';
import {ExpandableConfig} from 'rc-table/lib/interface';
import {urlHelper} from '../../../../../utils/urlHelper';
import {_apps} from '../../../../../store/selectors/apps';
import {jsonViewer} from '../../../../../utils/jsonViewer';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {ApiRoutes} from '../../../../../constants/apiRoutes';
import {Application} from '../../../../../types/application';
import {FilterDropdownProps} from 'antd/lib/table/interface';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import {Link} from '../../../../../components/antd/Typography';
import {Text} from '../../../../../components/antd/Typography';
import {_apiBaseUrl} from '../../../../../store/selectors/application';
import {_accessToken} from '../../../../../store/selectors/application';

export function useList() {
  const dispatch = useDispatch();
  const apps = useSelector(_apps);
  const apiBaseUrl = useSelector(_apiBaseUrl);
  const accessToken = useSelector(_accessToken);
  const {appUuid} = useParams<{ appUuid: string }>();

  const [data, setData] = useImmer<{
    loading: boolean;
    subCount: number;
    searchParams: any,
    topAppsLength: number;
    subCountLast24: number;
    apps: Apps.ListEntity[];
    top24HAppsLength: number;
    userFilters: { value: number; text: string }[];
  }>({
    topAppsLength   : 0,
    top24HAppsLength: 0,
    subCount        : 10,
    subCountLast24  : 10,
    userFilters     : [],
    searchParams    : {},
    apps            : [],
    loading         : true,
  });

  React.useEffect(() => {
    setData((draft) => {
      draft.apps = apps;
      draft.topAppsLength = apps?.filter((app) => app.total_users >= draft.subCount).length;
      draft.loading = true;
    });
  }, [
    apps,
    setData,
  ]);

  React.useEffect(() => {
    if (data.apps.length) {
      setData((draft) => {
        draft.topAppsLength = data.apps?.filter((app) => app.total_users >= draft.subCount).length;
      });
    }
  }, [
    setData,
    data.apps,
    data.subCount,
  ]);

  React.useEffect(() => {
    if (data.apps.length) {
      setData((draft) => {
        draft.top24HAppsLength = data.apps?.filter((app) => app.last_day_active_users >= draft.subCountLast24).length;
      });
    }
  }, [
    setData,
    data.apps,
    data.subCountLast24,
  ]);

  React.useEffect(() => {
    async function fetchUsers(users: any[], pageNumber: number): Promise<any> {
      try {
        const res = await fetch(`${apiBaseUrl}${ApiRoutes.LIST_USERS}?pageSize=1000&pageNumber=${pageNumber}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!res.ok) {
          console.error(await res.json());
          return users;
        } else {
          const {data} = await res.json();
          users = [...users, ...data.rows];
          if (users.length < Number(data.total)) {
            return fetchUsers(users, pageNumber + 1);
          } else {
            return users
          }
        }
      } catch (e) {
        console.error(e);
        return users;
      }
    }

    fetchUsers([], 1)
      .then(data => {
        setData((draft) => {
          const userIds: Record<number, {
            text: string;
            value: number;
            count: number;
          }> = {};

          draft.apps.forEach((app, index) => {
            const user = data.find((user: Application.User) => app.user_id === user.id);

            userIds[app.user_id] = {
              value: app.user_id,
              count: userIds[app.user_id] ? userIds[app.user_id].count + 1 : 1,
              text : user ? `${user.first_name} ${user.last_name}` : `${app.user_id}`,
            }

            userIds[app.user_id] = {
              ...userIds[app.user_id],
              text: userIds[app.user_id].text + ` (Apps: ${userIds[app.user_id]?.count || 0})`,
            }

            if (user) {
              draft.apps[index].user = user
            }
          });

          draft.userFilters = Object.values(userIds);

          draft.loading = false;
        })
      })
      .catch(console.error);
  }, [
    setData,
    dispatch,
    apiBaseUrl,
    accessToken,
  ]);

  function inputNumberOnChange(value: any) {
    setData(draft => {
      draft.subCount = value;
    })
  }

  function inputNumberOnChangeLast24(value: any) {
    setData(draft => {
      draft.subCountLast24 = value;
    })
  }

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setData((draft) => {
      draft.searchParams.searchedColumn = dataIndex;
      draft.searchParams.searchText = selectedKeys[0];
    });
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setData((draft) => {
      draft.searchParams.searchText = '';
    });
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: (
      {
        confirm,
        clearFilters,
        selectedKeys,
        setSelectedKeys
      }: FilterDropdownProps) => {
      return (
        <div className='p-3'>
          <Input
            value={selectedKeys[0]}
            className='d-block m-b-3'
            placeholder={`Search ${dataIndex}`}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onChange={e => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              if (!e.target.value) {
                handleSearch(selectedKeys, confirm, dataIndex);
              }
            }}
          />
          <Space>
            <Button
              size='small'
              type='primary'
              style={{width: 90}}
              icon={<SearchOutlined/>}
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            >Search
            </Button>
            <Button
              size='small'
              style={{width: 90}}
              onClick={() => handleReset(clearFilters)}
            >Reset
            </Button>
            <Button
              type='link'
              size='small'
              onClick={() => {
                confirm({closeDropdown: false});
                setData((draft) => {
                  draft.searchParams.searchedColumn = dataIndex;
                  draft.searchParams.searchText = selectedKeys[0];
                });
              }}
            >Filter
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon    : (filtered: boolean) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
    onFilter      : (value: string | number | boolean, record: Apps.ListEntity) => {
      if (typeof value === 'string') {
        return (record as any)[dataIndex] ? (record as any)[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '';
      }
    }
  });

  const expandable: ExpandableConfig<Apps.ListEntity> = {
    expandedRowRender: (record) => {
      return record.loading ? (
        <div
          className='d-flex justify-content-center align-items-center'
        >
          <Spin
            className='d-flex'
          />
        </div>
      ) : (
        <>
          {!!record.entity && (
            <div
              style={{maxHeight: 500, overflowY: 'auto'}}
              dangerouslySetInnerHTML={{__html: jsonViewer(record.entity, true)}}
            />
          )}
        </>
      )
    },
    onExpand         : (expanded, record) => {
      const index = data.apps.findIndex(app => app.uuid === record.uuid);

      if (expanded) {
        setData((draft) => {
          draft.apps[index].loading = true;
        });

        const url = urlHelper(`${apiBaseUrl}${ApiRoutes.GET_APP}`, {
          appUuid: record.uuid
        });

        if (!data.apps[index].entity) {
          fetch(url, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }).then(res => res.json())
            .then(res => {
              if (res.data) {
                setData((draft) => {
                  draft.apps[index].loading = false;
                  draft.apps[index].entity = res.data;
                });
              }
            })
            .catch(error => {
              console.error(error);
              setData((draft) => {
                draft.apps[index].loading = false;
              });
            })
        } else {
          setData((draft) => {
            draft.apps[index].loading = false;
          });
        }
      }
    },
  };

  const columns: ColumnsType<Apps.ListEntity> = [
    {
      onFilter : () => '',
      dataIndex: 'uuid',
      title    : 'App Reports',
      render   : (appUuid, record) => {
        return (
          <Link
            underline
            target='_blank'
            href={urlHelper(AppRoutes.ANALYTICS_REPORTS, {appUuid})}
          >
            <Text
              underline
              ellipsis={{tooltip: record.title}}
              style={{width: 150, color: 'inherit'}}
              copyable={{text: appUuid, tooltips: ['Copy App UUID', 'Copied']}}
            >{record.title}
            </Text>
          </Link>
        )
      },
      ...getColumnSearchProps('title'),
    },
    {
      title    : 'Domain',
      dataIndex: 'domain',
      render   : (domain) => {
        return !!domain?.trim() ? (
          <Link
            underline
            href={domain}
            target='_blank'
          >
            <Text
              underline
              ellipsis={{tooltip: domain}}
              style={{width: 150, color: 'inherit'}}
              copyable={{text: domain, tooltips: ['Copy Domain', 'Copied']}}
            >{domain}
            </Text>
          </Link>
        ) : null
      },
      ...getColumnSearchProps('domain'),
    },
    {
      title    : 'Total',
      dataIndex: 'total_users',
      render   : (totalUsers) => {
        return (
          <Tag
            color='success'
          >
            <Tooltip
              title={formatWithCommas(totalUsers)}
            >{nFormatter(totalUsers, 1)}
            </Tooltip>
          </Tag>
        );
      },
      sorter   : (a, b) => a.total_users - b.total_users,
    },
    {
      title    : 'Active',
      dataIndex: 'active_users',
      render   : (activeUsers) => {
        return (
          <Tag
            color='geekblue'
          >
            <Tooltip
              title={formatWithCommas(activeUsers)}
            >{nFormatter(activeUsers, 1)}
            </Tooltip>
          </Tag>
        );
      },
      sorter   : (a, b) => a.active_users - b.active_users,
    },
    {
      ellipsis : {showTitle: true},
      dataIndex: 'last_day_active_users',
      render   : (activeUsers) => {
        return (
          <Tag
            color='geekblue'
          >
            <Tooltip
              title={formatWithCommas(activeUsers || 0)}
            >{nFormatter(activeUsers || 0, 1)}
            </Tooltip>
          </Tag>
        );
      },
      sorter   : (a, b) => a.last_day_active_users - b.last_day_active_users,
      title    : () => {
        return (
          <AntTypography.Text
            style={{
              whiteSpace: 'nowrap',
              font      : 'inherit',
            }}
          >Last 24 hours Active
          </AntTypography.Text>
        )
      },
    },
    {
      width    : 150,
      title    : 'Archived',
      dataIndex: 'archived_users',
      render   : (archivedUsers) => {
        return (
          <Tag
            color='red'
          >
            <Tooltip
              title={formatWithCommas(archivedUsers)}
            >{nFormatter(archivedUsers, 1)}
            </Tooltip>
          </Tag>
        );
      },
      sorter   : (a, b) => a.archived_users - b.archived_users,
    },
    {
      filterSearch: true,
      title       : 'User',
      dataIndex   : 'user_id',
      filters     : data.userFilters,
      onFilter    : (value, record) => {
        return record.user_id === value
      },
      render      : (userId, record) => {
        if (record.user) {
          const {
            uuid,
            last_name,
            first_name,
          } = record.user;
          return (
            <>
              <Link
                underline
                target='_blank'
                href={urlHelper(AppRoutes.UPDATE_USER, {appUuid, uuid})}
              >
                <Text
                  style={{color: 'inherit'}}
                >{first_name}
                </Text>
                <br/>
                <Text
                  style={{color: 'inherit'}}
                >{last_name}
                </Text>
              </Link>
            </>
          )
        } else {
          return <>{userId}</>
        }
      }
    },
    {
      title    : 'Created At',
      dataIndex: 'created_at',
      ellipsis : {showTitle: true},
      sorter   : (a, b) => (new Date(a.created_at)).getTime() - (new Date(b.created_at)).getTime(),
    },
  ];

  return {
    data,
    columns,
    expandable,
    inputNumberOnChange,
    inputNumberOnChangeLast24,
  }
}
