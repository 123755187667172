import {Space} from 'antd';
import {EditAction} from '../../TableActions/Edit';
import {CopyIdAction} from '../../TableActions/CopyId';
import {DeleteAction} from '../../TableActions/Delete';
import {Templates} from '../../../../../../types/templates';
import {CreatePushNotificationAction} from '../../TableActions/CreatePushNotification';

export function Actions({record}: { record: Templates.Entity }) {
  return (
    <Space>
      <CopyIdAction
        row={record}
      />
      <EditAction
        row={record}
      />
      <CreatePushNotificationAction
        row={record}
      />
      <DeleteAction
        row={record}
      />
    </Space>
  )
}