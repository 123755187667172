import {notification} from 'antd';
import {_dataOptions} from './index';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {_apiBaseUrl} from '../../selectors/application';

export const _destroy = (uuid: string): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(_dataOptions({
    isDeleting: true,
  }));

  const apiBaseUrl = _apiBaseUrl(getState());

  const {
    hasError,
    body: {
      message,
    },
  } = await api.request(`${apiBaseUrl}${urlHelper(ApiRoutes.DELETE_APP, {
    appUuid: uuid,
  })}`, 'DELETE');

  dispatch(_dataOptions({
    isDeleting  : void 0,
    dialogIsOpen: void 0,
  }));

  if (hasError) {
    notification.error({message});
  } else {
    notification.success({message});
  }

  if (!hasError) {
    if (window) {
      return window.location.href = '/'
    }
  }
};