import React from 'react';
import {Layout} from 'antd';
import {useTranslation} from 'react-i18next';
import './index.less';

export function Footer() {
  const {t} = useTranslation();

  return (
    <Layout.Footer
      className='layout-footer'
    >{t('footer.copyright', {date: String((new Date()).getFullYear())})}
    </Layout.Footer>
  )
}