import React from 'react';
import {Space} from 'antd';
import {Switch} from 'antd';
import {Popover} from 'antd';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {ColumnsType} from 'antd/es/table';
import {ApiKeys} from '../../../../../types/apiKeys';
import {aclKeys} from '../../../../../constants/aclKeys';
import {EditAction} from './components/TableActions/Edit';
import {Text} from '../../../../../components/antd/Typography';
import {generateUUID} from '../../../../../utils/generateUUID';
import {useSmartPush} from '../../../../../hooks/useSmartPush';
import {DeleteAction} from './components/TableActions/Delete';
import {_fetch} from '../../../../../store/actions/apiKeys/fetch';
import {_dataOptions} from '../../../../../store/actions/apiKeys';
import {FiniteStates} from '../../../../../constants/finiteStates';
import * as selectors from '../../../../../store/selectors/apiKeys';
import {RegenerateAction} from './components/TableActions/Regenerate';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import {_toggleStatus} from '../../../../../store/actions/apiKeys/toggleStatus';

const defaultValues = {
  name         : '',
  limits_notice: '',
  enabled      : true,
}

export function useApiKeys() {
  const dispatch = useDispatch();

  const row = useSelector(selectors._row);
  const total = useSelector(selectors._total);
  const modal = useSelector(selectors._modal);
  const apiKeys = useSelector(selectors._apiKeys);
  const pageSize = useSelector(selectors._pageSize);
  const current = useSelector(selectors._pageNumber);
  const searchText = useSelector(selectors._searchText);
  const apiKeysState = useSelector(selectors._fetchingApiKeysState);

  React.useEffect(() => {
    dispatch(_fetch());
  }, [
    current,
    dispatch,
    pageSize,
    searchText,
  ]);

  React.useEffect(() => {
    return function () {
      dispatch(_dataOptions({
        pageNumber: 1,
        searchText: void 0,
      }));
    }
  }, [
    dispatch,
  ]);

  const tableOnChange = React.useCallback((pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      dispatch(_dataOptions({
        total     : pagination.total,
        pageNumber: pagination.current,
        pageSize  : pagination.pageSize,
      }));
    }
  }, [
    dispatch,
  ]);

  const columns: ColumnsType<ApiKeys.Entity> = React.useMemo(() => {
    return [
      {
        key      : 'name',
        dataIndex: 'name',
        title    : 'Name',
        render   : RenderName,
      },
      {
        title    : 'Key',
        key      : 'api_key',
        dataIndex: 'api_key',
        render   : RenderKey,
      },
      {
        key      : 'created_at',
        dataIndex: 'created_at',
        title    : 'Created at',
        render   : RenderCreatedAt,
      },
      {
        width    : '16rem',
        title    : 'Status',
        align    : 'center',
        key      : 'enabled',
        dataIndex: 'enabled',
        render   : RenderStatus
      },
      {
        width : '12rem',
        fixed : 'right',
        title : 'Actions',
        key   : 'operation',
        render: RenderActions,
      },
    ]
  }, []);

  return {
    row,
    total,
    modal,
    current,
    columns,
    apiKeys,
    pageSize,
    apiKeysState,
    defaultValues,
    tableOnChange,
  }
}

function RenderName(value: string) {
  return (
    <Text
      ellipsis
      style={{maxWidth: '30rem'}}
    >{value}
    </Text>
  )
}

function RenderKey(value: string) {
  return (
    <Text
      copyable
      ellipsis
    >{value}
    </Text>
  )
}

function RenderCreatedAt(value: string) {
  return (
    <Text
      ellipsis
    >{value}
    </Text>
  )
}

function RenderActions(props: ApiKeys.Entity) {
  return (
    <Space>
      <RegenerateAction
        row={props}
      />
      <EditAction
        row={props}
      />
      <DeleteAction
        row={props}
      />
    </Space>
  )
}

function RenderStatus(value: number, props: ApiKeys.Entity) {
  const {
    user: {
      canUpdate,
    }
  } = useSmartPush();
  const dispatch = useDispatch();

  const content = React.useMemo(() => {
    if (!!Object.keys(props.errors?.inputs || {}).length) {
      return Object.values(props.errors.inputs).map((msg) => (
        <Text
          type='danger'
          fontSize='small'
          key={generateUUID()}
        >{msg}
        </Text>
      ))
    } else {
      return undefined;
    }
  }, [
    props,
  ]);

  const onChange = React.useCallback((checked: boolean) => {
    dispatch(_toggleStatus({
      ...props,
      enabled: checked,
    }));
  }, [
    props,
    dispatch,
  ]);

  return (
    <Space
      align='center'
    >
      <Switch
        size='default'
        checked={!!value}
        onChange={onChange}
        checkedChildren='Enabled'
        unCheckedChildren='Disabled'
        disabled={!canUpdate(aclKeys.API_KEYS)}
        loading={props.updating === FiniteStates.LOADING}
      />
      {!!props.errors?.message && (
        <Popover
          content={content}
          title={<Text type='danger'>{props.errors.message}</Text>}
        >
          <Text
            type='danger'
          ><InfoCircleOutlined/>
          </Text>
        </Popover>
      )}
    </Space>
  )
}
