import React from 'react';
import {Modal} from 'antd';
import {Text} from '../Typography';
import {Title} from '../Typography';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {urlHelper} from '../../../utils/urlHelper';
import {AppRoutes} from '../../../constants/appRoutes';
import {_currentPlan} from '../../../store/selectors/portal';
import {_suspended} from '../../../store/selectors/application';
import WarningOutlined from '@ant-design/icons/WarningOutlined';

export function Suspended() {
  const {push} = useHistory();
  const suspended = useSelector(_suspended);
  const currentPlan = useSelector(_currentPlan);
  const {appUuid} = useParams<{ appUuid: string }>();

  const lowerCasePlan = currentPlan?.plan_slug?.toLowerCase();
  const upperCasePlan = currentPlan?.plan_slug?.toUpperCase();

  const onOk = function () {
    if (appUuid) {
      push(urlHelper(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP, {appUuid}));
    } else {
      push(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS);
    }
  }

  const onCancel = function () {
    if (appUuid) {
      push(urlHelper(AppRoutes.MANAGE_ACCOUNT_APP, {
        appUuid,
      }));
    } else {
      push(AppRoutes.MANAGE_ACCOUNT);
    }
  }

  return (
    <Modal
      visible
      centered
      onOk={onOk}
      closable={false}
      onCancel={onCancel}
      maskClosable={false}
      okText='Go to Payment Methods'
      cancelText='Go to Profile Page'
      title='Your Account is Suspended'
    >
      <div>
        <Title
          level={1}
          type='warning'
          align='center'
        ><WarningOutlined/>
        </Title>
      </div>
      {!!suspended.message && !!currentPlan && (
        <div>
          <Text
            align='center'
            type='secondary'
          >{suspended.message.replace(`[ ${lowerCasePlan} ]`, '').replace(`[ ${upperCasePlan} ]`, '')}
          </Text>
        </div>
      )}
      <div>
        <Text
          align='center'
          type='secondary'
        >There might be an issue with your payment methods or you might have insufficient funds.
        </Text>
      </div>
      <div>
        <Text
          align='center'
          type='secondary'
        >Please check your Payment Methods.
        </Text>
      </div>
    </Modal>
  );
}
