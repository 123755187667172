import React from 'react';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {useTitle} from '../../../../../hooks/useTitle';
import {_user} from '../../../../../store/selectors/application/acl';
import {_changePassword} from '../../../../../store/actions/settings/changePassword';

export function useChangePassword() {
  const dispatch = useDispatch();
  useTitle('Change Password | SmartPush');
  const {
    show_password,
  } = useSelector(_user);
  const form = useForm<Record<string, any>>();

  const {
    reset,
    control,
    setError,
    handleSubmit,
    formState: {
      isSubmitting,
    }
  } = form;

  const onSubmit = React.useCallback((formValues: Record<string, any>) => {
    return dispatch(_changePassword(formValues, {
      reset,
      setError,
    }))
  }, [
    reset,
    setError,
    dispatch,
  ]);

  return {
    reset,
    control,
    isSubmitting,
    show_password,
    submit: handleSubmit(onSubmit),
  }
}
