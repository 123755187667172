import React from 'react';
import {Form} from 'antd';
import {Spin} from 'antd';
import {Card} from 'antd';
import {Switch} from 'antd';
import {Button} from 'antd';
import {Result} from 'antd';
import {Tooltip} from 'antd';
import {syncExtremes} from '../index';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import * as Highcharts from 'highcharts';
import {TooltipOptions} from 'highcharts';
import {getMaxY} from '../../../../../../../utils';
import {getMinY} from '../../../../../../../utils';
import {nFormatter} from '../../../../../../../utils';
import HighchartsReact from 'highcharts-react-official';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {ANIMATION_DELAY} from '../../../../../../../constants';
import {Text} from '../../../../../../../components/antd/Typography';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import {FiniteStates} from '../../../../../../../constants/finiteStates';
import {_data} from '../../../../../../../store/selectors/reports/clicks';
import {_reportsDates} from '../../../../../../../store/selectors/reports';
import {_dataSum} from '../../../../../../../store/selectors/reports/clicks';
import {_isUnique} from '../../../../../../../store/selectors/reports/clicks';
import {_messages} from '../../../../../../../store/selectors/reports/clicks';
import {_dataOptions} from '../../../../../../../store/actions/reports/clicks';
import {_fetch} from '../../../../../../../store/actions/reports/clicks/fetch';
import {_fetchingState} from '../../../../../../../store/selectors/reports/clicks';
import {_fetch as _fetchCtr} from '../../../../../../../store/actions/reports/ctr/fetch';
import {_isUnique as _isUniqueDisplays} from '../../../../../../../store/selectors/reports/displays';

export function Clicks(
  {
    height,
    callback,
  }: {
    height: number;
    callback: Highcharts.ChartCallbackFunction;
  }
) {
  const dispatch = useDispatch();
  const sum = useSelector(_dataSum);
  const isUnique = useSelector(_isUnique);
  const messages = useSelector(_messages);
  const dates = useSelector(_reportsDates);
  const fetchingState = useSelector(_fetchingState);
  const {appUuid} = useParams<{ appUuid: string }>();
  const isUniqueDisplays = useSelector(_isUniqueDisplays);

  function onChange(checked: boolean) {
    dispatch(_dataOptions({unique: checked}));
    dispatch(_fetch(appUuid, dates));

    if ((isUniqueDisplays && checked) || (!isUniqueDisplays && !checked)) {
      dispatch(_fetchCtr(appUuid, dates));
    }
  }

  if (fetchingState === FiniteStates.FAILURE) {
    return (
      <Card
        style={{height: height + 2}}
        className='m-t-2 d-flex align-items-center justify-content-center'
      >
        <Result
          extra={[
            <Button
              ghost
              key='console'
              type='primary'
              icon={<ReloadOutlined/>}
              onClick={() => dispatch(_fetch(appUuid, dates))}
            >Reload
            </Button>
          ]}
          status='error'
          title='Clicks'
          style={{padding: 0}}
          subTitle={Object?.values(messages)?.join('. ') || 'Failed to Load'}
        />
      </Card>
    )
  } else {
    return (
      <Card
        className='m-t-2'
      >
        <Spin
          spinning={[
            FiniteStates.IDLE,
            FiniteStates.LOADING,
          ].includes(fetchingState)}
          delay={ANIMATION_DELAY}
        >
          <div
            className='d-flex align-items-center justify-content-between'
          >
            <div
              className='d-flex align-items-center'
            >
              <Text
                strong
                fontSize='large'
                style={{color: '#FFC400'}}
              >Clicks
              </Text>
              <Text
                strong
                fontSize='large'
                className='m-l-1'
                style={{color: '#FFC400'}}
              >{nFormatter(sum, 2)}
              </Text>
              <Tooltip
                title='Number of times subscribers have clicked push notifications and have opened the URL on the notification on the given day.'
              >
                <Text
                  fontSize='small'
                  type='secondary'
                  className='m-l-1 m-b-1'
                >
                  <InfoCircleOutlined/>
                </Text>
              </Tooltip>
            </div>
            <Form.Item
              label='Unique'
              className='m-b-0'
              valuePropName='checked'
              tooltip='Clicks per unique subscribers.'
            >
              <Switch
                size='small'
                checked={isUnique}
                onChange={onChange}
              />
            </Form.Item>
          </div>
          <MemoChart
            height={height}
            callback={callback}
          />
        </Spin>
      </Card>
    )
  }
}

const emptyArray: any[] = [];

const MemoChart = React.memo(function (
  {
    height,
    callback,
  }: {
    height: number;
    callback: Highcharts.ChartCallbackFunction;
  }
) {
  const data = useSelector(_data);

  let max = getMaxY(data);
  max = (max / 10) + max;

  let min = getMinY(data);
  min = min - (min / 10);

  if (min < 0) {
    min = 0;
  }

  return (
    <HighchartsReact
      options={{
        legend : {
          enabled: false
        },
        credits: {
          enabled: false
        },
        title  : false,
        series : [{
          fillOpacity: 0.3,
          color      : {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops         : [
              [0, 'rgba(255,196,0,.4)'],
              [1, 'rgba(255,255,255,.2)'],
            ],
          },
          name       : 'name',
          type       : 'area',
          lineColor  : '#FFC400',
          data       : data.length ? [...data] : emptyArray,
        }],
        chart  : {
          spacingTop   : 8,
          spacingBottom: 8,
          height       : height - 80,
        },
        xAxis  : {
          type                : 'datetime',
          events              : {syncExtremes},
          minTickInterval     : 24 * 3600 * 1000,
          dateTimeLabelFormats: {
            day  : '%e-%b-%y',
            month: '%b-%y-%e',
          },
          crosshair           : {
            width: 1.5,
            color: '#24083C',
          },
        },
        tooltip: {
          borderWidth    : 0,
          valueDecimals  : 0,
          headerFormat   : '',
          shadow         : false,
          backgroundColor: '#000000',
          style          : {
            fontSize  : '12px',
            fontWeight: 'bold',
            color     : '#FFC400',
          },
          formatter      : function () {
            if (this.point.y || this.point.y === 0) {
              return nFormatter(this.point.y, 2);
            }
          },
        } as TooltipOptions,
        yAxis  : {
          max,
          min,
          title      : false,
          endOnTick  : false,
          startOnTick: false,
          labels     : {
            rotation: -90
          }
        },
      }}
      callback={callback}
      highcharts={Highcharts}
    />
  )
})
