export const NumberOfPeriodsOptions = [
  {
    value: 'indefinite',
    label: 'Indefinite',
  },
  ...([...new Array(36)]).reduce((acc, curr, index) => {
    acc.push({
      value: index + 1,
      label: String(index + 1),
    });
    return acc
  }, [])
];