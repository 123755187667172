import clsx from 'clsx';
import React from 'react';
import {Steps} from 'antd';
import {useSelector} from 'react-redux';
import {_appsLength} from '../../../../../store/selectors/apps';
import {PageHeader} from '../../../../../components/antd/PageHeader';
import {_integrationIsCompleted} from '../../../../../store/selectors/apps';
import {_showPlanChange} from '../../../../../store/selectors/application/acl';
import './index.less';

export function Header(
  {
    percent,
    current,
  }: {
    percent: number;
    current: number;
  }
) {
  const appsLength = useSelector(_appsLength);
  const showPlanChange = useSelector(_showPlanChange);
  const integrationIsCompleted = useSelector(_integrationIsCompleted);

  return (
    <div
      className='create-app-header-container'
    >
      <PageHeader
        align='center'
        title={!!appsLength ? 'Create New App' : 'Getting Started'}
      >
        <Steps
          responsive
          current={current}
          percent={percent}
          className={clsx(
            'create-app-header-steps',
            showPlanChange ? 'max-width-75' : 'max-width-50',
          )}
        >
          <Steps.Step
            title='Add Your Site'
            description={(current === 0) && 'Few Steps'}
          />
          <Steps.Step
            title='Integrate Smartpush'
            description={(current === 1) && 'Quick and Easy'}
            status={((current === 2) && !integrationIsCompleted) ? 'error' : void 0}
          />
          {showPlanChange && (
            <Steps.Step
              title='Pay and Launch'
              description={(current === 2) && 'Almost ready'}
            />
          )}
        </Steps>
      </PageHeader>
    </div>
  )
}