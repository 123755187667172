import {_upsertForm} from './index';
import * as Sentry from '@sentry/react';
import {sleep} from '../../../utils/sleep';
import {Globals} from '../../../types/globals';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {isValidEmail} from '../../../utils/isValidEmail';
import {UseFormSetError} from 'react-hook-form/dist/types/form';
import {RecaptchaActions} from '../../../constants/recaptchaActions';
import {IForgotPasswordForm} from '../../../pages/Auth/Pages/ForgotPassword/useForgotPassword';

export const _forgotPassword = (
  {
    message: tmpInput,
    ...restFormValues
  }: IForgotPasswordForm, {
    setError,
    executeRecaptcha,
  }: {
    setError: UseFormSetError<IForgotPasswordForm>;
    executeRecaptcha: (action?: string) => Promise<string>;
  }): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  if (!isValidEmail(restFormValues.email)) {
    await sleep();
    return setError('email', {
      message: 'Please enter a valid email address',
    });
  }

  let token: string | undefined = void 0;

  if (typeof executeRecaptcha === 'function') {
    token = await executeRecaptcha(RecaptchaActions.PASSWORD_RESET);
  }

  const {
    body: {
      data,
      message,
    },
    hasError,
  } = await api.post(ApiRoutes.PASSWORD_RESET, {
    ...restFormValues,
    'g-recaptcha-response': token || 'token',
  });

  if (hasError) {
    if (Object.keys(data).length) {
      Object.keys(data).forEach((inputName) => {
        setError(inputName as keyof IForgotPasswordForm, {
          message: data[inputName],
        });
      });
    } else {
      setError('message', {
        message,
      });
    }

    Sentry.setExtra('forgotPassword', {data, message});
    Sentry.captureException({data, message});
  } else {
    if (data.message) {
      dispatch(_upsertForm({
        message: data.message
      }));
    }
  }
};
