import React from 'react';
import {IObject} from '../../../types';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import ScrollPlayground from '../../ScrollPlayground';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles((theme: Theme) => ({
  header           : {
    paddingTop: theme.spacing(1),
  },
  actionsTypography: {
    fontSize: 14,
  },
  actionsContainer : {
    display       : 'flex',
    justifyContent: 'center',
    padding       : theme.spacing(1, 2),
  },
  closeIcon        : {
    fontSize  : 15,
    marginLeft: theme.spacing(1),
    '&:hover' : {
      borderRadius: '50%',
      border      : '1px solid',
    }
  },
  errorIcon        : {
    fontSize  : 15,
    marginLeft: theme.spacing(1),
  },
}));

export default function RemoveLanguage(
  {
    value,
    errors,
    index,
    onRemove,
    selected,
  }: {
    value?: {
      label: string;
      value: number;
    };
    index?: number;
    errors?: IObject;
    selected?: boolean;
    onRemove?: (value: {
      label: string;
      value: number;
    }) => void;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();
  const anchorRef = React.useRef<any>(null);
  const [open, setOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setOpen(prevOpen => {
      return !prevOpen
    });
  }, []);

  const handleClose = React.useCallback((event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }, []);

  return (
    <>
      {(!errors || !!selected) ? (
        <CloseIcon
          ref={anchorRef}
          onClick={handleToggle}
          className={classes.closeIcon}
          data-id={`remove-language-country-tab-${index}`}
        />
      ) : (
        <Tooltip
          title={
            <>
              {Object.values(errors).map((i, index) => (
                <Typography
                  key={index}
                >{i}
                </Typography>
              ))}
            </>
          }
        >
          <ErrorOutlineIcon
            color='error'
            className={classes.errorIcon}
          />
        </Tooltip>
      )}
      <ClickAwayListener
        onClickAway={handleClose}
      >
        <div>
          <ScrollPlayground
            open={open}
            transition={true}
            placement='top-start'
            anchorEl={anchorRef.current}
          >
            <Paper
              elevation={3}
              style={{paddingLeft: 16, paddingRight: 16, paddingTop: 8}}
            >
              <Typography
                noWrap
                align='center'
                color='primary'
                className={classes.header}
              >{t('messages.remove_language_country', {language_country: value?.label})}
              </Typography>
              <Typography
                component='div'
                className={classes.actionsContainer}
              >
                <MenuItem
                  onClick={handleClose}
                  data-id={`close-remove-language-country-tab-${index}`}
                >
                  <Typography
                    noWrap
                    color='primary'
                    variant='inherit'
                    className={classes.actionsTypography}
                  >{t('buttons.cancel')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    handleClose(e);
                    if ((typeof onRemove === 'function') && value) {
                      onRemove(value);
                    }
                  }}
                  data-id={`confirm-remove-language-country-tab-${index}`}
                >
                  <Typography
                    noWrap
                    color='error'
                    variant='inherit'
                    className={classes.actionsTypography}
                  >{t('buttons.remove')}
                  </Typography>
                </MenuItem>
              </Typography>
            </Paper>
          </ScrollPlayground>
        </div>
      </ClickAwayListener>
    </>
  )
}
