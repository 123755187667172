import React from 'react';
import {Table} from 'antd';
import {useApiKeys} from './useApiKeys';
import {Upsert} from './components/Upsert';
import {TableHeader} from './components/TableHeader';
import {ModalTypes} from '../../../../../constants/modalTypes';
import {FiniteStates} from '../../../../../constants/finiteStates';

export function ApiKeys() {
  const {
    row,
    modal,
    total,
    current,
    apiKeys,
    columns,
    pageSize,
    apiKeysState,
    defaultValues,
    tableOnChange,
  } = useApiKeys();

  return (
    <>
      <TableHeader/>
      <Table
        rowKey='id'
        columns={columns}
        dataSource={apiKeys}
        onChange={tableOnChange}
        loading={apiKeysState === FiniteStates.LOADING}
        scroll={{x: true, scrollToFirstRowOnChange: true}}
        pagination={{showQuickJumper: true, total, current, pageSize}}
      />
      {(ModalTypes.CREATE === modal) && (
        <Upsert
          defaultValues={defaultValues}
        />
      )}
      {(ModalTypes.EDIT === modal) && (
        <Upsert
          defaultValues={row}
        />
      )}
    </>
  );
}
