import React from 'react';

export function Ctr(value: number) {
  return (
    <>
      {((value !== 0) && !value) ? (
        '-'
      ) : (
        `${Number(value).toFixed(2)}%`
      )}
    </>
  )
}