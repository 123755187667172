import {DEFAULT_PAGE_SIZES} from '../../../../../constants';
import {ActionTypes} from '../../../../constants/templateCampaigns';

const InitialState: any = {
  pageSize                    : 10,
  columns                     : [],
  sorting                     : [],
  pageSizes                   : DEFAULT_PAGE_SIZES,
  columnOrder                 : [
    'app_title',
    'uuid',
    'ctr_get',
    'open',
    'sent',
    'data->wait_until',
    'exclude_data',
  ],
  tableColumnExtensions       : [
    {
      width     : 250,
      columnName: 'data->wait_until',
    },
    {
      width     : 200,
      columnName: 'uuid',
    },
  ],
  sortingStateColumnExtensions: [
    {
      sortingEnabled: false,
      columnName    : 'exclude_data',
    },
    {
      sortingEnabled: false,
      columnName    : 'uuid',
    },
  ],
};

export default (state: any = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_TEMPLATE_CAMPAIGNS_TABLE_OPTIONS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};
