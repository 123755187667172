import {Spin} from 'antd';
import React from 'react';
import stripeJs from '@stripe/stripe-js';
import {CardElement} from '@stripe/react-stripe-js';
import './index.less';

const cardElementOptions: stripeJs.StripeCardElementOptions = {
  hidePostalCode: true,
  style         : {
    base: {
      fontSize  : '14px',
      lineHeight: '1.5715',
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    },
  },
  classes       : {
    focus  : 'StripeElementFocus',
    invalid: 'StripeElementInvalid',
  }
};

export const CardField = (
  {
    onBlur,
    invalid,
    onChange,
  }: {
    invalid?: boolean;
    onBlur: () => void;
    onChange: (event: stripeJs.StripeCardElementChangeEvent) => any;
  }
) => {
  const [ready, onReady] = React.useState(false);

  function handleReadyChange(element: stripeJs.StripeCardElement) {
    element.focus();
    onReady(true);
  }

  if (invalid) {
    document.querySelector('.StripeElement')?.classList.add('StripeElement-Invalid')
  } else {
    document.querySelector('.StripeElement')?.classList.remove('StripeElement-Invalid')
  }

  return (
    <Spin
      spinning={!ready}
    >
      <div
        className='cardElementWrapper'
      >
        <CardElement
          onBlur={onBlur}
          onChange={onChange}
          onReady={handleReadyChange}
          options={cardElementOptions}
        />
      </div>
    </Spin>
  )
};
