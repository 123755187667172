import {getSmartPush} from '../index';
import {createSelector} from 'reselect';
import {emptyObject} from '../../../constants';

export const getSubscribers = createSelector(getSmartPush, (smartPush) => {
  return smartPush.subscribers;
});

export const getData = createSelector(getSubscribers, (subscribers) => {
  return subscribers.data;
});

export const getById = createSelector(getData, (data) => {
  return data.byId;
});

export const getAllIds = createSelector(getData, (data) => {
  return data.allIds;
});

export const getSubscriber = createSelector(getData, (data) => {
  return data.entity;
});

export const getIsLoading = createSelector(getData, (data) => {
  return data.loading;
});

export const getDataOptions = createSelector(getData, (data) => {
  return data.dataOptions || emptyObject;
});

export const getActionDialogObj = createSelector(getData, (data) => {
  return data.actionDialog;
});

export const getActionDialog = createSelector(getActionDialogObj, (actionDialog) => {
  return actionDialog?.dialog;
});

export const getActionDialogData = createSelector(getActionDialogObj, (actionDialog) => {
  return actionDialog?.data;
});

export const getOptions = createSelector(getSubscribers, (subscribers) => {
  return subscribers.options;
});

export const getLastQuery = createSelector(getOptions, (options) => {
  return options.lastQuery;
});

export const getPaginationOptions = createSelector(getSubscribers, (subscribers) => {
  return subscribers.pagination;
});
