import React from 'react';
import {getParams} from './utils';
import {useLocation} from 'react-router';
import {urlHelper} from './utils/urlHelper';
import {AppRoutes} from './constants/appRoutes';
import {Application} from './types/application';
import {Spinner} from './components/antd/Spinner';
import {MainLayout} from './components/antd/MainLayout';
import {useSmartPushProvider} from './hooks/useSmartPushProvider';

export const SmartPushContext = React.createContext<Partial<ReturnType<typeof useSmartPushProvider>>>({});

export function SmartPushProvider(
  {
    children: Children,
  }: {
    children: React.ComponentType<{
      appUuid: string;
      user: Application.User;
    }>;
  }
) {
  const {pathname} = useLocation();
  const provider = useSmartPushProvider();

  const {
    plan,
  } = getParams<{ plan: string }>(pathname, {
    path: [
      AppRoutes.CHECKOUT,
      AppRoutes.CHECKOUT_APP,
    ],
  });

  return (
    <SmartPushContext.Provider
      value={provider}
    >{provider.user?.email ? (
      (plan || [
        AppRoutes.SURVEY,
        urlHelper(AppRoutes.SURVEY_APP, {appUuid: provider.appUuid}),
      ].includes(pathname)) ? (
        <Children
          user={provider.user}
          appUuid={provider.appUuid}
        />
      ) : (
        <MainLayout
          appUuid={provider.appUuid}
        >
          <Children
            user={provider.user}
            appUuid={provider.appUuid}
          />
        </MainLayout>
      )
    ) : <Spinner/>}
    </SmartPushContext.Provider>
  )
}
