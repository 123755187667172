import {Card} from 'antd';
import React from 'react';
import {Radio} from 'antd';
import {Space} from 'antd';
import {Button} from 'antd';
import {Divider} from 'antd';
import {Controller} from 'react-hook-form';
import {UseFormClearErrors} from 'react-hook-form';
import {IPayAndLaunchForm} from '../../usePayAndLaunch';
import {Control} from 'react-hook-form/dist/types/form';
import {GrowthPlanHeader} from './components/GrowthPlanHeader';
import {Text} from '../../../../../../../components/antd/Typography';
import {FormAlert} from '../../../../../../../components/antd/FormAlert';

export function GrowthPlan(
  {
    control,
    clearErrors,
    skipCheckout,
    isSubmitting,
    skippingCheckout,
    subscriptionPlans,
  }: {
    isSubmitting?: boolean;
    skipCheckout: () => void;
    skippingCheckout?: boolean;
    control: Control<IPayAndLaunchForm>;
    subscriptionPlans: Record<string, any>[];
    clearErrors: UseFormClearErrors<IPayAndLaunchForm>;
  }
) {
  return (
    <Card
      bordered={false}
      title='Growth Plan'
      className='growth-plan m-b-2'
    >
      <Controller
        control={control}
        name='free_plan_message'
        render={(
          {
            field,
            fieldState: {
              error,
            }
          }
        ) => {
          return (
            <>
              <input
                type='hidden'
                {...field}
              />
              <FormAlert
                type='error'
                message={error?.message}
                onClose={() => clearErrors('free_plan_message')}
              />
            </>
          )
        }}
      />
      <GrowthPlanHeader
        control={control}
        subscriptionPlans={subscriptionPlans}
      />
      <Controller
        name='plan_slug'
        control={control}
        render={(
          {
            field: {
              ref,
              ...fieldRest
            },
          }
        ) => {
          return (
            <Radio.Group
              ref={ref}
              {...fieldRest}
            >
              <Space
                direction='vertical'
              >
                {subscriptionPlans?.filter((sp: any) => !sp?.plan_slug?.startsWith('free'))?.map((subscriptionPlan: any) => {
                  return (
                    <Radio
                      key={subscriptionPlan.plan_slug}
                      value={subscriptionPlan.plan_slug}
                    >${subscriptionPlan.unit_amount} / month
                    </Radio>
                  )
                })}
              </Space>
            </Radio.Group>
          )
        }}
      />
      <Divider
      ><Text>or</Text>
      </Divider>
      <div
        className='d-flex justify-content-center'
      >
        <Button
          block
          onClick={skipCheckout}
          disabled={isSubmitting}
          loading={skippingCheckout}
          style={{maxWidth: '26rem'}}
        >
          <Text
            ellipsis
            disabled={isSubmitting}
          >Continue with Free Plan
          </Text>
        </Button>
      </div>
    </Card>
  )
}
