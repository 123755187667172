import {Tag} from 'antd';
import React from 'react';
import {Popover} from 'antd';
import {Text} from '../../../../../../components/antd/Typography';

export function Tags({value}: { value: string }) {
  const valueAsArray = value.split(',');

  return (
    <div
      style={{whiteSpace: 'nowrap'}}
    >
      {valueAsArray.slice(0, 3).map((tag: string) => {
        return (
          <Tag
            key={tag}
            color='geekblue'
            className='m-b-2'
          >
            <Text
              fontWeight='medium'
              ellipsis={{tooltip: tag}}
              style={{maxWidth: '12rem', color: 'inherit'}}
            >{tag.toLowerCase()}
            </Text>
          </Tag>
        );
      })}
      {(valueAsArray.length > 3) && (
        <Popover
          trigger='click'
          content={(
            <div style={{maxWidth: 300}}>
              {valueAsArray.slice(3, valueAsArray.length).map((tag: string) => {
                return (
                  <Tag
                    key={tag}
                    color='geekblue'
                    className='m-b-2'
                  >
                    <Text
                      fontWeight='medium'
                      ellipsis={{tooltip: tag}}
                      style={{maxWidth: '12rem', color: 'inherit'}}
                    >{tag.toLowerCase()}
                    </Text>
                  </Tag>
                );
              })}
            </div>
          )}
        >
          <Tag
            color='geekblue'
            className='m-b-2 pointer'
          >
            <Text
              fontWeight='medium'
              style={{maxWidth: '12rem', color: 'inherit'}}
            >{`+ ${valueAsArray.length - 3} ...`}
            </Text>
          </Tag>
        </Popover>
      )}
    </div>
  )
}