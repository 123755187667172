import React from 'react';
import {useSelector} from 'react-redux';
import {AppRoutes} from '../../constants/appRoutes';
import {Suspended} from '../../components/antd/Suspended';
import {_suspended} from '../../store/selectors/application';

const availablePaths: string[] = [
  AppRoutes.MANAGE_ACCOUNT,
  AppRoutes.MANAGE_ACCOUNT_APP,

  AppRoutes.CHECKOUT,
  AppRoutes.CHECKOUT_APP,

  AppRoutes.SECURITY,
  AppRoutes.SECURITY_APP,

  AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS,
  AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP,

  AppRoutes.CHANGE_PLAN,
  AppRoutes.CHANGE_PLAN_APP,

  AppRoutes.PAY_AND_ACTIVATE,
  AppRoutes.PAY_AND_ACTIVATE_APP,
];

export function SuspendedProvider(
  {
    children,
    matchPath,
  }: {
    matchPath: string;
    children: React.ReactNode;
  }
) {
  const suspended = useSelector(_suspended);

  if (suspended && !availablePaths.includes(matchPath)) {
    return (
      <Suspended/>
    )
  } else {
    return <>{children}</>
  }
}
