import {getState} from '../index';
import {createSelector} from 'reselect';

export const _segmentsReducer = createSelector(getState, (application) => {
  return application.segments;
});

export const _segment = createSelector(_segmentsReducer, (segments) => {
  return segments.entity;
});

export const _segments = createSelector(_segmentsReducer, (segments) => {
  return segments.entities;
});

export const _segmentsParams = createSelector(_segmentsReducer, (segments) => {
  return segments?.params?.map((param: Record<string, any>) => ({
    value  : param.id,
    type   : param.type,
    table  : param.table,
    field  : param.field,
    label  : param.label,
    search : param.search,
    filters: param.filters,
  }));
});

export const _dataOptions = createSelector(_segmentsReducer, (segments) => {
  return segments.dataOptions;
});

export const _total = createSelector(_dataOptions, (options) => {
  return options.total;
});

export const _sorterOrder = createSelector(_dataOptions, (options) => {
  return options.sorterOrder;
});

export const _sorterField = createSelector(_dataOptions, (options) => {
  return options.sorterField;
});

export const _fetchingSegmentState = createSelector(_dataOptions, (options) => {
  return options.fetchingSegmentState;
});

export const _fetchingSegmentsState = createSelector(_dataOptions, (options) => {
  return options.fetchingSegmentsState;
});

export const _fetchingSegmentsParamsState = createSelector(_dataOptions, (options) => {
  return options.fetchingSegmentParamsState;
});

export const _pageSize = createSelector(_dataOptions, (options) => {
  return options.pageSize;
});

export const _pageNumber = createSelector(_dataOptions, (options) => {
  return options.pageNumber;
});

export const _searchText = createSelector(_dataOptions, (options) => {
  return options.searchText;
});
