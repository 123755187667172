import React from 'react';
import {Spin} from 'antd';
import {Card} from 'antd';
import {Button} from 'antd';
import {Result} from 'antd';
import {Tooltip} from 'antd';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {ANIMATION_DELAY} from '../../../../../../../constants';
import {Text} from '../../../../../../../components/antd/Typography';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import {FiniteStates} from '../../../../../../../constants/finiteStates';
import {_reportsDates} from '../../../../../../../store/selectors/reports';
import {_data} from '../../../../../../../store/selectors/reports/periodUnsubscribed';
import {_data_1} from '../../../../../../../store/selectors/reports/periodUnsubscribed';
import {_messages} from '../../../../../../../store/selectors/reports/periodUnsubscribed';
import {_fetch} from '../../../../../../../store/actions/reports/periodUnsubscribed/fetch';
import {_fetchingState} from '../../../../../../../store/selectors/reports/periodUnsubscribed';

export function PeriodUnsubscribed(
  {
    height,
  }: {
    height: number;
    callback: Highcharts.ChartCallbackFunction;
  }
) {
  const dispatch = useDispatch();
  const messages = useSelector(_messages);
  const dates = useSelector(_reportsDates);
  const fetchingState = useSelector(_fetchingState);
  const {appUuid} = useParams<{ appUuid: string }>();

  if (fetchingState === FiniteStates.FAILURE) {
    return (
      <Card
        style={{height}}
        className='m-t-2 d-flex align-items-center justify-content-center'
      >
        <Result
          status='error'
          style={{padding: 0}}
          title='Subscribed and Unsubscribed in Period'
          subTitle={Object?.values(messages)?.join('. ') || 'Failed to Load'}
          extra={[
            <Button
              ghost
              key='console'
              type='primary'
              icon={<ReloadOutlined/>}
              onClick={() => dispatch(_fetch(appUuid, dates))}
            >Reload
            </Button>
          ]}
        />
      </Card>
    )
  } else {
    return (
      <Card
        className='m-t-2'
      >
        <Spin
          spinning={[
            FiniteStates.IDLE,
            FiniteStates.LOADING,
          ].includes(fetchingState)}
          delay={ANIMATION_DELAY}
        >
          <div
            className='d-flex align-items-center'
          >
            <Text
              strong
              fontSize='large'
              style={{color: 'rgba(0,0,0,.85)'}}
            >Subscribed and Unsubscribed in Period
            </Text>
            <Tooltip
              title='Shows unsubscribes per day and subscriptions. Unsubscribes are shown on the day of their subscription.'
            >
              <Text
                fontSize='small'
                type='secondary'
                className='m-l-1 m-b-1'
              >
                <InfoCircleOutlined/>
              </Text>
            </Tooltip>
          </div>
          <MemoChart
            height={height}
          />
        </Spin>
      </Card>
    )
  }
}

const emptyArray: any[] = [];

const MemoChart = React.memo(function (
  {
    height,
  }: {
    height: number;
  }
) {
  const data = useSelector(_data);
  const data_1 = useSelector(_data_1);

  return (
    <HighchartsReact
      options={{
        legend     : {
          borderWidth    : 1,
          x              : -4,
          y              : -32,
          floating       : true,
          verticalAlign  : 'top',
          shadow         : false,
          borderColor    : '#CCC',
          align          : 'right',
          backgroundColor: 'white',
        },
        credits    : {
          enabled: false
        },
        title      : false,
        chart      : {
          spacingBottom: 8,
          spacingTop   : 32,
          type         : 'column',
          height       : height - 75.14,
        },
        xAxis      : {
          type                : 'datetime',
          minTickInterval     : 24 * 3600 * 1000,
          dateTimeLabelFormats: {
            day  : '%e-%b-%y',
            month: '%b-%y-%e',
          },
          crosshair           : {
            width: 1.5,
            color: '#24083C',
          },
        },
        yAxis      : {
          title      : false,
          endOnTick  : false,
          startOnTick: false,
          labels     : {
            rotation: -90
          }
        },
        tooltip    : {
          pointFormat: '{series.name}: {point.y}'
        },
        plotOptions: {
          column: {
            stacking  : 'normal',
            dataLabels: {
              enabled: true
            }
          }
        },
        series     : [
          {
            color: '#5F639D',
            name : 'Unsubscribed in Period',
            data : data.length ? [...data] : emptyArray,
          },
          {
            color: '#434348',
            name : 'Subscribed in Period',
            data : data_1.length ? [...data_1] : emptyArray,
          }
        ]
      }}
      allowChartUpdate
      highcharts={Highcharts}
    />
  )
})
