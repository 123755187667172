import {notification} from 'antd';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {ActionTypes} from '../../constants/subscribers';
import {_apiBaseUrl} from '../../selectors/application';
import {getLastQuery} from '../../selectors/subscribers';
import {getActionDialogData} from '../../selectors/subscribers';

export const setDataAction = (data: any) => ({
  payload: data,
  type   : ActionTypes.WEB_NOTIFY_SUBSCRIBERS_SET_DATA,
});

export const optionsAction = (options: any) => ({
  payload: options,
  type   : ActionTypes.WEB_NOTIFY_SUBSCRIBERS_TABLE_OPTIONS,
});

export const dataOptionsAction = (options: any) => ({
  payload: options,
  type   : ActionTypes.WEB_NOTIFY_SUBSCRIBERS_DATA_OPTIONS,
});

export const loadingAction = (loading: boolean) => ({
  payload: loading,
  type   : ActionTypes.WEB_NOTIFY_SUBSCRIBERS_TOGGLE_LOADING,
});

export const toggleEntityAction = (options: any) => ({
  payload: options,
  type   : ActionTypes.WEB_NOTIFY_SUBSCRIBERS_TOGGLE_ENTITY,
});

export const toggleDialogAction = (payload?: {
  data?: any;
  dialog: string;
}) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_SUBSCRIBERS_TOGGLE_DIALOG,
});

export const emptyDataAction = () => ({
  type: ActionTypes.WEB_NOTIFY_SUBSCRIBERS_EMPTY_DATA,
});

export const fetchAction = (): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(loadingAction(true));

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.getList(getLastQuery(getState()));

  if (hasError) {
    dispatch(loadingAction(false));
    notification.error({message});
  } else {
    const {
      rows,
      ...rest
    } = data;

    dispatch(setDataAction(rows));
    dispatch(optionsAction(rest));
    dispatch(loadingAction(false));
  }
};

export const handleExportAction = (
  as: string,
): Globals.ThunkAction => async (dispatch, getState, api) => {
  const lastQuery = getLastQuery(getState());

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.get(`${lastQuery}&export=${as}`);

  notification.open({
    type   : hasError ? 'error' : 'success',
    message: data?.errors?.limits_notice || message,
  });
};

export const destroyAction = (
  appUuid: string,
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isDeleting: true,
  }));

  const apiBaseUrl = _apiBaseUrl(getState());
  const {uuid} = getActionDialogData(getState());

  const {
    hasError,
    body: {
      message,
    },
  } = await api.request(`${apiBaseUrl}${urlHelper(ApiRoutes.DELETE_SUBSCRIBERS, {
    uuid,
    appUuid,
  })}`, 'DELETE');

  dispatch(dataOptionsAction({
    isDeleting: void 0,
  }));

  dispatch(toggleDialogAction(void 0));

  notification.open({
    message,
    type: hasError ? 'error' : 'success',
  });

  if (!hasError) {
    await dispatch(fetchAction());
  }
};

export const getAction = (
  appUuid: string,
  uuid: string,
  {
    signal,
    redirect,
  }: {
    signal?: AbortSignal;
    redirect?: () => void;
  } = {},
): Globals.ThunkAction => async (dispatch: any, getState: any, api) => {
  const apiBaseUrl = _apiBaseUrl(getState());
  const endPoint = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_SUBSCRIBER, {
    uuid,
    appUuid,
  })}`;

  const {
    hasError,
    body: {
      data,
      name,
      status,
      message,
    },
  } = await api.get(endPoint, {signal});

  if (hasError) {
    if ((status === 404) && (typeof redirect === 'function')) {
      redirect();
    } else {
      if ((name !== 'AbortError')) {
        notification.error({message});
      }
    }
  } else {
    dispatch(toggleEntityAction(data))
  }
};
