import React from 'react';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {useLocation} from 'react-router';
import {Tab} from '../../components/Tabs';
import {Tabs} from '../../components/Tabs';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {useTitle} from '../../hooks/useTitle';
import {TabPanel} from '../../components/Tabs';
import {urlHelper} from '../../utils/urlHelper';
import Builders from './Builders';
import Icons from './Icons';
import Images from './Images';
import Titles from './Titles';
import Messages from './Messages';
import {AppRoutes} from '../../constants/appRoutes';
import {Content} from '../../components/antd/Content';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root    : {
    flexGrow: 1,
  },
  tabsRoot: {
    '& div.MuiTabs-fixed': {},
  },
  tabRoot : {
    border: 'none',
  },
}));
// TODO:: Remove material components
export default function Builder() {
  const classes = useStyles();
  const {t} = useTranslation();
  const {pathname} = useLocation();
  useTitle(t('builder.document_title'));

  const {
    appUuid,
  } = useParams<{ appUuid: string }>();

  return (
    <Content>
      <Paper
        square
        elevation={0}
        className={classes.root}
      >
        <Tabs
          value={pathname}
          className={classes.tabsRoot}
        >
          <Tab
            disableRipple
            component={Link}
            textColor='primary'
            className={classes.tabRoot}
            label={t('builder.builder')}
            to={urlHelper(AppRoutes.BUILDER, {appUuid})}
            value={urlHelper(AppRoutes.BUILDER, {appUuid})}
          />
          <Tab
            disableRipple
            component={Link}
            textColor='primary'
            className={classes.tabRoot}
            label={t('builder.icons')}
            to={urlHelper(AppRoutes.BUILDER_ICONS, {appUuid})}
            value={urlHelper(AppRoutes.BUILDER_ICONS, {appUuid})}
          />
          <Tab
            disableRipple
            component={Link}
            textColor='primary'
            className={classes.tabRoot}
            label={t('builder.images')}
            to={urlHelper(AppRoutes.BUILDER_IMAGES, {appUuid})}
            value={urlHelper(AppRoutes.BUILDER_IMAGES, {appUuid})}
          />
          <Tab
            disableRipple
            component={Link}
            textColor='primary'
            className={classes.tabRoot}
            label={t('builder.titles')}
            to={urlHelper(AppRoutes.BUILDER_TITLES, {appUuid})}
            value={urlHelper(AppRoutes.BUILDER_TITLES, {appUuid})}
          />
          <Tab
            disableRipple
            component={Link}
            textColor='primary'
            className={classes.tabRoot}
            label={t('builder.messages')}
            to={urlHelper(AppRoutes.BUILDER_MESSAGES, {appUuid})}
            value={urlHelper(AppRoutes.BUILDER_MESSAGES, {appUuid})}
          />
        </Tabs>
        <TabPanels/>
      </Paper>
    </Content>
  )
}

function TabPanels() {
  const {pathname} = useLocation();

  const {
    appUuid,
  } = useParams<{ appUuid: string }>();

  return (
    <>
      <TabPanel
        value={pathname}
        style={{border: '1px solid transparent'}}
        index={urlHelper(AppRoutes.BUILDER, {appUuid})}
      ><Builders/>
      </TabPanel>
      <TabPanel
        value={pathname}
        style={{border: '1px solid transparent'}}
        index={urlHelper(AppRoutes.BUILDER_ICONS, {appUuid})}
      ><Icons/>
      </TabPanel>
      <TabPanel
        value={pathname}
        style={{border: '1px solid transparent'}}
        index={urlHelper(AppRoutes.BUILDER_IMAGES, {appUuid})}
      ><Images/>
      </TabPanel>
      <TabPanel
        value={pathname}
        style={{border: '1px solid transparent'}}
        index={urlHelper(AppRoutes.BUILDER_TITLES, {appUuid})}
      ><Titles/>
      </TabPanel>
      <TabPanel
        value={pathname}
        style={{border: '1px solid transparent'}}
        index={urlHelper(AppRoutes.BUILDER_MESSAGES, {appUuid})}
      ><Messages/>
      </TabPanel>
    </>
  )
}
