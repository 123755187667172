import {IObject} from '../types';
import {IAction} from '../types';
import {IMessage} from '../types';
import {TFunction} from 'i18next';
import {urlHelper} from '../utils/urlHelper';

export const prefix = 'apps';

export const messageSeparator = '{{{/n}}}';

export const privatePrefix = 'sp';

export const blankFeatures = 'rel=noopener noreferrer,menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';

export const TITLE_LIMIT = 75;

export const MESSAGE_LIMIT = 470;

export const AbortError = 'AbortError';

export const ANIMATION_DELAY: number = 400;

export const DEFAULT_TEMPLATE_ID: number = 5;

export const DEFAULT_ICON_URL = ':CDN_URL/icon-push-dark.png';

export const emptyArray: IObject[] = [];

export const DEFAULT_TTL: IObject = {
  ttl_value: 1,
  ttl_unit : {
    value: 'day',
    label: 'day',
  },
};

export const DEFAULT_PAGE_SIZES: number[] = [
  10,
  20,
  30,
  50,
];

export const POSITIONS: string[] = [
  'top_left',
  'top_center',
  'top_right',
  'mid_left',
  'mid_center',
  'mid_right',
  'bottom_left',
  'bottom_center',
  'bottom_right'
];

export const emptyObject: IObject = {};

export const dataPrefixes = {
  auth: {
    login         : 'auth-login',
    register      : 'auth-register',
    resetPassword : 'auth-reset-password',
    forgotPassword: 'auth-forgot-password',
  }
}

export function getLocales(t: TFunction) {
  return [
    {
      value: 'country',
      label: t('locale.country'),
    },
    {
      value: 'language',
      label: t('locale.language'),
    },
  ]
}

export function getDefaultMessageValues(t: TFunction, {
  cdn_url,
  other,
}: {
  cdn_url: string;
  other?: Record<string, any>;
}): IMessage {
  return {
    title    : '',
    message  : '',
    image_url: '',
    _id      : 'default',
    iso      : 'default',
    text     : t('default'),
    icon_url : urlHelper(DEFAULT_ICON_URL, {
      CDN_URL: cdn_url,
    }),
    ...other,
  }
}

export function getDefaultActionValues(): IAction {
  return {
    url     : '',
    type    : '',
    title   : '',
    icon_url: '',
  }
}

export const SHOW_SURVEY_AFTER_REGISTERED_AT = '2022-05-16';
export const SHOW_CONGRATULATORY_MESSAGE_AFTER_REGISTERED_AT = '2022-05-16';
