import React from 'react';
import MuiCard from '@material-ui/core/Card';
import {CardProps} from '@material-ui/core/Card';

export function Card(
  {
    style,
    ...rest
  }: CardProps
): JSX.Element {
  return (
    <MuiCard
      square
      elevation={0}
      variant='outlined'
      style={{
        overflow: 'initial',
        border  : '1px solid #f0f0f0',
        ...style
      }}
      {...rest}
    />
  )
}