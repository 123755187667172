import React from 'react';
import {Form} from './Components/Form';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {Elements} from '@stripe/react-stripe-js';
import {urlHelper} from '../../../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../../../constants/appRoutes';
import {Spinner} from '../../../../../../../components/antd/Spinner';
import {initStripePromise} from '../../../../../../../utils/initStripe';
import {FiniteStates} from '../../../../../../../constants/finiteStates';
import {_subscriptionPlans} from '../../../../../../../store/selectors/portal';
import {_prorationTotalFiniteState} from '../../../../../../../store/selectors/portal';
import {_setProrationTotal} from '../../../../../../../store/actions/subscriptionsAndBilling';
import {_updateProrationTotal} from '../../../../../../../store/actions/subscriptionsAndBilling/updateProrationTotal';
import './index.less';

export function PayAndActivate() {
  const {push} = useHistory();
  const dispatch = useDispatch();
  const subscriptionPlans = useSelector(_subscriptionPlans);
  const {plan, appUuid} = useParams<{ plan: string; appUuid: string }>();
  const prorationTotalFiniteState = useSelector(_prorationTotalFiniteState);

  const loading = [
    FiniteStates.IDLE,
    FiniteStates.LOADING,
  ].includes(prorationTotalFiniteState);

  React.useEffect(() => {
    if (subscriptionPlans.find((sPlan: any) => sPlan.plan_slug === plan)) {
      dispatch(_updateProrationTotal(plan))
    } else {
      if (appUuid) {
        push(urlHelper(AppRoutes.CHANGE_PLAN_APP, {appUuid}));
      } else {
        push(AppRoutes.CHANGE_PLAN);
      }
    }

    return function () {
      dispatch(_setProrationTotal({
        prorationTotalFiniteState: FiniteStates.IDLE,
      }));
    }
  }, [
    plan,
    push,
    appUuid,
    dispatch,
    subscriptionPlans,
  ]);

  if (loading) {
    return <Spinner/>;
  }

  return (
    <Elements
      options={{locale: 'en'}}
      stripe={initStripePromise()}
    >
      <Form/>
    </Elements>
  )
}
