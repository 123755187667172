import {getState} from '../index';
import {createSelector} from 'reselect';

export const _app = createSelector(getState, (state) => {
  return state.application;
});

export const _appEnv = createSelector(_app, (app) => {
  return app.envVariables;
});

export const _appSumoMessage = createSelector(_app, (app) => {
  return app.appSumoMessage;
});

export const _activationAppSumoEmail = createSelector(_app, (app) => {
  return app.activation_email;
});

export const _appSumoTokenCheckingState = createSelector(_app, (app) => {
  return app.appSumoTokenCheckingState;
});

export const _isMobile = createSelector(_app, (app) => {
  return !!app.isMobile;
});

export const _formInfo = createSelector(_app, (app) => {
  return app.form;
});

export const _suspended = createSelector(_app, (app) => {
  return app.suspended;
});

export const _apiBaseUrl = createSelector(_appEnv, (env) => {
  return `${env.REACT_APP_PROTOCOL}://${env.REACT_APP_HOSTNAME}`;
});

export const _sidebarData = createSelector(_app, (app) => {
  return app.sidebarData;
});

export const _accessToken = createSelector(_app, (app) => {
  return app.accessToken;
});

export const _beforeunload = createSelector(_app, (app) => {
  return app.beforeunload;
});

export const _isUnauthorized = createSelector(_app, (app) => {
  return app.unauthorized;
});

export const _isAuthenticated = createSelector(_app, (app) => {
  return app.authenticated;
});

export const _applicationStatus = createSelector(_app, (app) => {
  return app.applicationStatus;
});
