import {getState} from '../index';
import {createSelector} from 'reselect';
import {emptyObject} from '../../../constants';

export const getModel = createSelector(getState, (state) => {
  return state.gallery;
});

export const getData = createSelector(getModel, (model) => {
  return model.data;
});

export const getById = createSelector(getData, (data) => {
  return data.byId;
});

export const getAllIds = createSelector(getData, (data) => {
  return data.allIds;
});

export const getEntity = createSelector(getData, (data) => {
  return data.entity;
});

export const getIsLoading = createSelector(getData, (data) => {
  return data.loading;
});

export const getErrors = createSelector(getData, (data) => {
  return data.errors;
});

export const getDataOptions = createSelector(getData, (data) => {
  return data.dataOptions || emptyObject;
});

export const getActionDialogObj = createSelector(getData, (data) => {
  return data.actionDialog;
});

export const getActionDialog = createSelector(getActionDialogObj, (actionDialog) => {
  return actionDialog?.dialog;
});

export const getActionDialogData = createSelector(getActionDialogObj, (actionDialog) => {
  return actionDialog?.data;
});

export const getOptions = createSelector(getModel, (model) => {
  return model.options;
});

export const getLastQuery = createSelector(getOptions, (options) => {
  return options.lastQuery;
});

export const getPaginationOptions = createSelector(getModel, (model) => {
  return model.pagination;
});
