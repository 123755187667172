import React from 'react';
import {Card} from '../Card';
import {TextField} from '../Input';
import Box from '@material-ui/core/Box';
import {useSelector} from 'react-redux';
import {CardHeader} from '../CardHeader';
import {IconButton} from '../IconButton';
import Radio from '@material-ui/core/Radio';
import {AutoComplete} from '../AutoComplete';
import DateFnsUtils from '@date-io/date-fns';
import {Theme} from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import {options} from '../../constants/ttlOptions';
import {DateTimePicker} from '@material-ui/pickers';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import CardContent from '@material-ui/core/CardContent';
import {timeOptions} from '../../constants/timeOptions';
import FormControl from '@material-ui/core/FormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {_hasTimezonesAccess} from '../../store/selectors/application/acl';

const useStyles = makeStyles((theme: Theme) => ({
  nestedCard     : {
    marginBottom: theme.spacing(4),
  },
  cardHeaderRoot : {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  cardContentRoot: {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  ttlLabel       : {
    alignItems    : 'center',
    justifyContent: 'flex-start',
    display       : 'inline-flex',
  },
}));

export default function CampaignScheduleCard(
  {
    labels,
    errors,
    entity,
    onChange,
  }: {
    labels: any;
    entity: any;
    errors?: any;
    onChange: any;
    loading?: boolean;
  }
) {
  const classes = useStyles();
  const hasTimezonesAccess = useSelector(_hasTimezonesAccess);

  return (
    <Card
      elevation={0}
    >
      <CardHeader
        color='primary'
        title={labels.schedule}
      />
      <CardContent
        classes={{root: classes.cardContentRoot}}
      >
        <>
          <FormControl
            component='fieldset'
          >
            <FormLabel
              component='legend'
            >
              <Typography
                color='primary'
              >{labels.delivery}
              </Typography>
            </FormLabel>
            <RadioGroup
              name='delivery'
              value={entity.delivery || 'immediately'}
              onChange={(e: any, value: any) => {
                onChange(e.target.name, value);
                if (value === 'immediately') {
                  onChange('wait_until', void 0);
                }
              }}
            >
              <FormControlLabel
                value='immediately'
                control={<Radio color='primary'/>}
                label={labels.begin_sending_immediately}
              />
              <FormControlLabel
                value='particular'
                control={<Radio color='primary'/>}
                label={labels.begin_sending_at_a_particular_time}
              />
            </RadioGroup>
          </FormControl>
          {entity.delivery === 'particular' && (
            <div>
              <Box
                marginTop={1}
                marginBottom={2}
              >
                <Divider/>
              </Box>
              <div
                style={{
                  display: 'flex'
                }}
              >
                <TextField
                  type='number'
                  inputProps={{min: 1}}
                  name='wait_until_value'
                  style={{marginRight: 8}}
                  label={labels.wait_before_send}
                  error={!!errors?.wait_until_value}
                  helperText={errors?.wait_until_value}
                  value={entity?.wait_until_value || ''}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
                <Box
                  marginTop='-6px'
                >
                  <AutoComplete
                    disableClearable
                    options={options}
                    style={{width: 140}}
                    value={entity.wait_until_unit}
                    renderInput={(props) => (
                      <TextField
                        name='role'
                        type='text'
                        size='small'
                        variant='outlined'
                        style={{marginTop: 28}}
                        error={!!errors?.wait_until_unit}
                        helperText={errors?.wait_until_unit}
                        {...props}
                      />
                    )}
                    onChange={(e: any, value: any) => {
                      onChange('wait_until_unit', value);
                    }}
                    getOptionLabel={option => (option && option.label) || ''}
                    getOptionSelected={(option, value) => (value && value.value) === (option && option.value)}
                  />
                </Box>
              </div>
              {!!localStorage.getItem('test') && (
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                >
                  <DateTimePicker
                    variant='inline'
                    label='Wait before send'
                    value={entity?.wait_before_send || new Date()}
                    onChange={(date) => onChange('wait_before_send', date)}
                  />
                </MuiPickersUtilsProvider>
              )}
            </div>
          )}
        </>
      </CardContent>
      {hasTimezonesAccess && (
        <>
          <Divider/>
          <CardContent
            classes={{root: classes.cardContentRoot}}
          >
            <>
              <FormControl
                component='fieldset'
              >
                <FormLabel
                  component='legend'
                >
                  <Typography
                    color='primary'
                  >{labels.per_user_optimization}
                  </Typography>
                </FormLabel>
                <RadioGroup
                  name='per_user_optimization'
                  value={entity.per_user_optimization || 'immediately'}
                  onChange={(e: any, value: any) => {
                    onChange(e.target.name, value);
                    if (value === 'immediately') {
                      onChange('deliver_at', entity.deliver_at || void 0);
                    }
                  }}
                >
                  <FormControlLabel
                    value='immediately'
                    label={labels.send_immediately}
                    data-id='per-user-optimization-immediately-label'
                    control={
                      <Radio
                        color='primary'
                        data-id='per-user-optimization-immediately-radio'
                        inputProps={{'data-id': 'per-user-optimization-immediately-input'} as any}
                      />
                    }
                  />
                  <FormControlLabel
                    value='particular'
                    data-id='per-user-optimization-particular-label'
                    label={labels.per_user_particular}
                    control={
                      <Radio
                        color='primary'
                        data-id='per-user-optimization-particular-radio'
                        inputProps={{'data-id': 'per-user-optimization-particular-input'} as any}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
              {entity.per_user_optimization === 'particular' && (
                <div>
                  <Box
                    marginBottom='24px'
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                    >
                      <Typography
                        style={{
                          fontSize  : 13,
                          fontWeight: 'bold',
                          padding   : '6px 12px',
                        }}
                      >{labels.deliver_at}
                      </Typography>
                      <Box>
                        <AutoComplete
                          freeSolo
                          size='small'
                          style={{width: 150}}
                          options={timeOptions}
                          onBlur={(e: any) => {
                            if (!e.target.value) {
                              onChange('deliver_at', void 0);
                            }
                          }}
                          value={entity.deliver_at || ''}
                          onInputChange={(e, value) => {
                            onChange('deliver_at', value);
                          }}
                          onChange={(e: any, value: any) => {
                            if (!value) {
                              onChange('deliver_at', value);
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              fullWidth
                              size='small'
                              disableMargin
                            />
                          )}
                        />
                      </Box>
                      <Typography
                        style={{
                          fontSize  : 13,
                          fontWeight: 'bold',
                          padding   : '6px 12px',
                        }}
                      >{labels.in_their_local_timezone}
                      </Typography>
                    </Box>
                    {(errors?.deliver_at) && (
                      <Typography color='error' style={{fontSize: 12}}>
                        {errors.deliver_at}
                      </Typography>
                    )}
                  </Box>
                </div>
              )}
            </>
          </CardContent>
        </>
      )}
      <Divider/>
      <CardContent
        classes={{root: classes.cardContentRoot}}
      >
        <div
          style={{
            display   : 'flex',
            alignItems: 'center'
          }}
        >
          <TextField
            type='number'
            name='ttl_value'
            style={{marginRight: 8}}
            error={!!errors?.ttl_value}
            value={entity.ttl_value || ''}
            helperText={errors?.ttl_value}
            inputProps={{min: 1, 'data-id': 'ttl-value-field'}}
            onChange={(e) => {
              onChange(e.target.name, e.target.value)
            }}
            label={
              <Typography
                component='div'
                className={classes.ttlLabel}
              >
              <span
              >{labels.ttl}
              </span>
                <Tooltip
                  title={labels.ttl_helper_text}
                >
                  <div>
                    <IconButton
                      size='small'
                      color='transparent'
                    >
                      <HelpIcon
                        fontSize='small'
                      />
                    </IconButton>
                  </div>
                </Tooltip>
              </Typography>
            }
          />
          <Box
            marginTop='2px'
          >
            <AutoComplete
              disableClearable
              options={options}
              style={{width: 140}}
              value={entity.ttl_unit}
              onChange={(e: any, value: any) => {
                onChange('ttl_unit', value);
              }}
              renderInput={(props) => {
                return (
                  <TextField
                    name='role'
                    type='text'
                    variant='outlined'
                    style={{marginTop: 30}}
                    error={!!(errors && errors.ttl)}
                    helperText={errors && errors.ttl}
                    {...props}
                  />
                )
              }}
              getOptionLabel={option => (option && option.label) || ''}
              getOptionSelected={(option, value) => (value && value.value) === (option && option.value)}
            />
            {!!errors?.ttl_value && (
              <Box height={27}/>
            )}
          </Box>
        </div>
      </CardContent>
      <div/>
    </Card>
  );
}
