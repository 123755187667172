import React from 'react';
import Menu from '@material-ui/core/Menu';
import {useTranslation} from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import expandWindow from '../../images/expand_window.png';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root       : {
    display   : 'flex',
    alignItems: 'center',
  },
  closeButton: {
    top     : 0,
    right   : 5,
    position: 'absolute',
  },
  popover    : {
    padding : 24,
    position: 'relative',
  },
  ul         : {
    margin     : 0,
    fontSize   : 12,
    paddingLeft: 18,
    maxHeight  : 300,
    overflowY  : 'auto',
  }
}));

export default function ObjectDrawer(
  {
    value,
    style,
  }: {
    style?: React.CSSProperties;
    value: { [key: string]: string | number | null };
  }
) {
  let count: number = 0;

  const classes = useStyles();
  const {t} = useTranslation();

  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = React.useCallback(() => {
    setOpen(prevOpen => {
      return !prevOpen
    });
  }, []);

  const handleClose = React.useCallback((event: React.MouseEvent<EventTarget>) => {
    setOpen(false);
  }, []);

  return (
    <Typography
      noWrap
      style={style}
      component='div'
      className={classes.root}
    >
      <Typography
        noWrap
        component='div'
      >
        {Object.keys(value || {}).map((item: string, index: number) => {
          if (value[item]) {
            count = count + 1;
          }
          return !!value[item] && (count < 3) && (
            <Typography
              noWrap
              key={`OtherFields_${index}`}
              style={{fontSize: 12, width: 120}}
            >
              <span>{item}</span>
              <span> - </span>
              <span>{`${value[item]}`}</span>
            </Typography>
          )
        })}
      </Typography>
      <>
        <Tooltip
          title={t('show_more')}
        >
          <div>
            <IconButton
              size='small'
              disableRipple
              color='default'
              ref={anchorRef}
              disableFocusRipple
              disableTouchRipple
              onClick={handleToggle}
              style={{marginLeft: 8}}
            >
              <img
                width={18}
                height={18}
                src={expandWindow}
                alt='Expand Window'
                style={{backgroundColor: '#404040'}}
              />
            </IconButton>
          </div>
        </Tooltip>
        <Menu
          open={open}
          elevation={0}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorEl={anchorRef.current}
          anchorOrigin={{
            vertical  : 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            square : true,
            variant: 'outlined',
            style  : {border: '1px solid lightgrey'},
          }}
          transformOrigin={{
            vertical  : 'bottom',
            horizontal: 'center',
          }}
          MenuListProps={{style: {paddingTop: 5, paddingBottom: 5}}}
        >
          <Typography
            component='div'
            className={classes.popover}
          >
            <IconButton
              size='small'
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon
                fontSize='small'
              />
            </IconButton>
            <ul
              className={classes.ul}
            >
              {Object.keys(value || {}).map((item: string, index: number) => {
                return (
                  <li
                    key={`OtherFields_${index}`}
                  >
                    <span>{item}</span>
                    <span> - </span>
                    <span>{`${value[item]}`}</span>
                  </li>
                )
              })}
            </ul>
          </Typography>
        </Menu>
      </>
    </Typography>
  )
}