import React from 'react';
import {useTranslation} from 'react-i18next';
import MuiDialog from '@material-ui/core/Dialog';
import {Button} from '../../../components/Button';
import {DialogProps} from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {CampaignPreview} from '../../../components/WebNotifyCampaignPreview';

export default function PreviewDialog(
  {
    icon,
    image,
    title,
    style,
    message,
    actions,
    close,
    onClose,
    ...rest
  }: Omit<DialogProps, 'children'> & {
    icon?: string;
    image?: string;
    title?: string;
    children?: any;
    message?: string;
    actions?: string[];
    close?: () => void;
    style?: React.CSSProperties
  }
) {
  const {t} = useTranslation();
  const onClick = React.useCallback(() => {
    if (typeof close === 'function') {
      close();
    }
  }, [
    close,
  ])

  return (
    <MuiDialog
      onClose={onClose}
      {...rest}
    >
      <MuiDialogContent>
        <CampaignPreview
          icon={icon}
          image={image}
          style={style}
          title={title}
          message={message}
        />
      </MuiDialogContent>
      <MuiDialogActions>
        <Button
          fullWidth
          size='small'
          color='primary'
          onClick={onClick}
          variant='contained'
        >{t('buttons.close')}
        </Button>
      </MuiDialogActions>
    </MuiDialog>
  )
}
