import React from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import {UseFormReturn} from 'react-hook-form/dist/types';

const iconStyles: React.CSSProperties = {
  marginLeft: 8,
  fontSize  : 15,
}

export function HasError(
  {
    form,
    index,
  }: {
    index: number;
    form: UseFormReturn<any>;
  }
) {
  const {
    formState: {
      errors,
    }
  } = form;
  const error: Record<string, any> = errors?.messages?.[index];
  if (!error) {
    return null;
  }

  return (
    <Tooltip
      title={Object.values(error).map((v, index) => {
        return (
          <Typography
            key={index}
          >{v.message}
          </Typography>
        );
      })}
    >
      <Box
        display='flex'
      >
        <ErrorIcon
          color='error'
          style={iconStyles}
        />
      </Box>
    </Tooltip>
  )
}