import {getState} from '../index';
import {cFirst} from '../../../utils';
import {createSelector} from 'reselect';
import {FEATURE} from '../../../constants/users';
import {aclKeys} from '../../../constants/aclKeys';
import {ACCESS_TYPES} from '../../../constants/users';
import {Permissions} from '../../../constants/permission';
import {FiniteStates} from '../../../constants/finiteStates';

export const _app = createSelector(getState, (state) => {
  return state.application;
});

export const _user = createSelector(_app, (app) => {
  return app.user;
});

export const _hasPendingEmail = createSelector(_user, (user) => {
  return !!user?.pending_email?.email;
});

export const _userId = createSelector(_user, (user) => {
  return Number(user?.id);
});

export const _shortName = createSelector(_user, (user) => {
  return `${cFirst(user?.first_name)}${cFirst(user?.last_name)}`;
});

export const _firstName = createSelector(_user, (user) => {
  return user?.first_name || '';
});

export const _surveyIsLoading = createSelector(_user, (user) => {
  return [
    FiniteStates.IDLE,
    FiniteStates.LOADING,
  ].includes(user?.surveyFiniteState) || !user?.surveyFiniteState;
});

export const _surveyData = createSelector(_user, (user) => {
  const data = (user?.surveys || []).reduce((acc, curr) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {} as any);
  if (!data.profession) {
    return {
      ...data,
      reason    : '',
      frequency : '',
      competitor: '',
      subscriber: '',
    }
  } else if (!data.reason) {
    return {
      ...data,
      frequency : '',
      competitor: '',
      subscriber: '',
    }
  } else if (!data.competitor) {
    return {
      ...data,
      frequency : '',
      subscriber: '',
    }
  } else if (!data.howDidYouFindUs) {
    return {
      ...data,
      frequency : '',
      subscriber: '',
    }
  } else if (!data.subscriber) {
    return {
      ...data,
      frequency: '',
    }
  } else if (!data.frequency) {
    return {
      ...data,
    }
  }

  return data;
});

export const _lastName = createSelector(_user, (user) => {
  return user?.last_name || '';
});

export const _email = createSelector(_user, (user) => {
  return user?.email || '';
});

export const _isVerified = createSelector(_user, (user) => {
  return !!user?.email_verified_at;
});

export const _planTemplateName = createSelector(_user, (user) => {
  return user?.plan_template_name;
});

export const _fullName = createSelector(_firstName, _lastName, (f, l) => {
  return `${f} ${l}`;
});

export const _displayLimitPassed = createSelector(_user, (user) => {
  return user.limit_passed;
});

export const _system = createSelector(_user, (user) => {
  return user?.system;
});

export const _notes = createSelector(_user, (user) => {
  return user?.notes;
});

export const _cdnUrl = createSelector(_system, (system) => {
  return system?.cdn_url;
});

export const _userAcl = createSelector(_user, (user) => {
  return user?.acl;
});

export const _userPlan = createSelector(_user, (user) => {
  return user?.plan;
});

export const _userIsFree = createSelector(_userPlan, (plan) => {
  return plan?.toLowerCase()?.startsWith('free');
});

export const _userRole = createSelector(_user, (user) => {
  return Number(user?.role);
});

export const _isAdmin = createSelector(_userRole, (role) => {
  return Number(role) === 1;
});

export const _showPlanChange = createSelector(_user, _isAdmin, (user, isAdmin) => {
  return !!user?.show_plan_change && !isAdmin;
});

export const _canUpdateApp = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.APPS]?.fg?.[Permissions.UPDATE];
});

export const _canDeleteApp = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.APPS]?.fg?.[Permissions.DELETE];
});

export const _canCreateApp = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.APPS]?.fg?.[Permissions.CREATE];
});

export const _canReadApp = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.APPS]?.fg?.[Permissions.READ];
});

export const _appIsVisible = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.APPS]?.fg?.[Permissions.VISIBLE];
});

export const _hasLocalesAccess = createSelector(_userAcl, (acl) => {
  return !!acl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE];
});

export const _hasWebhooksAccess = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.APPS]?.frs?.webhooks?.[Permissions.VISIBLE];
});

export const _hasTimezonesAccess = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.APPS]?.frs?.['timezone-send']?.[Permissions.VISIBLE];
});

export const _hasForcePromptAccess = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.APPS]?.frs?.['force-prompt']?.[Permissions.VISIBLE];
});

export const _canDeleteSubscriber = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.SUBSCRIBERS]?.fg?.[Permissions.DELETE];
});

export const _canCreateCampaign = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.CAMPAIGNS]?.fg?.[Permissions.CREATE];
});

export const _canCreateSegment = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.SEGMENTS]?.fg?.[Permissions.CREATE];
});

export const _canCreateTemplate = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.TEMPLATES]?.fg?.[Permissions.CREATE];
});

export const _canCreateAutomation = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.AUTOMATIONS]?.fg?.[Permissions.CREATE];
});

export const _canCreateUser = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.USERS]?.fg?.[Permissions.CREATE];
});

export const _canCreateBuilder = createSelector(_userAcl, (acl) => {
  return !!acl?.[aclKeys.BUILDER]?.fg?.[Permissions.CREATE];
});

export const _hasConversionsAccess = createSelector(_userAcl, (acl) => {
  return !!acl?.apps?.[FEATURE]?.conversions?.[ACCESS_TYPES.VISIBLE];
});

/**
 * Limits
 */
export const _userLimits = createSelector(_user, (user) => {
  return user?.limits;
});

export const _subscribersLimits = createSelector(_userLimits, (limits) => {
  return Number(limits?.apps?.max_subscribers_count) || void 0;
});

export const _maxDisplaysCount = createSelector(_userLimits, (limits) => {
  return Number(limits?.apps?.max_displays_count) || 200000;
});

export const _maxAppsCount = createSelector(_userLimits, (limits) => {
  return Number(limits?.apps?.per_account_max_count) || void 0;
});

export const _maxPerItemTagsCount = createSelector(_userLimits, (limits) => {
  return Number(limits?.builder?.per_item_tags_max_count) || void 0;
});

export const _maxApiKeysCount = createSelector(_userLimits, (limits) => {
  return Number(limits?.segments?.per_app_max_count) || void 0;
});

/**
 * Notifications
 */
export const _notifications = createSelector(_user, (user) => {
  return user?.notifications;
});

export const _sbs = createSelector(_notifications, (notifications) => {
  return notifications?.sbs;
});

export const _showActiveGracePeriodWarning = createSelector(_sbs, (sbs) => {
  return !!sbs?.active_grace_message && sbs?.active_grace_period;
});

export const _activeGraceMessage = createSelector(_sbs, (sbs) => {
  return sbs?.active_grace_message;
});

export const _showPassiveGracePeriodWarning = createSelector(_sbs, (sbs) => {
  return !!sbs?.passive_grace_data && sbs?.passive_grace_period;
});

export const _passiveGraceData = createSelector(_sbs, (sbs) => {
  return sbs?.passive_grace_data;
});

export const _passiveGraceDaysLeft = createSelector(_passiveGraceData, (passiveGraceData) => {
  return passiveGraceData?.passive_grace_days_left;
});
