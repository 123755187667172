import {_upsertForm} from './index';
import {_fetch} from '../apps/fetch';
import {Globals} from '../../../types/globals';
import {isMobile} from '../../../utils/isMobile';
import {_appsLength} from '../../selectors/apps';
import {ANIMATION_DELAY} from '../../../constants';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {ActionTypes} from '../../constants/application';
import {RecaptchaActions} from '../../../constants/recaptchaActions';

export const _confirmRegistration = (
  {
    token,
    redirect,
    executeRecaptcha,
  }: {
    token: string;
    redirect: () => void;
    executeRecaptcha: (action?: string) => Promise<string>;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  let recaptchaToken: string | undefined = void 0;

  if (typeof executeRecaptcha === 'function') {
    recaptchaToken = await executeRecaptcha(RecaptchaActions.CONFIRM_REGISTER);
  }

  const {
    body: {
      data,
      status,
      message,
    },
    hasError,
  } = await api.post(ApiRoutes.CONFIRM_REGISTRATION, {
    token,
    'g-recaptcha-response': recaptchaToken || 'token',
  });

  if (hasError) {
    dispatch(_upsertForm({
      status,
      message,
    }))
  } else {
    dispatch(_upsertForm({
      data,
      status,
      message,
    }));
    setTimeout(async function () {
      dispatch({
        payload: {
          authenticated: true,
          accessToken  : data.access_token,
        },
        type   : ActionTypes.AUTHENTICATE,
      });

      await dispatch(_fetch());

      const mobile = isMobile();
      const appsLength = _appsLength(getState());

      if (mobile && !!appsLength) {
        dispatch({
          payload: mobile,
          type   : ActionTypes.IS_MOBILE,
        });
      }
      redirect();
    }, 2 * ANIMATION_DELAY);
  }
};