import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import {ColumnChooser as ColumnChooserBase} from '@devexpress/dx-react-grid';

export function ColumnChooserBaseItem(props: ColumnChooserBase.ItemProps) {
  return (
    <ListItem
      button
      component='li'
      onClick={props.onToggle}
      disabled={props.disabled}
    >
      <Checkbox
        color='primary'
        style={{padding: 0}}
        disabled={props.disabled}
        checked={!props.item.hidden}
      />
      <ListItemText
        style={{paddingLeft: 8}}
        primary={props.item.column.title}
      />
    </ListItem>
  )
}