import {Form} from 'antd';
import React from 'react';
import {Input} from 'antd';
import {Controller} from 'react-hook-form';
import {SubmitButton} from '../SubmitButton';
import {UseFormRegister} from 'react-hook-form';
import {UseFormClearErrors} from 'react-hook-form';
import {Control} from 'react-hook-form/dist/types/form';
import {dataPrefixes} from '../../../../../../constants';
import {IResetPasswordForm} from '../../useResetPassword';
import {Title} from '../../../../../../components/antd/Typography';
import {FormAlert} from '../../../../../../components/antd/FormAlert';

const {Item} = Form;
const {Password} = Input;
const {auth: {resetPassword}} = dataPrefixes;

export function FormContent(
  {
    control,
    onSubmit,
    register,
    clearErrors,
    isSubmitting,
  }: {
    onSubmit: any;
    isSubmitting: boolean;
    control: Control<IResetPasswordForm>;
    register: UseFormRegister<IResetPasswordForm>;
    clearErrors: UseFormClearErrors<IResetPasswordForm>;
  }
) {
  return (
    <form
      noValidate
      className='form'
      autoComplete='off'
      onSubmit={onSubmit}
    >
      <input
        {...(register('token'))}
        type='hidden'
      />
      <div>
        <div
          className='m-b-5'
        >
          <Title
            level={5}
            align='center'
            fontWeight='regular'
          >Enter a new password
          </Title>
        </div>
        <Controller
          name='message'
          control={control}
          render={(
            {
              fieldState: {
                error,
              }
            }
          ) => {
            return (
              <FormAlert
                type='error'
                message={error?.message}
                onClose={() => clearErrors('message')}
              />
            )
          }}
        />
        <Form
          component='div'
          className='form'
          layout='vertical'
        >
          <Controller
            name='email'
            control={control}
            render={(
              {
                field     : {
                  ref,
                  ...fieldRest
                },
                fieldState: {
                  error,
                  invalid,
                }
              }
            ) => {
              return (
                <Item
                  label='Email'
                  help={error?.message}
                  validateStatus={invalid ? 'error' : void 0}
                >
                  <Input
                    disabled
                    ref={ref}
                    autoComplete='off'
                    {...fieldRest}
                  />
                </Item>
              );
            }}
          />
          <Controller
            name='password'
            control={control}
            render={(
              {
                field     : {
                  ref,
                  ...fieldRest
                },
                fieldState: {
                  error,
                  invalid,
                }
              }
            ) => {
              return (
                <Item
                  label='Password'
                  validateStatus={invalid ? 'error' : void 0}
                  help={<span data-qa={`${resetPassword}-form-item-email-explain-error`}>{error?.message}</span> || 'At least 8 characters'}
                >
                  <Password
                    ref={ref}
                    autoFocus
                    autoComplete='current-password'
                    {...fieldRest}
                  />
                </Item>
              );
            }}
          />
          <div
            className='m-t-5'
          >
            <SubmitButton
              control={control}
              loading={isSubmitting}
            />
          </div>
        </Form>
      </div>
    </form>
  )
}
