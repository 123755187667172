import {notification} from 'antd';
import {filterObject} from '../../../utils';
import {Globals} from '../../../types/globals';
import {FEATURE} from '../../../constants/users';
import {_currentApp} from '../../selectors/apps';
import {Templates} from '../../../types/templates';
import {urlHelper} from '../../../utils/urlHelper';
import {ACCESS_TYPES} from '../../../constants/users';
import {ActionTypes} from '../../constants/templates';
import {getTemplate} from '../../selectors/templates';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {_apiBaseUrl} from '../../selectors/application';
import {_userAcl} from '../../selectors/application/acl';
import {LOCALE_TYPE} from '../../../constants/localeType';
import templateTransformer from '../../../utils/templateTransformer';

export const setDataAction = (data: any) => ({
  payload: data,
  type   : ActionTypes.WEB_NOTIFY_TEMPLATES_SET_DATA,
});

export const optionsAction = (options: any) => ({
  payload: options,
  type   : ActionTypes.WEB_NOTIFY_TEMPLATES_TABLE_OPTIONS,
});

export const loadingAction = (loading: boolean) => ({
  payload: loading,
  type   : ActionTypes.WEB_NOTIFY_TEMPLATES_TOGGLE_LOADING,
});

export const dataOptionsAction = (options: any) => ({
  payload: options,
  type   : ActionTypes.WEB_NOTIFY_TEMPLATES_DATA_OPTIONS,
});

export const toggleErrorsAction = (errors?: any) => ({
  payload: errors,
  type   : ActionTypes.WEB_NOTIFY_TEMPLATES_TOGGLE_ERRORS,
});

export const removeErrorAction = (inputName: string) => ({
  payload: inputName,
  type   : ActionTypes.WEB_NOTIFY_TEMPLATES_REMOVE_ERROR,
});

export const updateEntityAction = (payload: any) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_TEMPLATES_UPDATE_ENTITY,
});

export const toggleEntityAction = (payload?: any) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_TEMPLATES_TOGGLE_ENTITY,
});

export const removeLanguageAction = (payload: any) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_TEMPLATES_REMOVE_LANG,
});

export const toggleDialogAction = (payload?: {
  data?: any;
  dialog: string;
}) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_TEMPLATES_TOGGLE_DIALOG,
});

export const updateEntityLangAction = (payload: any) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_TEMPLATES_UPDATE_ENTITY_LANG,
});

export const emptyDataAction = () => ({
  type: ActionTypes.WEB_NOTIFY_TEMPLATES_EMPTY_DATA,
});

export const emptyLanguagesAction = () => ({
  type: ActionTypes.WEB_NOTIFY_TEMPLATES_EMPTY_LANGUAGES,
});

export const getAction = (
  appUuid: string,
  templateId: number | string,
  {
    signal,
    redirect,
    cloneFromCampaign,
  }: {
    signal?: AbortSignal;
    redirect?: () => void;
    cloneFromCampaign?: boolean;
  } = {},
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isGetting: true,
  }));

  const apiBaseUrl = _apiBaseUrl(getState());
  const currentApp = _currentApp(getState());

  let endpoint: string = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_TEMPLATE, {
    appUuid,
    uuid: templateId,
  })}`

  if (cloneFromCampaign) {
    endpoint = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_CAMPAIGN, {
      appUuid,
      uuid: templateId,
    })}`
  }

  const {
    hasError,
    body: {
      name,
      data,
      status,
      message,
    },
  } = await api.get(endpoint, {signal});

  if (hasError) {
    if (name !== 'AbortError') {
      dispatch(toggleDialogAction(void 0));

      if ((status === 404) && typeof redirect === 'function') {
        redirect();
      }

      notification.error({message});
    }
  } else {
    let additionalDataUrl = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_SEGMENT_LANGUAGES, {
      appUuid,
    })}?type_id=9&page=1&pageSize=500`;

    if (data?.data?.locale_type === LOCALE_TYPE.COUNTRY) {
      additionalDataUrl = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_SEGMENT_COUNTRIES, {
        appUuid,
      })}?pageSize=500&page=1`;
    }

    const res = await api.get(additionalDataUrl, {signal});

    if (!res.hasError) {
      const template: any = templateTransformer({
        ...data,
        campaign_id: cloneFromCampaign ? templateId : void 0,
      }, res);
      dispatch(toggleEntityAction({
        ...template,
        messages: {
          ...Object.keys(template.messages || {}).reduce((acc: any, curr: any) => {
            const message = template.messages[curr];
            acc[curr] = {
              ...message,
              icon_url: message.icon_url || currentApp.default_icon_url
            }
            return acc;
          }, {})
        }
      }));
    }
  }

  dispatch(dataOptionsAction({
    isGetting: void 0,
  }));
};

export const createOrUpdateAction = (
  appUuid: string,
  {
    redirect,
    needToFetch = true,
  }: {
    needToFetch?: boolean;
    redirect?: () => void;
  }
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isCreatingOrUpdating: true,
  }));

  const userAcl = _userAcl(getState());
  const template = getTemplate(getState());
  const apiBaseUrl = _apiBaseUrl(getState());

  let newUrl: string = `${apiBaseUrl}${urlHelper(ApiRoutes.CREATE_TEMPLATE, {
    appUuid,
  })}`;

  if (template && template.id) {
    newUrl = `${apiBaseUrl}${urlHelper(ApiRoutes.UPDATE_TEMPLATE, {
      appUuid,
      uuid: template.id,
    })}`;
  }

  const action = Object.values((template?.action) || {}).map((action: any) => {
    const {
      identity,
      ...rest
    } = action;
    return {...rest}
  });

  let newEntity: any = filterObject({
    messages   : template?.messages,
    icon_url   : template?.icon_url,
    image_url  : template?.image_url,
    campaign_id: template?.campaign_id,
    locale_type: template?.locale_type || 'language',
    tags       : (template?.tags || []).map((tag: any) => (tag.value)).join(','),
  });

  if (!userAcl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE]) {
    newEntity = {
      ...newEntity,
      messages: {
        default: {
          ...template?.messages?.default
        }
      },
    }
  }

  if (template && template.uuid) {
    newEntity = {
      ...newEntity,
      uuid: template.uuid,
    }
  }

  if (template && template.id) {
    newEntity = {
      ...newEntity,
      id: template.id,
    }
  }

  if (action.length) {
    newEntity = {
      ...newEntity,
      action,
    }
  }

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.post(newUrl, newEntity);

  dispatch(dataOptionsAction({
    isCreatingOrUpdating: void 0,
  }));

  if (hasError) {
    notification.error({
      message: data?.errors?.limits_notice || data?.errors?.messages || message,
    });

    dispatch(toggleErrorsAction(data.errors));
  } else {
    notification.success({message});

    dispatch(toggleDialogAction(void 0));
    if (typeof redirect === 'function') {
      redirect();
    }

    if (needToFetch) {

    } else {
      dispatch(toggleEntityAction(void 0));
    }
  }
};

export const buildAction = (
  appUuid: string,
): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(dataOptionsAction({
    isCreatingOrUpdating: true,
  }));

  const template = getTemplate(getState());
  const apiBaseUrl = _apiBaseUrl(getState());

  const newUrl: string = `${apiBaseUrl}${urlHelper(ApiRoutes.BUILD_TEMPLATE, {
    appUuid,
  })}`;

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.post(newUrl, {
    title    : template?.messages?.default?.title?.id,
    message  : template?.messages?.default?.message?.id,
    icon_url : template?.messages?.default?.icon_url?.id,
    image_url: template?.messages?.default?.image_url?.id,
  });

  dispatch(dataOptionsAction({
    isCreatingOrUpdating: void 0,
  }));

  if (hasError) {
    notification.error({
      message: data?.errors?.limits_notice || data?.errors?.messages || message,
    });

    dispatch(toggleErrorsAction(data.errors));
  } else {
    notification.success({message});

    dispatch(toggleDialogAction(void 0));
    dispatch(toggleEntityAction(void 0));
  }
};


// /// /// /// /// /// /// /// /

export const _setTags = (data: string[]) => ({
  payload: data,
  type   : ActionTypes.TEMPLATES_SET_TAGS,
});

export const _setData = (data: Templates.Entity[]) => ({
  payload: data,
  type   : ActionTypes.TEMPLATES_SET_DATA,
});

export const _removeEntity = () => ({
  type: ActionTypes.TEMPLATES_REMOVE_ENTITY,
});

export const _setTemplateDetails = (data: Templates.EntityDetail) => ({
  payload: data,
  type   : ActionTypes.TEMPLATES_SET_DETAILS,
});

export const _dataOptions = (data: Partial<Templates.DataOptions>) => ({
  payload: data,
  type   : ActionTypes.TEMPLATES_DATA_OPTIONS,
});

export const _upsertDataItem = (data: Partial<Templates.Entity> & { id: number }) => ({
  payload: data,
  type   : ActionTypes.TEMPLATES_UPSERT_DATA_ITEM,
});

export const _upsertDetailsDataItem = (data: Partial<Templates.EntityDetail> & { uuid: string }) => ({
  payload: data,
  type   : ActionTypes.TEMPLATES_UPSERT_DETAILS_DATA_ITEM,
});

export const _upsertEntity = (data: Partial<Templates.SingleEntity> & { id: number }) => ({
  payload: data,
  type   : ActionTypes.TEMPLATES_UPSERT_ENTITY,
});
