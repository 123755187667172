import clsx from 'clsx';
import React from 'react';
import {Typography} from 'antd';
import {TextProps} from 'antd/lib/typography/Text';
import {LinkProps} from 'antd/lib/typography/Link';
import {TitleProps} from 'antd/lib/typography/Title';
import './index.less';

const {
  Text : AntdText,
  Link : AntdLink,
  Title: AntdTitle,
} = Typography;

export function Title(
  {
    align,
    className,
    fontWeight,
    ...rest
  }: TitleProps & {
    align?: 'center' | 'left' | 'right',
    fontWeight?: 'regular' | 'medium' | 'bold';
  }) {
  return (
    <AntdTitle
      {...rest}
      className={clsx(
        align,
        className,
        fontWeight || 'regular',
      )}
    />
  )
}

export function Link(
  {
    align,
    className,
    fontWeight,
    ...rest
  }: LinkProps & {
    align?: 'center' | 'left' | 'right',
    fontWeight?: 'regular' | 'medium' | 'bold';
  }) {
  return (
    <AntdLink
      {...rest}
      className={clsx(
        align,
        className,
        fontWeight || 'regular',
      )}
    />
  )
}

export function Text(
  {
    align,
    fontSize,
    className,
    fontWeight,
    ...rest
  }: TextProps & {
    fontSize?: 'small' | 'large';
    align?: 'center' | 'left' | 'right',
    fontWeight?: 'regular' | 'medium' | 'bold';
  }) {
  return (
    <AntdText
      {...rest}
      className={clsx(
        align,
        className,
        fontSize,
        fontWeight || 'regular',
      )}
    />
  )
}