import clsx from 'clsx';
import React from 'react';
import {Card} from '../Card';
import {Button} from '../Button';
import {TextField} from '../Input';
import {CardHeader} from '../CardHeader';
import {UploadButton} from '../UploadInput';
import AddIcon from '@material-ui/icons/Add';
import {Theme} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  disableGutters           : {
    padding: 0,
  },
  btnAddAction             : {
    width: 135,
  },
  campaignActionGroup      : {
    marginBottom: theme.spacing(3),
  },
  gutters                  : {
    margin: theme.spacing(0, 1),
  },
  nestedCard               : {
    marginBottom: theme.spacing(1),
  },
  cardHeaderRoot           : {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  cardContentRoot          : {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  btnGroup                 : {
    display   : 'flex',
    alignItems: 'center',
  },
  textWrapper              : {
    '& > div:first-child'         : {
      marginLeft: 0,
    },
    '& > div:last-child'          : {
      marginRight: 0,
    },
    display                       : 'flex',
    marginBottom                  : theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection        : 'column',
      '& > div'            : {
        marginLeft : 0,
        marginRight: 0,
      },
      '& > div:first-child': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  cardHeaderContent        : {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'space-between',
  },
  nestedSubheaderTypography: {
    color: '#9683A1',
  },
  nestedTitleTypography    : {
    fontSize  : 16,
    fontWeight: 'bold',
    color     : '#ffffff',
  },
  nestedCardHeaderRoot     : {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function ActionsCard(
  {
    labels,
    errors,
    entity,
    onChange,
    disabled,
    className,
  }: {
    labels: any;
    entity: any;
    errors?: any;
    onChange: any;
    disabled?: boolean;
    className?: string;
  }
) {
  const classes = useStyles();

  return (
    <Card
      elevation={3}
      className={clsx(classes.nestedCard, className)}
    >
      <CardHeader
        color={disabled ? 'disabled' : 'primary'}
        title={`${labels.actions}${labels.max_limit}`}
      />
      <CardContent
        classes={{root: classes.cardContentRoot}}
      >
        {Object.keys(entity || {}).map((item: string, index: number) => {
          const action = entity[item];
          return action && (
            <Typography
              component='div'
              key={`WelcomeMessageAction_${item}`}
              className={classes.campaignActionGroup}
            >
              <Typography
                component='div'
                className={classes.textWrapper}
              >
                <TextField
                  fullWidth
                  name='title'
                  disabled={disabled}
                  onChange={(e) => {
                    onChange('update', {
                      [e.target.name]: e.target.value,
                      identity       : action.identity,
                    });
                  }}
                  value={action.title || ''}
                  label={labels.action_title}
                  className={classes.gutters}
                  error={!!errors?.[`action.${index}.title`]}
                  placeholder={labels.action_title_placeholder}
                  helperText={(errors?.[`action.${index}.title`] || '')?.replace(`.${index}.`, ' ')}
                />
              </Typography>
              <UploadButton
                fullWidth
                name='icon_url'
                disabled={disabled}
                label={labels.icon_url}
                change={(v: any) => {
                  onChange('update', {
                    icon_url: v,
                    identity: action.identity,
                  });
                }}
                value={action.icon_url || ''}
                placeholder={labels.action_icon_url_placeholder}
                error={!!((errors && errors[`action.${index}.icon_url`]) || (errors && errors[`action.${index}.iconUrl`]))}
                errorMessage={
                  (
                    (errors && errors[`action.${index}.icon_url`]) ||
                    (errors && errors[`action.${index}.iconUrl`]) || ''
                  ).replace(`.${index}.`, ' ')
                }
              />
              <TextField
                fullWidth
                name='url'
                disabled={disabled}
                onChange={(e) => {
                  onChange('update', {
                    [e.target.name]: e.target.value,
                    identity       : action.identity,
                  });
                }}
                value={action.url || ''}
                label={labels.action_url}
                placeholder={labels.action_url_placeholder}
                error={!!(errors && errors[`action.${index}.url`])}
                helperText={((errors && errors[`action.${index}.url`]) || '').replace(`.${index}.`, ' ')}
              />
              <Typography
                component='div'
                className={classes.btnGroup}
              >
                {(Object.keys(entity || {}).length === 1) ? (
                  <Button
                    size='small'
                    color='primary'
                    variant='outlined'
                    disabled={disabled}
                    className={classes.btnAddAction}
                    startIcon={<AddIcon fontSize='small'/>}
                    onClick={() => {
                      onChange('add', {
                        identity: `${Number(action.identity) + 1}`
                      });
                    }}
                  >{labels.add_action}
                  </Button>
                ) : (
                  <Typography/>
                )}
                <Button
                  size='small'
                  color='danger'
                  variant='outlined'
                  disabled={disabled}
                  onClick={() => {
                    onChange('delete', {
                      identity: `${Number(action.identity)}`
                    });
                  }}
                  style={{marginLeft: 4}}
                  className={classes.btnAddAction}
                  startIcon={<DeleteIcon fontSize='small'/>}
                >{labels.delete_action}
                </Button>
              </Typography>
            </Typography>
          )
        })}
        {!Object.keys(entity || {}).length && (
          <Typography
            component='div'
            className={classes.campaignActionGroup}
          >
            <Typography
              component='div'
              className={classes.btnGroup}
            >
              <Button
                size='small'
                color='primary'
                variant='outlined'
                disabled={disabled}
                onClick={() => {
                  onChange('add', {
                    identity: '0'
                  });
                }}
                className={classes.btnAddAction}
                startIcon={<AddIcon fontSize='small'/>}
              >{labels.add_action}
              </Button>
            </Typography>
          </Typography>
        )}
      </CardContent>
      <div/>
    </Card>
  )
}
