import React from 'react';
import {Button} from 'antd';
import {Popconfirm} from 'antd';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {Text} from '../../../Typography';
import {momentTZ} from '../../../../../utils';
import {SPProgress} from '../../../SPProgress';
import {urlHelper} from '../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {_currentApp} from '../../../../../store/selectors/apps';
import {SPProgressDeliveredMessages} from '../../../SPProgress';
import {_currentPlan} from '../../../../../store/selectors/portal';
import {_scheduledPlan} from '../../../../../store/selectors/portal';
import {_sidebarData} from '../../../../../store/selectors/application';
import {_isAdmin} from '../../../../../store/selectors/application/acl';
import {_userIsFree} from '../../../../../store/selectors/application/acl';
import {_maxDisplaysCount} from '../../../../../store/selectors/application/acl';
import {_subscribersLimits} from '../../../../../store/selectors/application/acl';

export function PlanProgress(
  {
    appUuid,
    collapsed,
  }: {
    appUuid: string;
    collapsed: boolean;
  }
) {
  const {push} = useHistory();
  const isAdmin = useSelector(_isAdmin);
  const userIsFree = useSelector(_userIsFree);
  const currentApp = useSelector(_currentApp);
  const sidebarData = useSelector(_sidebarData);
  const currentPlan = useSelector(_currentPlan);
  const scheduledPlan = useSelector(_scheduledPlan);
  const maxDisplaysCount = useSelector(_maxDisplaysCount);
  const subscribersLimits = useSelector(_subscribersLimits);

  function upgradePlan() {
    return push(urlHelper(AppRoutes.CHANGE_PLAN_APP, {appUuid}))
  }

  if (isAdmin) {
    return null;
  }

  return (
    <li
      className='m-t-2 p-5'
      style={{
        transition: 'all 0.3s',
        width     : collapsed ? 0 : 200,
        display   : collapsed ? 'none' : 'block',
      }}
    >
      <SPProgressDeliveredMessages
        total={maxDisplaysCount}
        header='Delivered Messages'
        extra={[sidebarData?.deliveredMessages || 0]}
      />
      {userIsFree && (currentApp?.active_users || currentApp?.active_users === 0) && (
        <SPProgress
          header='Subscribers'
          total={subscribersLimits}
          extra={[currentApp?.active_users]}
        />
      )}
      <Popconfirm
        trigger='hover'
        placement='bottomLeft'
        disabled={!scheduledPlan}
        okButtonProps={{hidden: true}}
        cancelButtonProps={{hidden: true}}
        title={(
          <div
            className='d-flex align-items-center'
          >
            <div>
              <Text fontSize='large'>Plan downgrade from <Text
                strong>{currentPlan?.plan_name}</Text> to <strong>{scheduledPlan?.plan_name}</strong> is scheduled
                on <Text strong>{momentTZ(scheduledPlan?.date)?.format('YYYY-MM-DD hh:mm A')}</Text>.</Text>
              <br/>
              <Text>Your subscription will change when previous plan period is over.</Text>
            </div>
          </div>
        )}
      >
        <Button
          ghost
          block
          type='primary'
          onClick={upgradePlan}
          disabled={!!scheduledPlan}
        >Upgrade plan
        </Button>
      </Popconfirm>
    </li>
  )
}

