import {IObject} from '../types';
import {filterObject} from './index';
import {DEFAULT_TTL} from '../constants';
import {secondsConverter} from './index';

export function campaignFilter(data: IObject = {}) {
  const action: { [key: string]: any }[] = Object.values(data?.action || {}).map((action: any) => {
    const {
      identity,
      ...rest
    } = action;
    return filterObject({...rest})
  });

  let segments: { [key: string]: any } = {
    send_to: 'all',
  };
  if (String(data.send_to).toLowerCase() !== 'all') {
    segments = {
      send_to         : 'particular',
      include_segments: (data.include_segments || []).reduce((acc: any, curr: any) => {
        acc[curr.value] = curr.value;
        return acc;
      }, {}),
      exclude_segments: (data.exclude_segments || []).reduce((acc: any, curr: any) => {
        acc[curr.value] = curr.value;
        return acc;
      }, {}),
      front_data      : {
        exclude_segments: data.exclude_segments || [],
        include_segments: data.include_segments || [],
      },
    }
  }

  const messages: any = data.messages;
  let newData: any = {
    ...segments,
    locale_type: data.locale_type,
    messages   : Object.keys(messages || {}).reduce((acc: any, curr: any) => {
      const {
        url,
        ...restMessage
      } = filterObject(messages[curr]);

      acc[curr] = {
        ...restMessage,
        url: url || '',
      }
      return acc;
    }, {}),
  };

  if (action.length) {
    newData = {
      ...newData,
      action,
    }
  }

  let delivery: any = {
    delivery: 'immediately',
  };
  if (String(data.delivery).toLowerCase() !== 'immediately') {
    delivery = {
      delivery: 'particular',
    };

    if (Number(data.wait_until_value)) {
      delivery = {
        ...delivery,
        wait_until_value: Number(data.wait_until_value),
      }
    }

    if (data?.wait_until_unit?.value) {
      delivery = {
        ...delivery,
        wait_until_unit: data.wait_until_unit.value,
      }
    }
  }

  newData = {
    ...newData,
    ...delivery,
  }

  let per_user_optimization: any = {
    per_user_optimization: 'immediately',
  }

  if (String(data.per_user_optimization).toLowerCase() !== 'immediately') {
    per_user_optimization = {
      per_user_optimization: 'particular',
    }
    if (data.deliver_at) {
      per_user_optimization = {
        ...per_user_optimization,
        deliver_at: data.deliver_at,
      }
    }
  }

  newData = {
    ...newData,
    ...per_user_optimization,
  }

  if (data.uuid) {
    newData = {
      ...newData,
      uuid: data.uuid,
    }
  }

  if (data.template_id) {
    newData = {
      ...newData,
      template_id: data.template_id,
    }
  }

  if (Number(data.ttl_value)) {
    newData = {
      ...newData,
      ttl_value: Number(data.ttl_value),
    }
  }

  if (data?.ttl_unit?.value) {
    newData = {
      ...newData,
      ttl_unit: data.ttl_unit.value,
    }
  }

  return newData;
}

export function campaignTransformer(data: IObject = {}, additionalData: IObject) {
  const {
    automation,
    ...restData
  } = data?.data || {};
  let newData = {
    ...data,
    ...restData,
    ...data.progress,
    ...data.front_data,
    send_to    : (String(restData.send_to).toLowerCase() === 'all') ? 'all' : 'particular',
    messages   : {
      ...restData?.messages,
      default: {
        title    : data?.title,
        url      : restData?.url,
        message  : restData?.message,
        icon_url : restData?.icon_url,
        image_url: restData?.image_url,
        ...restData?.messages?.default
      }
    },
    locale_type: data?.data?.locale_type || 'language',
  };

  newData = {
    ...newData,
    messages: Object.keys(newData?.messages || {}).reduce((acc: any, curr: any) => {
      const message = newData?.messages?.[curr];
      acc[curr] = {
        ...message,
        url: message?.url,
      }
      return acc;
    }, {})
  }

  if (data.availableSegments && data.availableSegments.length) {
    newData = {
      ...newData,
      exclude_segments: (newData.exclude_segments || []).filter((i: any) => {
        return data.availableSegments.find((t: any) => t.value === i.value)
      }),
      include_segments: (newData.include_segments || []).filter((i: any) => {
        return data.availableSegments.find((t: any) => t.value === i.value)
      }),
    }
  }

  let mutatedDataJson: any = {};

  if (newData.ttl_value && newData.ttl_unit) {
    mutatedDataJson = {
      ...mutatedDataJson,
      ttl_unit: {
        value: newData.ttl_unit,
        label: newData.ttl_unit
      }
    }
  } else if (newData.ttl) {
    const newTtl = secondsConverter(newData.ttl);
    mutatedDataJson = {
      ...mutatedDataJson,
      ttl_value: newTtl.value,
      ttl_unit : {
        value: newTtl.unit,
        label: newTtl.unit,
      }
    }
  } else {
    mutatedDataJson = {
      ...mutatedDataJson,
      ttl_unit : DEFAULT_TTL.ttl_unit,
      ttl_value: DEFAULT_TTL.ttl_value,
    }
  }

  if (newData.wait_until_value && newData.wait_until_unit) {
    mutatedDataJson = {
      ...mutatedDataJson,
      wait_until_unit: {
        value: newData.wait_until_unit,
        label: newData.wait_until_unit
      }
    }
  } else if (newData.wait_until && !Number.isNaN(Number(String(newData.wait_until)))) {
    const newWaitUntil = secondsConverter(newData.wait_until);
    mutatedDataJson = {
      ...mutatedDataJson,
      wait_until_value: newWaitUntil.value,
      wait_until_unit : {
        value: newWaitUntil.unit,
        label: newWaitUntil.unit,
      }
    }
  } else if (
    newData?.automation?.delay &&
    !Number.isNaN(Number(String(newData?.automation?.delay)))
  ) {
    const newWaitUntil = secondsConverter(newData.automation.delay);
    mutatedDataJson = {
      ...mutatedDataJson,
      wait_until_value: newWaitUntil.value,
      wait_until_unit : {
        value: newWaitUntil.unit,
        label: newWaitUntil.unit,
      }
    }
  }

  if (newData.action) {
    newData = {
      ...newData,
      action: newData.action.map((i: any, index: any) => {
        return {
          ...i,
          identity: index
        }
      })
    }
  }

  return {
    ...newData,
    ...mutatedDataJson,
    values: Object.keys(data?.data?.messages || {}).map((id: string) => {
      if (Number(id)) {
        const current = (additionalData.body.data?.items || []).find((i: any) => (i.id === Number(id)));
        if (current) {
          return {
            value: current.id,
            label: current.text
          }
        }
      }

      return undefined;
    }).filter(i => !!i),
  }
}
