import produce from 'immer';
import {Segments} from '../../../types/segments';
import {ActionTypes} from '../../constants/segments';
import {FiniteStates} from '../../../constants/finiteStates';
import {ActionTypes as CoreActionTypes} from '../../constants/application';

const InitialState: Segments.Reducer = {
  dataOptions: {
    pageNumber                : 1,
    pageSize                  : 10,
    sorterOrder               : 'descend',
    sorterField               : 'created_at',
    fetchingSegmentState      : FiniteStates.IDLE,
    fetchingSegmentsState     : FiniteStates.IDLE,
    fetchingSegmentParamsState: FiniteStates.IDLE,
  },
};

export const segments = produce((draft, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.SEGMENTS_SET_DATA: {
      draft.entities = payload;

      return void 0;
    }
    case ActionTypes.SEGMENTS_SET_PARAMS: {
      draft.params = payload;

      return void 0;
    }
    case ActionTypes.SEGMENTS_DATA_OPTIONS: {
      if (!draft.dataOptions) {
        draft.dataOptions = {} as any;
      }
      Object.keys(payload || {}).forEach((key: string) => {
        (draft.dataOptions as any)[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.SEGMENTS_UPSERT_ENTITY: {
      if (!draft.entity) {
        draft.entity = {} as any;
      }
      Object.keys(payload || {}).forEach((key: string) => {
        (draft.entity as any)[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.SEGMENTS_REMOVE_ENTITY: {
      draft.entity = void 0;

      return void 0;
    }
    case ActionTypes.SEGMENTS_UPSERT_DATA_ITEM: {
      const index = draft.entities.findIndex((segment) => segment.id === payload.id);
      if (index !== -1) {
        Object.keys(payload).forEach((payloadKey) => {
          (draft.entities[index] as any)[payloadKey] = payload[payloadKey];
        });
      }

      return void 0;
    }
    case CoreActionTypes.LOGOUT: {
      return {...InitialState}
    }
    default:
      return void 0;
  }
}, InitialState);