import stripeJs from '@stripe/stripe-js';
import {Globals} from '../../../types/globals';
import {UseFormSetError} from 'react-hook-form';
import {blankFeatures} from '../../../constants';
import {ApiRoutes} from '../../../constants/apiRoutes';

export const _updateSubscription = (
  {
    card,
    stripe,
    redirect,
    setError,
    formValues,
    paymentMethod,
  }: {
    redirect: () => void;
    stripe: stripeJs.Stripe;
    formValues: Record<string, any>;
    card: stripeJs.StripeCardElement;
    paymentMethod: Record<string, any>;
    setError: UseFormSetError<Record<string, any>>;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  try {
    if ((formValues.card === 'existing_card') && !!paymentMethod) {
      const {
        hasError,
        body: {
          data,
          message
        }
      } = await api.post(ApiRoutes.UPDATE_SUBSCRIPTION, {
        interval      : formValues.interval,
        plan_slug     : formValues.plan_slug,
        payment_method: formValues.payment_method,
      });

      if (!hasError) {
        if (data.need_interaction && data.hosted_invoice_url) {
          window.open(data.hosted_invoice_url, '_blank', blankFeatures);
          redirect();
        } else {
          redirect();
        }
      } else {
        if (Object.keys(data || {}).length) {
          Object.keys(data || {}).forEach((errorKey) => {
            setError(errorKey, {
              message: data[errorKey]
            })
          });
        } else {
          if (message) {
            setError('payment_method', {
              message,
            })
          }
        }
      }
      return void 0;
    } else {
      const {
        body: {
          data: intentData
        }
      } = await api.get(ApiRoutes.SETUP_INTENT);
      const payload = await stripe.confirmCardSetup(intentData?.client_secret, {
        payment_method: {
          card,
          billing_details: {
            name   : formValues.name,
            address: {
              city       : formValues.city?.trim(),
              line1      : formValues.line1?.trim(),
              line2      : formValues.line2?.trim(),
              postal_code: formValues.postal_code?.trim(),
              country    : formValues.country?.value,
              state      : formValues.state?.value || formValues.state?.trim(),
            },
          }
        },
      });

      if (payload.error) {
        setError('cardField', {
          message: payload.error.message,
        });
      } else {
        const {
          hasError,
          body: {
            data,
            message,
          }
        } = await api.post(ApiRoutes.UPDATE_SUBSCRIPTION, {
          interval      : formValues.interval,
          plan_slug     : formValues.plan_slug,
          payment_method: payload.setupIntent.payment_method,
        });
        if (!hasError) {
          if (data.need_interaction && data.hosted_invoice_url) {
            window.open(data.hosted_invoice_url, '_blank', blankFeatures);
            redirect();
          } else {
            redirect();
          }
        } else {
          if (Object.keys(data || {}).length) {
            Object.keys(data || {}).forEach((errorKey) => {
              setError(errorKey, {
                message: data[errorKey]
              })
            });
          } else {
            if (message) {
              setError('payment_method', {
                message,
              });
            }
          }
        }
        return void 0;
      }
    }
  } catch (e) {
    console.error(e);
    setError('payment_method', {
      message: 'Something went wrong please try again later',
    });
  }
};