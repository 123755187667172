import {_fetch} from './fetch';
import {notification} from 'antd';
import {_dataOptions} from './index';
import {ApiKeys} from '../../../types/apiKeys';
import {Globals} from '../../../types/globals';
import {UseFormSetError} from 'react-hook-form';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {Text} from '../../../components/antd/Typography';

export const _createOrUpdate = (
  {
    setError,
    formValues,
  }: {
    formValues: ApiKeys.Entity;
    setError: UseFormSetError<ApiKeys.Entity>;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api
}) => {
  let url = ApiRoutes.CREATE_API_TOKEN;
  if (formValues.id) {
    url = urlHelper(ApiRoutes.UPDATE_API_TOKEN, {id: formValues.id})
  }

  const {
    hasError,
    body: {
      data,
      status,
      message,
    },
  } = await api.post(url, {
    ...formValues,
    enabled: Number(formValues.enabled)
  });

  if (!hasError) {
    if (formValues.id) {
      notification.success({message});
    } else {
      notification.success({
        message,
        duration   : null,
        description: <Description secret={data.secret}/>,
      });
    }

    dispatch(_dataOptions({
      row  : void 0,
      modal: void 0,
    }))

    await dispatch(_fetch());
  } else {
    if (Object.keys(data).length) {
      Object.keys(data).forEach((inputName) => {
        if ([
          'name',
          'enabled',
          'limits_notice',
        ].includes(inputName)) {
          setError(inputName as any, {
            message: data[inputName],
          });
        }
      });
    } else {
      if (status === 403) {
        setError('name', {message: 'You don\'t have access to manage this action'});
      } else {
        setError('name', {message});
      }
    }
  }
};

function Description(
  {
    secret,
  }: {
    secret: string;
  }
) {
  return (
    <div>
      <div>
        <Text
          fontSize='small'
          fontWeight='bold'
        >Make sure you save it - you won't be able to access it again.
        </Text>
      </div>
      <div>
        <Text
          copyable
          ellipsis
          fontSize='small'
        >{secret}
        </Text>
      </div>
    </div>
  )
}
