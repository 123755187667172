import clsx from 'clsx';
import React from 'react';
import {Tab} from '../Tabs';
import {Card} from '../Card';
import {Tabs} from '../Tabs';
import AddNew from './AddNew';
import {IObject} from '../../types';
import {useParams} from 'react-router';
import Box from '@material-ui/core/Box';
import {useSelector} from 'react-redux';
import MessageCard from '../MessageCard';
import {CardHeader} from '../CardHeader';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {FEATURE} from '../../constants/users';
import RemoveLanguage from './RemoveLanguage';
import Hidden from '@material-ui/core/Hidden';
import {Theme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {urlHelper} from '../../utils/urlHelper';
import MenuItem from '@material-ui/core/MenuItem';
import {ACCESS_TYPES} from '../../constants/users';
import ScrollPlayground from '../ScrollPlayground';
import {ApiRoutes} from '../../constants/apiRoutes';
import Typography from '@material-ui/core/Typography';
import {LOCALE_TYPE} from '../../constants/localeType';
import LanguageIcon from '@material-ui/icons/Language';
import CardContent from '@material-ui/core/CardContent';
import ToggleButton from '@material-ui/lab/ToggleButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {_userAcl} from '../../store/selectors/application/acl';
import {_apiBaseUrl} from '../../store/selectors/application';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

function TabPanel(props: any) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      style={{
        padding        : 24,
        marginBottom   : 32,
        backgroundColor: '#ffffff',
        border         : '1px solid #f0f0f0',
      }}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography
            component='div'
          >{children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  header                    : {
    paddingTop: theme.spacing(1),
  },
  subHeader                 : {
    fontSize: 12,
  },
  closeIcon                 : {
    fontSize  : 15,
    marginLeft: theme.spacing(1),
    '&:hover' : {
      borderRadius: '50%',
      border      : '1px solid',
    }
  },
  errorIcon                 : {
    fontSize  : 15,
    marginLeft: theme.spacing(1),
  },
  actionsTypography         : {
    fontSize: 14,
  },
  radioGroup                : {
    display     : 'flex',
    width       : 'max-content',
    marginBottom: theme.spacing(3),
  },
  tabsWrapper               : {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'flex-start',
  },
  actionsContainer          : {
    display       : 'flex',
    justifyContent: 'center',
    padding       : theme.spacing(1, 2),
  },
  removeLanguageContainer   : {
    display       : 'flex',
    width         : '100%',
    alignItems    : 'center',
    justifyContent: 'space-between',
  },
  nestedCard                : {
    marginBottom: theme.spacing(1),
  },
  cardHeaderRoot            : {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  cardContentRoot           : {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  campaignPreviewWrapper    : {
    display                    : 'flex',
    justifyContent             : 'space-between',
    '& > div:first-child'      : {
      marginRight: theme.spacing(.5),
    },
    '& > div:last-child'       : {
      marginLeft: theme.spacing(.5),
    },
    '@media (max-width:1199px)': {
      justifyContent       : 'unset',
      flexDirection        : 'column',
      '& > div:first-child': {
        marginRight: 0,
      },
      '& > div:last-child' : {
        margin: '0 auto',
      },
    }
  },
  cardHeaderContent         : {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'space-between',
  },
  nestedTitleTypography     : {
    fontSize  : 16,
    fontWeight: 'bold',
    color     : '#ffffff',
  },
  toggleButtonGroupContainer: {
    marginBottom: theme.spacing(3),
  },
  label                     : {
    display: 'flex',
    margin : theme.spacing(0, 1, .5, 0),
  },
  selectedToggleButton      : {
    '&.MuiToggleButton-root': {
      color          : '#096dd9',
      backgroundColor: '#ffffff',
      border         : '1px solid #096dd9',
      '&:hover'      : {
        color          : '#096dd9',
        backgroundColor: '#ffffff',
        border         : '1px solid #096dd9',
      },
      '&:disabled'   : {
        boxShadow      : 'none',
        color          : 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
  },
  groupedToggleButtonGroup  : {
    '&:not(:first-child)': {
      borderRadius: 0,
      width       : 100,
    },
    '&:first-child'      : {
      borderRadius: 0,
      width       : 100,
    },
  },
  sizeSmallToggleButton     : {
    padding : 4,
    fontSize: 13
  },
  disabled                  : {
    color: 'rgba(0, 0, 0, 0.26)'
  }
}));

export default function MultiTabs(
  {
    lang,
    tags,
    entity,
    errors,
    actions,
    onClose,
    disabled,
    defaultLang,
    updateEntity,
    emptyLanguages,
    copyLanguageData,
    updateEntityLang,
    messageCardLabels,
  }: {
    tags?: any;
    onClose: any;
    actions: any;
    lang?: IObject;
    entity?: IObject;
    errors?: IObject;
    updateEntity: any;
    disabled?: boolean;
    emptyLanguages: any;
    defaultLang?: IObject;
    updateEntityLang: any;
    messageCardLabels: IObject;
    copyLanguageData: (v: string) => void;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();
  const userAcl = useSelector(_userAcl);
  const apiBaseUrl = useSelector(_apiBaseUrl);
  const [value, setValue] = React.useState<number | null>(0);
  const [values, setValues] = React.useState<{ value: number; label: string }[]>([]);
  const [pendingValue, setPendingValue] = React.useState<{ [key: string]: any } | null>(null);

  const anchorRef = React.useRef<any>(null);
  const [open, setOpen] = React.useState(false);
  const handleToggle = React.useCallback(() => {
    setOpen(prevOpen => {
      return !prevOpen
    });
  }, []);
  const handleClose = React.useCallback((event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }, []);

  const {
    appUuid,
  } = useParams<{ appUuid: string, uuid?: string }>();

  React.useEffect(() => {
    if (entity?.values) {
      setValues(entity.values)
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    entity?.values,
  ]);

  const endpoint = React.useMemo(() => {
    if (entity?.locale_type === 'country') {
      return `${apiBaseUrl}${urlHelper(ApiRoutes.GET_SEGMENT_COUNTRIES, {appUuid})}?pageSize=1000`
    }
    return `${apiBaseUrl}${urlHelper(ApiRoutes.GET_SEGMENT_LANGUAGES, {appUuid})}?page=1&pageSize=1000`
    // eslint-disable-next-line
  }, [
    appUuid,
    apiBaseUrl,
    // eslint-disable-next-line
    entity?.locale_type,
  ]);

  const defaultErrors = React.useMemo(() => {
    if (
      errors?.icon_url ||
      errors?.image_url ||
      (errors || {})['messages.default.url'] ||
      (errors || {})['messages.default.title'] ||
      (errors || {})['messages.default.message']
    ) {
      return {
        icon_url : errors?.icon_url,
        image_url: errors?.image_url,
        url      : (errors || {})['messages.default.url'],
        title    : (errors || {})['messages.default.title'],
        message  : (errors || {})['messages.default.message'],
      }
    }
    return void 0;
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    errors?.url,
    // eslint-disable-next-line
    errors?.icon_url,
    // eslint-disable-next-line
    errors?.image_url,
    // eslint-disable-next-line
    (errors || {})['messages.default.title'],
    // eslint-disable-next-line
    (errors || {})['messages.default.message'],
  ]);

  const currentLang = React.useCallback((lg: number) => {
    return {
      ...(lang && lang[lg]),
    }
  }, [
    lang,
  ]);

  const currentErrors = React.useCallback((lg: number) => {
    if (
      (errors || {})[`messages.${lg}.url`] ||
      (errors || {})[`messages.${lg}.title`] ||
      (errors || {})[`messages.${lg}.message`]
    ) {
      return {
        url    : (errors || {})[`messages.${lg}.url`],
        title  : (errors || {})[`messages.${lg}.title`],
        message: (errors || {})[`messages.${lg}.message`],
      }
    }
    return void 0;
    // eslint-disable-next-line
  }, [
    errors,
  ]);

  const handleOnRemove = React.useCallback((removeValue) => {
    if (removeValue && removeValue.value) {
      setValues(prevState => {
        if (value === removeValue.value) {
          const index = prevState.findIndex((v) => (v.value === removeValue.value));
          if (prevState[index - 1]) {
            setValue(prevState[index - 1].value)
          } else {
            setValue(0);
          }
        }

        return [
          ...(prevState || []).filter((i) => i.value !== removeValue.value)
        ]
      });

      if (typeof onClose === 'function') {
        onClose(removeValue.value);
      }
    }
  }, [
    value,
    onClose,
  ]);

  const handleLocaleTypeChange = React.useCallback((event, value) => {
    if (value !== null) {
      if (!values.length) {
        setValue(0);
        setValues([]);
        updateEntity('locale_type', value);
      } else {
        handleToggle();
        setPendingValue({
          value,
          key: 'locale_type',
        })
      }
    }
  }, [
    handleToggle,
    updateEntity,
    values.length,
  ]);

  return (
    <Card
      elevation={3}
      className={classes.nestedCard}
    >
      <CardHeader
        color={disabled ? 'disabled' : 'primary'}
        title={messageCardLabels.push_notification_message}
      />
      <CardContent
        classes={{root: classes.cardContentRoot}}
      >
        {!!userAcl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE] && (
          <Typography
            component='div'
            className={classes.toggleButtonGroupContainer}
          >
            <Typography
              component='span'
              color='primary'
              className={clsx(classes.label, disabled && classes.disabled)}
            >{t('app_settings.send_by')}
            </Typography>
            <Typography
              component='div'
            >
              <ToggleButtonGroup
                exclusive
                size='small'
                ref={anchorRef}
                style={{paddingRight: 6}}
                onChange={handleLocaleTypeChange}
                value={entity?.locale_type || LOCALE_TYPE.LANGUAGE}
                classes={{
                  grouped: classes.groupedToggleButtonGroup,
                }}
              >
                <ToggleButton
                  size='small'
                  disabled={disabled}
                  value={LOCALE_TYPE.LANGUAGE}
                  data-id='locale-language-button'
                  classes={{
                    selected : classes.selectedToggleButton,
                    sizeSmall: classes.sizeSmallToggleButton,
                  }}
                >{t('language')}
                </ToggleButton>
                <ToggleButton
                  disabled={disabled}
                  value={LOCALE_TYPE.COUNTRY}
                  data-id='locale-country-button'
                  classes={{
                    selected : classes.selectedToggleButton,
                    sizeSmall: classes.sizeSmallToggleButton,
                  }}
                >{t('country')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Typography>
          </Typography>
        )}
        <Typography
          component='div'
        >
          <Typography
            component='div'
            className={classes.tabsWrapper}
          >
            <Tab
              value={0}
              disableRipple
              disabled={disabled}
              selected={value === 0}
              data-id='language-country-tab-1'
              label={
                <Typography
                  component='div'
                  className={`tab-part ${classes.removeLanguageContainer}`}
                >
                  <Hidden
                    smUp
                  >
                    <LanguageIcon
                      fontSize='small'
                    />
                  </Hidden>
                  <Hidden
                    xsDown
                  >{t('default')}
                  </Hidden>
                  {!!defaultErrors && (value !== 0) && (
                    <Tooltip
                      title={
                        <>
                          {Object.values(defaultErrors).map((i, index) => (
                            <Typography
                              key={index}
                            >{i}
                            </Typography>
                          ))}
                        </>
                      }
                    >
                      <ErrorOutlineIcon
                        color='error'
                        className={classes.errorIcon}
                      />
                    </Tooltip>
                  )}
                </Typography>
              }
              onClick={() => setValue(0)}
              style={{marginBottom: -1, minWidth: 'unset'}}
            />
            <Tabs
              value={value}
              scrollButtons='auto'
              variant='scrollable'
            >
              <Tab
                value={0}
                disableRipple
                style={{visibility: 'hidden', position: 'absolute', top: -99999}}
              />
              {!!userAcl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE] && (values || []).map((item, index) => !!item && (
                <Tab
                  disableRipple
                  value={item.value}
                  disabled={disabled}
                  key={`Tab_${item.value}`}
                  onClick={(event: any) => {
                    if (!!([...event?.target?.classList]).find((className: string) => {
                      return [
                        'tab-part',
                        'label-const',
                        'MuiTab-root',
                        'MuiTab-wrapper',
                      ].includes(className)
                    })) {
                      setValue(item.value)
                    }
                  }}
                  data-id={`language-country-tab-${index + 2}`}
                  label={
                    <Typography
                      noWrap
                      component='div'
                      className={`tab-part ${classes.removeLanguageContainer}`}
                    >
                      <Typography
                        noWrap
                        className='label-const'
                      >{item.label}
                      </Typography>
                      <RemoveLanguage
                        value={item}
                        index={index + 2}
                        onRemove={handleOnRemove}
                        errors={currentErrors(Number(item.value))}
                        selected={Number(item.value) === Number(value)}
                      />
                    </Typography>
                  }
                />
              ))}
            </Tabs>
            {!!userAcl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE] && (
              <AddNew
                url={endpoint}
                values={values}
                disabled={disabled}
                onChange={(v: any) => {
                  setValues(v);
                  const newLang = v.slice(-1).pop()
                  if (newLang?.value) {
                    copyLanguageData(newLang.value);
                  }
                }}
              />
            )}
          </Typography>
          <TabPanel
            index={0}
            value={value}
          >
            <MessageCard
              index={1}
              tags={tags}
              errors={{
                url      : (errors || {})['messages.default.url'],
                title    : (errors || {})['messages.default.title'],
                message  : (errors || {})['messages.default.message'],
                icon_url : (errors || {})['messages.default.icon_url'],
                image_url: (errors || {})['messages.default.image_url'],
              }}
              actions={actions}
              disabled={disabled}
              entity={defaultLang}
              labels={messageCardLabels}
              className={classes.nestedCard}
              updateEntityLang={(inputName: string, value: any) => {
                updateEntityLang('default', inputName, value);
              }}
            />
          </TabPanel>
          {!!userAcl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE] && (values || []).map((item, index) => !!item && (
            <TabPanel
              value={value}
              index={item.value}
              key={`TabPanel_${item.value}`}
            >
              <MessageCard
                tags={tags}
                errors={{
                  url      : (errors || {})[`messages.${item.value}.url`],
                  title    : (errors || {})[`messages.${item.value}.title`],
                  message  : (errors || {})[`messages.${item.value}.message`],
                  icon_url : (errors || {})[`messages.${item.value}.icon_url`],
                  image_url: (errors || {})[`messages.${item.value}.image_url`],
                }}
                index={index + 2}
                actions={actions}
                disabled={disabled}
                labels={messageCardLabels}
                className={classes.nestedCard}
                entity={currentLang(item.value)}
                updateEntityLang={(inputName: string, value: any) => {
                  updateEntityLang(item.value, inputName, value);
                }}
              />
            </TabPanel>
          ))}
        </Typography>
        <ClickAwayListener
          onClickAway={handleClose}
        >
          <div>
            <ScrollPlayground
              open={open}
              transition={true}
              anchorEl={anchorRef.current}
            >
              <Paper
                elevation={3}
                style={{paddingLeft: 16, paddingRight: 16, paddingTop: 8}}
              >
                <Typography
                  noWrap
                  align='center'
                  color='primary'
                  className={classes.header}
                >{t('messages.change_language_country_mode')}
                </Typography>
                <Typography
                  noWrap
                  align='center'
                  color='primary'
                  className={classes.subHeader}
                >{t('messages.you_may_lose_your_data')}
                </Typography>
                <Typography
                  component='div'
                  align='center'
                  className={classes.actionsContainer}
                >
                  <MenuItem
                    onClick={handleClose}
                  >
                    <Typography
                      noWrap
                      color='primary'
                      variant='inherit'
                      data-id='change-locale-close-button'
                      className={classes.actionsTypography}
                    >{t('buttons.cancel')}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      setValue(0);
                      setValues([]);
                      updateEntity([pendingValue.key], pendingValue.value);
                      handleClose(e);
                      emptyLanguages();
                    }}
                  >
                    <Typography
                      noWrap
                      color='error'
                      variant='inherit'
                      className={classes.actionsTypography}
                      data-id='change-locale-confirm-button'
                    >{t('buttons.go_ahead')}
                    </Typography>
                  </MenuItem>
                </Typography>
              </Paper>
            </ScrollPlayground>
          </div>
        </ClickAwayListener>
      </CardContent>
      <div/>
    </Card>
  )
}
