import clsx from 'clsx';
import React from 'react';
import {Card} from '../Card';
import {Button} from '../Button';
import {TextField} from '../Input';
import {CardHeader} from '../CardHeader';
import {UploadButton} from '../UploadInput';
import AddIcon from '@material-ui/icons/Add';
import {Theme} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  disableGutters           : {
    padding: 0,
  },
  campaignActionGroup      : {
    marginBottom: theme.spacing(3),
  },
  gutters                  : {
    margin: theme.spacing(0, 1),
  },
  btnAddAction             : {
    width: 135,
  },
  nestedCard               : {
    marginBottom: theme.spacing(4),
  },
  cardHeaderRoot           : {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  cardContentRoot          : {
    padding                       : theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft : theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  btnGroup                 : {
    display   : 'flex',
    alignItems: 'center',
  },
  btnDeleteAction          : {
    display      : 'flex',
    alignItems   : 'center',
    flexDirection: 'column',
    '& span'     : {
      fontSize: 12,
    },
  },
  textWrapper              : {
    '& > div:first-child'         : {
      marginLeft: 0,
    },
    '& > div:last-child'          : {
      marginRight: 0,
    },
    display                       : 'flex',
    marginBottom                  : theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection        : 'column',
      '& > div'            : {
        marginLeft : 0,
        marginRight: 0,
      },
      '& > div:first-child': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  cardHeaderContent        : {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'space-between',
  },
  nestedSubheaderTypography: {
    color: '#9683A1',
  },
  nestedTitleTypography    : {
    fontSize  : 16,
    fontWeight: 'bold',
    color     : '#ffffff',
  },
  nestedCardHeaderRoot     : {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default React.memo((
  {
    title,
    labels,
    errors,
    entity,
    bgColor,
    onChange,
    className,
  }: {
    entity?: any;
    errors?: any;
    labels?: any;
    onChange: any;
    bgColor?: any;
    title?: string;
    loading?: boolean;
    className?: string;
  }
) => {
  const classes = useStyles();

  const actionsLength = React.useMemo(() => {
    return Object.keys(entity || {}).length;
  }, [
    entity
  ]);

  return (
    <Card
      elevation={3}
      className={clsx(classes.nestedCard, className)}
    >
      <CardHeader
        color='primary'
        title={title ? title : labels.actions_max_limit}
        style={bgColor ? {backgroundColor: bgColor} : undefined}
      />
      <CardContent
        classes={{root: classes.cardContentRoot}}
      >
        {Object.keys(entity || {}).map((item: string, index: number) => {
          const action = entity[item];
          return action && (
            <Typography
              component='div'
              key={`WelcomeMessageAction_${item}`}
              className={classes.campaignActionGroup}
              data-id={`action-group-${index + 1}-div`}
            >
              <Typography
                component='div'
                className={classes.textWrapper}
              >
                <TextField
                  fullWidth
                  name='title'
                  value={action.title || ''}
                  className={classes.gutters}
                  label={labels.action_title}
                  placeholder={labels.action_title_placeholder}
                  error={!!(errors && errors[`action.${index}.title`])}
                  helperText={((errors && errors[`action.${index}.title`]) || '').replace(`.${index}.`, ' ')}
                  onChange={(e) => onChange('update', {
                    [e.target.name]: e.target.value,
                    identity       : action.identity,
                  }, e.target.name)}
                />
              </Typography>
              <UploadButton
                fullWidth
                name='icon_url'
                label={labels.icon_url}
                value={action.icon_url || ''}
                placeholder={labels.action_icon_url_placeholder}
                inputProps={{'data-id': `icon_url-${index + 1}-field`}}
                error={!!(errors && errors[`action.${index}.icon_url`])}
                errorMessage={((errors && errors[`action.${index}.icon_url`]) || '').replace(`.${index}.`, ' ')}
                change={(v: any) => onChange('update', {
                  icon_url: v,
                  identity: action.identity,
                }, 'icon_url')}
              />
              <TextField
                fullWidth
                name='url'
                value={action.url || ''}
                label={labels.action_url}
                placeholder={labels.action_url_placeholder}
                error={!!(errors && errors[`action.${index}.url`])}
                inputProps={{'data-id': `action_url-${index + 1}-field`}}
                helperText={((errors && errors[`action.${index}.url`]) || '').replace(`.${index}.`, ' ')}
                onChange={(e) => onChange('update', {
                  [e.target.name]: e.target.value,
                  identity       : action.identity,
                }, e.target.name)}
              />
              <Typography
                component='div'
                className={classes.btnGroup}
              >
                {(actionsLength === 1) ? (
                  <Button
                    size='small'
                    color='primary'
                    variant='outlined'
                    className={classes.btnAddAction}
                    startIcon={<AddIcon fontSize='small'/>}
                    onClick={() => onChange('add', {
                      identity: `${Number(action.identity) + 1}`
                    })}
                  >{labels.add_action}
                  </Button>
                ) : (
                  <Typography/>
                )}
                <Button
                  size='small'
                  color='danger'
                  variant='outlined'
                  style={{marginLeft: 4}}
                  className={classes.btnAddAction}
                  startIcon={<DeleteIcon fontSize='small'/>}
                  onClick={() => onChange('delete', {
                    identity: `${Number(action.identity)}`
                  })}
                >{labels.delete_action}
                </Button>
              </Typography>
            </Typography>
          )
        })}
        {!actionsLength && (
          <Typography
            component='div'
            className={classes.campaignActionGroup}
          >
            <Typography
              component='div'
              className={classes.btnGroup}
            >
              <Button
                size='small'
                color='primary'
                variant='outlined'
                className={classes.btnAddAction}
                startIcon={<AddIcon fontSize='small'/>}
                onClick={() => onChange('add', {identity: '0'})}
              >{labels.add_action}
              </Button>
            </Typography>
          </Typography>
        )}
      </CardContent>
      <div/>
    </Card>
  )
})
