import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  mainTypography     : {
    fontSize  : 20,
    lineHeight: 1.3,
    fontWeight: 700,
    color     : '#000000',
  },
  secondaryTypography: {
    fontSize  : 16,
    fontWeight: 400,
    lineHeight: 1.375,
    color     : '#000000',
  },
}));

export function Info() {
  const classes = useStyles();

  return (
    <Box
      width={280}
      marginTop={7}
      marginRight={8}
    >
      <Box
        marginBottom={3.5}
      >
        <Box
          marginBottom={.5}
        >
          <Typography
            className={classes.mainTypography}
          >Pricing Based on Created Value
          </Typography>
        </Box>
        <Typography
          className={classes.secondaryTypography}
        >Pay for actually delivered and displayed push messages, not for subscribers.
        </Typography>
      </Box>
      <Box
        marginBottom={3.5}
      >
        <Box
          marginBottom={.5}
        >
          <Typography
            className={classes.mainTypography}
          >Retain Visitors with Push Messaging
          </Typography>
        </Box>
        <Typography
          className={classes.secondaryTypography}
        >Strong messages and simple ping will help drive visitors back to your website and encourage them to take the desired action.
        </Typography>
      </Box>
      <Box>
        <Box
          marginBottom={.5}
        >
          <Typography
            className={classes.mainTypography}
          >Boost Customer Retention to Increase the Profit
          </Typography>
        </Box>
        <Typography
          className={classes.secondaryTypography}
        >Send targeted messages to loyal customers and grow your profit by 15-95%. The more visitors you gain, the higher is your chance that they will convert.
        </Typography>
      </Box>
    </Box>
  )
}