import React from 'react';
import {useDispatch} from 'react-redux';
import {ButtonProps} from 'antd/lib/button/button';
import {PopconfirmProps} from 'antd/lib/popconfirm';
import {Templates} from '../../../../../../types/templates';
import {aclKeys} from '../../../../../../constants/aclKeys';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import {Text} from '../../../../../../components/antd/Typography';
import {useSmartPush} from '../../../../../../hooks/useSmartPush';
import {FiniteStates} from '../../../../../../constants/finiteStates';
import {TableAction} from '../../../../../../components/antd/TableAction';
import {_destroy} from '../../../../../../store/actions/templates/destroy';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';

export function DeleteAction(
  {
    row,
  }: {
    row: Templates.Entity;
  }
) {
  const {
    appUuid,
    user: {
      canUpdate,
      canDelete,
    }
  } = useSmartPush();
  const dispatch = useDispatch();
  const buttonProps: ButtonProps = {
    danger  : true,
    size    : 'small',
    type    : 'default',
    shape   : 'circle',
    icon    : <DeleteOutlined/>,
    disabled: !canDelete(aclKeys.SEGMENTS),
    loading : row.deleting === FiniteStates.LOADING,
  }
  const popconfirmProps: PopconfirmProps = canDelete(aclKeys.SEGMENTS) ? {
    placement : 'topRight',
    cancelText: 'Keep Template',
    okText    : 'Delete Template',
    title     : <PopconfirmTitle name={row.title}/>,
    onConfirm : () => dispatch(_destroy({appUuid, id: row.id})),
    icon      : <Text type='danger' className='anticon'><QuestionCircleOutlined/></Text>
  } : void 0;
  const tooltipTitle = canUpdate(aclKeys.SEGMENTS) ? 'Delete' : 'You don\'t have access to manage this action';

  return (
    <TableAction
      buttonProps={buttonProps}
      popconfirmProps={popconfirmProps}
      tooltipProps={{title: tooltipTitle}}
    />
  )
}

function PopconfirmTitle(
  {
    name,
  }: {
    name: string;
  }
) {
  return (
    <>
      <div>
        <Text
          align='left'
        >Are you sure you want to delete <Text style={{maxWidth: '20rem'}} ellipsis>'{name}'</Text>?
        </Text>
      </div>
      <Text
        align='left'
        type='secondary'
        fontSize='small'
        fontWeight='regular'
      >Once you are done deleting, you cannot undo this action.
      </Text>
    </>
  )
}
