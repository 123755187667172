import React from 'react';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import {Theme} from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import arrowUp from '../../images/preview/webAndroid/arrowUp.svg';
import arrowDown from '../../images/preview/webAndroid/arrowDown.svg';
import arrowRight from '../../images/preview/webWindows/arrowRight.svg';
import webMacOSBackground from '../../images/preview/webMacOS/background.svg';
import webWindowsBackground from '../../images/preview/webWindows/background.svg';
import webAndroidBackground from '../../images/preview/webAndroid/background.svg';

const useStyles = makeStyles(() => ({
  root             : {
    '&.Mui-selected': {
      color: '#096dd9'
    },
    '&:disabled'    : {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  indicator        : {
    backgroundColor: '#096dd9',
  },
  indicatorDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
  },
}));

export const CampaignPreview: React.FC<{
  icon?: string;
  image?: string;
  title?: string;
  message?: string;
  disabled?: boolean;
  actions?: string[];
  style?: React.CSSProperties
}> = (
  {
    icon,
    image,
    title,
    style,
    actions,
    message,
    disabled,
  }
) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <Typography
      component='div'
      style={{
        ...style
      }}
    >
      <TabPanel
        index={0}
        value={value}
        disabled={disabled}
      >
        <AndroidWeb
          icon={icon}
          title={title}
          image={image}
          message={message}
        />
      </TabPanel>
      <TabPanel
        index={1}
        value={value}
        disabled={disabled}
      >
        <WindowsWeb
          icon={icon}
          image={image}
          title={title}
          message={message}
          actions={actions}
        />
      </TabPanel>
      <TabPanel
        index={2}
        value={value}
        disabled={disabled}
      >
        <MacOsWeb
          icon={icon}
          title={title}
          message={message}
        />
      </TabPanel>
      <Tabs
        value={value}
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        indicatorColor='secondary'
        classes={{
          indicator: !!disabled ? classes.indicatorDisabled : classes.indicator,
        }}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
      >
        <Tab
          value={0}
          disabled={disabled}
          label='WEB: ANDROID'
          data-id='web-android'
          classes={{root: classes.root}}
        />
        <Tab
          value={1}
          disabled={disabled}
          label='WEB: WINDOWS'
          data-id='web-windows'
          classes={{root: classes.root}}
        />
        <Tab
          value={2}
          label='WEB: MACOS'
          data-id='web-macos'
          disabled={disabled}
          classes={{root: classes.root}}
        />
      </Tabs>
    </Typography>
  )
};

const ChromeIcon = (
  {
    size,
  }: any
) => (
  <svg viewBox='0 0 48 48' style={{height: size, width: size}}>
    <circle cx='24' cy='24' r='19' fill='#4CAF50'/>
    <path fill='#FFC107' d='M24 5v19l8 4-8.8 15h.8c10.5 0 19-8.5 19-19S34.5 5 24 5z'/>
    <path fill='#F44336' d='M16 28l8-13h16.7C37.5 9 31.2 5 24 5c-6.7 0-12.6 3.5-16 8.8L16 28z'/>
    <path fill='#DD2C00' d='M8 13.8L16 28l5-1'/>
    <path fill='#F9A825' d='M40.6 15H24l-1.5 4.8'/>
    <path fill='#558B2F' d='M23.2 43L32 28l-3.8-3.1'/>
    <circle cx='24' cy='24' r='9' fill='#FFF'/>
    <circle cx='24' cy='24' r='7' fill='#2196F3'/>
  </svg>
);

const ChromeIconSmall = (
  {
    size,
  }: any
) => (
  <svg
    viewBox='0 0 1024 1024'
    style={{
      width : size,
      height: size,
      color : 'rgb(117, 117, 117)',
    }}
  >
    <path
      stroke='inherit'
      d='M258.278 446.542l-146.532-253.802c93.818-117.464 238.234-192.74 400.254-192.74 187.432 0 351.31 100.736 440.532 251h-417.77c-7.504-0.65-15.092-1-22.762-1-121.874 0-224.578 83.644-253.722 196.542zM695.306 325h293.46c22.74 57.93 35.234 121.004 35.234 187 0 280.826-226.1 508.804-506.186 511.926l209.394-362.678c29.48-42.378 46.792-93.826 46.792-149.248 0-73.17-30.164-139.42-78.694-187zM326 512c0-102.56 83.44-186 186-186s186 83.44 186 186c0 102.56-83.44 186-186 186s-186-83.44-186-186zM582.182 764.442l-146.578 253.878c-246.532-36.884-435.604-249.516-435.604-506.32 0-91.218 23.884-176.846 65.696-251.024l209.030 362.054c41.868 89.112 132.476 150.97 237.274 150.97 24.3 0 47.836-3.34 70.182-9.558z'
    />
  </svg>
);

function TabPanel(props: any) {
  const {
    value,
    index,
    children,
    ...other
  } = props;
  return (
    <Typography
      component='div'
      hidden={value !== index}
      {...other}
    >
      <Box
      >{children}
      </Box>
    </Typography>
  );
}

const useMacOsWebStyles = makeStyles((theme: Theme) => ({
  webMacOS            : {
    borderRadius      : 7,
    borderWidth       : 5,
    height            : 580,
    width             : 480,
    display           : 'block',
    backgroundSize    : 'cover',
    borderStyle       : 'solid',
    borderImage       : 'initial',
    margin            : '0 auto',
    backgroundRepeat  : 'no-repeat',
    backgroundPosition: '100% 100%',
    borderColor       : 'rgb(228, 228, 228)',
    backgroundImage   : `url('${webMacOSBackground}')`,
  },
  ntfContainer        : {
    display        : 'flex',
    maxWidth       : '100%',
    alignItems     : 'center',
    justifyContent : 'space-between',
    backgroundColor: 'rgb(240, 240, 240)',
    borderRadius   : theme.spacing(1),
    margin         : theme.spacing(9, 2),
    boxShadow      : 'rgba(0, 0, 0, 0.14) 0px 3px 13px 1px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
  },
  leftSectionContainer: {
    display       : 'flex',
    alignItems    : 'center',
    overflow      : 'hidden',
    whiteSpace    : 'nowrap',
    textOverflow  : 'ellipsis',
    justifyContent: 'flex-start',
    padding       : theme.spacing(1.5),
  },
  browserIconContainer: {
    display     : 'flex',
    paddingRight: theme.spacing(1),
  },
  iconContainer       : {
    display: 'flex',
    padding: theme.spacing(1.5),
  },
  messageTypography   : {
    fontWeight: 400,
  },
  domainNameTypography: {
    fontWeight: 500,
  },
  titleTypography     : {
    fontWeight: 500,
  },
}));
const MacOsWeb: React.FC<{
  icon?: string;
  title?: string;
  message?: string;
}> = (
  {
    icon,
    title,
    message,
  }
) => {
  const classes = useMacOsWebStyles();

  return (
    <Typography
      component='div'
      data-id='mac-os-web'
      className={classes.webMacOS}
    >
      <Typography
        component='div'
        className={classes.ntfContainer}
      >
        <Typography
          component='div'
          className={classes.leftSectionContainer}
        >
          <Typography
            component='div'
            className={classes.browserIconContainer}
          >
            <ChromeIcon
              size={54}
            />
          </Typography>
          <Typography
            noWrap
            component='div'
          >
            <Typography
              noWrap
              className={classes.titleTypography}
            >{title && title}
            </Typography>
            <Typography
              noWrap
              className={classes.domainNameTypography}
            >example.com
            </Typography>
            <Typography
              noWrap
              className={classes.messageTypography}
            >{message && message}
            </Typography>
          </Typography>
        </Typography>
        {icon && (
          <Typography
            component='div'
            className={classes.iconContainer}
          >
            <img
              alt='alt'
              src={icon}
              style={{width: 75, height: 75}}
            />
          </Typography>
        )}
      </Typography>
    </Typography>
  )
};

const useWindowsWebStyles = makeStyles((theme: Theme) => ({
  webWindows         : {
    borderRadius      : 7,
    borderWidth       : 5,
    height            : 580,
    width             : 480,
    display           : 'flex',
    backgroundSize    : 'cover',
    borderStyle       : 'solid',
    borderImage       : 'initial',
    margin            : '0 auto',
    position          : 'relative',
    alignItems        : 'flex-end',
    justifyContent    : 'flex-end',
    backgroundRepeat  : 'no-repeat',
    backgroundPosition: '100% 100%',
    borderColor       : 'rgb(228, 228, 228)',
    backgroundImage   : `url('${webWindowsBackground}')`,
  },
  ntfContainer       : {
    bottom         : 73,
    marginRight    : 15,
    marginBottom   : 80,
    paddingBottom  : 20,
    maxWidth       : 350,
    backgroundColor: 'rgb(32, 32, 32)',
    right          : theme.spacing(1),
  },
  imgContainer       : {
    display  : 'flex',
    '& > img': {
      maxHeight: 300,
      width    : '100%',
    },
  },
  middleSideContainer: {
    display : 'flex',
    position: 'relative',
  },
  textContainer      : {
    paddingTop   : 20,
    paddingLeft  : 10,
    paddingRight : 35,
    display      : 'flex',
    flexDirection: 'column',
    overflow     : 'hidden',
    whiteSpace   : 'nowrap',
    textOverflow : 'ellipsis',
  },
  iconContainer      : {
    paddingTop   : 20,
    paddingLeft  : 20,
    paddingRight : 10,
    display      : 'flex',
    flexDirection: 'column',
  },
  titleTypography    : {
    fontSize: 17,
    color   : '#ffffff',
  },
  messageTypography  : {
    fontSize: 17,
    color   : 'rgb(167, 167, 167)',
  },
  domainTypography   : {
    fontSize: 13,
    color   : 'rgb(167, 167, 167)',
  },
  arrowRightIcon     : {
    top     : 15,
    width   : 23,
    right   : 12,
    height  : 24,
    position: 'absolute',
  },
  actionsContainer   : {
    display       : 'flex',
    justifyContent: 'flex-end',
    padding       : theme.spacing(1, 2, 0),
  },
  actions            : {
    margin         : 3,
    borderWidth    : 2,
    fontSize       : 18,
    maxWidth       : 160,
    width          : '50%',
    borderStyle    : 'solid',
    textAlign      : 'center',
    cursor         : 'pointer',
    padding        : '5px 0px',
    borderColor    : 'rgb(67, 67, 67)',
    backgroundColor: 'rgb(67, 67, 67)',
    color          : 'rgb(255, 255, 255)',
    paddingLeft    : theme.spacing(1),
    paddingRight   : theme.spacing(1),
    '&:hover'      : {
      borderColor: 'rgb(136, 136, 136)',
    }
  }
}));
const WindowsWeb: React.FC<{
  icon?: string;
  image?: string;
  title?: string;
  message?: string;
  actions?: string[];
}> = (
  {
    icon,
    title,
    image,
    message,
    actions,
  }
) => {
  const classes = useWindowsWebStyles();
  const [toggleIcon, setToggleIcon] = React.useState<boolean>(false);

  return (
    <Typography
      component='div'
      data-id='windows-web'
      className={classes.webWindows}
    >
      <Typography
        component='div'
        className={classes.ntfContainer}
        onMouseEnter={() => setToggleIcon(true)}
        onMouseLeave={() => setToggleIcon(false)}
      >
        {image && (
          <Typography
            component='div'
            className={classes.imgContainer}
          >
            <img
              alt='alt'
              src={image}
            />
          </Typography>
        )}
        <Typography
          component='div'
          className={classes.middleSideContainer}
        >

          <Typography
            component='div'
            className={classes.iconContainer}
          >
            {!!icon ? (
              <img
                alt='alt'
                src={icon}
                style={{width: 54, height: 54}}
              />
            ) : (
              <ChromeIcon
                size={15}
              />
            )}
          </Typography>
          <Typography
            component='div'
            className={classes.textContainer}
          >
            <Typography
              noWrap
              component='p'
              className={classes.titleTypography}
            >{!!title && title}
            </Typography>
            <Typography
              noWrap
              component='p'
              className={classes.messageTypography}
            >{!!message && message}
            </Typography>
            <Typography
              noWrap
              component='p'
              className={classes.domainTypography}
            >Google Chrome • example.com
            </Typography>
          </Typography>

          {toggleIcon && (
            <img
              alt='alt'
              src={arrowRight}
              className={classes.arrowRightIcon}
            />
          )}
        </Typography>
        {!!(actions && actions.length) && (
          <Typography
            component='div'
            className={classes.actionsContainer}
          >
            {actions.filter((action) => action).map((action, i) => (
              <Typography
                noWrap
                component='p'
                className={classes.actions}
                key={`PreviewAction_${action}_${i}`}
              >{action}
              </Typography>
            ))}
          </Typography>
        )}
      </Typography>
    </Typography>
  )
};

const useAndroidWebStyles = makeStyles((theme: Theme) => ({
  webWindows       : {
    height          : 580,
    width           : 480,
    display         : 'block',
    backgroundSize  : 'cover',
    margin          : '0 auto',
    borderImage     : 'initial',
    position        : 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundImage : `url('${webAndroidBackground}')`,
  },
  domainTypography : {
    marginLeft : 5,
    marginRight: 5,
    fontSize   : 12,
  },
  toggleImageIcon  : {
    width : 15,
    height: 15,
    cursor: 'pointer',
  },
  ntfHeader        : {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'flex-start',
  },
  ntfContainer     : {
    top            : '48%',
    backgroundColor: '#ffffff',
    cursor         : 'pointer',
    position       : 'relative',
    padding        : theme.spacing(2),
    margin         : theme.spacing(0, 4),
  },
  subHeader        : {
    marginTop     : 5,
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'space-between',
  },
  textContainer    : {
    overflow    : 'hidden',
    whiteSpace  : 'nowrap',
    textOverflow: 'ellipsis',
  },
  titleTypography  : {
    fontSize: 14,
    color   : 'rgb(83, 83, 83)',
  },
  messageTypography: {
    fontSize   : 14,
    marginRight: theme.spacing(1),
    color      : 'rgb(117, 117, 117)',
  },
  iconContainer    : {
    width  : 38,
    height : 38,
    display: 'flex',
  },
}));
const AndroidWeb: React.FC<{
  icon?: string;
  image?: string;
  title?: string;
  message?: string;
  actions?: string[];
}> = (
  {
    icon,
    title,
    image,
    message,
  }
) => {
  const classes = useAndroidWebStyles();
  const [open, toggleImage] = React.useState<boolean>(false);

  React.useEffect(() => {
    toggleImage(!!image)
  }, [
    image,
  ]);

  React.useEffect(() => {
    toggleImage(!icon)
  }, [
    icon,
  ]);

  return (
    <Typography
      component='div'
      data-id='android-web'
      className={classes.webWindows}
    >
      <Typography
        component='div'
        className={classes.ntfContainer}
        onClick={() => toggleImage(!open)}
      >
        <Typography
          component='div'
          className={classes.ntfHeader}
        >
          <ChromeIconSmall
            size={13}
          />
          <Typography
            component='p'
            className={classes.domainTypography}
          >Chrome • example.com
          </Typography>
          {image && (
            open ? (
              <img
                alt='alt'
                src={arrowUp}
                className={classes.toggleImageIcon}
              />
            ) : (
              <img
                alt='alt'
                src={arrowDown}
                className={classes.toggleImageIcon}
              />
            )
          )}
        </Typography>
        <Typography
          component='div'
          className={classes.subHeader}
        >
          <Typography
            component='div'
            className={classes.textContainer}
          >
            <Typography
              noWrap
              component='p'
              className={classes.titleTypography}
            >{title && title}
            </Typography>
            <Typography
              noWrap
              component='p'
              className={classes.messageTypography}
            >{message && message}
            </Typography>
          </Typography>
          <Typography
            component='div'
            className={classes.iconContainer}
          >
            {((!open && image && icon) || (icon && !image)) && (
              <img
                alt='alt'
                src={icon}
                style={{height: 38, width: 38}}
              />
            )}
          </Typography>
        </Typography>
        {image && (
          <Collapse
            in={open}
            addEndListener={() => {
            }}
          >
            <img
              alt='alt'
              src={image}
              style={{width: '100%', marginTop: 8, height: 175, overflow: 'hidden'}}
            />
          </Collapse>
        )}
      </Typography>
    </Typography>
  )
};
