import produce from 'immer';
import {Users} from '../../../types/users';
import {ActionTypes} from '../../constants/users';
import {ActionTypes as CoreActionTypes} from '../../constants/application';

const InitialState: Users.Reducer = {
  dataOptions: {
    fetched: false,
    entities: void 0,
    total: 0,
  },
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortOrder: 'desc',
    sortName : 'created_at',
    searchText: '',
    planTemplateFilter: [],
    permissionsFilter: [],
  },
  subScriptionPlans: [],
  featureGroups: [],
};

export const users = produce((draft, action) => {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.USERS_SET_DATA: {
      draft.dataOptions.entities = payload.data;
      draft.dataOptions.total = payload.total || 0;
      draft.dataOptions.fetched = true;
      return;
    }
    case ActionTypes.USERS_DELETE_DATA: {
      const filteredData = draft.dataOptions.entities.filter((item: Users.Entity) => item.uuid !== payload);
      draft.dataOptions.entities = filteredData;
      return;
    }
    case ActionTypes.USERS_SET_LOADING: {
      draft.dataOptions.fetched = payload || false;
      return;
    }
    case ActionTypes.USERS_UPDATE_FILTERS: {
      draft.filters = {
        ...draft.filters,
        ...payload,
      };
      return;
    }
    case ActionTypes.USERS_SET_SUBSCRIPTION_PLANS: {
      draft.subScriptionPlans = payload;
      return;
    }
    case ActionTypes.USERS_SET_FEATURE_GROUPS: {
      draft.featureGroups = payload;
      return;
    }
    case ActionTypes.USERS_CLEANUP: {
      return {...InitialState};
    }
    case CoreActionTypes.LOGOUT: {
      return {...InitialState}
    }
    default:
      return void 0;
  }
}, InitialState);
