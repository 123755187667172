import React from 'react';
import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useTitle} from '../../../../hooks/useTitle';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {_emptyForm} from '../../../../store/actions/application';
import {_formInfo} from '../../../../store/selectors/application';
import {_resetPassword} from '../../../../store/actions/application/resetPassword';
import {_checkPasswordResetToken} from '../../../../store/actions/application/checkPasswordResetToken';

export interface IResetPasswordForm {
  email: string;
  token: string;
  message: string;
  password: string;
}

export function useResetPassword() {
  useTitle('Reset Password | SmartPush');
  const formInfo = useSelector(_formInfo);
  const {email, token} = useParams<{ token: string; email: string }>()
  const {
    reset,
    setError,
    handleSubmit,
    ...formRest
  } = useForm<IResetPasswordForm>({
    mode         : 'onSubmit',
    defaultValues: {
      email,
    }
  });
  const dispatch = useDispatch();
  const {executeRecaptcha} = useGoogleReCaptcha();

  React.useEffect(() => {
    dispatch(_checkPasswordResetToken({
      email,
      token,
      reset,
    }));

    return function () {
      dispatch(_emptyForm());
    }
  }, [
    email,
    token,
    reset,
    dispatch,
  ]);

  const submit = React.useCallback(async (formValues: IResetPasswordForm) => {
    return dispatch(_resetPassword(formValues, {
      setError,
      executeRecaptcha,
    }))
  }, [
    setError,
    dispatch,
    executeRecaptcha,
  ]);

  return {
    ...formRest,
    reset,
    formInfo,
    onSubmit: handleSubmit(submit),
  }
}