import React from 'react';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import Menu from '@material-ui/core/Menu';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import ArchiveIcon from '@material-ui/icons/Archive';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {urlHelper} from '../../../../../../../utils/urlHelper';
import {AppRoutes} from '../../../../../../../constants/appRoutes';
import {TableActions} from '../../../../../../../constants/tableActions';
import {toggleDialogAction} from '../../../../../../../store/actions/subscribers';
import {_canDeleteSubscriber} from '../../../../../../../store/selectors/application/acl';

export default function ActionFormatter(
  {
    row,
  }: DataTypeProvider.ValueFormatterProps
) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const canDeleteSubscriber = useSelector(_canDeleteSubscriber);

  const {
    appUuid,
  } = useParams<{ appUuid: string }>();

  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = React.useCallback(() => {
    setOpen(prevOpen => {
      return !prevOpen
    });
  }, []);

  const handleClose = React.useCallback((event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }, []);

  const toggleDialog = React.useCallback((e) => {
    handleClose(e);
    if (e.currentTarget.getAttribute('data-action')) {
      dispatch(toggleDialogAction({
        data  : row,
        dialog: e.currentTarget.getAttribute('data-action'),
      }));
    }
  }, [
    row,
    dispatch,
    handleClose,
  ]);

  return (
    <Typography
      align='center'
      component='div'
      color='primary'
    >
      <div>
        <IconButton
          size='small'
          disableRipple
          color='default'
          ref={anchorRef}
          disableFocusRipple
          disableTouchRipple
          onClick={handleToggle}
        >
          <CloseIcon
            fontSize='small'
            style={!open ? {display: 'none'} : undefined}
          />
          <MoreVertIcon
            fontSize='small'
            style={open ? {display: 'none'} : undefined}
          />
        </IconButton>
        <Menu
          open={open}
          elevation={0}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorEl={anchorRef.current}
          PaperProps={{
            square : true,
            variant: 'outlined',
            style  : {border: '1px solid lightgrey'},
          }}
          anchorOrigin={{
            vertical  : 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical  : 'center',
            horizontal: 'right',
          }}
          MenuListProps={{style: {paddingTop: 5, paddingBottom: 5}}}
        >
          <Link
            to={urlHelper(AppRoutes.ANALYTICS_SUBSCRIBERS_INFO, {
              appUuid,
              uuid: row.uuid
            })}
            style={{backgroundColor: 'transparent !important', textDecoration: 'none'}}
          >
            <MenuItem
              onClick={toggleDialog}
            >
              <ListItemIcon
                style={{minWidth: 26}}
              >
                <HelpOutlineIcon
                  fontSize='small'
                />
              </ListItemIcon>
              <Typography
                noWrap
                color='primary'
                variant='inherit'
              >{t('subscribers.info')}
              </Typography>
            </MenuItem>
          </Link>
          {canDeleteSubscriber && (
            <MenuItem
              onClick={toggleDialog}
              data-action={TableActions.DELETE}
            >
              <ListItemIcon
                style={{minWidth: 26}}
              >
                <ArchiveIcon
                  color='error'
                  fontSize='small'
                />
              </ListItemIcon>
              <Typography
                noWrap
                color='primary'
                variant='inherit'
              >{t('subscribers.archive')}
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </div>
    </Typography>
  )
}
