import {notification} from 'antd';
import {_upsertDataItem} from './index';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {Text} from '../../../components/antd/Typography';
import {FiniteStates} from '../../../constants/finiteStates';

export const _regenerate = (id: number): Globals.ThunkAction => async (dispatch, getState, {
  api
}) => {
  dispatch(_upsertDataItem({
    id,
    regenerating: FiniteStates.LOADING,
  }));

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.post(urlHelper(ApiRoutes.REGENERATE_API_TOKEN, {
    id,
  }));

  if (!hasError) {
    notification.success({
      message,
      duration   : null,
      description: <Description secret={data.secret}/>,
    });
    dispatch(_upsertDataItem({
      id,
      api_key     : data.key,
      regenerating: FiniteStates.SUCCESS,
    }));
  } else {
    notification.error({message: 'You don\'t have access to manage this action'});

    dispatch(_upsertDataItem({
      id,
      regenerating: FiniteStates.FAILURE,
    }));
  }
};

function Description(
  {
    secret,
  }: {
    secret: string;
  }
) {
  return (
    <div>
      <div>
        <Text
          fontSize='small'
          fontWeight='bold'
        >Make sure you save it - you won't be able to access it again.
        </Text>
      </div>
      <div>
        <Text
          copyable
          ellipsis
          fontSize='small'
        >{secret}
        </Text>
      </div>
    </div>
  )
}
