import {notification} from 'antd';
import {_dataOptions} from './index';
import {_upsertEntity} from './index';
import {TFunction} from 'react-i18next';
import {Globals} from '../../../types/globals';
import {_currentApp} from '../../selectors/apps';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';
import {UseFormSetError} from 'react-hook-form/dist/types/form';
import {requestTransformer} from '../../../utils/promptTransformer';
import {responseTransformer} from '../../../utils/promptTransformer';

export const _create = (
  appUuid: string,
  formData: Record<string, any>,
  {
    t,
    setError,
    unregisterCallback,
  }: {
    t: TFunction;
    setError: UseFormSetError<any>;
    unregisterCallback: () => void;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    isCreatingOrUpdating: FiniteStates.LOADING,
  }));

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.post(urlHelper(ApiRoutes.CREATE_PROMPT, {
    appUuid,
  }), requestTransformer(formData));

  notification.open({
    message,
    type: hasError ? 'error' : 'success',
  });

  if (hasError) {
    const errors = getErrors(data, formData.messages);

    Object.keys(errors).forEach((inputName: any) => {
      if (String(inputName).endsWith('.text')) {
        setError(inputName.replace('text', 'message_text'), {
          message: errors[inputName],
        })
      } else {
        setError(inputName, {
          message: errors[inputName],
        })
      }
    });

    dispatch(_dataOptions({
      isCreatingOrUpdating: FiniteStates.FAILURE,
    }));
  } else {
    const app = _currentApp(getState());
    dispatch(_upsertEntity(responseTransformer({
      id      : data.id,
      app_uuid: data.app_uuid,
      ...data.data,
    }, app, t)));
    unregisterCallback && unregisterCallback();

    dispatch(_dataOptions({
      isCreatingOrUpdating: FiniteStates.SUCCESS,
    }));
  }
};

function getErrors(errors: Record<string, string>, messages: any[]) {
  return Object.keys(errors).reduce((acc: any, errorKey: string) => {
    if (errorKey.startsWith('messages.')) {
      const id = errorKey.split('.')[1];
      const index = messages.findIndex((message) => String(message.iso) === String(id));
      if (index !== -1) {
        const newKey = errorKey.replace(`.${id}.`, `.${index}.`);
        acc[newKey] = errors[errorKey];
      }
    } else {
      acc[errorKey] = errors[errorKey];
    }

    return acc;
  }, {})
}
