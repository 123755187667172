import {notification} from 'antd';
import {_get as _getApp} from './get';
import {_upsertDataItem} from './index';
import {splice} from '../../../utils/splice';
import {Globals} from '../../../types/globals';
import {UseFormSetError} from 'react-hook-form';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {_apiBaseUrl} from '../../selectors/application';
import {requestTransformer} from '../../../utils/generalSettingTransformer';

export const _update = (formValues: Record<string, any>, {
  setError,
  skipLoading,
  unregisterCallback,
}: {
  skipLoading?: boolean;
  unregisterCallback: () => void;
  setError: UseFormSetError<Record<string, any>>;
}): Globals.ThunkAction => async (dispatch, getState, api) => {
  const {
    uuid,
    ...rest
  } = requestTransformer(formValues);

  const apiBaseUrl = _apiBaseUrl(getState());
  const url = `${apiBaseUrl}${urlHelper(ApiRoutes.UPDATE_APP, {
    appUuid: uuid,
  })}`;

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.post(url, {
    ...rest
  });

  if (!hasError) {
    unregisterCallback();
    dispatch(_upsertDataItem({
      uuid : data.uuid,
      title: data.title,
    }));
    await dispatch(_getApp(uuid, {skipLoading}));
  } else {
    Object.keys(data._errors).forEach((inputName: string) => {
      if (!inputName.startsWith('var')) {
        setError(inputName, {
          message: data._errors[inputName],
        })
      } else if (inputName.endsWith('.name')) {
        let newName: any = splice(inputName, '.', 3).replace('var', 'advanced_parameters');

        newName = newName.split('.');

        newName = [
          newName[0],
          Number(newName[1]) - 1,
          newName[2],
        ].join('.');

        setError(newName, {
          message: data._errors[inputName],
        })
      }
    });
  }

  notification.open({
    type   : hasError ? 'error' : 'success',
    message: data?.errors?.limits_notice || message,
  });
};