import {CancelToken} from 'axios';
import {_dataOptions} from './index';
import {_upsertEntity} from './index';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _get = (appUuid: string, id: string, {
  cancelToken,
}: {
  cancelToken: CancelToken;
}): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    fetchingTemplateState: FiniteStates.LOADING,
  }));

  const {
    hasError,
    body: {
      data,
    },
  } = await api.get(urlHelper(ApiRoutes.GET_TEMPLATE, {
    appUuid,
    uuid: id,
  }), {
    cancelToken,
  });

  if (!hasError) {
    dispatch(_upsertEntity(data));

    dispatch(_dataOptions({
      fetchingTemplateState: FiniteStates.SUCCESS,
    }));
  } else {
    dispatch(_dataOptions({
      fetchingTemplateState: FiniteStates.FAILURE,
    }));
  }
};