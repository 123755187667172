import {ActionTypes} from '../../../constants/appSettings';

const InitialState: any = {
  loading: false,
  errors : void 0,
};

export default (state: any = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_SETTINGS_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case ActionTypes.WEB_NOTIFY_SETTINGS_TOGGLE_ERRORS: {
      return {
        ...state,
        errors: payload,
      };
    }
    case ActionTypes.WEB_NOTIFY_SETTINGS_REMOVE_ERROR: {
      if (state?.errors?.[payload]) {
        return {
          ...state,
          errors: {
            ...state.errors,
            [payload]: void 0
          },
        }
      }
      return state;
    }
    default:
      return state;
  }
};
