import React from 'react';
import {useApp} from './useApp';
import Box from '@material-ui/core/Box';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import {Subheader} from './Components/Subheader';
import {DeleteApp} from './Components/DeleteApp';
import {GeneralCard} from './Components/GeneralCard';
import Typography from '@material-ui/core/Typography';
import {Button} from '../../../../../components/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Spinner} from '../../../../../components/antd/Spinner';
import {_currentApp} from '../../../../../store/selectors/apps';
import {FiniteStates} from '../../../../../constants/finiteStates';
import {_fetchingAppState} from '../../../../../store/selectors/apps';
import {AdvancedParametersCard} from './Components/AdvancedParametersCard';

const useStyles = makeStyles((theme: Theme) => ({
  btnContainer: {
    bottom                        : 0,
    display                       : 'flex',
    backgroundColor               : '#fff',
    margin                        : '0 auto',
    position                      : 'sticky',
    justifyContent                : 'flex-start',
    padding                       : theme.spacing(2),
    width                         : 'calc(100% - 2px)',
    boxShadow                     : '0px 2px 8px rgb(0 0 0 / 15%)',
    '& > button'                  : {
      flexGrow   : 1,
      maxWidth   : 230,
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection : 'column',
      justifyContent: 'center',
      alignItems    : 'center',
      '& > button'  : {
        marginRight : 0,
        minWidth    : '80%',
        marginBottom: theme.spacing(1),
      },
    },
  },
}));

export function App() {
  const app = useSelector(_currentApp);
  const appState = useSelector(_fetchingAppState);

  return (!!app && (appState === FiniteStates.SUCCESS)) ? <Content/> : <Spinner/>;
}

export function Content(): JSX.Element {
  const classes = useStyles();
  const {t} = useTranslation();

  const {
    tags,
    onSubmit,
    isSubmitting,
    canDeleteApp,
    canUpdateApp,
    hasWebhooksAccess,
    hasForcePromptAccess,
    form: {
      control,
      setValue,
      register,
      getValues,
      handleSubmit,
    },
  } = useApp();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <input
        type='hidden'
        {...register('uuid')}
      />
      <Box>
        <Box
          padding={2}
          marginTop={0}
          marginLeft='1px'
          marginBottom={1}
          marginRight='1px'
          style={{backgroundColor: '#fff'}}
        ><Subheader/>
        </Box>
        <GeneralCard
          tags={tags}
          control={control}
          setValue={setValue}
          getValues={getValues}
          disabled={!canUpdateApp}
          hasWebhooksAccess={hasWebhooksAccess}
          hasForcePromptAccess={hasForcePromptAccess}
        />
        <Box
          height={8}
        />
        <AdvancedParametersCard
          control={control}
          disabled={!canUpdateApp}
        />
        <Typography
          align='left'
          component='div'
          className={classes.btnContainer}
        >
          <Button
            type='submit'
            color='primary'
            variant='contained'
            loading={isSubmitting}
            disabled={isSubmitting || !canUpdateApp}
          >{t('app_settings.save_button')}
          </Button>
          {canDeleteApp && (
            <DeleteApp/>
          )}
        </Typography>
      </Box>
    </form>
  )
}
