import clsx from 'clsx';
import React from 'react';
import {Theme} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {OutlinedTextFieldProps} from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) => ({
  textField  : {
    marginTop                     : 0,
    marginBottom                  : theme.spacing(3),
    minWidth                      : 320,
    marginLeft                    : theme.spacing(1),
    marginRight                   : theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
    },
    '& fieldset'                  : {
      borderColor: '#EAEAEA'
    }
  },
  inputClass : {
    backgroundColor: '#ffffff',
  },
  formControl: {
    display      : 'flex',
    flexDirection: 'column',
    position     : 'relative',
  },
  inputCLabel: {
    fontSize  : 16,
    minHeight : 24,
    fontWeight: 'bold',
    margin    : '8px 14px 0',
    color     : theme.palette.text.primary,
  },
  loaderWrap : {
    top           : 0,
    left          : 0,
    zIndex        : 1000,
    display       : 'flex',
    height        : '100%',
    width         : '100%',
    justifyContent: 'center',
    alignItems    : 'center',
    position      : 'absolute',
  },
  loader     : {
    marginTop: 22,
  },
}));

export const FormControl: React.FC<IProps> = (
  {
    cLabel,
    showLoader,
    InputProps,
    formControlClass,
    FormHelperTextProps,
    ...rest
  }
) => {
  const classes = useStyles();
  return (
    <Typography
      component='div'
      className={clsx(classes.formControl, formControlClass)}
    >
      {!!cLabel && (
        <Typography
          component='span'
          className={classes.inputCLabel}
        >{cLabel}</Typography>
      )}
      {!!showLoader && (
        <Typography
          component='div'
          className={classes.loaderWrap}
        >
          <CircularProgress
            size={25}
            className={classes.loader}
          />
        </Typography>
      )}
      <TextField
        margin='dense'
        variant='outlined'
        className={classes.textField}
        FormHelperTextProps={{
          ...FormHelperTextProps,
        }}
        InputProps={{
          ...InputProps,
          className: classes.inputClass,
        }}
        {...rest}
      />
    </Typography>
  )
};

interface IProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  showLoader?: boolean;
  cLabel?: React.ReactNode;
  formControlClass?: string;
}
