import produce from 'immer';
import {FiniteStates} from '../../../constants/finiteStates';
import {ActionTypes} from '../../constants/subscriptionsAndBilling';
import {ActionTypes as CoreActionTypes} from '../../constants/application';

const InitialState: Record<string, any> = {
  downgrade        : void 0,
  prorationTotal   : {
    prorationTotalFiniteState: FiniteStates.IDLE,
  },
  portal           : {
    portalFiniteState: FiniteStates.IDLE,
  },
  subscriptionPlans: {
    subscriptionPlansFiniteState: FiniteStates.IDLE,
  },
  downgradeState   : FiniteStates.IDLE,
};

export const subscriptionsAndBilling = produce((draft, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.SET_PORTAL: {
      if (!draft.portal) {
        draft.portal = {};
      }
      Object.keys(payload || {}).forEach((key: string) => {
        draft.portal[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.CANCEL_DOWNGRADE: {
      if (!draft.downgrade) {
        draft.downgrade = {};
      }
      Object.keys(payload || {}).forEach((key: string) => {
        draft.downgrade[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.SET_PRORATION_TOTAL: {
      Object.keys(payload).forEach((key: string) => {
        draft.prorationTotal[key] = payload[key];
      })

      return void 0;
    }
    case ActionTypes.SET_SUBSCRIPTION_PLANS: {
      if (!draft.subscriptionPlans) {
        draft.subscriptionPlans = {};
      }
      Object.keys(payload || {}).forEach((key: string) => {
        draft.subscriptionPlans[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.UPSERT_SUBSCRIPTIONS_AND_BILLING: {
      Object.keys(payload || {}).forEach((key: string) => {
        draft[key] = payload[key];
      });

      return void 0;
    }
    case CoreActionTypes.LOGOUT: {
      return {...InitialState}
    }
    default:
      return void 0;
  }
}, InitialState);

