import {IObject} from '../types';
import moment from 'moment-timezone';
import {matchPath} from 'react-router';
import {RouteProps} from 'react-router';

export const cFirst = (s: string = ''): string => {
  return s.charAt(0);
};

export const ucFirst = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1)
};

export const lcFirst = (s: string): string => {
  return s.charAt(0).toLowerCase() + s.slice(1)
};

export const formatDate = (date: any) => {
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  return `${date.getFullYear()}-${month}-${day}`
};

export const filterObject: any = (obj: IObject) => {
  let newObj: IObject = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    } else if (obj[prop] === 0) {
      newObj[prop] = 0;
    } else if (obj[prop] === false) {
      newObj[prop] = false;
    }
  });
  return newObj;
};

export const copyToClipboard = (text: string): boolean => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  const res = document.execCommand('copy');
  textField.remove();
  return res;
};

export const getResponseErrorMsgs = (errors: any): any => {
  return Object.keys(errors || {}).reduce((accumulator: { [key: string]: string }, currentValue) => {
    const currentValueArray = currentValue.split('_');
    if (currentValueArray.length > 1) {
      let newKey: string = '';
      currentValueArray.forEach((item: string, index: number) => {
        if (index === 0) {
          newKey += item;
        } else {
          newKey += ucFirst(item);
        }
      });
      accumulator[newKey] = errors[currentValue].join(' ');
    } else {
      accumulator[currentValue] = errors[currentValue].join(' ');
    }
    return accumulator;
  }, {});
};

export const _getResponseErrorMsgs = (errors: any): any => {
  return Object.keys(errors || {}).reduce((accumulator: { [key: string]: string }, currentValue) => {
    accumulator[currentValue] = errors[currentValue].join(' ');
    return accumulator;
  }, {});
};

export const replaceBetween = (str: string = '', replacer: string | number, start?: number, end?: number) => {
  return str.substring(0, start || str.length) + replacer + str.substring(end || str.length);
};

export function nFormatter(num: number, digits: number) {
  const si = [
    {value: 1, symbol: ''},
    {value: 1E3, symbol: 'k'},
    {value: 1E6, symbol: 'M'},
    {value: 1E9, symbol: 'G'},
    {value: 1E12, symbol: 'T'},
    {value: 1E15, symbol: 'P'},
    {value: 1E18, symbol: 'E'}
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export const formatWithCommas = (num: number) => String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// eslint-disable-next-line
export const validateUrl = (url: any = '') => (/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(url));

export function getMinY(data: any) {
  return Math.min(...(data || []).map((i: any) => i[1]));
}

export function getMaxY(data: any) {
  return Math.max(...(data || []).map((i: any) => i[1]));
}

export const arrSum = (arr: any) => arr.reduce((a: any, b: any) => a + b, 0);

export const momentTZ = (date: string, keepLocalTime?: boolean): moment.Moment => {
  moment.tz.setDefault('UTC');
  const timeZone: string = localStorage.getItem('display_time_zone') || 'UTC';
  return moment(date).tz(timeZone, keepLocalTime);
};

export const momentUTC = (date?: string | Date, keepLocalTime?: boolean): moment.Moment => {
  return moment(date).tz('UTC', keepLocalTime);
};

export const now = (): moment.Moment => {
  const timeZone: string = localStorage.getItem('display_time_zone') || 'UTC';
  moment.tz.setDefault(timeZone);
  return moment();
};

export function getParams<Params extends { [K in keyof Params]?: string }>(pathname: string, props: string | string[] | RouteProps) {
  const matchProfile = matchPath<Params>(pathname, props);
  return (matchProfile && matchProfile.params) || {} as Params;
}

export function isFloat(n: any) {
  return Number(n) === n && n % 1 !== 0;
}

export function secondsConverter(num: any) {
  let t: any;
  let unity: any;
  if (num) {
    const days: any = ((num) / (60 * 60 * 24));
    if (isFloat(days)) {
      const hours: any = ((num) / (60 * 60));
      if (isFloat(hours)) {
        const minutes: any = ((num) / (60));
        if (isFloat(minutes)) {
          t = Number(minutes.toFixed(0));
          t = t ? t : 1;
          unity = 'minute';
        } else {
          t = minutes ? minutes : 1;
          unity = 'minute';
        }
      } else {
        t = hours;
        unity = 'hour';
      }
    } else {
      t = days;
      unity = 'day';
    }
  }

  return {
    unit : unity,
    value: Number(t),
  }
}

export function mutateCampaignData(data: any = {}, additionalData: { [key: string]: any }) {
  const {
    automation,
    ...restData
  } = data?.data || {};
  let newData = {
    ...data,
    ...restData,
    ...data.progress,
    messages   : {
      ...restData?.messages,
      default: {
        title  : data?.title,
        message: restData?.message,
        ...restData?.messages?.default
      }
    },
    locale_type: data?.data?.locale_type || 'language',
  };

  let mutatedDataJson: any = {};

  if (newData.ttl_value && newData.ttl_unit) {
    mutatedDataJson = {
      ...mutatedDataJson,
      ttl_unit: {
        value: newData.ttl_unit,
        label: newData.ttl_unit
      }
    }
  } else if (newData.ttl) {
    const newTtl = secondsConverter(newData.ttl);
    mutatedDataJson = {
      ...mutatedDataJson,
      ttl_value: newTtl.value,
      ttl_unit : {
        value: newTtl.unit,
        label: newTtl.unit,
      }
    }
  } else {
    mutatedDataJson = {
      ...mutatedDataJson,
      ttl_unit : DEFAULT_TTL.ttl_unit,
      ttl_value: DEFAULT_TTL.ttl_value,
    }
  }

  if (newData.wait_until_value && newData.wait_until_unit) {
    mutatedDataJson = {
      ...mutatedDataJson,
      wait_until_unit: {
        value: newData.wait_until_unit,
        label: newData.wait_until_unit
      }
    }
  } else if (newData.wait_until && !Number.isNaN(Number(String(newData.wait_until)))) {
    const newWaitUntil = secondsConverter(newData.wait_until);
    mutatedDataJson = {
      ...mutatedDataJson,
      wait_until_value: newWaitUntil.value,
      wait_until_unit : {
        value: newWaitUntil.unit,
        label: newWaitUntil.unit,
      }
    }
  } else if (newData && newData.automation && newData.automation.delay && !Number.isNaN(Number(String(newData.automation.delay)))) {
    const newWaitUntil = secondsConverter(newData.automation.delay);
    mutatedDataJson = {
      ...mutatedDataJson,
      wait_until_value: newWaitUntil.value,
      wait_until_unit : {
        value: newWaitUntil.unit,
        label: newWaitUntil.unit,
      }
    }
  }

  newData = {
    ...newData,
    ...mutatedDataJson
  };

  return newData
}

const DEFAULT_TTL: any = {
  ttl_value: 1,
  ttl_unit : {
    value: 'day',
    label: 'day',
  },
};
