import {getState} from '../index';
import {createSelector} from 'reselect';

export const _reportsReducer = createSelector(getState, (application) => {
  return application.reports;
});

export const _reportsIndex = createSelector(_reportsReducer, (reports) => {
  return reports.index;
});

export const _reportsDates = createSelector(_reportsIndex, (index) => {
  return index.dates;
});

export const _reportsAppUuid = createSelector(_reportsIndex, (index) => {
  return index.appUuid;
});

export const _reportsState = createSelector(_reportsIndex, (index) => {
  return index.state;
});

export const _reportsShowTable = createSelector(_reportsIndex, (index) => {
  return index.showTable;
});

export const _selectedCharts = createSelector(_reportsIndex, (index) => {
  return index.selectedCharts;
});

export const _reportsTable = createSelector(_reportsIndex, (index) => {
  return index.reportsTable;
});