import {Tag} from 'antd';
import {Card} from 'antd';
import React from 'react';
import {Table} from 'antd';
import moment from 'moment';
import {ColumnsType} from 'antd/lib/table/interface';
import {Link} from '../../../../../../../components/antd/Typography';

export interface IInvoice {
  stripe_id: string;
  data: {
    id: string;
    paid: boolean;
    total: number;
    status: string;
    created: number;
    base_fee: number;
    amount_due: number;
    description: string;
    invoice_pdf: string;
    subscription: string;
    ending_balance: number;
    overage_computed: number;
    hosted_invoice_url: string;
    status_transitions: {
      paid_at: number;
      voided_at?: any;
      finalized_at: number;
      marked_uncollectible_at?: any;
    };
  };
  created_at: string;
}

const statuses: Record<string, string> = {
  paid         : 'Paid',
  open         : 'Unpaid',
  uncollectible: 'Unpaid',
  void         : 'Canceled',
}

const statusColors: Record<string, string> = {
  uncollectible: 'error',
  open         : 'error',
  void         : 'default',
  paid         : 'success',
  draft        : 'processing',
}


export function BillingHistory(
  {
    invoices,
  }: {
    invoices: IInvoice[];
  }
) {
  const columns: ColumnsType<IInvoice> = [
    {
      width    : 250,
      ellipsis : true,
      title    : 'Date',
      key      : 'data.created',
      dataIndex: ['data', 'created'],
      render   : created => moment(created * 1000).format('MMMM DD, YYYY'),
    },
    {
      ellipsis : true,
      title    : 'Amount Due',
      key      : 'data.amount_due',
      dataIndex: ['data', 'amount_due'],
      render   : amountDue => `${(amountDue < 0) ? '-$' : '$'}${Math.abs(amountDue)}`,
    },
    {
      title    : 'Plan',
      key      : 'data.description',
      dataIndex: ['data', 'description'],
    },
    {
      title    : 'Status',
      key      : 'data.status',
      dataIndex: ['data', 'status'],
      render   : (status) => <Tag color={statusColors[status]}>{statuses[status] || status}</Tag>
    },
    {
      render: Invoice,
      title : 'Invoice',
      key   : 'data.invoice',
    },
  ];

  return (
    <Card
      title='Billing History'
      bodyStyle={{padding: 0}}
    >
      <Table
        columns={columns}
        pagination={false}
        dataSource={invoices}
        scroll={{x: true, scrollToFirstRowOnChange: true}}
      />
    </Card>
  )
}

function Invoice({data}: IInvoice) {
  return (
    <>
      <Link
        color='inherit'
        target='_blank'
        href={data.hosted_invoice_url}
      >{(data.status?.toLowerCase() === 'open') ? 'Pay Invoice' : 'Invoice'}
      </Link>
      {!['draft', 'open', 'void'].includes(data.status?.toLowerCase()) && (
        <div
          className='m-t-3'
        >
          <Link
            color='inherit'
            target='_blank'
            href={data.invoice_pdf}
          >Download
          </Link>
        </div>
      )}
    </>
  )
}
