import React from 'react';
import axios from 'axios';
import {Canceler} from 'axios';
import {usePrompt} from './usePrompt';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import {useTranslation} from 'react-i18next';
import {Card} from '../../../../../components/Card';
import {Button} from '../../../../../components/Button';
import CardContent from '@material-ui/core/CardContent';
import {GeneralOptions} from './Components/GeneralOptions';
import {_get} from '../../../../../store/actions/prompt/get';
import {_entity} from '../../../../../store/selectors/prompt';
import {Spinner} from '../../../../../components/antd/Spinner';
import {CardHeader} from '../../../../../components/CardHeader';
import {FiniteStates} from '../../../../../constants/finiteStates';
import {ToggleCustomPrompt} from './Components/ToggleCustomPrompt';
import {ToggleSubscription} from './Components/ToggleSubscription';
import {_fetchingAppState} from '../../../../../store/selectors/apps';
import {PromptMessages} from '../../../../../components/Messages/Prompt';

let cancel: Canceler | undefined;
const CancelToken = axios.CancelToken;

export function Prompt() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const entity = useSelector(_entity);
  const appState = useSelector(_fetchingAppState);
  const {appUuid} = useParams<{ appUuid: string }>();

  React.useEffect(() => {
    return function () {
      if (typeof cancel === 'function') {
        cancel();
      }
    }
  }, []);

  React.useEffect(() => {
    dispatch(_get(appUuid, {
      t,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    }))
  }, [
    t,
    appUuid,
    dispatch,
  ]);

  if (!entity || (appState !== FiniteStates.SUCCESS)) {
    return (
      <Spinner/>
    )
  }

  return (
    <Content/>
  )
}

function Content() {
  const {
    form,
    control,
    onSubmit,
    disabled,
    register,
    isSubmitting,
    customPromptState,
  } = usePrompt();
  const {t} = useTranslation();

  return (
    <form
      onSubmit={onSubmit}
    >
      <input
        type='hidden'
        {...register('has_locales')}
      />
      <Box>
        <ToggleSubscription/>
        <GeneralOptions
          control={control}
          disabled={disabled}
        />
        <ToggleCustomPrompt
          control={control}
          disabled={disabled}
        />
        <Card>
          <CardHeader
            title={t('app_settings.custom_prompt_settings')}
            color={(disabled || !customPromptState) ? 'disabled' : 'primary'}
          />
          <CardContent>
            <PromptMessages
              form={form}
              disabled={disabled || !customPromptState}
            />
          </CardContent>
        </Card>
        <Box
          width={200}
          marginTop={1}
        >
          <Button
            fullWidth
            type='submit'
            color='primary'
            variant='contained'
            loading={isSubmitting}
            disabled={isSubmitting || disabled}
          >{t('app_settings.save_button')}
          </Button>
        </Box>
      </Box>
    </form>
  )
}