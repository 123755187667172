import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Row, Tooltip} from 'antd';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {_updateFilters} from '../../../store/actions/builder/icon/actions';
import {getFilters, getFetchState} from '../../../store/selectors/icons';
import CreateOrEditDialog from './Dialogs/CreateOrEditIcon';
import {_fetchTags} from '../../../store/actions/tags';
import DynamicSelect from '../../../components/antd/DynamicSelect';

const initialPageNumber = 1;

interface IProps {
  onReload: () => void,
};

const FilterSection: React.FC<IProps> = ({onReload}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const filters = useSelector(getFilters);
  const fetchingState = useSelector(getFetchState);
 
  const onReloadData = () => {
    onReload();
  };

  const onTagsChange = (value: string[]) => {
    dispatch(_updateFilters({
      tags: value,
      pageNumber: initialPageNumber,
    }));
  };

  return (
    <>
      <Row className='justify-content-between m-b-5'>
        <Row >
          <DynamicSelect
            titleKey='id'
            valueKey='id'
            dataKey='rows'
            mode='multiple'
            placeholder='Filter by Tags'
            maxTagCount='responsive'
            value={filters.tags}
            onChange={onTagsChange}
            fetchData={_fetchTags}
            style={{width: 300}}
          />
          <Button
            type='primary'
            className='m-l-1'
            onClick={() => setVisible(true)}
            icon={<PlusOutlined/>}
            children='Create New'
          />
        </Row>
        <Tooltip title='Refresh' placement='left'>
          <Button
            shape='circle'
            onClick={onReloadData}
            icon={<ReloadOutlined style={{color: '#0000008a'}}/>}
            disabled={!fetchingState}
          />
        </Tooltip>
      </Row>
      {visible &&
        <CreateOrEditDialog
          visible={visible}
          onCancel={() => setVisible(false)}
        />
      }
    </>
  )
};

export default FilterSection;
