import clsx from 'clsx';
import React from 'react';
import {Button} from '../../Button';
import {ButtonProps} from '../../Button';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import {DialogActionsProps} from '@material-ui/core/DialogActions';

const useStyles = makeStyles((theme: Theme) => ({
  muiDialogActionsClass: {
    padding: `${theme.spacing(2.25)}px ${theme.spacing(4.5)}px`,
  },
  alignmentcenter      : {
    justifyContent: 'center',
  },
  alignmentleft        : {
    justifyContent: 'flex-start',
  },
  alignmentright       : {
    justifyContent: 'flex-end',
  },
}));

export default function Actions(
  {
    align,
    actions,
    className,
    ...rest
  }: ActionProps
) {
  const classes: any = useStyles();

  return (
    <MuiDialogActions
      className={clsx(classes.muiDialogActionsClass, className, align && classes[`alignment${align}`])}
      {...rest}
    >
      {actions.map((
        {
          label,
          loading,
          ...btnRest
        },
        index: number,
      ) => (
        <Button
          {...btnRest}
          size='small'
          key={`DialogBtn_${index}`}
          style={{width: 180, fontSize: 14, ...btnRest?.style}}
        >
          {!!loading && (
            <CircularProgress
              size={14}
              color='primary'
              style={{marginRight: 8}}
            />
          )}
          {label}
        </Button>
      ))}
    </MuiDialogActions>
  )
}

export type ActionProps =
  DialogActionsProps
  & { actions: (ButtonProps & { label: React.ReactNode, loading?: boolean })[], align?: 'center' | 'left' | 'right' }
