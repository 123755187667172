import React from 'react';
import {Alert} from 'antd';
import {messageSeparator} from '../../../constants';
import './index.less';

const styles: React.CSSProperties = {
  opacity  : 0,
  transform: 'translateY(-24px)',
};

const hasErrorStyles: React.CSSProperties = {
  opacity  : 1,
  transform: 'translateY(0)',
};

export function FormAlert(
  {
    type,
    message,
    onClose,
    description,
  }: {
    message?: React.ReactNode;
    description?: React.ReactNode;
    type?: 'success' | 'info' | 'warning' | 'error';
    onClose?: React.MouseEventHandler<HTMLButtonElement>;
  }
) {
  let alertMessage = message;
  let alertDescription = description;

  if (typeof message === 'string') {
    const splitMessage = message.split(messageSeparator);

    alertMessage = splitMessage?.[0] || message;
    if (splitMessage?.[1]) {
      alertDescription = splitMessage?.[1];
    }
  }

  return (
    <div
      className='alert-container'
      style={!!message ? hasErrorStyles : styles}
    >
      {!!message ? (
        <Alert
          showIcon
          type={type}
          onClose={onClose}
          closable={!!onClose}
          message={alertMessage}
          className='alert m-b-5'
          description={alertDescription}
        />
      ) : null}
    </div>
  )
}
