import {ActionTypes} from '../../../../constants/subscribers';

const InitialState: any = {
  pageNumber: 1,
};

export default (state: any = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_SUBSCRIBERS_TABLE_OPTIONS: {
      if (payload.pageNumber) {
        return {
          ...state,
          pageNumber: payload.pageNumber,
        }
      }
      return state;
    }
    case ActionTypes.WEB_NOTIFY_SUBSCRIBERS_EMPTY_DATA: {
      return InitialState;
    }
    default:
      return state;
  }
};
