import React from 'react';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {ButtonAdornment} from '../../../FileAdornment';
import {Control} from 'react-hook-form/dist/types/form';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {UseFormGetValues} from 'react-hook-form/dist/types/form';

export function IconUrlTextField(
  {
    index,
    control,
    disabled,
    setValue,
    getValues,
  }: {
    index: number;
    control: Control;
    disabled?: boolean;
    setValue: UseFormSetValue<any>;
    getValues: UseFormGetValues<any>;
    getError: (name: string, index: number) => string;
  }
) {
  const templateId = useWatch({
    control,
    name: `messages.[${index}].template_id`,
  });

  const isDisabled = [4].includes(Number(templateId)) || disabled;

  return (
    <Controller
      control={control}
      name={`messages.[${index}].icon_url`}
      render={(
        {
          field     : {
            ref,
            value,
            ...fieldRest
          },
          fieldState: {
            error: localError
          }
        }
      ) => {
        return (
          <ButtonAdornment
            aspectRatio={1}
            label='Icon URL'
            setValue={setValue}
            name={fieldRest.name}
            disabled={isDisabled}
            getValues={getValues}
            defaultValue={value || ''}
            helperText='96x96px recommended'
            errorMessage={localError?.message}
          />
        )
      }}
    />
  )
}