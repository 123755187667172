import clsx from 'clsx';
import React from 'react';
import {Layout} from 'antd';
import {RouteProps} from 'react-router';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {Footer} from './components/Footer';
import {Header} from './components/Header';
import {Content} from './components/Content';
import {LayoutSider} from './components/LayoutSider';
import {getPrefixCls} from '../../../utils/getPrefixCls';
import {_showPassiveGracePeriodWarning} from '../../../store/selectors/application/acl';
import './index.less';

export function MainLayout(
  {
    appUuid,
    children,
  }: IProps
) {
  const {pathname} = useLocation();
  const showPGPeriodWarning = useSelector(_showPassiveGracePeriodWarning);

  const showPassiveGPBackground = !pathname.includes('manage-account') && showPGPeriodWarning;

  return (
    <Layout
      className={clsx(
        getPrefixCls('mainLayout'),
        showPassiveGPBackground && getPrefixCls('mainLayoutHasBGImage')
      )}
    >
      {!showPassiveGPBackground ? (
        <>
          <Combiner
            appUuid={appUuid}
          />
          <Layout>
            <Content
              appUuid={appUuid}
            >{children}
            </Content>
            <Footer/>
          </Layout>
        </>
      ) : (
        <Content
          appUuid={appUuid}
        >{children}
        </Content>
      )}
    </Layout>
  )
}

function Combiner(
  {
    appUuid,
  }: {
    appUuid: string;
  }
) {
  const [drawerVisible, toggleDrawer] = React.useState<boolean>(false);

  return (
    <>
      <LayoutSider
        appUuid={appUuid}
        toggleDrawer={toggleDrawer}
        drawerVisible={drawerVisible}
      />
      <Header
        appUuid={appUuid}
        toggleDrawer={toggleDrawer}
      />
    </>
  )
}

interface IProps extends RouteProps {
  children?: React.ReactNode;
  appUuid: string | undefined;
}
