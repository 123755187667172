import {notification} from 'antd';
import {_dataOptions} from './index';
import {_upsertEntity} from './index';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';

export const _toggleSubscription = (appUuid: string): Globals.ThunkAction => async (
  dispatch,
  getState,
  {
    api,
  }
) => {
  dispatch(_dataOptions({
    isTogglingSubscription: true,
  }));

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.post(urlHelper(ApiRoutes.TOGGLE_SUBSCRIPTION, {appUuid}));

  if (hasError) {

  } else {
    dispatch(_upsertEntity(data));
  }

  notification.open({
    type   : hasError ? 'error' : 'success',
    message: data?.errors?.limits_notice || message,
  });

  dispatch(_dataOptions({
    isTogglingSubscription: void 0,
  }));
};