import {_fetch} from '../apps/fetch';
import * as Sentry from '@sentry/react';
import {sleep} from '../../../utils/sleep';
import {reactPixel} from '../../../utils/fbq';
import {Globals} from '../../../types/globals';
import {isMobile} from '../../../utils/isMobile';
import {_appsLength} from '../../selectors/apps';
import {ANIMATION_DELAY} from '../../../constants';
import {dataLayer} from '../../../utils/dataLayer';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {ActionTypes} from '../../constants/application';
import {isValidEmail} from '../../../utils/isValidEmail';
import {UseFormSetError} from 'react-hook-form/dist/types/form';
import {ILoginForm} from '../../../pages/Auth/Pages/Login/useLogin';
import {RecaptchaActions} from '../../../constants/recaptchaActions';

export const _login = (
  {
    message: tmpInput,
    ...restFormValues
  }: ILoginForm, {
    setError,
    executeRecaptcha,
  }: {
    setError: UseFormSetError<ILoginForm>;
    executeRecaptcha: (action?: string) => Promise<string>;
  }): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  if (!isValidEmail(restFormValues.email)) {
    await sleep();
    return setError('email', {
      message: 'Please enter a valid email address',
    });
  }

  let token: string | undefined = void 0;

  if (typeof executeRecaptcha === 'function') {
    token = await executeRecaptcha(RecaptchaActions.LOGIN);
  }

  const {
    body: {
      data,
      message,
    },
    hasError,
  } = await api.post(ApiRoutes.LOGIN, {
    ...restFormValues,
    'g-recaptcha-response': token || 'token',
  });

  if (hasError) {
    if (Object.keys(data).length) {
      Object.keys(data).forEach((inputName) => {
        setError(inputName as keyof ILoginForm, {
          message: data[inputName],
        });
      });
    } else {
      setError('message', {
        message,
      });
    }

    Sentry.setExtra('login', {data, message});
    Sentry.captureException({data, message});
  } else {
    try {
      reactPixel('trackCustom', 'Login (App)');
      dataLayer({
        authenticationMethod: 'email',
        event               : 'login',
      });
    } catch (e) {
      console.error(e)
    }

    setTimeout(async function () {
      dispatch({
        payload: {
          authenticated: true,
          accessToken  : data.access_token,
        },
        type   : ActionTypes.AUTHENTICATE,
      });

      await dispatch(_fetch());

      const mobile = isMobile();
      const appsLength = _appsLength(getState());

      if (mobile && !!appsLength) {
        dispatch({
          payload: mobile,
          type   : ActionTypes.IS_MOBILE,
        });
      }
    }, 2 * ANIMATION_DELAY)
  }
};
