import {notification} from 'antd';
import {_me} from '../application/me';
import {_upsertUser} from '../application';
import {Globals} from '../../../types/globals';
import {ActionTypes} from '../../constants/settings';
import {_user} from '../../selectors/application/acl';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {getGeneralForm} from '../../selectors/settings';
import {_apiBaseUrl} from '../../selectors/application';
import {_accessToken} from '../../selectors/application';
import {RecaptchaActions} from '../../../constants/recaptchaActions';

const errorMessages: any = {
  defaultHome         : 'Wrong path',
  displayTimeZone     : 'Wrong time zone',
  lastName            : 'Invalid last name',
  firstName           : 'Invalid first name',
  defaultMessage      : 'Something went wrong',
  email               : 'Invalid email address',
  nameRequired        : 'The name field is required',
  name                : 'Name must contain minimum 3 characters',
  password            : 'Password must be at least 8 characters',
  passwordConfirmation: 'The password and confirmation password must match',
}

export const saveGeneralFormAction = (): any => (async (dispatch: any, getState: any, {
  post,
}: any) => {
  try {
    dispatch(generalFormLoading(true));
    const {
      email,
      contacts,
      last_name,
      first_name,
      default_home,
      display_time_zone_text,
    } = getGeneralForm(getState());

    const apiBaseUrl = _apiBaseUrl(getState());
    const accessToken = _accessToken(getState());
    const url = `${apiBaseUrl}${ApiRoutes.UPDATE_USER_PROFILE}`;

    dispatch({
      type   : ActionTypes.CORE_REQUESTING_GENERAL_SETTINGS_FORM_START,
      payload: {
        url,
        accessToken,
        form: {
          email,
          last_name,
          first_name,
          default_home,
          display_time_zone_text,
        },
      },
    });

    const {
      phone,
      social,
    } = contacts;

    const {
      body: {
        data,
        message,
      },
      hasError,
    } = await post(url, {
      email,
      last_name             : last_name,
      first_name            : first_name,
      default_home          : default_home,
      display_time_zone_text: display_time_zone_text.value,
      contacts              : {
        phone : (phone?.number || phone?.code?.value) && {
          ...phone,
          code: phone?.code?.value
        },
        social: Object.values(social),
      }
    });

    if (hasError) {
      dispatch(generalFormError({
        message: message || errorMessages.defaultMessage,
        ...data.errors,
      }));

      return dispatch(generalFormLoading(false));
    } else {

      await dispatch(_me());
      const user = _user(getState());
      if (user.pending_email) {
        dispatch(handleSettingsAction({
          timer: 60,
        }));
      }

      notification.open({
        type   : hasError ? 'error' : 'success',
        message: hasError ? 'Something went wrong' : 'Success',
      });

      return dispatch(generalFormLoading(false));
    }
  } catch (e) {
    dispatch(generalFormError({
      message: 'Something went wrong',
    }));

    return dispatch(generalFormLoading(false));
  }
});

export const generalFormError = (error: any) => ({
  payload: error,
  type   : ActionTypes.CORE_GENERAL_SETTINGS_FORM_ERROR,
});

export const handleSettingsAction = (payload: any) => ({
  payload,
  type: ActionTypes.CORE_HANDLE_SETTINGS,
});

export const updateTimerAction = () => ({
  type: ActionTypes.UPDATE_TIMER,
});

export const generalFormLoading = (loading: boolean) => ({
  payload: loading,
  type   : ActionTypes.CORE_GENERAL_SETTINGS_FORM_LOADING,
});

export const generalFormSuccess = (message: string | undefined) => ({
  payload: message,
  type   : ActionTypes.CORE_GENERAL_SETTINGS_FORM_SUCCESS,
});

export const handleGeneralFormAction = (formData: Record<string, any>) => ({
  payload: formData,
  type   : ActionTypes.CORE_HANDLE_GENERAL_SETTINGS_FORM,
});

export const resendAction = (
  {
    executeRecaptcha
  }: {
    executeRecaptcha: (action?: string) => void
  }
): Globals.ThunkAction => async (dispatch, getState, {
  post,
}) => {
  dispatch(handleSettingsAction({
    isResending: true,
  }));
  const apiBaseUrl = _apiBaseUrl(getState());
  const url = `${apiBaseUrl}${ApiRoutes.RESEND_EMAIL_CHANGE}`;
  let token = null;

  if (typeof executeRecaptcha === 'function') {
    token = await executeRecaptcha(RecaptchaActions.RESEND)
  }

  const {
    body: {
      message,
    },
    hasError,
  } = await post(url, {
    'g-recaptcha-response': token,
  });

  if (message) {
    notification.open({
      message,
      type: hasError ? 'error' : 'success',
    });
  }

  if (!hasError) {
    dispatch(handleSettingsAction({
      timer: 60,
    }));
  }

  dispatch(handleSettingsAction({
    isResending: void 0,
  }));
};

export const resendAction1 = (
  {
    executeRecaptcha
  }: {
    executeRecaptcha: (action?: string) => void
  }
): Globals.ThunkAction => async (dispatch, getState, {
  post,
}) => {
  dispatch(handleSettingsAction({
    isResending: true,
  }));
  const apiBaseUrl = _apiBaseUrl(getState());
  const url = `${apiBaseUrl}${ApiRoutes.RESEND_REGISTRATION_EMAIL}`;
  const user = _user(getState());

  let token = null;
  if (typeof executeRecaptcha === 'function') {
    token = await executeRecaptcha(RecaptchaActions.RESEND)
  }

  const {
    body: {
      message,
    },
    hasError,
  } = await post(url, {
    'g-recaptcha-response': token,
    email                 : user?.email,
  });

  if (message) {
    notification.open({
      message,
      type: hasError ? 'error' : 'success',
    });
  }

  if (!hasError) {
    dispatch(handleSettingsAction({
      timer: 60,
    }));
  }

  dispatch(handleSettingsAction({
    isResending: void 0,
  }));
};

export const cancelAction = (): Globals.ThunkAction => async (dispatch, getState, {
  post,
}) => {
  dispatch(handleSettingsAction({
    isCanceling: true,
    isResending: true,
    timer      : void 0,
  }));
  const apiBaseUrl = _apiBaseUrl(getState());
  const url = `${apiBaseUrl}${ApiRoutes.CANCEL_EMAIL_CHANGE}`;

  const {
    body: {
      message,
    },
    hasError,
  } = await post(url, {});

  if (message) {
    notification.open({
      message,
      type: hasError ? 'error' : 'success',
    });
  }

  if (!hasError) {
    dispatch(_upsertUser({
      ..._user(getState()),
      pending_email: void 0
    }))
  }

  dispatch(handleSettingsAction({
    isCanceling: void 0,
    isResending: void 0,
  }));
};
