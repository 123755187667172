import React from 'react';
import classNames from 'clsx';
import TableMUI from '@material-ui/core/Table';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  table      : {
    tableLayout                                          : 'fixed',
    borderCollapse                                       : 'separate',
    '& thead th'                                         : {
      position : 'sticky',
      zIndex   : 600,
      fallbacks: {
        position: '-webkit-sticky',
      },
    },
    '& thead th:first-child.MuiTableCell-paddingCheckbox': {
      zIndex : 601,
      '& > *': {
        display: 'none'
      }
    }
  },
  stickyTable: {
    zIndex    : 500,
    position  : 'sticky',
    overflow  : 'visible',
    background: theme.palette.background.paper,
    fallbacks : {
      position: '-webkit-sticky',
    }
  },
  headTable  : {
    top: 0,
  },
  footTable  : {
    bottom: 0,
  },
}));

export default function TableBase(
  {
    use,
    children,
    tableRef,
    className,
    ...restProps
  }: any
) {
  const classes = useStyles();
  return (
    <TableMUI
      stickyHeader
      ref={tableRef}
      className={classNames(
        {
          [classes.table]      : true,
          [classes.stickyTable]: !!use,
          [classes.headTable]  : use === 'head',
          [classes.footTable]  : use === 'foot'
        },
        className
      )}
      {...restProps}
    >{children}
    </TableMUI>
  )
}
