import React from 'react';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {_emptyForm} from '../../../../store/actions/application';
import {_formInfo} from '../../../../store/selectors/application';
import {_confirmEmailChange} from '../../../../store/actions/application/confirmEmailChange';

export function useConfirmEmailChange() {
  const dispatch = useDispatch();
  const formInfo = useSelector(_formInfo);
  const {token} = useParams<{ token: string }>();
  const {executeRecaptcha} = useGoogleReCaptcha();

  React.useEffect(() => {
    return function () {
      dispatch(_emptyForm());
    }
  }, [
    dispatch,
  ]);

  React.useEffect(() => {
    if (typeof executeRecaptcha === 'function') {
      dispatch(_confirmEmailChange({
        token,
        executeRecaptcha,
        redirect: () => {
          window.location.replace('/');
        }
      }))
    }
  }, [
    token,
    dispatch,
    executeRecaptcha,
  ]);

  return {
    formInfo,
  }
}