import {Application} from '../types/application';
import {Permissions} from '../constants/permission';

export function checkPermissions(
  {
    user,
    aclKey,
  }: {
    aclKey: string;
    user: Application.User;
  }
) {
  const userAcl = user.acl;

  let displayUpgrade = false;
  let visible = !!userAcl?.[aclKey]?.fg?.[Permissions.VISIBLE];

  if (visible) {
    visible = !!userAcl?.[aclKey]?.fg?.[Permissions.READ];
  }

  if (aclKey === undefined) {
    visible = true;
  }

  return {
    visible,
    displayUpgrade,
  }
}
