import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Row} from 'antd';
import {_fetchTags} from '../../../store/actions/tags';
import DynamicSelect from '../../../components/antd/DynamicSelect';
import {_updateFilters, _cleanUp} from '../../../store/actions/builder/builders/actions';
import {getFilters} from '../../../store/selectors/builder';

const FilterSection: React.FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);

  const onTagsChange = (value: string[]) => {
    dispatch(_cleanUp());
    dispatch(_updateFilters({
      parentTags: value,
    }));
  };

  return (
    <Row className='m-b-5 m-t-5' >
      <DynamicSelect
        titleKey='id'
        valueKey='id'
        dataKey='rows'
        mode='multiple'
        label='Tags'
        layout='vertical'
        placeholder='Filter by Tags'
        maxTagCount='responsive'
        value={filters.parentTags}
        onChange={onTagsChange}
        fetchData={_fetchTags}
        style={{width: 300}}
      />
    </Row>
  )
};

export default FilterSection;
