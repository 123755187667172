import {_get} from './get';
import {History} from 'history';
import {_saveStep} from './saveStep';
import ym from 'react-yandex-metrika';
import {splice} from '../../../utils/splice';
import {_fetch as _fetchApps} from './fetch';
import {Globals} from '../../../types/globals';
import {UseFormSetError} from 'react-hook-form';
import {isMobile} from '../../../utils/isMobile';
import {_appsLength} from '../../selectors/apps';
import {_creationStep} from '../../selectors/apps';
import {dataLayer} from '../../../utils/dataLayer';
import {urlHelper} from '../../../utils/urlHelper';
import {AppRoutes} from '../../../constants/appRoutes';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {ActionTypes as AppActionTypes} from '../../constants/application';

export const _create = (formValues: Record<string, any>, {
  history,
  setError,
}: {
  history: History,
  setError: UseFormSetError<Record<string, any>>;
}): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  const {
    uuid,
    ...rest
  } = formValues;

  const {
    hasError,
    body: {
      data,
    },
  } = await api.post(!!uuid ? urlHelper(ApiRoutes.UPDATE_APP, {appUuid: uuid}) : ApiRoutes.CREATE_APP, {
    ...rest
  });

  if (!hasError) {
    if (!uuid) {
      dataLayer({
        app_uuid: data.uuid,
        event   : 'onboarding_app_create',
      });
      dataLayer({
        event       : 'app_create',
        app_uuid    : data.uuid,
        app_title   : data.title,
        app_site_url: data.domain,
      });

      ym('reachGoal', 'onboarding_app_create', {
        app_uuid: data.uuid,
      });
    }

    await dispatch(_get(data.uuid, {
      skipDelay  : true,
      skipLoading: true,
    }));

    const creationStep = _creationStep(getState());
    if (creationStep >= 1) {
      await dispatch(_fetchApps());
    } else {
      await Promise.all([
        dispatch(_fetchApps()),
        dispatch(_saveStep({
          creationStep: 1,
          appUuid     : data.uuid,
        }))
      ]);
    }

    const mobile = isMobile();
    const appsLength = _appsLength(getState());
    if ((appsLength === 1) && mobile) {
      dispatch({
        payload: mobile,
        type   : AppActionTypes.IS_MOBILE,
      });
    }

    history.replace(urlHelper(AppRoutes.APPS_EDIT_APP, {appUuid: data.uuid}));
    history.push(urlHelper(AppRoutes.APPS_INTEGRATE_APP, {appUuid: data.uuid}));
  } else {
    Object.keys(data).forEach((inputName: string) => {
      if (!inputName.startsWith('var')) {
        setError(inputName, {
          message: data[inputName],
        })
      } else if (inputName.endsWith('.name')) {
        let newName: any = splice(inputName, '.', 3).replace('var', 'advanced_parameters');

        newName = newName.split('.');

        newName = [
          newName[0],
          Number(newName[1]) - 1,
          newName[2],
        ].join('.');

        setError(newName, {
          message: data[inputName],
        })
      }
    });
  }
};
