import React, { useState } from 'react';
import {useParams } from 'react-router';
import {useDispatch} from 'react-redux';
import CreateOrUpdateForms from './CreateOrUpdateForms';
import {getPerAutomationData} from '../../../../store/actions/automation';
import {Spinner} from '../../../../components/antd/Spinner';

interface IProps {
  headerText: string;
  isActionClone?: boolean;
};

const UpdateAutomation: React.FC<IProps> = (props) => {
  const {id, appUuid} = useParams<{ appUuid: string, id?: string }>();
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      if (appUuid && id) {
        const res = await dispatch(getPerAutomationData(appUuid, id));
        setData(res);
      }
    })();
  }, [id, appUuid, dispatch]);

  if (!data) {
    return (
      <Spinner/>
    );
  }

  return (
    <CreateOrUpdateForms
      {...props}
      data={data}
    />
  );
};

export default UpdateAutomation;
