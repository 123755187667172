import clsx from 'clsx';
import React from 'react';
import {Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import createStyles from '@material-ui/core/styles/createStyles';

const BorderLinearProgress = withStyles((theme: Theme) => createStyles({
  root        : {
    borderRadius: 0,
    height      : 10,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar         : {
    borderRadius   : 0,
    backgroundColor: '#1a90ff',
  }
}))(LinearProgress);

const useStyles = makeStyles((theme: Theme) => ({
  progressRoot      : {
    marginBottom: 12,
  },
  progressPercent   : {
    marginLeft: theme.spacing(1),
  },
  progressTypography: {
    fontSize: 14,
  },
  progressBody      : {
    marginTop     : 4,
    display       : 'flex',
    justifyContent: 'space-between',
  },
  progressNumbers   : {
    display: 'flex',
  },
  progressValue     : {
    color: '#1a90ff',
  },
}));


export default function Progress(
  {
    value,
    label,
    percent,
  }: {
    value: number;
    label: string;
    percent: number;
  }
) {
  const classes = useStyles();

  return (
    <Typography
      component='div'
      className={classes.progressRoot}
    >
      <BorderLinearProgress
        value={percent}
        variant='determinate'
      />
      <Typography
        component='div'
        className={classes.progressBody}
      >
        <Typography
          color='primary'
          className={classes.progressTypography}
        >{label}
        </Typography>
        <Typography
          component='div'
          className={classes.progressNumbers}
        >
          <Typography
            color='primary'
            className={clsx(classes.progressTypography, classes.progressValue)}
          >{value}
          </Typography>
          <Typography
            color='primary'
            className={clsx(classes.progressTypography, classes.progressPercent)}
          >{percent}%
          </Typography>
        </Typography>
      </Typography>
    </Typography>
  );
}
