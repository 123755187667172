import {getState} from '../index';
import {createSelector} from 'reselect';

export const getSettings = createSelector(getState, (state) => {
  return state.settings;
});

export const getIsResending = createSelector(getSettings, (settings) => {
  return !!settings.isResending;
});

export const getIsCanceling = createSelector(getSettings, (settings) => {
  return !!settings.isCanceling;
});

export const getTimer = createSelector(getSettings, (settings) => {
  return settings.timer;
});

export const getGeneral = createSelector(getSettings, (settings) => {
  return settings.general;
});

export const getGeneralForm = createSelector(getGeneral, ({formData}) => {
  return formData;
});

export const getGeneralFormError = createSelector(getGeneral, (settings) => {
  return settings.error;
});

export const getGeneralFormIsLoading = createSelector(getGeneral, (settings) => {
  return !!settings.loading;
});
