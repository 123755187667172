import React from 'react';
import {Tooltip} from 'antd';
import {useSelector} from 'react-redux';
import {nFormatter} from '../../../../../../utils';
import {formatWithCommas} from '../../../../../../utils';
import {Segments} from '../../../../../../types/segments';
import {Text} from '../../../../../../components/antd/Typography';
import {_userId} from '../../../../../../store/selectors/application/acl';

export function Name({value, record}: { value: string, record: Segments.Entity }) {
  const userId = useSelector(_userId);

  return (
    <div>
      <div>
        <Text
          strong
          ellipsis
          fontSize='large'
        >{value}
        </Text>
      </div>
      {(userId === 1) && (
        <div
          className='d-flex align-items-center'
        >
          {(record.count_users || record.count_users === 0) && (
            <Tooltip
              title={formatWithCommas(record.count_users)}
            >
              <Text
                strong
              >{nFormatter(record.count_users, 2)}
              </Text>
            </Tooltip>
          )}
          <Text
            className='m-l-1'
          >{(record.count_users || record.count_users === 0) ? 'Subscribers' : 'Processing'}
          </Text>
        </div>
      )}
    </div>
  )
}