import clsx from 'clsx';
import React from 'react';
import {Alert} from 'antd';
import {Steps} from 'antd';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {urlHelper} from '../../../../utils/urlHelper';
import {AppRoutes} from '../../../../constants/appRoutes';
import {ErrorCodes} from '../../../../constants/errorCodes';
import {_errorCode} from '../../../../store/selectors/apps';
import {_appsLength} from '../../../../store/selectors/apps';
import {_creationStep} from '../../../../store/selectors/apps';
import {_isAdmin} from '../../../../store/selectors/application/acl';
import {_integrationIsCompleted} from '../../../../store/selectors/apps';
import {_showPlanChange} from '../../../../store/selectors/application/acl';
import './index.less';

export function IncompleteApp() {
  const {pathname} = useLocation();
  const isAdmin = useSelector(_isAdmin);
  const errorCode = useSelector(_errorCode);
  const appsLength = useSelector(_appsLength);
  const creationStep = useSelector(_creationStep);
  const showPlanChange = useSelector(_showPlanChange);
  const integrationIsCompleted = useSelector(_integrationIsCompleted);

  const {
    plan,
    appUuid,
  } = useParams<{ appUuid?: string; plan?: string }>();

  const hideAlert = React.useMemo(() => {
    return [
        AppRoutes.APPS_CREATE,
        urlHelper(AppRoutes.CHECKOUT, {plan}),
        urlHelper(AppRoutes.APPS_EDIT_APP, {appUuid}),
        urlHelper(AppRoutes.APPS_CREATE_APP, {appUuid}),
        urlHelper(AppRoutes.CHECKOUT_APP, {appUuid, plan}),
        urlHelper(AppRoutes.APPS_INTEGRATE_APP, {appUuid}),
        urlHelper(AppRoutes.APPS_PAY_AND_LAUNCH_APP, {appUuid}),
      ].includes(pathname) ||
      isAdmin ||
      (!!appsLength && !appUuid) ||
      (errorCode === ErrorCodes.planExpiration) ||
      (!showPlanChange && (creationStep === 2) && integrationIsCompleted)
  }, [
    plan,
    isAdmin,
    appUuid,
    pathname,
    errorCode,
    appsLength,
    creationStep,
    showPlanChange,
    integrationIsCompleted,
  ]);

  const to = React.useMemo(() => {
    switch (creationStep) {
      case 1: {
        return `${urlHelper(AppRoutes.APPS_INTEGRATE_APP, {appUuid})}?ti=true`;
      }
      case 2: {
        if (!integrationIsCompleted) {
          return `${urlHelper(AppRoutes.APPS_INTEGRATE_APP, {appUuid})}?ti=true`;
        } else {
          return urlHelper(AppRoutes.APPS_PAY_AND_LAUNCH_APP, {appUuid});
        }
      }
      default: {
        return appUuid ? urlHelper(AppRoutes.APPS_CREATE_APP, {appUuid}) : AppRoutes.APPS_CREATE;
      }
    }
  }, [
    appUuid,
    creationStep,
    integrationIsCompleted,
  ]);

  return hideAlert ? null : (
    <Link
      to={to}
    >
      <Alert
        type='error'
        message={
          <Steps
            responsive
            size='small'
            status='error'
            current={integrationIsCompleted ? creationStep : (creationStep - 1) || 1}
            className={clsx('m-x-auto', showPlanChange ? 'max-width-75' : 'max-width-50')}
          >
            <Steps.Step
              title='Add Your Site'
            />
            <Steps.Step
              title='Integrate Smartpush'
              status={!integrationIsCompleted ? 'error' : void 0}
            />
            {showPlanChange && (
              <Steps.Step
                title='Pay and Launch'
              />
            )}
          </Steps>
        }
      />
    </Link>
  )
}
