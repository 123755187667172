import {Store} from 'redux';
import {compose} from 'redux';
import {Api} from './services';
import thunk from 'redux-thunk';
import {ApiV2} from './services';
import {createStore} from 'redux';
import reducers from './reducers';
import {applyMiddleware} from 'redux';
import {Globals} from '../types/globals';
import {persistStore} from 'redux-persist';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {createMainMiddleware} from './middlewares';

export function configureStore() {
  const persistConfig = {
    storage,
    key      : 'root',
    whitelist: [] as Array<any>,
  };

  let composeEnhancers = compose;
  if (process.env.NODE_ENV === 'development') {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }
  const persistedReducer = persistReducer(persistConfig, reducers());

  const api = new Api();
  const apiV2 = new ApiV2();
  const store: Store<Globals.RootReducer> = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(
        createMainMiddleware(),
        thunk.withExtraArgument({
          api    : apiV2,
          get    : api.get,
          post   : api.post,
          upload : api.upload,
          request: api.request,
          getList: api.getList,
        })
      )
    )
  );
  api.setStore(store);
  apiV2.store = store;

  if (process.env.NODE_ENV === 'development') {
    (window as any).store = store;
  }

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
}
