import React from 'react';
import {RGBColor} from 'react-color';
import {SketchPicker} from 'react-color';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {UseFormGetValues} from 'react-hook-form/dist/types/form';

const useStyles = makeStyles(() => ({
  menuListProps: {
    padding: 0,
  },
  paperProps   : {
    borderRadius: 0,
    border      : '1px solid #F5F5F5',
  },
}));

export function ColorAdornment(
  {
    name,
    disabled,
    setValue,
    getValues,
    defaultValue,
  }: {
    name: string;
    disabled?: boolean;
    defaultValue?: string;
    setValue: UseFormSetValue<Record<string, any>>;
    getValues: UseFormGetValues<Record<string, any>>;
  }
) {
  const classes = useStyles();
  const [
    anchorSketchPicker,
    setAnchorSketchPicker
  ] = React.useState<undefined | HTMLElement>(void 0);

  const value = getValues(name) || defaultValue;

  const onClose = React.useCallback(() => {
    setAnchorSketchPicker(void 0)
  }, []);

  const iconButtonOnClick = React.useCallback((e) => {
    setAnchorSketchPicker(e.currentTarget)
  }, []);

  const onChangeComplete = React.useCallback(({rgb}) => {
    setValue(name, `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`, {
      shouldDirty: true,
    })
  }, [
    name,
    setValue,
  ]);

  return (
    <>
      <InputAdornment
        position='start'
      >
        <IconButton
          size='small'
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disabled={disabled}
          onClick={iconButtonOnClick}
        >
          <ColorLensIcon
            fontSize='small'
            color={!!disabled ? 'disabled' : undefined}
            style={!disabled ? {
              color: (value !== 'rgba(255,255,255,1)') ? value : ''
            } : void 0}
          />
        </IconButton>
      </InputAdornment>
      {anchorSketchPicker && (
        <Menu
          onClose={onClose}
          transitionDuration={0}
          anchorEl={anchorSketchPicker}
          open={Boolean(anchorSketchPicker)}
          MenuListProps={{className: classes.menuListProps}}
          PaperProps={{elevation: 0, className: classes.paperProps}}
        >
          <Typography
            component='div'
          >
            <SketchPicker
              color={rgbStringToRGBColor(value)}
              onChangeComplete={onChangeComplete}
            />
          </Typography>
        </Menu>
      )}
    </>
  )
}

function rgbStringToRGBColor(rgb: string): RGBColor {
  const rgbArray = rgb.replace(/[^\d.,]/g, '').split(',');

  return {
    r: Number(rgbArray[0]),
    g: Number(rgbArray[1]),
    b: Number(rgbArray[2]),
    a: Number(rgbArray[3]),
  };
}