import {ActionTypes} from '../../constants/users';
import {Users} from '../../../types/users';

export const _setSubScriptionData = (data: any) => ({
  payload: data,
  type   : ActionTypes.USERS_SET_SUBSCRIPTION_PLANS,
});

export const _setFeatureGroupsData = (data: any) => ({
  payload: data,
  type   : ActionTypes.USERS_SET_FEATURE_GROUPS,
});

export const _setUsersData = (data: Users.Entity[], total?: number) => ({
  payload: {data, total},
  type   : ActionTypes.USERS_SET_DATA,
});

export const _deleteUserData = (data: string) => ({
  payload: data,
  type   : ActionTypes.USERS_DELETE_DATA,
});

export const _setLoading = (data?: boolean) => ({
  payload: data,
  type   : ActionTypes.USERS_SET_LOADING,
});

export const _updateFilters = (data: {[key: string]: any}) => ({
  payload: data,
  type   : ActionTypes.USERS_UPDATE_FILTERS,
});

export const _cleanUp = () => ({
  type   : ActionTypes.USERS_CLEANUP,
});