import React from 'react';
import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {urlHelper} from '../../../../../utils/urlHelper';
import {DEFAULT_ICON_URL} from '../../../../../constants';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {_currentApp} from '../../../../../store/selectors/apps';
import {_create} from '../../../../../store/actions/apps/create';
import {_cdnUrl} from '../../../../../store/selectors/application/acl';

export interface ICreateAppForm {
  uuid: string;
  title: string;
  domain: string;
  default_url: string;
  limits_notice: string;
  gdpr_compliance: boolean;
  default_icon_url: string;
}

const fixedData: Record<string, boolean> = {
  webhooks    : false,
  not_https   : false,
  force_prompt: false,
};

const advanced_parameters = [
  {
    track    : true,
    name     : 'zid',
    parameter: 'zid',
    column   : 'var5',
  },
  {
    track    : true,
    name     : 'bid',
    parameter: 'bid',
    column   : 'var6',
  },
  {
    track    : true,
    column   : 'var8',
    name     : 'data2',
    parameter: 'data2',
  },
  {
    track    : true,
    column   : 'var9',
    name     : 'data3',
    parameter: 'data3',
  },
  {
    track    : true,
    name     : 'data4',
    parameter: 'data4',
    column   : 'var10',
  },
  {
    track    : true,
    column   : 'var7',
    name     : 'email',
    parameter: 'email',
  },
  {
    track    : true,
    column   : 'var1',
    name     : 'utm_source',
    parameter: 'utm_source',
  },
  {
    track    : true,
    column   : 'var2',
    name     : 'utm_medium',
    parameter: 'utm_medium',
  },
  {
    track    : true,
    column   : 'var4',
    name     : 'utm_content',
    parameter: 'utm_content',
  },
  {
    track    : true,
    column   : 'var3',
    name     : 'utm_campaign',
    parameter: 'utm_campaign',
  },
]

export function useUpsertApp() {
  const history = useHistory();
  const dispatch = useDispatch();
  const cdnUrl = useSelector(_cdnUrl);
  const currentApp = useSelector(_currentApp);
  const {appUuid} = useParams<{ appUuid?: string }>();

  const {
    reset,
    setError,
    setValue,
    handleSubmit,
    ...formRest
  } = useForm<ICreateAppForm>();
  const isCreating = history.location.pathname.includes('apps/create');

  React.useEffect(() => {
    if (
      currentApp?.uuid &&
      history.location.pathname.includes('apps/edit')
    ) {
      reset({
        uuid            : currentApp.uuid,
        domain          : currentApp.domain,
        default_url     : currentApp.default_url,
        default_icon_url: currentApp.default_icon_url,
        gdpr_compliance : !!currentApp?.details?.gdpr_compliance,
      }, {
        keepDirty: false,
      });

      setTimeout(function () {
        setValue('title', currentApp.title, {
          shouldDirty: true,
        });
      }, 0);
    } else {
      if (
        !currentApp?.uuid ||
        (history.location.pathname === urlHelper(AppRoutes.APPS_CREATE_APP, {appUuid: currentApp?.uuid}))
      ) {
        reset({
          default_icon_url: urlHelper(DEFAULT_ICON_URL, {CDN_URL: cdnUrl})
        }, {
          keepDirty: false,
        })
      }
    }
  }, [
    reset,
    cdnUrl,
    appUuid,
    history,
    setValue,
    currentApp?.uuid,
    currentApp?.title,
    currentApp?.domain,
    currentApp?.default_url,
    currentApp?.default_icon_url,
    currentApp?.details?.gdpr_compliance,
  ]);

  const onSubmit = React.useCallback((formValues: ICreateAppForm) => {
    return dispatch(_create({
      ...fixedData,
      ...formValues,
      advanced_parameters,
    }, {
      history,
      setError: setError as any,
    }));
  }, [
    history,
    setError,
    dispatch,
  ]);

  return {
    ...formRest,
    isCreating,
    setValue: setValue,
    setError: setError,
    submit  : handleSubmit(onSubmit),
  }
}
