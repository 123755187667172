import clsx from 'clsx';
import axios from 'axios';
import {Spin} from 'antd';
import React from 'react';
import {Select} from 'antd';
import {Button} from 'antd';
import {Divider} from 'antd';
import {Canceler} from 'axios';
import {Typography} from 'antd';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Text} from '../../../../../Typography';
import useMediaQuery from 'use-media-antd-query';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import {privatePrefix} from '../../../../../../../constants';
import {urlHelper} from '../../../../../../../utils/urlHelper';
import {dataLayer} from '../../../../../../../utils/dataLayer';
import {_apps} from '../../../../../../../store/selectors/apps';
import {AppRoutes} from '../../../../../../../constants/appRoutes';
import {getPrefixCls} from '../../../../../../../utils/getPrefixCls';
import {_fetch} from '../../../../../../../store/actions/apps/fetch';
import {_appsLength} from '../../../../../../../store/selectors/apps';
import {_currentApp} from '../../../../../../../store/selectors/apps';
import {FiniteStates} from '../../../../../../../constants/finiteStates';
import {_temporaryTitle} from '../../../../../../../store/selectors/apps';
import {_fetchingAppState} from '../../../../../../../store/selectors/apps';
import {_userId} from '../../../../../../../store/selectors/application/acl';
import {_fetchingAppsState} from '../../../../../../../store/selectors/apps';
import {_isAdmin} from '../../../../../../../store/selectors/application/acl';
import {_canCreateApp} from '../../../../../../../store/selectors/application/acl';
import {_maxAppsCount} from '../../../../../../../store/selectors/application/acl';

let cancel: Canceler | undefined;
const CancelToken = axios.CancelToken;

export function useAllApps(
  {
    appUuid,
  }: {
    appUuid: string | undefined;
  }
) {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const apps = useSelector(_apps);
  const colSize = useMediaQuery();
  const userId = useSelector(_userId);
  const isAdmin = useSelector(_isAdmin);
  const appsLength = useSelector(_appsLength);
  const currentApp = useSelector(_currentApp);
  const canCreateApp = useSelector(_canCreateApp);
  const maxAppsCount = useSelector(_maxAppsCount);
  const temporaryTitle = useSelector(_temporaryTitle);
  const fetchingAppState = useSelector(_fetchingAppState);
  const fetchingAppsState = useSelector(_fetchingAppsState);
  const isCreatingApp = [
    AppRoutes.APPS_CREATE,
    urlHelper(AppRoutes.APPS_CREATE_APP, {appUuid}),
  ].includes(history.location.pathname);

  const [selectStyle, setSelectStyle] = React.useState<React.CSSProperties | undefined>(void 0);

  const onClick = React.useCallback(() => {
    if (typeof document !== undefined) {
      document.body.style.overflow = 'initial';
    }

    history.push(urlHelper(AppRoutes.APPS_CREATE_APP, {
      appUuid
    }))
  }, [
    history,
    appUuid,
  ]);

  const selectOnSearch = React.useCallback((value) => {
    if (value) {
      setSelectStyle({
        minWidth: document.getElementsByClassName('ant-select')[0]?.clientWidth
      })
    } else {
      setSelectStyle(void 0);
    }
  }, []);

  const hideCreateApp = React.useMemo(() => {
    let visible = false;
    if (fetchingAppsState === FiniteStates.SUCCESS) {
      if (maxAppsCount !== undefined) {
        const filteredAllIds = apps?.filter((cApp: Record<string, any>) => {
          if (isAdmin) {
            return false;
          }
          return Number(cApp?.user_id) === userId;
        });
        if (filteredAllIds.length >= maxAppsCount) {
          visible = true;
        }
      }
    }

    return visible;
  }, [
    apps,
    userId,
    isAdmin,
    maxAppsCount,
    fetchingAppsState,
  ]);

  const dropdownRender = React.useCallback((menu) => {
    if (fetchingAppsState === FiniteStates.LOADING) {
      return (
        <div>
          {menu}
          <div className='m-t-2 d-flex align-items-center justify-content-center'>
            <Spin
              size='small'
            />
          </div>
        </div>
      )
    }
    return (
      <div>
        {menu}
        {canCreateApp && (
          hideCreateApp ? (
            <>
              <Divider
                className='dropdown-divider'
              />
              <div
                className='new-app-button'
              >
                <Text
                  align='center'
                  fontWeight='medium'
                >{t('per_account_max_count', {count: maxAppsCount})}
                </Text>
              </div>
            </>
          ) : (
            <>
              <Divider
                className='dropdown-divider'
              />
              <div
                className='new-app-button'
              >
                <Button
                  type='link'
                  size='small'
                  onClick={onClick}
                  icon={<PlusOutlined/>}
                >New App
                </Button>
              </div>
            </>
          )
        )}
      </div>
    )
  }, [
    t,
    onClick,
    canCreateApp,
    maxAppsCount,
    hideCreateApp,
    fetchingAppsState,
  ]);

  const onChange = React.useCallback((uuid: string) => {
    const isEqual = [
      urlHelper(AppRoutes.APPS_EDIT_APP, {appUuid}),
      urlHelper(AppRoutes.APPS_CREATE_APP, {appUuid}),
      urlHelper(AppRoutes.APPS_INTEGRATE_APP, {appUuid}),
      urlHelper(AppRoutes.APPS_PAY_AND_LAUNCH_APP, {appUuid}),
    ].includes(history.location.pathname);

    const path = history.location.pathname;
    const segments = path.split('/').filter(p => !!p && (p !== privatePrefix));

    segments.shift();
    segments.unshift(uuid);

    const tmpSegments = [...segments].reverse();

    if ([
      'info',
      'edit',
      'clone',
    ].includes(tmpSegments?.[0])) {
      segments.pop();
      segments.pop();
    }

    if (isEqual) {
      history.push(urlHelper(AppRoutes.ANALYTICS_REPORTS, {
        appUuid: uuid,
      }));
    } else {
      segments.unshift(privatePrefix);
      history.push(`/${segments.join('/')}`);
    }
  }, [
    history,
    appUuid,
  ])

  const onDropdownVisibleChange = React.useCallback((visible) => {
    if (visible) {
      if (typeof document !== undefined) {
        document.body.style.overflow = 'hidden';
      }

      if (!appsLength) {
        dispatch(_fetch(1000, {
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          })
        }))
      }
    } else {
      if (typeof document !== undefined) {
        document.body.style.overflow = 'initial';
      }

      if (typeof cancel === 'function') {
        cancel();
      }
    }
  }, [
    dispatch,
    appsLength,
  ]);

  const filterOption = React.useCallback((value: string, option: any) => {
    return option.title.toLowerCase().includes(value.toLowerCase())
  }, []);

  const options = React.useMemo(() => apps?.map((cApp: Record<string, any>) => {
    const highlighted = [
      AppRoutes.APPS_CREATE,
      urlHelper(AppRoutes.APPS_EDIT_APP, {appUuid}),
      urlHelper(AppRoutes.APPS_CREATE_APP, {appUuid}),
      urlHelper(AppRoutes.APPS_INTEGRATE_APP, {appUuid}),
      urlHelper(AppRoutes.APPS_PAY_AND_LAUNCH_APP, {appUuid}),
    ].includes(history.location.pathname);

    return (
      <Select.Option
        key={cApp.uuid}
        value={cApp.uuid}
        title={cApp.title}
      >
        <div
          className='d-flex align-items-center'
        >
          <Typography.Text
            ellipsis
            className={clsx((highlighted && (appUuid === cApp.uuid)) && getPrefixCls('highlighted'))}
          >{cApp.title}
          </Typography.Text>
          <Typography.Text
            className={clsx((highlighted && (appUuid === cApp.uuid)) && getPrefixCls('highlighted'))}
          >({cApp.active_users})
          </Typography.Text>
        </div>
      </Select.Option>
    )
  }), [
    apps,
    appUuid,
    history.location.pathname,
  ]);

  React.useEffect(() => {
    if (currentApp?.uuid) {
      dataLayer({
        event       : 'app_change',
        app_uuid    : currentApp.uuid,
        app_title   : currentApp.title,
        app_site_url: currentApp.default_url,
      })
    }
  }, [
    currentApp?.uuid,
    currentApp?.title,
    currentApp?.default_url,
  ]);

  return {
    apps,
    options,
    appUuid,
    onChange,
    selectStyle,
    filterOption,
    isCreatingApp,
    hideCreateApp,
    selectOnSearch,
    dropdownRender,
    temporaryTitle,
    fetchingAppState,
    fetchingAppsState,
    push    : history.push,
    onDropdownVisibleChange,
    isMobile: [
      'sm',
      'xs',
      'md',
    ].includes(colSize),
  }
}
