import clsx from 'clsx';
import React from 'react';
import Img from '../Img';
import Search from '../Search';
import Dialogs from '../Dialogs';
import {stringify} from 'querystring';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {IconButton} from '../../IconButton';
import {useTranslation} from 'react-i18next';
import Select from '@material-ui/core/Select';
import Hidden from '@material-ui/core/Hidden';
import {Theme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import RefreshIcon from '@material-ui/icons/Refresh';
import Pagination from 'material-ui-flat-pagination';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '../../CircularProgress';
import {getById} from '../../../store/selectors/gallery';
import {getAllIds} from '../../../store/selectors/gallery';
import {fetchAction} from '../../../store/actions/gallery';
import {getOptions} from '../../../store/selectors/gallery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {TableActions} from '../../../constants/tableActions';
import {optionsAction} from '../../../store/actions/gallery';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {getIsLoading} from '../../../store/selectors/gallery';
import {toggleDialogAction} from '../../../store/actions/gallery';
import {_apiBaseUrl} from '../../../store/selectors/application';
import {PageSizeSelector} from '../../ReduxTable/PageSizeSelector';

const useStyles = makeStyles((theme: Theme) => ({
  div                : {
    cursor                : 'pointer',
    position              : 'relative',
    display               : 'inline-flex',
    '&:hover:not(.active)': {
      '& div.icon:not(.active)': {
        display        : 'block',
        backgroundColor: 'rgba(255,255,255,.5)',
      }
    },
    '&.active'            : {
      '& div.icon.active': {
        display        : 'block',
        backgroundColor: 'rgb(246, 246, 246)',
      }
    },
  },
  root               : {
    display : 'flex',
    flexWrap: 'wrap',
  },
  icon               : {
    bottom      : 10,
    right       : 10,
    zIndex      : 1500,
    borderRadius: '50%',
    margin      : 'auto',
    display     : 'none',
    position    : 'absolute',
    height      : 'max-content',
    width       : 'max-content',
  },
  selectedIcon       : {
    display: 'flex',
  },
  selected           : {
    transform: 'scale(.8)',
  },
  selectedDiv        : {
    backgroundColor: 'rgb(238, 238, 238)',
  },
  select             : {
    paddingTop   : 8,
    paddingBottom: 8,
  },
  sorting            : {
    marginRight: 8,
    display    : 'flex',
    alignItems : 'center',
  },
  rootContainer      : {
    display        : 'flex',
    flexDirection  : 'column',
    backgroundColor: 'inherit',
    justifyContent : 'space-between',
    minHeight      : 'calc(100vh - 250px)',
  },
  listToolbarRoot    : {
    display       : 'flex',
    width         : '100%',
    margin        : '0 auto',
    alignItems    : 'center',
    justifyContent: 'space-between',
    borderBottom  : '1px solid #bdbdbd',
    paddingBottom : theme.spacing(1),
  },
  toolbarLeftActions : {
    display             : 'flex',
    alignItems          : 'center',
    justifyContent      : 'flex-start',
    '& > div'           : {
      marginRight: 12,
    },
    '& > div:last-child': {
      marginRight: 0,
    },
  },
  paginationContainer: {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'center',
    marginTop     : theme.spacing(3),
  },
  sortDirection      : {
    marginLeft: 8,
    cursor    : 'pointer',
    transition: theme.transitions.create('transform')
  },
  rotate             : {
    transform: 'rotate(180deg)',
  },
  stickyHeader       : {
    top            : 0,
    zIndex         : 10,
    position       : 'sticky',
    backgroundColor: '#ffffff',
  },
  wrapper            : {
    flexGrow       : 1,
    overflowY      : 'scroll',
    overflowX      : 'hidden',
    backgroundColor: 'inherit',
    height         : 'calc(100vh - 380px)',
  },
  selectOutlined     : {
    borderRadius                  : 0,
    width                         : 190,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    }
  },
  tooltip            : {
    display: 'flex',
  },
  typographySortBy   : {
    marginRight: 8,
  },
  typographyWrapper  : {
    margin: '0 auto',
  },
  typographyNoData   : {
    marginTop   : theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  noDataContainer    : {
    display      : 'flex',
    flexDirection: 'column',
  },
}));

export default function List(
  {
    width,
    onClick,
    selected,
    aspectRatio,
    onCloseGallery,
  }: {
    width?: any;
    selected?: any;
    aspectRatio: number;
    onCloseGallery?: any;
    onClick?: (selected: any) => any;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const byId = useSelector(getById);
  const allIds = useSelector(getAllIds);
  const options = useSelector(getOptions);
  const loading = useSelector(getIsLoading);
  const apiBaseUrl = useSelector(_apiBaseUrl);

  React.useEffect(() => {
    const queryString = {
      pageSize  : options.pageSize,
      sortName  : options.sortName,
      sortOrder : options.sortOrder,
      pageNumber: options.pageNumber,
      searchText: options.searchText,
    };

    dispatch(optionsAction({lastQuery: `${apiBaseUrl}/media?${stringify(queryString)}`}));

    dispatch(fetchAction());
  }, [
    dispatch,
    apiBaseUrl,
    options.pageSize,
    options.sortName,
    options.sortOrder,
    options.searchText,
    options.pageNumber,
  ]);

  const onCrop = React.useCallback((e) => {
    const {
      thumbnail,
      ...rest
    } = byId[e.currentTarget.getAttribute('data-id')];
    dispatch(toggleDialogAction({
      dialog: TableActions.CROP,
      data  : {
        thumbnail: String(selected).includes(thumbnail) ? selected : thumbnail,
        ...rest
      }
    }))
  }, [
    byId,
    selected,
    dispatch,
  ]);

  const loadData = React.useCallback(() => {
    dispatch(fetchAction());
  }, [
    dispatch,
  ]);

  const onDelete = React.useCallback((e) => {
    let tmp: any = byId[e.currentTarget.getAttribute('data-id')];
    if (selected.includes(e.currentTarget.getAttribute('data-value'))) {
      tmp = {
        ...tmp,
        destroy: () => onClick('')
      }
    }
    dispatch(toggleDialogAction({
      dialog: TableActions.DELETE,
      data  : tmp
    }))
  }, [
    byId,
    onClick,
    dispatch,
    selected,
  ]);

  const handleOnSort = React.useCallback(() => {
    dispatch(optionsAction({
      sortOrder: (options.sortOrder === 'asc') ? 'desc' : 'asc',
    }));
  }, [
    dispatch,
    options.sortOrder,
  ]);

  const selectOnChange = React.useCallback((e) => {
    dispatch(optionsAction({
      sortName: e.target.value,
    }))
  }, [
    dispatch,
  ]);

  const onPageSizeChange = React.useCallback((value) => {
    dispatch(optionsAction({
      pageNumber: 1,
      pageSize  : value,
    }));
  }, [
    dispatch,
  ]);

  const paginationOnClick = React.useCallback((e, offset, page) => {
    dispatch(optionsAction({
      pageNumber: page,
    }));
  }, [
    dispatch,
  ]);

  const images = React.useMemo(() => allIds.map((id: string) => byId[id]), [
    byId,
    allIds,
  ]);

  return (
    <>
      <Typography
        component='div'
        className={classes.wrapper}
      >
        <Typography
          component='div'
          className={classes.stickyHeader}
        >
          <Typography
            component='div'
            className={classes.listToolbarRoot}
          >
            <Typography
              component='div'
              className={classes.toolbarLeftActions}
            >
              <Typography
                component='div'
              >
                <Hidden
                  xsDown
                ><Search/>
                </Hidden>
              </Typography>
              <Typography
                component='div'
              >
                <Hidden
                  xsDown
                >
                  <Typography
                    component='div'
                    className={classes.sorting}
                  >
                    <Typography
                      noWrap
                      color='primary'
                      className={classes.typographySortBy}
                    >{t('sort_by')}
                    </Typography>
                    <Select
                      labelWidth={0}
                      variant='outlined'
                      value={options.sortName}
                      onChange={selectOnChange}
                      classes={{select: classes.select}}
                      className={classes.selectOutlined}
                    >
                      {allowedColumns.map(({value, name}) => (
                        <MenuItem
                          value={value}
                          key={`AllowedColumns_${value}`}
                        >{name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Tooltip
                      title={t('sort')}
                      className={classes.tooltip}
                    >
                      <div>
                        <ArrowUpwardIcon
                          onClick={handleOnSort}
                          className={clsx(classes.sortDirection, (options.sortOrder === 'desc') && classes.rotate)}
                        />
                      </div>
                    </Tooltip>
                  </Typography>
                </Hidden>
              </Typography>
            </Typography>
            <Typography
              component='div'
            >
              <Tooltip
                title={t('refresh')}
              >
                <div>
                  <IconButton
                    size='small'
                    onClick={loadData}
                    color='transparent'
                  >
                    <RefreshIcon/>
                  </IconButton>
                </div>
              </Tooltip>
            </Typography>
          </Typography>
        </Typography>
        <MemoList
          width={width}
          images={images}
          onCrop={onCrop}
          loading={loading}
          onDelete={onDelete}
          selected={selected}
        />
      </Typography>
      <Typography
        component='div'
        className={classes.paginationContainer}
      >
        <Hidden
          xsDown
        >
          <PageSizeSelector
            pageSize={options.pageSize}
            pageSizes={options.pageSizes}
            onPageSizeChange={onPageSizeChange}
            getMessage={() => t('table.rows_per_page')}
          />
        </Hidden>
        <Pagination
          total={options.total}
          otherPageColor='default'
          limit={options.pageSize}
          currentPageColor='primary'
          onClick={paginationOnClick}
          offset={((options.pageNumber || 1) * options.pageSize) - 1}
        />
      </Typography>
      <Dialogs
        onClick={onClick}
        aspectRatio={aspectRatio}
        onCloseGallery={onCloseGallery}
      />
    </>
  );
}

function MemoList(
  {
    width,
    images,
    onCrop,
    loading,
    onDelete,
    selected
  }: any
) {
  const classes = useStyles();
  const {t} = useTranslation();

  return React.useMemo(() => {
    return (
      <Typography
        component='div'
        style={{width}}
        className={classes.typographyWrapper}
      >
        {!!(images || []).length ? (
          !loading ? (
            (images || []).map(({id, thumbnail}: any) => (
              <Typography
                component='div'
                key={`${thumbnail}${Math.random()}`}
                className={clsx(classes.div, (selected.includes(thumbnail)) && `${classes.selectedDiv} active`)}
              >
                <Typography
                  component='div'
                  className={clsx('icon', classes.icon, (selected.includes(thumbnail)) && `${classes.selectedIcon} active`)}
                >
                  <IconButton
                    data-id={id}
                    onClick={onDelete}
                    color='transparent'
                    data-value={thumbnail}
                  >
                    <DeleteIcon
                      color='error'
                      fontSize='default'
                    />
                  </IconButton>
                </Typography>
                <div
                  data-id={id}
                  onClick={onCrop}
                  data-value={thumbnail}
                >
                  <Img
                    selected={selected}
                    thumbnail={thumbnail}
                  />
                </div>
              </Typography>
            ))
          ) : (
            <div
              className={classes.noDataContainer}
            >
              <CircularProgress
                className={classes.typographyNoData}
              />
            </div>
          )
        ) : (
          <div
            className={classes.noDataContainer}
          >
            {loading ? (
              <CircularProgress
                className={classes.typographyNoData}
              />
            ) : (
              <Typography
                variant='h6'
                align='center'
                color={'primary'}
                className={classes.typographyNoData}
              >{t('no_data')}
              </Typography>
            )}
          </div>
        )}
      </Typography>
    )
  }, [
    t,
    width,
    onCrop,
    images,
    loading,
    classes,
    selected,
    onDelete,
  ])
}

interface IColumn {
  name: string;
  value: string;
}

const allowedColumns: IColumn[] = [
  {
    value: 'name',
    name : 'Name',
  },
  {
    value: 'updated_at',
    name : 'Created at',
  },
];
