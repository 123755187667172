import React from 'react';
import {Button} from 'antd';
import {Tooltip} from 'antd';
import {Breadcrumb} from 'antd';
import {useSelector} from 'react-redux';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {getPrefixCls} from '../../../../../../utils/getPrefixCls';
import {Navigation} from '../../../../../../components/antd/Navigation';
import {_userId} from '../../../../../../store/selectors/application/acl';
import './index.less';

const overlayStyles: React.CSSProperties = {
  maxWidth: 'unset',
}

const overlayInnerStyles: React.CSSProperties = {
  whiteSpace: 'nowrap',
}

export function Header(
  {
    reloadData,
    createAction,
  }: {
    reloadData: () => void;
    createAction?: React.MouseEventHandler<HTMLElement>;
  }
) {
  const userId = useSelector(_userId);
  const [
    tooltipVisibility,
    toggleTooltipVisibility,
  ] = React.useState<boolean>(false);

  const [
    overlayClassName,
    setOverlayClassName,
  ] = React.useState<string | undefined>();

  React.useEffect(() => {
    setTimeout(function () {
      toggleTooltipVisibility(true);
    }, 800);
    setTimeout(function () {
      setOverlayClassName(getPrefixCls('push-notifications-page-header-heading-extra-new-push-button'));
    }, 1200);
  });

  return (
    <Navigation
      title='Push Notifications'
      breadcrumbItems={[
        <Breadcrumb.Item
          key='app.pushNotifications'
        >Push Notifications
        </Breadcrumb.Item>
      ]}
      extra={[
        (typeof createAction === 'function') && (
          <Tooltip
            zIndex={998}
            placement='left'
            key='NewPushNotification'
            overlayStyle={overlayStyles}
            overlayClassName={overlayClassName}
            overlayInnerStyle={overlayInnerStyles}
            title="Let's Send your first Push Notification! 👉 "
            visible={tooltipVisibility && !localStorage.getItem(`create_push_notification_tooltip_has_shown_${userId}`)}
          >
            <Button
              type='primary'
              icon={<PlusOutlined/>}
              onClick={createAction}
            >New Push Notification
            </Button>
          </Tooltip>
        ),
        <Button
          type='default'
          key='reloadData'
          onClick={reloadData}
          icon={<ReloadOutlined/>}
        />,
      ]}
    />
  )
}