import React from 'react';
import {Form} from 'antd';
import {Input} from 'antd';
import {Checkbox} from 'antd';
import {ILoginForm} from '../../useLogin';
import {Controller} from 'react-hook-form';
import {SubmitButton} from '../SubmitButton';
import {Link as RLink} from 'react-router-dom';
import {UseFormClearErrors} from 'react-hook-form';
import {Control} from 'react-hook-form/dist/types/form';
import {dataPrefixes} from '../../../../../../constants';
import {AppRoutes} from '../../../../../../constants/appRoutes';
import {FormAlert} from '../../../../../../components/antd/FormAlert';

const {Item} = Form;
const {Password} = Input;
const {auth: {login}} = dataPrefixes;

export function FormContent(
  {
    control,
    clearErrors,
    isSubmitting,
    searchString,
  }: {
    isSubmitting: boolean;
    searchString?: string;
    control: Control<ILoginForm>;
    clearErrors: UseFormClearErrors<ILoginForm>
  }
) {
  return (
    <div>
      <Controller
        name='message'
        control={control}
        render={(
          {
            fieldState: {
              error,
            }
          }
        ) => {
          return (
            <FormAlert
              type='error'
              message={error?.message}
              onClose={() => clearErrors('message')}
            />
          )
        }}
      />
      <Form
        component='div'
        className='form'
        layout='vertical'
      >
        <Controller
          name='email'
          control={control}
          render={(
            {
              field     : {
                ref,
                ...fieldRest
              },
              fieldState: {
                error,
                invalid,
              }
            }
          ) => {
            return (
              <Item
                label='Email'
                validateStatus={invalid ? 'error' : void 0}
                help={<span data-qa={`${login}-form-item-email-explain-error`}>{error?.message}</span>}
              >
                <Input
                  ref={ref}
                  autoFocus
                  autoComplete='off'
                  {...fieldRest}
                />
              </Item>
            );
          }}
        />
        <Controller
          name='password'
          control={control}
          render={(
            {
              field     : {
                ref,
                ...fieldRest
              },
              fieldState: {
                error,
                invalid,
              }
            }
          ) => {
            return (
              <Item
                label='Password'
                help={error?.message}
                validateStatus={invalid ? 'error' : void 0}
              >
                <Password
                  ref={ref}
                  autoComplete='current-password'
                  {...fieldRest}
                />
              </Item>
            );
          }}
        />
        <Item>
          <div
            className='d-flex justify-content-between align-items-center'
          >
            <Controller
              name='remember'
              control={control}
              render={(
                {
                  field: {
                    ref,
                    value,
                    ...fieldRest
                  },
                }
              ) => {
                return (
                  <Item
                    noStyle
                  >
                    <Checkbox
                      ref={ref}
                      checked={value}
                      {...fieldRest}
                    >Remember me
                    </Checkbox>
                  </Item>
                )
              }}
            />
            <RLink
              className='ant-typography regular'
              data-qa={`${login}-forgot-your-password-link`}
              to={!searchString ? AppRoutes.PASSWORD_FORGOT : `${AppRoutes.PASSWORD_FORGOT}?${searchString}`}
            >Forgot Your Password?
            </RLink>
          </div>
        </Item>
        <Item>
          <SubmitButton
            control={control}
            loading={isSubmitting}
          />
        </Item>
      </Form>
    </div>
  )
}
