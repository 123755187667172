import {filterObject} from '../../../../../utils';
import {DEFAULT_TTL} from '../../../../../constants';
import {ActionTypes} from '../../../../constants/campaigns';
import {TableActions} from '../../../../../constants/tableActions';
import {ActionTypes as CoreActionTypes} from '../../../../constants/application';

const INITIAL_ENTITY: { [key: string]: any } = {
  wait_until_value     : 10,
  send_to              : 'all',
  locale_type          : 'language',
  delivery             : 'immediately',
  per_user_optimization: 'immediately',
  wait_until_unit      : {
    value: 'minute',
    label: 'minute',
  },
  ttl_unit             : DEFAULT_TTL.ttl_unit,
  ttl_value            : DEFAULT_TTL.ttl_value,
};

const InitialState: any = {
  byId        : {},
  allIds      : [],
  loading     : false,
  errors      : void 0,
  dataOptions : void 0,
  actionDialog: void 0,
  entity      : INITIAL_ENTITY,
};

export default (state: any = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_SET_DATA: {
      const byId: any = {};
      const allIds: any[] = [];
      (payload as Array<any>).forEach((
        {
          ctr,
          uuid,
          data,
          ...rest
        },
        index
      ) => {
        allIds.push(uuid);
        byId[uuid] = {
          ctr,
          uuid,
          ctr_get: ctr,
          index  : index + 1,
          ...data,
          ...rest,
          messages: {
            ...data?.messages,
            default: {
              icon_url : rest?.icon,
              image_url: rest?.image,
              ...data?.messages?.default,
            }
          }
        };
      });
      return {
        ...state,
        byId,
        allIds,
      };
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_SET_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_REMOVE_LANG: {
      state.entity?.messages && delete state.entity?.messages[payload];
      return {
        ...state,
        entity: {
          ...state.entity,
          messages: {
            ...state.entity?.messages,
          },
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_DATA_OPTIONS: {
      return {
        ...state,
        dataOptions: {
          ...state.dataOptions,
          ...payload
        },
      };
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_TOGGLE_ERRORS: {
      return {
        ...state,
        errors: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_REMOVE_ERROR: {
      if (state?.errors?.[payload]) {
        return {
          ...state,
          errors: {
            ...state.errors,
            [payload]: void 0
          },
        }
      }
      return state;
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_TOGGLE_ENTITY: {
      return {
        ...state,
        entity: payload ? payload : INITIAL_ENTITY,
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_TOGGLE_DIALOG: {
      return {
        ...state,
        actionDialog: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_UPDATE_ENTITY: {
      return {
        ...state,
        entity: {
          ...state.entity,
          ...payload
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_DELETE_ACTION: {
      return {
        ...state,
        actionDialog: {
          dialog: TableActions.DELETE,
          data  : payload,
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_CANCEL_ACTION: {
      return {
        ...state,
        actionDialog: {
          dialog: TableActions.CANCEL,
          data  : payload,
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_EMPTY_TABLE_DATA: {
      return {
        ...state,
        allIds: [],
        byId  : {},
      };
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_SET_CAMPAIGN_STAT: {
      return {
        ...state,
        campaignStats: payload,
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_ADD_ENTITY_ACTION: {
      return {
        ...state,
        entity: {
          ...state.entity,
          action: {
            ...state?.entity?.action,
            ...payload
          },
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_UPDATE_ENTITY_LANG: {
      const {
        id,
        ...restPayload
      } = payload;

      return {
        ...state,
        entity: {
          ...state.entity,
          messages: {
            ...state.entity?.messages,
            [id]: {
              ...(state.entity?.messages && state.entity?.messages[id]),
              ...restPayload
            }
          },
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_EMPTY_LANGUAGES: {
      return {
        ...state,
        entity: {
          ...state.entity,
          messages: {
            default: {...state.entity?.messages?.default}
          },
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_EMPTY_CAMPAIGN_STAT: {
      return {
        ...state,
        campaignStats: void 0,
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_DELETE_ENTITY_ACTION: {
      const t_1 = filterObject({
        ...state?.entity?.action,
        [payload]: void 0
      });
      return {
        ...state,
        entity: {
          ...state.entity,
          action: {
            ...t_1
          },
        },
      }
    }
    case ActionTypes.WEB_NOTIFY_CAMPAIGNS_UPDATE_ENTITY_ACTIONS: {
      return {
        ...state,
        entity: {
          ...state.entity,
          action: {
            ...state?.entity?.action,
            [payload.identity]: {
              ...(state?.entity?.action && state?.entity?.action[payload.identity]),
              ...payload
            }
          },
        },
      }
    }
    case CoreActionTypes.LOGOUT: {
      return InitialState
    }
    default:
      return state;
  }
};
