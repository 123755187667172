import {Reports} from '../../../../types/reports';
import {ActionTypes} from '../../../constants/reports/dailyInactive';

export const _dataOptions = (data: Partial<Reports.Chart.DataOptions>) => ({
  payload: data,
  type   : ActionTypes.REPORTS_DAILY_INACTIVE_DATA_OPTIONS,
});

export const _setData = (data: Reports.Chart.Data) => ({
  payload: data,
  type   : ActionTypes.REPORTS_DAILY_INACTIVE_SET_DATA,
});