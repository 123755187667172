import React from 'react';
import {Space} from 'antd';
import {Button} from 'antd';
import {useIntegrate} from './useIntegrate';
import {CheckOutlined} from '@ant-design/icons';
import * as Common from '../../../../CommonComponents';
import {HeaderWrapper} from './components/HeaderWrapper';
import {Content} from '../../../../../components/antd/Content';
import {IntegrationOptions} from './components/IntegrationOptions';
import {IntegrationTesting} from '../../../../../components/antd/IntegrationTesting';

export function Integrate() {
  const {
    value,
    state,
    submit,
    goBack,
    onCopy,
    onClick,
    appUuid,
    visible,
    onChange,
    validation,
    skipForNow,
    savingStep,
    downloading,
    showPlanChange,
    stepsIsVisible,
    downloadWorkerJS,
    toggleVisibility,
    toggleStepsVisibility,
    fireIntegrationSkipped,
  } = useIntegrate();

  return (
    <div>
      <form>
        <HeaderWrapper
          state={state}
          radioGroupValue={value}
        />
        <Content
          gutter={8}
        >
          <IntegrationOptions
            stepsIsVisible={stepsIsVisible}
            toggleStepsVisibility={toggleStepsVisibility}
            fireIntegrationSkipped={fireIntegrationSkipped}
          />
          {stepsIsVisible && (
            <div
              className='d-flex align-items-center flex-column'
            >
              <Common.IntegrationTypes
                value={value}
                onCopy={onCopy}
                onChange={onChange}
                downloading={downloading}
                downloadWorkerJS={downloadWorkerJS}
              />
              <div
                className='create-app-card right'
              >
                <Space
                  size={8}
                >
                  <Button
                    onClick={goBack}
                    disabled={savingStep || visible}
                  >Go Back
                  </Button>
                  <Button
                    type='primary'
                    htmlType='button'
                    onClick={onClick}
                    icon={<CheckOutlined/>}
                    loading={savingStep || visible}
                  >{showPlanChange ? 'Continue' : 'Finish'}
                  </Button>
                </Space>
              </div>
            </div>
          )}
        </Content>
      </form>
      {visible && (
        <IntegrationTesting
          submit={submit}
          visible={visible}
          appUuid={appUuid}
          skipForNow={skipForNow}
          validation={validation}
          savingStep={savingStep}
          toggleVisibility={toggleVisibility}
          fireIntegrationSkipped={fireIntegrationSkipped}
        />
      )}
    </div>
  )
}
