import {_upsertUser} from './index';
import {Globals} from '../../../types/globals';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _surveys = (): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_upsertUser({
    surveyFiniteState: FiniteStates.LOADING,
  }));

  const {
    body: {
      data,
    },
    hasError,
  } = await api.get(ApiRoutes.SURVEY);

  if (!hasError) {
    dispatch(_upsertUser({
      surveys          : data,
      surveyFiniteState: FiniteStates.SUCCESS,
    }));
  } else {
    dispatch(_upsertUser({
      surveyFiniteState: FiniteStates.FAILURE,
    }));
  }
};