import clsx from 'clsx';
import React from 'react';
import {TColor} from '../index';
import {Theme} from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {DialogContentProps} from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme: Theme) => ({
  iconClass         : {
    height: 60,
    width : 60,
    margin: '0 auto',
  },
  success           : {
    color: green[500],
  },
  warning           : {
    color: amber[500],
  },
  danger            : {
    color: red[500],
  },
  primary           : {
    color: theme.palette.primary.main,
  },
  infoText          : {
    fontSize    : 22,
    textAlign   : 'center',
    color       : '#000000',
    padding     : '10px 18px',
    overflowWrap: 'break-word',
  },
  helperText        : {
    fontSize : 15,
    textAlign: 'center',
    color    : grey[500],
    padding  : `0 ${theme.spacing(4.5)}px`,
  },
  dialogContentClass: {
    display                       : 'flex',
    overflowY                     : 'unset',
    flexDirection                 : 'column',
    position                      : 'relative',
    padding                       : theme.spacing(4.5, 4.5, 2.25, 4.5),
    '& > div'                     : {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 1, .5, 1),
    }
  },
}));

export default function Content(
  {
    color,
    children,
    className,
    bodyTitle,
    bodyDescription,
    bodyIcon: BodyIcon,
    ...rest
  }: ContentProps
) {
  const classes = useStyles();

  return (
    <MuiDialogContent
      className={clsx(classes.dialogContentClass, className)}
      {...rest}
    >
      {color && (
        ((() => {
          switch (color) {
            case 'success': {
              return (
                BodyIcon ? (
                  <BodyIcon
                    className={clsx(classes.iconClass, classes.success)}
                  />
                ) : (
                  <DoneAllIcon
                    className={clsx(classes.iconClass, classes.success)}
                  />
                )
              )
            }
            case 'warning': {
              return (
                BodyIcon ? (
                  <BodyIcon
                    className={clsx(classes.iconClass, classes.warning)}
                  />
                ) : (
                  <WarningIcon
                    className={clsx(classes.iconClass, classes.warning)}
                  />
                )
              )
            }
            case 'danger': {
              return (
                BodyIcon ? (
                  <BodyIcon
                    className={clsx(classes.iconClass, classes.danger)}
                  />
                ) : (
                  <DeleteIcon
                    className={clsx(classes.iconClass, classes.danger)}
                  />
                )
              )
            }
            case 'primary': {
              return (
                BodyIcon ? (
                  <BodyIcon
                    className={clsx(classes.iconClass, classes.primary)}
                  />
                ) : (
                  <DoneAllIcon
                    className={clsx(classes.iconClass, classes.primary)}
                  />
                )
              )
            }
            default: {
              return <></>
            }
          }
        })())
      )}
      {bodyTitle && (
        <Typography
          className={classes.infoText}
        >{bodyTitle}
        </Typography>
      )}
      {bodyDescription && (
        <Typography
          component='span'
          className={classes.helperText}
        >{bodyDescription}
        </Typography>
      )}
      {children}
    </MuiDialogContent>
  )
}

export interface ContentProps extends DialogContentProps {
  color?: TColor;
  bodyIcon?: any;
  bodyTitle?: React.ReactNode;
  bodyDescription?: React.ReactNode;
}

