import React from 'react';
import {useSelector} from 'react-redux';
import {Status} from '../../../../../../../components/Status';
import Skeleton from '../../../../../../../components/Skeleton';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {getOptions} from '../../../../../../../store/selectors/campaigns';
import {getIsLoading} from '../../../../../../../store/selectors/campaigns';

const useStyles = makeStyles(() => ({
  skeleton: {
    margin      : 0,
    borderRadius: 0,
    height      : 24,
    width       : '100%',
    transform   : 'unset',
  }
}));

export default function StatusFormatter(
  {
    row,
    value,
  }: DataTypeProvider.ValueFormatterProps
) {
  const classes = useStyles();
  const isLoading = useSelector(getIsLoading);
  const tableOptions = useSelector(getOptions);
  const showProgress = tableOptions?.options?.tab !== 'draft';

  return (
    <div>
      {isLoading ? (
        <Skeleton
          className={classes.skeleton}
        />
      ) : (
        <Status
          status={!row.is_system ? Number(value) : 7}
          progress={showProgress ? row.progress : void 0}
          scheduledAt={row.scheduled_at || row.wait_until}
          variant={(row.automation && row.automation.type === 'welcome') ? 'outlined' : 'contained'}
          disabled={(row.automation && row.automation.type === 'welcome') ? !row.automation.enabled : void 0}
        />
      )}
    </div>
  )
}
