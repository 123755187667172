import produce from 'immer';
import {isMobile} from '../../../utils/isMobile';
import {Application} from '../../../types/application';
import {ActionTypes} from '../../constants/application';
import {FiniteStates} from '../../../constants/finiteStates';

const InitialState: Application.Reducer = {
  isMobile                 : isMobile(),
  applicationStatus        : FiniteStates.IDLE,
  appSumoTokenCheckingState: FiniteStates.IDLE,
  accessToken              : localStorage.getItem('access_token'),
  authenticated            : !!localStorage.getItem('access_token'),
};

export const application = produce((draft, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.LOGOUT: {
      return {
        ...InitialState,
        envVariables : {
          ...draft?.envVariables,
        },
        isMobile     : void 0,
        authenticated: false,
      };
    }
    case ActionTypes.IS_MOBILE: {
      draft.isMobile = payload;

      return void 0;
    }
    case ActionTypes.SUSPENDED: {
      draft.suspended = payload;

      return void 0;
    }
    case ActionTypes.EMPTY_FORM: {
      draft.form = void 0;

      return void 0;
    }
    case ActionTypes.UPSERT_USER: {
      if (!draft.user) {
        draft.user = {} as any;
      }
      Object.keys(payload).forEach((key: string) => {
        (draft.user as any)[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.UPSERT_FORM: {
      if (!draft.form) {
        draft.form = {};
      }
      Object.keys(payload || {}).forEach((key: string) => {
        draft.form[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.SET_APP_ENV: {
      if (!draft.envVariables) {
        draft.envVariables = {} as any;
      }
      Object.keys(payload || {}).forEach((key: string) => {
        (draft.envVariables as any)[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.UNAUTHORIZED: {
      draft.unauthorized = payload;

      return void 0;
    }
    case ActionTypes.AUTHENTICATE: {
      if (payload.accessToken) {
        localStorage.setItem('access_token', payload.accessToken);
      }

      Object.keys(payload).forEach((key) => {
        (draft as any)[key] = payload[key];
      });
      draft.unauthorized = false;

      return void 0;
    }
    case ActionTypes.UPSERT_SURVEY: {
      if (!draft.user) {
        draft.user = {} as any;
      }
      if (!draft.user.surveys) {
        draft.user.surveys = [] as any;
      }

      draft.user.surveys.push(payload);

      return void 0;
    }
    case ActionTypes.BEFORE_UNLOAD: {
      draft.beforeunload = payload;

      return void 0;
    }
    case ActionTypes.UPSERT_APPLICATION: {
      Object.keys(payload || {}).forEach((key: string) => {
        (draft as any)[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.UPSERT_SIDEBAR_DATA: {
      if (!draft.sidebarData) {
        draft.sidebarData = {} as any;
      }
      Object.keys(payload || {}).forEach((key: string) => {
        (draft.sidebarData as any)[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.SET_APPLICATION_STATUS: {
      draft.applicationStatus = payload;

      return void 0;
    }
    default:
      return void 0;
  }
}, InitialState);
