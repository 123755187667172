import {Row} from 'antd';
import {Col} from 'antd';
import {Card} from 'antd';
import {Form} from 'antd';
import React from 'react';
import {Input} from 'antd';
import {Select} from 'antd';
import {CardProps} from 'antd';
import {useSelector} from 'react-redux';
import {Controller} from 'react-hook-form';
import {CardField} from './components/CardField';
import {StateInput} from './components/StateInput';
import {Control} from 'react-hook-form/dist/types/form';
import {_countries} from '../../../store/selectors/locales';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';

export function PaymentDetails(
  {
    control,
    setValue,
    cardProps,
  }: {
    control: Control<any>;
    cardProps?: CardProps;
    setValue: UseFormSetValue<any>;
  }
) {
  const countries = useSelector(_countries);

  const options = React.useMemo(() => countries.map((country: any) => (
    <Select.Option
      key={country.id}
      value={country.iso}
    >{country.text}
    </Select.Option>
  )), [
    countries,
  ]);

  const filterOption = React.useCallback((value: string, option: any) => (
    option.children.toLowerCase().includes(value.toLowerCase()) ||
    option.value.toLowerCase().includes(value.toLowerCase())
  ), []);

  return (
    <Card
      bordered={false}
      title='Enter Payment Details'
      className='enter-payment-details m-b-2'
      {...cardProps}
    >
      <Form
        component='div'
        layout='vertical'
      >
        <Controller
          name='cardField'
          control={control}
          render={(
            {
              field     : {
                ref,
                ...fieldRest
              },
              fieldState: {
                error,
                invalid,
              }
            }
          ) => {
            return (
              <Form.Item
                label='Card Number'
                help={error?.message}
                validateStatus={invalid ? 'error' : void 0}
              >
                <CardField
                  {...fieldRest}
                  invalid={invalid}
                />
              </Form.Item>
            )
          }}
        />
        <Controller
          name='name'
          control={control}
          render={(
            {
              field     : {
                ref,
                ...fieldRest
              },
              fieldState: {
                error,
                invalid,
              }
            }
          ) => {
            return (
              <Form.Item
                label='Name on Card'
                help={error?.message}
                validateStatus={invalid ? 'error' : void 0}
              >
                <Input
                  ref={ref}
                  {...fieldRest}
                />
              </Form.Item>
            )
          }}
        />
        <Controller
          name='country'
          control={control}
          render={(
            {
              field     : {
                ref,
                ...fieldRest
              },
              fieldState: {
                error,
                invalid,
              }
            }
          ) => {
            return (
              <Form.Item
                label='Country'
                help={error?.message}
                validateStatus={invalid ? 'error' : void 0}
              >
                <Select
                  ref={ref}
                  showSearch
                  filterOption={filterOption}
                  {...fieldRest}
                >{options}
                </Select>
              </Form.Item>
            )
          }}
        />
        <Controller
          name='line1'
          control={control}
          render={(
            {
              field     : {
                ref,
                ...fieldRest
              },
              fieldState: {
                error,
                invalid,
              }
            }
          ) => {
            return (
              <Form.Item
                label='Address'
                help={error?.message}
                validateStatus={invalid ? 'error' : void 0}
              >
                <Input
                  ref={ref}
                  placeholder='Street address'
                  {...fieldRest}
                />
              </Form.Item>
            )
          }}
        />
        <Controller
          name='line2'
          control={control}
          render={(
            {
              field     : {
                ref,
                ...fieldRest
              },
              fieldState: {
                error,
                invalid,
              }
            }
          ) => {
            return (
              <Form.Item
                help={error?.message}
                validateStatus={invalid ? 'error' : void 0}
              >
                <Input
                  ref={ref}
                  placeholder='Apt, Unit, Suite, etc. (optional)'
                  {...fieldRest}
                />
              </Form.Item>
            )
          }}
        />
        <Row
          gutter={24}
          align='bottom'
          justify='space-between'
          className='create-app-site-info-row'
        >
          <Col
            sm={8}
            xs={24}
          >
            <Controller
              name='city'
              control={control}
              render={(
                {
                  field     : {
                    ref,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                    invalid,
                  }
                }
              ) => {
                return (
                  <Form.Item
                    label='City'
                    help={error?.message}
                    validateStatus={invalid ? 'error' : void 0}
                  >
                    <Input
                      ref={ref}
                      {...fieldRest}
                    />
                  </Form.Item>
                )
              }}
            />
          </Col>
          <Col
            sm={8}
            xs={24}
          >
            <StateInput
              control={control}
              setValue={setValue}
            />
          </Col>
          <Col
            sm={8}
            xs={24}
          >
            <Controller
              control={control}
              name='postal_code'
              render={(
                {
                  field     : {
                    ref,
                    ...fieldRest
                  },
                  fieldState: {
                    error,
                    invalid,
                  }
                }
              ) => {
                return (
                  <Form.Item
                    label='ZIP Code'
                    help={error?.message}
                    validateStatus={invalid ? 'error' : void 0}
                  >
                    <Input
                      ref={ref}
                      {...fieldRest}
                    />
                  </Form.Item>
                )
              }}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  )
}
