import {loadStripe, Stripe} from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;

export const initStripePromise = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK, {
      locale: 'en',
    });
  }
  return stripePromise;
};
