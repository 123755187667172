import React from 'react';
import {Spin} from 'antd';
import {Card} from 'antd';
import {Button} from 'antd';
import {Result} from 'antd';
import {Tooltip} from 'antd';
import {syncExtremes} from '../index';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import * as Highcharts from 'highcharts';
import {TooltipOptions} from 'highcharts';
import {getMaxY} from '../../../../../../../utils';
import {getMinY} from '../../../../../../../utils';
import HighchartsReact from 'highcharts-react-official';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {ANIMATION_DELAY} from '../../../../../../../constants';
import {Text} from '../../../../../../../components/antd/Typography';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import {_data} from '../../../../../../../store/selectors/reports/ctr';
import {FiniteStates} from '../../../../../../../constants/finiteStates';
import {_messages} from '../../../../../../../store/selectors/reports/ctr';
import {_reportsDates} from '../../../../../../../store/selectors/reports';
import {_fetch} from '../../../../../../../store/actions/reports/ctr/fetch';
import {_fetchingState} from '../../../../../../../store/selectors/reports/ctr';
import {_dataSum as _clicksDataSum} from '../../../../../../../store/selectors/reports/clicks';
import {_isUnique as _isUniqueClicks} from '../../../../../../../store/selectors/reports/clicks';
import {_dataSum as _displaysDataSum} from '../../../../../../../store/selectors/reports/displays';
import {_isUnique as _isUniqueDisplays} from '../../../../../../../store/selectors/reports/displays';
import {_fetchingState as _clicksFetchingState} from '../../../../../../../store/selectors/reports/clicks';
import {_fetchingState as _displaysFetchingState} from '../../../../../../../store/selectors/reports/displays';

export function Ctr(
  {
    height,
    callback,
  }: {
    height: number;
    callback: Highcharts.ChartCallbackFunction;
  }
) {
  const dispatch = useDispatch();
  const messages = useSelector(_messages);
  const dates = useSelector(_reportsDates);
  const clicksDataSum = useSelector(_clicksDataSum);
  const fetchingState = useSelector(_fetchingState);
  const {appUuid} = useParams<{ appUuid: string }>();
  const isUniqueClicks = useSelector(_isUniqueClicks);
  const displaysDataSum = useSelector(_displaysDataSum);
  const isUniqueDisplays = useSelector(_isUniqueDisplays);

  const clicksFetchingState = useSelector(_clicksFetchingState);
  const displaysFetchingState = useSelector(_displaysFetchingState);

  const crtValueIsReady = (displaysFetchingState === FiniteStates.SUCCESS) && (clicksFetchingState === FiniteStates.SUCCESS)

  if (fetchingState === FiniteStates.FAILURE) {
    return (
      <Card
        style={{height}}
        className='m-t-2 d-flex align-items-center justify-content-center'
      >
        <Result
          title='CTR'
          status='error'
          style={{padding: 0}}
          subTitle={Object?.values(messages)?.join('. ') || 'Failed to Load'}
          extra={[
            <Button
              ghost
              key='console'
              type='primary'
              icon={<ReloadOutlined/>}
              onClick={() => dispatch(_fetch(appUuid, dates))}
            >Reload
            </Button>
          ]}
        />
      </Card>
    )
  } else {
    return (
      <Card
        className='m-t-2'
      >
        <Spin
          delay={ANIMATION_DELAY}
          spinning={[
            FiniteStates.IDLE,
            FiniteStates.LOADING,
          ].includes(fetchingState)}
        >
          {(isUniqueClicks === isUniqueDisplays) ? (
            <>
              <div
                className='d-flex align-items-center justify-content-between'
              >
                <div
                  className='d-flex align-items-center'
                >
                  <Text
                    strong
                    fontSize='large'
                    style={{color: '#C400BC'}}
                  >CTR
                  </Text>
                  <Text
                    strong
                    fontSize='large'
                    className='m-l-1'
                    style={{color: '#C400BC'}}
                  >
                    <Spin
                      size='small'
                      delay={ANIMATION_DELAY}
                      spinning={!crtValueIsReady}
                    >
                      {
                        crtValueIsReady ? `${Number((clicksDataSum * 100) / (displaysDataSum || 1)).toFixed(2)}%` : '0.00%'
                      }
                    </Spin>
                  </Text>
                  <Tooltip
                    title={<TooltipTitle/>}
                  >
                    <Text
                      fontSize='small'
                      type='secondary'
                      className='m-l-1 m-b-1'
                    >
                      <InfoCircleOutlined/>
                    </Text>
                  </Tooltip>
                </div>
                {(isUniqueClicks && isUniqueDisplays) && (
                  <Text>
                    <Text
                      strong
                      className='m-r-1'
                    >Unique
                    </Text>
                    <Tooltip
                      title='CTR per unique subscribers.'
                    ><InfoCircleOutlined/>
                    </Tooltip>
                  </Text>
                )}
              </div>
              <MemoChart
                height={height}
                callback={callback}
              />
            </>
          ) : (
            <div
              style={{height: height - 50}}
              className='d-flex align-items-center justify-content-center'
            >
              <Result
                status='warning'
                style={{padding: 0}}
                subTitle={<WarningSubTitle/>}
              />
            </div>
          )}
        </Spin>
      </Card>
    )
  }
}

const emptyArray: any[] = [];

const MemoChart = React.memo(function (
  {
    height,
    callback,
  }: {
    height: number;
    callback: Highcharts.ChartCallbackFunction;
  }
) {
  const data = useSelector(_data);

  let max = getMaxY(data);
  max = (max / 10) + max;

  let min = getMinY(data);
  min = min - (min / 10);

  if (min < 0) {
    min = 0;
  }

  return (
    <HighchartsReact
      options={{
        legend : {
          enabled: false
        },
        credits: {
          enabled: false
        },
        title  : false,
        series : [{
          fillOpacity: 0.3,
          color      : {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops         : [
              [0, 'rgba(196,0,188,.3)'],
              [1, 'rgba(255,255,255,.2)'],
            ],
          },
          name       : 'name',
          type       : 'area',
          lineColor  : '#C400BC',
          data       : data.length ? [...data] : emptyArray,
        }],
        chart  : {
          spacingTop   : 8,
          spacingBottom: 8,
          height       : height - 75.14,
        },
        xAxis  : {
          type                : 'datetime',
          events              : {syncExtremes},
          minTickInterval     : 24 * 3600 * 1000,
          dateTimeLabelFormats: {
            day  : '%e-%b-%y',
            month: '%b-%y-%e',
          },
          crosshair           : {
            width: 1.5,
            color: '#24083C',
          },
        },
        tooltip: {
          borderWidth    : 0,
          valueDecimals  : 0,
          headerFormat   : '',
          shadow         : false,
          backgroundColor: '#000000',
          style          : {
            fontSize  : '12px',
            fontWeight: 'bold',
            color     : '#C400BC',
          },
          formatter      : function () {
            if (this.point.y || this.point.y === 0) {
              return `${Number(this.point.y).toFixed(2)}%`;
            }
          },
        } as TooltipOptions,
        yAxis  : {
          max,
          min,
          title      : false,
          endOnTick  : false,
          startOnTick: false,
          labels     : {
            rotation: -90
          }
        },
      }}
      callback={callback}
      highcharts={Highcharts}
    />
  )
})

function TooltipTitle() {
  return (
    <>
      Click-Through Rate is the percentage of displayed push messages that have been clicked. It is calculated as
      <i> Clicks/Displays </i>
      on the given day.
    </>
  )
}

function WarningSubTitle() {
  return (
    <>
      Cannot calculate CTR as Displays and Clicks charts are not in the same state.
      <br/>
      Please set the 'Unique' toggle for both of them to On or Off at the same time.
    </>
  )
}