import {_setData} from './index';
import {CancelToken} from 'axios';
import {_dataOptions} from './index';
import {Globals} from '../../../types/globals';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';

export const _fetch = (
  pageSize: number = 1000,
  {
    cancelToken
  }: {
    cancelToken?: CancelToken
  } = {}
): Globals.ThunkAction => async (dispatch, getState, {
  api
}) => {
  dispatch(_dataOptions({
    errorCode        : void 0,
    fetchingAppsState: FiniteStates.LOADING,
  }));

  const {
    hasError,
    body: {
      data,
      error_code,
    },
  } = await api.get(`${ApiRoutes.LIST_APPS}?pageSize=${pageSize}&created_at=desc`, {
    cancelToken,
    skipDelay: true,
  });

  if (!hasError) {
    const {
      rows,
    } = data;

    dispatch(_setData(rows));
    dispatch(_dataOptions({
      fetchingAppsState: FiniteStates.SUCCESS,
    }));
  } else {
    dispatch(_dataOptions({
      errorCode        : error_code,
      fetchingAppsState: FiniteStates.FAILURE,
    }));
  }
};
