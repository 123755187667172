import {ActionTypes} from '../../constants/automation';
import {Automations} from '../../../types/automations';

export const _dataOptions = (options: Partial<Automations.DataOptions>) => ({
  payload: options,
  type   : ActionTypes.AUTOMATIONS_DATA_OPTIONS,
});

export const _setData = (data: Automations.Entity[]) => ({
  payload: data,
  type   : ActionTypes.AUTOMATIONS_SET_DATA,
});

export const _updateData = (data: Automations.Entity) => ({
  payload: data,
  type   : ActionTypes.AUTOMATIONS_UPDATE_DATA,
});

export const _deleteData = (data: number) => ({
  payload: data,
  type   : ActionTypes.AUTOMATIONS_DELETE_DATA,
});

export const _clearData = () => ({
  type: ActionTypes.AUTOMATIONS_CLEAR_DATA,
});

export const _toggleErrors = (errors: {[key: string]: string}) => ({
  payload: errors,
  type   : ActionTypes.AUTOMATIONS_TOGGLE_ERRORS,
});
