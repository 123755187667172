import React from 'react';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useTitle} from '../../../../../hooks/useTitle';
import {ApiRoutes} from '../../../../../constants/apiRoutes';
import phoneNumbers from '../../../../../constants/phone.json';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {resendAction} from '../../../../../store/actions/settings';
import {cancelAction} from '../../../../../store/actions/settings';
import {resendAction1} from '../../../../../store/actions/settings';
import {_user} from '../../../../../store/selectors/application/acl';
import {generalFormError} from '../../../../../store/actions/settings';
import {getIsCanceling} from '../../../../../store/selectors/settings';
import {getGeneralForm} from '../../../../../store/selectors/settings';
import {getIsResending} from '../../../../../store/selectors/settings';
import {_apiBaseUrl} from '../../../../../store/selectors/application';
import {_accessToken} from '../../../../../store/selectors/application';
import {generalFormSuccess} from '../../../../../store/actions/settings';
import {getGeneralFormError} from '../../../../../store/selectors/settings';
import {saveGeneralFormAction} from '../../../../../store/actions/settings';
import {handleGeneralFormAction} from '../../../../../store/actions/settings';
import {getGeneralFormIsLoading} from '../../../../../store/selectors/settings';

export function useGeneral() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  useTitle(t('general_settings.document_title'));

  const user = useSelector(_user);
  const apiBaseUrl = useSelector(_apiBaseUrl);
  const error = useSelector(getGeneralFormError);
  const isResending = useSelector(getIsResending);
  const isCanceling = useSelector(getIsCanceling);
  const accessToken = useSelector(_accessToken);
  const isLoading = useSelector(getGeneralFormIsLoading);
  const generalSettingsForm = useSelector(getGeneralForm);
  const {executeRecaptcha} = useGoogleReCaptcha();

  const [mainData, setMainData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const resend = React.useCallback(() => {
    return dispatch(resendAction({
      executeRecaptcha
    }))
  }, [
    dispatch,
    executeRecaptcha,
  ]);

  const resend1 = React.useCallback(() => {
    return dispatch(resendAction1({
      executeRecaptcha
    }))
  }, [
    dispatch,
    executeRecaptcha,
  ]);

  const cancel = React.useCallback(() => {
    return dispatch(cancelAction())
  }, [
    dispatch,
  ])

  const updateUser = React.useCallback(() => {
    return dispatch(saveGeneralFormAction());
  }, [
    dispatch,
  ]);

  const resetGeneralForm = React.useCallback(() => {
    let contacts: any = user?.contacts;
    if (!Object.keys(user?.contacts || {}).length) {
      contacts = {
        social: {},
      };
    } else {
      const code = phoneNumbers.find((i) => i.value === contacts?.phone?.code)

      contacts = {
        social: {
          ...contacts?.social,
        },
        phone : {
          ...contacts?.phone,
          code,
        }
      }
    }

    dispatch(handleGeneralFormAction({
      contacts,
      email                 : user.email,
      last_name             : user.last_name,
      first_name            : user.first_name,
      default_home          : user.default_home,
      email_verified_at     : user.email_verified_at,
      display_time_zone_text: mainData.find((obj: any) => obj.value === user.display_time_zone_text),
    }))
  }, [
    mainData,
    user,
    dispatch,
  ]);

  const handleGeneralForm = React.useCallback((formData: Record<string, any>) => {
    return dispatch(handleGeneralFormAction(formData))
  }, [
    dispatch,
  ]);

  React.useEffect(() => {
    (async () => {
      const endpoint = `${apiBaseUrl}${ApiRoutes.PROFILE_TIMEZONES}`;
      const response = await fetch(`${endpoint}`, {
        method : 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const {
          data: {
            all,
          },
        } = await response.json();
        const allData = Object.keys(all).reduce((acc: any, current: any) => {
          const tmpData = Object.keys(all[current] || {}).reduce((sAcc: any, sCurrent: any) => {
            sAcc.push({
              groupBy: current,
              value  : sCurrent,
              label  : all[current][sCurrent],
            });
            return sAcc;
          }, []);
          return acc.concat(tmpData);
        }, []);

        setMainData(allData);

        let contacts: any = user?.contacts;
        if (!Object.keys(user?.contacts || {}).length) {
          contacts = {
            social: {},
          };
        } else {
          const code = phoneNumbers.find((i) => i.value === contacts?.phone?.code)

          contacts = {
            social: {
              ...contacts?.social,
            },
            phone : {
              ...contacts?.phone,
              code,
            }
          }
        }

        dispatch(handleGeneralFormAction({
          contacts,
          email                 : user.email,
          last_name             : user.last_name,
          first_name            : user.first_name,
          default_home          : user.default_home,
          email_verified_at     : user.email_verified_at,
          display_time_zone_text: allData.find((obj: any) => obj.value === user.display_time_zone_text),
        }));
        setLoading(false);
      }
    })()

    return () => {
      dispatch(generalFormSuccess(void 0));
      dispatch(generalFormError(void 0));
    }
  }, [
    user,
    dispatch,
    apiBaseUrl,
    accessToken,
  ]);

  return {
    user,
    error,
    cancel,
    resend,
    loading,
    mainData,
    isLoading,
    updateUser,
    isCanceling,
    isResending,
    resend1,
    resetGeneralForm,
    handleGeneralForm,
    generalSettingsForm,
  }
}
