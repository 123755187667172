import clsx from 'clsx';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  bars   : {
    height: 10,
    float : 'left',
  },
  fails  : {
    backgroundColor: '#ffb22b',
  },
  errors : {
    backgroundColor: '#fc4b6c',
  },
  pending: {
    backgroundColor: 'lightgray',
  },
  success: {
    backgroundColor: '#5eba00',
  },
}));

export const ProgressBar: React.FC<any> = (
  {
    total,
    readings,
  }
) => {
  const classes = useStyles();

  return (
    <Typography
      component='div'
    >
      <Typography
        component='div'
        style={{display: 'flex'}}
      >
        {(readings || []).map((item: any, i: any) => {
          let colorClass;
          switch (item.color) {
            case 'errors': {
              colorClass = classes.errors;
              break;
            }
            case 'success': {
              colorClass = classes.success;
              break;
            }
            case 'pending': {
              colorClass = classes.pending;
              break;
            }
            case 'fails': {
              colorClass = classes.fails;
              break;
            }
          }
          return (
            <Typography
              component='div'
              key={`Bars_${i}`}
              className={clsx(classes.bars, colorClass)}
              style={{
                'backgroundColor': item.color,
                'width'          : (item.value * 100 / (total === 0 ? 1 : total)).toFixed(2) + '%'
              }}
            />
          )
        })}
      </Typography>
    </Typography>
  );
};
