import React from 'react';
import {useSelector} from 'react-redux';
import List from '@material-ui/core/List';
import {Theme} from '@material-ui/core/styles';
import {emptyArray} from '../../../../../constants';
import Typography from '@material-ui/core/Typography';
import {TextField} from '../../../../../components/Input';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {getErrors} from '../../../../../store/selectors/users';
import {AutoComplete} from '../../../../../components/AutoComplete';
import {getPermissions} from '../../../../../store/selectors/users';
import CircularProgress from '../../../../../components/CircularProgress';

const useStyles = makeStyles((theme: Theme) => ({
  error                    : {
    marginBottom: theme.spacing(3),
  },
  formControl              : {
    marginBottom: theme.spacing(3),
  },
  autoComplete             : {
    '& .MuiAutocomplete-inputRoot[class*=\'MuiOutlinedInput-root\']': {
      paddingTop   : theme.spacing(.5),
      paddingBottom: theme.spacing(.5),
    }
  },
  circularProgressContainer: {
    display       : 'flex',
    width         : '100%',
    justifyContent: 'center',
    alignItems    : 'center',
  },
}));

export default React.memo<{
  data: any[];
  onChange: any
}>((
  {
    data,
    onChange,
  }
) => {
  const classes = useStyles();
  const errors = useSelector(getErrors);
  const permissions = useSelector(getPermissions);

  if (!permissions && !errors?.message) {
    return (
      <Typography
        component='div'
        className={classes.formControl}
      >
        <List
          className={classes.circularProgressContainer}
        >
          <CircularProgress/>
        </List>
      </Typography>
    );
  }

  return (
    <>
      {!!errors?.message && (
        <Typography
          color='error'
          align='center'
          className={classes.error}
        >{errors.message}
        </Typography>
      )}
      {permissions?.map((
        {
          title,
          inputs,
          service,
        }: {
          title: string;
          inputs: any[];
          service: string;
        },
        index: number
      ) => (
        <Typography
          component='div'
          key={`Sub_${service}_${index}`}
          className={classes.formControl}
        >
          <AutoComplete
            multiple
            onChange={onChange}
            disableCloseOnSelect
            value={data || emptyArray}
            className={classes.autoComplete}
            getOptionLabel={(option) => option?.label || ''}
            options={((inputs) || []).filter((v: any) => v.type === 'checkbox')}
            getOptionSelected={(option, value) => (option && option.value) === (value && value.value)}
            renderInput={(props) => (
              <TextField
                name='role'
                type='text'
                label={title}
                disableMargin
                {...props}
              />
            )}
          />
        </Typography>
      ))}
    </>
  )
})
