import React from 'react';
import {Link} from '../../../../../../components/antd/Typography';

export function Terms() {
  return (
    <Link
      target='_blank'
      href='https://www.smartpush.ai/terms-of-use/'
    >Terms
    </Link>
  )
}
