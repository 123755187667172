import {getState} from '../index';
import {createSelector} from 'reselect';
import {Apps} from '../../../types/apps';

export const _appsReducer = createSelector(getState, (application) => {
  return application.apps;
});

export const _apps = createSelector(_appsReducer, (apps) => {
  return apps.entities;
});

export const _firstApp = createSelector(_apps, (apps) => {
  return apps?.[0];
});

export const _firstAppUUID = createSelector(_firstApp, (firstApp) => {
  return firstApp?.uuid;
});

export const _appsLength = createSelector(_apps, (apps) => {
  return apps?.length;
});

export const _currentApp = createSelector(_appsReducer, (apps) => {
  return apps.entity;
});

export const _currentAppTitle = createSelector(_currentApp, (app) => {
  return app?.title;
});

export const _currentAppDetails = createSelector(_currentApp, (app) => {
  return app?.details;
});

export const _creationStep = createSelector(_currentAppDetails, (details) => {
  return Number(details?.creation_step) || void 0;
});

export const _integrationIsCompleted = createSelector(_currentAppDetails, (details) => {
  if (details?.integration_state === undefined) {
    return true;
  }
  // 1 - success, 2 - fail
  return Number(details.integration_state) === 1;
});

export const _currentAppValidation = createSelector(_currentAppDetails, (details) => {
  return Object.values<Apps.EntityDetailsValidation>(details?.validation || {}).find((f) => f.uuid && f.script_exist);
});

export const _subscriptionState = createSelector(_currentApp, (app) => {
  return !!app.subscription_enabled;
});

export const _customPromptState = createSelector(_currentApp, (app) => {
  return !!app.custom_prompt_enabled;
});

export const _languages = createSelector(_currentApp, (app) => {
  return app.languages;
});

export const _countries = createSelector(_currentApp, (app) => {
  return app.countries;
});

export const _currentAppStats = createSelector(_currentApp, (app) => {
  return app?.stats || {};
});

export const _dataOptions = createSelector(_appsReducer, (apps) => {
  return apps.dataOptions;
});

export const _downloadingSWFile = createSelector(_dataOptions, (options) => {
  return options?.isDownloading;
});

export const _downloadingDoc = createSelector(_dataOptions, (options) => {
  return options?.isDownloadingDoc;
});

export const _errorCode = createSelector(_dataOptions, (options) => {
  return options?.errorCode;
});

export const _temporaryTitle = createSelector(_dataOptions, (options) => {
  return options?.temporaryTitle;
});

export const _savingStep = createSelector(_dataOptions, (options) => {
  return options?.savingStep;
});

export const _emailError = createSelector(_dataOptions, (options) => {
  return options?.emailError;
});

export const _sendingToDeveloper = createSelector(_dataOptions, (options) => {
  return options?.sendingToDeveloper;
});

export const _fetchingAppState = createSelector(_dataOptions, (options) => {
  return options?.fetchingAppState;
});

export const _fetchingAppsState = createSelector(_dataOptions, (options) => {
  return options?.fetchingAppsState;
});

export const _isTogglingSubscription = createSelector(_dataOptions, (dataOptions) => {
  return !!dataOptions?.isTogglingSubscription;
});

export const _welcomeMessageUuid = createSelector(_currentApp, (app) => {
  return app?.welcome_nf_uuid;
});
