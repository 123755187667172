import React from 'react';
import {Empty} from 'antd';
import {Table} from 'antd';
import {Button} from 'antd';
import {Tooltip} from 'antd';
import {Breadcrumb} from 'antd';
import {useIndex} from './useIndex';
import {aclKeys} from '../../../../../constants/aclKeys';
import {TableHeader} from '../../components/TableHeader';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {Content} from '../../../../../components/antd/Content';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import {FiniteStates} from '../../../../../constants/finiteStates';
import {Navigation} from '../../../../../components/antd/Navigation';
import {EmptyTextDescription} from '../../components/TableLocale/EmptyText';

export function Index() {
  const {
    total,
    create,
    columns,
    current,
    onReload,
    onExport,
    pageSize,
    canCreate,
    templates,
    exportState,
    tableOnChange,
    templatesState,
  } = useIndex();

  return (
    <>
      <Navigation
        title='Templates'
        breadcrumbItems={[
          <Breadcrumb.Item
            key='app.templates'
          >Templates
          </Breadcrumb.Item>
        ]}
        extra={[
          canCreate(aclKeys.TEMPLATES) && (
            <Button
              type='primary'
              onClick={create}
              key='newTemplate'
              icon={<PlusOutlined/>}
            >New Template
            </Button>
          ),
          <Tooltip
            key='ExportAsCSV'
            title='Export as CSV'
          >
            <Button
              type='default'
              onClick={onExport}
              icon={<DownloadOutlined/>}
              disabled={(exportState === FiniteStates.LOADING) || !total}
            />
          </Tooltip>,
          <Button
            type='default'
            key='reloadData'
            onClick={onReload}
            icon={<ReloadOutlined/>}
            disabled={templatesState === FiniteStates.LOADING}
          />,
        ]}
      />
      <Content>
        <TableHeader/>
        <Table
          rowKey='id'
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<EmptyTextDescription/>}
              />
            )
          }}
          columns={columns}
          dataSource={templates}
          onChange={tableOnChange}
          loading={templatesState === FiniteStates.LOADING}
          scroll={{x: true, scrollToFirstRowOnChange: true}}
          pagination={{showQuickJumper: true, total, current, pageSize}}
        />
      </Content>
    </>
  )
}
