import React from 'react';
import {useParams, useHistory} from 'react-router';
import {Menu, Dropdown, Row} from 'antd';
import {urlHelper} from '../../../../utils/urlHelper';
import {aclKeys} from '../../../../constants/aclKeys';
import {AppRoutes} from '../../../../constants/appRoutes';
import {MoreOutlined, EditFilled, DeleteFilled} from '@ant-design/icons';
import {Users} from '../../../../types/users';
import DeleteDialog from '../DeleteDialog';
import {useSmartPush} from '../../../../hooks/useSmartPush';

interface IProps {
  row: Users.Entity,
}

const iconStyle = {
  marginRight: 10,
  marginTop: 2,
  color: '#0000008a',
};

const ActionCell: React.FC<IProps> = ({ row }) => {
  const history = useHistory();
  const [visible, setVisible] = React.useState(false);
  const {appUuid} = useParams<{ appUuid: string }>();
  const {
    user:{
      canUpdate,
      canDelete,
    }
  } = useSmartPush();

  const menu = (
    <Menu>
      {canUpdate(aclKeys.USERS) && (
        <Menu.Item
          key={row.id}
          onClick={() => history.push(urlHelper(AppRoutes.UPDATE_USER, {appUuid, uuid: row.uuid}))}
        >
          <Row align='middle'>
            <EditFilled style={iconStyle}/>
            <div>Edit</div>
          </Row>
        </Menu.Item>
      )}
      {canDelete(aclKeys.USERS) && (
        <Menu.Item
          key={row.uuid}
          onClick={() => setVisible(true)}
        >
          <Row align='middle'>
            <DeleteFilled style={{...iconStyle, color: '#f44336'}}/>
            <div>Archive</div>
          </Row>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      {visible &&
        <DeleteDialog
          visible={visible}
          onCancel={() => setVisible(false)}
          rowData={row}
        />
      }
      <Dropdown overlay={menu} trigger={['click']} key={row.id}>
        <MoreOutlined style={{fontSize: '18px'}}/>
      </Dropdown>
    </>
  );
}

export default ActionCell;
