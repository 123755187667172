import {stringify} from 'querystring';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';

export const _fetchTags = async (
  appUuid: string,
  apiBaseUrl: string,
  accessToken: string,
  pageNumber: number,
  searchText?: string
  ) => {
  try {
    const response = await fetch(`${apiBaseUrl}${urlHelper(ApiRoutes.TEMPLATES_TAGS, {
      appUuid,
    })}?${stringify({
      pageNumber,
      searchText,
    })}`, {
      method : 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.ok) {
      const { data } = await response.json();
      return data;
    }
  } catch (e) {
    console.log(e);
  }
};