import React from 'react';
import {Tab} from '../../Tabs';
import {Tabs} from '../../Tabs';
import {Preview} from './Preview';
import {TabPanel} from '../../Tabs';
import {HasError} from '../HasError';
import {Locales} from '../../Locales';
import {RemoveTab} from '../RemoveTab';
import Box from '@material-ui/core/Box';
import {useSelector} from 'react-redux';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {Select} from '../../AsyncPaginate';
import {TemplateHtml} from './TemplateHtml';
import {useTranslation} from 'react-i18next';
import {POSITIONS} from '../../../constants';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import {useFieldArray} from 'react-hook-form';
import {SendByWrapper} from './SendByWrapper';
import {Theme} from '@material-ui/core/styles';
import {TextField} from '../../BorderedTextField';
import {ColorAdornment} from '../../ColorAdornment';
import {MessageTextField} from './MessageTextField';
import {IconUrlTextField} from './IconUrlTextField';
import Typography from '@material-ui/core/Typography';
import LanguageIcon from '@material-ui/icons/Language';
import {UseFormReturn} from 'react-hook-form/dist/types';
import {DenyButtonTextField} from './DenyButtonTextField';
import {DirectionFormControl} from './DirectionFormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {DenyButtonColorTextField} from './DenyButtonColorTextField';
import {_hasLocalesAccess} from '../../../store/selectors/application/acl';
import {DenyButtonTextColorTextField} from './DenyButtonTextColorTextField';

const useStyles = makeStyles((theme: Theme) => ({
  root            : {
    display                       : 'flex',
    '& > div'                     : {
      width: '50%',
    },
    '& > div:last-child'          : {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > div'    : {
        width: '100%',
      },
    }
  },
  promptActions   : {
    display                       : 'flex',
    '& > div'                     : {
      width: '50%',
    },
    '& > div:first-child'         : {
      marginRight: 8,
    },
    '& > div:last-child'          : {
      marginRight: 8,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& > div'    : {
        marginLeft : 0,
        marginRight: 0,
        width      : '100%',
      },
    }
  },
  contentContainer: {
    display                       : 'flex',
    '& > div'                     : {
      flexGrow: 1,
    },
    '& > div:first-child'         : {
      marginRight: 8,
    },
    '& > div:last-child'          : {
      marginLeft: 8,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection        : 'column',
      '& > div:first-child': {
        marginRight: 0,
      },
      '& > div:last-child' : {
        marginLeft: 0,
      },
    }
  },
}));

export function PromptMessages(
  {
    form,
    disabled,
    isToggling,
  }: {
    disabled?: boolean;
    isToggling?: boolean;
    form: UseFormReturn<Record<string, any>>;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();
  const hasLocalesAccess = useSelector(_hasLocalesAccess);

  const {
    control,
    setValue,
    register,
    getValues,
    formState,
  } = form;

  useWatch({
    control,
    name: 'tab_value',
  });

  const formFieldArray = useFieldArray<any>({
    control,
    name: 'messages',
  });
  const {
    fields,
  } = formFieldArray;

  const getError = React.useCallback((name: string, index: number) => {
    return (formState.errors?.messages as any)?.[index]?.[name]?.message || '';
  }, [
    formState,
  ]);

  const onRemove = React.useCallback((item: any, index: number) => () => {
    const tabValue = getValues('tab_value');
    const previousItem: any = formFieldArray.fields[index - 1];

    if (previousItem && (tabValue === item.iso)) {
      setValue('tab_value', previousItem.iso);
    }

    formFieldArray.remove(index);
  }, [
    setValue,
    getValues,
    formFieldArray,
  ]);

  const tabsOnChange = React.useCallback((event: React.ChangeEvent<any>, newValue: string) => {
    if (!!([...event?.target?.classList]).find((className: string) => {
      return [
        'box',
        'item.text',
        'MuiTab-root',
        'MuiTab-wrapper',
      ].includes(className)
    })) {
      if (getValues('tab_value') !== newValue) {
        setValue('tab_value', newValue);
      }
    }
  }, [
    setValue,
    getValues,
  ]);

  return (
    <Box>
      {hasLocalesAccess && (
        <SendByWrapper
          form={form}
          disabled={disabled}
          isToggling={isToggling}
        />
      )}
      <Box height='16px'/>
      <AppBar
        elevation={0}
        color='transparent'
        variant='elevation'
        position='relative'
        style={{zIndex: 995}}
      >
        <Box
          width='100%'
          display='flex'
        >
          <Tabs
            textColor='primary'
            scrollButtons='auto'
            variant='scrollable'
            onChange={tabsOnChange}
            value={getValues('tab_value')}
          >
            {fields.map((item: Record<string, any>, index: number) => {
              return (
                <Tab
                  key={item.id}
                  value={item.iso}
                  disabled={disabled}
                  label={(
                    <Box
                      display='flex'
                      className='box'
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Hidden
                        smUp
                      >
                        <LanguageIcon
                          fontSize='small'
                        />
                      </Hidden>
                      <Hidden
                        xsDown
                      >
                        <Typography
                          noWrap
                          align='center'
                          className='item.text'
                        >{item.text}
                        </Typography>
                      </Hidden>
                      {(index > 0) && (
                        <RemoveTab
                          item={item}
                          remove={onRemove(item, index)}
                        />
                      )}
                      {(item.iso !== getValues('tab_value')) && (
                        <HasError
                          form={form}
                          index={index}
                        />
                      )}
                    </Box>
                  )}
                />
              )
            })}
          </Tabs>
          {hasLocalesAccess && (
            <Locales
              form={form}
              disabled={disabled}
              formFieldArray={formFieldArray}
            />
          )}
        </Box>
      </AppBar>
      {fields.map((item: any, index: number) => {
        return (
          <TabPanel
            key={item.id}
            index={item.iso}
            value={getValues('tab_value')}
          >
            <input
              type='hidden'
              {...register(`messages.[${index}]._id`)}
            />
            <input
              type='hidden'
              {...register(`messages.[${index}].iso`)}
            />
            <input
              type='hidden'
              {...register(`messages.[${index}].text`)}
            />
            <input
              type='hidden'
              {...register(`messages.[${index}].template_id`)}
            />
            <input
              type='hidden'
              {...register(`messages.[${index}].template_html`)}
            />
            <Typography
              component='div'
              className={classes.root}
            >
              <Typography
                component='div'
                className={classes.contentContainer}
              >
                <Typography
                  component='div'
                >
                  <DirectionFormControl
                    index={index}
                    control={control}
                    disabled={disabled}
                  />
                  <Controller
                    control={control}
                    name={`messages.[${index}].position`}
                    render={({field}) => (
                      <Select
                        {...field}
                        isDisabled={disabled}
                        label={t('app_settings.position')}
                        options={POSITIONS.map((position) => ({
                          value: position,
                          label: t(`app_settings.${position}`),
                        }))}
                      />
                    )}
                  />
                  <MessageTextField
                    index={index}
                    control={control}
                    getError={getError}
                    disabled={disabled}
                    setValue={setValue}
                    getValues={getValues}
                  />
                  <Controller
                    control={control}
                    name={`messages.[${index}].message_text_color`}
                    render={({field: {ref, value, ...fieldRest}}) => (
                      <TextField
                        fullWidth
                        value={value}
                        inputRef={ref}
                        margin='normal'
                        autoComplete='off'
                        inputProps={{
                          readOnly: true,
                          disabled: false,
                        }}
                        InputProps={{
                          endAdornment: (
                            <ColorAdornment
                              disabled={disabled}
                              setValue={setValue}
                              name={fieldRest.name}
                              getValues={getValues}
                            />
                          ),
                        }}
                        disabled={disabled}
                        label={t('app_settings.message_text_color')}
                        error={!!getError('message_text_color', index)}
                        helperText={getError('message_text_color', index)}
                        {...fieldRest}
                      />
                    )}
                  />
                  <IconUrlTextField
                    index={index}
                    control={control}
                    getError={getError}
                    disabled={disabled}
                    setValue={setValue}
                    getValues={getValues}
                  />
                  <Controller
                    control={control}
                    name={`messages.[${index}].bg_color`}
                    render={({field: {ref, value, ...fieldRest}}) => (
                      <TextField
                        fullWidth
                        value={value}
                        inputRef={ref}
                        margin='normal'
                        autoComplete='off'
                        inputProps={{
                          readOnly: true,
                          disabled: false
                        }}
                        InputProps={{
                          endAdornment: (
                            <ColorAdornment
                              disabled={disabled}
                              setValue={setValue}
                              name={fieldRest.name}
                              getValues={getValues}
                            />
                          ),
                        }}
                        disabled={disabled}
                        error={!!getError('bg_color', index)}
                        helperText={getError('bg_color', index)}
                        label={t('app_settings.background_color')}
                        {...fieldRest}
                      />
                    )}
                  />
                  <Typography
                    component='div'
                    className={classes.promptActions}
                  >
                    <Typography
                      component='div'
                    >
                      <Controller
                        control={control}
                        name={`messages.[${index}].allow_text`}
                        render={({field: {ref, ...fieldRest}}) => (
                          <TextField
                            fullWidth
                            inputRef={ref}
                            margin='normal'
                            autoComplete='off'
                            disabled={disabled}
                            inputProps={{
                              disabled: false,
                              readOnly: disabled,
                            }}
                            error={!!getError('allow_text', index)}
                            helperText={getError('allow_text', index)}
                            label={t('app_settings.allow_button_text')}
                            {...fieldRest}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={`messages.[${index}].allow_text_color`}
                        render={({field: {ref, value, ...fieldRest}}) => (
                          <TextField
                            fullWidth
                            value={value}
                            inputRef={ref}
                            margin='normal'
                            autoComplete='off'
                            InputProps={{
                              endAdornment: (
                                <ColorAdornment
                                  disabled={disabled}
                                  setValue={setValue}
                                  name={fieldRest.name}
                                  getValues={getValues}
                                />
                              ),
                            }}
                            inputProps={{
                              readOnly: true,
                              disabled: false,
                            }}
                            disabled={disabled}
                            error={!!getError('allow_text_color', index)}
                            helperText={getError('allow_text_color', index)}
                            label={t('app_settings.allow_button_text_color')}
                            {...fieldRest}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={`messages.[${index}].allow_button_color`}
                        render={({field: {ref, value, ...fieldRest}}) => (
                          <TextField
                            fullWidth
                            value={value}
                            inputRef={ref}
                            margin='normal'
                            autoComplete='off'
                            InputProps={{
                              endAdornment: (
                                <ColorAdornment
                                  disabled={disabled}
                                  setValue={setValue}
                                  name={fieldRest.name}
                                  getValues={getValues}
                                />
                              ),
                            }}
                            inputProps={{
                              readOnly: true,
                              disabled: false,
                            }}
                            disabled={disabled}
                            label={t('app_settings.allow_button_color')}
                            error={!!getError('allow_button_color', index)}
                            helperText={getError('allow_button_color', index)}
                            {...fieldRest}
                          />
                        )}
                      />
                    </Typography>
                    <Typography
                      component='div'
                    >
                      <DenyButtonTextField
                        index={index}
                        control={control}
                        disabled={disabled}
                        getError={getError}
                      />
                      <DenyButtonTextColorTextField
                        index={index}
                        control={control}
                        getError={getError}
                        disabled={disabled}
                        setValue={setValue}
                        getValues={getValues}
                      />
                      <DenyButtonColorTextField
                        index={index}
                        control={control}
                        getError={getError}
                        disabled={disabled}
                        setValue={setValue}
                        getValues={getValues}
                      />
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
              <Preview
                index={index}
                control={control}
                disabled={disabled}
              />
            </Typography>
            <TemplateHtml
              index={index}
              control={control}
              disabled={disabled}
              setValue={setValue}
            />
          </TabPanel>
        );
      })}
    </Box>
  )
}

