import React from 'react';
import Box from '@material-ui/core/Box';
import {IconButton} from '../../IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {ColumnChooser} from '@devexpress/dx-react-grid-material-ui';

export default function ToggleButtonComponent(
  {
    onToggle,
    buttonRef,
    getMessage,
  }: ColumnChooser.ToggleButtonProps
) {
  return (
    <Tooltip
      title={getMessage('showColumnChooser')}
    >
      <div>
        <Box
          marginLeft={.5}
          marginRight={.5}
        >
          <IconButton
            size='small'
            onClick={onToggle}
            buttonRef={buttonRef}
            data-id='toggle-column-chooser'
          ><VisibilityOffIcon/>
          </IconButton>
        </Box>
      </div>
    </Tooltip>
  )
}
