import {notification} from 'antd';
import {_dataOptions} from './index';
import {stringify} from 'querystring';
import {filterObject} from '../../../utils';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import * as selectors from '../../selectors/templates';
import {FiniteStates} from '../../../constants/finiteStates';

export const _export = (appUuid: string, {
  exportType,
}: {
  exportType: 'csv'
}): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    exportTemplatesState: FiniteStates.LOADING,
  }));

  const tags = selectors._tags(getState());
  const pageSize = selectors._pageSize(getState());
  const pageNumber = selectors._pageNumber(getState());
  const searchText = selectors._searchText(getState());
  const sorterOrder = selectors._sorterOrder(getState());
  const sorterField = selectors._sorterField(getState());

  const params = stringify(filterObject({
    pageSize,
    pageNumber,
    searchText,
    export   : exportType,
    sortName : sorterField,
    tags     : tags?.join(','),
    sortOrder: (sorterOrder === 'ascend') ? 'asc' : 'desc',
  }));

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.get(`${urlHelper(ApiRoutes.LIST_TEMPLATES, {
    appUuid,
  })}?${params}`, {
    skipDelay: true,
  });

  if (!hasError) {
    notification.success({
      message: data.message,
    });
  } else {
    notification.error({
      message: data?.errors?.limits_notice || message,
    });
  }

  dispatch(_dataOptions({
    exportTemplatesState: FiniteStates.SUCCESS,
  }));
};