import React from 'react';
import {useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {getIconsData} from '../../../store/actions/builder/icon/services';
import {getFilters} from '../../../store/selectors/icons';
import FilterSection from './FilterSection';
import IconsView from './IconsView';

const Icons: React.FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);
  const {appUuid} = useParams<{ appUuid: string }>();

  const onReload = () => {
    dispatch(getIconsData(appUuid));
  };

  React.useEffect(() => {
    dispatch(getIconsData(appUuid));
  }, [dispatch, appUuid, filters.pageNumber, filters.pageSize, filters.tags]);

  return (
    <>
      <FilterSection onReload={onReload} />
      <IconsView />
    </>
  );
};

export default Icons;
