import {Space} from 'antd';
import {ExcludeAction} from '../../TableActions/Exclude';
import {Templates} from '../../../../../../../types/templates';

export function Actions(record: Templates.EntityDetail) {
  return (
    <Space>
      <ExcludeAction
        row={record}
      />
    </Space>
  )
}