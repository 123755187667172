import {Moment} from 'moment';
import {_setData} from './index';
import {_dataOptions} from './index';
import {CancelTokenSource} from 'axios';
import {AbortError} from '../../../../constants';
import {Globals} from '../../../../types/globals';
import {urlHelper} from '../../../../utils/urlHelper';
import {ApiRoutes} from '../../../../constants/apiRoutes';
import {FiniteStates} from '../../../../constants/finiteStates';
import {_fetch as _fetchClicks} from '../../../actions/reports/clicks/fetch';
import {_isUnique as _isUniqueClicks} from '../../../selectors/reports/clicks';
import {_fetch as _fetchDisplays} from '../../../actions/reports/displays/fetch';
import {_isUnique as _isUniqueDisplays} from '../../../selectors/reports/displays';
import {_fetchingState as _clicksFetchingState} from '../../../selectors/reports/clicks';
import {_fetchingState as _displaysFetchingState} from '../../../selectors/reports/displays';

export const _fetch = (
  appUuid: string,
  dates: [Moment, Moment],
  {
    cancelTokenSource,
  }: {
    cancelTokenSource?: CancelTokenSource;
  } = {}
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    fetchingState: FiniteStates.LOADING
  }));

  const to = dates[1].format('YYYY-MM-DD');
  const from = dates[0].format('YYYY-MM-DD');

  let unique = 0;

  const isUniqueClicks = _isUniqueClicks(getState());
  const isUniqueDisplays = _isUniqueDisplays(getState());

  if (isUniqueClicks && isUniqueDisplays) {
    unique = 1;
  }

  const clicksFetchingState = _clicksFetchingState(getState())
  const displaysFetchingState = _displaysFetchingState(getState())

  if (clicksFetchingState === FiniteStates.IDLE) {
    await dispatch(_fetchClicks(appUuid, dates));
  }

  if (displaysFetchingState === FiniteStates.IDLE) {
    await dispatch(_fetchDisplays(appUuid, dates));
  }

  const {
    hasError,
    body: {
      data,
      name,
    },
  } = await api.get(`${urlHelper(ApiRoutes.CTR_STATS, {
    appUuid,
  })}?to=${to}&from=${from}&unique=${unique}`, {
    cancelToken: cancelTokenSource?.token,
  });

  if (!hasError) {
    dispatch(_setData(data));

    dispatch(_dataOptions({
      fetchingState: FiniteStates.SUCCESS,
    }));
  } else {
    if (name === AbortError) {
      dispatch(_setData([]));
      dispatch(_dataOptions({
        fetchingState: FiniteStates.IDLE,
      }));
    } else {
      dispatch(_dataOptions({
        messages     : data,
        fetchingState: FiniteStates.FAILURE,
      }));
    }
  }
};