import React from 'react';
import {Theme} from '@material-ui/core/styles';
import MuiInputLabel from '@material-ui/core/InputLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useInputLabel = makeStyles(({palette}: Theme) => ({
  root   : {
    fontSize                                                      : '14px',
    color                                                         : palette.text.primary,
    '&$focused:not($error)'                                       : {
      color: palette.text.primary,
    },
    '&.Mui-required .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red',
    },
  },
  error  : {},
  focused: {},
  shrink : {
    fontWeight: 400,
    transform : 'translate(0, -1px)',
  },
}));

export function InputLabel(
  {
    label,
    error,
    disabled,
    required,
  }: {
    error?: boolean;
    disabled?: boolean;
    required?: boolean;
    label: React.ReactNode;
  }
) {
  const inputLabelStyles = useInputLabel();

  return (
    <MuiInputLabel
      shrink={true}
      error={error}
      required={required}
      disabled={disabled}
      classes={{...inputLabelStyles}}
    >{label}
    </MuiInputLabel>
  )
}
