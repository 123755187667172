import {Form} from 'antd';
import React from 'react';
import {Input} from 'antd';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {ICreateAppForm} from '../../../../useUpsertApp';
import {Control} from 'react-hook-form/dist/types/form';
import {ucFirst} from '../../../../../../../../../utils';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';

function guessTitle(defaultUrl: string = '') {
  let title: any = '';
  try {
    defaultUrl = defaultUrl.trim();

    title = (new URL(defaultUrl)).hostname;

    if (String(title).toLowerCase().startsWith('www.')) {
      title = String(title).toLowerCase().replace(/^(www\.)/, "");
    }
  } catch (e) {
  }

  return title;
}

export function SiteTitle(
  {
    control,
    isDirty,
    setValue,
    toggleFocusState,
  }: {
    isDirty?: true;
    control: Control<ICreateAppForm>;
    toggleFocusState: React.Dispatch<boolean>;
    setValue: UseFormSetValue<ICreateAppForm>;
  }
) {
  const domain = useWatch({
    control,
    name: 'domain',
  });

  React.useEffect(() => {
    if (!isDirty) {
      setValue('title', ucFirst(guessTitle(domain)), {
        shouldDirty   : false,
        shouldTouch   : false,
        shouldValidate: false,
      })
    }
  }, [
    domain,
    isDirty,
    setValue,
  ]);

  return (
    <Controller
      name='title'
      control={control}
      render={(
        {
          field     : {
            ref,
            value,
            onBlur,
            ...fieldRest
          },
          fieldState: {
            error,
            invalid,
          }
        }
      ) => {
        return (
          <Form.Item
            label='Site Title'
            help={error?.message}
            validateStatus={invalid ? 'error' : void 0}
          >
            <Input
              ref={ref}
              value={value || ''}
              onBlur={() => {
                onBlur();
                toggleFocusState(false);
              }}
              placeholder='Your site title'
              onFocus={() => toggleFocusState(true)}
              {...fieldRest}
            />
          </Form.Item>
        )
      }}
    />
  )
}
