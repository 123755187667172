import React from 'react';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import backIcon from '../../images/back.svg';
import {Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root      : {
    display                       : 'flex',
    alignItems                    : 'center',
    marginBottom                  : theme.spacing(2),
    marginTop                     : 0,
    marginLeft                    : 0,
    width                         : '100%',
    [theme.breakpoints.down('md')]: {
      marginLeft  : 0,
      width       : '100%',
      display     : 'flex',
      marginTop   : theme.spacing(1),
      marginBottom: theme.spacing(2),
    }
  },
  wrapper   : {
    display   : 'flex',
    alignItems: 'center',
    cursor    : 'pointer',
  },
  typography: {
    lineHeight: 1,
    marginLeft: 2,
    fontSize  : 12,
    fontWeight: 'bold'
  },
}));

export function Back(
  {
    url,
    text,
    style,
  }: {
    url?: string;
    text?: string;
    style?: React.CSSProperties;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();
  const history = useHistory();

  const onClick = React.useCallback(() => {
    if (url) {
      history.push(url)
    } else {
      history.goBack()
    }
  }, [
    url,
    history,
  ])

  return (
    <Typography
      style={style}
      component='div'
      className={classes.root}
    >
      <Typography
        component='div'
        onClick={onClick}
        className={classes.wrapper}
      >
        <img
          alt='Back'
          src={backIcon}
        />
        <Typography
          className={classes.typography}
        >{text || t('back')}
        </Typography>
      </Typography>
    </Typography>
  )
}