import React from 'react';
import {Menu} from 'antd';
import {Badge} from 'antd';
import {Space} from 'antd';
import {Avatar} from 'antd';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {Text} from '../../../../../Typography';
import useMediaQuery from 'use-media-antd-query';
import {ItemType} from 'antd/lib/menu/hooks/useItems';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import {urlHelper} from '../../../../../../../utils/urlHelper';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import {surveyState} from '../../../../../../../constants/survey';
import {AppRoutes} from '../../../../../../../constants/appRoutes';
import CreditCardOutlined from '@ant-design/icons/CreditCardOutlined';
import {surveyIsEnabled} from '../../../../../../../utils/surveyIsEnabled';
import {_email} from '../../../../../../../store/selectors/application/acl';
import {_logout} from '../../../../../../../store/actions/application/logout';
import {_fullName} from '../../../../../../../store/selectors/application/acl';
import {_shortName} from '../../../../../../../store/selectors/application/acl';
import {_firstName} from '../../../../../../../store/selectors/application/acl';
import {_surveyData} from '../../../../../../../store/selectors/application/acl';
import {_isVerified} from '../../../../../../../store/selectors/application/acl';
import {_showPlanChange} from '../../../../../../../store/selectors/application/acl';
import {_hasPendingEmail} from '../../../../../../../store/selectors/application/acl';

export function useProfile(
  {
    appUuid,
  }: {
    appUuid: string | undefined;
  }
) {
  const history = useHistory();
  const dispatch = useDispatch();
  const colSize = useMediaQuery();
  const email = useSelector(_email);
  const fullName = useSelector(_fullName);
  const shortName = useSelector(_shortName);
  const firstName = useSelector(_firstName);
  const isVerified = useSelector(_isVerified);
  const surveyData = useSelector(_surveyData);
  const showPlanChange = useSelector(_showPlanChange);
  const hasPendingEmail = useSelector(_hasPendingEmail);

  const dropdownProps: {
    placement?: 'bottomRight',
    overlayStyle?: React.CSSProperties,
  } = React.useMemo(() => {
    if (colSize === 'xs') {
      return {
        placement   : 'bottomRight',
        overlayStyle: {
          width: '100%',
        }
      }
    } else {
      return {}
    }
  }, [
    colSize,
  ]);

  const isProfilePaths = React.useMemo(() => {
    return [
      AppRoutes.API_KEYS,
      AppRoutes.SECURITY,
      AppRoutes.CHANGE_PLAN,
      AppRoutes.MANAGE_ACCOUNT,
      AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS,
      urlHelper(AppRoutes.SECURITY_APP, {appUuid}),
      urlHelper(AppRoutes.API_KEYS_APP, {appUuid}),
      urlHelper(AppRoutes.CHANGE_PLAN_APP, {appUuid}),
      urlHelper(AppRoutes.MANAGE_ACCOUNT_APP, {appUuid}),
      urlHelper(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP, {appUuid}),
    ].includes(history.location.pathname);
  }, [
    appUuid,
    history,
  ]);

  const logout = React.useCallback(() => {
    dispatch(_logout())
  }, [
    dispatch,
  ]);

  const manageAccount = React.useCallback(() => {
    if (appUuid) {
      history.push(urlHelper(AppRoutes.MANAGE_ACCOUNT_APP, {appUuid}));
    } else {
      history.push(AppRoutes.MANAGE_ACCOUNT);
    }
  }, [
    history,
    appUuid,
  ]);

  const completePersonalization = React.useCallback(async () => {
    if (appUuid) {
      history.push({
        state   : {
          from: history.location.pathname
        },
        pathname: urlHelper(AppRoutes.SURVEY_APP, {appUuid}),
      });
    } else {
      history.push({
        state   : {
          from: history.location.pathname
        },
        pathname: AppRoutes.SURVEY,
      });
    }
  }, [
    history,
    appUuid,
  ]);

  const paymentsSubscriptions = React.useCallback(() => {
    if (appUuid) {
      history.push(urlHelper(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP, {appUuid}));
    } else {
      history.push(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS);
    }
  }, [
    history,
    appUuid,
  ]);

  const showCompletePersonalization = (surveyData?.state === surveyState.SKIPPED) || !surveyData?.state;

  const hasDot = !isVerified || hasPendingEmail || (surveyIsEnabled() ? showCompletePersonalization : false);

  const items: ItemType[] = [
    {
      key      : 'full-name-width-menu-item',
      className: 'full-name-width-menu-item',
      onClick  : manageAccount,
      label    : (
        <Space
          className='profile-space'
        >
          <Avatar
            className='avatar'
          >{shortName}
          </Avatar>
          <div
            className='d-flex flex-column'
          >
            <Text
              className='full-name-width'
              ellipsis={{tooltip: fullName}}
            >{fullName}
            </Text>
            <Text
              type='secondary'
              fontSize='small'
              className='full-name-width'
              ellipsis={{tooltip: email}}
            >{email}
            </Text>
          </div>
        </Space>
      )
    },
    {
      type: 'divider'
    },
    surveyIsEnabled() && showCompletePersonalization && {
      onClick: completePersonalization,
      key    : 'complete-personalization',
      label  : 'Complete Personalization',
      icon   : <div className='complete-personalization-dot'/>
    },
    {
      onClick: manageAccount,
      label  : 'Manage Account',
      key    : 'manage-account',
      icon   : <Badge dot={!isVerified || hasPendingEmail}><SettingOutlined style={{fontSize: 12}}/></Badge>,
    },
    !showPlanChange && {
      onClick: paymentsSubscriptions,
      key    : 'payments-subscriptions',
      label  : 'Payments & subscriptions',
      icon   : <CreditCardOutlined style={{fontSize: 12}}/>,
    },
    {
      type: 'divider'
    },
    {
      onClick: logout,
      label  : 'Sign Out',
      key    : 'logout-outlined',
      icon   : <LogoutOutlined style={{fontSize: 12}}/>,
    }
  ];

  const overlayMenu = <Menu items={items}/>;

  return {
    hasDot,
    firstName,
    shortName,
    overlayMenu,
    dropdownProps,
    isProfilePaths,
    isMobile: [
      'sm',
      'xs',
      'md',
    ].includes(colSize),
  }
}
