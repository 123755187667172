import clsx from 'clsx';
import React from 'react';
import {Theme} from '@material-ui/core/styles';
import MuiCardHeader from '@material-ui/core/CardHeader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {CardHeaderProps} from '@material-ui/core/CardHeader';

type Color = 'primary' | 'disabled';

const useStyles = makeStyles(({palette}: Theme) => ({
  primary : {
    fontSize       : 16,
    fontWeight     : 500,
    color          : 'rgba(0,0,0,.85)',
    backgroundColor: palette.common.white,
  },
  disabled: {
    fontWeight     : 700,
    boxShadow      : 'none',
    color          : 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  default : {
    borderBottom: '1px solid #f0f0f0',
  },
}));

export function CardHeader(
  {
    color,
    classes,
    ...rest
  }: CardHeaderProps & { color?: Color }
): JSX.Element {
  const _classes: any = useStyles();

  return (
    <MuiCardHeader
      disableTypography
      classes={{
        ...classes,
        root: clsx(_classes[color as any], classes?.root, _classes?.default),
      }}
      {...rest}
    />
  )
}
