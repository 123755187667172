import {getState} from '../index';
import {createSelector} from 'reselect';

export const _locales = createSelector(getState, (state) => {
  return state.locales;
});

export const _status = createSelector(_locales, (locales) => {
  return locales.status;
});

export const _countries = createSelector(_locales, (locales) => {
  return locales.countries;
});

export const _languages = createSelector(_locales, (locales) => {
  return locales.languages;
});
