import React from 'react';
import {Result} from 'antd';
import {Button} from 'antd';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useRegister} from './useRegister';
import {FormContent} from './components/FormContent';
import {Redirecting} from '../../../Common/Redirecting';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {Spinner} from '../../../../../components/antd/Spinner';
import {Title} from '../../../../../components/antd/Typography';
import {FiniteStates} from '../../../../../constants/finiteStates';
import appSumoLogo from '../../../../../images/antd/appSumoLogo.svg';
import {_appSumoMessage} from '../../../../../store/selectors/application';
import {_upsertApplication} from '../../../../../store/actions/application';
import {_appSumoTokenCheckingState} from '../../../../../store/selectors/application';
import appSumoSmartPushLogo from '../../../../../images/antd/appSumoSmartPushLogo.png';
import {_checkAppSumoToken} from '../../../../../store/actions/application/checkAppSumoToken';
import '../../../index.less';

export function Register() {
  const dispatch = useDispatch();
  const {token} = useParams<{ token: string }>();
  const {executeRecaptcha} = useGoogleReCaptcha();
  const appSumoTokenCheckingState = useSelector(_appSumoTokenCheckingState);

  React.useEffect(() => {
    if (typeof executeRecaptcha === 'function') {
      dispatch(_checkAppSumoToken({token}, {executeRecaptcha}))
    }
  }, [
    token,
    dispatch,
    executeRecaptcha,
  ]);

  React.useEffect(() => {
    return function () {
      dispatch(_upsertApplication({
        appSumoTokenCheckingState: FiniteStates.IDLE,
      }));
    }
  }, [
    dispatch,
  ]);

  return [
    FiniteStates.IDLE,
    FiniteStates.LOADING,
  ].includes(appSumoTokenCheckingState) ? (
    <div
      className='loading-backdrop'
    ><Spinner/>
    </div>
  ) : (
    <RegisterFormWrapper/>
  )
}

export function RegisterFormWrapper() {
  const {
    control,
    onSubmit,
    clearErrors,
    formState: {
      isSubmitting,
      isSubmitSuccessful,
    },
  } = useRegister();
  const {replace} = useHistory();
  const appSumoMessage = useSelector(_appSumoMessage);
  const appSumoTokenCheckingState = useSelector(_appSumoTokenCheckingState);

  if (appSumoTokenCheckingState === FiniteStates.FAILURE) {
    return (
      <div
        className='loading-backdrop'
      >
        <Result
          status='error'
          title={appSumoMessage}
          extra={
            <Button
              type='primary'
              onClick={() => replace(AppRoutes.LOGIN)}
            >Back to Login
            </Button>
          }
        />
      </div>
    )
  }

  return (
    <>
      {!isSubmitSuccessful && (
        <>
          <div
            className='m-b-5'
          >
            <div
              className='m-b-2'
            >
              <Title
                level={4}
                align='center'
                fontWeight='medium'
                className='d-flex align-items-center justify-content-center'
              >
                Create a
                <img
                  alt='SmartPush Logo'
                  src={appSumoSmartPushLogo}
                  className='appsumo-smartpush-logo m-l-1 m-r-1'
                />
                account
              </Title>
            </div>
            <Title
              level={4}
              align='center'
              fontWeight='medium'
              className='d-flex align-items-center justify-content-center'
            >
              to redeem your
              <img
                src={appSumoLogo}
                alt='APPSUMO Logo'
                className='appsumo-logo m-l-1 m-r-1'
              />
              license
            </Title>
          </div>
        </>
      )}
      <form
        noValidate
        className='form'
        autoComplete='off'
        onSubmit={onSubmit}
      >
        {isSubmitSuccessful ? (
          <Redirecting
            title='You have Signed Up.'
            subTitle='Redirecting to the App ...'
          />
        ) : (
          <FormContent
            control={control}
            clearErrors={clearErrors}
            isSubmitting={isSubmitting}
          />
        )}
      </form>
    </>
  )
}
