import React from 'react';
import Box from '@material-ui/core/Box';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Control} from 'react-hook-form/dist/types/form';
import {Select} from '../../../../../../components/AsyncPaginate';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {ErrorMessage} from '../../../../../../components/ErrorMessage';

export function FilterField(
  {
    index,
    control,
    setValue,
    defaultValue,
    defaultTypeValue,
  }: {
    index: number;
    control: Control;
    defaultValue: Record<string, any>;
    defaultTypeValue: Record<string, any>;
    setValue: UseFormSetValue<Record<string, any>>
  }
) {
  const {t} = useTranslation();
  const queryType = useWatch({
    control,
    defaultValue: defaultTypeValue,
    name        : `query.${index}.type`,
  });

  const options = React.useMemo(() => queryType?.filters?.map((filter: string) => {
    if (
      (queryType.search === false) &&
      (queryType.type === 'number') &&
      ![
        'sessions',
        'conversion_sum',
        'conversion_count',
      ].includes(queryType.field)
    ) {
      return {
        value: filter,
        label: t(`params_1.${filter}`),
      };
    }
    return {
      value: filter,
      label: t(`params.${filter}`),
    };
  }), [
    t,
    queryType,
  ]);

  React.useEffect(() => {
    if (!defaultTypeValue?.value || (queryType?.value !== defaultTypeValue?.value)) {
      setValue(`query.${index}.filter`, '')
    }
    // eslint-disable-next-line
  }, [
    setValue,
    queryType?.value,
    defaultTypeValue?.value,
  ]);

  return (
    <Box
      width={!!queryType ? 200 : 0}
      marginLeft={!!queryType ? 1 : 0}
      marginRight={!!queryType ? 1 : 0}
    >
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={`query.${index}.filter`}
        render={(
          {
            field     : {
              ref,
              ...fieldRest
            },
            fieldState: {
              error: localError
            }
          }
        ) => {
          if (!queryType) {
            return null;
          }

          return (
            <>
              <Select
                ref={ref}
                isClearable
                placeholder=''
                options={options}
                isLoading={!options}
                hasError={!!localError?.message}
                {...fieldRest}
              />
              <ErrorMessage
                message={localError?.message}
              />
            </>
          );
        }}
      />
    </Box>
  )
}