import React from 'react';
import {Route} from 'react-router';
import {RouteProps} from 'react-router';
import {RouteComponentProps} from 'react-router';
import {AlertsProvider} from '../../../providers/Alert';
import {SurveyProvider} from '../../../providers/Survey';
import {LoadingProvider} from '../../../providers/Loading';
import {PGPeriodProvider} from '../../../providers/PGPeriod';
import {SuspendedProvider} from '../../../providers/Suspended';
import {VisibilityProvider} from '../../../providers/Visibility';
import {CongratulatoryProvider} from '../../../providers/Congratulatory';

export function Private(
  {
    visible,
    component: Component,
    ...rest
  }: IProps
) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  function renderRoute(routeProps: RouteComponentProps<Record<string, any>>) {
    const matchPath = routeProps?.match?.path;

    return (
      <SurveyProvider
        matchPath={matchPath}
        pathname={routeProps.location.pathname}
        appUuid={(rest as any)?.computedMatch?.params?.appUuid}
      >
        <PGPeriodProvider
          matchPath={matchPath}
        >
          <SuspendedProvider
            matchPath={matchPath}
          >
            <LoadingProvider>
              <VisibilityProvider
                visible={visible}
              >
                <AlertsProvider
                  matchPath={matchPath}
                >
                  <CongratulatoryProvider>
                    <Component
                      {...routeProps}
                    />
                  </CongratulatoryProvider>
                </AlertsProvider>
              </VisibilityProvider>
            </LoadingProvider>
          </SuspendedProvider>
        </PGPeriodProvider>
      </SurveyProvider>
    );
  }

  return (
    <Route
      {...rest}
      render={renderRoute}
    />
  );
}

interface IProps extends RouteProps {
  visible?: boolean;
}
