export const codespinnet = '<script type="text/javascript" src=":CDN_URL/push.min.js" async defer id=":APP_UUID"></script>';

export const codespinnet2 = '<script type="application/javascript">';

export const codespinnet_2 = '(function (){window.w_push_options={appId:":APP_UUID",autoShow:false,disableDialog:true,ttl:30,delay:1000,tags:{user_id: 123111}};})();';

export const codespinnet_2_1 = '(function () {';
export const codespinnet_2_2 = '    window.w_push_options = {';
export const codespinnet_2_3 = '        appId        : ":APP_UUID",';
export const codespinnet_2_4 = '        autoShow     : false,';
export const codespinnet_2_5 = '        disableDialog: true,';
export const codespinnet_2_6 = '        ttl          : 30,';
export const codespinnet_2_7 = '        delay        : 1000,';
export const codespinnet_2_8 = '        tags         : {';
export const codespinnet_2_9 = '            user_id: 123111';
export const codespinnet_2_10 = '        }';
export const codespinnet_2_11 = '    };';
export const codespinnet_2_12 = '})();';
