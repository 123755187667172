import React from 'react';
import {Button} from 'antd';
import {Breadcrumb} from 'antd';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {Cards} from './components/Cards';
import {Charts} from './components/Charts';
import {ReportsTable} from './components/Table';
import {useTitle} from '../../../../hooks/useTitle';
import {ChartSelect} from './components/ChartSelect';
import {ToggleTable} from './components/ToggleTable';
import {_get} from '../../../../store/actions/apps/get';
import {getPrefixCls} from '../../../../utils/getPrefixCls';
import {Content} from '../../../../components/antd/Content';
import {DateRangePicker} from './components/DateRangePicker';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {_initReports} from '../../../../store/actions/reports';
import {_resetReports} from '../../../../store/actions/reports';
import {FiniteStates} from '../../../../constants/finiteStates';
import {_reportsDates} from '../../../../store/selectors/reports';
import {_reportsState} from '../../../../store/selectors/reports';
import {Navigation} from '../../../../components/antd/Navigation';
import './index.less';

export function Reports() {
  const dispatch = useDispatch();
  useTitle('Reports | SmartPush');
  const state = useSelector(_reportsState);
  const reportsDates = useSelector(_reportsDates);
  const {appUuid} = useParams<{ appUuid: string }>();

  function reloadReports() {
    dispatch(_get(appUuid, {skipDelay: true}));
    dispatch(_initReports(appUuid, reportsDates));
  }

  React.useEffect(() => {
    localStorage.setItem('mounted', 'mounted');
    dispatch(_initReports(appUuid, reportsDates));
  }, [
    appUuid,
    dispatch,
    reportsDates,
  ]);

  React.useEffect(() => {
    return function () {
      localStorage.removeItem('mounted');
      dispatch(_resetReports());
    }
  }, [
    appUuid,
    dispatch,
  ]);

  return (
    <>
      <Navigation
        extra={[
          <Button
            type='default'
            key='ReloadReports'
            onClick={reloadReports}
            icon={<ReloadOutlined/>}
            disabled={state !== FiniteStates.SUCCESS}
          />
        ]}
        title='Reports'
        breadcrumbItems={[
          <Breadcrumb.Item
            key='app.reports'
          >Reports
          </Breadcrumb.Item>
        ]}
      />
      <Content>
        <Cards/>
        <div
          className={`m-t-2 justify-content-start w-100 ${getPrefixCls('analytics-reports-filtersContainer')}`}
        >
          <DateRangePicker/>
          <ChartSelect/>
          <ToggleTable/>
        </div>
        <Charts/>
        <ReportsTable/>
      </Content>
    </>
  )
}
