import React from 'react';
import {Typography, Switch, Row, Space} from 'antd';

const {Title, Text} = Typography;


interface IProps {
  headerText: string;
  checked: boolean;
  onChange: () => void;
}

const FormHeader: React.FC<IProps> = ({headerText, checked, onChange}) => {

  return (
    <Row align='middle' justify='space-between' style={{marginBottom: '30px'}}>
      <Title level={4}>
        {headerText}
      </Title>
      <Space align='center'>
        <Text strong>Active</Text>
        <Switch
          checked={checked}
          onChange={onChange}
        />
      </Space>
    </Row>
  );
};

export default FormHeader;
