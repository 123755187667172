import React from 'react';
import {Form} from 'antd';
import {Input} from 'antd';
import {Modal} from 'antd';
import {Alert} from 'antd';
import {Button} from 'antd';
import {Checkbox} from 'antd';
import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {ButtonProps} from 'antd/lib/button/button';
import {ApiKeys} from '../../../../../../../types/apiKeys';
import {Control} from 'react-hook-form/dist/types/form';
import {_modal} from '../../../../../../../store/selectors/apiKeys';
import {ModalTypes} from '../../../../../../../constants/modalTypes';
import {_dataOptions} from '../../../../../../../store/actions/apiKeys';
import {FormAlert} from '../../../../../../../components/antd/FormAlert';
import {_maxApiKeysCount} from '../../../../../../../store/selectors/application/acl';
import {_createOrUpdate} from '../../../../../../../store/actions/apiKeys/createOrUpdate';

const {Item} = Form;
const {confirm} = Modal;

export function Upsert(
  {
    defaultValues,
  }: {
    defaultValues: Partial<ApiKeys.Entity>;
  }
) {
  const {
    control,
    setError,
    register,
    clearErrors,
    handleSubmit,
    formState: {
      dirtyFields,
      isSubmitting,
    }
  } = useForm<ApiKeys.Entity>({
    defaultValues,
  });

  const dispatch = useDispatch();
  const modal = useSelector(_modal);
  const maxApiKeysCount = useSelector(_maxApiKeysCount);

  const onCancel = React.useCallback(() => {
    if (!!Object.values(dirtyFields).filter(f => f).length) {
      confirm({
        onOk() {
          dispatch(_dataOptions({
            row  : void 0,
            modal: void 0,
          }));
        },
        centered: true,
        type    : 'warning',
        title   : 'You have unsaved changes in your form. If you continue, your changes will be lost.',
      });
    } else {
      dispatch(_dataOptions({
        row  : void 0,
        modal: void 0,
      }));
    }
  }, [
    dispatch,
    dirtyFields,
  ]);

  const submit = React.useCallback((formValues: any) => {
    return dispatch(_createOrUpdate({
      setError,
      formValues: formValues
    }));
  }, [
    setError,
    dispatch,
  ]);

  return (
    <form
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(submit)}
    >
      <Modal
        okText='Save'
        footer={[
          <Button
            key='cancel'
            onClick={onCancel}
          >Cancel
          </Button>,
          <Submit
            key='save'
            type='primary'
            htmlType='submit'
            control={control}
            loading={isSubmitting}
          >Save
          </Submit>,
        ]}
        getContainer='form'
        onCancel={onCancel}
        title='Create API Key'
        confirmLoading={isSubmitting}
        visible={[ModalTypes.CREATE, ModalTypes.EDIT].includes(modal)}
      >
        <input type='hidden' {...register('id')}/>
        <Controller
          control={control}
          name='limits_notice'
          render={(
            {
              fieldState: {
                error,
              }
            }
          ) => {
            if (error?.message) {
              return (
                <FormAlert
                  type='error'
                  message={error?.message}
                  onClose={() => clearErrors('limits_notice')}
                />
              )
            } else if (!!maxApiKeysCount) {
              return (
                <Alert
                  showIcon
                  type='warning'
                  className='m-b-5'
                  message={`Your pricing plan allows having ${maxApiKeysCount} API Key`}
                />
              )
            }

            return null;
          }}
        />
        <Form
          component='div'
          className='form'
          layout='vertical'
        >
          <Controller
            name='name'
            control={control}
            render={(
              {
                field     : {
                  ref,
                  ...fieldRest
                },
                fieldState: {
                  error,
                  invalid,
                }
              }
            ) => {
              return (
                <Item
                  label='Name'
                  help={error?.message}
                  validateStatus={invalid ? 'error' : void 0}
                >
                  <Input
                    ref={ref}
                    autoFocus
                    autoComplete='off'
                    placeholder='e.g. For External Services'
                    {...fieldRest}
                  />
                </Item>
              );
            }}
          />
          <Controller
            name='enabled'
            control={control}
            render={(
              {
                field     : {
                  ref,
                  value,
                  ...fieldRest
                },
                fieldState: {
                  error,
                  invalid,
                }
              }
            ) => {
              return (
                <Item
                  help={error?.message}
                  validateStatus={invalid ? 'error' : void 0}
                >
                  <Checkbox
                    ref={ref}
                    checked={!!value}
                    {...fieldRest}
                  >Enabled
                  </Checkbox>
                </Item>
              );
            }}
          />
        </Form>
      </Modal>
    </form>
  )
}

function Submit(
  {
    control,
    ...props
  }: ButtonProps & { control: Control<ApiKeys.Entity> }
) {
  const name = useWatch({
    control,
    name: 'name',
  });

  return (
    <Button
      {...props}
      disabled={props?.disabled || !name?.trim()}
    />
  )
}
