import {getState} from '../index';
import {createSelector} from 'reselect';

export const getIcons = createSelector(getState, (state) => {
  return state.builder.icons;
});

export const getIconsData = createSelector(getIcons, (icons) => {
  return icons.dataOptions.entities;
});

export const getFetchState = createSelector(getIcons, (icons) => {
  return icons.dataOptions.fetched;
});

export const getTotal= createSelector(getIcons, (icons) => {
  return icons.dataOptions.total;
});

export const getFilters = createSelector(getIcons, (icons) => {
  return icons.filters;
});
