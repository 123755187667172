import clsx from 'clsx';
import React from 'react';
import {Button} from '../Button';
import DropZoneJS from 'dropzone';
import {DropzoneFile} from 'dropzone';
import {useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import {IconButton} from '../IconButton';
import List from '@material-ui/core/List';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import {Theme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import {_apiBaseUrl} from '../../store/selectors/application';
import {_accessToken} from '../../store/selectors/application';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'dropzone/dist/min/dropzone.min.css';

let mDropZoneInstance: DropZoneJS | undefined;

const useStyles = makeStyles((theme: Theme) => ({
  uploadButton   : {
    marginTop: 5,
    width    : 150,
  },
  singleDropZone : {
    maxWidth        : 960,
    width           : '100%',
    marginLeft      : 'auto',
    marginRight     : 'auto',
    textAlign       : 'center',
    '& .dz-progress': {
      display: 'none',
    }
  },
  uploadProgress : {
    left      : 0,
    right     : 0,
    fontSize  : 22,
    fontWeight: 700,
    position  : 'absolute',
    bottom    : theme.spacing(.5),
    color     : theme.palette.primary.main,
  },
  dropZoneRoot   : {
    flexGrow                                 : 1,
    maxHeight                                : 440,
    overflowY                                : 'scroll',
    borderRadius                             : '.375rem',
    position                                 : 'relative',
    border                                   : '2px dashed #dee2e6',
    color                                    : theme.palette.text.hint,
    transition                               : theme.transitions.create('all'),
    '&.dropzone .dz-preview .dz-image'       : {
      borderRadius: 0,
    },
    '&.dropzone .dz-message button.dz-button': {
      margin    : 0,
      fontSize  : 22,
      fontWeight: 500,
    },
    '&.dropzone .dz-message'                 : {
      top           : 0,
      left          : 0,
      right         : 0,
      bottom        : 0,
      margin        : 0,
      display       : 'flex',
      alignItems    : 'center',
      flexDirection : 'column',
      justifyContent: 'center',
      position      : 'absolute',
    },
    '&:hover'                                : {
      color      : theme.palette.primary.main,
      borderColor: theme.palette.primary.light,
    },
    '&.dropzone .dz-progress'                : {
      display: 'none',
    },
    '&.dropzone.dz-started .dz-message'      : {
      display: 'none',
    }
  },
  dropZoneWrapper: {
    position: 'relative',
  },
}));

export default function MultipleFilesUpload(
  {
    onSuccess,
    onStartUploading,
  }: {
    onStartUploading: () => void;
    onSuccess: (files: any[]) => void;
  }
): JSX.Element {
  const classes = useStyles();
  const {t} = useTranslation();
  const baseApi = useSelector(_apiBaseUrl);
  const accessToken = useSelector(_accessToken);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showButton, toggleButton] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<{ message: string, fileName: string }[]>([]);

  React.useEffect(() => {
    if (
      document.getElementById('template') &&
      document.getElementById('dropzone') &&
      (typeof onSuccess === 'function')
    ) {
      var previewNode: any = document.getElementById('template');
      previewNode.id = "";
      var previewTemplate = previewNode.parentNode.innerHTML;
      previewNode.parentNode.removeChild(previewNode);

      mDropZoneInstance = new DropZoneJS('div#dropzone', {
        parallelUploads   : 10,
        maxFilesize       : 25,
        maxFiles          : 50,
        uploadMultiple    : true,
        autoProcessQueue  : false,
        timeout           : 600000,
        paramName         : 'files',
        acceptedFiles     : 'image/*',
        previewsContainer : '#previews',
        previewTemplate   : previewTemplate,
        url               : `${baseApi}/media`,
        dictDefaultMessage: 'Drop files or click here to upload',
        headers           : {Authorization: `Bearer ${accessToken}`},
        dictFileTooBig    : 'The file is too large ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.',
        queuecomplete(): void {
          if (mDropZoneInstance?.getFilesWithStatus('success').length) {
            const medias: any[] = [];
            const files = mDropZoneInstance?.getFilesWithStatus('success');

            files.forEach((file) => {
              try {
                const parsedFile = JSON.parse(file?.xhr?.response);
                medias.push(parsedFile.data);
              } catch (e) {
                console.error(e);
              }
            });

            onSuccess(medias);
            mDropZoneInstance?.removeAllFiles();
            toggleButton(false);
          } else {
            onSuccess([]);
          }
          if (mDropZoneInstance?.getAcceptedFiles().length) {
            toggleButton(true);
          }
          setLoading(false);
        },
        removedfile(file: DropZoneJS.DropzoneFile): void {
          if (file.previewElement != null && file.previewElement.parentNode != null) {
            file.previewElement.parentNode.removeChild(file.previewElement);
          }
          if (!mDropZoneInstance?.getAcceptedFiles().length) {
            toggleButton(false);
          }
        },
        accept(file: DropZoneJS.DropzoneFile, done: (error?: (string | Error)) => void): void {
          done();
          toggleButton(true);
        },
        errormultiple(files: DropzoneFile[], message: string | any, xhr: XMLHttpRequest): void {
          const e: { message: string, fileName: string }[] = [];

          files.forEach(file => {
            if ((typeof message === 'string') && xhr === undefined) {
              e.push({
                message : message,
                fileName: file.name,
              });
            } else if (typeof message !== 'string') {
              const m: string[] = [];

              Object.values(message?.errors?.['files.0'] || {}).forEach((err: any) => {
                if (typeof err === 'string') {
                  m.push(err.replace('files.0', 'file'));
                }
              })

              e.push({
                fileName: file.name,
                message : m.length ? m : (message?.message || t('messages.failed_to_upload')),
              });
            } else {
              e.push({
                fileName: file.name,
                message : t('messages.failed_to_upload'),
              });
            }

            mDropZoneInstance?.removeFile(file);
          });

          if (e.length) {
            setErrors(prevState => ([
              ...prevState,
              ...e
            ]));
          }
        },
      });
    }

    return function () {
      mDropZoneInstance?.removeAllFiles();

      mDropZoneInstance?.off();
      mDropZoneInstance?.destroy();

      mDropZoneInstance = undefined;
    };
    // eslint-disable-next-line
  }, [
    t,
    accessToken,
  ]);

  const upload = React.useCallback(() => {
    if (typeof onStartUploading === 'function') {
      setErrors([]);

      setLoading(true);
      onStartUploading();

      const files = mDropZoneInstance?.getAcceptedFiles();

      setLoading(!!files?.length);

      files?.forEach((file, index) => {
        setTimeout(() => {
          mDropZoneInstance?.uploadFiles([file]);
        }, 1000 + (100 * index));
      });
    }
  }, [
    setLoading,
    onStartUploading,
  ]);

  return (
    <Typography
      component='div'
      style={{
        flexGrow     : 1,
        display      : 'flex',
        flexDirection: 'column',
      }}
    >
      {!!errors.length && (
        <Alert
          severity='error'
          style={{marginBottom: 16}}
          onClose={() => setErrors([])}
        >
          <AlertTitle>{t('messages.error')}</AlertTitle>
          {errors.map((error, index) => {
            return (
              <span
                key={index}
                style={{display: 'flex'}}
              >
                <Typography
                  noWrap
                  component='span'
                  style={{width: 200, display: 'block'}}
                >
                  <Tooltip
                    title={error.fileName}
                    placement='bottom-start'
                  ><strong>{error.fileName}</strong>
                  </Tooltip>
                </Typography>
                <Typography
                  component='span'
                  style={{marginRight: 4, marginLeft: 4}}
                >-
                </Typography>
                {Array.isArray(error.message) ? (
                  <Typography
                    component='p'
                  >
                    {error.message.map((m: string) => (
                      <>
                        <Typography
                          component='span'
                        >{m}
                        </Typography>
                        <br/>
                      </>
                    ))}
                  </Typography>
                ) : (
                  <Typography
                    component='span'
                  >{error.message}
                  </Typography>
                )}
                <br/>
              </span>
            );
          })}
        </Alert>
      )}
      <div
        style={{
          padding      : 24,
          flexGrow     : 1,
          paddingBottom: 0,
          display      : 'flex',
          flexDirection: 'column',
          position     : 'relative',
        }}
      >
        <div
          id='dropzone'
          className={clsx(
            classes.dropZoneRoot,
            'dropzone',
            showButton && 'dz-started'
          )}
        >
          <div
            data-dz-message={true}
            className='dz-default dz-message'
          >
            <CloudUploadIcon
              style={{
                fontSize: 60,
                color   : 'rgba(0,0,0,.3)'
              }}
            />
            <button
              className='dz-button'
            >Drop files or <span style={{color: 'rgba(0,0,0,.85)', textDecoration: 'underline'}}>click here</span> to upload
            </button>
          </div>
          <List
            dense={true}
            id='previews'
            style={{padding: 0}}
          >
            <ListItem
              id='template'
            >
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary=''
                primaryTypographyProps={{
                  noWrap        : true,
                  'data-dz-name': ''
                } as any}
                secondaryTypographyProps={{
                  'data-dz-size': ''
                } as any}
              />
              <IconButton
                edge='end'
                data-dz-remove
              ><DeleteIcon/>
              </IconButton>
            </ListItem>
          </List>
          {loading && <Backdrop/>}
        </div>
      </div>
      {showButton && (
        <Box
          paddingLeft={3}
          paddingRight={3}
        >
          <Button
            size='small'
            color='primary'
            onClick={upload}
            component='label'
            variant='outlined'
            disabled={!!loading}
            className={classes.uploadButton}
            startIcon={!!loading ? <CircularProgress style={{height: 14, width: 14}}/> : void 0}
          >
            <Typography
              noWrap
            >Upload
            </Typography>
          </Button>
        </Box>
      )}
    </Typography>
  );
}

function Backdrop() {
  return (
    <div
      style={{
        top            : 0,
        left           : 0,
        width          : '100%',
        height         : '100%',
        zIndex         : 3000000,
        borderRadius   : '.375rem',
        position       : 'absolute',
        backgroundColor: 'rgba(0,0,0,.3)',
      }}
    />
  );
}