import React from 'react';
import {Picker} from '../Picker';
import Menu from '@material-ui/core/Menu';
import {replaceBetween} from '../../utils';
import {TextField} from '../BorderedTextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {UseFormGetValues} from 'react-hook-form/dist/types/form';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';

const useStyles = makeStyles(() => ({
  menuListProps: {
    padding: 0,
  },
  paperProps   : {
    borderRadius: 0,
    border      : '1px solid #F5F5F5',
  },
}));

export function EmojiAdornment(
  {
    name,
    inputRef,
    disabled,
    setValue,
    getValues,
    defaultValue,
  }: {
    name: string;
    disabled?: boolean;
    defaultValue: string;
    inputRef: React.RefObject<any>;
    setValue: UseFormSetValue<any>;
    getValues: UseFormGetValues<any>;
  }
) {
  const classes = useStyles();
  const [
    anchorEmoji,
    setAnchorEmoji
  ] = React.useState<undefined | HTMLElement>(void 0);

  const [selectionPosition, setSelectionPosition] = React.useState<{
    selectionEnd: number | null;
    selectionStart: number | null;
  }>({
    selectionEnd  : null,
    selectionStart: null,
  });

  const onClose = React.useCallback(() => {
    setAnchorEmoji(void 0)
  }, []);

  const iconButtonOnClick = React.useCallback((e) => {
    setAnchorEmoji(e.currentTarget)
  }, []);

  const onSelect = React.useCallback((emoji) => {
    const value = getValues(name) || defaultValue || '';

    setValue(name, replaceBetween(
      value || '',
      emoji.native,
      selectionPosition.selectionStart,
      selectionPosition.selectionEnd
    ), {
      shouldDirty   : true,
      shouldValidate: true,
    });
    setSelectionPosition((
      {
        selectionEnd,
        selectionStart,
      }) => {
      if ((selectionEnd === null) || (selectionStart === null)) {
        return {
          selectionEnd  : value.length + String(emoji.native).length,
          selectionStart: value.length + String(emoji.native).length,
        }
      } else {
        return {
          selectionEnd  : selectionEnd + String(emoji.native).length,
          selectionStart: selectionStart + String(emoji.native).length,
        }
      }
    });
  }, [
    name,
    setValue,
    getValues,
    defaultValue,
    selectionPosition,
  ]);

  React.useEffect(() => {
    const inputRefCurrent = inputRef.current;
    const blurEventListener = (e: any) => {
      setSelectionPosition({
        selectionEnd  : e.currentTarget.selectionEnd || 0,
        selectionStart: e.currentTarget.selectionStart || 0,
      })
    };

    inputRefCurrent?.addEventListener('blur', blurEventListener);

    return function () {
      inputRefCurrent?.removeEventListener('blur', blurEventListener);
    }
  }, [
    inputRef,
  ]);

  return (
    <InputAdornment
      position='start'
    >
      <IconButton
        size='small'
        disableRipple
        disableTouchRipple
        disableFocusRipple
        disabled={disabled}
        onClick={iconButtonOnClick}
      >
        <SentimentSatisfiedOutlinedIcon
          fontSize='small'
        />
      </IconButton>
      {!!anchorEmoji && (
        <Menu
          onClose={onClose}
          transitionDuration={0}
          anchorEl={anchorEmoji}
          open={Boolean(anchorEmoji)}
          transformOrigin={{
            vertical  : 'center',
            horizontal: 'left',
          }}
          MenuListProps={{className: classes.menuListProps}}
          PaperProps={{elevation: 0, className: classes.paperProps}}
        >
          <Typography
            component='div'
          >
            <Picker
              onSelect={onSelect}
            />
          </Typography>
        </Menu>
      )}
    </InputAdornment>
  )
}

export function EmojiTextField(
  {
    error,
    label,
    inputRef,
    disabled,
    setValue,
    getValues,
    helperText,
    placeholder,
    characterLimit,
    ...fieldRest
  }: {
    value: any;
    name?: string;
    error?: boolean;
    inputProps?: any;
    disabled?: boolean;
    placeholder?: string;
    label: React.ReactNode;
    characterLimit?: number;
    helperText?: React.ReactNode;
    inputRef: React.Ref<HTMLInputElement>;
    setValue: UseFormSetValue<Record<string, any>>;
    getValues: UseFormGetValues<Record<string, any>>;
  }
) {
  const emojiTextFieldRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <TextField
      fullWidth
      label={label}
      error={error}
      margin='normal'
      autoComplete='off'
      InputProps={{
        endAdornment: (
          <EmojiAdornment
            disabled={disabled}
            setValue={setValue}
            name={fieldRest.name}
            getValues={getValues}
            inputRef={emojiTextFieldRef}
            defaultValue={fieldRest.value}
          />
        ),
      }}
      disabled={disabled}
      helperText={helperText}
      placeholder={placeholder}
      inputRef={instance => {
        (inputRef as any)(instance);
        emojiTextFieldRef.current = instance;
      }}
      characterLimit={characterLimit}
      {...fieldRest}
    />
  )
}
