import {ActionTypes} from '../../../constants/images';
import {Builder} from '../../../../types/builder';

export const _updateFilters = (data: {[key: string]: any}) => ({
  payload: data,
  type   : ActionTypes.BUILDER_IMAGES_UPDATE_FILTERS,
});

export const _setData = (data: Builder.ImageEntity[]) => ({
  payload: data,
  type   : ActionTypes.BUILDER_IMAGES_SET_DATA,
});

export const _deleteData = (data: number) => ({
  payload: data,
  type   : ActionTypes.BUILDER_IMAGES_DELETE_DATA,
});

export const _setLoading = (data?: boolean) => ({
  payload: data,
  type   : ActionTypes.BUILDER_IMAGES_SET_LOADING,
});
