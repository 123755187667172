import React from 'react';
import {Button} from 'antd';
import {useWatch} from 'react-hook-form';
import {CheckOutlined} from '@ant-design/icons';
import {ICreateAppForm} from '../../useUpsertApp';
import {Control} from 'react-hook-form/dist/types/form';

export function SubmitButton(
  {
    control,
    loading,
  }: {
    loading?: boolean;
    control: Control<ICreateAppForm>
  }
) {
  const title = useWatch({
    control,
    name: 'title',
  });

  const domain = useWatch({
    control,
    name: 'domain',
  });

  return (
    <Button
      type='primary'
      htmlType='submit'
      loading={loading}
      icon={<CheckOutlined/>}
      disabled={!domain?.trim() || !title?.trim()}
    >Continue
    </Button>
  );
}