import React from 'react';
import {RouteComponentProps} from 'react-router';
import {SubscriptionsAndBillingRoutes} from './Routes';
import {Spinner} from '../../../../../components/antd/Spinner';
import {usePaymentsAndSubscriptions} from './usePaymentsAndSubscriptions';

export function PaymentsAndSubscriptions(props: RouteComponentProps) {
  const {
    loading,
  } = usePaymentsAndSubscriptions();

  return loading ? (
    <Spinner/>
  ) : (
    <SubscriptionsAndBillingRoutes
      {...props}
    />
  )
}

