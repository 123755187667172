import {stringify} from 'querystring';
import {getResponseErrorMsgs} from '../utils';

export const getList = async (
  url: string,
  accessToken: string | null,
  options: any,
  method?: string,
): Promise<{
  hasError: boolean;
  body: {
    data: any;
    status: number;
    message?: string;
  };
}> => {
  try {
    const response = await fetch(`${url}?${stringify(options)}`, {
      method : method ? method : 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      const {
        errors,
        message,
      } = await response.json();
      const errorMessages = getResponseErrorMsgs(errors);
      return {
        hasError: true,
        body    : {
          message,
          status: response.status,
          data  : {
            options: [],
            hasMore: false,
            errors : errorMessages,
          },
        },
      }
    } else {
      const {
        data,
      } = await response.json();
      return {
        hasError: false,
        body    : {
          status: response.status || 200,
          data  : {
            options   : data.rows.map((item: any) => ({
              value: (item?.text?.toLowerCase() === 'all') ? 'all' : item.text,
              label: (item?.text?.toLowerCase() === 'all') ? 'all' : item.text,
            })),
            hasMore   : data.has_more,
            additional: {
              pageNumber: options.pageNumber + 1,
            },
          },
        },
      }
    }
  } catch (e) {
    return {
      hasError: true,
      body    : {
        status: e.status,
        data  : {
          errors : {
            message: e.message,
          },
          options: [],
          hasMore: false,
        },
      },
    }
  }
};
