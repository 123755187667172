import {prefix} from './index';
import {aclKeys} from './aclKeys';
import Users from '../pages/Users/List';
import {privatePrefix} from './index';
import Builder from '../pages/Builder';
import {Checkout} from '../pages/Checkout';
import * as Pages from '../pages/Application'
import UserCreateOrUpdate from '../pages/Users/Pages/CreateOrUpdate/tmp';

export const AppRoutes = {
  LOGIN            : '/login',
  OAUTH_GOOGLE     : '/oauth/google',
  EMAIL_CHANGE     : '/email-change/:token',
  APP_SUMO_REGISTER: '/app-sumo-register/:token',

  REGISTER            : '/register',
  REGISTER_PLAN       : '/register/:plan',
  CONFIRM_REGISTRATION: '/register/confirm/:token',

  PASSWORD_FORGOT: '/password/forgot',
  PASSWORD_RESET : '/password/reset/:token/:email',

  CHECKOUT_APP: `/${privatePrefix}/:appUuid/checkout/:plan`,
  CHECKOUT    : `/${prefix}/checkout/:plan`,

  //Automation
  AUTOMATIONS      : `/${privatePrefix}/:appUuid/automations`,
  AUTOMATION_CREATE: `/${privatePrefix}/:appUuid/automations/create`,
  AUTOMATION_UPDATE: `/${privatePrefix}/:appUuid/automations/:id/edit`,
  AUTOMATION_CLONE : `/${privatePrefix}/:appUuid/automations/:id/clone`,

  //Builder
  BUILDER         : `/${privatePrefix}/:appUuid/builder`,
  BUILDER_ICONS   : `/${privatePrefix}/:appUuid/builder/icons`,
  BUILDER_IMAGES  : `/${privatePrefix}/:appUuid/builder/images`,
  BUILDER_TITLES  : `/${privatePrefix}/:appUuid/builder/titles`,
  BUILDER_MESSAGES: `/${privatePrefix}/:appUuid/builder/messages`,

  //Documentation
  DOCUMENTATION                      : `/${privatePrefix}/:appUuid/integration-guides`,
  DOCUMENTATION_GTM_INTEGRATION      : `/${privatePrefix}/:appUuid/integration-guides/gtm-integration`,
  DOCUMENTATION_ADVANCED_INTEGRATION : `/${privatePrefix}/:appUuid/integration-guides/advanced-integration`,
  DOCUMENTATION_MANAGING_CONVERSIONS : `/${privatePrefix}/:appUuid/integration-guides/managing-conversions`,
  DOCUMENTATION_WORDPRESS_INTEGRATION: `/${privatePrefix}/:appUuid/integration-guides/wordpress-integration`,

  //Users
  USERS      : `/${privatePrefix}/:appUuid/users`,
  CREATE_USER: `/${privatePrefix}/:appUuid/users/create`,
  UPDATE_USER: `/${privatePrefix}/:appUuid/users/:uuid/edit`,

  ACCESS_DENIED: `/${prefix}/access-denied`,

  /** Apps **/
  SURVEY                 : `/${prefix}/survey`,
  APPS_CREATE            : `/${prefix}/create`,
  APPS                   : `/${privatePrefix}/:appUuid/apps`,
  SURVEY_APP             : `/${privatePrefix}/:appUuid/survey`,
  APPS_EDIT_APP          : `/${privatePrefix}/:appUuid/apps/edit-app`,
  APPS_CREATE_APP        : `/${privatePrefix}/:appUuid/apps/create-app`,
  APPS_INTEGRATE_APP     : `/${privatePrefix}/:appUuid/apps/edit-app/integrate`,
  APPS_PAY_AND_LAUNCH_APP: `/${privatePrefix}/:appUuid/apps/edit-app/pay-and-launch`,

  /** Analytics **/
  ANALYTICS                 : `/${privatePrefix}/:appUuid/analytics`,
  ANALYTICS_REPORTS         : `/${privatePrefix}/:appUuid/analytics/reports`,
  ANALYTICS_SUBSCRIBERS     : `/${privatePrefix}/:appUuid/analytics/subscribers`,
  ANALYTICS_SUBSCRIBERS_INFO: `/${privatePrefix}/:appUuid/analytics/subscribers/:uuid/info`,

  /** Settings **/
  SETTINGS                : `/${privatePrefix}/:appUuid/settings`,
  SETTINGS_INTEGRATIONS   : `/${privatePrefix}/:appUuid/settings/integrations`,
  SETTINGS_PROMPT_CONFIGS : `/${privatePrefix}/:appUuid/settings/prompt-configs`,
  SETTINGS_WELCOME_MESSAGE: `/${privatePrefix}/:appUuid/settings/welcome-message`,

  /** Campaigns **/
  CAMPAIGNS                          : `/${privatePrefix}/:appUuid/campaigns`,
  CAMPAIGNS_PUSH_NOTIFICATIONS       : `/${privatePrefix}/:appUuid/campaigns/push-notifications`,
  CAMPAIGNS_PUSH_NOTIFICATIONS_CREATE: `/${privatePrefix}/:appUuid/campaigns/push-notifications/create`,
  CAMPAIGNS_PUSH_NOTIFICATIONS_EDIT  : `/${privatePrefix}/:appUuid/campaigns/push-notifications/:uuid/edit`,
  CAMPAIGNS_PUSH_NOTIFICATIONS_INFO  : `/${privatePrefix}/:appUuid/campaigns/push-notifications/:uuid/info`,
  CAMPAIGNS_PUSH_NOTIFICATIONS_CLONE : `/${privatePrefix}/:appUuid/campaigns/push-notifications/:uuid/clone`,

  /** Profile **/
  MANAGE_ACCOUNT    : `/${prefix}/manage-account`,
  MANAGE_ACCOUNT_APP: `/${privatePrefix}/:appUuid/manage-account`,

  SECURITY    : `/${prefix}/manage-account/security`,
  SECURITY_APP: `/${privatePrefix}/:appUuid/manage-account/security`,

  API_KEYS    : `/${prefix}/manage-account/api-keys`,
  API_KEYS_APP: `/${privatePrefix}/:appUuid/manage-account/api-keys`,

  PAYMENTS_AND_SUBSCRIPTIONS    : `/${prefix}/manage-account/payments-and-subscriptions`,
  PAYMENTS_AND_SUBSCRIPTIONS_APP: `/${privatePrefix}/:appUuid/manage-account/payments-and-subscriptions`,

  CHANGE_PLAN    : `/${prefix}/manage-account/payments-and-subscriptions/change-plan`,
  CHANGE_PLAN_APP: `/${privatePrefix}/:appUuid/manage-account/payments-and-subscriptions/change-plan`,

  PAY_AND_ACTIVATE    : `/${prefix}/manage-account/payments-and-subscriptions/change-plan/:plan`,
  PAY_AND_ACTIVATE_APP: `/${privatePrefix}/:appUuid/manage-account/payments-and-subscriptions/change-plan/:plan`,

  /** Templates **/
  TEMPLATES                   : `/${privatePrefix}/:appUuid/templates`,
  TEMPLATES_CREATE            : `/${privatePrefix}/:appUuid/templates/create`,
  TEMPLATES_EDIT              : `/${privatePrefix}/:appUuid/templates/:id/edit`,
  TEMPLATE_DETAILS            : `/${privatePrefix}/:appUuid/templates/:id/details`,
  TEMPLATE_CLONE_FROM_CAMPAIGN: `/${privatePrefix}/:appUuid/templates/:uuid/clone`,

  /** Segments **/
  SEGMENTS       : `/${privatePrefix}/:appUuid/segments`,
  SEGMENTS_CREATE: `/${privatePrefix}/:appUuid/segments/create`,
  SEGMENTS_EDIT  : `/${privatePrefix}/:appUuid/segments/:id/edit`,
};

export const PrivateRoutes = () => [
  {
    exact    : true,
    Component: Checkout,
    path     : AppRoutes.CHECKOUT_APP,
  },
  {
    exact    : true,
    Component: Checkout,
    path     : AppRoutes.CHECKOUT,
  },
  {
    exact    : false,
    aclKey   : aclKeys.TEMPLATES,
    path     : AppRoutes.TEMPLATES,
    Component: Pages.TemplatesRoutes,
  },
  {
    exact    : true,
    Component: Users,
    aclKey   : aclKeys.USERS,
    path     : AppRoutes.USERS,
  },
  {
    exact    : true,
    aclKey   : aclKeys.USERS,
    Component: UserCreateOrUpdate,
    path     : AppRoutes.CREATE_USER,
  },
  {
    exact    : true,
    aclKey   : aclKeys.USERS,
    Component: UserCreateOrUpdate,
    path     : AppRoutes.UPDATE_USER,
  },
  {
    exact    : true,
    Component: Builder,
    aclKey   : aclKeys.BUILDER,
    path     : AppRoutes.BUILDER,
  },
  {
    exact    : true,
    Component: Builder,
    aclKey   : aclKeys.BUILDER,
    path     : AppRoutes.BUILDER_ICONS,
  },
  {
    exact    : true,
    Component: Builder,
    aclKey   : aclKeys.BUILDER,
    path     : AppRoutes.BUILDER_IMAGES,
  },
  {
    exact    : true,
    Component: Builder,
    aclKey   : aclKeys.BUILDER,
    path     : AppRoutes.BUILDER_TITLES,
  },
  {
    exact    : true,
    Component: Builder,
    aclKey   : aclKeys.BUILDER,
    path     : AppRoutes.BUILDER_MESSAGES,
  },
]
