import {Draft} from 'immer';
import * as React from 'react';
import {useDispatch} from 'react-redux';
import {Title} from '../../../../Typography';
import {Surveys} from '../../../../../../types/surveys';
import {SurveyAutocomplete} from '../../../SurveyAutocomplete';
import {professions} from '../../../../../../constants/survey';
import {getPrefixCls} from '../../../../../../utils/getPrefixCls';
import {_storeSurvey} from '../../../../../../store/actions/application/storeSurvey';

let oldValue: string;

export function Line1(
  {
    data,
    setData,
  }: {
    data: Surveys.Data;
    setData: (f: (draft: Draft<Surveys.Data>) => void | Surveys.Data) => void;
  }
) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    oldValue = data.profession;
    // eslint-disable-next-line
  }, []);

  function onBlur() {
    if (oldValue?.trim()?.toLowerCase() !== data.profession?.trim()?.toLowerCase()) {
      oldValue = data.profession;

      dispatch(_storeSurvey({
        key  : 'profession',
        value: data.profession?.trim(),
      }));
    }

    setData(draft => {
      draft.professionIsOpen = false;
      draft.professionSelected = !!draft.profession;
    });
  }

  function onClick() {
    setData(draft => {
      draft.professions = professions;
      draft.professionIsOpen = true;
    })
  }

  function onFocus() {
    setData(draft => {
      draft.professionIsOpen = true;
    })
  }

  function onSelect(v: any) {
    setData(draft => {
      draft.professionIsOpen = false;
      draft.professionSelected = true;
    })
  }

  function onMouseDown() {
    setData(draft => {
      draft.professions = professions;
      draft.professionIsOpen = true;
    })
  }

  function onPressEnter(e: any) {
    const value = e.currentTarget.value;
    if (value) {
      setData(draft => {
        draft.professionSelected = !!value?.trim();
      })
    }
    if (!!value?.trim()) {
      e.currentTarget.blur();
    }
  }

  function onSearch(value: string) {
    setData(draft => {
      draft.professions = professions.filter((p) => p.value.toUpperCase().trim().indexOf(value.toUpperCase()) !== -1)
    })
  }

  function onChange(value: string) {
    setData(draft => {
      draft.profession = value;

      if (!value?.trim()) {
        draft.professionSelected = false;

        draft.reason = void 0;
        draft.frequency = void 0;
        draft.subscriber = void 0;
        draft.competitor = void 0;

        draft.reasonSelected = false;
        draft.frequencySelected = false;
        draft.subscriberSelected = false;
        draft.competitorSelected = false;
      }
    })
  }

  return (
    <div
      className={`${getPrefixCls('survey-word-break')} d-flex align-items-center m-b-5`}
    >
      <div
        className='d-flex align-items-center m-b-md-5'
      >
        <Title
          level={3}
          className='m-r-1'
        >I am a
        </Title>
        <SurveyAutocomplete
          onBlur={onBlur}
          onFocus={onFocus}
          onClick={onClick}
          onSelect={onSelect}
          onChange={onChange}
          onSearch={onSearch}
          value={data.profession}
          onMouseDown={onMouseDown}
          options={data.professions}
          onPressEnter={onPressEnter}
          open={!!data.professionIsOpen}
          autoFocus={!data.profession?.trim()}
          dropdownStyle={{minWidth: 280, maxWidth: 280}}
        />
        {!!data.profession?.trim() && data.professionSelected && (
          <Title
            level={3}
            className='sp-survey-colorBlue'
          >.
          </Title>
        )}
      </div>
      {data.professionSelected && (
        <Title
          level={3}
          className='m-l-1'
        >I am signing to SmartPush
        </Title>
      )}
    </div>
  )
}
