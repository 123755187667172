import {notification} from 'antd';
import {ActionTypes} from '../../constants/gallery';
import {getLastQuery} from '../../selectors/gallery';
import {_apiBaseUrl} from '../../selectors/application';
import {_userLimits} from '../../selectors/application/acl';
import {getActionDialogData} from '../../selectors/gallery';

export const setDataAction = (data: any) => ({
  payload: data,
  type   : ActionTypes.WEB_NOTIFY_GALLERY_SET_DATA,
});

export const optionsAction = (options: any) => ({
  payload: options,
  type   : ActionTypes.WEB_NOTIFY_GALLERY_TABLE_OPTIONS,
});

export const dataOptionsAction = (options: any) => ({
  payload: options,
  type   : ActionTypes.WEB_NOTIFY_GALLERY_DATA_OPTIONS,
});

export const toggleErrorsAction = (errors?: any) => ({
  payload: errors,
  type   : ActionTypes.WEB_NOTIFY_GALLERY_TOGGLE_ERRORS,
});

export const toggleEntityAction = (entity?: any) => ({
  payload: entity,
  type   : ActionTypes.WEB_NOTIFY_GALLERY_TOGGLE_ENTITY,
});

export const loadingAction = (loading: boolean) => ({
  payload: loading,
  type   : ActionTypes.WEB_NOTIFY_GALLERY_TOGGLE_LOADING,
});

export const toggleDialogAction = (payload?: {
  data?: any;
  dialog: string;
}) => ({
  payload,
  type: ActionTypes.WEB_NOTIFY_GALLERY_TOGGLE_DIALOG,
});

export const emptyDataAction = () => ({
  type: ActionTypes.WEB_NOTIFY_GALLERY_EMPTY_DATA,
});

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // ///

export const fetchAction = () => {
  return async (dispatch: any, getState: any, {
    getList,
  }: any) => {
    dispatch(loadingAction(true));

    let lastQuery = getLastQuery(getState());

    const {
      hasError,
      body: {
        data,
      },
    } = await getList(lastQuery);

    if (hasError) {
      dispatch(loadingAction(false));
    } else {
      const {
        rows,
        ...rest
      } = data;

      dispatch(setDataAction(rows));
      dispatch(optionsAction(rest));
      dispatch(loadingAction(false));
      const limits = _userLimits(getState());
      if (Number(limits?.users?.per_account_max_images_count)) {
        if (Number(limits?.users?.per_account_max_images_count) > rest.total) {
          dispatch(dataOptionsAction({
            disable_upload_button: void 0,
            limits_notice        : void 0,
            limits_notice_is_open: void 0,
          }));
        }
      }
    }
  }
};

export const uploadAction = (files: File[]) => async (dispatch: any, getState: any, {
  upload,
}: any) => {
  dispatch(dataOptionsAction({
    isUploading: true,
  }));

  const formData = new FormData();
  const apiBaseUrl = _apiBaseUrl(getState());

  files.forEach((file, index) => {
    formData.append(`files[${index}]`, file);
  });

  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await upload(`${apiBaseUrl}/media`, 'POST', {
    body: formData
  });

  dispatch(dataOptionsAction({
    isUploading: void 0,
  }));

  if (!hasError) {
    dispatch(dataOptionsAction({
      uploaderIsOpen: void 0,
    }));
    dispatch(fetchAction());
  } else {
    if (data?.errors?.limits_notice) {
      dispatch(dataOptionsAction({
        uploaderIsOpen       : void 0,
        limits_notice        : data?.errors?.limits_notice,
        limits_notice_is_open: true,
      }));
    } else {
      dispatch(toggleErrorsAction({
        message,
        ...data.errors
      }));
    }
  }
};

export const destroyAction = () => async (dispatch: any, getState: any, {
  request,
}: any) => {
  dispatch(dataOptionsAction({
    isDeleting: true,
  }));

  const apiBaseUrl = _apiBaseUrl(getState());
  const {id, destroy} = getActionDialogData(getState());
  if (typeof destroy === 'function') {
    destroy()
  }

  const {
    hasError,
    body: {
      message,
    },
  } = await request(`${apiBaseUrl}/media/${id}`, 'DELETE');

  dispatch(dataOptionsAction({
    isDeleting: void 0,
  }));

  dispatch(toggleDialogAction(void 0));

  notification.open({
    message,
    type: hasError ? 'error' : 'success',
  });

  if (!hasError) {
    dispatch(fetchAction());
  }
};
