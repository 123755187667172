export enum ActionTypes {
  WEB_NOTIFY_TEMPLATES_SET_DATA = 'WEB_NOTIFY_TEMPLATES_SET_DATA',
  WEB_NOTIFY_TEMPLATES_EMPTY_DATA = 'WEB_NOTIFY_TEMPLATES_EMPTY_DATA',
  WEB_NOTIFY_TEMPLATES_REMOVE_LANG = 'WEB_NOTIFY_TEMPLATES_REMOVE_LANG',
  WEB_NOTIFY_TEMPLATES_REMOVE_ERROR = 'WEB_NOTIFY_TEMPLATES_REMOVE_ERROR',
  WEB_NOTIFY_TEMPLATES_DATA_OPTIONS = 'WEB_NOTIFY_TEMPLATES_DATA_OPTIONS',
  WEB_NOTIFY_TEMPLATES_TABLE_OPTIONS = 'WEB_NOTIFY_TEMPLATES_TABLE_OPTIONS',
  WEB_NOTIFY_TEMPLATES_TOGGLE_ERRORS = 'WEB_NOTIFY_TEMPLATES_TOGGLE_ERRORS',
  WEB_NOTIFY_TEMPLATES_TOGGLE_DIALOG = 'WEB_NOTIFY_TEMPLATES_TOGGLE_DIALOG',
  WEB_NOTIFY_TEMPLATES_TOGGLE_ENTITY = 'WEB_NOTIFY_TEMPLATES_TOGGLE_ENTITY',
  WEB_NOTIFY_TEMPLATES_UPDATE_ENTITY = 'WEB_NOTIFY_TEMPLATES_UPDATE_ENTITY',
  WEB_NOTIFY_TEMPLATES_TOGGLE_LOADING = 'WEB_NOTIFY_TEMPLATES_TOGGLE_LOADING',
  WEB_NOTIFY_TEMPLATES_EMPTY_LANGUAGES = 'WEB_NOTIFY_TEMPLATES_EMPTY_LANGUAGES',
  WEB_NOTIFY_TEMPLATES_UPDATE_ENTITY_LANG = 'WEB_NOTIFY_TEMPLATES_UPDATE_ENTITY_LANG',

  TEMPLATES_SET_DATA                 = 'templates/SET_DATA',
  TEMPLATES_SET_TAGS                 = 'templates/SET_TAGS',
  TEMPLATES_SET_DETAILS              = 'templates/SET_DETAILS',
  TEMPLATES_DATA_OPTIONS             = 'templates/DATA_OPTIONS',
  TEMPLATES_UPSERT_ENTITY            = 'templates/UPSERT_ENTITY',
  TEMPLATES_REMOVE_ENTITY            = 'templates/REMOVE_ENTITY',
  TEMPLATES_UPSERT_DATA_ITEM         = 'templates/UPSERT_DATA_ITEM',
  TEMPLATES_UPSERT_DETAILS_DATA_ITEM = 'templates/UPSERT_DETAILS_DATA_ITEM',
}
