import produce from 'immer';
import {Apps} from '../../../types/apps';
import {ActionTypes} from '../../constants/apps';
import {FiniteStates} from '../../../constants/finiteStates';
import {ActionTypes as CoreActionTypes} from '../../constants/application';

const InitialState: Apps.Reducer = {
  entity     : void 0,
  entities   : void 0,
  dataOptions: {
    fetchingAppState  : FiniteStates.IDLE,
    isDownloadingDoc  : FiniteStates.IDLE,
    fetchingAppsState : FiniteStates.IDLE,
    sendingToDeveloper: FiniteStates.IDLE,
  },
};

export const apps = produce((draft, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.APPS_SET_DATA: {
      draft.entities = payload;

      return void 0;
    }
    case ActionTypes.APPS_DATA_OPTIONS: {
      if (!draft.dataOptions) {
        draft.dataOptions = {} as any;
      }
      Object.keys(payload || {}).forEach((key: string) => {
        (draft.dataOptions as any)[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.APPS_TOGGLE_ENTITY: {
      draft.entity = payload;

      return void 0;
    }
    case ActionTypes.APPS_UPSERT_ENTITY: {
      if (!draft.entity) {
        draft.entity = {} as any;
      }
      Object.keys(payload || {}).forEach((key: string) => {
        (draft.entity as any)[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.APPS_UPSERT_DATA_ITEM: {
      const index = draft?.entities?.findIndex(e => e.uuid === payload.uuid);

      if (index !== -1) {
        Object.keys(payload || {}).forEach((key: string) => {
          (draft.entities[index] as any)[key] = payload[key]
        });
      }

      return void 0;
    }
    case CoreActionTypes.LOGOUT: {
      return {...InitialState}
    }
    default:
      return void 0;
  }
}, InitialState);
