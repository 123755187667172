import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux'
import {Row, Button} from 'antd';
import {getFilters, getIconsAllData, getImagesAllData, getTitlesAllData, getMessagesAllData} from '../../../store/selectors/builder';
import {getIconsData, getImagesData, getTitlesData, getMessagesData, createTemplate} from '../../../store/actions/builder/builders/services';
import {
  _updateFilters,
  _cleanUp,
  _cleanUpIconsData,
  _cleanUpImagesData,
  _cleanUpTitlesData,
  _cleanUpMessagesData
} from '../../../store/actions/builder/builders/actions';
import {Builder} from '../../../types/builder';

import MediaCard from './MediaCard';
import TextCard from './TextCard';
import PreviewDialog from '../Preview';

const initialPageNumber = 1;

const defaultConfigs: TMP = {
  title: null,
  message: null,
  icon_url: null,
  image_url: null,
};

export type TMP = {
  title: number | null,
  message: number | null,
  icon_url: number | null,
  image_url: number | null,
};

const BulderView: React.FC = () => {
  const dispatch = useDispatch();
  const {appUuid} = useParams<{ appUuid: string }>();
  const filters = useSelector(getFilters);
  const iconData = useSelector(getIconsAllData);
  const imageData = useSelector(getImagesAllData);
  const titleData = useSelector(getTitlesAllData);
  const messageData = useSelector(getMessagesAllData);
  const [previewState, setPreviewState] = React.useState<boolean>(false);
  const [templateConfigs, setTemplateConfigs] = useState<TMP>(defaultConfigs);
  const {
    iconPageNumber,
    imagePageNumber,
    parentTags,
    iconTags,
    imageTags,
    titleSearchText,
    titlePageNumber,
    messageSearchText,
    messagePageNumber
  } = filters;

  useEffect(() => {
    if (parentTags.length) {
      dispatch(getIconsData(appUuid));
    }
  }, [dispatch, appUuid, iconPageNumber, parentTags, iconTags]);

  useEffect(() => {
    if (parentTags.length) {
      dispatch(getImagesData(appUuid));
    }
  }, [dispatch, appUuid, imagePageNumber, parentTags, imageTags]);

  useEffect(() => {
    if (parentTags.length) {
      dispatch(getTitlesData(appUuid));
    }
  }, [dispatch, appUuid, titlePageNumber, titleSearchText, parentTags]);

  useEffect(() => {
    if (parentTags.length) {
      dispatch(getMessagesData(appUuid));
    }
  }, [dispatch, appUuid, messageSearchText, messagePageNumber, parentTags]);

  useEffect(() => {
    if (!!parentTags.length) {
      dispatch(_cleanUp());
    }
    return function() {
      dispatch(_cleanUp());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setTemplateConfigs(defaultConfigs);
  }, [parentTags]);

  const onIconTagsChange = (value: string[]) => {
    setTemplateConfigs(defaultConfigs);
    dispatch(_cleanUpIconsData());
    dispatch(_updateFilters({
      iconTags: value,
      iconPageNumber: initialPageNumber,
    }));
  };

  const onImageTagsChange = (value: string[]) => {
    setTemplateConfigs(defaultConfigs);
    dispatch(_cleanUpImagesData());
    dispatch(_updateFilters({
      imageTags: value,
      imagePageNumber: initialPageNumber,
    }));
  };

  const onTitleChange = (value: string) => {
    setTemplateConfigs(defaultConfigs);
    dispatch(_cleanUpTitlesData());
    dispatch(_updateFilters({
      titleSearchText: value,
      titlePageNumber: initialPageNumber,
    }));
  };

  const onMessageChange = (value: string) => {
    setTemplateConfigs(defaultConfigs);
    dispatch(_cleanUpMessagesData());
    dispatch(_updateFilters({
      messageSearchText: value,
      messagePageNumber: initialPageNumber,
    }));
  };

  const onShowMoreIcon = () => {
    dispatch(_updateFilters({
      iconPageNumber: iconPageNumber + 1,
    }));
  };

  const onShowMoreImage = () => {
    dispatch(_updateFilters({
      imagePageNumber: imagePageNumber + 1,
    }));
  };

  const onShowMoreTitle = () => {
    dispatch(_updateFilters({
      titlePageNumber: titlePageNumber + 1,
    }));
  };

  const onShowMoreMessage = () => {
    dispatch(_updateFilters({
      messagePageNumber: messagePageNumber + 1,
    }));
  };

  const updateTMPConfigs = (id: number, type: string) => {
    setTemplateConfigs({...templateConfigs, [type]: id});
  };

  const onSaveTemplate = () => {
    //console.log(data);
    dispatch(createTemplate(appUuid, templateConfigs));
  };

  return (
    <Row>
      <MediaCard
        label='Icons'
        type='icon_url'
        data={iconData}
        updateTMPConfigs={updateTMPConfigs}
        value={templateConfigs.icon_url}
        updateTags={onIconTagsChange}
        selectedTagsValue={iconTags}
        onBtnClick={onShowMoreIcon}
        disabled={!parentTags.length}
      />
      <MediaCard
        label='Images'
        type='image_url'
        data={imageData}
        updateTMPConfigs={updateTMPConfigs}
        value={templateConfigs.image_url}
        updateTags={onImageTagsChange}
        onBtnClick={onShowMoreImage}
        selectedTagsValue={imageTags}
        disabled={!parentTags.length}
      />
      <TextCard
        label='Titles'
        type='title'
        data={titleData}
        value={templateConfigs.title}
        templateConfigs={templateConfigs}
        updateTMPConfigs={updateTMPConfigs}
        handleSearch={onTitleChange}
        onBtnClick={onShowMoreTitle}
        disabled={!parentTags.length}
        searchText={titleSearchText}
      />
      <TextCard
        label='Messages'
        type='message'
        data={messageData}
        value={templateConfigs.message}
        templateConfigs={templateConfigs}
        updateTMPConfigs={updateTMPConfigs}
        handleSearch={onMessageChange}
        onBtnClick={onShowMoreMessage}
        disabled={!parentTags.length}
        searchText={messageSearchText}
      />
      <Row className='justify-content-center m-t-4' style={{ width: '100%'}}>
        <Button
          onClick={onSaveTemplate}
          type='primary'
          style={{width: 120, marginRight: 20}}
          disabled={!templateConfigs.title}
        >
          Save
        </Button>
        <Button
          ghost
          type='primary'
          onClick={() => setPreviewState(true)}
          style={{width: 120}}
        >
          Preview
        </Button>
      </Row>
      <PreviewDialog
        open={previewState}
        title={titleData?.entities.find((t: Builder.TitleEntity) => t.id === templateConfigs.title)?.value}
        message={messageData?.entities.find((m: Builder.MessageEntity) => m.id === templateConfigs.message)?.value}
        icon={iconData?.entities.find((ic: Builder.IconEntity) => ic.id === templateConfigs.image_url)?.value}
        image={imageData?.entities.find((im: Builder.MessageEntity) => im.id === templateConfigs.image_url)?.value}
        close={() => setPreviewState(false)}
        onClose={() => setPreviewState(false)}
      />
    </Row>
  );
};

export default BulderView;
