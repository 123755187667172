import React from 'react';
import {Row, Space, Button, Radio, Input, Form} from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import {TMP} from './BuilderView';
import {Builder} from '../../../types/builder';

const {Search} = Input;

interface IProps {
  label?: string;
  type: string;
  value: number | string;
  templateConfigs: TMP;
  updateTMPConfigs: (data: number, type: string) => void;
  searchText: string;
  handleSearch: (value: string) => void;
  onBtnClick?: () => void;
  data: Builder.DataOptions;
  disabled?: boolean;
};

const TextCard: React.FC<IProps> = ({label, type, value, searchText, handleSearch, onBtnClick, data, disabled, templateConfigs, updateTMPConfigs}) => {

  const onSearch = (value: string) => {
    handleSearch(value);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      onSearch(e.target.value);
    }
  };

  const handleRadioCheck = (e: RadioChangeEvent) => {
    updateTMPConfigs(e.target.value, type);
  };

  const onReset = () => {
    updateTMPConfigs(null, type);
  };

  const btnVisible = React.useMemo(() => {
    return data.entities?.length < data.total;
  }, [data]);

  const getMetaContent = () => {
    return (
      <Radio.Group onChange={handleRadioCheck} value={value} >
        <Space direction='vertical'>
          {data.entities?.map((item) => {
            return (
              <Radio value={item.id} key={item.id}>{item.value}</Radio>
            );
          })}
        </Space>
      </Radio.Group>
    );
  };

  return (
    <div className='card_content' >
      <Form layout='vertical'>
        <Form.Item
          label={
            <Row align='middle' justify='space-between' style={{width: '100%'}}>
              <div>{label}</div>
              {value &&
                <Button size='small' danger type='text' onClick={onReset}>
                  Reset
                </Button>
              }
            </Row>
          }
        >
          <Search
            onChange={onChange}
            onSearch={onSearch}
            placeholder='Search...'
            disabled={disabled}
          />
        </Form.Item>
      </Form>
      <div className='card_body radio_group'>
        {getMetaContent()}
        {btnVisible &&
          <Row justify='center' className='m-b-5 m-t-3' >
            <Button size='small' onClick={onBtnClick}>
              Show More
            </Button>
          </Row>
        }
      </div>
    </div>
  );
};

export default TextCard;
