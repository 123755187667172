import React from 'react';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {Menu, Dropdown, Row, Button} from 'antd';
import {MoreOutlined, EditFilled, DeleteFilled} from '@ant-design/icons';
import {Builder} from '../../../types/builder';
import {deleteTitle} from '../../../store/actions/builder/title/services';
import DeleteDialog from '../../../components/antd/Dialogs/DeleteDialog';
import CreateOrEditDialog from './Dialogs/CreateOrEditDialog';

interface IProps {
  rowData: Builder.TitleEntity,
};

const iconStyle = {
  marginRight: 10,
  marginTop: 2,
  color: '#0000008a',
};

const ActionCell: React.FC<IProps> = ({ rowData }) => {
  const [showDelete, setShowDelete] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const dispatch = useDispatch();
  const {appUuid} = useParams<{appUuid: string}>();

  const menu = (
    <Menu style={{width: 120}}>
      <Menu.Item
        key='edit'
        onClick={() => setShowEdit(true)}
      >
        <Row align='middle'>
          <EditFilled style={iconStyle}/>
          <div>Edit</div>
        </Row>
      </Menu.Item>
      <Menu.Item
        key='delete'
        onClick={() => setShowDelete(true)}
      >
        <Row align='middle'>
          <DeleteFilled style={{...iconStyle, color: '#f44336'}}/>
          <div>Delete</div>
        </Row>
      </Menu.Item>
    </Menu>
  );

  const handleDelete = (rowData: Builder.TitleEntity) => {
    dispatch(deleteTitle(appUuid, rowData.id));
  };

  const getBody = (rowData: Builder.TitleEntity) => {
    return (
      <>
        <h2>Are you sure you want to delete {rowData.value}?</h2>
        <span>Once you are done deleting, you cannot undo this action.</span>
      </>
    );
  };

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']} key={rowData.id}>
        <Button
          shape='circle'
          size='small'
          icon={<MoreOutlined style={{fontSize: '18px'}}/>}
        />
      </Dropdown>
      {showEdit &&
        <CreateOrEditDialog
          visible={showEdit}
          onCancel={() => setShowEdit(false)}
          rowData={rowData}
        />
      }
      {showDelete &&
        <DeleteDialog
          title='Delete Title'
          onCancelText='Keep Title'
          onAcceptText='Delete Title'
          visible={showDelete}
          onCancel={() => setShowDelete(false)}
          onAccept={handleDelete}
          rowData={rowData}
          body={getBody}
        />
      }
    </>
  );
}

export default ActionCell;
