import React from 'react';
import {Alert} from 'antd';
import {Space} from 'antd';
import {Modal} from 'antd';
import {Button} from 'antd';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {Text} from '../../antd/Typography';
import {Link} from '../../antd/Typography';
import {blankFeatures} from '../../../constants';
import {urlHelper} from '../../../utils/urlHelper';
import {AppRoutes} from '../../../constants/appRoutes';
import {_currentApp} from '../../../store/selectors/apps';
import {_subscribersLimits} from '../../../store/selectors/application/acl';

export function SubscribersLimits(
  {
    matchPath,
  }: {
    matchPath: string | string[];
  }
): null | JSX.Element {
  const app = useSelector(_currentApp);
  const subscribersLimits = useSelector(_subscribersLimits);

  const render = React.useMemo(() => {
    return (
      app?.active_users &&
      subscribersLimits &&
      (app?.active_users >= subscribersLimits) &&
      !Array.isArray(matchPath) && [
        AppRoutes.ANALYTICS_REPORTS,
        AppRoutes.ANALYTICS_SUBSCRIBERS,
      ].includes(matchPath)
    )
  }, [
    matchPath,
    app?.active_users,
    subscribersLimits,
  ]);

  return render ? <Before/> : null;
}

function Before() {
  const [modal, contextHolder] = Modal.useModal();
  const {appUuid} = useParams<{ appUuid: string }>();
  const [alert, toggleAlert] = React.useState<boolean>(false);

  React.useEffect(() => {
    toggleAlert(true);

    return function () {
      toggleAlert(false);
    }
  }, []);

  const alertOnClose = React.useCallback(() => {
    toggleAlert(false);
  }, []);

  const alertContentOnClick = React.useCallback(() => {
    modal.warning({
      centered  : true,
      okCancel  : true,
      cancelText: 'Close',
      okType    : 'primary',
      content   : <Content/>,
      okText    : 'Upgrade Plan',
      title     : 'What does "Reaching Subscribers Limit" mean?',
      onOk      : () => window.open(appUuid ? urlHelper(AppRoutes.CHANGE_PLAN_APP, {appUuid}) : AppRoutes.CHANGE_PLAN, '_blank', blankFeatures),
    })
    toggleAlert(false);
  }, [
    modal,
    appUuid,
  ]);

  return (
    <>
      {alert && (
        <Alert
          banner
          closable
          icon={null}
          type='error'
          showIcon={false}
          onClose={alertOnClose}
          message={
            <AlertContent
              toggleAlert={toggleAlert}
              onClick={alertContentOnClick}
            />
          }
        />
      )}
      {contextHolder}
    </>
  )
}

function Content() {
  return (
    <>
      <Text
        className='m-b-1 d-flex'
      >New visitors will still be able to subscribe. However, these new subscribers will be unavailable. You cannot segment them and send them push notifications.
      </Text>
      <Text
        className='m-b-1 d-flex'
      >Every time someone from your audience unsubscribes, we will add a new subscriber from the waitlist.
      </Text>
      <Text
        className='m-b-1 d-flex'
      >You can upgrade your plan and get Unlimited subscribers.
      </Text>
    </>
  )
}

function AlertContent(
  {
    onClick,
    toggleAlert,
  }: {
    onClick: () => void;
    toggleAlert: React.Dispatch<boolean>;
  }
) {
  const {appUuid} = useParams<{ appUuid: string }>();
  const subscribersLimits = useSelector(_subscribersLimits);

  return (
    <Space
      size={4}
      align='center'
      className='justify-content-center d-flex'
    >
      <Text
      >You have reached your limit of {subscribersLimits} subscribers.
      </Text>
      <Link
        target='_blank'
        onClick={() => toggleAlert(false)}
        href={appUuid ? urlHelper(AppRoutes.CHANGE_PLAN_APP, {appUuid}) : AppRoutes.CHANGE_PLAN}
      >Upgrade
      </Link>
      <Text
      >to get Unlimited Subscribers.
      </Text>
      <Button
        type='text'
        size='small'
        onClick={onClick}
      >
        <Text
          fontWeight='bold'
        >More
        </Text>
      </Button>
    </Space>
  )
}
