import React from 'react';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 'bold',
    color     : '#009688',
  }
}));

export default function RevenueFormatter(
  {
    value,
  }: DataTypeProvider.ValueFormatterProps
) {
  const classes = useStyles();

  return (
    <i className={classes.root}>${Number(value).toFixed(3)}</i>
  )
}
