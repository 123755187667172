import React from 'react';
import {Spin} from 'antd';
import Fade from '@material-ui/core/Fade';
import {Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useLoadingStyles = makeStyles((theme: Theme) => ({
  loaderContainer  : {
    top     : 0,
    left    : 0,
    width   : '100%',
    height  : '100%',
    position: 'absolute',
    zIndex  : theme.zIndex.modal + 2,
  },
  loadingShadingMui: {
    top     : 0,
    left    : 0,
    width   : '100%',
    height  : '100%',
    position: 'absolute',
    zIndex  : theme.zIndex.modal + 1,
  },
  loadingIconMui   : {
    fontSize: 20,
    position: 'absolute',
    top     : 'calc(45% - 16px)',
    left    : 'calc(50% - 16px)',
    zIndex  : theme.zIndex.modal + 3,
  },
}));

export const Loading: React.FC<{
  bgColor?: string;
  isLoading?: boolean;
}> = (
  {
    bgColor,
    isLoading,
  }
) => {
  const {
    loadingIconMui,
    loaderContainer,
    loadingShadingMui,
  } = useLoadingStyles();
  return (
    <>
      <Fade
        in={isLoading}
      >
        <Typography
          component='div'
          className={loaderContainer}
        >
          <Typography
            component='div'
            className={loadingShadingMui}
            style={{backgroundColor: bgColor}}
          >
            <Spin
              size='large'
              className={`${loadingIconMui} d-flex`}
            />
          </Typography>
        </Typography>
      </Fade>
    </>
  )
};
