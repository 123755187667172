import React from 'react';
import {filterObject} from '../../../../../../utils';

export default function (
  {
    icon,
    deny,
    style,
    allow,
    cdnUrl,
    bgColor,
    message,
    position,
    backdrop,
    direction,
    isPreview,
    messageColor,
  }: {
    icon: string;
    cdnUrl: string;
    bgColor: string;
    message: string;
    position: string;
    direction: string;
    backdrop?: boolean;
    isPreview?: boolean;
    messageColor: string;
    style?: React.CSSProperties;
    deny: {
      button: string;
      bgColor: string;
      textColor: string;
    };
    allow: {
      button: string;
      bgColor: string;
      textColor: string;
    };
  }
) {
  return (
    <div>
      <div
        style={style}
        id='ppialog-popover-container'
        className='smart-root-container smart-root-container-3 ppialog-popover-container'
      >
        {!!backdrop && (
          <div
            style={{
              top            : 0,
              left           : 0,
              width          : '100%',
              height         : '100%',
              position       : 'fixed',
              minWidth       : '100vw',
              minHeight      : '100vh',
              zIndex         : 100000,
              backgroundColor: 'rgba(0,0,0,.5)',
            }}
          />
        )}
        <link rel="stylesheet" href={`${cdnUrl}/templates/style_3.css`}/>
        <div
          className={`smart-prompt ${position.split('_').join('-')}`}
          style={filterObject({
            transform: !isPreview && 'unset',
            zIndex   : !isPreview ? 0 : 9999999,
            width    : !isPreview ? '100%' : 'auto',
            position : !isPreview ? 'static' : 'fixed',
          })}
        >
          <div
            className='smart-prompt-content'
            style={{backgroundColor: bgColor}}
          >
            <div
              className='smart-prompt-image'
            >
              <img
                alt='prompt icon'
                src={icon || `${cdnUrl}/default-bell.svg`}
              />
            </div>
            <div
              style={{
                textAlign: 'initial',
                color    : messageColor,
              }}
              className={`smart-prompt-title ${direction}`}
            >{message}
            </div>
            <div
              className='smart-btn-block'
              id='ppialog-popover-cancel-button'
            >
              <svg
                width='12'
                height='12'
                className='icon'
                xmlns='http://www.w3.org/2000/svg'
              >
                <use
                  xlinkHref='#closeIcon'
                />
              </svg>
            </div>
          </div>
          <div
            id='ppialog-popover-allow-button'
            style={{
              backgroundColor: allow.bgColor,
              color          : allow.textColor,
            }}
            className='smart-btn-prompt smart-btn-allow'
          >
            <div
              className='smart-prompt-button-icon'
            >
              <svg
                className='icon'
                width='22'
                height='22'
                xmlns='http://www.w3.org/2000/svg'
              >
                <use
                  xlinkHref='#bellIcon'
                />
              </svg>
            </div>
            <div>
              <span
                className='smart-prompt-button-strong'
              >{allow.button}
              </span>
            </div>
          </div>
        </div>
        <svg
          style={{display: 'none'}}
          xmlns='http://www.w3.org/2000/svg'
        >
          <defs>
            <symbol
              id='bellIcon'
              viewBox='0 257.75 20 20'
            >
              <path
                d='M5.466 259.977l-1.296-1.295a9.761 9.761 0 00-3.886 7.217h1.85c.186-2.5 1.388-4.628 3.332-5.923zm12.4 5.922h1.85c-.185-2.962-1.573-5.552-3.794-7.218l-1.295 1.295c1.85 1.295 3.053 3.424 3.238 5.922zm-1.85.462c0-2.868-1.944-5.182-4.628-5.83v-.647c0-.74-.647-1.388-1.388-1.388-.74 0-1.388.648-1.388 1.388v.648c-2.684.647-4.627 2.96-4.627 5.83v5.088l-1.85 1.852v.926h15.73v-.926l-1.85-1.85v-5.09zM10 277.004h.37c.648-.094 1.11-.556 1.296-1.11.093-.186.185-.464.185-.74h-3.7c0 1.016.832 1.85 1.85 1.85z'
              />
            </symbol>
            <symbol
              id='closeIcon'
              viewBox='0 0 10 10'
            >
              <path
                fillRule='evenodd'
                d='M9.096 0L5 4.097.903 0 0 .904 4.096 5 0 9.097.903 10 5 5.904 9.096 10 10 9.097 5.903 5 10 .904z'
              />
            </symbol>
          </defs>
        </svg>
      </div>
    </div>
  )
}
