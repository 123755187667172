import clsx from 'clsx';
import React from 'react';
import {formatWithCommas} from '../../utils';
import {Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  moreInfo       : {
    display             : 'flex',
    alignItems          : 'center',
    justifyContent      : 'space-between',
    padding             : `${theme.spacing(.5)}px ${theme.spacing(1.5)}px`,
    '& span'            : {
      fontSize  : 13,
      fontWeight: 600,
    },
    '& span:first-child': {
      marginRight: 5,
    },
  },
  moreInfoSuccess: {
    backgroundColor: 'rgb(24, 207, 115)',
    '& span'       : {
      color: '#333333',
    },
  },
  moreInfoFails  : {
    backgroundColor: 'rgb(226, 54, 54)',
    '& span'       : {
      color: '#ffffff'
    },
  },
  moreInfoPending: {
    backgroundColor: 'rgb(222, 222, 222)',
    '& span'       : {
      color: '#333333'
    },
  },
  moreInfoErrors : {
    backgroundColor: 'rgb(246, 230, 0)',
    '& span'       : {
      color: '#333333'
    },
  },
}));

export const StatusItem: React.FC<{
  label: string;
  total: number;
  current: number;
  color: 'success' | 'fails' | 'errors' | 'pending';
}> = (
  {
    label,
    color,
    total,
    current,
  }
) => {
  const classes: any = useStyles();

  let colorClass;
  switch (color) {
    case 'errors': {
      colorClass = classes.moreInfoErrors;
      break;
    }
    case 'success': {
      colorClass = classes.moreInfoSuccess;
      break;
    }
    case 'pending': {
      colorClass = classes.moreInfoPending;
      break;
    }
    case 'fails': {
      colorClass = classes.moreInfoFails;
      break;
    }
  }

  return (
    <Typography
      component='div'
      className={clsx(classes.moreInfo, colorClass)}
    >
      <Typography
        component='span'
      >{label}
      </Typography>
      <Typography
        component='span'
      >{formatWithCommas(current)}({(Number(current) * 100 / (Number(total) === 0 ? 1 : Number(total))).toFixed(2)}%)
      </Typography>
    </Typography>
  )
};
