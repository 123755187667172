export enum TableActions {
  CROP = 'crop',
  EDIT = 'edit',
  INFO = 'info',
  CLONE = 'clone',
  DELETE = 'delete',
  CREATE = 'create',
  CANCEL = 'cancel',
  EXCLUDE = 'exclude',
  EXPORT_AS = 'export_as',
  CLONE_FROM = 'clone_from',
  REGENERATE = 'regenerate',
  TOGGLE_STATE = 'toggle_state',
  SUBMIT_CANCEL = 'submit_cancel',
}