import React from 'react';
import {momentTZ} from '../../../../../../../utils';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import DATE_COLUMNS from '../../../../../../../constants/dateColumns';

export default function WaitUntilFormatter(
  {
    row,
    value,
  }: DataTypeProvider.ValueFormatterProps
) {
  const dateFormat = (DATE_COLUMNS.find(dC => dC.column === 'scheduled_at') || {format: 'YYYY-MM-DD hh:mm:ss A'}).format;
  const newValue = momentTZ(row.scheduled_at || row.wait_until);

  return (
    <>{row.is_system ? '-' : (value ? newValue.format(dateFormat) : '-')}</>
  )
}
