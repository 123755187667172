import {ActionTypes} from '../../constants/subscriptionsAndBilling';

export const _setPortal = (portal: Record<string, any>) => ({
  payload: portal,
  type   : ActionTypes.SET_PORTAL,
});

export const _setDowngrade = (portal: Record<string, any>) => ({
  payload: portal,
  type   : ActionTypes.CANCEL_DOWNGRADE,
});

export const _setSubscriptionPlans = (subscriptionPlans: Record<string, any>) => ({
  payload: subscriptionPlans,
  type   : ActionTypes.SET_SUBSCRIPTION_PLANS,
});

export const _setProrationTotal = (data: Record<string, any>) => ({
  payload: data,
  type   : ActionTypes.SET_PRORATION_TOTAL,
});

export const _upsertSubscriptionsAndBilling = (data: Record<string, any>) => ({
  payload: data,
  type   : ActionTypes.UPSERT_SUBSCRIPTIONS_AND_BILLING,
});
