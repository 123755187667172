import T1 from './1';
import T2 from './2';
import T3 from './3';
import T4 from './4';
import T5 from './5';
import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import {urlHelper} from '../../../../../utils/urlHelper';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const templateList: {
  id: number;
  deny: {
    button: string;
    bgColor: string;
    textColor: string;
  };
  allow: {
    button: string;
    bgColor: string;
    textColor: string;
  };
  icon: string;
  bgColor: string;
  message: string;
  position: string;
  direction: string;
  messageColor: string;
  component: React.FC<{
    icon: string;
    cdnUrl: string;
    bgColor: string;
    message: string;
    position: string;
    direction: string;
    backdrop?: boolean;
    isPreview?: boolean;
    messageColor: string;
    style?: React.CSSProperties;
    deny: {
      button: string;
      bgColor: string;
      textColor: string;
    };
    allow: {
      button: string;
      bgColor: string;
      textColor: string;
    };
  }>
}[] = [
  {
    id          : 1,
    component   : T1,
    direction   : 'ltr',
    bgColor     : '#ffffff',
    position    : 'top_left',
    messageColor: 'rgba(0,0,0,1)',
    deny        : {
      textColor: '#000',
      button   : 'Block',
      bgColor  : '-internal-light-dark(rgb(239, 239, 239), rgb(74, 74, 74))',
    },
    allow       : {
      textColor: '#000',
      button   : 'Allow',
      bgColor  : '-internal-light-dark(rgb(239, 239, 239), rgb(74, 74, 74))',
    },
    icon        : ':CDN_URL/default-bell.svg',
    message     : 'We\'d like to send you notifications for the latest news and updates.',
  },
  {
    id          : 2,
    component   : T2,
    direction   : 'ltr',
    bgColor     : '#FBFBFB',
    position    : 'top_left',
    messageColor: 'rgba(0,0,0,1)',
    allow       : {
      button   : 'Allow',
      textColor: 'rgb(34, 34, 34)',
      bgColor  : 'rgb(249, 249, 249)',
    },
    deny        : {
      textColor: '#000',
      button   : 'Block',
      bgColor  : 'rgb(249, 249, 249)',
    },
    icon        : ':CDN_URL/default-bell.svg',
    message     : 'We\'d like to send you notifications for the latest news and updates.',
  },
  {
    id          : 3,
    component   : T3,
    direction   : 'ltr',
    bgColor     : '#FBFBFB',
    position    : 'top_left',
    messageColor: 'rgba(0,0,0,1)',
    icon        : ':CDN_URL/default-bell.svg',
    message     : 'We\'d like to send you notifications for the latest news and updates.',
    deny        : {
      button   : 'Block',
      textColor: '#000000',
      bgColor  : '-internal-light-dark(rgb(239, 239, 239), rgb(74, 74, 74))',
    },
    allow       : {
      textColor: '#1b80e5',
      button   : 'Subscribe',
      bgColor  : '-internal-light-dark(rgb(239, 239, 239), rgb(74, 74, 74))',
    },
  },
  {
    id          : 4,
    component   : T4,
    direction   : 'ltr',
    bgColor     : '#FBFBFB',
    position    : 'top_left',
    messageColor: 'rgba(0,0,0,1)',
    icon        : ':CDN_URL/default-bell.svg',
    message     : 'We\'d like to send you notifications for the latest news and updates.',
    deny        : {
      textColor: '#777',
      bgColor  : '#ddd',
      button   : 'Not Now',
    },
    allow       : {
      bgColor  : '#0a4',
      textColor: '#ffffff',
      button   : 'Yes, Enable notifications',
    },
  },
  {
    id          : 5,
    component   : T5,
    direction   : 'ltr',
    bgColor     : '#ffffff',
    position    : 'top_left',
    messageColor: 'rgba(0,0,0,1)',
    deny        : {
      textColor: '#000',
      button   : 'Block',
      bgColor  : '-internal-light-dark(rgb(239, 239, 239), rgb(74, 74, 74))',
    },
    allow       : {
      textColor: '#000',
      button   : 'Allow',
      bgColor  : '-internal-light-dark(rgb(239, 239, 239), rgb(74, 74, 74))',
    },
    icon        : ':CDN_URL/default-bell.svg',
    message     : 'We\'d like to send you notifications for the latest news and updates.',
  },
];

export function TemplateList(
  {
    value,
    cdnUrl,
    onChange,
  }: {
    cdnUrl: string;
    value?: number;
    onClose: () => void;
    onChange: (value: any) => void;
  }
) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number((event.target as HTMLInputElement).value));
  };

  return (
    <div
      style={{
        display      : 'flex',
        alignItems   : 'center',
        flexDirection: 'column',
      }}
    >
      <FormControl
        component='fieldset'
      >
        <RadioGroup
          value={value}
          name='template'
          onChange={handleChange}
        >
          {templateList.map((t, index) => {
            const {
              component: Component,
            } = t;
            return (
              <FormControlLabel
                value={t.id}
                key={`T_${index}`}
                control={
                  <Radio
                    color='primary'
                  />
                }
                style={{marginBottom: 16}}
                label={
                  <div>
                    <Component
                      {...t}
                      cdnUrl={cdnUrl}
                      icon={urlHelper(t.icon, {CDN_URL: cdnUrl})}
                      style={{margin: 4, minWidth: 300, position: 'relative'}}
                    />
                  </div>
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  )
}
