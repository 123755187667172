import clsx from 'clsx';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {getWidth} from '../../../../../../../../utils/getWidth';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';

const useStyles = makeStyles((theme) => ({
  toolTipLi     : {
    fontSize  : 14,
    fontWeight: 500,
  },
  root          : {
    display                       : 'flex',
    alignItems                    : 'baseline',
    justifyContent                : 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    }
  },
  spanContainers: {
    display   : 'flex',
    alignItems: 'baseline',
  },
  includeIcon   : {
    fontSize: 15,
    color   : '#3AAB60'
  },
  excludeIcon   : {
    fontSize: 15,
    color   : '#DE0000'
  },
  tableUl       : {
    listStyle  : 'none',
    paddingLeft: 8,
    margin     : 0
  },
  tableLi       : {
    fontWeight: 'bold',
    fontSize  : 12
  },
  typographyAll : {
    fontSize  : 12,
    fontWeight: 'bold',
  },
}));

export default function SegmentCell(
  {
    campaign
  }: {
    campaign: { [key: string]: any }
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();

  const firstIncludeSegment = campaign?.front_data?.include_segments && campaign?.front_data?.include_segments[0];
  const includeWithOutFirst = (campaign?.front_data?.include_segments && campaign?.front_data?.include_segments.length > 1 && campaign?.front_data?.include_segments.slice(1)) || [];

  const firstExcludeSegment = campaign?.front_data?.exclude_segments && campaign?.front_data?.exclude_segments[0];
  const excludeWithOutFirst = (campaign?.front_data?.exclude_segments && campaign?.front_data?.exclude_segments.length > 1 && campaign?.front_data?.exclude_segments.slice(1)) || [];

  function setIncludeWidth() {
    if (getWidth(firstIncludeSegment?.label, 'bold 12px roboto') >= 200) {
      return 200
    } else {
      return 'auto'
    }
  }

  function setExcludeWidth() {
    if (getWidth(firstExcludeSegment?.label, 'bold 12px roboto') >= 200) {
      return 200
    } else {
      return 'auto'
    }
  }

  return (
    <Typography
      noWrap
      component='span'
      className={clsx(classes.root, classes.spanContainers)}
    >
      {firstIncludeSegment && (
        <Typography
          style={{
            width      : setIncludeWidth(),
            marginRight: firstExcludeSegment ? 18 : 0,
          }}
          component='span'
          className={classes.spanContainers}
        >
          <Tooltip
            title={t('campaigns.include_segments')}
          >
            <CheckCircleOutlineRoundedIcon
              className={classes.includeIcon}
            />
          </Tooltip>
          <Typography
            noWrap
            component='ul'
            className={classes.tableUl}
          >
            <Typography
              noWrap
              component='li'
              style={{display: 'flex'}}
              className={classes.tableLi}
              key={`CampaignsListIncludeSegments_${firstIncludeSegment.label}${Math.random()}`}
            >
              <Tooltip
                title={firstIncludeSegment.label}
              >
                <Typography
                  component='div'
                  style={{
                    display     : 'block',
                    whiteSpace  : 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow    : 'hidden',
                    width       : '100%'
                  }}
                  className={classes.tableLi}
                >{firstIncludeSegment.label}
                </Typography>
              </Tooltip>
              {campaign?.front_data?.include_segments.length > 1 && (
                <Tooltip
                  title={includeWithOutFirst.map((item: any) => (
                    <li
                      className={classes.toolTipLi}
                      key={`CampaignsListIncludeSegments_${item.label}${Math.random()}`}
                      style={{listStyle: campaign?.front_data?.include_segments.length > 1 ? 'circle' : 'none'}}
                    >{item.label}
                    </li>
                  ))}
                >
                  <span style={{color: '#096dd9', marginLeft: 6}}>{`+${(includeWithOutFirst.length)}`} </span>
                </Tooltip>
              )}
            </Typography>
          </Typography>
        </Typography>
      )}
      {firstExcludeSegment && (
        <Typography
          component='span'
          className={classes.spanContainers}
          style={{width: setExcludeWidth()}}
        >
          <Tooltip
            title={t('campaigns.exclude_segments')}
          >
            <RemoveCircleOutlineRoundedIcon
              className={classes.excludeIcon}
            />
          </Tooltip>
          <Typography
            noWrap
            component='ul'
            className={classes.tableUl}
          >
            <Typography
              noWrap
              component='li'
              style={{display: 'flex'}}
              className={classes.tableLi}
              key={`CampaignsListIncludeSegments_${firstExcludeSegment.label}${Math.random()}`}
            >
              <Tooltip
                title={firstExcludeSegment.label}
              >
                <Typography
                  component='div'
                  style={{
                    width       : '100%',
                    display     : 'block',
                    overflow    : 'hidden',
                    whiteSpace  : 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                  className={classes.tableLi}
                >{firstExcludeSegment.label}
                </Typography>
              </Tooltip>
              {campaign?.front_data?.exclude_segments?.length > 1 && (
                <Tooltip
                  title={excludeWithOutFirst.map((item: any) => (
                    <li
                      style={{
                        listStyle: campaign?.front_data?.exclude_segments.length > 1 ? 'circle' : 'none',
                      }}
                      className={'classes.toolTipLi'}
                      key={`CampaignsListIncludeSegments_${item.label}${Math.random()}`}
                    >{item.label}
                    </li>
                  ))}
                >
                  <span
                    style={{
                      marginLeft: 6,
                      color     : '#096dd9',
                    }}
                  >{`+${(excludeWithOutFirst.length)}`} </span>
                </Tooltip>
              )}
            </Typography>
          </Typography>
        </Typography>
      )}
      {(() => {
        if ((String(campaign && campaign?.send_to).toLocaleLowerCase() === 'all')) {
          return (
            <Typography
              align='left'
              component='span'
              className={classes.typographyAll}
            >{t('campaigns.all')}
            </Typography>
          )
        } else if (!(campaign?.front_data?.include_segments && campaign?.front_data?.exclude_segments)) {
          return (
            <></>
          )
        }
      })()}
    </Typography>
  )
}
