import * as Sentry from '@sentry/react';
import {Globals} from '../../../types/globals';
import {blankFeatures} from '../../../constants';
import {ApiRoutes} from '../../../constants/apiRoutes';

export const _googleAuth = (
  {
    plan,
    action,
    searchString,
    reCaptchaAction,
    executeRecaptcha,
  }: {
    plan?: string;
    searchString?: string;
    reCaptchaAction: string;
    action: 'gmail_login' | 'gmail_registration';
    executeRecaptcha: (action?: string) => Promise<string>;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  let token: string | undefined = void 0;
  if (typeof executeRecaptcha === 'function') {
    token = await executeRecaptcha(reCaptchaAction);
  }

  const {
    body: {
      data,
      message,
    },
    hasError,
  } = await api.post(ApiRoutes.OAUTH, {
    plan                  : plan,
    agreed                : true,
    provider              : 'google',
    'g-recaptcha-response': token || 'token',
  });

  if (hasError) {
    console.error(data);
    Sentry.setExtra('googleAuth', {data, message});
    Sentry.captureException({data, message});
  } else {
    const {redirect_url} = data;

    window.open(
      `${redirect_url}&state=${plan || ''},${action},${encodeURIComponent(searchString)}`,
      '_self',
      blankFeatures
    );
  }
};
