import React from 'react';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {CurrentPlan} from '../CurrentPlan';
import {BillingHistory} from '../BillingHistory';
import {_invoices} from '../../../../../../../store/selectors/portal';
import {_currentPlan} from '../../../../../../../store/selectors/portal';
import {_scheduledPlan} from '../../../../../../../store/selectors/portal';
import {_isDowngrading} from '../../../../../../../store/selectors/portal';
import {_paymentMethod} from '../../../../../../../store/selectors/portal';
import {_billingAddress} from '../../../../../../../store/selectors/portal';
import {PaymentMethodAndBillingAddress} from '../PaymentMethodAndBillingAddress';
import {_cancelDowngrade} from '../../../../../../../store/actions/subscriptionsAndBilling/cancelDowngrade';

export function SubscriptionInfo() {
  const dispatch = useDispatch();
  const invoices = useSelector(_invoices);
  const currentPlan = useSelector(_currentPlan);
  const scheduledPlan = useSelector(_scheduledPlan);
  const isDowngrading = useSelector(_isDowngrading);
  const paymentMethod = useSelector(_paymentMethod);
  const billingAddress = useSelector(_billingAddress);

  const cancelDowngrade = React.useCallback(() => {
    dispatch(_cancelDowngrade());
  }, [
    dispatch,
  ]);

  return (
    <div>
      {!!currentPlan && (
        <div
          className='m-b-2'
        >
          <CurrentPlan
            currentPlan={currentPlan}
            scheduledPlan={scheduledPlan}
            isDowngrading={isDowngrading}
            cancelDowngrade={cancelDowngrade}
          />
        </div>
      )}
      <div
        className='m-b-2'
      >
        <PaymentMethodAndBillingAddress
          paymentMethod={paymentMethod}
          billingAddress={billingAddress}
        />
      </div>
      <BillingHistory
        invoices={invoices}
      />
    </div>
  )
}
