import React from 'react';
import Dialogs from '../../../Dialogs';
import ActionFormatter from '../Formatter';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {DataTypeProviderProps} from '@devexpress/dx-react-grid';

export default function ActionProvider(
  {
    actions,
    ...restProps
  }: DataTypeProviderProps & { actions: any }
) {
  return (
    <>
      <Dialogs/>
      <DataTypeProvider
        formatterComponent={ActionFormatter}
        {...restProps}
      />
    </>
  )
}
