import React from 'react';
import {Skip} from './Skip';
import {Draft} from 'immer';
import {Wizard} from './Wizard';
import {Header} from './Header';
import {Welcome} from './Welcome';
import {Surveys} from '../../../types/surveys';
import {getPrefixCls} from '../../../utils/getPrefixCls';
import './index.less';

export function SurveyContainer(
  {
    skip,
    data,
    start,
    letsGo,
    setData,
    firstName,
  }: {
    skip: () => void;
    start: () => void;
    firstName: string;
    letsGo: () => void;
    data: Surveys.Data;
    setData: (f: (draft: Draft<Surveys.Data>) => void | Surveys.Data) => void;
  }
) {
  React.useEffect(() => {
    document.body.style.overflowY = 'hidden';

    if (document.getElementById('tidio-chat')) {
      document.getElementById('tidio-chat').style.display = 'none';
    }
    return function () {
      document.body.style.overflowY = 'initial';
      if (document.getElementById('tidio-chat')) {
        document.getElementById('tidio-chat').style.display = 'block';
      }
    }
  }, []);

  return (
    <div
      className={`${getPrefixCls('survey-container')} d-flex flex-column`}
    >
      <Header/>
      {!data.started ? (
        <Welcome
          onClick={start}
          firstName={firstName}
        />
      ) : (
        <Wizard
          data={data}
          letsGo={letsGo}
          setData={setData}
        />
      )}
      <Skip
        data={data}
        skip={skip}
        setData={setData}
      />
    </div>
  )
}