import {getState} from '../index';
import {createSelector} from 'reselect';

const _model = createSelector(getState, (state) => {
  return state.prompt;
});

export const _entity = createSelector(_model, (model) => {
  return model.entity;
});

export const _dataOptions = createSelector(_model, (model) => {
  return model.dataOptions;
});

export const _isTogglingPrompt = createSelector(_dataOptions, (options) => {
  return options?.isTogglingPrompt;
});
