import React from 'react';
import {Button} from 'antd';
import {useWatch} from 'react-hook-form';
import {ILoginForm} from '../../useLogin';
import {Control} from 'react-hook-form/dist/types/form';
import {dataPrefixes} from '../../../../../../constants';

const {auth: {login}} = dataPrefixes;

export function SubmitButton(
  {
    control,
    loading,
  }: {
    loading: boolean;
    control: Control<ILoginForm>,
  }
) {
  const email = useWatch({
    control,
    name: 'email',
  });
  const password = useWatch({
    control,
    name: 'password',
  });

  return (
    <Button
      block
      size='large'
      type='primary'
      htmlType='submit'
      loading={loading}
      disabled={!(password && email)}
      data-qa={`${login}-login-button`}
    >Sign In
    </Button>
  )
}
