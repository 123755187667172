import {apps} from './apps';
import {prompt} from './prompt';
import gallery from './gallery';
import {reports} from './reports';
import settings from './settings';
import {locales} from './locales';
import {apiKeys} from './apiKeys';
import {automations} from './automation';
import {users} from './users';
import builder from './builder';
import uploader from './uploader';
import {segments} from './segments';
import smartPush from './smartPush';
import {templates} from './templates';
import {combineReducers} from 'redux';
import {application} from './application';
import {subscriptionsAndBilling} from './subscriptionsAndBilling';

const createRootReducer = () => combineReducers({
  apps,
  prompt,
  reports,
  gallery,
  apiKeys,
  locales,
  settings,
  segments,
  uploader,
  smartPush,
  automations,
  users,
  builder,
  templates,
  application,
  subscriptionsAndBilling,
});

export default createRootReducer;
