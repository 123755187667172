import React from 'react';
import {Breadcrumb} from 'antd';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import Table from '@material-ui/core/Table';
import {useTranslation} from 'react-i18next';
import {momentTZ} from '../../../../../utils';
import {Theme} from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import {copyToClipboard} from '../../../../../utils';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {urlHelper} from '../../../../../utils/urlHelper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {Spinner} from '../../../../../components/antd/Spinner';
import {Content} from '../../../../../components/antd/Content';
import DATE_COLUMNS from '../../../../../constants/dateColumns';
import {Title} from '../../../../../components/antd/Typography';
import ObjectDrawer from '../../../../../components/ObjectDrawer';
import {getAction} from '../../../../../store/actions/subscribers';
import {Navigation} from '../../../../../components/antd/Navigation';
import {convertTimezone} from '../../../../../utils/convertTimezone';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {getSubscriber} from '../../../../../store/selectors/subscribers';
import {toggleEntityAction} from '../../../../../store/actions/subscribers';
import {_hasConversionsAccess} from '../../../../../store/selectors/application/acl';
import {useTitle} from '../../../../../hooks/useTitle';
import {notification} from 'antd';

const useStyles = makeStyles((theme: Theme) => ({
  iconButton          : {
    marginLeft: 5,
  },
  fileCopyOutlinedIcon: {
    fontSize: 14,
  },
  mainInfo            : {
    width                         : '50%',
    marginRight                   : theme.spacing(.5),
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(1)}px auto`,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  varsInfo            : {
    width                         : '50%',
    marginLeft                    : theme.spacing(.5),
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  campaignLink        : {
    fontSize    : 12,
    height      : '3em',
    overflowY   : 'hidden',
    overflowWrap: 'anywhere',
    textOverflow: 'ellipsis',
  },
  clickIdTypography   : {
    fontSize    : 12,
    height      : '3em',
    overflowY   : 'hidden',
    overflowWrap: 'anywhere',
    textOverflow: 'ellipsis',
  },
  infoContainer       : {
    display                       : 'flex',
    '& .MuiTableCell-body'        : {
      borderTop        : 'none',
      borderLeft       : 'none',
      borderRight      : 'none',
      borderBottomWidth: 2,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  clickIdRoot         : {
    display   : 'flex',
    alignItems: 'center',
  },
  title               : {
    marginTop   : 0,
    fontWeight  : 'bold',
    alignItems  : 'center',
    cursor      : 'default',
    display     : 'inline-flex',
    marginBottom: theme.spacing(1),
  },
  tbHeaderRow         : {
    '& > td': {
      fontWeight: 500,
    },
  },
  varsInfoBlock       : {
    marginBottom: theme.spacing(1),
  },
}));

export function SubscriberInfo() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const mainInfoRef = React.useRef<any>(null);
  const subscriber = useSelector(getSubscriber);
  useTitle(t('subscribers.info_document_title'));
  const hasConversionsAcl = useSelector(_hasConversionsAccess);
  const [height, setHeight] = React.useState<number | null | string>(null);

  const {
    uuid,
    appUuid,
  } = useParams<{
    uuid: string;
    appUuid: string;
  }>();

  const redirect = React.useCallback(() => {
    history.push(urlHelper(AppRoutes.ANALYTICS_SUBSCRIBERS, {
      appUuid,
    }));
  }, [
    history,
    appUuid,
  ]);

  const copyText = React.useCallback((text: string, message: string) => {
    if (copyToClipboard(text)) {
      notification.success({
        message
      });
    } else {
      notification.error({
        message: t('subscribers.something_went_wrong'),
      });
    }
  }, [
    t,
  ]);

  React.useEffect(() => {
    let controller: null | AbortController = null;

    if (appUuid && uuid) {
      controller = new AbortController();
      dispatch(getAction(appUuid, uuid, {
        redirect,
        signal: controller.signal,
      }))
    }

    return function () {
      controller?.abort();

      dispatch(toggleEntityAction(void 0));
    }
  }, [
    uuid,
    appUuid,
    redirect,
    dispatch,
  ]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (mainInfoRef.current?.clientHeight) {
        if ((window.innerHeight - 241) > mainInfoRef.current?.clientHeight) {
          setHeight(window.innerHeight - 241)
        } else {
          setHeight(mainInfoRef.current?.clientHeight)
        }
        clearInterval(interval);
      }
    }, 1000)
  }, [t])

  if (!subscriber) {
    return (
      <Spinner/>
    )
  }

  return (
    <>
      <Navigation
        title={
          <Title
            level={4}
            fontWeight='medium'
            copyable={{text: subscriber.uuid}}
            ellipsis={{tooltip: subscriber.uuid}}
          >UUID: {subscriber.uuid}
          </Title>
        }
        breadcrumbItems={[
          <Breadcrumb.Item
            key='app.subscribers'
          >
            <Link
              to={urlHelper(AppRoutes.ANALYTICS_SUBSCRIBERS, {appUuid})}
            >Subscribers
            </Link>
          </Breadcrumb.Item>,
          <Breadcrumb.Item
            key='app.subscribers.subscriberInfo'
          >UUID: {subscriber.uuid}
          </Breadcrumb.Item>,
        ]}
      />
      <Content>
        <Typography
          component='div'
          className={classes.infoContainer}
        >
          <Typography
            component='div'
            className={classes.mainInfo}
          >
            <Table
              ref={mainInfoRef}
            >
              <TableBody>
                {Object.keys(subscriber || {}).filter((key: any) => {
                  if (hasConversionsAcl) {
                    return true;
                  }
                  return ![
                    'conversion_sum',
                    'conversion_count',
                    'last_converted_at',
                  ].includes(key);
                }).map((key: string) => {
                  const value = subscriber[key];
                  const dateFormat = (DATE_COLUMNS.find(dC => dC.column === key));
                  if (key === 'tags') {
                    return (
                      <TableRow
                        key={`WebNotifySubscriber_${key}`}
                      >
                        <TableCell
                          align='left'
                        >{t(`subscribers.${key}`)}
                        </TableCell>
                        <TableCell
                          align='right'
                        >
                          {(value && (Object.prototype.toString.call(value) === '[object Object]')) ? (
                            <ObjectDrawer
                              value={value}
                              style={{justifyContent: 'flex-end'}}
                            />
                          ) : (
                            '-'
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  }

                  return (![
                    'tags',
                    'country',
                    'user_id',
                    'app_data',
                    'app_uuid',
                  ].includes(key) && !!value && !(value instanceof Object)) && (
                    <TableRow
                      key={`WebNotifySubscriber_${key}`}
                    >
                      <TableCell
                        align='left'
                      >{key === 'sessions' ? t(`subscribers.visits_count`) : t(`subscribers.${key}`)}
                      </TableCell>
                      <TableCell
                        align='right'
                      >
                        {(() => {
                          if (key === 'time_zone') {
                            if (Number(value)) {
                              return convertTimezone(Number(value))
                            } else {
                              return '-'
                            }
                          } else {
                            if (dateFormat) {
                              return momentTZ(value).format(dateFormat.format)
                            } else {
                              return value;
                            }
                          }
                        })()}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Typography>
          <Typography
            component='div'
            className={classes.varsInfo}
            style={{height, overflowY: 'auto', minHeight: 500}}
          >
            {!!(subscriber?.conversions || []).length && (
              <Typography
                component='div'
                className={classes.varsInfoBlock}
              >
                <Table>
                  <TableBody>
                    <TableRow
                      className={classes.tbHeaderRow}
                    >
                      <TableCell
                        align='left'
                      >{t(('subscribers.campaign'))}
                      </TableCell>
                      <TableCell
                        align='left'
                      >{t(('subscribers.click_id'))}
                      </TableCell>
                      <TableCell
                        align='left'
                      >{t(('subscribers.amount'))}
                      </TableCell>
                      <TableCell
                        align='left'
                      >{t(('subscribers.timestamp'))}
                      </TableCell>
                    </TableRow>
                    {(subscriber?.conversions || []).map((conversion: any, index: number) => {
                      const dateFormat = (DATE_COLUMNS.find(dC => dC.column === 'created_at')).format;
                      return (
                        <TableRow
                          key={`WebNotifySubscriber_${index}`}
                        >
                          <TableCell
                            align='left'
                          >
                            <Typography
                              className={classes.campaignLink}
                            >
                              <Link
                                target='_blank'
                                rel='noopener noreferrer'
                                to={urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_INFO, {
                                  appUuid,
                                  uuid: conversion.notification_uuid,
                                })}
                              >{conversion.notification_uuid}
                              </Link>
                            </Typography>
                          </TableCell>
                          <TableCell
                            align='left'
                          >
                            <Typography
                              component='div'
                              className={classes.clickIdRoot}
                            >
                              <Typography
                                className={classes.clickIdTypography}
                              >{`${conversion.notification_uuid}_${subscriber.uuid}`}
                              </Typography>
                              <IconButton
                                size='small'
                                onClick={() => {
                                  copyText(
                                    `${conversion.notification_uuid}_${subscriber.uuid}`,
                                    t('subscribers.click_id_successfully_copied')
                                  )
                                }}
                                className={classes.iconButton}
                              >
                                <FileCopyOutlinedIcon
                                  className={classes.fileCopyOutlinedIcon}
                                />
                              </IconButton>
                            </Typography>
                          </TableCell>
                          <TableCell
                            align='left'
                          >${Number(conversion.payout).toFixed(3)}
                          </TableCell>
                          <TableCell
                            align='right'
                          >
                            <Typography
                              className={classes.clickIdTypography}
                            >{momentTZ(conversion.created_at).format(dateFormat)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Typography>
            )}
            {(subscriber?.app_data || []).map((appData: Record<string, any>) => {
              const dateFormat = (DATE_COLUMNS.find(dC => dC.column === 'created_at')).format;
              const tmp = Object.keys(appData || {}).filter((i: any) => {
                return ![
                  'id',
                  'data',
                  'created_at',
                  'subscriber_uuid',
                ].includes(i) && !!appData[i];
              }).map((i) => {
                return {
                  label: i,
                  value: appData[i],
                }
              });

              return (
                <Typography
                  component='div'
                  className={classes.varsInfoBlock}
                  key={`WebNotifySubscriber_${appData.id}`}
                >
                  <Table>
                    <TableBody>
                      <TableRow
                        className={classes.tbHeaderRow}
                      >
                        <TableCell
                          colSpan={2}
                          align='left'
                        >{momentTZ(appData.created_at).format(dateFormat)}
                        </TableCell>
                      </TableRow>
                      {!tmp.length ? (
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            align='center'
                          >{t('subscribers.subscriber_entered_the_web_page')}
                          </TableCell>
                        </TableRow>
                      ) : (
                        tmp.map((i) => {
                          return (
                            <TableRow
                              key={`WebNotifySubscriber_${i.label}`}
                            >
                              <TableCell
                                align='left'
                              >{i.label}
                              </TableCell>
                              <TableCell
                                align='right'
                              >{i.value}
                              </TableCell>
                            </TableRow>
                          )
                        })
                      )}
                    </TableBody>
                  </Table>
                </Typography>
              )
            })}
          </Typography>
        </Typography>
      </Content>
    </>
  )
}