import React from 'react';
import {useDispatch} from 'react-redux';
import {ButtonProps} from 'antd/lib/button/button';
import {PopconfirmProps} from 'antd/lib/popconfirm';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import {ApiKeys} from '../../../../../../../../types/apiKeys';
import {aclKeys} from '../../../../../../../../constants/aclKeys';
import {Text} from '../../../../../../../../components/antd/Typography';
import {useSmartPush} from '../../../../../../../../hooks/useSmartPush';
import {FiniteStates} from '../../../../../../../../constants/finiteStates';
import {TableAction} from '../../../../../../../../components/antd/TableAction';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import {_regenerate} from '../../../../../../../../store/actions/apiKeys/regenerate';

export function RegenerateAction(
  {
    row,
  }: {
    row: ApiKeys.Entity;
  }
) {
  const {
    user: {
      canUpdate,
    }
  } = useSmartPush();
  const dispatch = useDispatch();
  const buttonProps: ButtonProps = {
    size    : 'small',
    shape   : 'circle',
    type    : 'default',
    icon    : <SyncOutlined rotate={90}/>,
    disabled: !canUpdate(aclKeys.API_KEYS),
    loading : row.regenerating === FiniteStates.LOADING,
  };
  const popconfirmProps: PopconfirmProps = canUpdate(aclKeys.API_KEYS) ? {
    placement : 'topRight',
    cancelText: 'Cancel Regenerate',
    okText    : 'Regenerate API Key',
    onConfirm : () => dispatch(_regenerate(row.id)),
    title     : <RegeneratePopconfirmTitle name={row.name}/>,
    icon      : <Text type='warning' className='anticon'><QuestionCircleOutlined/></Text>,
  } : void 0
  const tooltipTitle = canUpdate(aclKeys.API_KEYS) ? 'Regenerate' : 'You don\'t have access to manage this action';

  return (
    <TableAction
      buttonProps={buttonProps}
      popconfirmProps={popconfirmProps}
      tooltipProps={{title: tooltipTitle}}
    />
  )
}

function RegeneratePopconfirmTitle(
  {
    name,
  }: {
    name: string;
  }
) {
  return (
    <>
      <div>
        <Text
          align='left'
        >Are you sure you want to regenerate <Text style={{maxWidth: '20rem'}} ellipsis>'{name}'</Text>?
        </Text>
      </div>
      <Text
        align='left'
        type='secondary'
        fontSize='small'
        fontWeight='regular'
      >All external services using this API Key will not be able to communicate with SmartPush any longer.
      </Text>
    </>
  )
}
