import {Spin} from 'antd';
import React from 'react';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {IOSSwitch} from '../../../../../../../components/IOSSwitch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {_subscriptionState} from '../../../../../../../store/selectors/apps';
import {_isTogglingSubscription} from '../../../../../../../store/selectors/apps';
import {_canUpdateApp} from '../../../../../../../store/selectors/application/acl';
import {_toggleSubscription} from '../../../../../../../store/actions/apps/toggleSubscription';

const useStyles = makeStyles(() => ({
  formControlLabel: {
    marginLeft : 0,
    marginRight: 0,
  },
}));

export function ToggleSubscription() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canUpdateApp = useSelector(_canUpdateApp);
  const checked = useSelector(_subscriptionState);
  const {appUuid} = useParams<{ appUuid: string }>();
  const isTogglingSubscription = useSelector(_isTogglingSubscription);

  const toggleSubscription = React.useCallback(() => {
    return dispatch(_toggleSubscription(appUuid));
  }, [
    appUuid,
    dispatch,
  ]);

  return (
    <Box
      padding={2}
      marginBottom={1}
      style={{backgroundColor: '#fff'}}
    >
      <Box
        display='flex'
        alignItems='center'
      >
        <FormControlLabel
          checked={checked}
          labelPlacement='end'
          onChange={toggleSubscription}
          control={<IOSSwitch size='small'/>}
          classes={{root: classes.formControlLabel}}
          disabled={isTogglingSubscription || !canUpdateApp}
          label={<Label isTogglingSubscription={isTogglingSubscription}/>}
        />
      </Box>
    </Box>
  )
}

function Label(
  {
    isTogglingSubscription,
  }: {
    isTogglingSubscription?: boolean;
  }
) {
  return (
    <Box
      display='flex'
      marginLeft={.5}
      alignItems='center'
    >
      <Typography
        variant='body1'
        component='span'
      ><strong>Subscriptions are enabled</strong>
      </Typography>
      {isTogglingSubscription && (
        <Box
          display='flex'
          marginLeft={1}
        >
          <Spin
            size='small'
            style={{display: 'flex'}}
          />
        </Box>
      )}
    </Box>
  )
}