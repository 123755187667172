import {getState} from '../index';
import {createSelector} from 'reselect';

export const _apiKeysReducer = createSelector(getState, (application) => {
  return application.apiKeys;
});

export const _apiKeys = createSelector(_apiKeysReducer, (apiKeys) => {
  return apiKeys.entities;
});

export const _dataOptions = createSelector(_apiKeysReducer, (apiKeys) => {
  return apiKeys.dataOptions;
});

export const _total = createSelector(_dataOptions, (options) => {
  return options.total;
});

export const _fetchingApiKeysState = createSelector(_dataOptions, (options) => {
  return options.fetchingApiKeysState;
});

export const _pageSize = createSelector(_dataOptions, (options) => {
  return options.pageSize;
});

export const _pageNumber = createSelector(_dataOptions, (options) => {
  return options.pageNumber;
});

export const _modal = createSelector(_dataOptions, (options) => {
  return options.modal;
});

export const _row = createSelector(_dataOptions, (options) => {
  return options.row;
});

export const _searchText = createSelector(_dataOptions, (options) => {
  return options.searchText;
});
