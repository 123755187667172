import React from 'react';
import {Redirect} from 'react-router-dom';
import {Route, Switch} from 'react-router';
import Header from './CreateOrUpdate/Header';
import Automation from './List';
import CreateOrUpdateForms from './CreateOrUpdate/CreateOrUpdateForms';
import UpdateAutomation from './CreateOrUpdate/UpdateAutomation';
import {AppRoutes} from '../../../constants/appRoutes';
import {urlHelper} from '../../../utils/urlHelper';

const AutomationRoutes: React.FC = () => {

  return (
    <Switch>
      <Route
        exact
        component={Automation}
        path={AppRoutes.AUTOMATIONS}
      />
      <Route
        exact
        render={() =>
          <>
            <Header title='Create Automation'/>
            <CreateOrUpdateForms
              headerText='CREATE NEW AUTOMATION'
            />
          </>
        }
        path={AppRoutes.AUTOMATION_CREATE}
      />
      <Route
        exact
        component={() =>
          <>
            <Header title='Update Automation'/>
            <UpdateAutomation
              headerText='UPDATE AUTOMATION'
            />
          </>
        }
        path={AppRoutes.AUTOMATION_UPDATE}
      />
      <Route
        exact
        component={() =>
          <>
            <Header title='Create an automation'/>
            <UpdateAutomation
              headerText='CREATE AN AUTOMATION'
              isActionClone
            />
          </>
        }
        path={AppRoutes.AUTOMATION_CLONE}
      />
      <Route
        render={(props) => {
          const {appUuid} = props.match.params
          return (
            <Redirect
              to={urlHelper(AppRoutes.AUTOMATIONS, {appUuid})}
            />
          )
        }}
      />
    </Switch>
  );
};

export default AutomationRoutes;
