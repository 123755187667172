import {CancelToken} from 'axios';
import {notification} from 'antd';
import {_dataOptions} from './index';
import {_upsertEntity} from './index';
import {AbortError} from '../../../constants';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {_segmentsParams} from '../../selectors/segments';
import {FiniteStates} from '../../../constants/finiteStates';
import {_hasConversionsAccess} from '../../selectors/application/acl';
import {responseTransformer} from '../../../utils/segmentsTransformer';

export const _get = (appUuid: string, id: number, {
  cancelToken,
}: {
  cancelToken: CancelToken;
}): Globals.ThunkAction => async (dispatch, getState, {
  api
}) => {
  dispatch(_dataOptions({
    fetchingSegmentState: FiniteStates.LOADING,
  }));

  const {
    hasError,
    body: {
      data,
      message,
      name
    },
  } = await api.get(urlHelper(ApiRoutes.GET_SEGMENT, {id, appUuid}), {
    cancelToken,
  });

  if (!hasError) {
    const typeOptions = _segmentsParams(getState());
    const hasConversionsAcl = _hasConversionsAccess(getState());

    const formData = responseTransformer(data, typeOptions, {
      hasConversionsAcl,
    });
    dispatch(_upsertEntity(formData));

    dispatch(_dataOptions({
      fetchingSegmentState: FiniteStates.SUCCESS,
    }));
  } else {
    dispatch(_dataOptions({
      fetchingSegmentState: FiniteStates.FAILURE,
    }));

    if (name !== AbortError) {
      notification.error({message})
    }
  }
};