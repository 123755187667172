import {_upsertApplication} from './index';
import {Globals} from '../../../types/globals';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {FiniteStates} from '../../../constants/finiteStates';
import {RecaptchaActions} from '../../../constants/recaptchaActions';

export const _checkAppSumoToken = (
  {
    token,
  }: {
    token: string;
  }, {
    executeRecaptcha,
  }: {
    executeRecaptcha: (action?: string) => Promise<string>;
  }): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_upsertApplication({
    appSumoTokenCheckingState: FiniteStates.LOADING,
  }));

  let gRecaptchaResponse: string | undefined = void 0;
  if (typeof executeRecaptcha === 'function') {
    gRecaptchaResponse = await executeRecaptcha(RecaptchaActions.CHECK_APPSUMO_TOKEN);
  }

  const {
    body: {
      data,
      message,
    },
    hasError,
  } = await api.post(ApiRoutes.APP_SUMO_CHECK_TOKEN, {
    token,
    'g-recaptcha-response': gRecaptchaResponse
  });
  if (!hasError) {
    dispatch(_upsertApplication({
      appSumoToken             : data.token,
      appSumoTokenCheckingState: FiniteStates.SUCCESS,
      activation_email         : data.activation_email,
      appSumoMessage           : message || data?.message,
    }))
  } else {
    dispatch(_upsertApplication({
      appSumoTokenCheckingState: FiniteStates.FAILURE,
      appSumoMessage           : message || data?.message || 'Something went wrong please try again later',
    }));
  }
};
