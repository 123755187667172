import React from 'react';
import {useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {getTitlesData} from '../../../store/actions/builder/title/services';
import {_cleanUp} from '../../../store/actions/builder/title/actions';
import FilterSection from './FilterSection';
import TitlesTable from './TitlesTable';

import {getFilters} from '../../../store/selectors/titles';

const Titles: React.FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);
  const {appUuid} = useParams<{ appUuid: string }>();

  const onReload = () => {
    dispatch(getTitlesData(appUuid));
  };

  React.useEffect(() => {
    dispatch(getTitlesData(appUuid));

    return function () {
      dispatch(_cleanUp());
    };
  }, [dispatch, appUuid, filters]);

  return (
    <>
      <FilterSection onReload={onReload} />
      <TitlesTable />
    </>
  );
};

export default Titles;
