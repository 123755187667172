import React, { useState } from 'react';
import {useDispatch} from 'react-redux';
import {Button, Popover, Typography, Spin, Empty} from 'antd';
import {Users} from '../../../../types/users';
import {urlHelper} from '../../../../utils/urlHelper';
import {AppRoutes} from '../../../../constants/appRoutes';
import {getAppsData} from '../../../../store/actions/users/services';

const { Link } = Typography;

type AppsType = {
  uuid: string,
  title: string,
  total: number,
};

interface IProps {
  rowData: Users.Entity,
};

const ShowApps: React.FC<IProps> = ({ rowData }) => {
  const dispatch = useDispatch();
  const [appsData, setAppsData] = useState<AppsType[]>([]);
  const [loading, setLoading] = useState(true);
  const [fetched, setFetched] = useState(false);

  const getData = async () => {
    if (!fetched) {
      const res = await dispatch(getAppsData(rowData.id));
      setAppsData(res as unknown as AppsType[]);
      setLoading(false);
      setFetched(true);
    }
  };

  const content = () => {
    if (loading) {
      return (
        <Spin tip='Loading...'/>
      );
    } else if (!appsData.length) {
      return <Empty />;
    } else {
      return (
        appsData.map((app: any) => {
          return (
            <Link
              key={app.uuid}
              target='_blank'
              href={urlHelper(AppRoutes.ANALYTICS_REPORTS, {appUuid: app.uuid})}
              title={app.title}
              style={{display: 'block'}}
            >
              {app.title}
            </Link>
          );
        })
      );
    }
  };

  return (
    <Popover
      placement='top'
      content={content}
      trigger='click'
    >
      <Button onClick={getData} >
        {'Show Apps'}
      </Button>
    </Popover>
  );
};

export default ShowApps;
