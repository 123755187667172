import React from 'react';
import {Route} from 'react-router';
import {Switch} from 'react-router';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {RouteComponentProps} from 'react-router';
import {ChangePlan} from '../Components/ChangePlan';
import {urlHelper} from '../../../../../../utils/urlHelper';
import {PayAndActivate} from '../Components/PayAndActivate';
import {AppRoutes} from '../../../../../../constants/appRoutes';
import {SubscriptionInfo} from '../Components/SubscriptionInfo';
import {_scheduledPlan} from '../../../../../../store/selectors/portal';

export function SubscriptionsAndBillingRoutes(props: RouteComponentProps) {
  const scheduledPlan = useSelector(_scheduledPlan);

  return (
    <Switch>
      <Route
        exact
        path={props.match.path}
        component={SubscriptionInfo}
      />
      <Route
        exact
        path={[
          AppRoutes.CHANGE_PLAN,
          AppRoutes.CHANGE_PLAN_APP,
        ]}
        component={scheduledPlan ? RedirectTo : ChangePlan}
      />
      <Route
        exact
        path={[
          AppRoutes.PAY_AND_ACTIVATE,
          AppRoutes.PAY_AND_ACTIVATE_APP,
        ]}
        component={scheduledPlan ? RedirectTo : PayAndActivate}
      />
      <Route
        component={RedirectTo}
      />
    </Switch>
  )
}

function RedirectTo() {
  const {appUuid} = useParams<{ appUuid: string }>();
  return (
    <Redirect
      to={appUuid ? urlHelper(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP, {appUuid}) : AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS}
    />
  )
}
