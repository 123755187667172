import React from 'react';
import Paper from '@material-ui/core/Paper';
import {nFormatter} from '../../../../../../utils';
import {Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  widget: {
    width         : 190,
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'space-between',
    margin        : theme.spacing(0, 1),
    padding       : theme.spacing(1.5, 1, 1, 1),
  },
}));

export default function Widget(
  {
    icon,
    label,
    value,
    total,
  }: {
    label: string,
    value: number,
    total: number,
    icon: React.ReactNode,
  }
) {
  const classes = useStyles();

  return (
    <Paper
      square
      elevation={3}
      className={classes.widget}
    >
      <Typography
        align='center'
        component='div'
      >
        {icon}
        <Typography
          component='p'
          align='center'
        >{label}
        </Typography>
      </Typography>
      <Typography
        align='center'
        component='div'
      >
        <Typography
          component='p'
          style={{fontSize: 16, fontWeight: 'bold'}}
        >{nFormatter(value || 0, 1)} <span style={{fontSize: 12, fontWeight: 500}}>{Number(((value * 100) / (total || 1)).toFixed(0))}%</span>
        </Typography>
      </Typography>
    </Paper>
  )
}
