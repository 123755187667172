import {ApiKeys} from '../../../types/apiKeys';
import {ActionTypes} from '../../constants/apiKeys';

export const _dataOptions = (options: Partial<ApiKeys.DataOptions>) => ({
  payload: options,
  type   : ActionTypes.API_KEYS_DATA_OPTIONS,
});

export const _setData = (data: ApiKeys.Entity[]) => ({
  payload: data,
  type   : ActionTypes.API_KEYS_SET_DATA,
});

export const _upsertDataItem = (apiKey: Partial<ApiKeys.Entity>) => ({
  payload: apiKey,
  type   : ActionTypes.API_KEYS_UPSERT_DATA_ITEM,
});
