import React from 'react';
import MuiRadio from '@material-ui/core/Radio';
import {RadioProps} from '@material-ui/core/Radio';
import {withStyles} from '@material-ui/core/styles';

export const Radio = withStyles({
  root   : {
    color      : '#000000',
    '&$checked': {
      color: '#000000',
    },
  },
  checked: {},
})((props: RadioProps) => (
  <MuiRadio
    color='default'
    {...props}
  />
));