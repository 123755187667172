import React from 'react';
import {Modal, Button, Row, ModalProps } from 'antd';
import './index.less';

interface IProps extends ModalProps {
  onAcceptText: string;
  onCancelText: string;
  onAccept: (data: any) => void;
  onCancel?: () => void;
  rowData: any;
  body?: (data: any) => JSX.Element;
};

const DeleteDialog: React.FC<IProps> = ({ onCancel, onAccept, rowData, onCancelText, onAcceptText, body, ...props}) => {

  const handleAccept = () => {
    if (onAccept) {
      onAccept(rowData);
    }
    onCancel();
  };

  return (
    <Modal
      onCancel={onCancel}
      bodyStyle={{textAlign: 'center'}}
      wrapClassName='Delete_dialog'
      {...props}
      footer={(
        <Row justify='center'>
          <Button
            children={onCancelText}
            onClick={onCancel}
            type='primary'
            ghost
          />
          <Button
            danger
            type='primary'
            children={onAcceptText}
            onClick={handleAccept}
            style={{marginLeft: 70}}
          />
        </Row>
      )}
    >
      {body ? body(rowData) : 'Are you sure you want to delete?' }
    </Modal>
  );
};

export default DeleteDialog;
