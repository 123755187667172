import {notification} from 'antd';
import {_fetchPortal} from './fetchPortal';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';

export const _updateBillingAddress = (
  {
    formValues,
  }: {
    formValues: Record<string, any>;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  const {
    id,
    ...restFormValues
  } = formValues;

  const {
    hasError,
    body: {
      data,
      message,
    }
  } = await api.post(urlHelper(ApiRoutes.UPDATE_PAYMENT_METHOD, {
    stripePaymentMethodId: id
  }), {
    billing_details: {
      address: {
        ...restFormValues,
        city       : restFormValues?.city?.trim(),
        line1      : restFormValues?.line1?.trim(),
        line2      : restFormValues?.line2?.trim(),
        postal_code: restFormValues?.postal_code?.trim(),
        state      : restFormValues.state?.value || restFormValues.state?.trim(),
        country    : restFormValues.country?.value || restFormValues.country?.trim(),
      }
    },
  });

  if (hasError) {
    if (Object.keys(data || {}).length) {

    } else {
      notification.error({message});
    }
  } else {
    await dispatch(_fetchPortal());

    notification.success({message: data.message});
  }
};