import React from 'react';
import {Empty} from 'antd';
import {Table} from 'antd';
import {Button} from 'antd';
import {Breadcrumb} from 'antd';
import {useIndex} from './useIndex';
import {aclKeys} from '../../../../../constants/aclKeys';
import {TableHeader} from '../../components/TableHeader';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {Content} from '../../../../../components/antd/Content';
import {FiniteStates} from '../../../../../constants/finiteStates';
import {Navigation} from '../../../../../components/antd/Navigation';

export function Index() {
  const {
    total,
    columns,
    current,
    onCreate,
    onReload,
    segments,
    pageSize,
    canCreate,
    tableOnChange,
    segmentsState,
  } = useIndex();

  return (
    <>
      <Navigation
        extra={[
          canCreate(aclKeys.SEGMENTS) && <Button
            type='primary'
            key='newSegment'
            onClick={onCreate}
            icon={<PlusOutlined/>}
          >New Segment
          </Button>,
          <Button
            type='default'
            key='reloadData'
            onClick={onReload}
            icon={<ReloadOutlined/>}
            disabled={segmentsState === FiniteStates.LOADING}
          />,
        ]}
        title='Segments'
        breadcrumbItems={[
          <Breadcrumb.Item
            key='app.segments'
          >Segments
          </Breadcrumb.Item>
        ]}
      />
      <Content>
        <TableHeader/>
        <Table
          rowKey='id'
          locale={{
            emptyText: (
              <Empty
                description='No segments found.'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )
          }}
          columns={columns}
          dataSource={segments}
          onChange={tableOnChange}
          loading={segmentsState === FiniteStates.LOADING}
          scroll={{x: true, scrollToFirstRowOnChange: true}}
          pagination={{showQuickJumper: true, total, current, pageSize}}
        />
      </Content>
    </>
  )
}