import {getState} from '../index';
import {createSelector} from 'reselect';

export const getMessages = createSelector(getState, (state) => {
  return state.builder.messages;
});

export const getMessagesData = createSelector(getMessages, (messages) => {
  return messages.dataOptions.entities;
});

export const getFetchState = createSelector(getMessages, (messages) => {
  return messages.dataOptions.fetched;
});

export const getTotal= createSelector(getMessages, (messages) => {
  return messages.dataOptions.total;
});

export const getFilters = createSelector(getMessages, (messages) => {
  return messages.filters;
});
