import React from 'react';
import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {useTitle} from '../../../../../hooks/useTitle';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {_activationAppSumoEmail} from '../../../../../store/selectors/application';
import {_appSumoRegister} from '../../../../../store/actions/application/appSumoRegister';

export interface IRegisterForm {
  plan: string;
  email: string;
  password: string;
  message: string;
  last_name: string;
  first_name: string;
}

export function useRegister() {
  const dispatch = useDispatch();
  const {search} = useLocation();
  useTitle('Sign up | SmartPush');
  const {token} = useParams<{ token: string }>();
  const email = useSelector(_activationAppSumoEmail);

  const defaultValues: Partial<IRegisterForm> = {
    message: '',
    email  : email,
  }

  const searchString = search.replace('?', '');

  const {
    setError,
    handleSubmit,
    ...formRest
  } = useForm<IRegisterForm>({
    mode         : 'onSubmit',
    defaultValues: defaultValues,
  });
  const {executeRecaptcha} = useGoogleReCaptcha();

  const submit = React.useCallback((formValues: IRegisterForm) => {
    return dispatch(_appSumoRegister({
      ...formValues,
      searchString,
      token,
      last_name : formValues.last_name?.trim(),
      first_name: formValues.first_name?.trim(),
    }, {
      setError,
      executeRecaptcha,
    }));
  }, [
    token,
    setError,
    dispatch,
    searchString,
    executeRecaptcha,
  ]);

  return {
    ...formRest,
    searchString,
    onSubmit: handleSubmit(submit),
  }
}
