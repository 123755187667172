import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import {Table, TablePaginationConfig} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {FilterValue} from 'antd/lib/table/interface';
import {SortTypes} from '../../../constants/sortTypes';
import {_getUsersData, _getTotal, _getFetchState, _getFilters} from '../../../store/selectors/users';
import {_updateFilters} from'../../../store/actions/users/actions';
import ActionCell from './Table/ActionCell';
import ShowApps from './Table/ShowApps';
// eslint-disable-next-line
import DATE_COLUMNS from '../../../constants/dateColumns';

const UsersTable: React.FC = () => {
  const dispatch = useDispatch();
  const usersData = useSelector(_getUsersData);
  const fetched = useSelector(_getFetchState);
  const total = useSelector(_getTotal);
  const filters = useSelector(_getFilters);

  const dateFormat = 'YYYY-MM-DD hh:mm:ss A';
  // TODO
  // const dateFormat = (DATE_COLUMNS.find(dC => dC.column === 'wait_until') || {format: 'YYYY-MM-DD hh:mm:ss A'}).format;

  const columns: ColumnsType<any> = React.useMemo(() => {
    return [
      {
        key      : 'first_name',
        title    : 'First Name',
        dataIndex: 'first_name',
        width    : 200,
        sorter   : true,
        ellipsis : true,
      },
      {
        key      : 'last_name',
        title    : 'Last Name',
        dataIndex: 'last_name',
        width    : 200,
        sorter   : true,
        ellipsis : true,
      },
      {
        key      : 'email',
        title    : 'Email',
        dataIndex: 'email',
        width    : '150px',
        sorter   : true,
        ellipsis : true,
      },
      {
        key      : 'apps',
        title    : 'Apps',
        render   : (rowData) => <ShowApps rowData={rowData}/>,
      },
      {
        key      : 'status',
        title    : 'Status',
        dataIndex: 'enabled',
        render   : (enabled) => {
          return (
            <div className={`status ${enabled ? 'enabled' : 'disabled'}`}>
              <span className='circle'></span>
              <span>{enabled ? 'Enabled' : 'Disabled'}</span>
            </div>
          );
        },
        width    : 200,
        sorter   : true,
      },
      {
        key      : 'plan',
        title    : 'Plan Name',
        dataIndex: 'plan',
        width    : 200,
        sorter   : true,
        ellipsis : true,
      },
      {
        key      : 'plan_template_name',
        title    : 'Plan Template',
        dataIndex: 'plan_template_name',
        width    : 200,
        sorter   : true,
        ellipsis : true,
      },
      {
        key      : 'billing_cycle',
        title    : 'Payment Cycle',
        dataIndex: 'billing_cycle',
        width    : 200,
        sorter   : true,
        ellipsis : true,
      },
      {
        key      : 'cycle_count',
        title    : 'Number of Periods',
        dataIndex: 'cycle_count',
        width    : 200,
        sorter   : true,
        ellipsis : true,
      },
      {
        key      : 'plan_started_at',
        title    : 'Plan Start Date',
        dataIndex: 'plan_started_at',
        ellipsis : true,
        sorter   : true,
        render   : (startDate) => moment(startDate).format(dateFormat),
      },
      {
        key      : 'expires_at',
        title    : 'Plan Expiration Date',
        dataIndex: 'expires_at',
        ellipsis : true,
        sorter   : true,
        render   : (expireDate) => moment(expireDate).format(dateFormat),
      },
      {
        key      : 'created_at',
        title    : 'Created at',
        dataIndex: 'created_at',
        defaultSortOrder: 'descend',
        ellipsis : true,
        sorter   : true,
        render   : (expireDate) => moment(expireDate).format(dateFormat),
      },
      {
        key      : 'actions',
        title    : 'Actions',
        align    : 'center',
        fixed    : 'right',
        width    : 60,
        render   : (row) => <ActionCell row={row}/>,
      },
    ]
  }, []);

  const handleTableChange = (p: TablePaginationConfig, f: Record<string, FilterValue>, sorter: any) => {
    const order: 'descend' | 'ascend' = sorter.order;
    dispatch(_updateFilters({ sortName: sorter.field, sortOrder: SortTypes[order] }));
  };

  const onPageChanges = (pageNumber: number, pageSize: number) => {
    dispatch(_updateFilters({ pageNumber, pageSize }));
  };

  return (
    <Table
      rowKey='id'
      columns={columns}
      scroll={{x: true, scrollToFirstRowOnChange: true}}
      dataSource={usersData}
      loading={!fetched}
      onChange={handleTableChange}
      pagination={{
        current: filters.pageNumber,
        onChange: (p, s) => onPageChanges(p, s),
        total,
        position: ['bottomCenter'],
        showSizeChanger: true,
      }}
    />
  )
};

export default UsersTable;
