import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Breadcrumb} from 'antd';
import {Content} from '../../../components/antd/Content';
import {Navigation} from '../../../components/antd/Navigation';
import {getSubscriptionPlans, getFeatureGroups, getUsersData} from '../../../store/actions/users/services';
import {_cleanUp} from '../../../store/actions/users/actions';
import {_getFilters} from '../../../store/selectors/users';
import FilterSection from './FilterSection';
import UsersTable from './UserTable';
import '../index.less';

const User: React.FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector(_getFilters);

  React.useEffect(() => {
    dispatch(getSubscriptionPlans());
    dispatch(getFeatureGroups());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getUsersData());
  }, [dispatch, filters.pageNumber, filters.pageSize, filters.sortName, filters.sortOrder]);

  React.useEffect(() => {
    return function () {
      dispatch(_cleanUp());
    };
  }, [dispatch]);

  return (
    <>
      <Navigation
        title='Users'
        breadcrumbItems={[
          <Breadcrumb.Item key='app.users'>
            Users
          </Breadcrumb.Item>
        ]}
      />
      <Content className='content'>
        <FilterSection />
        <UsersTable />
      </Content>
    </>
  )
};

export default User;
