import React from 'react';
import Box from '@material-ui/core/Box';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import {TabProps} from '@material-ui/core/Tab';
import {Theme} from '@material-ui/core/styles';
import {BoxProps} from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography';
import {TypographyProps} from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import {lighten} from '@material-ui/core/styles/colorManipulator';

export const Tabs: any = withStyles<any, any, any>({
  root     : {
    marginBottom              : -2,
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid #fafafa',
    }
  },
  indicator: {
    display: 'none',
  },
})(MuiTabs);

export const Tab: any = withStyles<any, any, TabProps>((theme: Theme) => createStyles({
  root    : {
    marginRight         : 2,
    borderTopLeftRadius : 8,
    borderTopRightRadius: 8,
    marginBottom        : -1,
    minWidth            : 100,
    textTransform       : 'unset',
    borderBlockColor    : '#ffffff',
    backgroundColor     : 'transparent',
    borderTopColor      : '#f0f0f0  !important',
    border              : '1px solid #f0f0f0',
    transition          : 'all .1s cubic-bezier(.645,.045,.355,1)',
    '&:hover'           : {
      opacity: 1,
      color  : lighten('#096dd9', .2),
    },
    '&$selected'        : {
      color            : '#096dd9',
      borderBottomColor: '#ffffff',
      fontWeight       : theme.typography.fontWeightMedium,
    },
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38) !important',
  },
  selected: {
    backgroundColor  : '#ffffff',
    borderBottomColor: '#ffffff',
  },
}))((props: TabProps) => <MuiTab disableRipple {...props} />);

const localStyle: React.CSSProperties = {
  padding        : 24,
  marginBottom   : 32,
  backgroundColor: '#ffffff',
  border         : '1px solid #f0f0f0',
}

export function TabPanel(
  {
    value,
    style,
    index,
    boxProps,
    children,
    keepMounted,
    typographyProps,
    ...other
  }: {
    index: any;
    value: any;
    boxProps?: BoxProps;
    keepMounted?: boolean;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    typographyProps?: TypographyProps;
  }
) {
  return (
    <div
      role='tabpanel'
      style={{...localStyle, ...style}}
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {(!keepMounted && (value === index)) && (
        <Box
          {...boxProps}
        >
          <Typography
            component='div'
            {...typographyProps as any}
          >{children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
