export function convertTimezone(value: number) {
  const nValue = Number(value);

  if (nValue) {
    let hours = (nValue / 60);
    if (hours < 0) {
      hours = hours * -1;
    }
    const rHours = Math.floor(hours);
    const minutes = (hours - rHours) * 60;
    const rMinutes = Math.round(minutes);

    if (nValue < 0) {
      return `UTC-${rHours}${!!rMinutes ? `:${rMinutes}` : ''}`;
    } else {
      return `UTC+${rHours}${!!rMinutes ? `:${rMinutes}` : ''}`;
    }
  } else if (nValue === 0) {
    return 'UTC';
  } else {
    return '-';
  }
}