import {IObject} from '../../../../types';
import {ActionTypes} from '../../../constants/gallery';
import {DEFAULT_PAGE_SIZES} from '../../../../constants';

const InitialState: IObject = {
  pageNumber: 1,
  pageSize  : 10,
  sortOrder : 'desc',
  sortName  : 'updated_at',
  pageSizes : DEFAULT_PAGE_SIZES,
};

export default (state = InitialState, action: any) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActionTypes.WEB_NOTIFY_GALLERY_TABLE_OPTIONS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};
