import clsx from 'clsx';
import React from 'react';
import {Pagination} from '../Pagination';
import Hidden from '@material-ui/core/Hidden';
import {Theme} from '@material-ui/core/styles';
import {PageSizeSelector} from '../PageSizeSelector';
import {PagingPanel} from '@devexpress/dx-react-grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  pager: {
    flex          : 'none',
    display       : 'flex',
    alignItems    : 'center',
    overflow      : 'hidden',
    justifyContent: 'center',
    padding       : theme.spacing(1.5, 0),
  },
}));

const Pager = (
  {
    pageSize,
    className,
    pageSizes,
    totalCount,
    getMessage,
    totalPages,
    currentPage,
    hasMorePages,
    disableInput,
    onPageSizeChange,
    simplePagination,
    onCurrentPageChange,
    ...restProps
  }: any
) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.pager, className)}
      {...restProps}
    >
      <Hidden
        xsDown
      >
        <PageSizeSelector
          pageSize={pageSize}
          pageSizes={pageSizes}
          getMessage={getMessage}
          onPageSizeChange={onPageSizeChange}
        />
      </Hidden>
      <Pagination
        pageSize={pageSize}
        totalPages={totalPages}
        totalCount={totalCount}
        getMessage={getMessage}
        currentPage={currentPage}
        hasMorePages={hasMorePages}
        disableInput={disableInput}
        simplePagination={simplePagination}
        onCurrentPageChange={(page: any) => onCurrentPageChange(page)}
      />
    </div>
  )
};

export default function CustomPager(props: PagingPanel.ContainerProps & { simplePagination?: boolean, hasMorePages?: boolean, disableInput?: boolean }) {
  return (
    <Pager
      {...props}
    />
  );
}
