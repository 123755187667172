import React from 'react';
import {Button} from '../Button';
import {TextField} from '../Input';
import {validateUrl} from '../../utils';
import {IconButton} from '../IconButton';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import ImageIcon from '@material-ui/icons/Image';
import PublishIcon from '@material-ui/icons/Publish';
import Typography from '@material-ui/core/Typography';
import {PhotoGalleryDialog} from '../PhotoGalleryDialog';
import {TextFieldProps} from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

export function UploadInput(
  {
    value,
    change,
    loading,
    onChange,
    InputProps,
    inputRef: ref,
    disablePreview,
    ...rest
  }: TextFieldProps & {
    loading?: boolean;
    disableMargin?: boolean;
    disablePreview?: boolean;
    change: (val: string | undefined | unknown | number) => string | undefined | unknown | number;
    color?: 'primary' | 'default' | 'success' | 'error';
  }
) {
  const inputRef = React.createRef<any>();
  const [url, setUrl] = React.useState<string | any>(void 0);
  const [anchorIcon, setAnchorIcon] = React.useState<undefined | HTMLElement>(void 0);
  const [inputValue, setInputValue] = React.useState<string | undefined | unknown>('');

  React.useEffect(() => {
    if (validateUrl(value)) {
      setUrl(value);
    } else {
      setUrl(void 0);
    }
    setInputValue(value || '');
  }, [
    value
  ]);

  const handleOnChange = React.useCallback((e) => {
    if (validateUrl(e.target.value)) {
      setUrl(e.target.value);
    } else {
      setUrl(void 0);
    }
    setInputValue(e.target.value);
    change(e.target.value);
  }, [
    change,
  ]);

  const handleOnClose = React.useCallback(() => {
    setAnchorIcon(void 0);
    inputRef.current.focus();
  }, [
    inputRef,
  ]);

  const handleOnSet = React.useCallback((selected) => {
    setInputValue(selected);
    if (validateUrl(selected)) {
      setUrl(selected);
    } else {
      setUrl(void 0);
    }
    change(selected);
  }, [
    change,
  ]);
  const aspectRatio = (rest.name === 'image_url') ? 2 : 1;

  return (
    <>
      <TextField
        loading={loading}
        value={inputValue}
        InputProps={{
          endAdornment: (
            <EndAdornment
              url={url}
              disabled={rest.disabled}
              aspectRatio={aspectRatio}
              setAnchorIcon={setAnchorIcon}
              disablePreview={disablePreview}
            />
          ),
        }}
        inputRef={inputRef}
        onChange={handleOnChange}
        {...rest}
      />
      {anchorIcon && (
        <PhotoGalleryDialog
          selected={inputValue}
          onClose={handleOnClose}
          setSelected={handleOnSet}
          aspectRatio={aspectRatio}
          open={Boolean(anchorIcon)}
        />
      )}
    </>
  )
}

export function UploadButton(
  {
    label,
    value,
    change,
    loading,
    onChange,
    disabled,
    InputProps,
    helperText,
    errorMessage,
    disablePreview,
    disableMargin,
    ...rest
  }: TextFieldProps & {
    loading?: boolean;
    errorMessage?: string;
    disableMargin?: boolean;
    disablePreview?: boolean;
    change: (val: string | undefined | unknown | number) => string | undefined | unknown | number;
    color?: 'primary' | 'default' | 'success' | 'error';
  }
) {
  const [, setUrl] = React.useState<string | any>(void 0);
  const [anchorIcon, setAnchorIcon] = React.useState<undefined | HTMLElement>(void 0);
  const [inputValue, setInputValue] = React.useState<string | undefined | unknown>('');

  React.useEffect(() => {
    if (validateUrl(value)) {
      setUrl(value);
    } else {
      setUrl(void 0);
    }
    setInputValue(value || '');
  }, [
    value
  ]);

  const handleOnClose = React.useCallback(() => {
    setAnchorIcon(void 0);
  }, []);

  const handleOnSet = React.useCallback((selected) => {
    setInputValue(selected);
    if (validateUrl(selected)) {
      setUrl(selected);
    } else {
      setUrl(void 0);
    }
    change(selected);
  }, [
    change,
  ]);
  const aspectRatio = (rest.name === 'image_url') ? 2 : 1;

  const handleOnClick = React.useCallback((e) => {
    setAnchorIcon(e.currentTarget)
  }, [
    setAnchorIcon,
  ]);

  return (
    <div
      style={{
        marginBottom : disableMargin ? 0 : 24,
        display      : 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        color='primary'
        style={{
          margin: '0px 8px 4px 0px',
          color : disabled ? 'rgba(0, 0, 0, 0.38)' : void 0
        }}
      >
        {label}
        {!!helperText && (
          <span
            style={{
              marginLeft: 8,
              fontSize  : '12px',
              color     : disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.54)'
            }}
          >{helperText}
          </span>
        )}
      </Typography>
      <Typography
        noWrap
        component='div'
        style={{
          display   : 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          size='small'
          color='primary'
          variant='outlined'
          disabled={disabled}
          style={{width: 100}}
          onClick={handleOnClick}
        >{!!value ? 'Replace' : 'Select'}
        </Button>
        {!!value && (
          <>
            <Tooltip
              placement='top-start'
              title={
                <Avatar
                  style={{
                    borderRadius: 0,
                    height      : 120,
                    width       : aspectRatio * 120,
                  }}
                  src={value as any}
                />
              }
              disableHoverListener={disabled}
              disableFocusListener={disabled}
              disableTouchListener={disabled}
            >
              <Typography
                noWrap
                style={{
                  marginLeft: 8,
                  maxWidth  : 250,
                  fontSize  : '12px',
                  fontWeight: 'bold',
                  color     : disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.54)',
                }}
              >{value}
              </Typography>
            </Tooltip>
            <Tooltip
              title='Remove'
            >
              <div>
                <IconButton
                  size='small'
                  disabled={disabled}
                  onClick={() => {
                    setUrl(void 0);
                    change(void 0);
                    setInputValue(void 0);
                  }}
                >
                  <RemoveCircleIcon
                    style={{fontSize: '16px'}}
                    color={disabled ? 'disabled' : 'error'}
                  />
                </IconButton>
              </div>
            </Tooltip>
          </>
        )}
      </Typography>
      <Typography
        style={{
          color   : '#f44336',
          fontSize: '12px',
          margin  : '8px 8px 0px',
        }}
      >{errorMessage}
      </Typography>
      {anchorIcon && (
        <PhotoGalleryDialog
          selected={inputValue}
          onClose={handleOnClose}
          setSelected={handleOnSet}
          aspectRatio={aspectRatio}
          open={Boolean(anchorIcon)}
        />
      )}
    </div>
  )
}

function EndAdornment(
  {
    url,
    disabled,
    aspectRatio,
    setAnchorIcon,
    disablePreview,
  }: any
) {
  const handleOnClick = React.useCallback((e) => {
    setAnchorIcon(e.currentTarget)
  }, [
    setAnchorIcon,
  ]);

  return (
    <InputAdornment
      position='end'
    >
      <IconButton
        size='small'
        name='icon_url'
        color='transparent'
        disabled={disabled}
        onClick={handleOnClick}
      >
        <PublishIcon
          fontSize='small'
        />
      </IconButton>
      {(!disablePreview && url) && (
        <Tooltip
          placement='left'
          title={
            <Avatar
              src={url}
              style={{
                borderRadius: 0,
                height      : 120,
                width       : aspectRatio * 120,
              }}
            />
          }
          disableHoverListener={disabled}
          disableFocusListener={disabled}
          disableTouchListener={disabled}
        >
          <Typography
            component='div'
          >
            <IconButton
              size='small'
              name='icon_url'
              color='transparent'
              disabled={disabled}
            >
              <ImageIcon
                fontSize='small'
              />
            </IconButton>
          </Typography>
        </Tooltip>
      )}
    </InputAdornment>
  )
}
