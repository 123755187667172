import produce from 'immer';
import {Locales} from '../../../types/locales';
import {ActionTypes} from '../../constants/locales';
import {FiniteStates} from '../../../constants/finiteStates';
import {ActionTypes as CoreActionTypes} from '../../constants/application';

const InitialState: Locales.Reducer = {
  status   : FiniteStates.IDLE,
};

export const locales = produce((draft, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.LOCALES_SET_STATUS: {
      draft.status = payload;

      return void 0;
    }
    case ActionTypes.LOCALES_SET_LANGUAGES: {
      draft.languages = payload;

      return void 0;
    }
    case ActionTypes.LOCALES_SET_COUNTRIES: {
      draft.countries = payload;

      return void 0;
    }
    case CoreActionTypes.LOGOUT: {
      return {
        ...InitialState
      }
    }
    default:
      return void 0;
  }
}, InitialState);
