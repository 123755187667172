import {Tabs} from 'antd';
import React from 'react';
import {useParams} from 'react-router';
import {SettingRoutes} from './Routes';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {aclKeys} from '../../../constants/aclKeys';
import {urlHelper} from '../../../utils/urlHelper';
import {AppRoutes} from '../../../constants/appRoutes';
import {Content} from '../../../components/antd/Content';
import {useSmartPush} from '../../../hooks/useSmartPush';
import {Navigation} from '../../../components/antd/Navigation';
import {_currentAppTitle} from '../../../store/selectors/apps';
import {dataOptionsAction} from '../../../store/actions/gallery';

export function Settings() {
  const {push} = useHistory();
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const {
    appUuid,
  } = useParams<{ appUuid: string }>();
  const {
    user: {
      canRead,
      hasVisibility,
    },
  } = useSmartPush();

  const appTitle = useSelector(_currentAppTitle);
  const hasWelcomeMessageAccess = canRead(aclKeys.WELCOME_MESSAGES) && hasVisibility(aclKeys.WELCOME_MESSAGES);

  React.useEffect(() => {
    dispatch(dataOptionsAction({
      limits_notice_is_open: true,
    }))
  }, [
    dispatch,
  ]);

  return (
    <>
      <Navigation
        title='Settings'
        subTitle={appTitle}
        hideDefaultBreadcrumbItem
        footer={
          <Tabs
            size='large'
            animated={false}
            defaultActiveKey={pathname}
            onChange={(activeKey) => push(activeKey)}
          >
            <Tabs.TabPane
              tab='General'
              key={urlHelper(AppRoutes.SETTINGS, {appUuid})}
            />
            <Tabs.TabPane
              tab='Integrations'
              key={urlHelper(AppRoutes.SETTINGS_INTEGRATIONS, {appUuid})}
            />
            <Tabs.TabPane
              tab='Prompt Configs'
              key={urlHelper(AppRoutes.SETTINGS_PROMPT_CONFIGS, {appUuid})}
            />
            {hasWelcomeMessageAccess && (
              <Tabs.TabPane
                tab='Welcome Message'
                key={urlHelper(AppRoutes.SETTINGS_WELCOME_MESSAGE, {appUuid})}
              />
            )}
          </Tabs>
        }
      />
      <Content>
        <SettingRoutes
          hasWelcomeMessageAccess={hasWelcomeMessageAccess}
        />
      </Content>
    </>
  )
}
