import React from 'react';
import {Button} from 'antd';
import {Breadcrumb} from 'antd';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Upsert} from '../../components/Upsert';
import {FEATURE} from '../../../../../constants/users';
import {urlHelper} from '../../../../../utils/urlHelper';
import {ACCESS_TYPES} from '../../../../../constants/users';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {Content} from '../../../../../components/antd/Content';
import {Link} from '../../../../../components/antd/Typography';
import {Navigation} from '../../../../../components/antd/Navigation';
import {getTemplate} from '../../../../../store/selectors/templates';
import {_userAcl} from '../../../../../store/selectors/application/acl';
import {getDataOptions} from '../../../../../store/selectors/templates';
import {createOrUpdateAction} from '../../../../../store/actions/templates';

export function Clone() {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userAcl = useSelector(_userAcl);
  const template = useSelector(getTemplate);
  const {appUuid} = useParams<{ appUuid: string }>();
  const {isCreatingOrUpdating} = useSelector(getDataOptions);

  const hasLocales = React.useMemo(() => {
    return (Object.keys(template?.messages || {}).length > 1)
  }, [
    template,
  ]);

  const hasLocalesAccess = React.useMemo(() => {
    return (
      !!userAcl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE]
    )
  }, [
    userAcl,
  ]);

  const handleCreateOrUpdate = React.useCallback(() => {
    if (!hasLocalesAccess && hasLocales) {
      return updateDialogState({
        open           : true,
        buttonText     : t('buttons.save'),
        bodyTitle      : 'This Template has additional Languages/Countries added for it.',
        bodyDescription: 'You do not have access to this feature. So if you save, the new template will have no additional Languages/Countries selected.',
        agreeAction    : () => {
          updateDialogState({open: false});
          dispatch(createOrUpdateAction(appUuid, {
            redirect: () => {
              history.push(`${urlHelper(AppRoutes.TEMPLATES, {appUuid})}`);
            }
          }));
        }
      });
    }

    dispatch(createOrUpdateAction(appUuid, {
      redirect: () => {
        history.push(`${urlHelper(AppRoutes.TEMPLATES, {appUuid})}`);
      }
    }));
  }, [
    t,
    history,
    appUuid,
    dispatch,
    hasLocales,
    hasLocalesAccess,
  ]);

  const [dialogState, updateDialogState] = React.useState<{
    open: boolean;
    timer?: number;
    bodyTitle?: string;
    buttonText?: string;
    bodyDescription?: string;
    agreeAction?: () => void;
  }>({
    open: false,
  });

  const redirectToTemplates = function () {
    history.push(urlHelper(AppRoutes.TEMPLATES, {appUuid}));
  }

  return (
    <>
      <Navigation
        extra={[
          <Button
            key='app.cancelAction'
            onClick={redirectToTemplates}
            disabled={isCreatingOrUpdating}
          >Cancel
          </Button>,
          <Button
            type='primary'
            htmlType='submit'
            key='app.updateAction'
            icon={<CheckOutlined/>}
            loading={isCreatingOrUpdating}
            onClick={handleCreateOrUpdate}
          >Save
          </Button>,
        ]}
        title={template?.title}
        breadcrumbItems={[
          <Breadcrumb.Item
            key='app.segments'
          >
            <Link
              ellipsis
              type='secondary'
              onClick={redirectToTemplates}
            >Templates
            </Link>
          </Breadcrumb.Item>,
          <Breadcrumb.Item
            key='app.editTemplate'
          >{template?.title}
          </Breadcrumb.Item>
        ]}
      />
      <Content>
        <Upsert
          cloneFromCampaign
          dialogState={dialogState}
          updateDialogState={updateDialogState}
        />
      </Content>
    </>
  )
}
