import {Form} from 'antd';
import React from 'react';
import {Button} from 'antd';
import {ButtonProps} from 'antd';
import Icon from '@ant-design/icons';
import {useGoogleAuth} from './useGoogleAuth';
import {ReactComponent as GoogleLogo} from '../../../../images/antd/google.svg';

export function GoogleAuth(
  {
    action,
    buttonProps,
    buttonLabel,
    reCaptchaAction,
  }: {
    reCaptchaAction: string;
    buttonLabel: React.ReactNode;
    action: 'gmail_login' | 'gmail_registration',
    buttonProps?: ButtonProps & { 'data-qa': string };
  }
) {
  const {
    onSubmit,
    formState: {
      isSubmitting,
    },
  } = useGoogleAuth({reCaptchaAction, action});

  return (
    <form
      className='form'
      onSubmit={onSubmit}
    >
      <Form
        component='div'
        className='form'
        layout='vertical'
      >
        <Button
          {...buttonProps}
          block
          size='large'
          type='default'
          htmlType='submit'
          loading={isSubmitting}
          icon={<Icon component={GoogleLogo}/>}
        >{buttonLabel}
        </Button>
      </Form>
    </form>
  )
}
