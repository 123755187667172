import React from 'react';
import {useGeneral} from './useGeneral';
import Grid from '@material-ui/core/Grid';
import {Theme} from '@material-ui/core/styles';
import {Spinner} from '../../../../../components/antd/Spinner';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {GeneralForm} from '../../../../../components/Settings/General';

const useStyles = makeStyles((theme: Theme) => ({
  settingsForm: {
    marginTop: theme.spacing(3),
  },
  gridItem    : {
    display       : 'flex',
    justifyContent: 'center',
  },
}));

export function General() {
  const classes = useStyles();
  const {
    user,
    error,
    cancel,
    resend,
    loading,
    mainData,
    isLoading,
    updateUser,
    isCanceling,
    isResending,
    resend1,
    resetGeneralForm,
    handleGeneralForm,
    generalSettingsForm,
  } = useGeneral();

  if (loading) {
    return (
      <Spinner/>
    )
  }

  return (
    <Grid
      container
    >
      <Grid
        item
        xs={12}
        className={classes.gridItem}
      >
        <GeneralForm
          error={error}
          resend={resend}
          cancel={cancel}
          resend1={resend1}
          loading={isLoading}
          dataList={mainData}
          submitForm={updateUser}
          isResending={isResending}
          isCanceling={isCanceling}
          resetForm={resetGeneralForm}
          formData={generalSettingsForm}
          handleForm={handleGeneralForm}
          className={classes.settingsForm}
          pendingEmail={user.pending_email}
        />
      </Grid>
    </Grid>
  )
}
