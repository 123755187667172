import React from 'react';
import {Alert} from 'antd';
import {Button} from 'antd';
import {Breadcrumb} from 'antd';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import {Card} from '../../../../../../components/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '../../../../../../components/Dialog';
import SendOutlined from '@ant-design/icons/SendOutlined';
import {FEATURE} from '../../../../../../constants/users';
import {urlHelper} from '../../../../../../utils/urlHelper';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {ACCESS_TYPES} from '../../../../../../constants/users';
import {AppRoutes} from '../../../../../../constants/appRoutes';
import {Spinner} from '../../../../../../components/antd/Spinner';
import {Content} from '../../../../../../components/antd/Content';
import {Permissions} from '../../../../../../constants/permission';
import {_currentApp} from '../../../../../../store/selectors/apps';
import {getAction} from '../../../../../../store/actions/campaigns';
import {sendAction} from '../../../../../../store/actions/campaigns';
import {FEATURE_GROUP} from '../../../../../../constants/permission';
import {getErrors} from '../../../../../../store/selectors/campaigns';
import {getEntity} from '../../../../../../store/selectors/campaigns';
import {Navigation} from '../../../../../../components/antd/Navigation';
import {getEntityLang} from '../../../../../../store/selectors/campaigns';
import {_userAcl} from '../../../../../../store/selectors/application/acl';
import {getDataOptions} from '../../../../../../store/selectors/campaigns';
import {getCommonEntity} from '../../../../../../store/selectors/campaigns';
import {dataOptionsAction} from '../../../../../../store/actions/campaigns';
import {removeErrorAction} from '../../../../../../store/actions/campaigns';
import {toggleErrorsAction} from '../../../../../../store/actions/campaigns';
import {toggleEntityAction} from '../../../../../../store/actions/campaigns';
import {createOrUpdateAction} from '../../../../../../store/actions/campaigns';
import {updateEntityLangAction} from '../../../../../../store/actions/campaigns';
import {getCampaignDefaultLang} from '../../../../../../store/selectors/campaigns';
import {_hasTimezonesAccess} from '../../../../../../store/selectors/application/acl';
import MultiTabsWrapper from '../../../../../../components/Campaigns/MultiTabsWrapper';
import ActionsCardWrapper from '../../../../../../components/Campaigns/ActionsCardWrapper';
import AudienceCardWrapper from '../../../../../../components/Campaigns/AudienceCardWrapper';
import CampaignScheduleCardWrapper from '../../../../../../components/Campaigns/CampaignScheduleCardWrapper';

const useStyles = makeStyles((theme: Theme) => ({
  cardContentRoot: {
    padding: 0,
  },
  cardRoot       : {
    borderRadius                  : 0,
    position                      : 'relative',
    backgroundColor               : 'transparent',
    borderColor                   : 'transparent !important',
    transition                    : theme.transitions.create(['border-color'], {
      delay: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
}));

export default function CreateOrUpdate(
  {
    isClone,
  }: {
    isClone?: boolean;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    uuid,
    appUuid,
  } = useParams<{ appUuid: string, uuid: string }>();

  const [dialogState, updateDialogState] = React.useState<{
    open: boolean;
    timer?: number;
    bodyTitle?: string;
    buttonText?: string;
    bodyDescription?: string;
    agreeAction?: () => void;
  }>({
    open: false,
  })

  const entity = useSelector(getEntity);
  const userAcl = useSelector(_userAcl);
  const errors = useSelector(getErrors);
  const lang = useSelector(getEntityLang);
  const currentApp = useSelector(_currentApp);
  const commonEntity = useSelector(getCommonEntity);
  const defaultLang = useSelector(getCampaignDefaultLang);
  const hasTimezonesAccess = useSelector(_hasTimezonesAccess);

  const hasTimezones = React.useMemo(() => {
    return entity.per_user_optimization === 'particular'
  }, [
    entity,
  ]);

  const hasSegments = React.useMemo(() => {
    return !!(
      Object.values(entity?.exclude_segments || {}).length ||
      Object.values(entity?.include_segments || {}).length
    )
  }, [
    entity,
  ]);
  const hasSegmentsAccess = React.useMemo(() => {
    return !!(
      userAcl?.segments?.[FEATURE_GROUP]?.[Permissions.READ] &&
      userAcl?.segments?.[FEATURE_GROUP]?.[Permissions.VISIBLE]
    )
  }, [
    userAcl,
  ]);

  const hasLocales = React.useMemo(() => {
    return (Object.keys(entity?.messages || {}).length > 1)
  }, [
    entity,
  ]);
  const hasLocalesAccess = React.useMemo(() => {
    return (
      !!userAcl?.apps?.[FEATURE]?.locales?.[ACCESS_TYPES.VISIBLE]
    )
  }, [
    userAcl,
  ]);

  const {
    isSending,
    isGetting,
    disableSend,
    limits_notice,
    disableSaveAsDraft,
    isCreatingOrUpdating,
  } = useSelector(getDataOptions);

  const removeError = React.useCallback((key: string) => {
    dispatch(removeErrorAction(key))
  }, [
    dispatch,
  ]);

  const memorizedDefaultLang = React.useMemo(() => {
    return defaultLang;
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    defaultLang.url,
    // eslint-disable-next-line
    defaultLang.title,
    // eslint-disable-next-line
    defaultLang.message,
    // eslint-disable-next-line
    defaultLang.icon_url,
    // eslint-disable-next-line
    defaultLang.image_url,
  ]);

  const memorizedCommonEntity = React.useMemo(() => {
    return commonEntity;
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    commonEntity.values,
    // eslint-disable-next-line
    commonEntity.locale_type,
  ]);

  const memorizedActionsEntity = React.useMemo(() => {
    return entity?.action || {}
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    entity?.action,
  ]);

  const memorizedAudienceEntity = React.useMemo(() => {
    if (String(entity.send_to).toLowerCase() !== 'all') {
      return {
        send_to         : 'particular',
        exclude_segments: entity.exclude_segments || [],
        include_segments: entity.include_segments || [],
      };
    } else {
      return {
        send_to: 'all',
      }
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    entity.send_to,
    // eslint-disable-next-line
    entity.exclude_segments,
    // eslint-disable-next-line
    entity.include_segments,
  ]);

  const memorizedScheduleEntity = React.useMemo(() => {
    return {
      delivery             : entity.delivery,
      ttl_unit             : entity.ttl_unit,
      ttl_value            : entity.ttl_value,
      deliver_at           : entity.deliver_at,
      wait_until_unit      : entity.wait_until_unit,
      wait_until_value     : entity.wait_until_value,
      wait_before_send     : entity.wait_before_send,
      per_user_optimization: entity.per_user_optimization,
      // eslint-disable-next-line
    }
  }, [
    // eslint-disable-next-line
    entity.delivery,
    // eslint-disable-next-line
    entity.deliver_at,
    // eslint-disable-next-line
    entity.ttl_unit,
    // eslint-disable-next-line
    entity.ttl_value,
    // eslint-disable-next-line
    entity.wait_until_unit,
    // eslint-disable-next-line
    entity.wait_until_value,
    // eslint-disable-next-line
    entity.per_user_optimization,
    // eslint-disable-next-line
    entity.wait_before_send,
  ]);

  const sendCampaign = React.useCallback(() => {
    if (!hasSegmentsAccess && hasSegments) {
      return updateDialogState({
        open           : true,
        buttonText     : t('buttons.send'),
        bodyTitle      : t('campaigns.segments_warning_title'),
        bodyDescription: t('campaigns.segments_warning_description_send'),
        agreeAction    : () => {
          if (!hasLocalesAccess && hasLocales) {
            return updateDialogState({
              timer          : 3,
              open           : true,
              buttonText     : t('buttons.send'),
              bodyDescription: t('campaigns.send_warning'),
              bodyTitle      : t('campaigns.warning_title'),
              agreeAction    : () => {
                if (!hasTimezonesAccess && hasTimezones) {
                  return updateDialogState({
                    timer          : 3,
                    open           : true,
                    buttonText     : t('buttons.send'),
                    bodyTitle      : 'This Campaign has Send Per Timezone turned on for it.',
                    bodyDescription: 'You do not have access to this feature. So if you send, the campaign will not have this option selected.',
                    agreeAction    : () => {
                      updateDialogState({open: false});
                      dispatch(sendAction(appUuid, {
                        redirect: () => {
                          history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                            appUuid: appUuid,
                          }));
                        },
                      }))
                    }
                  });
                }

                updateDialogState({open: false});
                dispatch(sendAction(appUuid, {
                  redirect: () => {
                    history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                      appUuid: appUuid,
                    }));
                  },
                }));
              }
            });
          }

          if (!hasTimezonesAccess && hasTimezones) {
            return updateDialogState({
              timer          : 3,
              open           : true,
              buttonText     : t('buttons.send'),
              bodyTitle      : 'This Campaign has Send Per Timezone turned on for it.',
              bodyDescription: 'You do not have access to this feature. So if you send, the campaign will not have this option selected.',
              agreeAction    : () => {
                updateDialogState({open: false});
                dispatch(sendAction(appUuid, {
                  redirect: () => {
                    history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                      appUuid: appUuid,
                    }));
                  },
                }))
              }
            });
          }

          updateDialogState({open: false});
          dispatch(sendAction(appUuid, {
            redirect: () => {
              history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                appUuid: appUuid,
              }));
            },
          }));
        }
      });
    }

    if (!hasLocalesAccess && hasLocales) {
      return updateDialogState({
        open           : true,
        buttonText     : t('buttons.send'),
        bodyDescription: t('campaigns.send_warning'),
        bodyTitle      : t('campaigns.warning_title'),
        agreeAction    : () => {
          if (!hasTimezonesAccess && hasTimezones) {
            return updateDialogState({
              timer          : 3,
              open           : true,
              buttonText     : t('buttons.send'),
              bodyTitle      : 'This Campaign has Send Per Timezone turned on for it.',
              bodyDescription: 'You do not have access to this feature. So if you send, the campaign will not have this option selected.',
              agreeAction    : () => {
                updateDialogState({open: false});
                dispatch(sendAction(appUuid, {
                  redirect: () => {
                    history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                      appUuid: appUuid,
                    }));
                  },
                }))
              }
            });
          }

          updateDialogState({open: false});
          dispatch(sendAction(appUuid, {
            redirect: () => {
              history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                appUuid: appUuid,
              }));
            },
          }));
        }
      });
    }

    if (!hasTimezonesAccess && hasTimezones) {
      return updateDialogState({
        open           : true,
        buttonText     : t('buttons.send'),
        bodyTitle      : 'This Campaign has Send Per Timezone turned on for it.',
        bodyDescription: 'You do not have access to this feature. So if you send, the campaign will not have this option selected.',
        agreeAction    : () => {
          updateDialogState({open: false});
          dispatch(sendAction(appUuid, {
            redirect: () => {
              history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                appUuid: appUuid,
              }));
            },
          }))
        }
      });
    }

    dispatch(sendAction(appUuid, {
      redirect: () => {
        history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
          appUuid: appUuid,
        }));
      },
    }));
  }, [
    t,
    appUuid,
    history,
    dispatch,
    hasLocales,
    hasSegments,
    hasTimezones,
    hasLocalesAccess,
    hasSegmentsAccess,
    hasTimezonesAccess,
  ]);

  const createOrUpdateCampaign = React.useCallback(() => {
    if (!hasSegmentsAccess && hasSegments) {
      return updateDialogState({
        open           : true,
        buttonText     : t('buttons.save'),
        bodyTitle      : t('campaigns.segments_warning_title'),
        bodyDescription: t('campaigns.segments_warning_description_edit'),
        agreeAction    : () => {
          if (!hasLocalesAccess && hasLocales) {
            return updateDialogState({
              timer          : 3,
              open           : true,
              buttonText     : t('buttons.save'),
              bodyDescription: t('campaigns.edit_warning'),
              bodyTitle      : t('campaigns.warning_title'),
              agreeAction    : () => {
                if (!hasTimezonesAccess && hasTimezones) {
                  return updateDialogState({
                    timer          : 3,
                    open           : true,
                    buttonText     : t('buttons.save'),
                    bodyTitle      : 'This Campaign has Send Per Timezone turned on for it.',
                    bodyDescription: 'You do not have access to this feature. So if you save, the campaign will not have this option selected.',
                    agreeAction    : () => {
                      updateDialogState({open: false});
                      dispatch(createOrUpdateAction(appUuid, {
                        redirect: () => {
                          history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                            appUuid: appUuid,
                          }));
                        }
                      }));
                    }
                  });
                }

                updateDialogState({open: false});
                dispatch(createOrUpdateAction(appUuid, {
                  redirect: () => {
                    history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                      appUuid: appUuid,
                    }));
                  }
                }));
              }
            });
          }

          if (!hasTimezonesAccess && hasTimezones) {
            return updateDialogState({
              timer          : 3,
              open           : true,
              buttonText     : t('buttons.save'),
              bodyTitle      : 'This Campaign has Send Per Timezone turned on for it.',
              bodyDescription: 'You do not have access to this feature. So if you save, the campaign will not have this option selected.',
              agreeAction    : () => {
                updateDialogState({open: false});
                dispatch(createOrUpdateAction(appUuid, {
                  redirect: () => {
                    history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                      appUuid: appUuid,
                    }));
                  }
                }));
              }
            });
          }

          updateDialogState({open: false});
          dispatch(createOrUpdateAction(appUuid, {
            redirect: () => {
              history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                appUuid: appUuid,
              }));
            }
          }));
        }
      });
    }

    if (!hasLocalesAccess && hasLocales) {
      return updateDialogState({
        open           : true,
        buttonText     : t('buttons.save'),
        bodyDescription: t('campaigns.edit_warning'),
        bodyTitle      : t('campaigns.warning_title'),
        agreeAction    : () => {
          if (!hasTimezonesAccess && hasTimezones) {
            return updateDialogState({
              timer          : 3,
              open           : true,
              buttonText     : t('buttons.save'),
              bodyTitle      : 'This Campaign has Send Per Timezone turned on for it.',
              bodyDescription: 'You do not have access to this feature. So if you save, the campaign will not have this option selected.',
              agreeAction    : () => {
                updateDialogState({open: false});
                dispatch(createOrUpdateAction(appUuid, {
                  redirect: () => {
                    history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                      appUuid: appUuid,
                    }));
                  }
                }));
              }
            });
          }

          updateDialogState({open: false});
          dispatch(createOrUpdateAction(appUuid, {
            redirect: () => {
              history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                appUuid: appUuid,
              }));
            }
          }));
        }
      });
    }

    if (!hasTimezonesAccess && hasTimezones) {
      return updateDialogState({
        open           : true,
        buttonText     : t('buttons.save'),
        bodyTitle      : 'This Campaign has Send Per Timezone turned on for it.',
        bodyDescription: 'You do not have access to this feature. So if you save, the campaign will not have this option selected.',
        agreeAction    : () => {
          updateDialogState({open: false});
          dispatch(createOrUpdateAction(appUuid, {
            redirect: () => {
              history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
                appUuid: appUuid,
              }));
            }
          }));
        }
      });
    }

    dispatch(createOrUpdateAction(appUuid, {
      redirect: () => {
        history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {
          appUuid: appUuid,
        }));
      }
    }));
  }, [
    t,
    appUuid,
    history,
    dispatch,
    hasLocales,
    hasSegments,
    hasTimezones,
    hasLocalesAccess,
    hasSegmentsAccess,
    hasTimezonesAccess,
  ]);

  React.useEffect(() => {
    document.title = (isClone || !uuid) ? t('campaigns.create_new_campaign_document_title') : t('campaigns.update_campaign_document_title');
  }, [
    uuid,
    t,
    isClone,
  ]);

  React.useEffect(() => {
    if (!uuid && !isClone) {
      let payload: any = {
        id: 'default',
      }

      if (currentApp.default_url) {
        payload = {
          ...payload,
          url: currentApp.default_url,
        }
      }

      if (currentApp.default_icon_url) {
        payload = {
          ...payload,
          icon_url: currentApp.default_icon_url,
        }
      }

      dispatch(updateEntityLangAction(payload));
    }
  }, [
    uuid,
    isClone,
    dispatch,
    currentApp,
  ]);

  React.useEffect(() => {
    let controller: null | AbortController = null;

    if (appUuid && uuid) {
      controller = new AbortController();
      dispatch(getAction(appUuid, uuid, {
        isClone,
        signal: controller.signal,
      }));
    }

    return function () {
      controller?.abort();

      dispatch(toggleErrorsAction(void 0));
      dispatch(toggleEntityAction(void 0));
      dispatch(dataOptionsAction({
        availableSegments: void 0,
      }));
    }
  }, [
    uuid,
    appUuid,
    isClone,
    dispatch,
  ]);

  React.useEffect(() => {
    dispatch(dataOptionsAction({
      disableSend       : void 0,
      limits_notice     : void 0,
      disableSaveAsDraft: void 0,
    }))
  }, [
    dispatch,
  ])

  if (!!isGetting) {
    return <Spinner/>
  }

  return (
    <>
      <Navigation
        title={(!uuid || !!isClone) ? 'New Push Notification' : !!entity?.messages?.default?.title &&
          <Title title={entity?.messages?.default?.title}/>}
        breadcrumbItems={[
          <Breadcrumb.Item
            key='app.pushNotifications'
          >
            <Link
              to={urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid})}
            >Push Notifications
            </Link>
          </Breadcrumb.Item>,
          <Breadcrumb.Item
            key='app.pushNotifications.createOrUpdatePushNotification'
          >{(!uuid || !!isClone) ? 'New Push Notification' : !!entity?.messages?.default?.title &&
            <Title title={entity?.messages?.default?.title}/>}
          </Breadcrumb.Item>
        ]}
        extra={[
          <Button
            key='cancel'
            type='default'
            disabled={isCreatingOrUpdating || isSending}
            onClick={() => history.push(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS, {appUuid}))}
          >Cancel
          </Button>,
          <Button
            type='default'
            key='SaveAsDraft'
            icon={<CheckOutlined/>}
            loading={isCreatingOrUpdating}
            disabled={isCreatingOrUpdating || isSending || disableSaveAsDraft}
            onClick={createOrUpdateCampaign}
          >Save as Draft
          </Button>,
          <Button
            type='primary'
            key='startSending'
            loading={isSending}
            icon={<SendOutlined/>}
            onClick={sendCampaign}
            disabled={isCreatingOrUpdating || isSending || disableSend}
          >Start Sending
          </Button>,
        ]}
      />
      {!!limits_notice && (
        <Alert
          banner
          closable
          type='error'
          message={limits_notice}
        />
      )}
      <Content>
        <Card
          variant='outlined'
          classes={{root: classes.cardRoot}}
        >
          <CardContent
            classes={{root: classes.cardContentRoot}}
          >
            {hasSegmentsAccess && (
              <AudienceCardWrapper
                errors={errors}
                removeError={removeError}
                entity={memorizedAudienceEntity}
              />
            )}
            <MultiTabsWrapper
              lang={lang}
              errors={errors}
              currentApp={currentApp}
              removeError={removeError}
              actions={memorizedActionsEntity}
              defaultLang={memorizedDefaultLang}
              commonEntity={memorizedCommonEntity}
            />
            <ActionsCardWrapper
              errors={errors}
              removeError={removeError}
              entity={memorizedActionsEntity}
              removeErrors={() => dispatch(toggleErrorsAction(void 0))}
            />
            <CampaignScheduleCardWrapper
              errors={errors}
              removeError={removeError}
              entity={memorizedScheduleEntity}
            />
          </CardContent>
          <div/>
        </Card>
        {dialogState.open && (
          <WarningDialog
            onClose={() => {
              updateDialogState({open: false})
            }}
            dialogState={dialogState}
            updateDialogState={updateDialogState}
            loading={isCreatingOrUpdating || isSending}
          />
        )}
      </Content>
    </>
  )
}
let interval: any;

function WarningDialog(
  {
    onClose,
    loading,
    dialogState,
    updateDialogState,
  }: {
    loading?: boolean,
    updateDialogState: any,
    dialogState: {
      open: boolean;
      timer?: number;
      bodyTitle?: string;
      buttonText?: string;
      agreeAction?: () => void;
      bodyDescription?: string;
    };
    onClose: () => void;
  }
) {
  const {t} = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      color='warning'
      onClose={onClose}
      open={dialogState.open}
      actions={{
        align  : 'center',
        actions: [
          {
            onClick: onClose,
            color  : 'primary',
            variant: 'outlined',
            label  : t('buttons.cancel'),
          },
          {
            loading,
            color   : 'primary',
            variant : 'contained',
            onClick : dialogState.agreeAction,
            disabled: loading || !!dialogState.timer,
            label   : !dialogState.timer ? dialogState.buttonText :
              <AgreeButton updateDialogState={updateDialogState} timer={dialogState.timer}/>,
          },
        ],
      }}
      content={{
        color          : 'warning',
        bodyIcon       : WarningIcon,
        bodyTitle      : dialogState.bodyTitle,
        bodyDescription: dialogState.bodyDescription
      }}
      dialogTitle={{
        onClose,
        color: 'warning',
      }}
    />
  )
}

function AgreeButton(
  {
    timer,
    updateDialogState
  }: any
) {
  const [localTimer, setTimer] = React.useState<number | undefined>(timer);

  React.useEffect(() => {
    if (interval) {
      clearInterval(interval)
    }
    interval = setInterval(() => {
      setTimer(prevState => prevState - 1);
    }, 1000)

    return function () {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, []);

  React.useEffect(() => {
    if (localTimer < 1) {
      if (interval) {
        clearInterval(interval)
        updateDialogState((prevState: any) => ({
          ...prevState,
          timer: void 0
        }))
      }
    }
    // eslint-disable-next-line
  }, [
    localTimer,
  ]);
  return <span>{localTimer}</span>
}

function Title({title}: { title: string }) {
  const [localTitle, setLocalTitle] = React.useState<string>("");

  React.useEffect(() => {
    setLocalTitle(title);
    // eslint-disable-next-line
  }, []);

  return <>{localTitle}</>
}
