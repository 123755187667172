import React from 'react';
import {Form} from 'antd';
import {Input} from 'antd';
import {Select} from 'antd';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {Control} from 'react-hook-form/dist/types/form';
import states from '../../../../../constants/states.json';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';

export function StateInput(
  {
    control,
    setValue,
  }: {
    control: Control<any>;
    setValue: UseFormSetValue<any>;
  }
) {
  const country = useWatch({
    control,
    name: 'country',
  });

  React.useEffect(() => {
    setValue('state', '');
  }, [
    country,
    setValue,
  ])

  return (
    <Controller
      name='state'
      control={control}
      render={(
        {
          field     : {
            ref,
            value,
            ...fieldRest
          },
          fieldState: {
            error,
            invalid,
          }
        }
      ) => {
        return (
          <Form.Item
            help={error?.message}
            validateStatus={invalid ? 'error' : void 0}
            label={(country === 'us') ? 'State' : 'State/Region'}
          >
            {(country === 'us') ? (
              <Select
                ref={ref}
                allowClear
                showSearch
                options={states}
                value={value || null}
                placeholder='Please select'
                dropdownMatchSelectWidth={250}
                onClear={() => fieldRest.onChange(null)}
                {...fieldRest}
              />
            ) : (
              <Input
                ref={ref}
                {...fieldRest}
              />
            )}
          </Form.Item>
        )
      }}
    />
  )
}
