import React from 'react';
import {Modal} from 'antd';
import {Text} from '../Typography';
import {Title} from '../Typography';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {formatWithCommas} from '../../../utils';
import {urlHelper} from '../../../utils/urlHelper';
import {AppRoutes} from '../../../constants/appRoutes';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import {_passiveGraceData} from '../../../store/selectors/application/acl';
import {_passiveGraceDaysLeft} from '../../../store/selectors/application/acl';

export function PassiveGracePeriod() {
  const {push} = useHistory();
  const {appUuid} = useParams<{ appUuid: string }>();
  const passiveGraceData = useSelector(_passiveGraceData);
  const passiveGraceDaysLeft = useSelector(_passiveGraceDaysLeft);

  const onOk = function () {
    if (appUuid) {
      push(urlHelper(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP, {appUuid}));
    } else {
      push(AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS);
    }
  }

  const onCancel = function () {
    if (appUuid) {
      push(urlHelper(AppRoutes.MANAGE_ACCOUNT_APP, {
        appUuid,
      }));
    } else {
      push(AppRoutes.MANAGE_ACCOUNT);
    }
  }

  return (
    <Modal
      visible
      centered
      onOk={onOk}
      closable={false}
      onCancel={onCancel}
      maskClosable={false}
      okText='Go to Payment Methods'
      cancelText='Go to Profile Page'
      title='Your Account is Suspended'
    >
      <div>
        <Title
          level={1}
          type='warning'
          align='center'
        ><WarningOutlined/>
        </Title>
      </div>
      <div>
        <Text
          align='center'
          type='secondary'
        >We were not able to process payment for your subscription
        </Text>
      </div>
      <div>
        <Text
          align='center'
          type='secondary'
        >There might be an issue with your payment methods or you might have insufficient funds.
        </Text>
      </div>
      <div>
        <Text
          align='center'
          type='secondary'
        >Please check your Payment Methods.
        </Text>
      </div>
      <div
        className='m-t-3'
      >
        <Text
          align='center'
          type='secondary'
        >Your active and planned campaigns will be stopped while the account is suspended.
        </Text>
      </div>
      <div>
        <Text
          align='center'
          type='secondary'
        >We will continue storing all of your data and subscriptions for another <Text strong>{passiveGraceDaysLeft}</Text> {(passiveGraceDaysLeft === 1) ? 'day' : 'days'}.
        </Text>
      </div>
      <div
        className='m-t-3'
      >
        <Text
          type='warning'
          align='center'
          fontSize='large'
        >Account Status
        </Text>
      </div>
      <div
        className='d-flex align-items-center justify-content-center'
      >
        <div
          className='m-r-1'
        >
          <Text
            align='center'
            type='secondary'
          >Total Subscriptions
          </Text>
          <div>
            <Text
              strong
              type='warning'
              align='center'
            >{formatWithCommas(passiveGraceData?.total_before_exp || 0)}
            </Text>
          </div>
        </div>
        <div
          className='m-l-1'
        >
          <Text
            align='center'
            type='secondary'
          >New Subscribers
          </Text>
          <Text
            strong
            type='warning'
            align='center'
          >{formatWithCommas(passiveGraceData?.total_after_exp || 0)}
          </Text>
        </div>
      </div>
    </Modal>
  );
}
