import i18n from 'i18next';
import {lcFirst} from '../utils';
import {ucFirst} from '../utils';
import commonEn from './en/en.json';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export default function loadI18n(): Promise<typeof i18n> {
  return new Promise((accept, reject) => {
    i18n
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        lng          : 'en',
        fallbackLng  : 'en',
        interpolation: {
          escapeValue: false,
          format     : function (value, format, lng) {
            if (format === 'ucFirst') return ucFirst(value);
            if (format === 'lcFirst') return lcFirst(value);
            return value;
          },
        },
        debug        : process.env.NODE_ENV === 'development',
        ns           : [
          'common',
        ],
        defaultNS    : 'common',
        fallbackNS   : 'common',
        resources    : {
          en: {
            common: commonEn,
          },
        },
        react        : {
          transSupportBasicHtmlNodes: true,
          useSuspense               : true,
          bindI18n                  : 'languageChanged loaded',
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'a'],
        },
      }, (error) => {
        if (error) {
          reject(error)
        } else {
          accept(i18n);
        }
      })
      .catch((e) => reject(e))
  })
}
