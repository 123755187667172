import {_reset} from './index';
import {CancelToken} from 'axios';
import {_upsertEntity} from './index';
import {TFunction} from 'react-i18next';
import {_entity} from '../../selectors/prompt';
import {Globals} from '../../../types/globals';
import {_currentApp} from '../../selectors/apps';
import {urlHelper} from '../../../utils/urlHelper';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {responseTransformer} from '../../../utils/promptTransformer';

export const _get = (
  appUuid: string,
  {
    t,
    cancelToken,
  }: {
    t: TFunction;
    cancelToken: CancelToken;
  }
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  if (appUuid !== _entity(getState())?.app_uuid) {
    dispatch(_reset());
  }

  const app = _currentApp(getState());

  const {
    hasError,
    body: {
      data,
    },
  } = await api.get(urlHelper(ApiRoutes.GET_PROMPT, {appUuid}), {
    cancelToken,
  });

  if (!hasError) {
    dispatch(_upsertEntity(responseTransformer({
      id      : data.id,
      app_uuid: data.app_uuid,
      ...data.data,
    }, app, t)));
  }
};
