import React from 'react';
import DateFormatter from '../Formatter';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {DataTypeProviderProps} from '@devexpress/dx-react-grid';

export default function DateTypeProvider(props: DataTypeProviderProps) {
  return (
    <DataTypeProvider
      formatterComponent={DateFormatter}
      {...props}
    />
  )
}
