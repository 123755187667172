import React from 'react';
import Providers from '../Providers';
import {useParams} from 'react-router';
import Box from '@material-ui/core/Box';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import {Header} from '../components/Header';
import {useTranslation} from 'react-i18next';
import {useTheme} from '@material-ui/core/styles';
import {useTitle} from '../../../../../hooks/useTitle';
import ToolbarLeftActions from '../ToolbarLeftActions';
import Table from '../../../../../components/ReduxTable';
import {urlHelper} from '../../../../../utils/urlHelper';
import {ToolbarRightActions} from '../ToolbarRightActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {ApiRoutes} from '../../../../../constants/apiRoutes';
import {AppRoutes} from '../../../../../constants/appRoutes';
import * as s from '../../../../../store/selectors/campaigns';
import {Content} from '../../../../../components/antd/Content';
import {Spinner} from '../../../../../components/antd/Spinner';
import {Permissions} from '../../../../../constants/permission';
import {FEATURE_GROUP} from '../../../../../constants/permission';
import {fetchAction} from '../../../../../store/actions/campaigns';
import {optionsAction} from '../../../../../store/actions/campaigns';
import {_apiBaseUrl} from '../../../../../store/selectors/application';
import {emptyDataAction} from '../../../../../store/actions/campaigns';
import {dataOptionsAction} from '../../../../../store/actions/gallery';
import {_userId} from '../../../../../store/selectors/application/acl';
import {_userAcl} from '../../../../../store/selectors/application/acl';
import {handleExportAction} from '../../../../../store/actions/campaigns';
import {_hasConversionsAccess} from '../../../../../store/selectors/application/acl';

export function PushNotifications() {
  const {push} = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(_userId);
  const {appUuid} = useParams<{ appUuid: string }>();

  React.useEffect(() => {
    dispatch(dataOptionsAction({
      limits_notice_is_open: true,
    }))
  }, [
    dispatch,
  ]);

  const onClick = React.useCallback((url) => () => {
    localStorage.setItem(`create_push_notification_tooltip_has_shown_${userId}`, 'true')
    push(url);
  }, [
    push,
    userId,
  ]);

  const loadData = React.useCallback(() => dispatch(fetchAction()), [
    dispatch,
  ]);

  return (
    <>
      <Header
        reloadData={loadData}
        createAction={onClick(urlHelper(AppRoutes.CAMPAIGNS_PUSH_NOTIFICATIONS_CREATE, {appUuid}))}
      />
      <Content>
        <Paper
          square
          elevation={0}
          variant='elevation'
        >
          <Box
            padding={3}
            position='relative'
          >
            <Childes/>
          </Box>
        </Paper>
      </Content>
    </>
  )
}

function Childes() {
  const theme = useTheme();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const byId = useSelector(s.getById);
  const userAcl = useSelector(_userAcl);
  const allIds = useSelector(s.getAllIds);
  useTitle(t('campaigns.document_title'));
  const apiBaseUrl = useSelector(_apiBaseUrl);
  const isLoading = useSelector(s.getIsLoading);
  const tableOptions = useSelector(s.getOptions);
  const {appUuid} = useParams<{ appUuid: string }>();
  const paginationOptions = useSelector(s.getPagination);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = React.useState<boolean>(true);
  const hasConversionsAcl = useSelector(_hasConversionsAccess);

  const hasSegmentsAccess = React.useMemo(() => {
    return !!(
      userAcl?.segments?.[FEATURE_GROUP]?.[Permissions.READ] &&
      userAcl?.segments?.[FEATURE_GROUP]?.[Permissions.VISIBLE]
    )
  }, [
    userAcl,
  ])

  React.useEffect(() => {
    setLoading(true);
    dispatch(optionsAction({
      columns: tableOptions.columnOrder.map((item: string) => ({
        name : item,
        title: t(`campaigns.column_${item}`),
      })).filter((i: any) => {
        if (hasSegmentsAccess) {
          return true
        } else {
          return i.name !== 'front_data'
        }
      }).filter((i: any) => {
        if (hasConversionsAcl) {
          return true;
        }
        return ![
          'cr',
          'epc',
          'revenue',
          'conversions',
        ].includes(i.name);
      }),
    }));
    setLoading(false);
  }, [
    t,
    dispatch,
    hasSegmentsAccess,
    hasConversionsAcl,
    tableOptions.columnOrder,
  ]);

  React.useEffect(() => {
    return function () {
      dispatch(emptyDataAction());
    }
  }, [
    dispatch,
  ]);

  const options = React.useMemo(() => ({
    ...tableOptions,
    ...paginationOptions,
  }), [
    tableOptions,
    paginationOptions,
  ]);

  const loadData = React.useCallback(() => dispatch(fetchAction()), [
    dispatch,
  ]);

  const handleExport = React.useCallback(() => dispatch(handleExportAction('csv')), [
    dispatch,
  ]);

  const updateOptions = React.useCallback((options: any) => dispatch(optionsAction(options)), [
    dispatch,
  ]);

  function getDraftTableNoDataMessage() {
    if (isLoading) {
      return t('loading');
    }
    if (!!options.searchText) {
      return t('empty_search', {
        name: t('campaigns.draft_tab_name'),
      });
    } else {
      return t('campaigns.draft_empty_state');
    }
  }

  function getActiveTableNoDataMessage() {
    if (isLoading) {
      return t('loading');
    }
    if (!!options.searchText) {
      return t('empty_search', {
        name: t('campaigns.active_tab_name'),
      });
    } else {
      return t('campaigns.active_empty_state');
    }
  }

  function getHistoryTableNoDataMessage() {
    if (isLoading) {
      return t('loading');
    }
    if (!!options.searchText) {
      return t('empty_search', {
        name: t('campaigns.history_tab_name'),
      });
    } else {
      return t('campaigns.history_empty_state');
    }
  }

  function tableNoDataMessage() {
    switch (options.tab) {
      case 'history': {
        return getHistoryTableNoDataMessage();
      }
      case 'draft': {
        return getDraftTableNoDataMessage();
      }
      default: {
        return getActiveTableNoDataMessage();
      }
    }
  }

  if (loading) {
    return (
      <Spinner/>
    );
  }

  return (
    <Table
      byId={byId}
      disableReload
      allIds={allIds}
      messages={{
        tableNoData        : tableNoDataMessage(),
        exportAgree        : t('campaigns.exportAgree'),
        exportCancel       : t('campaigns.exportCancel'),
        searchPlaceholder  : t('campaigns.search_placeholder'),
        exportSecondaryText: t('campaigns.exportSecondaryText'),
        exportMainText     : t('campaigns.exportMainTextActive'),
      }}
      options={options}
      loading={isLoading}
      loadData={loadData}
      disableSearch={matches}
      handleExport={handleExport}
      customProviders={Providers}
      updateTableOptions={updateOptions}
      toolbarLeftActions={ToolbarLeftActions}
      getRowId={row => `${row.uuid}${Math.random()}`}
      url={`${apiBaseUrl}${urlHelper(ApiRoutes.LIST_CAMPAIGNS, {appUuid})}`}
      toolbarRightActions={(options.tab === 'history') ? ToolbarRightActions : void 0}
    />
  )
}