import React from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useStripe} from '@stripe/react-stripe-js';
import {useElements} from '@stripe/react-stripe-js';
import {CardElement} from '@stripe/react-stripe-js';
import {_countries} from '../../../../../../../../store/selectors/locales';
import {_createPaymentMethod} from '../../../../../../../../store/actions/subscriptionsAndBilling/createPaymentMethod';

export function useCreate(
  {
    onClose,
  }: {
    onClose: () => void;
  }
) {
  const form = useForm<any>({
    defaultValues: {
      country: {
        value: 'us',
        label: 'UNITED STATES (US)',
      }
    }
  });
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const countries = useSelector(_countries);

  const {
    control,
    setError,
    setValue,
    clearErrors,
    handleSubmit,
    formState: {
      isSubmitting,
    }
  } = form;

  const onSubmit = React.useCallback((formValues: Record<string, any>) => {
    if (!stripe || !elements) {
      return;
    }

    elements.getElement('card').focus();

    return dispatch(_createPaymentMethod({
      stripe,
      onClose,
      setError,
      formValues,
      card: elements.getElement(CardElement),
    }));
  }, [
    stripe,
    onClose,
    elements,
    dispatch,
    setError,
  ]);

  return {
    control,
    setError,
    setValue,
    countries,
    clearErrors,
    isSubmitting,
    submit: handleSubmit(onSubmit)
  }
}
