import React from 'react';
import axios from 'axios';
import {Canceler} from 'axios';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {ColumnsType} from 'antd/es/table';
import {urlHelper} from '../../../../../utils/urlHelper';
import {Templates} from '../../../../../types/templates';
import {AppRoutes} from '../../../../../constants/appRoutes';
import {_get} from '../../../../../store/actions/templates/get';
import * as TableCell from '../../components/Details/TableCell';
import {FiniteStates} from '../../../../../constants/finiteStates';
import {_template} from '../../../../../store/selectors/templates';
import {_dataOptions} from '../../../../../store/actions/templates';
import {_removeEntity} from '../../../../../store/actions/templates';
import {_detailsTotal} from '../../../../../store/selectors/templates';
import {_templateDetails} from '../../../../../store/selectors/templates';
import {_detailsPageNumber} from '../../../../../store/selectors/templates';
import {_detailsSorterField} from '../../../../../store/selectors/templates';
import {_detailsSorterOrder} from '../../../../../store/selectors/templates';
import {_getDetails} from '../../../../../store/actions/templates/getDetails';
import {_fetchingTemplateState} from '../../../../../store/selectors/templates';
import {_fetchingTemplateDetailsState} from '../../../../../store/selectors/templates';

let cancel: Canceler | undefined;
const CancelToken = axios.CancelToken;

export function useDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const template = useSelector(_template);
  const detailsTotal = useSelector(_detailsTotal);
  const templateDetails = useSelector(_templateDetails);
  const detailsPageNumber = useSelector(_detailsPageNumber);
  const templateState = useSelector(_fetchingTemplateState);
  const detailsSorterField = useSelector(_detailsSorterField);
  const detailsSorterOrder = useSelector(_detailsSorterOrder);
  const templateDetailsState = useSelector(_fetchingTemplateDetailsState);

  const {
    id,
    appUuid,
  } = useParams<{
    id: string;
    appUuid: string;
  }>();

  React.useEffect(() => {
    dispatch(_getDetails(appUuid, id, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    }));
  }, [
    id,
    appUuid,
    dispatch,
    detailsPageNumber,
    detailsSorterField,
    detailsSorterOrder,
  ]);

  React.useEffect(() => {
    dispatch(_get(appUuid, id, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    }));
  }, [
    id,
    appUuid,
    dispatch,
  ]);

  React.useEffect(() => {
    return function () {
      if (typeof cancel === 'function') {
        cancel();
      }

      dispatch(_removeEntity());

      dispatch(_dataOptions({
        fetchingTemplateState       : FiniteStates.IDLE,
        fetchingTemplateDetailsState: FiniteStates.IDLE,
      }));
    }
  }, [
    dispatch,
  ])

  const onReload = React.useCallback(() => {
    dispatch(_getDetails(appUuid, id, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    }))
  }, [
    id,
    appUuid,
    dispatch,
  ]);

  const redirectToTemplates = React.useCallback(() => {
    history.push(urlHelper(AppRoutes.TEMPLATES, {appUuid}))
  }, [
    history,
    appUuid,
  ]);

  const tableOnChange = React.useCallback((pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      dispatch(_dataOptions({
        detailsTotal     : pagination.total,
        detailsPageNumber: pagination.current,
        detailsPageSize  : pagination.pageSize,
      }));
    } else if (extra.action === 'sort') {
      dispatch(_dataOptions({
        detailsSorterOrder: sorter.order,
        detailsSorterField: sorter.field,
      }));
    }
  }, [
    dispatch,
  ]);

  const columns: ColumnsType<Templates.EntityDetail> = React.useMemo(() => {
    return [
      {
        key      : 'app_title',
        title    : 'App Title',
        dataIndex: 'app_title',
      },
      {
        key      : 'uuid',
        dataIndex: 'uuid',
        render   : TableCell.Uuid,
        title    : 'Campaign UUID',
      },
      {
        sorter   : true,
        key      : 'ctr',
        dataIndex: 'ctr',
        title    : 'CTR',
        render   : TableCell.Ctr,
      },
      {
        sorter   : true,
        key      : 'open',
        dataIndex: 'open',
        title    : 'Opened',
      },
      {
        sorter   : true,
        key      : 'sent',
        dataIndex: 'sent',
        title    : 'Sent',
      },
      {
        sorter          : true,
        defaultSortOrder: 'descend',
        title           : 'Scheduled',
        key             : 'wait_until',
        dataIndex       : 'wait_until',
        render          : TableCell.WaitUntil,
      },
      {
        fixed : 'right',
        title : 'Actions',
        render: TableCell.Actions,
      },
    ]
  }, []);

  return {
    columns,
    onReload,
    template,
    detailsTotal,
    tableOnChange,
    templateDetails,
    detailsPageNumber,
    redirectToTemplates,
    loading: (templateState === FiniteStates.LOADING) || (templateDetailsState === FiniteStates.LOADING)
  }
}