import React from 'react';
import {Table, Breadcrumb} from 'antd';
import {Content} from '../../../../components/antd/Content';
import {useAutomation} from './useAutomation';
import {FiniteStates} from '../../../../constants/finiteStates';
import TableHeader from './Table/TableHeader';
import {Navigation} from '../../../../components/antd/Navigation';

export default function Automation() {
  const {columns, automationsData, fetchingState, pageNumber, total, onPageChanges, handleTableChange} = useAutomation();

  return (
    <>
      <Navigation
        title='Automations'
        breadcrumbItems={[
          <Breadcrumb.Item key='app.templates'>
            Automations
          </Breadcrumb.Item>
        ]}
      />
      <Content>
        <div style={{padding: 20, backgroundColor: '#fff'}}>
          <TableHeader/>
          <Table
            rowKey='id'
            loading={fetchingState === FiniteStates.LOADING}
            columns={columns}
            scroll={{x: true, scrollToFirstRowOnChange: true}}
            dataSource={automationsData}
            onChange={handleTableChange}
            pagination={{
              current: pageNumber,
              onChange: (p, s) => onPageChanges(p, s),
              showQuickJumper: false,
              total,
              position: ['bottomCenter'],
              showSizeChanger: true,
            }}
          />
        </div>
      </Content>
    </>
  )
};
