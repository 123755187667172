import React from 'react';
import {filterObject} from '../../../../../../utils';

export default function (
  {
    deny,
    icon,
    style,
    allow,
    cdnUrl,
    message,
    bgColor,
    position,
    backdrop,
    isPreview,
    direction,
    messageColor,
  }: {
    icon: string;
    cdnUrl: string;
    bgColor: string;
    message: string;
    position: string;
    direction: string;
    backdrop?: boolean;
    isPreview?: boolean;
    messageColor: string;
    style?: React.CSSProperties;
    deny: {
      button: string;
      bgColor: string;
      textColor: string;
    };
    allow: {
      button: string;
      bgColor: string;
      textColor: string;
    };
  }
) {
  return (
    <div>
      <div
        style={style}
        id='ppialog-popover-container'
        className='smart-root-container smart-root-container-1 ppialog-popover-container'
      >
        {!!backdrop && (
          <div
            style={{
              top            : 0,
              left           : 0,
              width          : '100%',
              height         : '100%',
              position       : 'fixed',
              minWidth       : '100vw',
              minHeight      : '100vh',
              zIndex         : 100000,
              backgroundColor: 'rgba(0,0,0,.5)',
            }}
          />
        )}
        <link rel="stylesheet" href={`${cdnUrl}/templates/style_1.css`}/>
        {!!bgColor && (
          <style>{`.smart-root-container-1 .smart-prompt .smart-prompt-arrow-box:after {border-bottom-color: ${bgColor}}`}</style>
        )}
        <div
          className={`smart-prompt ${position.split('_').join('-')}`}
          style={filterObject({
            backgroundColor: bgColor,
            transform      : !isPreview && 'unset',
            zIndex         : !isPreview ? 0 : 9999999,
            width          : !isPreview ? '100%' : 380,
            position       : !isPreview ? 'static' : 'fixed',
          })}
        >
          <div
            className='smart-prompt-arrow-box'
          />
          <p/>
          <p
            className='smart-prompt-notification-icon'
          >
          <span
            className='smart-prompt-icon'
          >
            <img
              src={icon}
              width='16'
              height='16'
              alt='prompt icon'
            />
          </span>
            <span
              className={`smart-prompt-text ${direction}`}
              style={{
                flexGrow : 1,
                textAlign: 'initial',
                color    : messageColor,
              }}
            >{message}
          </span>
          </p>
          <div
            className='smart-prompt-buttons-container'
          >
            {!!deny.button && (
              <button
                type='button'
                id='ppialog-popover-cancel-button'
                style={{backgroundColor: deny.bgColor, color: deny.textColor}}
                className='smart-btn-prompt smart-btn-block smart-root-no-wrap'
              >{deny.button}
              </button>
            )}
            {!!allow.button && (
              <button
                type='button'
                id='ppialog-popover-allow-button'
                className='smart-btn-prompt smart-btn-allow smart-root-no-wrap'
                style={{backgroundColor: allow.bgColor, color: allow.textColor}}
              >{allow.button}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
