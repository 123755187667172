import React from 'react';
import CloneDialog from './Clone';
import CancelDialog from './Cancel';
import {useParams} from 'react-router';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {urlHelper} from '../../../../../utils/urlHelper';
import Typography from '@material-ui/core/Typography';
import {AppRoutes} from '../../../../../constants/appRoutes';
import DeleteDialog from '../../../../../components/Dialog/Delete';
import {TableActions} from '../../../../../constants/tableActions';
import {cancelAction} from '../../../../../store/actions/campaigns';
import {destroyAction} from '../../../../../store/actions/campaigns';
import {getDataOptions} from '../../../../../store/selectors/campaigns';
import {getActionDialog} from '../../../../../store/selectors/campaigns';
import {toggleDialogAction} from '../../../../../store/actions/campaigns';
import {getActionDialogData} from '../../../../../store/selectors/campaigns';

export default function Dialogs() {
  const history = useHistory();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {appUuid} = useParams<{ appUuid: string }>();

  const dialog = useSelector(getActionDialog);
  const row = useSelector(getActionDialogData);
  const {
    isRequesting,
  } = useSelector(getDataOptions);

  const handleCancel = React.useCallback(() => dispatch(cancelAction(appUuid, t)), [
    t,
    appUuid,
    dispatch,
  ]);

  const handleDelete = React.useCallback(() => dispatch(destroyAction(appUuid)), [
    appUuid,
    dispatch,
  ]);

  const handleCreateTemplate = React.useCallback(() => {
    dispatch(toggleDialogAction(void 0));
    history.push(urlHelper(AppRoutes.TEMPLATE_CLONE_FROM_CAMPAIGN, {
      appUuid,
      uuid: row.uuid
    }));
  }, [
    row,
    history,
    appUuid,
    dispatch,
  ]);

  const handleClose = React.useCallback(() => dispatch(toggleDialogAction(void 0)), [
    dispatch,
  ]);

  switch (dialog) {
    case TableActions.CLONE: {
      return (
        <CloneDialog
          onClose={handleClose}
          loading={!!isRequesting}
          open={TableActions.CLONE === dialog}
          bodyTitle={row?.dialogState?.bodyTitle}
          agreeLabel={row?.dialogState?.agreeLabel}
          agreeAction={row?.dialogState?.agreeAction}
          dismissLabel={row?.dialogState?.dismissLabel}
          dismissAction={row?.dialogState?.dismissAction}
          bodyDescription={row?.dialogState?.bodyDescription}
        />
      )
    }
    case TableActions.DELETE: {
      return (
        <DeleteDialog
          onClose={handleClose}
          loading={!!isRequesting}
          agreeAction={handleDelete}
          dismissAction={handleClose}
          open={TableActions.DELETE === dialog}
          dismissLabel={t('campaigns.keep_campaign')}
          agreeLabel={t('campaigns.delete_campaign')}
          bodyDescription={t('campaigns.delete_body_description')}
          bodyTitle={t('campaigns.delete_body_title', {name: row.title})}
        />
      )
    }
    case TableActions.CANCEL: {
      return (
        <CancelDialog
          onClose={handleClose}
          loading={!!isRequesting}
          agreeAction={handleCancel}
          dismissAction={handleClose}
          open={TableActions.CANCEL === dialog}
          agreeLabel={t('campaigns.cancel_campaign')}
          dismissLabel={t('campaigns.keep_campaign')}
          bodyTitle={t('campaigns.cancel_body_title', {name: row.title})}
        />
      )
    }
    case TableActions.CLONE_FROM: {
      return (
        <CloneDialog
          onClose={handleClose}
          loading={!!isRequesting}
          dismissAction={handleClose}
          agreeLabel={t('buttons.create')}
          dismissLabel={t('buttons.cancel')}
          agreeAction={handleCreateTemplate}
          open={TableActions.CLONE_FROM === dialog}
          bodyTitle={t('campaigns.clone_body_title')}
          bodyDescription={t('campaigns.create_template_body_description')}
        />
      )
    }
    case TableActions.SUBMIT_CANCEL: {
      return (
        <CancelDialog
          color='warning'
          onClose={handleClose}
          loading={!!isRequesting}
          disabled={!!row?.dialogState?.timer}
          bodyTitle={row?.dialogState?.bodyTitle}
          agreeAction={row?.dialogState?.agreeAction}
          open={TableActions.SUBMIT_CANCEL === dialog}
          dismissLabel={row?.dialogState?.dismissLabel}
          dismissAction={row?.dialogState?.dismissAction}
          bodyDescription={row?.dialogState?.bodyDescription}
          agreeLabel={!row?.dialogState?.timer ? (
            row?.dialogState?.agreeLabel
          ) : (
            <Label
              row={row}
              dialog={dialog}
              timer={Number(row?.dialogState?.timer)}
            />
          )}
        />
      )
    }
    default: {
      return null;
    }
  }
}

let interval: any;

function Label(
  {
    row,
    timer,
    dialog,
  }: {
    row: any;
    dialog: any;
    timer: number;
  }
) {
  const dispatch = useDispatch();
  const [localTimer, setLocalTimer] = React.useState<number>(timer);

  React.useEffect(() => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(function () {
      setLocalTimer(prevState => prevState - 1);
    }, 1000);

    return function () {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, []);

  React.useEffect(() => {
    if (localTimer <= 0) {
      dispatch(toggleDialogAction({
        data: {
          ...row,
          dialogState: {
            ...row?.dialogState,
            timer: void 0,
          }
        },
        dialog,
      }));
    }
  }, [
    row,
    dialog,
    dispatch,
    localTimer,
  ]);

  return (
    <Typography
      variant='inherit'
    >{localTimer}
    </Typography>
  )
}