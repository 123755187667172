import React from 'react';
import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {_googleAuth} from '../../../../store/actions/application/googleAuth';

export function useGoogleAuth(
  {
    action,
    reCaptchaAction,
  }: {
    reCaptchaAction: string;
    action: 'gmail_login' | 'gmail_registration';
  }
) {
  const {
    handleSubmit,
    ...formRest
  } = useForm();

  const dispatch = useDispatch();
  const {search} = useLocation();
  const searchString = search.replace('?', '');
  const {plan} = useParams<{ plan?: string }>();
  const {executeRecaptcha} = useGoogleReCaptcha();

  const googleAuth = React.useCallback(() => {
    return dispatch(_googleAuth({
      plan,
      action,
      searchString,
      reCaptchaAction,
      executeRecaptcha,
    }))
  }, [
    plan,
    action,
    dispatch,
    searchString,
    reCaptchaAction,
    executeRecaptcha,
  ]);

  return {
    ...formRest,
    onSubmit: handleSubmit(googleAuth),
  }
}