import React from 'react';
import Switch from '@material-ui/core/Switch';
import {Theme} from '@material-ui/core/styles';
import {SwitchProps} from '@material-ui/core/Switch';
import {SwitchClassKey} from '@material-ui/core/Switch';
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

export const IOSSwitch = withStyles((theme: Theme) => createStyles({
  root        : {
    padding: 0,
    margin : theme.spacing(.5),
  },
  switchBase  : {
    padding                : 1,
    '&$checked'            : {
      transform   : 'translateX(16px)',
      color       : theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity        : 1,
        border         : 'none',
      },
    },
    '&$focusVisible $thumb': {
      color : '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb       : {},
  track       : {
    opacity        : 1,
    borderRadius   : 26 / 2,
    backgroundColor: theme.palette.grey[50],
    border         : `1px solid ${theme.palette.grey[400]}`,
    transition     : theme.transitions.create(['background-color', 'border']),
  },
  checked     : {},
  focusVisible: {},
  disabled    : {
    '&$checked': {
      '& + $track': {
        opacity: .5,
      },
    },
  },
}))(({classes, ...props}: Props) => {
  return (
    <Switch
      disableRipple
      focusVisibleClassName={classes.focusVisible}
      classes={{
        root      : classes.root,
        thumb     : classes.thumb,
        track     : classes.track,
        checked   : classes.checked,
        disabled  : classes.disabled,
        switchBase: classes.switchBase,
      }}
      {...props}
    />
  );
});

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}
