import React from 'react';
import {TableField} from './TableField';
import Box from '@material-ui/core/Box';
import {FilterField} from './FilterField'
import {ValuesField} from './ValuesField';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import {UseFormRegister} from 'react-hook-form';
import DeleteIcon from '@material-ui/icons/Delete';
import {Button} from '../../../../../components/Button';
import IconButton from '@material-ui/core/IconButton';
import {Control} from 'react-hook-form/dist/types/form';
import {Select} from '../../../../../components/AsyncPaginate';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {ErrorMessage} from '../../../../../components/ErrorMessage';
import {TextField} from '../../../../../components/BorderedTextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles(() => ({
  filterRow: {
    '&::after'    : {
      width              : 9,
      left               : 0,
      borderWidth        : 1,
      borderTopLeftRadius: 3,
      top                : 40,
      height             : '50%',
      content            : '\'\'',
      borderStyle        : 'solid',
      borderColor        : '#b2b2b2',
      position           : 'absolute',
      borderRightColor   : 'transparent',
      borderBottomColor  : 'transparent',
    },
    '&::before'   : {
      width                 : 9,
      left                  : 0,
      borderWidth           : 1,
      borderBottomLeftRadius: 3,
      height                : '50%',
      content               : '\'\'',
      borderStyle           : 'solid',
      borderColor           : '#b2b2b2',
      position              : 'absolute',
      borderTopColor        : 'transparent',
      borderRightColor      : 'transparent',
      top                   : 'calc(50% + 28px)',
    },
    '&:last-child': {
      '&::after' : {
        borderColor: 'transparent',
      },
      '&::before': {
        borderColor: 'transparent',
      },
    }
  },
}));

export function SegmentsForm(
  {
    params,
    fields,
    control,
    register,
    setValue,
    onRemove,
    onAppend,
    isLoading,
  }: {
    control: Control;
    isLoading?: boolean;
    onAppend: () => void;
    params: Record<string, any>[];
    fields: Record<string, any>[];
    onRemove: (index: number) => () => void;
    setValue: UseFormSetValue<Record<string, any>>;
    register: UseFormRegister<Record<string, any>>;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <div>
      <input
        type='hidden'
        {...register('id')}
      />
      <Controller
        name='name'
        control={control}
        render={(
          {
            field     : {
              ref,
              value,
              ...fieldRest
            },
            fieldState: {
              error: localError
            }
          }
        ) => {
          return (
            <TextField
              fullWidth
              inputRef={ref}
              margin='normal'
              autoComplete='off'
              value={value || ''}
              label={t('segments.name')}
              error={!!localError?.message}
              helperText={localError?.message}
              placeholder={t('segments.name_placeholder')}
              {...fieldRest}
            />
          );
        }}
      />
      <Box>
        {fields.map((field, index) => {
          return (
            <Box
              display='flex'
              key={field.id}
              position='relative'
              alignItems='flex-start'
              className={classes.filterRow}
            >
              <TableField
                index={index}
                control={control}
                setValue={setValue}
                register={register}
                name={`query.${index}.type`}
              />
              <Box
                width={200}
                marginLeft={1}
                marginRight={1}
              >
                <Controller
                  control={control}
                  defaultValue={field.type}
                  name={`query.${index}.type`}
                  render={(
                    {
                      field     : {
                        ref,
                        ...fieldRest
                      },
                      fieldState: {
                        error: localError
                      }
                    }
                  ) => {
                    return (
                      <>
                        <Select
                          ref={ref}
                          isClearable
                          placeholder=''
                          options={params}
                          isLoading={isLoading}
                          hasError={!!localError?.message}
                          {...fieldRest}
                        />
                        <ErrorMessage
                          message={localError?.message}
                        />
                      </>
                    );
                  }}
                />
              </Box>
              <Box
                position='relative'
              >
                <FilterField
                  index={index}
                  control={control}
                  setValue={setValue}
                  defaultValue={field.filter}
                  defaultTypeValue={field.type}
                />
              </Box>
              <ValuesField
                index={index}
                control={control}
                setValue={setValue}
                defaultValue={field.values}
                defaultFilterValue={field.filter}
              />
              {(fields.length > 1) && (
                <Box
                  marginTop={2}
                  display='flex'
                  alignItems='center'
                  marginBottom={3.75}
                >
                  <IconButton
                    size='small'
                    type='button'
                    color='primary'
                    style={{padding: 8}}
                    onClick={onRemove(index)}
                  >
                    <DeleteIcon
                      color='error'
                      fontSize='small'
                    />
                  </IconButton>
                </Box>
              )}
            </Box>
          )
        })}
      </Box>
      <Box>
        <Box
          marginTop={1}
          marginBottom={1}
        >
          <Divider
            variant='fullWidth'
          />
        </Box>
        <Button
          size='medium'
          type='button'
          variant='text'
          color='primary'
          onClick={onAppend}
          disabled={fields.length >= 10}
          startIcon={<AddCircleOutlineIcon/>}
        >{t('segments.add_filter_row')}
        </Button>
      </Box>
    </div>
  )
}
