import React from 'react';
import {Empty} from 'antd';
import {Table} from 'antd';
import {Button} from 'antd';
import {Breadcrumb} from 'antd';
import {useDetails} from './useDetails';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {Content} from '../../../../../components/antd/Content';
import {Link} from '../../../../../components/antd/Typography';
import {Navigation} from '../../../../../components/antd/Navigation';
import {Description} from '../../components/Details/Locale/EmptyText';

export function Details() {
  const {
    loading,
    columns,
    template,
    onReload,
    detailsTotal,
    tableOnChange,
    templateDetails,
    detailsPageNumber,
    redirectToTemplates,
  } = useDetails();

  return (
    <>
      <Navigation
        extra={[
          <Button
            type='default'
            key='reloadData'
            onClick={onReload}
            disabled={loading}
            icon={<ReloadOutlined/>}
          />,
        ]}
        breadcrumbItems={[
          <Breadcrumb.Item
            key='app.segments'
          >
            <Link
              ellipsis
              type='secondary'
              onClick={redirectToTemplates}
            >Templates
            </Link>
          </Breadcrumb.Item>,
          <Breadcrumb.Item
            key='app.segments.newSegment'
          >{template?.data.messages.default.title}
          </Breadcrumb.Item>,
        ]}
        title={template?.data.messages.default.title}
      />
      <Content>
        <Table
          rowKey='uuid'
          locale={{
            emptyText: (
              <Empty
                description={<Description/>}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )
          }}
          loading={loading}
          columns={columns}
          onChange={tableOnChange}
          dataSource={templateDetails}
          pagination={{
            pageSize       : 10,
            showQuickJumper: true,
            total          : detailsTotal,
            current        : detailsPageNumber,
          }}
          scroll={{x: true, scrollToFirstRowOnChange: true}}
        />
      </Content>
    </>
  )
}