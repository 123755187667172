import React from 'react';
import {useParams} from 'react-router';
import Box from '@material-ui/core/Box';
import {useSelector} from 'react-redux';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import AddIcon from '@material-ui/icons/Add';
import {useFieldArray} from 'react-hook-form';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {Button} from '../../../../../../components/Button';
import {Control} from 'react-hook-form/dist/types/form';
import {urlHelper} from '../../../../../../utils/urlHelper';
import {fetchList} from '../../../../../../services/constants';
import {ApiRoutes} from '../../../../../../constants/apiRoutes';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {ErrorMessage} from '../../../../../../components/ErrorMessage';
import {TextField} from '../../../../../../components/BorderedTextField';
import {AsyncPaginate} from '../../../../../../components/AsyncPaginate';
import {_apiBaseUrl} from '../../../../../../store/selectors/application';
import {_accessToken} from '../../../../../../store/selectors/application';

export function ValuesField(
  {
    index,
    control,
    setValue,
    defaultValue,
    defaultFilterValue,
  }: {
    index: number;
    control: Control;
    defaultValue: any;
    defaultFilterValue: any;
    setValue: UseFormSetValue<Record<string, any>>
  }
) {
  const queryType = useWatch({
    control,
    name: `query.${index}.type`,
  });

  const queryFilter = useWatch({
    control,
    name: `query.${index}.filter`,
  });

  return (
    <Box
      width={!!queryFilter ? 200 : 0}
      marginLeft={!!queryFilter ? 1 : 0}
      marginRight={!!queryFilter ? 1 : 0}
    >
      <Fields
        index={index}
        control={control}
        setValue={setValue}
        queryType={queryType}
        queryFilter={queryFilter}
        defaultValue={defaultValue}
        defaultFilterValue={defaultFilterValue}
      />
    </Box>
  )
}

function Fields(
  {
    index,
    control,
    setValue,
    queryType,
    queryFilter,
    defaultValue,
    defaultFilterValue,
  }: {
    index: number;
    control: Control;
    queryType: Record<string, any>;
    queryFilter: Record<string, any>;
    defaultValue: Record<string, any>;
    defaultFilterValue: Record<string, any>;
    setValue: UseFormSetValue<Record<string, any>>;
  }
) {
  React.useEffect(() => {
    if (!defaultFilterValue?.value || (queryFilter?.value !== defaultFilterValue?.value)) {
      setValue(`query.${index}.values`, '');
      setValue(`query.${index}._values`, [{value: ''}], {shouldValidate: true});
    }
    // eslint-disable-next-line
  }, [
    setValue,
    queryFilter?.value,
    defaultFilterValue?.value,
  ]);

  switch (queryType?.search) {
    case true: {
      return (
        <SelectWithPagination
          index={index}
          control={control}
          queryType={queryType}
          queryFilter={queryFilter}
          defaultValue={defaultValue}
        />
      );
    }
    case false: {
      switch (queryType.type) {
        case 'number': {
          return (
            <NumberField
              index={index}
              control={control}
              queryType={queryType}
              queryFilter={queryFilter}
              defaultValue={defaultValue}
            />
          )
        }
        case 'string': {
          switch (queryFilter?.value) {
            case 'in':
            case 'not_in': {
              return (
                <MultiStringField
                  index={index}
                  control={control}
                  queryType={queryType}
                  queryFilter={queryFilter}
                  defaultValue={defaultValue}
                />
              )
            }
            case 'equal':
            case 'not_equal': {
              return (
                <StringField
                  index={index}
                  control={control}
                  queryType={queryType}
                  queryFilter={queryFilter}
                  defaultValue={defaultValue}
                />
              )
            }
          }
        }
      }
    }
  }

  return null
}

function MultiStringField(
  {
    index,
    control,
  }: {
    index: number;
    control: Control;
    queryType: Record<string, any>;
    queryFilter: Record<string, any>;
    defaultValue: Record<string, any>;
  }
) {
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `query.${index}._values`,
  });

  return (
    <Box
      marginTop={2}
    >
      {fields.map((field: any, subIndex) => {
        return (
          <Controller
            key={field.id}
            control={control}
            defaultValue={field.value}
            name={`query.${index}._values.${subIndex}.value`}
            render={(
              {
                field     : {
                  ref,
                  value,
                  ...fieldRest
                },
                fieldState: {
                  error: localError
                }
              }
            ) => {
              return (
                <Box
                  display='flex'
                  alignItems='flex-start'
                >
                  <TextField
                    fullWidth
                    margin='none'
                    inputRef={ref}
                    autoComplete='off'
                    value={value || ''}
                    error={!!localError?.message}
                    helperText={localError?.message}
                    {...fieldRest}
                  />
                  {(fields.length > 1) && (
                    <Box
                      display='flex'
                      marginTop={.5}
                      marginLeft={.5}
                      alignItems='center'
                      marginBottom={2.75}
                    >
                      <IconButton
                        type='button'
                        style={{padding: 6}}
                        onClick={() => remove(subIndex)}
                      >
                        <RemoveIcon
                          color='error'
                          fontSize='small'
                        />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              );
            }}
          />
        )
      })}
      <Button
        size='small'
        variant='outlined'
        disabled={fields.length >= 10}
        onClick={() => append({value: ''})}
        startIcon={<AddIcon color='inherit' fontSize='inherit'/>}
      >Add Row
      </Button>
    </Box>
  )
}

function StringField(
  {
    index,
    control,
    queryFilter,
    defaultValue,
  }: {
    index: number;
    control: Control;
    queryType: Record<string, any>;
    queryFilter: Record<string, any>;
    defaultValue: Record<string, any>;
  }
) {
  return (
    <Controller
      control={control}
      render={(
        {
          field     : {
            ref,
            ...fieldRest
          },
          fieldState: {
            error: localError
          }
        }
      ) => {
        if (!queryFilter) {
          return null;
        }
        return (
          <Box
            marginTop={2}
            display='flex'
            alignItems='center'
          >
            <TextField
              inputRef={ref}
              autoComplete='off'
              error={!!localError?.message}
              helperText={localError?.message}
              {...fieldRest}
            />
          </Box>
        )
      }}
      defaultValue={defaultValue}
      name={`query.${index}.values`}
    />
  )
}

function SelectWithPagination(
  {
    index,
    control,
    queryType,
    queryFilter,
    defaultValue,
  }: {
    index: number;
    control: Control;
    queryType: Record<string, any>;
    queryFilter: Record<string, any>;
    defaultValue: Record<string, any>;
  }
) {
  const apiBaseUrl = useSelector(_apiBaseUrl);
  const accessToken = useSelector(_accessToken);
  const {appUuid} = useParams<{ appUuid: string }>();

  const loadOptions = React.useCallback(async (
    search: string, loadedOptions: any, {
      page,
      queryType,
    }: Record<string, any>
  ) => {
    let input = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_SEGMENT_COUNTRIES, {appUuid})}`;

    if (queryType.table === 'constant') {
      input = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_SEGMENT_CONSTANTS, {appUuid})}`;
    } else if (queryType.field === 'language') {
      input = `${apiBaseUrl}${urlHelper(ApiRoutes.GET_SEGMENT_LANGUAGES, {appUuid})}`;
    }

    const {
      body: {
        data,
      }
    } = await fetchList(input, accessToken, {
      page,
      search,
      queryType,
    });

    return data;
  }, [
    appUuid,
    apiBaseUrl,
    accessToken,
  ]);

  const isMulti = [
    'in',
    'not_in',
  ].includes(queryFilter?.value);

  return (
    <Controller
      control={control}
      render={(
        {
          field     : {
            ref,
            ...fieldRest
          },
          fieldState: {
            error: localError
          }
        }
      ) => {
        if (!queryFilter) {
          return null;
        }
        return (
          <>
            <AsyncPaginate
              isClearable
              isMulti={isMulti}
              loadOptions={loadOptions}
              closeMenuOnSelect={!isMulti}
              error={!!localError?.message}
              additional={{page: 1, queryType}}
              {...fieldRest}
            />
            <ErrorMessage
              message={localError?.message}
            />
          </>
        )
      }}
      defaultValue={defaultValue}
      name={`query.${index}.values`}
    />
  );
}

function NumberField(
  {
    index,
    control,
    queryType,
    queryFilter,
    defaultValue,
  }: {
    index: number;
    control: Control;
    queryType: Record<string, any>;
    queryFilter: Record<string, any>;
    defaultValue: Record<string, any>;
  }
) {
  return (
    <Controller
      control={control}
      render={(
        {
          field     : {
            ref,
            ...fieldRest
          },
          fieldState: {
            error: localError
          }
        }
      ) => {
        if (!queryFilter) {
          return null;
        }
        return (
          <Box
            marginTop={2}
            display='flex'
            alignItems='center'
          >
            <TextField
              inputRef={ref}
              autoComplete='off'
              error={!!localError?.message}
              inputProps={{
                min : 1,
                step: 1,
                type: 'number',
              }}
              helperText={localError?.message}
              {...fieldRest}
            />
            {![
              'sessions',
              'conversion_sum',
              'conversion_count',
            ].includes(queryType.field) && (
              <Box
                marginLeft={1}
                marginBottom={2.75}
              >
                <Typography
                >Day(s)
                </Typography>
              </Box>
            )}
          </Box>
        )
      }}
      defaultValue={defaultValue}
      name={`query.${index}.values`}
    />
  )
}