import React from 'react';
import {Button} from 'antd';
import {useWatch} from 'react-hook-form';
import {IForm} from '../../useUnauthorized';
import {Control} from 'react-hook-form/dist/types/form';

export function SubmitButton(
  {
    control,
    loading,
  }: {
    loading: boolean;
    control: Control<IForm>,
  }
) {
  const email = useWatch({
    control,
    name: 'email',
  });
  const password = useWatch({
    control,
    name: 'password',
  });

  return (
    <Button
      block
      size='large'
      type='primary'
      htmlType='submit'
      loading={loading}
      disabled={!(password?.trim() && email?.trim())}
    >Continue
    </Button>
  )
}