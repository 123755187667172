import React from 'react';
import Table from './Table';
import SegmentCell from './SegmentCell';
import {useSelector} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {Theme} from '@material-ui/core/styles';
import {IObject} from '../../../../../../../types';
import {momentTZ} from '../../../../../../../utils';
import Typography from '@material-ui/core/Typography';
import {Status} from '../../../../../../../components/Status';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DATE_COLUMNS from '../../../../../../../constants/dateColumns';
import {_hasTimezonesAccess} from '../../../../../../../store/selectors/application/acl';
import {_hasConversionsAccess} from '../../../../../../../store/selectors/application/acl';

const useStyles = makeStyles((theme: Theme) => ({
  root         : {
    marginBottom: theme.spacing(1),
  },
  paperRoot    : {
    width: '100%',
  },
  floatRight   : {
    float: 'right',
  },
  dataContainer: {
    display                       : 'flex',
    flexWrap                      : 'wrap',
    backgroundColor               : 'white',
    '& > div'                     : {
      flexGrow                      : 1,
      minWidth                      : 350,
      display                       : 'flex',
      width                         : `${100 / 3}%`,
      [theme.breakpoints.down('xs')]: {
        minWidth: 'unset',
        width   : '100%'
      }
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
    }
  },
  cardHeader   : {
    fontSize       : 16,
    fontWeight     : 500,
    textAlign      : 'start',
    padding        : theme.spacing(1, 2),
    color          : theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    border         : '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom   : 'none',
  },
}));

export default function TableContainer(
  {
    stats,
    campaign,
    isWelcomeMessage,
  }: {
    stats: IObject;
    campaign: IObject;
    isWelcomeMessage?: boolean;
  }
) {
  const classes = useStyles();
  const {t} = useTranslation();

  const automation = React.useMemo(() => {
    let tmp: IObject;

    try {
      tmp = JSON.parse(campaign.automation);
    } catch (e) {
      tmp = campaign.automation;
    }
    return tmp
  }, [
    campaign,
  ]);

  const hasTimezonesAccess = useSelector(_hasTimezonesAccess);
  const hasConversionsAcl = useSelector(_hasConversionsAccess);

  const closed = Number(stats?.actions?.closed?.value) || 0;
  const active = Number(stats?.delivery?.active?.value) || 0;
  const errors = Number(stats?.delivery?.errors?.value) || 0;
  const clicked = Number(stats?.actions?.clicked?.value) || 0;
  const pending = Number(stats?.delivery?.pending?.value) || 0;
  const inactive = Number(stats?.delivery?.inactive?.value) || 0;
  const converted = Number(stats?.conversions?.converted?.value) || 0;
  const unsubscribed = Number(stats?.delivery?.unsubscribed?.value) || 0;

  const waitDateFormat = (DATE_COLUMNS.find(dC => dC.column === 'scheduled_at') || {format: 'YYYY-MM-DD hh:mm:ss A'}).format;
  const waitNewValue = momentTZ(campaign.scheduled_at || campaign.wait_until);

  const createDateFormat = (DATE_COLUMNS.find(dC => dC.column === 'created_at') || {format: 'YYYY-MM-DD hh:mm:ss A'}).format;
  const createNewValue = momentTZ(campaign.created_at);

  function getDeliveryText(delivery: any) {
    if (!delivery) {
      return '-';
    }
    const newDelivery = String(delivery).toLowerCase();
    if (!newDelivery) {
      return '-';
    }

    if (newDelivery === 'particular') {
      return t('campaigns.particular');
    } else if (newDelivery === 'immediately') {
      return t('campaigns.immediately');
    } else {
      return newDelivery;
    }
  }

  function getPerUserOptimizationText(text: any) {
    if (!text) {
      return '-';
    }
    const newText = String(text).toLowerCase();
    if (!newText) {
      return '-';
    }

    if (newText === 'particular') {
      return t('campaigns.per_user_particular_info_text');
    } else if (newText === 'immediately') {
      return t('campaigns.per_user_immediately_info_text');
    } else {
      return newText;
    }
  }

  function getPerUserOptimizationHelperText(text: any) {
    if (!text) {
      return void 0;
    }
    const newText = String(text).toLowerCase();
    if (!newText) {
      return void 0;
    }

    if (newText === 'particular') {
      return t('campaigns.per_user_particular_info_helper_text');
    } else if (newText === 'immediately') {
      return t('campaigns.per_user_immediately_info_helper_text');
    } else {
      return newText;
    }
  }

  return (
    <div
      className={classes.root}
    >
      <Typography
        className={classes.cardHeader}
      >{!isWelcomeMessage ? t('campaigns.details_title') : 'Welcome Message Details'}
      </Typography>
      <Typography
        component='div'
        className={classes.dataContainer}
      >
        {!isWelcomeMessage ? (
          <>
            <Typography
              component='div'
            >
              <Paper
                square
                variant='outlined'
                className={classes.paperRoot}
              >
                <Table
                  data={[
                    {
                      name : t('campaigns.segment_name'),
                      value: (
                        <SegmentCell
                          campaign={campaign}
                        />
                      ),
                    },
                    {
                      name : t('campaigns.status'),
                      value: (automation?.type === 'welcome') ? (
                        <Typography
                          component='div'
                          className={classes.floatRight}
                        >
                          <Status
                            status={7}
                          />
                        </Typography>
                      ) : (
                        <Typography
                          component='div'
                          className={classes.floatRight}
                        >
                          <Status
                            status={Number(campaign.status)}
                            scheduledAt={campaign.scheduled_at || campaign.wait_until}
                          />
                        </Typography>
                      )
                    },
                    {
                      name : t('campaigns.created_at'),
                      value: createNewValue.format(createDateFormat) || '-',
                    },
                    {
                      value: getDeliveryText(campaign.delivery),
                      name : t('campaigns.delivery'),
                    },
                    {
                      name : t('campaigns.start_sending_at'),
                      value: campaign.is_system ? '-' : waitNewValue.format(waitDateFormat),
                    },
                    {
                      slug      : 'per_user_optimization',
                      value     : getPerUserOptimizationText(campaign.per_user_optimization),
                      name      : t('campaigns.per_user_optimization'),
                      helperText: getPerUserOptimizationHelperText(campaign.per_user_optimization),
                    },
                    {
                      value: campaign.deliver_at || '-',
                      name : t('campaigns.deliver_at'),
                    },
                  ].filter((s) => {
                    if (hasTimezonesAccess) {
                      return true;
                    } else {
                      return s.slug !== 'per_user_optimization';
                    }
                  })}
                />
              </Paper>
            </Typography>
            <Typography
              component='div'
            >
              <Paper
                square
                variant='outlined'
                className={classes.paperRoot}
              >
                <Table
                  data={[
                    {
                      value     : active,
                      column    : 'active',
                      name      : t('campaigns.active'),
                      helperText: t('campaigns.active_helper_text'),
                      percent   : `${((active * 100) / (campaign.total || 1)).toFixed(2)}%`,
                    },
                    {
                      value     : inactive,
                      column    : 'inactive',
                      name      : t('campaigns.inactive'),
                      helperText: t('campaigns.inactive_helper_text'),
                      percent   : `${((inactive * 100) / (campaign.total || 1)).toFixed(2)}%`,
                    },
                    {
                      value     : pending,
                      column    : 'pending',
                      name      : t('campaigns.pending'),
                      helperText: t('campaigns.pending_helper_text'),
                      percent   : `${((pending * 100) / (campaign.total || 1)).toFixed(2)}%`,
                    },
                    {
                      value     : clicked,
                      column    : 'click',
                      name      : t('campaigns.click'),
                      helperText: t('campaigns.click_helper_text'),
                      percent   : `${((clicked * 100) / (active || 1)).toFixed(2)}%`
                    },
                    {
                      value     : closed,
                      column    : 'close',
                      name      : t('campaigns.close'),
                      helperText: t('campaigns.close_helper_text'),
                      percent   : `${((closed * 100) / (active || 1)).toFixed(2)}%`,
                    },
                    {
                      value     : errors,
                      column    : 'errors',
                      name      : t('campaigns.errors'),
                      helperText: t('campaigns.errors_helper_text'),
                      percent   : `${((errors * 100) / (campaign.total || 1)).toFixed(2)}%`,
                    },
                    {
                      value     : unsubscribed,
                      column    : 'unsubscribed',
                      name      : t('campaigns.unsubscribed'),
                      helperText: t('campaigns.unsubscribed_helper_text'),
                      percent   : `${((unsubscribed * 100) / (campaign.total || 1)).toFixed(2)}%`,
                    },
                    {
                      value     : converted,
                      column    : 'conversions',
                      name      : t('campaigns.conversions'),
                      helperText: t('campaigns.conversions_helper_text'),
                      percent   : `${((converted * 100) / (active || 1)).toFixed(2)}%`
                    },
                  ].filter((i) => {
                    if (hasConversionsAcl) {
                      return true;
                    }
                    return ![
                      'conversions',
                    ].includes(i.column)
                  })}
                />
              </Paper>
            </Typography>
          </>
        ) : (
          <>
            <Typography
              component='div'
            >
              <Paper
                square
                variant='outlined'
                className={classes.paperRoot}
              >
                <Table
                  data={[
                    {
                      name : 'Type',
                      value: (automation?.type === 'welcome') ? (
                        <Typography
                          component='div'
                          className={classes.floatRight}
                        >
                          <Status
                            status={7}
                          />
                        </Typography>
                      ) : (
                        <Typography
                          component='div'
                          className={classes.floatRight}
                        >
                          <Status
                            status={Number(campaign.status)}
                            scheduledAt={campaign.scheduled_at || campaign.wait_until}
                          />
                        </Typography>
                      )
                    },
                    {
                      name : t('campaigns.created_at'),
                      value: createNewValue.format(createDateFormat) || '-',
                    },
                  ]}
                />
              </Paper>
            </Typography>
            <Typography
              component='div'
            >
              <Paper
                square
                variant='outlined'
                className={classes.paperRoot}
              >
                <Table
                  data={[
                    {
                      value     : active,
                      name      : 'Delivered',
                      column    : 'delivered',
                      helperText: t('campaigns.active_helper_text'),
                      percent   : `${((active * 100) / (campaign.total || 1)).toFixed(2)}%`,
                    },
                    {
                      value     : inactive,
                      column    : 'not_delivered',
                      name      : 'Not Delivered',
                      helperText: t('campaigns.inactive_helper_text'),
                      percent   : `${((inactive * 100) / (campaign.total || 1)).toFixed(2)}%`,
                    },
                    {
                      value     : pending,
                      column    : 'pending',
                      name      : t('campaigns.pending'),
                      helperText: t('campaigns.pending_helper_text'),
                      percent   : `${((pending * 100) / (campaign.total || 1)).toFixed(2)}%`,
                    },
                    {
                      value     : clicked,
                      column    : 'click',
                      name      : t('campaigns.click'),
                      helperText: t('campaigns.click_helper_text'),
                      percent   : `${((clicked * 100) / (active || 1)).toFixed(2)}%`
                    },
                    {
                      value     : closed,
                      column    : 'close',
                      name      : t('campaigns.close'),
                      helperText: t('campaigns.close_helper_text'),
                      percent   : `${((closed * 100) / (active || 1)).toFixed(2)}%`,
                    },
                    {
                      value     : errors,
                      column    : 'errors',
                      name      : t('campaigns.errors'),
                      helperText: t('campaigns.errors_helper_text'),
                      percent   : `${((errors * 100) / (campaign.total || 1)).toFixed(2)}%`,
                    },
                    {
                      value     : unsubscribed,
                      column    : 'unsubscribed',
                      name      : t('campaigns.unsubscribed'),
                      helperText: t('campaigns.unsubscribed_helper_text'),
                      percent   : `${((unsubscribed * 100) / (campaign.total || 1)).toFixed(2)}%`,
                    },
                    {
                      value     : converted,
                      column    : 'conversions',
                      name      : t('campaigns.conversions'),
                      helperText: t('campaigns.conversions_helper_text'),
                      percent   : `${((converted * 100) / (active || 1)).toFixed(2)}%`
                    },
                  ].filter((i) => {
                    if (hasConversionsAcl) {
                      return true;
                    }
                    return ![
                      'conversions',
                    ].includes(i.column)
                  })}
                />
              </Paper>
            </Typography>
          </>
        )}
        <Typography
          component='div'
        >
          <Paper
            square
            variant='outlined'
            className={classes.paperRoot}
          >
            <Table
              data={[
                {
                  column: 'total',
                  value : Number(campaign.total),
                  name  : t('campaigns.total_subscribers_to_send'),
                },
                {
                  column: 'revenue',
                  name  : t('campaigns.revenue'),
                  value : `$${Number(campaign.revenue || 0).toFixed(3)}`,
                },
                {
                  column    : 'ctr',
                  name      : t('campaigns.ctr'),
                  helperText: t('campaigns.ctr_helper_text'),
                  value     : `${Number(campaign.ctr || 0).toFixed(2)}%`,
                },
                {
                  column    : 'cr',
                  name      : t('campaigns.cr'),
                  helperText: t('campaigns.cr_helper_text'),
                  value     : `${Number(campaign.cr || 0).toFixed(2)}%`,
                },
                {
                  column    : 'epc',
                  name      : t('campaigns.epc'),
                  helperText: t('campaigns.epc_helper_text'),
                  value     : `$${Number(campaign.epc || 0).toFixed(3)}`,
                },
                {
                  column    : 'eppsm',
                  name      : t('campaigns.eppsm'),
                  helperText: t('campaigns.eppsm_helper_text'),
                  value     : `$${Number(campaign.eppsm || 0).toFixed(3)}`,
                },
                {
                  column    : 'eppdm',
                  name      : t('campaigns.eppdm'),
                  helperText: t('campaigns.eppdm_helper_text'),
                  value     : `$${Number(campaign.eppdm || 0).toFixed(3)}`,
                },
              ].filter((i) => {
                if (hasConversionsAcl) {
                  return true;
                }

                return ![
                  'cr',
                  'epc',
                  'eppsm',
                  'eppdm',
                  'revenue',
                ].includes(i.column)
              })}
            />
          </Paper>
        </Typography>
      </Typography>
    </div>
  )
}
