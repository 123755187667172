import React from 'react';
import {Route} from 'react-router';
import {Switch} from 'react-router';
import {useParams} from 'react-router';
import {Redirect} from 'react-router-dom';
import {General} from '../Components/General';
import {ApiKeys} from '../Components/ApiKeys';
import {urlHelper} from '../../../../utils/urlHelper';
import {AppRoutes} from '../../../../constants/appRoutes';
import {ChangePassword} from '../Components/ChangePassword';
import {PaymentsAndSubscriptions} from '../Components/PaymentsAndSubscriptions';

export function ManageAccountRoutes(
  {
    showPlanChange,
    hasApiKeysAccess,
  }: {
    showPlanChange?: boolean;
    hasApiKeysAccess?: boolean;
  }
) {
  return (
    <Switch>
      <Route
        exact
        path={[
          AppRoutes.MANAGE_ACCOUNT,
          AppRoutes.MANAGE_ACCOUNT_APP,
        ]}
        component={General}
      />
      <Route
        path={[
          AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS,
          AppRoutes.PAYMENTS_AND_SUBSCRIPTIONS_APP,
        ]}
        component={!showPlanChange ? PaymentsAndSubscriptions : RedirectTo}
      />
      <Route
        exact
        path={[
          AppRoutes.API_KEYS,
          AppRoutes.API_KEYS_APP,
        ]}
        component={hasApiKeysAccess ? ApiKeys : RedirectTo}
      />
      <Route
        exact
        path={[
          AppRoutes.SECURITY,
          AppRoutes.SECURITY_APP,
        ]}
        component={ChangePassword}
      />
      <Route
        component={RedirectTo}
      />
    </Switch>
  )
}

function RedirectTo() {
  const {appUuid} = useParams<{ appUuid: string }>();
  return (
    <Redirect
      to={!!appUuid ? urlHelper(AppRoutes.MANAGE_ACCOUNT_APP, {appUuid}) : AppRoutes.MANAGE_ACCOUNT}
    />
  )
}
