import {notification} from 'antd';
import {stringify} from 'querystring';
import {filterObject} from '../../../utils';
import {IObject} from '../../../types';
import {Globals} from '../../../types/globals';
import {urlHelper} from '../../../utils/urlHelper';
import {_isAdmin} from '../../selectors/application/acl';
import {_apiBaseUrl} from '../../selectors/application';
import {ApiRoutes} from '../../../constants/apiRoutes';
import {PaymentCycleOptions} from '../../../constants/paymentCycle';
import {NumberOfPeriodsOptions} from '../../../constants/numberOfPeriods';
import {
  _setSubScriptionData,
  _setFeatureGroupsData,
  _setUsersData,
  _deleteUserData,
  _setLoading,
} from'./actions';
import {_getFilters} from '../../selectors/users';

export const getSubscriptionPlans = (): Globals.ThunkAction => async (dispatch, getState, api) => {

  const isAdmin = _isAdmin(getState());
  const apiBaseUrl = _apiBaseUrl(getState());

  if (isAdmin) {
    const sPlans = await api.get(`${apiBaseUrl}${ApiRoutes.SUBSCRIPTION_PLANS}`);

    if (!sPlans.hasError) {
      const newSPlans = (sPlans.body.data || []).map((sPlan: IObject) => {
        const paymentOption = PaymentCycleOptions.find((option) => option.value === sPlan.free_billing_cycle);
        const periodOption = NumberOfPeriodsOptions.find((option) => option.value === sPlan.free_cycle_count);

        return {
          ...sPlan,
          free_cycle_count  : periodOption,
          free_billing_cycle: paymentOption,
        }
      });

      dispatch(_setSubScriptionData(newSPlans));
    }
  }
};

export const getFeatureGroups = (): Globals.ThunkAction => async (dispatch, getState, api) => {
  const apiBaseUrl = _apiBaseUrl(getState());

  const response = await api.get(`${apiBaseUrl}${ApiRoutes.FEATURE_GROUPS}`);
  if (!response.hasError) {
    dispatch(_setFeatureGroupsData(response.body.data))
  }
};

export const getAppsData = (id: number): Globals.ThunkAction => {
  return async (_, getState, { api }) => {
    const { hasError, body: { data, message } } = await api.get(`${'webnotify/users/'}${id}/apps`);

    if (hasError) {
      notification.error({message});
      return;
    }
    return data;
  };
};

export const getUsersData = (): Globals.ThunkAction => async (dispatch, getState, api) => {
  dispatch(_setLoading());
  const apiBaseUrl = _apiBaseUrl(getState());
  const filters = _getFilters(getState());
  const {pageSize, pageNumber, searchText, sortOrder, sortName, planTemplateFilter, permissionsFilter} = filters;

  const planTemplateFilterObj = planTemplateFilter.reduce((acc: any, curr: string, index: number) => {
    acc[`planTemplateFilter[${index}]`] = curr;
    return acc;
  }, {});

  const permissionsFilterObj = permissionsFilter.reduce((acc: any, curr: string, index: number) => {
    const splited = curr.split('_');
    acc[`permissionsFilter[${index}][fg]`] = splited[0];
    acc[`permissionsFilter[${index}][value]`] = splited[1];
    acc[`permissionsFilter[${index}][access]`] = splited[2];
    return acc;
  }, {});

  const params = stringify(filterObject({
    pageSize,
    pageNumber,
    searchText,
    sortOrder,
    sortName,
    ...planTemplateFilterObj,
    ...permissionsFilterObj,
  }));
  const {
    hasError,
    body: {
      data,
      message,
    },
  } = await api.get(`${apiBaseUrl}${ApiRoutes.LIST_USERS}?${params}`);

  if (hasError) {
    notification.error({message});
  } else {
    const {rows, total} = data;
    dispatch(_setUsersData(rows, total));
  }
};

export const archiveUser = (uuid: string): Globals.ThunkAction => {

  return async (dispatch, _, {
    api,
  }) => {
    const {
      hasError,
      body: {
        message,
      },
    } = await api.delete(urlHelper(ApiRoutes.ARCHIVE_USER, {uuid}));

    notification.open({
      message,
      type: hasError ? 'error' : 'success',
    });

    if (!hasError) {
      dispatch(_deleteUserData(uuid));
    }
  }
};