export async function upgrade(persistor: any) {
  localStorage.removeItem('tokenType');
  localStorage.removeItem('expiresIn');
  localStorage.removeItem('selectedCharts');

  if (Number(localStorage.getItem('smart_version')) < 6) {
    await persistor.purge();
    localStorage.setItem('smart_version', '6');
  } else if (Number(localStorage.getItem('smart_version')) < 7) {
    removeUnnecessaryCharts();
    localStorage.setItem('smart_version', '7');
  } else if (Number(localStorage.getItem('smart_version')) !== 8) {
    localStorage.removeItem('accessToken');
    localStorage.setItem('smart_version', '8');

    window?.location?.reload()
  }
}

function removeUnnecessaryCharts() {
  const selectedCharts = JSON.parse(localStorage.getItem('selected_charts') || 'null');

  if (Array.isArray(selectedCharts) && selectedCharts.length) {
    const filteredCharts = selectedCharts.filter((i: { value: string, label: string }) => i.value !== 'subscribed')
    localStorage.setItem('selected_charts', JSON.stringify(filteredCharts))
  }
}