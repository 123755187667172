import produce from 'immer';
import {ApiKeys} from '../../../types/apiKeys';
import {ActionTypes} from '../../constants/apiKeys';
import {FiniteStates} from '../../../constants/finiteStates';
import {ActionTypes as CoreActionTypes} from '../../constants/application';

const InitialState: ApiKeys.Reducer = {
  dataOptions: {
    pageNumber          : 1,
    pageSize            : 10,
    fetchingApiKeyState : FiniteStates.IDLE,
    fetchingApiKeysState: FiniteStates.IDLE,
  },
};

export const apiKeys = produce((draft, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActionTypes.API_KEYS_SET_DATA: {
      draft.entities = payload;

      return void 0;
    }
    case ActionTypes.API_KEYS_DATA_OPTIONS: {
      if (!draft.dataOptions) {
        draft.dataOptions = {} as any;
      }
      Object.keys(payload || {}).forEach((key: string) => {
        (draft.dataOptions as any)[key] = payload[key];
      });

      return void 0;
    }
    case ActionTypes.API_KEYS_UPSERT_DATA_ITEM: {
      const index = draft.entities.findIndex((apiKey) => apiKey.id === payload.id);
      if (index !== -1) {
        Object.keys(payload).forEach((payloadKey) => {
          (draft.entities[index] as any)[payloadKey] = payload[payloadKey];
        });
      }

      return void 0;
    }
    case CoreActionTypes.LOGOUT: {
      return {...InitialState}
    }
    default:
      return void 0;
  }
}, InitialState);
