import React from 'react';
import {Route} from 'react-router';
import {Integrate} from './Integrate';
import {UpsertApp} from './UpsertApp';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {Redirect} from 'react-router-dom';
import {PayAndLaunch} from './PayAndLaunch';
import {urlHelper} from '../../../../utils/urlHelper';
import {AppRoutes} from '../../../../constants/appRoutes';
import {_creationStep} from '../../../../store/selectors/apps';
import {_integrationIsCompleted} from '../../../../store/selectors/apps';
import {_showPlanChange} from '../../../../store/selectors/application/acl';
import './index.less';

export function AppCreate() {
  const {pathname} = useLocation();
  const creationStep = useSelector(_creationStep);
  const {appUuid} = useParams<{ appUuid?: string }>();
  const showPlanChange = useSelector(_showPlanChange);
  const integrationIsCompleted = useSelector(_integrationIsCompleted);

  const redirect = React.useMemo(() => {
    if (showPlanChange) {
      return (
        !showPlanChange &&
        (creationStep === 2) &&
        integrationIsCompleted &&
        (urlHelper(AppRoutes.APPS_CREATE_APP, {appUuid}) !== pathname)
      );
    } else {
      return (
        (creationStep === 2) &&
        integrationIsCompleted &&
        (urlHelper(AppRoutes.APPS_CREATE_APP, {appUuid}) !== pathname)
      );
    }
  }, [
    appUuid,
    pathname,
    creationStep,
    showPlanChange,
    integrationIsCompleted,
  ]);

  return redirect ? (
    <Redirect
      to={urlHelper(AppRoutes.ANALYTICS_REPORTS, {appUuid})}
    />
  ) : (
    <>
      <Route
        exact
        path={[
          AppRoutes.APPS_CREATE,
          AppRoutes.APPS_EDIT_APP,
          AppRoutes.APPS_CREATE_APP,
        ]}
        component={UpsertApp}
      />
      <Route
        exact
        component={Integrate}
        path={AppRoutes.APPS_INTEGRATE_APP}
      />
      {showPlanChange && (
        <Route
          exact
          component={PayAndLaunch}
          path={AppRoutes.APPS_PAY_AND_LAUNCH_APP}
        />
      )}
    </>
  )
}