import React from 'react';
import {useTranslation} from 'react-i18next';
import {momentTZ} from '../../../../../utils';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import DATE_COLUMNS from '../../../../../constants/dateColumns';

export default function DateFormatter(
  {
    value,
    column: {
      name,
    },
  }: DataTypeProvider.ValueFormatterProps
) {
  const {i18n} = useTranslation();
  if (!value) {
    return <>-</>
  }
  try {
    const dateFormat = (DATE_COLUMNS.find(dC => dC.column === name) || {format: 'YYYY-MM-DD hh:mm:ss A'}).format;
    const newValue = momentTZ(value).format(dateFormat);

    return <>{newValue}</>
  } catch (e) {
    console.error(e);
    return <>{i18n.t('Invalid date')}</>
  }
}
