import React from 'react';
import CreateOrUpdate from '../CreateOrUpdate';

export default function Clone() {
  return (
    <CreateOrUpdate
      isClone={true}
    />
  )
}
