import {createSelector} from 'reselect';
import {arrSum} from '../../../../utils';
import {_reportsReducer} from '../index';

export const _dailyInactive = createSelector(_reportsReducer, (reports) => {
  return reports.dailyInactive;
});

export const _data = createSelector(_dailyInactive, (total) => {
  return total.data;
});

export const _dataSum = createSelector(_data, (data) => {
  return arrSum((data).map((item) => item[1]));
});

export const _dataOptions = createSelector(_dailyInactive, (total) => {
  return total.dataOptions;
});

export const _messages = createSelector(_dataOptions, (dataOptions) => {
  return dataOptions.messages;
});

export const _fetchingState = createSelector(_dataOptions, (dataOptions) => {
  return dataOptions.fetchingState;
});
