import clsx from 'clsx';
import React from 'react';
import Box from '@material-ui/core/Box';
import stripeJs from '@stripe/stripe-js';
import {Theme} from '@material-ui/core/styles';
import {CardElement} from '@stripe/react-stripe-js';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  hasError          : {
    outlineOffset: -1,
    outline      : '1px solid red',
  },
  cardElementBase   : {
    borderRadius: 2,
    padding     : '5.42px 10px',
    border      : '1px solid rgba(0, 0, 0, 0.3)',
    transition  : 'all 1s cubic-bezier(.075,.82,.165,1)',
  },
  cardElementWhite  : {
    backgroundColor: '#ffffff',
  },
  cardElementFocus  : {
    border   : `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'rgba(24, 144, 255, 0.2) 0px 0px 0px 2px',
  },
  cardElementInvalid: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  asterisk          : {
    lineHeight: 1,
    top       : 7,
    fontSize  : 14,
    fontWeight: 400,
    left      : 124.5,
    textShadow: 'none',
    position  : 'absolute',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  }
}));

export const CardField = (
  {
    color,
    disabled,
    onChange,
    hasError,
  }: {
    color?: 'white';
    hasError?: boolean;
    disabled?: boolean;
    onChange: (event: stripeJs.StripeCardElementChangeEvent) => any;
  }
) => {
  const classes = useStyles();
  const [state, setState] = React.useState<boolean>(false)

  return (
    <Box
      position='relative'
      className={clsx((hasError && !disabled) && classes.hasError)}
    >
      <span
        className={classes.asterisk}
        style={{
          display: state ? 'none' : 'inline-flex',
          color  : !disabled ? '#a3a3a3' : '#d6d6d6',
        }}
      >*
      </span>
      <CardElement
        onChange={(e) => {
          setState(!e.empty);
          onChange(e);
        }}
        options={{
          hidePostalCode: true,
          style         : {
            base: {
              fontWeight     : 400,
              fontSize       : '14px',
              textShadow     : 'none',
              lineHeight     : '16.6264px',
              letterSpacing  : '0.13132px',
              fontFamily     : 'Roboto, Helvetica, Arial, sans-serif',
              '::placeholder': {
                fontSize     : '14px',
                color        : '#a3a3a3',
                textTransform: 'capitalize',
                lineHeight   : '16.6264px',
                fontFamily   : 'Roboto, Helvetica, Arial, sans-serif',
              },
              ':disabled'    : {
                color: '#d6d6d6',
              },
            },
          },
          disabled      : disabled,
          classes       : {
            focus  : classes.cardElementFocus,
            invalid: classes.cardElementInvalid,
            base   : clsx(
              classes.cardElementBase,
              (color === 'white') && classes.cardElementWhite,
            ),
          },
        }}
      />
    </Box>
  )
};
