import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Input, Button, Row, Tooltip} from 'antd';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import {_updateFilters} from '../../../store/actions/builder/title/actions';
import {getFilters, getFetchState} from '../../../store/selectors/titles';
import CreateOrEditDialog from './Dialogs/CreateOrEditDialog';

const {Search} = Input;
const initialPageNumber = 1;

interface IProps {
  onReload: () => void,
};

const FilterSection: React.FC<IProps> = ({onReload}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const filters = useSelector(getFilters);
  const fetchingState = useSelector(getFetchState);
 
  const onReloadData = () => {
    onReload();
  };

  const onSearch = (value: string) => {
    dispatch(_updateFilters({
      searchText: value,
      pageNumber: initialPageNumber,
    }))
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      onSearch(event.target.value);
    }
  };

  return (
    <>
      <Row className='justify-content-between'>
        <Row wrap={false}>
          <Search
            onChange={onChange}
            onSearch={onSearch}
            placeholder='Search...'
            defaultValue={filters.searchText}
            disabled={!fetchingState}
          />
          <Button
            type='primary'
            className='m-l-1'
            onClick={() => setVisible(true)}
            icon={<PlusOutlined/>}
            children={'Create Title'}
          />
        </Row>
        <Tooltip title='Refresh' placement='left'>
          <Button
            shape='circle'
            onClick={onReloadData}
            icon={<ReloadOutlined style={{color: '#0000008a'}}/>}
            disabled={!fetchingState}
          />
        </Tooltip>
      </Row>
      {visible &&
        <CreateOrEditDialog
          visible={visible}
          onCancel={() => setVisible(false)}
        />
      }
    </>
  )
};

export default FilterSection;
