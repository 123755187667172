import React from 'react';
import {Edit} from './Pages/Edit';
import {Route} from 'react-router';
import {Switch} from 'react-router';
import {Clone} from './Pages/Clone';
import {Index} from './Pages/Index';
import {Create} from './Pages/Create';
import {useParams} from 'react-router';
import {Details} from './Pages/Details';
import {Redirect} from 'react-router-dom';
import {aclKeys} from '../../../constants/aclKeys';
import {urlHelper} from '../../../utils/urlHelper';
import {AppRoutes} from '../../../constants/appRoutes';
import {useSmartPush} from '../../../hooks/useSmartPush';

export function TemplatesRoutes() {
  const {
    user: {
      canUpdate,
      canCreate,
    }
  } = useSmartPush();

  return (
    <Switch>
      <Route
        exact
        component={Index}
        path={AppRoutes.TEMPLATES}
      />
      <Route
        exact
        path={AppRoutes.TEMPLATES_EDIT}
        component={canUpdate(aclKeys.TEMPLATES) ? Edit : RedirectTo}
      />
      <Route
        exact
        path={AppRoutes.TEMPLATE_CLONE_FROM_CAMPAIGN}
        component={canCreate(aclKeys.TEMPLATES) ? Clone : RedirectTo}
      />
      <Route
        exact
        path={AppRoutes.TEMPLATES_CREATE}
        component={canCreate(aclKeys.TEMPLATES) ? Create : RedirectTo}
      />
      <Route
        exact
        component={Details}
        path={AppRoutes.TEMPLATE_DETAILS}
      />
      <Route
        component={RedirectTo}
        path={`${AppRoutes.TEMPLATES}/*`}
      />
    </Switch>
  )
}

function RedirectTo() {
  const {appUuid} = useParams<{ appUuid: string }>();

  return (
    <Redirect
      to={urlHelper(AppRoutes.TEMPLATES, {appUuid})}
    />
  )
}
