import {Moment} from 'moment';
import {_setData} from './index';
import {_dataOptions} from './index';
import {CancelTokenSource} from 'axios';
import {AbortError} from '../../../../constants';
import {Globals} from '../../../../types/globals';
import {urlHelper} from '../../../../utils/urlHelper';
import {ApiRoutes} from '../../../../constants/apiRoutes';
import {FiniteStates} from '../../../../constants/finiteStates';

export const _fetch = (
  appUuid: string,
  dates: [Moment, Moment],
  {
    cancelTokenSource,
  }: {
    cancelTokenSource?: CancelTokenSource,
  } = {}
): Globals.ThunkAction => async (dispatch, getState, {
  api,
}) => {
  dispatch(_dataOptions({
    fetchingState: FiniteStates.LOADING
  }));

  const to = dates[1].format('YYYY-MM-DD');
  const from = dates[0].format('YYYY-MM-DD');

  const {
    hasError,
    body: {
      data,
      name,
    },
  } = await api.get(`${urlHelper(ApiRoutes.PERIOD_UNSUBSCRIBED_STATS, {
    appUuid,
  })}?to=${to}&from=${from}`, {
    cancelToken: cancelTokenSource?.token,
  });

  const {
    hasError: hasError_1,
    body    : {
      name: name_1,
      data: data_1,
    },
  } = await api.get(`${urlHelper(ApiRoutes.SUBSCRIBED_STATS, {
    appUuid,
  })}?to=${to}&from=${from}`, {
    skipDelay  : true,
    cancelToken: cancelTokenSource?.token,
  });

  if (!hasError && !hasError_1) {
    dispatch(_setData(data, data_1));

    dispatch(_dataOptions({
      fetchingState: FiniteStates.SUCCESS,
    }));
  } else {
    if ((name === AbortError) || (name_1 === AbortError)) {
      dispatch(_setData([], []));
      dispatch(_dataOptions({
        fetchingState: FiniteStates.IDLE,
      }));
    } else {
      dispatch(_dataOptions({
        messages     : data || data_1,
        fetchingState: FiniteStates.FAILURE,
      }))
    }
  }
};