import React from 'react';
import Box from '@material-ui/core/Box';
import {Select} from '../../AsyncPaginate';
import {ILocaleType} from '../../../types';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {getLocales} from '../../../constants';
import {Theme} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {ScrollPlayground} from '../../ScrollPlayground';
import {UseFormReturn} from 'react-hook-form/dist/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles((theme: Theme) => ({
  paper            : {
    paddingTop  : theme.spacing(1),
    paddingLeft : theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  header           : {
    paddingTop: theme.spacing(1),
  },
  subHeader        : {
    fontSize: 12,
  },
  actionsTypography: {
    fontSize: 14,
  },
  actionsContainer : {
    display       : 'flex',
    justifyContent: 'center',
    padding       : theme.spacing(1, 2),
  },
}));

export function SendBy(
  {
    form,
    disabled,
  }: {
    disabled?: boolean;
    form: UseFormReturn<any>;
  }
) {
  const {
    register,
  } = form;

  const classes = useStyles();
  const {t} = useTranslation();
  const anchorRef = React.useRef<any>(null);

  const [state, setState] = React.useState<{
    open: boolean;
    value: ILocaleType;
    pendingValue?: ILocaleType;
  }>({
    open        : false,
    pendingValue: void 0,
    value       : form.getValues('locale_type'),
  });

  const locales = React.useMemo(() => getLocales(t), [
    t,
  ]);

  const handleClose = React.useCallback((event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setState(prevState => ({
      ...prevState,
      open: false,
    }));
  }, []);

  const goAhead = React.useCallback(() => {
    setState(prevState => ({
      open        : false,
      pendingValue: void 0,
      value       : prevState.pendingValue,
    }));

    form.setValue('tab_value', 'default', {shouldDirty: true});
    form.setValue('locale_type', state.pendingValue, {shouldDirty: true});
    form.setValue('messages', [
      form.getValues('messages[0]')
    ], {shouldDirty: true});
  }, [
    form,
    state,
  ]);

  const selectOnChange = React.useCallback((v) => {
    if (v?.value !== state?.value?.value) {
      if (form.getValues('messages')?.length > 1) {
        setState(prevState => ({
          ...prevState,
          pendingValue: v,
          open        : !prevState.open,
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          value: v,
        }));
        form.setValue('locale_type', v, {
          shouldDirty: true,
        });
      }
    }
  }, [
    form,
    state,
  ]);

  return (
    <Box
      width='300px'
      marginTop='-16px'
      marginBottom='16px'
    >
      <input
        type='hidden'
        {...register('locale_type')}
      />
      <div
        ref={anchorRef}
      >
        <ClickAwayListener
          onClickAway={handleClose}
        >
          <div>
            <Select
              label='Send by'
              options={locales}
              value={state.value}
              onChange={selectOnChange}
              isDisabled={state.open || disabled}
            />
            <ScrollPlayground
              open={state.open}
              transition={true}
              placement='top-start'
              anchorEl={anchorRef.current}
            >
              <Paper
                elevation={3}
                className={classes.paper}
              >
                <Typography
                  noWrap
                  align='center'
                  color='primary'
                  className={classes.header}
                >{t('messages.change_language_country_mode')}
                </Typography>
                <Typography
                  noWrap
                  align='center'
                  color='primary'
                  className={classes.subHeader}
                >{t('messages.you_may_lose_your_data')}
                </Typography>
                <Typography
                  component='div'
                  align='center'
                  className={classes.actionsContainer}
                >
                  <MenuItem
                    onClick={handleClose}
                  >
                    <Typography
                      noWrap
                      color='primary'
                      variant='inherit'
                      className={classes.actionsTypography}
                    >{t('buttons.cancel')}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={goAhead}
                  >
                    <Typography
                      noWrap
                      color='primary'
                      variant='inherit'
                      className={classes.actionsTypography}
                    >{t('buttons.go_ahead')}
                    </Typography>
                  </MenuItem>
                </Typography>
              </Paper>
            </ScrollPlayground>
          </div>
        </ClickAwayListener>
      </div>
    </Box>
  )
}