import React from 'react';
import {useWatch} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import Radio from '@material-ui/core/Radio';
import {useTranslation} from 'react-i18next';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import {Control} from 'react-hook-form/dist/types/form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export function DirectionFormControl(
  {
    index,
    control,
    disabled,
  }: {
    index: number;
    control: Control;
    disabled?: boolean;
  }
) {
  const {t} = useTranslation();
  const templateId = useWatch({
    control,
    name: `messages.[${index}].template_id`,
  });
  const isDisabled = [4].includes(Number(templateId)) || disabled;

  return (
    <FormControl
      component='fieldset'
      disabled={isDisabled}
    >
      <FormLabel>
        <Typography
          color={isDisabled ? 'textSecondary' : 'primary'}
        >{t('app_settings.direction')}
        </Typography>
      </FormLabel>
      <Controller
        control={control}
        name={`messages.[${index}].direction`}
        render={({field}) => (
          <RadioGroup
            row
            {...field}
          >
            <FormControlLabel
              value='ltr'
              disabled={isDisabled}
              control={<Radio color='primary'/>}
              label={t('app_settings.left_to_right')}
            />
            <FormControlLabel
              value='rtl'
              disabled={isDisabled}
              control={<Radio color='primary'/>}
              label={t('app_settings.right_to_left')}
            />
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}