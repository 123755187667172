import React from 'react';
import {Redirect} from 'react-router-dom';

export function VisibilityProvider(
  {
    visible,
    children,
  }: {
    visible?: boolean;
    children: React.ReactNode;
  }
) {
  return visible ? <>{children}</> : <Redirect to='/'/>;
}